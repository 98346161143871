import React, { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import DateFnsUtils from '@date-io/date-fns';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';

import { getItemDetailsApi } from '../../apis/itemApi';
import { ITEM_FILE_UPLOAD_TYPE } from '../../constants/itemConstants';
import { Link } from 'react-router-dom';
import { downloadS3File } from '../../utils/fileUtils';

function CreateDeliveryOrderDialog(props) {
  const { open, selected, onCancel, onConfirm } = props;

  const [selectedDate, setSelectedDate] = useState(new Date());
  const [refClientPO, setRefClientPO] = useState(null);
  const [customerPoFiles, setCustomerPoFiles] = useState([]);

  useEffect(() => {
    if (isEmpty(selected)) {
      return;
    }
    const firstItemID = selected[0];
    getItemDetailsApi(firstItemID).then(async (itemDetails) => {
      const { customerUploadFiles } = itemDetails;
      const _customerPoFiles = (customerUploadFiles || []).filter(
        (item) => item.type === ITEM_FILE_UPLOAD_TYPE.CUSTOMER_PO
      );
      setCustomerPoFiles(_customerPoFiles);
    });
  }, [selected]);

  const handleConfirm = () => {
    onConfirm({ selectedDate, refClientPO });
  };

  return (
    <Dialog open={open} aria-labelledby='form-dialog-title'>
      <DialogTitle id='form-dialog-title'>Date of Delivery</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Set the date of delivery for delivery order.
        </DialogContentText>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Grid container>
            <KeyboardDatePicker
              disableToolbar
              variant='inline'
              format='MM/dd/yyyy'
              margin='normal'
              id='date-picker-inline'
              value={selectedDate}
              onChange={(date) => setSelectedDate(date)}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              fullWidth
            />
            <TextField
              label="Ref. Client's PO"
              variant='outlined'
              onChange={(evt) => setRefClientPO(evt.target.value)}
              value={refClientPO}
              margin='dense'
              fullWidth
            />
            {!isEmpty(customerPoFiles) ? (
              customerPoFiles.map((item) => {
                return (
                  <Typography key={item.fileName}>
                    Customer PO:&nbsp;
                    <Link
                      onClick={(e) => {
                        e.stopPropagation();
                        downloadS3File(item.url);
                      }}
                    >
                      {item.fileName}
                    </Link>
                  </Typography>
                );
              })
            ) : (
              <Typography>
                Customer has not uploaded any PO for this checkout.
              </Typography>
            )}
          </Grid>
        </MuiPickersUtilsProvider>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} color='primary'>
          Cancel
        </Button>
        <Button onClick={handleConfirm} color='primary'>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default CreateDeliveryOrderDialog;
