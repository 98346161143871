import React, { Fragment, useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';

import { colors } from '../../palette';

import {
  Avatar,
  Button,
  Container,
  Grid,
  InputBase,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  makeStyles,
} from '@material-ui/core';

import CameraAltOutlinedIcon from '@material-ui/icons/CameraAltOutlined';

import BootstrapInput from '../../components/inputs/BootstrapInput';
import EditorAddressesPopup from '../../components/popups/EditorAddressesPopup';
import FilesUploadActionButton from '../../components/buttons/FilesUploadActionButton';
import FilesUploadedActionButton from '../../components/buttons/FilesUploadedActionButton';
import FtrIOSSwitch from '../../components/ftr-components/FtrIOSSwitch';
import { FtrTypography, FtrButton } from '../../components/ftr-components';
import FtrInfoIconTooltip from '../../components/ftr-components/FtrInfoIconTooltip';
import { FlexRow, FlexRowCenter } from '../../components/layouts/FlexLayouts';

import PrivacyIcon from '../../assets/img/privacy-lock.png';

import { addCustomerNDAFile } from '../../apis/userApi';

import { getUserProfile, editUserProfile } from '../../actions';
import { updateCustomerSettingsWithToastNotification } from '../../actions/customerSettings';

import { getCustomerSettingsSelector } from '../../selectors/customerSettingsSelector';

import useManageProjectRevampLegacyCustomerHook from '../../hooks/useManageProjectRevampLegacyCustomerHook';

import { getCurrencySymbol } from '../../utils/currencyUtils';
import { downloadS3File } from '../../utils/fileUtils';
import { isEmptyValue } from '../../utils/commonUtils';

import {
  getCustomerNDAFileS3Key,
  uploadFileToS3,
  uploadPublicFileToS3,
} from '../../services/s3Service';
import { notifyError } from '../../services/notificationService';

import {
  DISPLAY_UNIT_DROPDOWN,
  UNIT_TYPES,
} from '../../constants/unitConstants';
import { PROFILEPIC_DIRNAME } from '../../constants';

// --------------------------------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  body: {
    height: '100%',
    overflowY: 'auto',
  },
  container: {
    display: 'flex',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
  },
  avatar: {
    width: theme.spacing(14),
    height: theme.spacing(14),
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(13),
  },
  field: {
    marginBottom: theme.spacing(2),
  },
  gridContainer: {
    width: 'auto',
    margin: 'auto',
  },
  inputField: {
    padding: '5px 14px',
    background: colors.fontWhite,
    border: `1px solid ${colors.inputBorderBlue}`,
    boxSizing: 'border-box',
    borderRadius: '5px',
    color: `${colors.fontGrey}`,
    marginBottom: '13px',
  },
  inputLabel: {
    fontSize: '16px',
    lineHeight: '27px',
    color: colors.inputLabelGrey,
    marginBottom: '5px',
  },
  profilePicture: {
    cursor: 'pointer',
  },
  privacyBox: {
    background: colors.fontWhite,
    border: `2px solid ${colors.inputBorderBlue}`,
    boxSizing: 'border-box',
    boxShadow: `0px 0px 15px ${colors.boxShadowBlue}`,
    borderRadius: '2px',
    display: 'flex',
    padding: '14px 20px',
  },
  privacyMsg: {
    fontSize: '14px',
    lineHeight: '19px',
    color: colors.fontGrey,
  },
}));

export function CustomerProfile(props) {
  const classes = useStyles();

  const history = useHistory();
  const dispatch = useDispatch();

  const {
    onPageLoad,
    userID,
    profile,
    name,
    email,
    companyID,
    companyName,
    address,
    contact,
    profilePic,
    ndaFileUrl,
    ndaVerified,
    credits,
    currency,
    submitEditProfile,
    myUserID,
    unitType,
  } = props;

  const { isLegacyCustomer } = useManageProjectRevampLegacyCustomerHook(userID);

  const customerSettings = useSelector(getCustomerSettingsSelector);

  // Declare component states
  const [editProfileName, setEditName] = useState(name);
  const [editProfileContact, setEditContact] = useState(contact ? contact : '');
  const [editCompanyName, setEditCompanyName] = useState(
    companyName ? companyName : ''
  );
  const [editProfileAddress, setEditAddress] = useState(address ? address : '');
  const [editProfileImg, setEditImg] = useState(profilePic ? profilePic : '');
  const [showEditorAddresses, setShowEditorAddresses] = useState(false);
  const [userUnits, setUserUnits] = useState(unitType);

  // Function that runs when the page is loaded
  useEffect(() => {
    onPageLoad();
  }, []);

  useEffect(() => {
    setEditName(name);
    setEditContact(contact ? contact : '');
    setEditCompanyName(companyName ? companyName : '');
    setEditAddress(address ? address : '');
    setEditImg(profilePic ? profilePic : '');
  }, [profile]);

  // Declare function that saves uploaded image and returns an S3 url
  function handleImgChange(e) {
    const file = e.target.files[0];
    const s3Key = `${PROFILEPIC_DIRNAME}/${file.name}`;
    uploadPublicFileToS3(file, s3Key)
      .then((data) => {
        setEditImg(data.Location.split(' ').join('%20'));
      })
      .catch((err) => {
        console.error(err);
        alert(err);
      });
  }

  const handleNDAUpload = async (files) => {
    const file = files[0];
    const toastId = toast('Uploading file...', {
      type: toast.TYPE.INFO,
      autoClose: false,
    });
    try {
      const fileExtension = file.name.split('.').pop();
      const fileName = `Factorem NDA - ${companyName}.${fileExtension}`;
      const data = await uploadFileToS3(
        file,
        getCustomerNDAFileS3Key(file),
        fileName
      );
      const s3ObjectUrl = data.Location;
      const body = {
        url: s3ObjectUrl,
      };
      await addCustomerNDAFile(userID, body);
      toast.update(toastId, {
        render: 'NDA uploaded successfully!',
        type: toast.TYPE.SUCCESS,
        autoClose: 3000,
      });
      onPageLoad();
    } catch (err) {
      notifyError('Error uploading NDA');
    }
  };

  // Declare function that submits new user information
  const handleSubmit = (e) => {
    e.preventDefault();
    submitEditProfile({
      editProfileName,
      editProfileContact,
      email,
      editCompanyName,
      editProfileAddress,
      editProfileImg,
      userUnits,
    });
  };

  const renderManageProjectsRevampFeatureSwitch = () => {
    if (!isLegacyCustomer) {
      return;
    }

    return (
      <FlexRow>
        <FtrTypography>New Manage Projects Revamp</FtrTypography>
        <FtrIOSSwitch
          checked={Boolean(customerSettings.manageProjectsRevampFeature)}
          onChange={() => {
            dispatch(
              updateCustomerSettingsWithToastNotification(userID, {
                manageProjectsRevampFeature: !customerSettings.manageProjectsRevampFeature,
              })
            );
          }}
          name='manage-projects-revamp-switch'
        />
      </FlexRow>
    );
  };

  const userLinkedToCompany = companyID !== 0 && !isEmptyValue(editCompanyName);

  return (
    <div className={classes.body}>
      <Container component='main' className={classes.container}>
        <Grid container spacing={9} className={classes.gridContainer}>
          <Grid container item spacing={10}>
            <Grid item xs={3} container>
              <div style={{ textAlign: 'center' }}>
                <Avatar className={classes.avatar} src={editProfileImg} />
                <input
                  id='profile-img'
                  type='file'
                  onChange={handleImgChange}
                  onClick={(event) => (event.target.value = null)}
                  style={{
                    display: 'none',
                  }}
                />
                <label htmlFor='profile-img' className={classes.profilePicture}>
                  <CameraAltOutlinedIcon
                    style={{ color: colors.inputBorderBlue }}
                  />
                </label>
                {credits !== 0 && (
                  <div style={{ color: colors.tertiaryBlue }}>
                    <p>
                      Credit Available:
                      <br />
                      {getCurrencySymbol(currency)} {credits}
                    </p>
                  </div>
                )}
              </div>
            </Grid>
            <Grid item xs={9} container direction='column' spacing={5}>
              <Grid item>
                <div className={classes.form}>
                  <InputLabel className={classes.inputLabel}>
                    Full Name
                  </InputLabel>
                  <InputBase
                    id='name'
                    name='name'
                    className={classes.inputField}
                    value={editProfileName}
                    onChange={(evt) => setEditName(evt.target.value)}
                  />
                  <InputLabel className={classes.inputLabel}>
                    Email&nbsp;
                    <FtrInfoIconTooltip
                      toolTipText={`Please contact our support via sales@factorem.co if you wish to change your email.`}
                    />
                  </InputLabel>
                  <InputBase
                    id='email'
                    name='email'
                    className={classes.inputField}
                    value={email}
                    disabled
                    autoComplete='off'
                  />
                  <InputLabel className={classes.inputLabel}>
                    Contact Number
                  </InputLabel>
                  <InputBase
                    id='contact'
                    name='contact'
                    className={classes.inputField}
                    value={editProfileContact}
                    onChange={(evt) => setEditContact(evt.target.value)}
                  />
                  <InputLabel className={classes.inputLabel}>
                    Company&nbsp;
                    {userLinkedToCompany && (
                      <FtrInfoIconTooltip
                        toolTipText={`Please contact our support via sales@factorem.co if you wish to change your company name.`}
                      />
                    )}
                  </InputLabel>

                  <InputBase
                    id='companyName'
                    name='companyName'
                    className={classes.inputField}
                    value={editCompanyName}
                    onChange={(evt) => setEditCompanyName(evt.target.value)}
                    disabled={userLinkedToCompany}
                  />
                  <div
                    style={{
                      display: 'grid',
                      gridTemplateColumns: 'repeat(2, 1fr)',
                      gap: '1rem',
                    }}
                  >
                    <div>
                      <InputLabel className={classes.inputLabel}>
                        Units
                      </InputLabel>
                      <Select
                        fullWidth
                        id='user-units'
                        value={userUnits}
                        onChange={(evt) => {
                          setUserUnits(evt.target.value);
                        }}
                        input={<BootstrapInput />}
                      >
                        {Object.values(UNIT_TYPES).map((option) => (
                          <MenuItem key={option} value={option}>
                            {DISPLAY_UNIT_DROPDOWN[option]}
                          </MenuItem>
                        ))}
                      </Select>
                    </div>
                    <div>
                      <InputLabel className={classes.inputLabel}>
                        Addresses
                      </InputLabel>
                      <Button
                        fullWidth
                        variant='outlined'
                        component='span'
                        color='primary'
                        className={classes.inputField}
                        style={{ height: '2.75rem' }}
                        onClick={() => setShowEditorAddresses(true)}
                      >
                        MANAGE Addresses
                      </Button>
                    </div>
                  </div>
                  <InputLabel className={classes.inputLabel}>NDA</InputLabel>
                  {isEmptyValue(ndaFileUrl) ? (
                    <Fragment>
                      <Typography variant='subtitle2'>
                        If you don&#39;t have one, you can download&nbsp;
                        <a
                          href='https://tinyurl.com/FactoremNDA'
                          target='_blank'
                          rel='noreferrer'
                        >
                          Factorem&#39;s NDA
                        </a>
                        &nbsp;here.
                      </Typography>
                      <FilesUploadActionButton
                        accept='.pdf'
                        inputID={`file-upload-NDA`}
                        buttonText={'Upload NDA'}
                        handleUploadFiles={(file) => handleNDAUpload(file)}
                        multiple={false}
                      />
                    </Fragment>
                  ) : (
                    <Fragment>
                      <FilesUploadedActionButton
                        inputID={`file-uploaded-NDA`}
                        buttonText={ndaVerified ? 'Signed' : 'Pending'}
                        tooltip={
                          ndaVerified
                            ? 'Your NDA has been verified!'
                            : 'We are currently verifying your NDA. You will be notified via email as soon as verification is complete.'
                        }
                        verifying={!ndaVerified}
                        uploaded={Boolean(ndaVerified)}
                        onClick={(e) => {
                          e.stopPropagation();
                          downloadS3File(ndaFileUrl);
                        }}
                      />
                    </Fragment>
                  )}
                  <FlexRowCenter style={{ marginTop: '30px' }}>
                    <FtrButton
                      size='large'
                      variant='contained'
                      color='blue'
                      onClick={(e) => handleSubmit(e)}
                    >
                      Save profile
                    </FtrButton>
                    <FtrButton
                      size='large'
                      variant='outlined'
                      color='blue'
                      onClick={() => history.push('/change-password')}
                    >
                      Change Password
                    </FtrButton>
                  </FlexRowCenter>
                </div>
              </Grid>
            </Grid>
          </Grid>
          <Grid container item>
            {renderManageProjectsRevampFeatureSwitch()}
          </Grid>
          <Grid container item>
            <div className={classes.privacyBox}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  marginRight: '14px',
                }}
              >
                <img src={PrivacyIcon} alt='icon' />
              </div>
              <div className={classes.privacyMsg}>
                <b>Privacy: </b> When you use our platform, we collect personal
                information from you to fulfill the orders you have. You may
                read our privacy policy to find out more.
              </div>
            </div>
          </Grid>
        </Grid>
      </Container>
      {showEditorAddresses && (
        <EditorAddressesPopup
          open={showEditorAddresses}
          onClose={() => setShowEditorAddresses(false)}
          userID={myUserID}
        />
      )}
    </div>
  );
}

function mapStateToProps(state) {
  return {
    profile: state.editProfile.profile,
    name: state.editProfile.name,
    email: state.editProfile.email,
    companyID: state.editProfile.companyID,
    companyName: state.editProfile.companyName,
    address: state.editProfile.address,
    contact: state.editProfile.contact,
    profilePic: state.editProfile.profilePic,
    userID: state.editProfile.userID,
    ndaFileUrl: state.editProfile.ndaFileUrl,
    ndaVerified: state.editProfile.ndaVerified,
    credits: state.editProfile.credits,
    currency: state.auth.location.currency,
    myUserID: state.auth.user.userID,
    unitType: state.auth.user.unitType,
  };
}

function matchDispatchToProps(dispatch) {
  return {
    onPageLoad: () => dispatch(getUserProfile()),
    submitEditProfile: (editedProfile) =>
      dispatch(editUserProfile(editedProfile)),
  };
}

const withConnect = connect(mapStateToProps, matchDispatchToProps);

export default withConnect(CustomerProfile);
