import React from 'react';

import { Box } from '@material-ui/core';

import { colors } from '../../palette';

// ------------------------------------------------------------------

export function FtrBox(props) {
  return (
    <Box
      style={{
        backgroundColor: 'white',
        borderRadius: 10,
        padding: '0.5rem 1rem',
        border: `0.8px solid ${colors.neutral040}`,
        boxSizing: 'border-box',
        ...(props.style || {}),
      }}
    >
      {props.children}
    </Box>
  );
}

export function FtrWhiteBox(props) {
  return <FtrBox {...props} />;
}

export function FtrMintBox(props) {
  const { style, ...restProps } = props;

  return (
    <FtrBox
      style={{
        backgroundColor: colors.green020,
        border: `0.5px solid ${colors.green010}`,
        color: colors.green050,
        ...(style || {}),
      }}
      {...restProps}
    />
  );
}
