import React from 'react';
import { makeStyles } from '@material-ui/core/styles/index';

import { FlexRow } from '../layouts/FlexLayouts';
import FtrCheckIconTooltip from '../ftr-components/FtrCheckIconTooltip';
import FtrWarningIconTooltip from '../ftr-components/FtrWarningIconTooltip';

const useStyles = makeStyles(() => ({
  infoIcon: {
    marginLeft: '10px',
  },
}));

/**
 * Renders icons based on the reconciliation status of an invoice.
 *
 * @param {object} props - The component props.
 * @param {React.ReactNode} props.children - The child components to be rendered.
 * @param {boolean} props.isInvoiceSentToHubdoc - Flag indicating if the invoice is sent to Hubdoc.
 * @param {string} [props.className] - Additional CSS class names.
 * @param {object} [props.rest] - Additional properties to be passed to the icons.
 *
 * @returns {JSX.Element} The rendered component.
 */
function InvoiceReconciledInfo(props) {
  const {
    children,
    isInvoiceSentToHubdoc,
    showCheckIconOnly = false,
    className,
    isOrderDelivered = false,
    ...rest
  } = props;
  const classes = useStyles();

  if (isInvoiceSentToHubdoc) {
    return (
      <FlexRow style={{ marginBottom: '8px' }}>
        {children}
        <FtrCheckIconTooltip
          className={`${classes.infoIcon} ${className}`}
          toolTipText={'Invoice submitted successfully!'}
          {...rest}
        />
      </FlexRow>
    );
  }

  if (showCheckIconOnly) {
    return <FlexRow style={{ marginBottom: '8px' }}>{children}</FlexRow>;
  }

  const toolTipText = isOrderDelivered
    ? 'There may be an issue with your invoice. ' +
      "Please ensure that your invoice value matches Factorem's PO. " +
      'If the invoice value is correct, you may ignore this message and ' +
      'you will receive confirmation within one working day.'
    : 'Your invoice will only be processed once all items have been delivered.';

  return (
    <FlexRow style={{ marginBottom: '8px' }}>
      {children}
      <FtrWarningIconTooltip
        className={`${classes.infoIcon} ${className}`}
        toolTipText={toolTipText}
        {...rest}
      />
    </FlexRow>
  );
}

export default InvoiceReconciledInfo;
