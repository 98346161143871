import React from 'react';

import { makeStyles } from '@material-ui/core/styles/index';

import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';

import CustomerTableRowText from '../../components/ftr-components/table/CustomerTableRowText';
import FtrTableEmptyRows from '../../components/ftr-components/FtrTableEmptyRows';
import { FlexColumn } from '../../components/layouts/FlexLayouts';

import withCustomerProjectsFilterHOC from '../../hocs/withCustomerProjectsFilterHOC';

import { isEmptyValue } from '../../utils/commonUtils';

import { colors } from '../../palette';

// ----------------------------------------------------------------------------------------------

const useStyles = makeStyles(() => ({
  tableRowRoot: {
    verticalAlign: 'top',
    backgroundColor: colors.fontWhite,
    position: 'relative',
    borderBottom: `1px solid ${colors.neutral030}`,
  },
  tableContainer: {
    overflowX: 'auto',
    '& .MuiTableCell-root': {
      padding: '0.8rem',
    },
  },
  tableRow: {
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: colors.neutral020,
    },
  },
  tableCellRoot: {
    paddingTop: '1.5rem',
    paddingBottom: '1.5rem',
  },
}));

function CustomerProjectsMuiTable(props) {
  const classes = useStyles();

  const {
    projects = [],
    columns = [],
    rowKey = 'projectID',
    searching = false,
    onRowClick = () => {},
  } = props;

  return (
    <FlexColumn>
      <TableContainer
        component={Paper}
        style={{ borderRadius: '16px', width: '100%' }}
      >
        <Table aria-label='table'>
          <TableHead style={{ height: 82 }}>
            <TableRow>
              {columns.map((column) => {
                if (column.hidden) {
                  return null;
                }

                return (
                  <TableCell
                    key={column.title}
                    style={{
                      top: 0,
                      color: colors.neutral060,
                      fontSize: '16px',
                      fontWeight: 600,
                      width: column.width,
                    }}
                  >
                    {column.title}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {projects.map((row) => {
              return (
                <TableRow
                  key={row[rowKey]}
                  id={`${row[rowKey]}`}
                  onClick={() => onRowClick(row)}
                  className={classes.tableRow}
                  classes={{
                    root: classes.tableRowRoot,
                  }}
                >
                  {columns.map((column) => {
                    const renderFunc = column.render;
                    if ((!renderFunc && !column.field) || column.hidden) {
                      return null;
                    }

                    return (
                      <TableCell
                        key={row.field}
                        style={{
                          width: column.width,
                          borderBottom: 'none',
                        }}
                        classes={{
                          root: classes.tableCellRoot,
                        }}
                      >
                        <CustomerTableRowText>
                          {typeof renderFunc === 'function'
                            ? renderFunc(row)
                            : row[column.field]}
                        </CustomerTableRowText>
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {isEmptyValue(projects) && (
        <FtrTableEmptyRows
          text={searching ? 'No matches found' : 'Not found'}
        />
      )}
    </FlexColumn>
  );
}

export default withCustomerProjectsFilterHOC(CustomerProjectsMuiTable);
