import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles/index';

import { Publish as PublishIcon } from '@material-ui/icons';
import { Tooltip } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  uploadButton: {
    display: 'flex',
    alignItems: 'center',
    width: 'fit-content',
    color: 'black',
    padding: '0.4rem',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: '#0000000a',
    },
    borderRadius: 4,
    textTransform: 'uppercase',
    fontSize: '0.6rem',
    lineHeight: '0.8rem',
    whiteSpace: 'nowrap',
  },
  uploadIcon: {
    color: 'gray',
  },
  highlightButton: {
    backgroundColor: '#00000008',
    '&:hover': {
      backgroundColor: '#00000011',
    },
  },
  disabled: {
    color: '#00000042',
    '&:hover': {
      backgroundColor: 'initial',
      cursor: 'default',
    },
    userSelect: 'none',
  },
}));

/**
 *
 * @param {object} props
 * @param {string} props.inputID
 * @param {string} props.buttonText
 * @param {() => void} props.handleUploadFiles
 * @param {boolean} props.highlightButton
 * @param {boolean} props.disabled
 * @param {boolean} props.multiple
 * @param {string} props.accept
 * @param {string} props.tooltip
 */
function FilesUploadActionButton(props) {
  const classes = useStyles();

  const {
    inputID = 'file-upload',
    buttonText = 'Upload Files',
    handleUploadFiles,
    highlightButton = false,
    disabled = false,
    multiple = true,
    accept = '*',
    tooltip = '',
  } = props;

  return (
    <div onClick={(event) => event.stopPropagation()}>
      <input
        style={{
          display: 'none',
        }}
        id={inputID}
        type='file'
        multiple={multiple}
        onChange={(evt) => handleUploadFiles(evt.target.files)}
        onClick={(event) => (event.target.value = null)}
        disabled={disabled}
        accept={accept}
      />
      <Tooltip title={tooltip}>
        <label
          className={clsx(
            classes.uploadButton,
            highlightButton && classes.highlightButton,
            disabled && classes.disabled
          )}
          htmlFor={inputID}
        >
          <PublishIcon
            className={clsx(classes.uploadIcon, disabled && classes.disabled)}
          />
          <span style={{ marginTop: 3, marginLeft: 4, marginRight: 4 }}>
            {buttonText}
          </span>
        </label>
      </Tooltip>
    </div>
  );
}

export default FilesUploadActionButton;
