import React, { useEffect, useContext } from 'react';
import { useSelector } from 'react-redux';

import { useTheme, useMediaQuery } from '@material-ui/core';

import { DrawerButtonsContext } from '../ResponsiveSidebar';

import withGuidedTour from './withGuidedTour';

import { BUYER_UPLOAD_PARTS_SUMMARY_TOUR } from './guidedTours/tourConstants';

function MultiplePartSummaryFormTour(props) {
  const {
    updateTourState,
    userCompletedTourBefore,
    tour,
    collapseArrow,
    expandFirstArrow,
  } = props;

  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const questionAnswered = useSelector(
    (state) => state.createUser.questionAnswered
  );
  const signupSuccess = useSelector((state) => state.createUser.signupSuccess);

  const { closeDrawerButtonRef } = useContext(DrawerButtonsContext);

  const theme = useTheme();
  // isMobile is used to decide placement of the tour steps, depending on mobile or not.
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    updateTourState({
      tourKey: BUYER_UPLOAD_PARTS_SUMMARY_TOUR,
      createOnClickGettingStartedPromise,
      createExpandArrowPromise,
      createCollapseArrowPromise,
      delayedScrollToHandler,
    });
  }, []);

  // Connects the tour to the redux store, so that getting started uses the correct tour as the page.
  // When we dismount, set to null.
  useEffect(() => {
    // To handle /getquotenow
    if (!isAuthenticated) {
      return;
    }

    // User just signed up and has not answered question yet.
    if (!questionAnswered && signupSuccess) {
      return;
    }

    if (tour && userCompletedTourBefore === false) {
      tour.start();
    }
  }, [tour, userCompletedTourBefore, isAuthenticated, questionAnswered]);

  function createOnClickGettingStartedPromise() {
    return new Promise((resolve) => {
      if (isMobile && closeDrawerButtonRef?.current) {
        closeDrawerButtonRef.current.click();
      }
      collapseArrow();
      resolve();
    });
  }

  function createExpandArrowPromise() {
    return new Promise((resolve) => {
      expandFirstArrow();
      resolve();
    });
  }

  function createCollapseArrowPromise() {
    return new Promise((resolve) => {
      collapseArrow();
      resolve();
    });
  }

  function delayedScrollToHandler(element) {
    setTimeout(() => {
      element.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }, 460);
  }

  return <></>;
}

export default withGuidedTour(MultiplePartSummaryFormTour);
