import React, { useMemo, useState } from 'react';

import MoreActionsButton from '../components/ftr-components/MoreActionsButton';
import { FlexRow } from '../components/layouts/FlexLayouts';

// --------------------------------------------------------------------------------------------

function withCustomerPOActionsMenuListHOC(WrappedComponent) {
  function WrappedComponentWithHOC(props) {
    const {
      showActions = true,
      handleReplacePOFile = () => {},
      handleDownloadPOFile = () => {},
      handleDeletePOFile = () => {},
    } = props;

    const hiddenFileInput = React.useRef(null);

    const [isHovered, setIsHovered] = useState(false);

    const MoreActionsMenuList = useMemo(() => {
      return [
        {
          text: 'Replace',
          onClick: () => {
            hiddenFileInput.current.click();
          },
        },
        {
          text: 'Download',
          onClick: handleDownloadPOFile,
        },
        {
          text: 'Delete',
          onClick: handleDeletePOFile,
        },
      ];
    });

    return (
      <FlexRow
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <WrappedComponent {...props} />
        {showActions && (
          <MoreActionsButton
            menuList={MoreActionsMenuList}
            style={{ padding: 0, visibility: isHovered ? 'visible' : 'hidden' }}
          />
        )}
        <input
          type='file'
          ref={hiddenFileInput}
          onChange={(evt) => handleReplacePOFile(evt.target.files)}
          style={{ display: 'none' }}
        />
      </FlexRow>
    );
  }

  return WrappedComponentWithHOC;
}

export default withCustomerPOActionsMenuListHOC;
