import { get } from 'lodash';
import Decimal from 'decimal.js';

import { getPpeInfo } from './itemUtils';
import { isEmptyValue } from './commonUtils';

export const getItemDimensionDefaultValues = (item) => {
  if (isEmptyValue(item)) {
    return {};
  }

  const getPpeInfoValue = (key) => {
    const ppeInfo = item?.ppeInformation ?? getPpeInfo(item);
    return get(ppeInfo, key) ?? null;
  };

  const weight = getPpeInfoValue('weight');
  const weightPerUnit = getPpeInfoValue('weightPerUnit');
  const calcWeightPerUnit = weight
    ? new Decimal(weight).dividedBy(item?.quantity || 1).toNumber()
    : null;

  return {
    boundingBoxX: getPpeInfoValue('boundingBoxX'),
    boundingBoxY: getPpeInfoValue('boundingBoxY'),
    boundingBoxZ: getPpeInfoValue('boundingBoxZ'),
    volume: getPpeInfoValue('volume'),
    weight,
    surfaceArea: getPpeInfoValue('surfaceArea'),
    weightPerUnit: weightPerUnit || calcWeightPerUnit,
    numBends: getPpeInfoValue('numBends'),
    numHoles: getPpeInfoValue('numHoles'),
    sizeXUnfoldedPart: getPpeInfoValue('sizeXUnfoldedPart'),
    sizeYUnfoldedPart: getPpeInfoValue('sizeYUnfoldedPart'),
    thickness: getPpeInfoValue('thickness'),
  };
};
