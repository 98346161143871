import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useQuery } from 'react-query';

import withGuidedTour from './withGuidedTour';

import PartUploadTourPopup from './PartUploadTourPopup';

import { getGuidedTourCompletionStatus } from '../../apis/tourApi';

import { isAdminOrHigherRole } from '../../utils/roleUtils';
import { isEmptyValue } from '../../utils/commonUtils';

import { PART_UPLOAD_STEP_ONE_TOUR } from './guidedTours/tourConstants';

// -------------------------------------------------------------------------------------------------

function PartUploadStepOneTour(props) {
  const {
    updateTourState,
    userCompletedTourBefore,
    setTourEnded,
    tour,
  } = props;

  const userID = useSelector((state) => state.auth?.user?.userID);
  const role = useSelector((state) => state.auth?.user?.role);
  const userName = useSelector((state) => state.auth?.user?.name) ?? '';

  const [showPopup, setShowPopup] = useState(false);

  const { data: completeOldTour } = useQuery(
    ['getGuidedTourCompletionStatus', userID, PART_UPLOAD_STEP_ONE_TOUR],
    () => {
      if (isEmptyValue(userID)) {
        return null;
      }
      return getGuidedTourCompletionStatus(userID, PART_UPLOAD_STEP_ONE_TOUR)
        .then((data) => {
          return Boolean(data?.completed);
        })
        .catch((e) => {
          console.error(e);
          return false;
        });
    }
  );

  useEffect(() => {
    updateTourState({
      tourKey: PART_UPLOAD_STEP_ONE_TOUR,
      userCompletedTourBefore: completeOldTour,
    });
  }, [completeOldTour]);

  useEffect(() => {
    const completeTour = userCompletedTourBefore === false;
    const showPopup = !isAdminOrHigherRole(role) && completeTour;
    setShowPopup(showPopup);
  }, [role, userCompletedTourBefore]);

  return (
    <>
      <PartUploadTourPopup
        open={showPopup}
        onClose={() => setShowPopup(false)}
        userName={userName}
        handleSkipTour={() => setTourEnded()}
        handleStartTour={tour?.start}
        newUser={completeOldTour === false}
      />
    </>
  );
}

export default withGuidedTour(PartUploadStepOneTour);
