import React from 'react';
import { makeStyles } from '@material-ui/core/styles/index';

import CheckIcon from '../icons/CheckIcon';
import WarningIcon from '../icons/WarningIcon';

import { isNumber } from '../../utils/numberUtils';

const useStyles = makeStyles(() => ({
  infoIcon: {
    marginLeft: '10px',
  },
}));

/**
 *
 * @param {object} props
 * @param {any} props.value
 * @param {any} props.compareValue
 * @param {string} props.tooltipTextCheck
 * @param {string} props.tooltipTextWarning
 * @param {boolean} props.isCheck
 */
function CheckAndWarningInfo(props) {
  const {
    value,
    compareValue,
    tooltipTextCheck,
    tooltipTextWarning,
    isCheck,
    className,
    ...rest
  } = props;
  const classes = useStyles();

  const isEqualValue = isNumber(compareValue)
    ? +value === +compareValue
    : value === compareValue;

  if ((isEqualValue && isCheck === undefined) || isCheck) {
    return (
      <CheckIcon
        className={`${classes.infoIcon} ${className}`}
        toolTipText={tooltipTextCheck}
        {...rest}
      />
    );
  }

  return (
    <WarningIcon
      className={`${classes.infoIcon} ${className}`}
      toolTipText={tooltipTextWarning}
      {...rest}
    />
  );
}

export default CheckAndWarningInfo;
