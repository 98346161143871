import { BACKEND_SERVICE_URL } from '../constants';

import { getAuthorizedHeader } from '../utils/apiUtils';

export const sendSupplierInvoiceToHubdoc = async (
  supplierInvoiceInfoID,
  body
) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/supplier-invoice/hubdoc/${supplierInvoiceInfoID}`;
  return fetch(requestUrl, {
    method: 'PUT',
    headers: getAuthorizedHeader(),
    body: JSON.stringify(body),
  }).then(async (response) => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return await response.json();
  });
};

export const updateSupplierInvoiceInfo = async (
  supplierInvoiceInfoID,
  body
) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/supplier-invoice/${supplierInvoiceInfoID}`;
  return fetch(requestUrl, {
    method: 'PATCH',
    headers: getAuthorizedHeader(),
    body: JSON.stringify(body),
  }).then(async (response) => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return response.json();
  });
};
