import { useEffect, useState } from 'react';
import { isEmptyValue } from '../utils/commonUtils';
import { sendUserFeedback } from '../apis/userFeedbackApi';

export const useUserFeedbackHook = ({ questionList = [], checkoutID }) => {
  const [qAndA, setQAndA] = useState([]);
  const [hasAllQuestionAnswered, setHasAllQuestionAnswered] = useState(false);

  useEffect(() => {
    const qAndA = questionList.map((question) => ({
      ...question,
    }));
    setQAndA(qAndA);
  }, [questionList]);

  const updateAnswer = ({ id, answer }) => {
    const updatedQAndA = qAndA.map((question) =>
      question.id === id ? { ...question, answer } : question
    );
    setQAndA(updatedQAndA);
    setHasAllQuestionAnswered(
      updatedQAndA.every((question) => !isEmptyValue(question.answer))
    );
  };

  const submitUserFeedback = async (
    userID,
    submitLocation = 'First checkout'
  ) => {
    const body = {
      qAndA,
      submitLocation,
      checkoutID,
    };
    return sendUserFeedback(userID, body);
  };

  return [
    {
      qAndA,
      hasAllQuestionAnswered,
    },
    {
      updateAnswer,
      submitUserFeedback,
    },
  ];
};
