import React from 'react';
import { FlexColumn, FlexRow } from '../layouts/FlexLayouts';
import { FtrBoldText, FtrNormalText } from '../ftr-components';
import { dateTzSingapore } from '../../utils/dateTimeUtils';

/**
 * A component that displays information about a request.
 *
 * @param {Object} props - The properties for the component.
 * @param {string|Date} props.requestedAt - The date and time when the request was made.
 * @param {string} props.requestedByName - The name of the person who made the request.
 * @param {string} [props.requestedByID] - The ID of the person who made the request (optional).
 * @param {string} [props.label] - The label to be displayed about the info. (optional)
 * @param {string} [props.fontSize] - The text fontsize (optional)
 * @param {string} [props.justifyContent] - Manage how to the flex item is aligned (optional)
 * @param {React.CSSProperties} [props.style] - Additional styles to apply to the component (optional).
 * @param {Object} [rest] - Any additional properties to pass to the FlexColumn component.
 */
function RequestedCard(props) {
  const {
    label = 'Requested at',
    fontSize = '14',
    justifyContent = 'flex-start',
    requestedAt,
    requestedByName,
    requestedByID,
    style,
    ...rest
  } = props;

  return (
    <FlexColumn style={{ gap: 0, ...style }} {...rest}>
      <FlexRow style={{ gap: 3, justifyContent }}>
        <FtrNormalText fontSize={fontSize}>{label}</FtrNormalText>
        <FtrBoldText fontSize={fontSize}>
          {dateTzSingapore(requestedAt)}
        </FtrBoldText>
      </FlexRow>
      <FtrBoldText fontSize={fontSize}>
        By {requestedByName}
        {requestedByID ? ` (${requestedByID})` : ''}
      </FtrBoldText>
    </FlexColumn>
  );
}

export default RequestedCard;
