import React, { useEffect, useState } from 'react';

import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles/index';

import Title from '../Title';
import BlueButton from '../buttons/BlueButton';
import DifferentValueCompanyAndUser from '../DifferentValueCompanyAndUser';

import { notifyError, notifySuccess } from '../../services/notificationService';

import { updateUserFromCompanyData } from '../../apis/userApi';

import { isEmptyValue } from '../../utils/commonUtils';

const useStyles = makeStyles((theme) => ({
  paper: {
    width: 600,
    minWidth: 200,
    maxWidth: 800,
    borderRadius: 0,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  space: {
    width: theme.spacing(2),
  },
}));

function CheckDifferentValueCompanyAndUserPopup(props) {
  const classes = useStyles();

  const { open = false, onClose, data = [], onSuccess = () => null } = props;

  const [selectedRadioButtons, setSelectedRadioButtons] = useState({});

  useEffect(() => {
    const defaultValues = {};
    data.forEach((el) => {
      let objValues = {};
      el.values.forEach((value) => {
        objValues[value.property] = value.company;
      });
      defaultValues[el.userID] = objValues;
    });
    setSelectedRadioButtons(defaultValues);
  }, [data]);

  const handleUpdateUserData = () => {
    const params = [];
    for (const userID in selectedRadioButtons) {
      params.push({
        ...selectedRadioButtons[userID],
        userID,
      });
    }
    updateUserFromCompanyData(params)
      .then(() => {
        notifySuccess(
          `${params.length > 1 ? 'Users are' : 'User is'} updated successfully`
        );
        onSuccess();
        onClose();
      })
      .catch(() =>
        notifyError(
          `${
            params.length > 1 ? 'Users are' : 'User is'
          } updated unsuccessfully`
        )
      );
  };

  const isAllUserDiffValuesEmpty = data?.every((el) => isEmptyValue(el.values));

  if (!open || isAllUserDiffValuesEmpty) return null;

  console.log(data, 'data');

  return (
    <Dialog
      maxWidth='xl'
      open={open}
      onClose={onClose}
      aria-labelledby='check-different-value-company-and-user'
      classes={{
        paper: classes.paper,
      }}
    >
      <DialogTitle id='check-different-value-company-and-user'>
        <Title
          contentTitle='Check Different Value Company and User'
          size='small'
        />
      </DialogTitle>
      <div
        style={{
          paddingBottom: '1rem',
        }}
      >
        <DialogContent
          style={{
            padding: '0 3rem',
            paddingBottom: '2rem',
          }}
        >
          {data.map((el) => (
            <DifferentValueCompanyAndUser
              key={el.userID}
              selectedRadioButtons={selectedRadioButtons}
              setSelectedRadioButtons={setSelectedRadioButtons}
              userID={el.userID}
              values={el.values}
              name={el.name}
            />
          ))}
          <div
            style={{
              marginTop: '2rem',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <BlueButton
              btnContent='Update User Data'
              onBtnClick={handleUpdateUserData}
            />
          </div>
        </DialogContent>
      </div>
      <IconButton
        aria-label='close'
        className={classes.closeButton}
        onClick={onClose}
      >
        <CloseIcon />
      </IconButton>
    </Dialog>
  );
}

export default CheckDifferentValueCompanyAndUserPopup;
