import React from 'react';
import { IconButton, Tooltip } from '@material-ui/core';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import { useHistory } from 'react-router-dom';

const ShipmentTrackingIcon = () => {
  const history = useHistory();

  return (
    <Tooltip title={'View deliveries calendar'}>
      <IconButton onClick={() => history.push({ pathname: '/calendar' })}>
        <CalendarTodayIcon />
      </IconButton>
    </Tooltip>
  );
};

export default ShipmentTrackingIcon;
