import { ceil, get } from 'lodash';
import React, { useEffect, useReducer, useState } from 'react';
import { connect } from 'react-redux';
import { Link, Redirect, withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';

import { makeStyles } from '@material-ui/core/styles/index';

import { DataGrid } from '@mui/x-data-grid';

import { Icon, IconButton, Tooltip, Typography } from '@material-ui/core';

import { Edit as EditIcon, Info } from '@material-ui/icons';

import HorizontalExpandSpace from '../components/ftr-components/HorizontalExpandSpace';
import { ExportCsvButton } from '../components/grid-data/buttons/ExportCsvButton';
import SearchBar from '../components/grid-data/buttons/SearchBar';
import CustomToolbar, {
  DataGridToolbarLayout,
} from '../components/grid-data/CustomToolbar';
import GridDataPagination from '../components/grid-data/GridDataPagination';
import EmailStatusIcon from '../components/icons/EmailStatusIcon';
import { FlexRow } from '../components/layouts/FlexLayouts';
import AdminQuoteDetailsDisplayPopup from '../components/popups/AdminQuoteDetailsDisplayPopup';
import PoAmountPopup from '../components/popups/PoAmountPopup';
import RejectedReasonPopup from '../components/popups/RejectedReasonPopup';
import YesNoPopup from '../components/popups/YesNoPopup';
import DataGridWrapTextCell from '../components/tables/cells/DataGridWrapTextCell';

import FactCheckIcon from '../assets/icons/fact_check_blue.svg';

import {
  adminExportQuotationsAsCsv,
  adminGetAllQuotations,
  adminUpdateQuotation,
  supplierAcknowledgePO,
  updateReasonQuotations,
} from '../apis/quotationApi';

import { displayEditOrderPage } from '../actions';

import { generateCurrentCustomDateTimeString } from '../util';
import { convertPriceToCurrency } from '../utils/currencyUtils';
import { dateTzSingapore } from '../utils/dateTimeUtils';
import {
  createDownloadFile,
  downloadS3File,
  getPORevisedUploadUrl,
} from '../utils/fileUtils';
import { isSuperAdminRole } from '../utils/roleUtils';

import { notifyError, notifySuccess } from '../services/notificationService';

import {
  deliveryOptionCol,
  partIDColumnWithLink,
  projectIDColumn,
} from '../constants/itemTableConstants';
import {
  originalQuoteColumn,
  platformFeeCol,
  poAcknowledgeColumn,
  purchaseOrderAdminCol,
  repeatOrderInfoCol,
  totalPriceShownToCustomerCol,
} from '../constants/quotationTableConstants';

import { ORDER_STATUS } from '../constants';
import { CURRENCY_CODE } from '../constants/currencyConstants';

import { colors } from '../palette';

// --------------------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  cardDisplay: {
    minWidth: 275,
    variant: 'elevated',
  },
  header: {
    fontSize: 14,
    paddingBottom: theme.spacing(1),
  },
  body: {
    padding: '0 1rem',
    '& .MuiTablePagination-root': {
      marginRight: '4rem',
    },
    marginBottom: '2rem',
    '& .MuiDataGrid-columnHeaderTitle': {
      lineHeight: 'normal',
      wordBreak: 'break-word',
      whiteSpace: 'normal',
      color: colors.blue060,
      fontSize: '11pt',
      fontWeight: 600,
      margin: '0 auto',
      textAlign: 'center',
    },
    '& .MuiDataGrid-columnsContainer': {
      display: 'flex',
      justifyContent: 'center',
    },
    '& .MuiDataGrid-cell': {
      textAlign: 'center',
      justifyContent: 'center !important',
    },
  },
  formControl: {
    marginTop: '10px',
    width: '150px',
    '& .MuiPopover-paper': {
      width: '330px !important',
    },
    '& .MuiSelect-selectMenu': {
      whiteSpace: 'normal',
    },
    '& .MuiSelect-select.MuiSelect-select': {
      fontSize: '12px',
      fontWeight: '600',
    },
  },
  buttonReasonRejected: {
    display: 'flex',
    margin: '0 auto',
    alignItems: 'center',
    width: 'fit-content',
    color: 'black',
    padding: '0.4rem',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: '#00000011',
    },
    backgroundColor: '#00000008',
    borderRadius: 4,
    textTransform: 'uppercase',
    fontSize: '9px',
    lineHeight: '13px',
    whiteSpace: 'nowrap',
  },
  formRejected: {
    '& .MuiTypography-body1': {
      fontSize: '13px',
    },
    '& .PrivateSwitchBase-root-40': {
      padding: '5px',
    },
  },
  listReasonRejected: {
    fontSize: '11px',
    fontWeight: '500',
    lineHeight: 'normal',
    wordBreak: 'break-word',
    whiteSpace: 'normal',
  },
  otherInput: {
    display: 'flex',
    marginLeft: '35px',
  },
  buttonSubmitReasonRejected: {
    '& .makeStyles-submitBtn-44': {
      fontSize: '13px',
      padding: '10px 15px',
    },
  },
  uploadIcon: {
    color: 'gray',
    marginRight: '5px',
  },
  linkButton: {
    color: colors.blue050,
    textDecoration: 'underline',
    fontSize: '16px',
  },
}));

export const DOWNLOAD_PO_LINK = 'download-po-link';

export function AllQuotesPagination(props) {
  const classes = useStyles();

  const { openEditOrder, role } = props;

  const [selectedAcknowledgeQuotation, setSelectedAcknowledgeQuotation] =
    useState(null);
  const [openAcknowledgeQuotationPopup, setOpenAcknowledgeQuotationPopup] =
    useState(false);
  const [showRejectedReasonPopup, setShowRejectedReasonPopup] = useState(false);
  const [selectedQuotation, setSelectedQuotation] = useState(null);
  const [processing, setProcessing] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [openQuoteDetails, setOpenQuoteDetails] = useState(false);
  const [tableQueryParams, updateTableQueryParams] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      page: 0,
      pageSize: 20,
      search: '',
      totalCount: 0,
      status: ORDER_STATUS.ACCEPTED,
    }
  );
  const [poAmountPopup, updatePoAmountPopup] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      show: false,
      data: null,
      poFormURL: '',
    }
  );

  const getTableData = () => {
    setProcessing(true);
    adminGetAllQuotations(tableQueryParams)
      .then((data) => {
        setTableData(data.rows);
        updateTableQueryParams({ totalCount: data.totalCount });
      })
      .finally(() => {
        setProcessing(false);
      });
  };

  let timer;
  useEffect(() => {
    updateTableQueryParams({ loading: true });
    if (!tableQueryParams.search) {
      getTableData();
    } else {
      clearTimeout(timer);
      timer = setTimeout(() => {
        getTableData();
      }, 300);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [
    tableQueryParams.search,
    tableQueryParams.page,
    tableQueryParams.pageSize,
  ]);

  const handleAcknowledgePO = () => {
    const { quotationID } = selectedAcknowledgeQuotation;
    supplierAcknowledgePO(quotationID)
      .then(() => {
        notifySuccess('Acknowledged PO Successfully');
        getTableData();
      })
      .catch(() => notifyError('Acknowledged PO failed'));
  };

  const handlePORevisedUploadCallback = async (
    rowData,
    { files, poFormURL }
  ) => {
    updatePoAmountPopup({ data: rowData, files, poFormURL, show: true });
  };

  const handleRejectedReason = async ({ rejectedReason, quotationIDs }) => {
    updateReasonQuotations({ rejectedReason, quotationIDs }).then(() => {
      getTableData();
      notifySuccess('Reason rejected has been updated successfully');
      setShowRejectedReasonPopup(false);
    });
  };

  const handleSubmitPOAmount = async (poAmount) => {
    const toastId = toast('Uploading file...', {
      type: toast.TYPE.INFO,
      autoClose: false,
    });
    try {
      const poRevisedUrl = await getPORevisedUploadUrl(
        poAmountPopup.files,
        poAmountPopup.poFormURL
      );
      await adminUpdateQuotation(poAmountPopup.data?.quotationID, {
        poAmount,
        combinedPOsForm: poRevisedUrl,
      });
      toast.update(toastId, {
        render: `Revised PO uploaded for all parts awarded to ${poAmountPopup.data?.supplierName} in Project ${poAmountPopup.data?.projectID}.`,
        type: toast.TYPE.SUCCESS,
        autoClose: 3000,
      });
      getTableData();
    } catch (err) {
      toast.update(toastId, {
        render: err?.message || 'Error uploading PO file!',
        type: toast.TYPE.ERROR,
        autoClose: 3000,
      });
    }
  };

  /**
   *
   * @param {String} fileName
   * @param {'Quote' | 'PO'} downloadType
   */
  const handleDownloadCsv = async (fileName, downloadType = 'Quote') => {
    const toastId = toast(`Exporting ${downloadType} CSV...`, {
      type: toast.TYPE.INFO,
      autoClose: false,
    });
    try {
      // Call the API to generate the CSV and get the download URL
      const response = await adminExportQuotationsAsCsv({
        ...tableQueryParams,
        forPO: downloadType === 'PO',
      });
      const blob = await response.blob();
      const downloadUrl = window.URL.createObjectURL(blob);
      createDownloadFile(downloadUrl, {
        fileName: `${fileName} ${generateCurrentCustomDateTimeString()}.csv`,
      });
      toast.update(toastId, {
        render: `Exported ${downloadType} CSV successfully!`,
        type: toast.TYPE.SUCCESS,
        autoClose: 3000,
      });
    } catch (err) {
      toast.update(toastId, {
        render: `Error Exporting ${downloadType} CSV!`,
        type: toast.TYPE.ERROR,
        autoClose: 3000,
      });
    }
  };

  const renderEditButton = (rowData) => {
    if (
      (rowData.status === 'disabled' || rowData.status === 'rejected') &&
      role === 'reviewer'
    ) {
      return (
        <IconButton
          aria-label='edit'
          onClick={() => openEditOrder(rowData.quotationID)}
          disabled
        >
          <EditIcon />
        </IconButton>
      );
    } else {
      return (
        <Tooltip title='Hold Ctrl/Cmd and click to edit in new tab'>
          <IconButton
            aria-label='edit'
            onClick={(e) => {
              e.stopPropagation();
              if (e.ctrlKey || e.metaKey) {
                window.open(`/order/edit/${rowData.quotationID}`);
                return;
              }
              openEditOrder(rowData.quotationID);
            }}
          >
            <EditIcon />
          </IconButton>
        </Tooltip>
      );
    }
  };

  const columns = [
    {
      headerName: ' ',
      renderCell: ({ row: rowData }) => {
        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Tooltip title='View quote details'>
              <IconButton
                edge='end'
                aria-label='delete'
                onClick={() => {
                  setOpenQuoteDetails(true);
                  setSelectedQuotation(rowData);
                }}
              >
                <Info color='primary' style={{ fontSize: '15pt' }} />
              </IconButton>
            </Tooltip>
            {renderEditButton(rowData)}
          </div>
        );
      },
      field: 'actions',
    },
    {
      ...projectIDColumn,
    },
    {
      ...partIDColumnWithLink,
    },
    { headerName: 'Name', field: 'name' },
    {
      headerName: 'Customer',
      field: 'buyerName',
      renderCell: ({ row: rowData }) => (
        <DataGridWrapTextCell
          text={`${rowData.buyerName} (${rowData.buyerID})`}
        />
      ),
      width: 150,
    },
    {
      title: 'Supplier name',
      field: 'supplierName',
      renderCell: ({ row: rowData }) => (
        <DataGridWrapTextCell
          text={`${rowData.supplierName} (${rowData.userID})`}
        />
      ),
      width: 150,
    },
    {
      ...purchaseOrderAdminCol({
        uploadCallback: handlePORevisedUploadCallback,
      }),
    },
    {
      ...poAcknowledgeColumn,
      renderCell: ({ row: rowData }) => {
        const renderYes = (date, nameID) => {
          return (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                lineHeight: 'normal',
              }}
            >
              <span style={{ color: 'green' }}>Yes</span>
              <div
                style={{
                  fontWeight: 'bold',
                  fontSize: '0.75rem',
                  whiteSpace: 'nowrap',
                }}
              >
                {date}
              </div>
              <span
                style={{
                  fontWeight: 'bold',
                  fontSize: '0.75rem',
                  wordBreak: 'break-word',
                  whiteSpace: 'normal',
                  lineHeight: 'normal',
                }}
              >
                {nameID}
              </span>
            </div>
          );
        };
        const renderNoWithAcknowledgeButton = (rowData) => {
          return (
            <div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Typography>
                  <span style={{ color: 'orange' }}>No</span>
                </Typography>
                <Tooltip title='Acknowledge PO'>
                  <IconButton
                    color='primary'
                    onClick={(event) => {
                      setSelectedAcknowledgeQuotation(rowData);
                      setOpenAcknowledgeQuotationPopup(true);
                      event.stopPropagation();
                    }}
                  >
                    <Icon>
                      <img
                        className={classes.imageIcon}
                        src={FactCheckIcon}
                        color='primary'
                        alt=''
                      />
                    </Icon>
                  </IconButton>
                </Tooltip>
              </div>
              <FlexRow>
                {rowData.brevoOrderAcceptance && (
                  <EmailStatusIcon
                    status={rowData.brevoOrderAcceptance?.lastStatus}
                    emailType={rowData.brevoOrderAcceptance?.type}
                    date={rowData.brevoOrderAcceptance?.updatedAt}
                    key='brevoOrderAcceptance'
                  />
                )}
                {rowData.brevoReminderOrderAcceptance && (
                  <EmailStatusIcon
                    status={rowData.brevoReminderOrderAcceptance?.lastStatus}
                    emailType={rowData.brevoReminderOrderAcceptance?.type}
                    date={rowData.brevoReminderOrderAcceptance?.updatedAt}
                    key='brevoReminderOrderAcceptance'
                  />
                )}
              </FlexRow>
            </div>
          );
        };

        if (rowData.poAcknowledged === null) {
          return 'N.A.';
        }
        if (Boolean(rowData.poAcknowledged) === true) {
          let date = '';
          const acknowledgementLog = rowData.acknowledgementLog;
          let nameID = '';
          if (rowData.acknowledgementLog) {
            nameID = `${acknowledgementLog.name} (${acknowledgementLog.userID})`;
          }
          if (rowData.acknowledgedDate) {
            date = dateTzSingapore(rowData.acknowledgedDate);
          }
          return renderYes(date, nameID);
        } else if (Boolean(rowData.poAcknowledged) === false) {
          return renderNoWithAcknowledgeButton(rowData);
        } else {
          return 'N.A.';
        }
      },
      width: 180,
    },
    {
      ...repeatOrderInfoCol,
    },
    {
      ...deliveryOptionCol,
    },
    { headerName: 'Technology', field: 'technology', width: 150 },
    { headerName: 'Qty', field: 'quantity', width: 70 },
    {
      ...originalQuoteColumn,
    },
    {
      headerName: 'Price from supplier (excl. GST) in SGD',
      field: 'priceFromSupplierExclGstInSGD',
      renderCell: ({ row: rowData }) => 'S$' + rowData.priceBidded,
      minWidth: 200,
    },
    {
      headerName: 'GST',
      field: 'gst',
      renderCell: ({ row: rowData }) =>
        'S$' + (rowData.priceBidded * (rowData.gst / 100)).toFixed(2),
    },
    {
      headerName: 'Delivery Fees',
      field: 'deliveryFees',
      renderCell: ({ row: rowData }) => 'S$' + rowData.deliveryFee,
    },
    {
      headerName: 'CMM Price',
      field: 'cmmPrice',
      renderCell: ({ row: rowData }) => {
        return convertPriceToCurrency({
          price: get(rowData, 'metadata.cmmPrice', 0),
          currency: CURRENCY_CODE.SGD,
          exchangeRate: 1,
        });
      },
      minWidth: 120,
    },
    {
      headerName: 'CMM Price for Customer',
      field: 'cmmPriceForCustomer',
      renderCell: ({ row: rowData }) => {
        return convertPriceToCurrency({
          price:
            get(rowData, 'metadata.cmmPriceCustomer') ||
            get(rowData, 'metadata.cmmPrice') ||
            0,
          currency: CURRENCY_CODE.SGD,
          exchangeRate: 1,
        });
      },
      width: 150,
    },
    {
      ...totalPriceShownToCustomerCol,
    },
    {
      ...platformFeeCol,
    },
    {
      headerName: 'PO Amount',
      field: 'poAmount',
      renderCell: ({ row: rowData }) => {
        return rowData.poAmount ?? 'N.A';
      },
      width: 150,
    },
    {
      headerName: 'Lead time (original + markup)',
      field: 'leadTimeOriginalMarkup',
      renderCell: ({ row: rowData }) =>
        `${rowData.leadTime} + ${rowData.markupLeadTime} working days`,
      minWidth: 150,
    },
    {
      headerName: 'Partner name',
      field: 'supplierName',
      renderCell: ({ row: rowData }) => (
        <DataGridWrapTextCell
          text={`${rowData.supplierName} (${rowData.userID})`}
        />
      ),
      minWidth: 150,
    },

    {
      headerName: 'Customer remarks',
      field: 'customerRemarks',
      maxWidth: 280,
      width: 130,
    },
  ];

  const handleSearch = (searchTerm) => {
    updateTableQueryParams({
      page: 0,
      search: searchTerm,
    });
  };

  const getCustomerToolbar = () => {
    const buttonsComponents = [
      <SearchBar
        key='search'
        onSearch={handleSearch}
        searchTerm={tableQueryParams.search}
      />,
      <ExportCsvButton
        key='export-csv'
        handleClick={() => handleDownloadCsv('All Accepted Quotes')}
        show={isSuperAdminRole(role)}
      />,
      <ExportCsvButton
        key='export-po-csv'
        handleClick={() => handleDownloadCsv('All PO Quotes', 'PO')}
        name='Export PO CSV'
        show={isSuperAdminRole(role)}
      />,
    ];

    return (
      <DataGridToolbarLayout>
        <HorizontalExpandSpace />
        <CustomToolbar buttons={buttonsComponents} />
      </DataGridToolbarLayout>
    );
  };

  return role === 'buyer' || role === 'supplier' ? (
    <Redirect
      to={{
        pathname: '/',
        state: { from: props.location },
      }}
    />
  ) : (
    <div className={classes.body}>
      <h1>Manage Accepted Quotes</h1>
      <div style={{ height: '100%', width: '100%' }}>
        <DataGrid
          autoHeight
          paginationMode='server'
          rows={tableData}
          columns={columns.map((col) => ({
            ...col,
            sortable: false,
          }))}
          getRowId={(row) => row.quotationID}
          rowHeight={120}
          headerHeight={80}
          components={{
            Toolbar: getCustomerToolbar,
            Pagination: () => (
              <GridDataPagination
                pageCount={ceil(
                  tableQueryParams.totalCount / tableQueryParams.pageSize
                )}
              />
            ),
          }}
          pageSize={tableQueryParams.pageSize}
          rowsPerPageOptions={[10, 20, 50]}
          onPageSizeChange={(newPageSize) =>
            updateTableQueryParams({ pageSize: newPageSize })
          }
          page={tableQueryParams.page}
          onPageChange={(newPage) => updateTableQueryParams({ page: newPage })}
          rowCount={tableQueryParams.totalCount}
          loading={processing}
          disableRowSelectionOnClick
          disableSelectionOnClick
          disableColumnMenu
        />
      </div>
      {showRejectedReasonPopup && (
        <RejectedReasonPopup
          dialog={showRejectedReasonPopup}
          handleClose={() => setShowRejectedReasonPopup(false)}
          onOk={handleRejectedReason}
          rowData={selectedQuotation}
        />
      )}
      {openQuoteDetails && (
        <AdminQuoteDetailsDisplayPopup
          open={openQuoteDetails}
          rowData={selectedQuotation}
          onClose={() => setOpenQuoteDetails(false)}
        />
      )}
      {poAmountPopup.show && (
        <PoAmountPopup
          open={poAmountPopup.show}
          rowData={poAmountPopup.data}
          onClose={() => updatePoAmountPopup({ show: false })}
          onSubmit={handleSubmitPOAmount}
        />
      )}
      <YesNoPopup
        open={openAcknowledgeQuotationPopup}
        handleYes={() => {
          handleAcknowledgePO();
          setOpenAcknowledgeQuotationPopup(false);
        }}
        handleNo={() => setOpenAcknowledgeQuotationPopup(false)}
        noButtonLabel='Cancel'
        yesButtonLabel='Acknowledge'
        title='Please acknowledge this Purchase Order'
        body={
          selectedAcknowledgeQuotation &&
          selectedAcknowledgeQuotation.purchaseOrderForm ? (
            <div>
              Download PO Link:&nbsp;
              <Link
                onClick={(e) => {
                  e.stopPropagation();
                  downloadS3File(
                    selectedAcknowledgeQuotation.purchaseOrderForm
                  );
                }}
              >
                Download
              </Link>
            </div>
          ) : null
        }
      />
    </div>
  );
}

function mapStateToProps(state) {
  return {
    role: state.auth.user.role,
  };
}

function matchDispatchToProps(dispatch, props) {
  return {
    openEditOrder: (id) => dispatch(displayEditOrderPage(id, props)),
  };
}

const withConnect = connect(mapStateToProps, matchDispatchToProps);

export default withRouter(withConnect(AllQuotesPagination));
