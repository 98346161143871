import React, { useEffect, useState } from 'react';

import { makeStyles } from '@material-ui/core/styles/index';

import { CircularProgress } from '@material-ui/core';
import { getPresignedUrl } from '../../utils/presignedUrlUtils';

import { colors } from '../../palette';

const DEFAULT_URL =
  'https://factorem-s3-bucket.s3-ap-southeast-1.amazonaws.com/Factorem/ImagePlaceholder';

// eslint-disable-next-line no-unused-vars
const useStyles = makeStyles(() => ({
  itemText: {
    '& span, & svg': {
      fontSize: 12,
    },
  },
  fadeContainer: {
    opacity: 0,
    animation: '$fadeIn 800ms ease-in-out forwards',
  },
  '@keyframes fadeIn': {
    from: {
      opacity: 0,
    },
    to: {
      opacity: 1,
    },
  },
}));

function SingleImage(props) {
  // const classes = useStyles();

  const {
    url,
    width = 70,
    height = 70,
    noBorder = false,
    showImagePlaceholder = true,
    borderRadius = 0,
    loading = false,
    style,
    ...restProps
  } = props;

  const imageUrl = url && url.startsWith('http') ? url : DEFAULT_URL;

  const [displayImageUrl, setDisplayImageUrl] = useState(null);

  useEffect(() => {
    getPresignedUrl(imageUrl)
      .then((url) => {
        setDisplayImageUrl(url);
      })
      .catch(() => {
        setDisplayImageUrl(DEFAULT_URL);
      });
  }, [imageUrl]);

  if (displayImageUrl === DEFAULT_URL && !showImagePlaceholder) {
    return null;
  }

  return (
    <div
      style={{
        width,
        height,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: colors.neutral030,
        border: noBorder ? 'none' : `1px solid ${colors.inputBorderBlue}`,
        borderRadius,
        ...style,
      }}
      {...restProps}
    >
      {loading && (
        <CircularProgress
          style={{
            color: colors.neutral040,
          }}
          size={width / 5 + 4}
        />
      )}
      {!loading && (
        <img
          // className={classes.fadeContainer}
          style={{
            width,
            height,
            borderRadius,
            border: noBorder ? 'none' : `1px solid ${colors.inputBorderBlue}`,
            maxWidth: '100%',
            maxHeight: '100%',
            objectFit: 'contain',
          }}
          src={displayImageUrl}
          alt=''
        />
      )}
    </div>
  );
}

export default SingleImage;
