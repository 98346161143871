import React, { useEffect, useState } from 'react';
import { FtrDropdown } from '../../../../components/ftr-components';
import FtrSearchBar from '../../../../components/ftr-components/FtrSearchBar';
import { isEmptyValue } from '../../../../utils/commonUtils';
import useDeviceCheck from '../../../../hooks/useDeviceCheck';
import FtrCheckboxDropdown from '../../../../components/ftr-components/FtrCheckboxDropdown';
import { FlexRow } from '../../../../components/layouts/FlexLayouts';
import {
  DATE_CREATED_SORT_VALUES,
  DEFAULT_FILTER_STATUS,
} from '../../../../constants/partsLibraryConstants';

const FilterProject = (props) => {
  const { style, onChange } = props;
  const [dateCreated, setDateCreated] = useState(null);
  const [statusFilter, setStatusFilter] = useState(DEFAULT_FILTER_STATUS);
  const [searchValue, setSearchValue] = useState('');

  const [{ isMobile }] = useDeviceCheck();

  useEffect(() => {
    onChange({
      sortDateCreated:
        dateCreated === DATE_CREATED_SORT_VALUES.LATEST_TO_OLDEST ||
        isEmptyValue(dateCreated)
          ? 'DESC'
          : 'ASC',
      statusFilter,
      search: searchValue,
    });
  }, [dateCreated, statusFilter, searchValue]);

  return (
    <div
      style={{
        display: 'flex',
        alignItems: isMobile ? 'flex-start' : 'center',
        justifyContent: 'space-between',
        flexDirection: isMobile ? 'column-reverse' : 'row',
        ...style,
      }}
    >
      <FlexRow style={{ gap: '1rem' }}>
        <FtrDropdown
          items={Object.values(DATE_CREATED_SORT_VALUES)}
          handleChange={setDateCreated}
          value={dateCreated ?? 'Date Created'}
        />
        <FtrCheckboxDropdown
          id='ftr-checkbox-dropdown'
          label='Status'
          items={statusFilter}
          handleChange={setStatusFilter}
        />
      </FlexRow>
      <FtrSearchBar
        autoFocus
        onSearch={setSearchValue}
        placeholder='Search parts, IDs and project'
        width={isMobile && '100%'}
        style={{
          margin: isMobile ? '0 0 1rem 0' : 0,
          padding: 0,
        }}
      />
    </div>
  );
};
export default FilterProject;
