import React, { useReducer } from 'react';

import { makeStyles } from '@material-ui/core/styles/index';

import { Container, CircularProgress, Button } from '@material-ui/core';

import ShippingForm, {
  ShipmentInfo,
} from '../../components/forms/shipping-form/ShippingForm';
import { FtrTypography } from '../../components/ftr-components';
import { PickupDetailsForm } from '../../components/forms/shipping-form/PickupDetailsForm';
import { ConsigneeDetailsForm } from '../../components/forms/shipping-form/ConsigneeDetailsForm';
import { FlexColumn } from '../../components/layouts/FlexLayouts';

import {
  createOrderJnt,
  getJntRates,
  getJntWaybill,
} from '../../apis/shipmentApi';

import { isEmptyValue } from '../../utils/commonUtils';
import { convertPriceToCurrencyBeautified } from '../../utils/currencyUtils';

import { colors } from '../../palette';
import { COUNTRY_LIST } from '../../constants/countryConstants';
import { CURRENCY_CODE } from '../../constants/currencyConstants';

// --------------------------------------------------------------------------------------

const useStyles = makeStyles(() => ({
  buttonUpdate: {
    padding: '6px 16px',
    fontSize: '0.8rem',
  },
  editIcon: {
    fontSize: '0.9rem',
    '&:hover': {
      backgroundColor: colors.blue050,
      color: 'white',
      cursor: 'pointer',
      borderRadius: '50%',
    },
    padding: 2,
  },
}));

/**
 * this will support multiple items and quotes
 *
 * @returns
 */
function LocalShippingCalculatorMultipleItems() {
  const classes = useStyles();

  const defaultLocalState = {
    itemID: null,
    weight: null,
    length: null,
    width: null,
    height: null,
    totalWeight: null,
    jntPrice: '',
    isGetJntRatesProcessing: false,
    isSuccessGetJntRates: false,
    isCreateOrderJntProcessing: false,
    isSuccessJntCreateOrderRates: false,
    showConsigneeDetailsForm: false,
    factoremContactName: 'Factorem',
    factoremPhoneNumber: '+65 8925 8615',
    factoremAddress: '81 Ayer Rajah Crescent, #01-54, Singapore',
    factoremPostalCode: '139967',
  };

  const [localState, updateLocalState] = useReducer((prev, next) => {
    return { ...prev, ...next };
  }, defaultLocalState);

  const paramsRates = {
    weight: localState.totalWeight,
    length: localState.length,
    width: localState.width,
    height: localState.height,
  };

  const paramsCreateOrder = {
    ...paramsRates,
    contact_name: localState.factoremContactName,
    factoremPhoneNumber: localState.factoremPhoneNumber,
    factoremAddress: localState.factoremAddress,
    factoremPostalCode: localState.factoremPostalCode,
    supplierContactName: localState.supplierContactName,
    supplierPhoneNumber: localState.supplierPhoneNumber,
    supplierAddress: localState.supplierAddress,
    supplierPostalCode: localState.supplierPostalCode,
  };

  const validateParams = (params) => {
    let isFilled = true;
    for (const state in params) {
      if (isEmptyValue(params[state])) {
        isFilled = false;
      }
    }
    return isFilled;
  };

  const handleGetJNTShipmentFee = () => {
    updateLocalState({
      isGetJntRatesProcessing: true,
      isSuccessGetJntRates: false,
    });
    const body = {
      weight: Number(localState.totalWeight),
      length: Number(localState.length),
      width: Number(localState.width),
      height: Number(localState.height),
      country_code: COUNTRY_LIST[localState.destinationCountry].code,
    };
    getJntRates(body)
      .then((data) => {
        updateLocalState({
          isSuccessGetJntRates: true,
          price: convertPriceToCurrencyBeautified({
            currency: CURRENCY_CODE.SGD,
            price: data.rate,
          }),
        });
      })
      .finally(() => updateLocalState({ isGetJntRatesProcessing: false }));
  };

  const handleGetJntWaybill = (referenceNumber) => {
    updateLocalState({
      isSuccessGetJntWaybillProcessing: true,
      isSuccessGetJntWaybill: false,
    });
    const body = {
      size: 'A6',
      reference_number: referenceNumber,
    };
    getJntWaybill(body)
      .then((data) => {
        updateLocalState({
          isSuccessGetJntWaybill: true,
          waybill_url: data.connote_label_url,
        });
      })
      .finally(() => {
        updateLocalState({ isSuccessGetJntWaybillProcessing: false });
      });
  };

  const handleJntCreateOrder = () => {
    updateLocalState({
      isCreateOrderJntProcessing: true,
      isSuccessCreateOrderJnt: false,
    });
    const body = {
      consignee_details: {
        contact_name: localState.factoremContactName,
        phone_number: localState.factoremPhoneNumber,
        address: localState.factoremAddress,
        postcode: localState.factoremPostalCode,
        country_code: COUNTRY_LIST[localState.destinationCountry].code,
        instructions: localState.consigneeInstructions,
      },
      pickup_details: {
        contact_name: localState.supplierContactName,
        phone_number: localState.supplierPhoneNumber,
        address: localState.supplierAddress,
        postcode: localState.supplierPostalCode,
        instructions: localState.pickupInstructions,
        date: localState.plannedPickupDate,
      },
      item_details: [
        {
          weight: Number(localState.totalWeight),
          length: Number(localState.length),
          width: Number(localState.width),
          height: Number(localState.height),
          weight_unit: 'KG',
        },
      ],
    };

    createOrderJnt(body)
      .then((data) => {
        updateLocalState({
          isSuccessCreateOrderJnt: true,
          reference_number: data.reference_number,
          sorting_code: data.sorting_code,
          tracking_id: data.tracking_id,
        });
        if (data.reference_number) {
          handleGetJntWaybill(data.reference_number);
        }
      })
      .finally(() => updateLocalState({ isCreateOrderJntProcessing: false }));
  };

  const renderOrderDetails = () => {
    if (!localState.isSuccessCreateOrderJnt) {
      return null;
    }
    return (
      <>
        <FtrTypography>
          <FtrTypography>
            Reference Number:{' '}
            <span style={{ color: colors.blue060 }}>
              {localState.reference_number}
            </span>
          </FtrTypography>
        </FtrTypography>
        {localState.waybill_url && (
          <FtrTypography>
            Waybill URL:{' '}
            <a href={localState.waybill_url} target='_blank' rel='noreferrer'>
              {localState.waybill_url}
            </a>
          </FtrTypography>
        )}
      </>
    );
  };

  return (
    <Container>
      <div>
        <ShippingForm
          onChangeState={(objStates) => updateLocalState(objStates)}
          isLocal
          initialValues={defaultLocalState}
        />
      </div>
      <div style={{ marginTop: '1rem' }}>
        <Button
          variant='contained'
          color='primary'
          fullWidth
          onClick={() => {
            handleGetJNTShipmentFee();
          }}
          disabled={
            localState.isGetJntRatesProcessing || !validateParams(paramsRates)
          }
        >
          {localState.isGetJntRatesProcessing && (
            <CircularProgress className={classes.circularProgress} size={20} />
          )}
          &nbsp;Get shipment fee
        </Button>
      </div>
      <div style={{ margin: '1rem 0 1rem' }}>
        <ShipmentInfo
          price={localState.price}
          service='Next Day Express Delivery'
          title='J&T Shipment'
          isLoading={localState.isGetJntRatesProcessing}
          isSuccess={localState.isSuccessGetJntRates}
        />
      </div>
      {localState.isSuccessGetJntRates && (
        <FlexColumn>
          <PickupDetailsForm
            onChangeState={updateLocalState}
            initialValues={{
              supplierID: localState.supplierID,
              supplierAddress: localState.supplierAddress,
              supplierPostalCode: localState.supplierPostalCode,
            }}
          />
          <ConsigneeDetailsForm
            onChangeState={updateLocalState}
            initialValues={{
              factoremContactName: localState.factoremContactName,
              factoremPhoneNumber: localState.factoremPhoneNumber,
              factoremAddress: localState.factoremAddress,
              factoremPostalCode: localState.factoremPostalCode,
            }}
            title='Delivery Details'
          />
          <Button
            variant='contained'
            color='primary'
            fullWidth
            onClick={() => {
              handleJntCreateOrder();
            }}
            disabled={
              localState.isGetJntRatesProcessing ||
              !validateParams(paramsCreateOrder)
            }
          >
            {localState.isCreateOrderJntProcessing && (
              <CircularProgress
                className={classes.circularProgress}
                size={20}
              />
            )}
            &nbsp;Create Order
          </Button>
          {renderOrderDetails()}
        </FlexColumn>
      )}
    </Container>
  );
}

export default LocalShippingCalculatorMultipleItems;
