// Import settings
import React, { useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { DatePicker } from '@material-ui/pickers';

// Import material UI components
import { FtrTypography, FtrButton } from '../ftr-components';

import { upsertLeadTimeFeedback } from '../../apis/ppeFeedbackApi';

import { updateExpectedLeadTimeForPart } from '../../actions';

import { colors } from '../../palette';

import {
  Box,
  Dialog,
  DialogContent,
  withStyles,
  IconButton,
} from '@material-ui/core';

import { Close as CloseIcon } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles/index';

import { FEEDBACK_STATUS } from '../../constants/feedbackConstants';
import { addBusinessDays, isSameDate } from '../../utils/dateTimeUtils';

// Style the components
const useStyles = makeStyles((theme) => ({
  paper: {
    borderRadius: '30px',
    [theme.breakpoints.down('sm')]: {
      borderRadius: '0',
    },
    width: 600,
    minWidth: 200,
    maxWidth: 800,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  space: {
    width: theme.spacing(2),
  },
}));

const FieldLabel = withStyles({
  root: {
    padding: '0.3rem 0',
    color: colors.neutral070,
  },
})((props) => <FtrTypography type='heading' fontSize='16' {...props} />);

const renderTargetLeadTimeField = (leadTime, setLeadTime) => {
  return (
    <>
      <FtrTypography type='heading' fontSize='22'>
        Target Delivery Date
      </FtrTypography>
      <FieldLabel style={{ marginTop: '3rem' }}>
        Target Delivery Date
      </FieldLabel>
      <DatePicker
        InputProps={{
          style: {
            borderRadius: '10px',
          },
        }}
        disableToolbar
        value={leadTime}
        onChange={(expectedLeadTime) => {
          setLeadTime(expectedLeadTime);
        }}
        animateYearScrolling
        inputVariant='outlined'
        margin='dense'
        fullWidth
        clearable
        clearLabel='No Preference'
        emptyLabel='No Preference'
        disablePast
      />
    </>
  );
};

function LeadTimeFeedbackPopup(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const userID = useSelector((state) => state.auth.user.userID);
  const {
    dialog,
    handleClose,
    items,
    setFeedback,
    maxLeadTime,
    minLeadTime,
  } = props;
  const initialLeadTime = addBusinessDays(new Date(), minLeadTime);
  const [leadTime, setLeadTime] = useState(initialLeadTime);

  return (
    <Dialog
      maxWidth='xl'
      open={dialog}
      onClose={handleClose}
      aria-labelledby='confirmation-dialog-title'
      classes={{ paper: classes.paper }}
    >
      <div style={{ padding: '35px 0 20px' }}>
        <DialogContent style={{ padding: '0 30px' }}>
          <React.Fragment>
            {renderTargetLeadTimeField(leadTime, setLeadTime)}
          </React.Fragment>
        </DialogContent>
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
            marginTop: 10,
            position: 'relative',
          }}
        >
          <div style={{ flexGrow: 1, maxWidth: '25%' }}></div> {/* Spacer */}
          <FtrButton
            onClick={handleClose}
            size='medium'
            variant='text'
            style={{ marginLeft: 'auto' }}
          >
            Cancel
          </FtrButton>
          <FtrButton
            color='blue'
            size='medium'
            onClick={() => {
              for (let item of items) {
                dispatch(
                  updateExpectedLeadTimeForPart({
                    id: item.id,
                    expectedLeadTime: leadTime,
                  })
                );

                upsertLeadTimeFeedback({
                  iqLogsID: item.ppePriceLogId,
                  userID: userID,
                  leadTimeFeedback: FEEDBACK_STATUS.BAD,
                  targetLeadTime: leadTime,
                  givenLeadTime: maxLeadTime,
                });
              }
              setFeedback(null);
              handleClose();
            }}
            style={{ marginLeft: '10px', marginRight: '10px' }} // Adjust spacing between buttons if needed
            disabled={isSameDate(leadTime, initialLeadTime)}
          >
            Save
          </FtrButton>
          <Box className={classes.space}></Box>
        </div>
      </div>
      <IconButton
        aria-label='close'
        className={classes.closeButton}
        onClick={handleClose}
      >
        <CloseIcon />
      </IconButton>
    </Dialog>
  );
}

export default LeadTimeFeedbackPopup;
