import React from 'react';

import { Dialog, DialogContent, makeStyles } from '@material-ui/core';

import FtrButton from './FtrButton';
import { FtrH6, FtrLightText } from './FtrTypography';
import { FlexColumnCenter, FlexRow } from '../layouts/FlexLayouts';

import { colors } from '../../palette';

// -------------------------------------------------------------------------------------------------

const useStyles = makeStyles(() => ({
  backdrop: {
    backdropFilter: 'blur(3px)',
  },
  dialog: {
    padding: '1.5rem',
    borderRadius: '1.5rem',
  },
  title: {
    display: 'flex',
    fontSize: '22px',
    fontWeight: '700',
    alignItems: 'center',
    justifyContent: 'center',
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  closeIcon: {
    position: 'absolute',
    right: '1.5rem',
    top: '1.5rem',
    color: colors.neutral060,
    width: '1.5rem',
    height: '1.5rem',
    cursor: 'pointer',
  },
}));

function FtrDeletePopup(props) {
  const classes = useStyles();

  const {
    open,
    title = 'Title',
    subtitle = 'Subtitle',
    onConfirm = () => {},
    onClose = () => {},
    type = 'red-button', // 'red-button' | 'blue-button'
  } = props;

  const renderRedButtonStyle = () => {
    return (
      <FlexColumnCenter
        style={{
          gap: '1rem',
          marginTop: '2rem',
          maxWidth: 200,
          width: '100%',
          alignSelf: 'center',
        }}
      >
        <FtrButton
          id='proceed-delete-btn'
          color='blue'
          variant='contained'
          onClick={(e) => {
            onConfirm(e);
            onClose();
          }}
          autoFocus
          fullWidth
          style={{
            backgroundColor: colors.red030,
          }}
        >
          Delete
        </FtrButton>
        <FtrButton variant='text-black' onClick={onClose}>
          Cancel
        </FtrButton>
      </FlexColumnCenter>
    );
  };

  const renderBlueButtonStyle = () => {
    return (
      <FlexRow
        style={{
          gap: '1rem',
          marginTop: '2rem',
          maxWidth: 200,
          width: '100%',
          alignSelf: 'center',
        }}
      >
        <FtrButton variant='text-black' onClick={onClose}>
          Cancel
        </FtrButton>
        <FtrButton
          id='proceed-delete-btn'
          color='blue'
          variant='contained'
          onClick={(e) => {
            onConfirm(e);
            onClose();
          }}
          autoFocus
        >
          Delete
        </FtrButton>
      </FlexRow>
    );
  };

  const renderDialogActions = () => {
    switch (type) {
      case 'red-button':
        return renderRedButtonStyle();
      case 'blue-button':
        return renderBlueButtonStyle();
    }
    return null;
  };

  return (
    <Dialog
      open={open}
      width='md'
      maxWidth='xl'
      scroll='body'
      BackdropProps={{
        classes: {
          root: classes.backdrop,
        },
      }}
      PaperProps={{ className: classes.dialog }}
      onClose={onClose}
    >
      <DialogContent className={classes.dialogContent}>
        <FtrH6 style={{ marginBottom: '1rem' }}>{title}</FtrH6>
        {subtitle && <FtrLightText>{subtitle}</FtrLightText>}
        {renderDialogActions()}
      </DialogContent>
    </Dialog>
  );
}

export default FtrDeletePopup;
