import React, { useState } from 'react';
import { makeStyles, useMediaQuery, useTheme } from '@material-ui/core';

import { FtrTypography } from '../ftr-components';

import TechnicalDrawingFileIcon from '../../assets/icons/technical_drawing_file_icon.svg';
import DeleteDrawingIcon from '../../assets/icons/delete_drawing.svg';

import { colors } from '../../palette';

import { isEmptyValue } from '../../utils/commonUtils';
import { getFileNameFromCadFile } from '../../utils/itemUtils';

const useStyles = makeStyles(() => ({
  fileName: {
    color: colors.neutral070,
    wordBreak: 'break-all',
    paddingRight: '1rem',
  },
}));

function TechnicalFileNameDisplay(props) {
  const { file, onDelete = () => {} } = props;

  const classes = useStyles();

  const [hovered, setHoveredRow] = useState(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  const fileName = !isEmptyValue(file)
    ? getFileNameFromCadFile(file)
    : 'No technical drawing uploaded';

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        gap: '0.5rem',
        flexGrow: '1 1 auto',
        width: '100%',
        paddingRight: '0.5rem',
      }}
      onMouseEnter={() => setHoveredRow(true)}
      onMouseLeave={() => {
        setHoveredRow(false);
      }}
    >
      <img src={TechnicalDrawingFileIcon} alt='file-icon' />
      <FtrTypography type='heading' fontSize='16' className={classes.fileName}>
        {fileName}
      </FtrTypography>
      {(hovered || isMobile) && !isEmptyValue(file) && (
        <img
          src={DeleteDrawingIcon}
          alt='delete'
          style={{ cursor: 'pointer' }}
          onClick={() => {
            onDelete(file);
          }}
        />
      )}
    </div>
  );
}

export default TechnicalFileNameDisplay;
