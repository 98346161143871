/* eslint-disable react/prop-types */
import React, { Fragment, useEffect, useState } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { useQuery } from 'react-query';
import { toast } from 'react-toastify';
import clsx from 'clsx';
import { isEmpty, sample } from 'lodash';

import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { colors } from '../../palette';

import {
  insertUserIndustryCustomerType,
  openPartSummaryPage,
  openPopupCadRenderer,
  resetPartSummaryDetails,
  savePartSummaryDetails,
  saveProject,
  update2DImageUrl,
  urlFileCadRender,
} from '../../actions';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Backdrop,
  Button,
  Box,
  CircularProgress,
  Checkbox,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  TextField,
  Tooltip,
  Typography,
  Divider,
} from '@material-ui/core';

import {
  Add as AddIcon,
  AddBox as AddBoxIcon,
  DoneOutlined as DoneOutlinedIcon,
  EditOutlined as EditOutlinedIcon,
  Warning as WarningIcon,
  ExpandMore as ExpandMoreIcon,
  ArrowUpward as ArrowUpwardIcon,
  ArrowDownward as ArrowDownwardIcon,
} from '@material-ui/icons';
import MuiTableCell from '@material-ui/core/TableCell';

import { makeStyles, withStyles } from '@material-ui/core/styles';

import SolidBlueButton from '../../components/buttons/SolidBlueButton';
import EditPartForm from '../../components/forms/EditPartForm';
import UploadPartsStepper from '../../components/labels/UploadPartsStepper';

import {
  TECHNOLOGY_OPTION_TYPE,
  THREE_D_P_TECH_ABBRE_MAPPING,
} from '../../constants/NewPartConstants';

import SetsInput from '../../components/inputs/SetsInput';
import PriceTooHighPopup from '../../components/popups/PriceTooHighPopup';
import PriceTooLowPopup from '../../components/popups/PriceTooLowPopup';
import BulkPricingPopup from '../../components/popups/BulkPricingPopup';
import { TWO_D_IMAGE_URLS_KEY } from '../../constants';
import InfoIcon from '../../components/icons/InfoIcon';
import SingleImage from '../../components/images/SingleImage';
import {
  displayLeadTime,
  getFileNameFromCadFile,
  getMaterialWithColorText,
  getSurfaceFinishWithCustomizationsText,
} from '../../utils/itemUtils';
import { convertPriceWithQuantityToCurrency } from '../../utils/currencyUtils';
import QualityCertificationsPopup from '../../components/popups/QualityCertificationsPopup';
import SignUpForQuotePopup from '../../components/popups/SignUpForQuotePopup';
import VerifyEmailPopup from '../../components/popups/VerifyEmailPopup';
import HearAboutUsPopup from '../../components/popups/HearAboutUsPopup';
import {
  QC_FORMAL_CMM_REPORT,
  QC_NO_QC_REPORT,
  // QC_STANDARD_QC_REPORT,
} from '../../constants/projectConstants';
import QcReportsDisplay from '../../components/info/QcReportsDisplay';
import WarningPopup from '../../components/popups/WarningPopup';
import { isCustomTechnology } from '../../utils/inputUtils';
import { notifyError } from '../../services/notificationService';
import MultiplePartsSummaryClarificationPopup from '../../components/popups/MultiplePartsSummaryClarificationPopup';
import {
  getPartsNeedClarify,
  showUpload2DFileText,
  validateSelectedItems,
} from '../../utils/partUploadUtils';
import { COUNTRY_NAMES } from '../../constants/countryConstants';
import { SUBMITTING_ORDER_MESSAGE_OPTIONS_ARRAY } from '../../constants/messageConstants';
import { FE_FEATURE_FLAGS_CONFIGURATION } from '../../constants/configurations';
import { getFeatureFlags } from '../../apis/configurationApi';
import { getCadFileTo3DRenderer } from '../../utils/fileUtils';
import { showUnitValueFromMetric } from '../../utils/userUtils';
import { isAdminOrHigherRole } from '../../utils/roleUtils';
import { updateUserIDForInstantLogs } from '../../apis/instantQuotesLogsApi';
import { isEmptyValue, sleep } from '../../utils/commonUtils';

import MultiplePartSummaryFormTour from '../../components/tour/MultiplePartSummaryFormTour';
import { createProjectGroup } from '../../apis/projectApi';

// Style components
const useStyles = makeStyles((theme) => ({
  bulkPricing: {
    color: colors.uploadFileBorder,
    fontSize: '9pt',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: colors.fontWhite,
  },
  backdropContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  columnTitle: {
    fontSize: '13pt',
    fontWeight: '600',
  },
  deleteIcon: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: '100%',
  },
  formComponent: {
    height: '100%',
    padding: '1rem 1.5rem',
  },
  pageButtonComponent: {
    display: 'flex',
  },
  summaryItem: {
    height: 100,
  },
  summaryItemLeft: {
    display: 'flex',
  },
  summaryRightComponent: {
    display: 'flex',
    height: '100%',
  },
  summaryItemRight: {
    width: '100%',
    fontSize: '9pt',
    paddingLeft: '0.5rem',
    paddingRight: '0.5rem',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    color: colors.fontLightGrey,
    height: '100%',
    borderLeft: `1px solid ${colors.bgLightGrey}`,
  },
  summaryItemRightBottom: {
    display: 'flex',
    width: 'auto',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    color: colors.fontBlackGrey,
    fontSize: '11pt',
  },
  manualRFQ: {
    textTransform: 'uppercase',
    display: 'flex',
    fontSize: '9pt',
  },
  manualRFQBox: {
    display: 'flex',
    justifyContent: 'flex-start',
    marginTop: '20px',
    fontSize: 14,
    width: 'fit-content',
  },
  summaryItemDetails: {
    display: 'flex',
  },
  summaryItemDetailsSection: {
    width: 250,
    color: colors.fontLightGrey,
    marginRight: '0.5rem',
  },
  summaryItemAttachmentsSection: {
    width: 150,
    color: colors.fontLightGrey,
    marginRight: '0.5rem',
  },
  summaryFileDetailsSection: {
    width: 275,
    color: colors.fontLightGrey,
  },
  summaryItemName: {
    marginBottom: 10,
    fontWeight: '600',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  summaryText: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  editDetailsLink: {
    color: colors.solidBlue,
    display: 'flex',
    marginLeft: 20,
    textDecoration: 'none',
  },
  editDetailsLinkText: {
    marginLeft: 7,
  },
  deleteButtonWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  addButtonWrapper: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  fileWarning: {
    fontSize: '9pt',
    color: colors.errorRed,
    display: 'flex',
  },
  projectLabel: {
    marginBottom: '20px',
    display: 'flex',
    alignItems: 'center',
  },
  projectText: {
    fontSize: '12pt',
    fontWeight: '600',
    marginRight: '10px',
  },
  textField: {
    color: colors.fontBlack,
  },
  addDetailsLink: {
    color: colors.solidBlue,
    display: 'flex',
    textDecoration: 'none',
  },
  expandedPane: {
    '&.MuiAccordionDetails-root': {
      display: 'block',
      padding: '0px 48px 0px 60px',
    },
  },
  checkbox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    verticalAlign: 'center',
    height: '100%',
  },
  fileItem: {
    height: 22,
    color: colors.uploadFileBorder,
    paddingLeft: 0,
    '& span': {
      fontSize: '11pt',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
  },
  accordionSummary: {
    padding: '0 8px',
    '&.Mui-expanded': {
      minHeight: 0,
    },
    '& .MuiAccordionSummary-content.Mui-expanded': {
      margin: '12px 0',
    },
    flexDirection: 'row-reverse',
  },
  itemListTitle: {
    fontSize: '14pt',
    fontWeight: '600',
    marginRight: '10px',
  },
  itemListContainer: {
    margin: '10px 0px',
    paddingLeft: 10,
  },
  priceContainer: {
    padding: '10px',
  },
  priceContainerText: {
    fontSize: '9px',
    color: 'red',
  },
  proceedText: {
    fontStyle: 'italic',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    fontSize: '0.8rem',
    width: 210,
  },
  tableCellHeader: {
    paddingBottom: 0,
  },
  tableCellBody: {
    padding: 0,
  },
  twoDImageContainer: {
    width: 100,
    minWidth: 100,
    height: 100,
    minHeight: 100,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  expandIcon: {
    position: 'absolute',
    bottom: 0,
    left: '50%',
    background: colors.buttonColorBlueGradient,
    borderRadius: '50%',
    '& > path': {
      fill: colors.fontWhite,
    },
  },
  expanded: {
    cursor: 'default',
    '& .MuiAccordionSummary-content': {
      cursor: 'default',
    },
  },
  collapsed: {
    cursor: 'pointer',
  },
  render3dCAD: {
    display: 'none',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    color: 'white',
    fontSize: '0.875rem',
  },
  renderImage: {
    cursor: 'pointer',
    marginRight: '1rem',
    position: 'relative',
    '&:hover': {
      '& img': {
        filter: 'brightness(0.8)',
      },
      '& $render3dCAD': {
        display: 'block',
      },
    },
  },
}));

const MyAccordion = withStyles({
  root: {
    border: `1px solid ${colors.bgLightGrey}`,
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&$expanded': {
      margin: 0,
    },
  },
  expanded: {},
})(Accordion);

const TableCell = withStyles({
  root: {
    borderBottom: 'none',
    fontWeight: 'bold',
    padding: 5,
  },
})(MuiTableCell);

const TableCellGrayText = withStyles({
  root: {
    color: colors.fontLightGrey,
  },
})(TableCell);

const LeadTimeHeader = withStyles({
  root: {
    color: colors.fontLightGrey,
    minWidth: 100,
  },
})(TableCell);

/**
 * @deprecated
 * @param {*} props
 * @returns
 */
const MultiplePartSummaryForm = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const {
    data: featureFlags,
    isSuccess: isSuccessFetchFeatureFlags,
  } = useQuery('getAllFeatureFlags', () => getFeatureFlags());

  const {
    signupSuccess,
    verifyEmailSuccess,
    questionAnswered,
    insertIndustryCustomerType,
    formDataAvailable,
    userId,
    industrySector,
    customerType,
    updateProject,
    twoDImagesMapping,
    currency,
    exchangeRate,
    country,
    role,
  } = props;

  formDataAvailable.map((x) => (x['checked'] = true));
  const [itemState, setItemState] = useState(formDataAvailable);
  const [itemsFull, setItemsFull] = useState(
    itemState.filter((obj) => obj.checked).length >= 15
  );
  const [currentProjectName, setCurrentProjectName] = useState(null);
  const [selectedIndustry, setIndustry] = useState(
    industrySector ? industrySector : ''
  );
  const [selectedCustomerType, setCustomerType] = useState(
    customerType ? customerType : ''
  );
  const [onEditProject, setOnEditProject] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [priceTooHighDialog, setPriceTooHighDialog] = useState(false);
  const [priceTooLowDialog, setPriceTooLowDialog] = useState(false);
  const [bulkPricingDialog, setBulkPricingDialog] = useState(false);
  const [
    currentLowPriceItemSelected,
    setCurrentLowPriceItemSelected,
  ] = useState(null);
  const [
    currentBulkPricingItemSelected,
    setCurrentBulkPricingItemSelected,
  ] = useState(null);
  const [popUpOpen, setPopUpOpen] = useState(false);
  const [partsNeedClarify, setPartsNeedClarify] = useState([]);
  const [openQualityPopup, setOpenQualityPopup] = useState(false);
  const [qcReports, setQcReports] = useState({
    main: QC_NO_QC_REPORT,
    addOns: [],
    others: [],
  });
  const [showWarningPopup, setShowWarningPopup] = useState(false);
  const [saveItemOnCMMReport, setSaveItemOnCMMReport] = useState(false);
  const itemRef = React.useRef();

  useEffect(() => {
    return () => {
      dispatch(resetPartSummaryDetails());
    };
  }, []);

  useEffect(() => {
    setTimeout(() => {
      itemRef.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest',
      });
    }, 200);
  }, [itemRef, expanded]);

  useEffect(() => {
    setItemState(formDataAvailable);
  }, [formDataAvailable]);

  useEffect(() => {
    setItemsFull(itemState.filter((obj) => obj.checked).length >= 15);
  }, [itemState]);

  useEffect(() => {
    for (const item of itemState) {
      const originalS3Key = item.cadPart[0];
      let twoDImageUrl = twoDImagesMapping[originalS3Key];
      if (twoDImageUrl) {
        dispatch(update2DImageUrl({ originalS3Key, twoDImageUrl }));
      }
    }
  }, [twoDImagesMapping]);

  useEffect(() => {
    if (userId && !isEmptyValue(formDataAvailable)) {
      const instantLogIDs = formDataAvailable.map((item) => item.ppePriceLogId);
      const body = {
        userID: userId,
        instantLogIDs,
      };
      updateUserIDForInstantLogs(body);
    }
  }, [userId]);

  const handlePopupOpen = () => {
    setPopUpOpen(true);
  };

  const handlePopupClose = () => {
    setPopUpOpen(false);
  };

  const itemDetailCheck = (items) => {
    return items.reduce((acc, currentItem) => {
      return acc && currentItem.name !== '' && currentItem.qty > 0;
    }, true);
  };

  const onSameFactoryCheckboxChange = (itemID, checkValue) => {
    const _partsNeedClarify = partsNeedClarify.map((item) => {
      if (item.itemID !== itemID) {
        return item;
      }
      return {
        ...item,
        requestSameFabricator: checkValue,
      };
    });
    setPartsNeedClarify(_partsNeedClarify);
  };

  const handleSubmitForm = () => {
    if (expanded !== false) {
      setShowWarningPopup(true);
      return;
    }

    const selectedItems = itemState.filter((obj) => obj.checked);
    const error = validateSelectedItems(selectedItems);
    if (!isEmpty(error)) {
      notifyError(`One or more items are ${error}`);
      return;
    }

    if (!popUpOpen) {
      setPartsNeedClarify([]);
      const _partsNeedClarify = getPartsNeedClarify(selectedItems);
      if (_partsNeedClarify.length) {
        setPartsNeedClarify(_partsNeedClarify);
        handlePopupOpen();
        return;
      }

      if (!industrySector || !customerType) {
        handlePopupOpen();
        return;
      }
    }

    handlePopupClose();
    setLoading(true);
    let payload = {
      projectName: currentProjectName,
      projectStatus: 'Open',
      items: selectedItems.map((obj) => {
        const partClarified = partsNeedClarify.find(
          (item) => item.itemID === obj.id
        );
        const requestSameFabricator = partClarified
          ? partClarified.requestSameFabricator
          : undefined;
        return {
          ...obj,
          expectedLeadTime: obj.expectedLeadTime ? obj.expectedLeadTime : null,
          requestSameFabricator,
          userID: userId,
        };
      }),
      userID: userId,
      qcReports,
    };
    if (!itemDetailCheck(payload.items)) {
      setLoading(false);
      toast.error(
        'Some items have missing details. Please fill in the necessary fields and try again.',
        {
          position: toast.POSITION.TOP_RIGHT,
        }
      );
    } else {
      try {
        if (!industrySector || !customerType) {
          insertIndustryCustomerType({
            userID: userId,
            industrySector: selectedIndustry,
            customerType: selectedCustomerType,
          });
        }

        createProjectGroup(payload)
          .then(async (data) => {
            if (isAdminOrHigherRole(role)) {
              // wait for 2 seconds with each item. To make sure all processes completed in the background
              await sleep(2 * selectedItems?.length);
            }
            setLoading(false);
            updateProject({ projectName: '', projectStatus: '' });
            // show success page
            props.history.push({
              pathname: '/success',
              state: { projectID: data.projectID },
            });
          })
          .catch(() => {
            notifyError(
              'There was an error. Please fill in the necessary fields and try again.'
            );
          });
      } catch (err) {
        setLoading(false);
        notifyError(
          'There was an error confirming your order. Please contact Factorem support team.'
        );
      }
    }
  };

  const handleOnClickChecked = (event) => {
    event.stopPropagation();
  };

  const handleChecked = (event) => {
    // Retrieve index
    let index = Number(event.target.name);

    // 1. Make a shallow copy of the items
    let items = [...itemState];

    // 2. Make a shallow copy of the item you want to mutate
    let item = { ...items[index] };

    // 3. Replace the property you're interested in
    item.checked = event.target.checked;

    // 4. Put it back into our array. N.B. we *are* mutating the array here, but that's why we made a copy first
    items[index] = item;

    // 5. Set the state to our new copy by replacing the entire array
    setItemState(items);
  };

  const handleProjectNameOnChange = (event) => {
    setCurrentProjectName(event.target.value);
  };

  const handleEditButtonOnClick = () => {
    setOnEditProject(true);
  };

  const handleDoneButtonOnClick = () => {
    updateProject({ projectName: currentProjectName, projectStatus: 'Open' });
    setOnEditProject(false);
  };

  const handlePanelChange = (panel) => (event, isExpanded) => {
    if (expanded !== false) {
      return;
    }
    setExpanded(isExpanded ? panel : false);
  };

  const handleSaveEditedForm = (editedPart) => {
    // Save the new item and close the edit panel
    let newItemState = JSON.parse(JSON.stringify(itemState));
    let newEditedPart = JSON.parse(JSON.stringify(editedPart));
    if (
      qcReports.main === QC_FORMAL_CMM_REPORT &&
      newEditedPart.price !== null
    ) {
      newEditedPart = {
        ...newEditedPart,
        initialPrice: newEditedPart.price,
        initialMarkupPercent: newEditedPart.markupPercent,
        initialTotalPrice: newEditedPart.totalPrice,
        initialOriginalPrice: newEditedPart.originalPrice,
        price: null,
        markupPercent: null,
        totalPrice: null,
        originalPrice: null,
      };
    }
    newItemState[newEditedPart.id] = newEditedPart;
    setItemState([...newItemState]);
    setExpanded(false);
    dispatch(savePartSummaryDetails(newItemState)); // update redux form state
    // notifyInfo(`Success`);
  };

  const handleCancelEditPartForm = () => {
    setExpanded(false);
  };

  const handleBulkPricingClick = (event, item) => {
    event.preventDefault();
    event.stopPropagation();
    setCurrentBulkPricingItemSelected(item);
    setBulkPricingDialog(true);
  };

  const handlePriceTooHighButtonClick = (event, item) => {
    event.preventDefault();
    event.stopPropagation();
    setCurrentLowPriceItemSelected(item);
    setPriceTooHighDialog(true);
  };

  const handlePriceTooLowButtonClick = (event, item) => {
    event.preventDefault();
    event.stopPropagation();
    setCurrentLowPriceItemSelected(item);
    setPriceTooLowDialog(true);
  };

  const handleClosePriceTooHighDialog = () => {
    setPriceTooHighDialog(false);
  };

  const handleClosePriceTooLowDialog = () => {
    setPriceTooLowDialog(false);
  };

  const handleCloseBulkPricingDialog = () => {
    setBulkPricingDialog(false);
  };

  const handleSwitchToManual = () => {
    if (currentLowPriceItemSelected) {
      currentLowPriceItemSelected.price = null;
      currentLowPriceItemSelected.markupPercent = null;
      currentLowPriceItemSelected.totalPrice = null;
      currentLowPriceItemSelected.originalPrice = null;
    }
    setPriceTooLowDialog(false);
    setPriceTooHighDialog(false);
  };

  const handleSelectQtyClick = (updatedPart) => {
    let newItemState = JSON.parse(JSON.stringify(itemState));
    let newUpdatedPart = JSON.parse(JSON.stringify(updatedPart));
    if (
      qcReports.main === QC_FORMAL_CMM_REPORT &&
      newUpdatedPart.price !== null
    ) {
      newUpdatedPart = {
        ...newUpdatedPart,
        initialPrice: newUpdatedPart.price,
        initialMarkupPercent: newUpdatedPart.markupPercent,
        initialTotalPrice: newUpdatedPart.totalPrice,
        initialOriginalPrice: newUpdatedPart.originalPrice,
        price: null,
        markupPercent: null,
        totalPrice: null,
        originalPrice: null,
      };
    }
    newItemState[newUpdatedPart.id] = newUpdatedPart;
    setItemState([...newItemState]);
    setBulkPricingDialog(false);
  };

  const submitUpdatedItemState = (newItemState) => {
    setItemState([...newItemState]);
    setExpanded(false);
  };

  const getLoadingMessage = () => {
    const message = sample(SUBMITTING_ORDER_MESSAGE_OPTIONS_ARRAY);
    return message;
  };

  const getItemTechnologyText = (item) => {
    if (isCustomTechnology(item.technology)) {
      return item.otherTechnology;
    }
    if (item.technology !== TECHNOLOGY_OPTION_TYPE.THREE_D_PRINTING) {
      return item.technology;
    }
    const abbr = THREE_D_P_TECH_ABBRE_MAPPING[item.threeDTechnology];
    return `${item.technology}: ${abbr}`;
  };

  const renderSignUpPopups = () => {
    const delayDuration = 1750; // delay in milliseconds
    return (
      <Fragment>
        <SignUpForQuotePopup open={!signupSuccess && !userId} />
        <VerifyEmailPopup
          delay={delayDuration}
          open={signupSuccess && !verifyEmailSuccess && !userId}
        />
        <HearAboutUsPopup
          delay={delayDuration}
          open={
            signupSuccess &&
            verifyEmailSuccess &&
            (!userId || !questionAnswered)
          }
        />
      </Fragment>
    );
  };

  const renderMaterialWithColorText = (item) => {
    const colorMaterialText = getMaterialWithColorText(item);
    return (
      <Tooltip title={colorMaterialText}>
        <div className={classes.summaryText}>{colorMaterialText}</div>
      </Tooltip>
    );
  };

  const renderColorSurfaceFinishText = (item) => {
    const colorSurfaceText = getSurfaceFinishWithCustomizationsText(item);
    return (
      <Tooltip title={colorSurfaceText}>
        <div className={classes.summaryText}>{colorSurfaceText}</div>
      </Tooltip>
    );
  };

  const renderItemInfo = (item) => {
    return (
      <div style={{ marginLeft: 20, overflow: 'hidden' }}>
        <div className={classes.summaryItemName}>{item.name}</div>
        <div className={classes.summaryItemDetails}>
          <div className={classes.summaryItemDetailsSection}>
            <div className={classes.summaryText}>
              {getItemTechnologyText(item)}
            </div>
            {renderMaterialWithColorText(item)}
            {renderColorSurfaceFinishText(item)}
          </div>
          <div className={classes.summaryItemAttachmentsSection}>
            <div className={classes.summaryText}>
              {item.cadPart.length > 0
                ? `${item.cadPart.length} attachment(s)`
                : 'No attachments'}
            </div>
            <div className={classes.summaryText}>
              {item.remarks ? item.remarks : 'No description'}
            </div>
            <div className={classes.summaryText}>
              {showUnitValueFromMetric(item.tolerance, item.unitType)}
            </div>
          </div>
          <div className={classes.summaryFileDetailsSection}>
            <div className={classes.summaryText}>
              Files uploaded:
              {item.cadPart ? (
                <List>
                  {item.cadPart.map((link, index) => {
                    const fileName = getFileNameFromCadFile(link);
                    return (
                      <ListItem key={index} className={classes.fileItem}>
                        <ListItemText primary={fileName} />
                      </ListItem>
                    );
                  })}
                </List>
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const warningLabel = (item) => {
    return (
      <div>
        {showUpload2DFileText(item) && (
          <Box
            sx={{
              display: 'inline-flex',
              backgroundColor: colors.bgBeige,
              fontSize: 14,
              color: colors.fontBlack,
              whiteSpace: 'nowrap',
              padding: '2px 4px',
            }}
          >
            Please upload 2D files/technical drawings.
          </Box>
        )}
        {!item.name && (
          <div className={classes.fileWarning}>
            <div>
              <WarningIcon
                style={{
                  fontSize: '1.1rem',
                  color: colors.errorRed,
                }}
              />
            </div>
            <div style={{ display: 'flex', marginLeft: 4 }}>
              Order Name should not be empty.
            </div>
          </div>
        )}
        {item.qty <= 0 && (
          <div className={classes.fileWarning}>
            <div>
              <WarningIcon
                style={{
                  fontSize: '1.1rem',
                  color: colors.errorRed,
                }}
              />
            </div>
            <div style={{ display: 'flex', marginLeft: 4 }}>
              Quantity should not be zero.
            </div>
          </div>
        )}
      </div>
    );
  };

  const render2DImage = (value) => {
    const imageUrl = value[TWO_D_IMAGE_URLS_KEY]
      ? value[TWO_D_IMAGE_URLS_KEY][0]
      : null;
    const cadFile3DRenderer = getCadFileTo3DRenderer(value.cadPart.join(','));
    return imageUrl !== 'loading' ? (
      <React.Fragment>
        {isSuccessFetchFeatureFlags &&
        featureFlags &&
        featureFlags.config[FE_FEATURE_FLAGS_CONFIGURATION.CAD_RENDERER_AINC] &&
        cadFile3DRenderer ? (
          <div
            className={classes.renderImage}
            onClick={(event) => {
              dispatch(openPopupCadRenderer(true));
              dispatch(urlFileCadRender(cadFile3DRenderer));
              event.stopPropagation();
              event.preventDefault();
            }}
          >
            <SingleImage
              url={imageUrl}
              width={isMobile ? 60 : 100}
              height={isMobile ? 60 : 100}
              noBorder
            />
            <span className={classes.render3dCAD}>Render 3D CAD</span>
          </div>
        ) : (
          <SingleImage
            url={imageUrl}
            width={isMobile ? 60 : 100}
            height={isMobile ? 60 : 100}
            noBorder
          />
        )}
      </React.Fragment>
    ) : (
      <div
        className={classes.twoDImageContainer}
        style={{
          width: isMobile ? 60 : 100,
          minWidth: isMobile ? 60 : 100,
          height: isMobile ? 60 : 100,
          minHeight: isMobile ? 60 : 100,
        }}
      >
        <CircularProgress
          style={{
            width: isMobile ? 20 : 30,
            height: isMobile ? 20 : 30,
          }}
        />
      </div>
    );
  };

  const renderQcReports = () => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
        }}
      >
        <div>
          <Button
            color='primary'
            size='medium'
            startIcon={<AddIcon />}
            onClick={() => setOpenQualityPopup(true)}
          >
            Quality Certifications
          </Button>
        </div>
        <div
          style={{
            padding: '0.3rem',
            paddingLeft: '1rem',
          }}
        >
          <QcReportsDisplay qcReports={qcReports} showTextField={false} />
        </div>
      </div>
    );
  };

  const renderAddPartButton = () => {
    return (
      <div className={classes.addButtonWrapper}>
        {!itemsFull ? (
          <Link
            className={classes.addDetailsLink}
            to={{
              pathname: '/summary/add',
              state: { formDataAvailable: itemState },
            }}
          >
            <Button size='small' style={{ color: colors.tertiaryBlue }}>
              <AddBoxIcon style={{ fontSize: 20 }} />
              <div style={{ marginLeft: 4 }}>Add Part</div>
            </Button>
          </Link>
        ) : (
          <Button
            disabled={true}
            size='small'
            style={{ color: colors.fontLightGrey }}
          >
            <AddBoxIcon style={{ fontSize: 20 }} />
            <div style={{ marginLeft: 4 }}>Add Part</div>
          </Button>
        )}
      </div>
    );
  };

  const renderProjectInfo = () => {
    return (
      <div className={classes.projectLabel}>
        <Typography className={classes.projectText}>Project Name:</Typography>
        <TextField
          value={currentProjectName}
          disabled={!onEditProject}
          onChange={handleProjectNameOnChange}
          InputProps={{ classes: { disabled: classes.textField } }}
        ></TextField>
        {onEditProject ? (
          <IconButton
            onClick={handleDoneButtonOnClick}
            aria-label='edit-success'
          >
            <DoneOutlinedIcon />
          </IconButton>
        ) : (
          <IconButton onClick={handleEditButtonOnClick} aria-label='edit'>
            <EditOutlinedIcon />
          </IconButton>
        )}
        <SetsInput
          itemState={itemState}
          submitUpdatedItemState={submitUpdatedItemState}
        />
      </div>
    );
  };

  const renderPpeAccordionItem = (value) => {
    const { unitPriceStr, totalPriceStr } = convertPriceWithQuantityToCurrency({
      totalPrice: value.totalPrice,
      quantity: value.qty,
      currency,
      exchangeRate,
    });
    return (
      <div ref={itemRef}>
        <MyAccordion
          key={value.id}
          expanded={expanded === value.id}
          onChange={handlePanelChange(value.id)}
          TransitionProps={{ unmountOnExit: true }}
          elevation={0}
          variant='outlined'
        >
          <AccordionSummary
            className={clsx(
              classes.accordionSummary,
              expanded === false ? classes.collapsed : classes.expanded
            )}
          >
            <Grid key={value.id} item container>
              <Grid
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
                item
                xs={7}
              >
                <Checkbox
                  checked={value.checked}
                  onChange={handleChecked}
                  name={value.id.toString()}
                  style={{
                    backgroundColor: 'transparent',
                    verticalAlign: 'middle',
                  }}
                  onClick={handleOnClickChecked}
                />
                {render2DImage(value)}
                {renderItemInfo(value)}
              </Grid>
              <Grid item xs={5}>
                <div className={classes.summaryRightComponent}>
                  <div className={classes.summaryItemRight}>
                    {warningLabel(value)}
                    <Grid container>
                      <Grid item xs={10}>
                        <TableContainer>
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCellGrayText align='center'>
                                  QTY
                                </TableCellGrayText>
                                <TableCellGrayText align='center'>
                                  UNIT
                                </TableCellGrayText>
                                <TableCell align='center'>
                                  TOTAL&nbsp;
                                  {country === COUNTRY_NAMES.SINGAPORE ? (
                                    <InfoIcon toolTipText='excl. GST' />
                                  ) : null}
                                </TableCell>
                                <LeadTimeHeader align='center'>
                                  LEAD TIME
                                </LeadTimeHeader>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              <TableRow>
                                <TableCellGrayText align='center'>
                                  {value.qty}
                                </TableCellGrayText>
                                <TableCellGrayText align='center'>
                                  {unitPriceStr}
                                </TableCellGrayText>
                                <TableCell align='center'>
                                  {totalPriceStr}
                                </TableCell>
                                <TableCellGrayText align='center'>
                                  {displayLeadTime(value)}
                                </TableCellGrayText>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Grid>
                      <Grid item xs={2}>
                        <div>
                          <Tooltip arrow title='Price too high' placement='top'>
                            <Button
                              size='small'
                              style={{
                                color: colors.successGreen,
                              }}
                              onClick={(event) =>
                                handlePriceTooHighButtonClick(event, value)
                              }
                            >
                              <ArrowUpwardIcon
                                style={{
                                  fontSize: 25,
                                }}
                              />
                            </Button>
                          </Tooltip>
                          <Tooltip arrow title='Price too low' placement='top'>
                            <Button
                              size='small'
                              data-cy='price-too-low-button'
                              style={{
                                color: colors.hotRed,
                              }}
                              onClick={(event) =>
                                handlePriceTooLowButtonClick(event, value)
                              }
                            >
                              <ArrowDownwardIcon
                                style={{
                                  fontSize: 25,
                                }}
                              />
                            </Button>
                          </Tooltip>
                        </div>
                      </Grid>
                      {!value.repeatOrder && (
                        <Button
                          className={classes.bulkPricing}
                          variant='text'
                          onClick={(event) =>
                            handleBulkPricingClick(event, value)
                          }
                        >
                          View Bulk Pricing
                        </Button>
                      )}
                    </Grid>
                  </div>
                </div>
              </Grid>
            </Grid>
            {expanded === false && (
              <ExpandMoreIcon
                className={classes.expandIcon + ' expandMoreIcon'}
              />
            )}
          </AccordionSummary>
          <AccordionDetails className={classes.expandedPane}>
            <EditPartForm
              item={value}
              itemState={itemState.filter((obj) => !obj.deleted)}
              submitEditedPart={handleSaveEditedForm}
              submitUpdatedItemState={submitUpdatedItemState}
              onCancel={handleCancelEditPartForm}
            />
          </AccordionDetails>
        </MyAccordion>
      </div>
    );
  };

  const renderProceedText = () => {
    return (
      <div className={classes.proceedText}>
        <Typography style={{ fontSize: 'inherit' }}>
          Clicking &apos;Proceed&apos; will ensure that Factorem receives
          your&nbsp;<span style={{ fontWeight: 'bold' }}>enquiry</span>.
          This&nbsp;<span style={{ fontWeight: 'bold' }}>does not</span> confirm
          your order.
        </Typography>
      </div>
    );
  };

  const renderProceedButton = () => {
    return (
      <div className={classes.pageButtonComponent}>
        <div id='multiple-part-summary-proceed'>
          <SolidBlueButton onBtnClick={handleSubmitForm} btnContent='Proceed' />
        </div>
      </div>
    );
  };

  const renderContent = () => {
    return (
      <Fragment>
        <div
          style={{
            marginBottom: 10,
            borderBottom: `1px solid ${colors.bgLightGrey}`,
          }}
        >
          <UploadPartsStepper activeStep={1} />
        </div>
        <div
          style={{
            position: 'relative',
          }}
        >
          <Grid container spacing={1}>
            <Grid item xs={12} md={6}>
              {renderProjectInfo()}
              {renderAddPartButton()}
            </Grid>
            <Grid item xs={12} md={6}>
              {renderQcReports()}
            </Grid>
          </Grid>
          <Divider />
          <Grid item xs={12}>
            <Grid container direction='column' spacing={2}>
              {itemState.filter((obj) => !obj.deleted && obj.price).length >
                0 && (
                <Grid
                  container
                  direction='column'
                  id='multiple-part-instant-quote'
                >
                  <Grid container className={classes.itemListContainer}>
                    <Typography className={classes.itemListTitle}>
                      Instant Quote
                    </Typography>
                    <div
                      style={{
                        marginTop: 10,
                        fontStyle: 'italic',
                        fontSize: 'small',
                        display: 'inline-block',
                      }}
                    >
                      The quote(s) below reflect true market rates for your
                      component(s). Please note that Factorem will assess your
                      drawings only after payment and checkout. The payment will
                      be held in escrow till a suitable fabrication Partner has
                      been found.
                    </div>
                    <div
                      style={{
                        marginTop: 10,
                        fontStyle: 'italic',
                        fontSize: 'small',
                        display: 'inline-block',
                      }}
                    >
                      In case the quotes are not to your liking, we price match!
                      If you have an alternative quote but would still like to
                      work with us, upload it onto the{' '}
                      <a
                        href='https://factorem.co/factorempricematcher'
                        target='_blank'
                        rel='noreferrer'
                      >
                        Factorem Price Matcher
                      </a>{' '}
                      and we will do our best to beat it!
                    </div>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    style={{
                      overflowX: 'auto',
                      height: 'fit-content',
                      paddingBottom: 10,
                    }}
                  >
                    <div
                      style={{
                        minWidth: 980,
                      }}
                    >
                      <Grid container direction='column' spacing={0}>
                        {itemState
                          .filter((obj) => !obj.deleted && obj.price)
                          .map((value) => renderPpeAccordionItem(value))}
                      </Grid>
                    </div>
                  </Grid>
                </Grid>
              )}
              {itemState.filter((obj) => !obj.deleted && !obj.price).length >
                0 && (
                <Grid
                  container
                  direction='column'
                  id='multiple-part-manual-rfq'
                >
                  <Grid item className={classes.itemListContainer}>
                    <Typography className={classes.itemListTitle}>
                      Manual RFQ
                    </Typography>
                  </Grid>
                  <Grid item container>
                    <Box
                      component='span'
                      color='black'
                      bgcolor={colors.bgBeige}
                      style={{
                        width: '100%',
                        marginBottom: '1rem',
                        padding: '0.3rem 0.5rem',
                      }}
                    >
                      <Typography style={{ fontSize: 14 }}>
                        Some parts require Request for Quote. Vendors will be
                        contacted to provide quotes once you submit order. Once
                        available, you can view the quotes in the{' '}
                        <strong>Manage Projects</strong> page.
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    style={{
                      overflowX: 'auto',
                      height: 'fit-content',
                      paddingBottom: 10,
                    }}
                  >
                    <div
                      style={{
                        minWidth: 980,
                      }}
                    >
                      <Grid item container direction='column' spacing={0}>
                        {itemState
                          .filter((obj) => !obj.deleted && !obj.price)
                          .map((value) => (
                            <MyAccordion
                              ref={itemRef}
                              key={value.id}
                              expanded={expanded === value.id}
                              onChange={handlePanelChange(value.id)}
                              TransitionProps={{ unmountOnExit: true }}
                              elevation={0}
                              variant='outlined'
                            >
                              <AccordionSummary
                                className={clsx(
                                  classes.accordionSummary,
                                  expanded === false
                                    ? classes.collapsed
                                    : classes.expanded
                                )}
                              >
                                <Grid key={value.id} item container>
                                  <Grid
                                    style={{
                                      display: 'flex',
                                      alignItems: 'center',
                                    }}
                                    item
                                    xs={7}
                                  >
                                    <Checkbox
                                      checked={value.checked}
                                      onChange={handleChecked}
                                      name={value.id.toString()}
                                      style={{ backgroundColor: 'transparent' }}
                                      onClick={handleOnClickChecked}
                                    />
                                    {render2DImage(value)}
                                    {renderItemInfo(value)}
                                  </Grid>
                                  <Grid item xs={5}>
                                    <div
                                      className={classes.summaryRightComponent}
                                    >
                                      <div className={classes.summaryItemRight}>
                                        {warningLabel(value)}
                                        <TableContainer>
                                          <Table>
                                            <TableHead>
                                              <TableRow>
                                                <TableCellGrayText align='center'>
                                                  QTY
                                                </TableCellGrayText>
                                              </TableRow>
                                            </TableHead>
                                            <TableBody>
                                              <TableRow>
                                                <TableCellGrayText align='center'>
                                                  {value.qty}
                                                </TableCellGrayText>
                                                <TableCell
                                                  style={{
                                                    fontWeight: 'normal',
                                                  }}
                                                >
                                                  <div
                                                    className={
                                                      classes.manualRFQ
                                                    }
                                                  >
                                                    <div>Manual RFQ&nbsp;</div>
                                                    <div
                                                      style={{
                                                        display: 'flex',
                                                        marginLeft: 1,
                                                      }}
                                                    >
                                                      <InfoIcon toolTipText='Upon confirming your order, our team will assess the part and prepare a quotation within 1-3 working days.' />
                                                    </div>
                                                  </div>
                                                </TableCell>
                                              </TableRow>
                                            </TableBody>
                                          </Table>
                                        </TableContainer>
                                      </div>
                                    </div>
                                  </Grid>
                                </Grid>
                                {expanded === false && (
                                  <ExpandMoreIcon
                                    className={
                                      classes.expandIcon + ' expandMoreIcon'
                                    }
                                  />
                                )}
                              </AccordionSummary>
                              <AccordionDetails
                                className={classes.expandedPane}
                              >
                                <EditPartForm
                                  item={value}
                                  itemState={itemState.filter(
                                    (obj) => !obj.deleted
                                  )}
                                  submitEditedPart={handleSaveEditedForm}
                                  submitUpdatedItemState={
                                    submitUpdatedItemState
                                  }
                                  onCancel={handleCancelEditPartForm}
                                />
                              </AccordionDetails>
                            </MyAccordion>
                          ))}
                      </Grid>
                    </div>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        </div>
        {bulkPricingDialog && (
          <BulkPricingPopup
            dialog={bulkPricingDialog}
            item={currentBulkPricingItemSelected}
            currency={currency}
            exchangeRate={exchangeRate}
            handleClose={handleCloseBulkPricingDialog}
            submitUpdatedPart={handleSelectQtyClick}
          />
        )}
        <PriceTooHighPopup
          dialog={priceTooHighDialog}
          handleClose={handleClosePriceTooHighDialog}
          handleSwitchButtonClick={handleSwitchToManual}
        />
        <PriceTooLowPopup
          dialog={priceTooLowDialog}
          handleClose={handleClosePriceTooLowDialog}
          handleSwitchButtonClick={handleSwitchToManual}
        />
        {openQualityPopup && (
          <QualityCertificationsPopup
            dialog={openQualityPopup}
            itemList={itemState.filter((obj) => obj.checked)}
            value={qcReports}
            onClose={() => setOpenQualityPopup(false)}
            onOk={(value) => {
              if (value.main === QC_FORMAL_CMM_REPORT) {
                const copyItemState = [...itemState];
                copyItemState.forEach((item) => {
                  item.initialPrice = item.price;
                  item.initialMarkupPercent = item.markupPercent;
                  item.initialTotalPrice = item.totalPrice;
                  item.initialOriginalPrice = item.originalPrice;
                  item.price = null;
                  item.markupPercent = null;
                  item.totalPrice = null;
                  item.originalPrice = null;
                });
                setItemState(copyItemState);
                setSaveItemOnCMMReport(true);
              } else {
                if (saveItemOnCMMReport) {
                  const copyItemState = [...itemState];
                  copyItemState.forEach((item) => {
                    item.price = item.initialPrice;
                    item.markupPercent = item.initialMarkupPercent;
                    item.totalPrice = item.initialTotalPrice;
                    item.originalPrice = item.initialOriginalPrice;
                  });
                  setSaveItemOnCMMReport(false);
                }
              }
              setQcReports(value);
              setOpenQualityPopup(false);
            }}
          />
        )}
      </Fragment>
    );
  };

  if (
    userId &&
    (props.location.state == null ||
      props.location.state.from !== '/' ||
      itemState.length === 0 ||
      !itemState.some((obj) => !obj.deleted))
  ) {
    return (
      <Redirect
        to={{
          pathname: '/',
          state: { from: props.location },
        }}
      />
    );
  }

  return (
    <Fragment>
      {!questionAnswered && renderSignUpPopups()}
      <div className={classes.formComponent}>{renderContent()}</div>
      {!expanded && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            marginTop: '1rem',
            position: 'sticky',
            bottom: 0,
            backgroundColor: 'white',
            borderTop: `1px solid rgba(0, 0, 0, 0.12)`,
            borderLeft: `1px solid rgba(0, 0, 0, 0.12)`,
            zIndex: 1,
          }}
        >
          <div
            style={{
              display: 'flex',
              gap: '0.3rem',
              padding: '0.6rem 1rem',
              alignItems: 'center',
              justifyContent: 'center',
              flexWrap: 'wrap',
            }}
          >
            {renderProceedText()}
            {renderProceedButton()}
          </div>
        </div>
      )}
      <WarningPopup
        open={showWarningPopup}
        title='Unsaved Item'
        body='Item information has not been saved.'
        onClose={() => setShowWarningPopup(false)}
      ></WarningPopup>
      <MultiplePartSummaryFormTour
        expandFirstArrow={() => setExpanded('0')}
        collapseArrow={() => setExpanded(false)}
      />
      {popUpOpen && (
        <MultiplePartsSummaryClarificationPopup
          open={popUpOpen}
          handleClose={handlePopupClose}
          handleSubmit={handleSubmitForm}
          partsNeedClarify={partsNeedClarify}
          onSameFactoryCheckboxChange={onSameFactoryCheckboxChange}
          hasIndustry={industrySector}
          onIndustrySelect={setIndustry}
          hasCustomerType={customerType}
          onCustomerTypeSelect={setCustomerType}
        />
      )}
      <Backdrop className={classes.backdrop} open={loading}>
        <div className={classes.backdropContent}>
          <CircularProgress color='inherit' />
          <Typography>{getLoadingMessage()}</Typography>
        </div>
      </Backdrop>
    </Fragment>
  );
};

function mapStateToProps(state) {
  return {
    userId: state.auth.user.userID,
    industrySector: state.auth.user.industrySector,
    customerType: state.auth.user.customerType,
    role: state.auth.user.role,
    formDataAvailable: state.item.formDataAvailable,
    project: state.project.project,
    twoDImagesMapping: state.twoDImages,
    currency: state.auth.location.currency,
    exchangeRate: state.auth.rates[state.auth.location.currency],
    country: state.auth.user.country,
    unitType: state.auth.user.unitType,
    signupSuccess: state.createUser.signupSuccess,
    verifyEmailSuccess: state.createUser.verifyEmailSuccess,
    questionAnswered: state.createUser.questionAnswered,
    verificationDate: state.createUser.verificationDate,
  };
}

function matchDispatchToProps(dispatch, props) {
  return {
    insertIndustryCustomerType: (newData) =>
      dispatch(insertUserIndustryCustomerType(newData)),
    updateSummaryForm: (formDataAvailable) =>
      dispatch(openPartSummaryPage(formDataAvailable, props)),
    updateProject: (project) => dispatch(saveProject(project)),
  };
}

const withConnect = connect(mapStateToProps, matchDispatchToProps);

export default withConnect(MultiplePartSummaryForm);
