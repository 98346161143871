import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { Close } from '@material-ui/icons';

import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@material-ui/core';

import Title from '../Title';
import SubDesc from '../SubDesc';
import DetailRow from '../../pages/DetailRow';
import { makeStyles } from '@material-ui/core/styles/index';
import {
  getMaterialWithColorText,
  getSurfaceFinishWithCustomizationsText,
} from '../../utils/itemUtils';
import { getOriginalPpeQuoteOfItem } from '../../apis/quotationApi';
import { convertPriceToCurrency } from '../../utils/currencyUtils';
import { get } from 'lodash';
import { CURRENCY_CODE } from '../../constants/currencyConstants';
import { DEFAULT_FACTOREM_GST } from '../../constants';
import { isRocketQuote } from '../../utils/quotationUtils';

const useStyles = makeStyles((theme) => ({
  paper: {
    minWidth: 400,
    borderRadius: 0,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

function InstantQuoteDetailsDisplayRequestQuoteReferencePopup(props) {
  const classes = useStyles();

  const { open, itemID, onClose } = props;

  const [rowData, setRowData] = useState(null);

  useEffect(() => {
    getOriginalPpeQuoteOfItem(itemID).then((quotation) => {
      setRowData(quotation);
    });
  }, [itemID]);

  const renderRowDetails = () => {
    if (!rowData) {
      return 'No reference quote available';
    }
    return (
      <div style={{ marginBottom: 15, lineHeight: 'normal' }}>
        <DetailRow
          title='Date of Quotation'
          content={
            rowData.dateOfOrder ? rowData.dateOfOrder.substring(0, 10) : 'N.A.'
          }
        />
        <DetailRow title='Quantity' content={rowData.quantity ?? 'N.A.'} />
        <DetailRow title='Technology' content={rowData.technology ?? 'N.A.'} />
        <DetailRow
          title='Material'
          content={getMaterialWithColorText(rowData)}
        />
        <DetailRow
          title='Surface Finish'
          content={getSurfaceFinishWithCustomizationsText(rowData)}
        />
        <DetailRow
          title='Original Quote'
          content={convertPriceToCurrency(
            {
              price: rowData.originalQuote,
              currency: rowData.currency,
              exchangeRate: 1,
            },
            ''
          )}
        />
        <DetailRow
          title='Price from supplier (excl. GST) in SGD'
          content={'S$' + rowData.priceBidded}
        />
        <DetailRow title='Delivery Fees' content={'S$' + rowData.deliveryFee} />
        <DetailRow
          title='CMM Price'
          content={convertPriceToCurrency({
            price: get(rowData, 'metadata.cmmPrice', 0),
            currency: CURRENCY_CODE.SGD,
            exchangeRate: 1,
          })}
        />
        <DetailRow
          title='CMM Price for Customer'
          content={convertPriceToCurrency({
            price:
              get(rowData, 'metadata.cmmPriceCustomer') ||
              get(rowData, 'metadata.cmmPrice') ||
              0,
            currency: CURRENCY_CODE.SGD,
            exchangeRate: 1,
          })}
        />
        <DetailRow
          title='Total Price shown to customer (excl. Factorem GST)'
          content={
            rowData.totalPrice
              ? convertPriceToCurrency(
                  {
                    price: rowData.totalPrice,
                    currency: CURRENCY_CODE.SGD,
                    exchangeRate: 1,
                  },
                  ''
                )
              : 'N.A.'
          }
        />
        <DetailRow
          title='Service fee'
          content={convertPriceToCurrency({
            price: rowData.platformFee,
            currency: CURRENCY_CODE.SGD,
            exchangeRate: 1,
          })}
        />
        <DetailRow
          title='Total Price (incl. GST)'
          content={
            rowData.totalPrice
              ? convertPriceToCurrency(
                  {
                    price: rowData.totalPrice * (1 + DEFAULT_FACTOREM_GST),
                    currency: CURRENCY_CODE.SGD,
                    exchangeRate: 1,
                  },
                  ''
                )
              : 'N.A.'
          }
        />
        <DetailRow
          title='Lead time (original + markup)'
          content={`${rowData.leadTime} + ${rowData.markupLeadTime} working days`}
        />
        <DetailRow
          title='Supplier name'
          content={`${rowData.supplierName} (${rowData.userID})`}
        />
        <DetailRow title='Status' content={rowData.status ?? 'N.A.'} />
        <DetailRow
          title='Verification date'
          content={
            rowData.verifiedDate
              ? rowData.verifiedDate.substring(0, 10)
              : 'N.A.'
          }
        />
        <DetailRow
          title='PPE Quote'
          content={rowData.rfqQuote ? 'No' : 'Yes'}
        />
        <DetailRow
          title='Rocket Quote'
          content={isRocketQuote(rowData) ? 'Yes' : 'No'}
        />
      </div>
    );
  };

  if (!open) {
    return null;
  }

  return (
    <Dialog
      maxWidth='xl'
      open={open}
      onClose={onClose}
      aria-labelledby='quote-details'
      classes={{ paper: classes.paper }}
    >
      <DialogTitle id='confirmation-dialog-title'>
        <Title contentTitle='Quote Details'></Title>
        <SubDesc content={`Quotation ID: #${rowData?.quotationID}`} />
      </DialogTitle>
      <DialogContent>
        <div
          style={{
            width: 'fit-content',
            margin: '0 auto',
          }}
        >
          {renderRowDetails(rowData)}
        </div>
      </DialogContent>
      <IconButton
        aria-label='close'
        className={classes.closeButton}
        onClick={onClose}
      >
        <Close />
      </IconButton>
    </Dialog>
  );
}

function mapStateToProps(state) {
  return {
    user: state.auth.user,
    userId: state.auth.user.userID,
    currency: state.auth.location.currency,
    role: state.auth.user.role,
    exchangeRate: state.auth.rates[state.auth.location.currency],
  };
}

const withConnect = connect(mapStateToProps, null);

export default withConnect(
  InstantQuoteDetailsDisplayRequestQuoteReferencePopup
);
