import React, { useContext, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles/index';

import { Button, Typography } from '@material-ui/core';

import { Skeleton } from '@material-ui/lab';

import OutlinedDiv from './OutlinedDiv';
import { FlexColumn, FlexRowEnd } from '../layouts/FlexLayouts';

import InfoIcon from '../icons/InfoIcon';

import { retrieveUserCredits } from '../../actions';

import { useCreditsBalance } from '../../hooks/useCreditsBalanceHook';

import { getMultiCheckoutFormSelector } from '../../selectors/multiCheckoutFormSelector';

import { isEmptyValue } from '../../utils/commonUtils';
import {
  convertPriceToCurrency,
  getCurrencySymbol,
  extractPriceFromCurrencyString,
  beautifyNumber,
} from '../../utils/currencyUtils';

import { COUNTRY_NAMES } from '../../constants/countryConstants';

import { colors } from '../../palette';
import ProjectItemsContext from '../../context/ProjectItemsContext';
import useCustomerSettingsHook from '../../hooks/useCustomerSettingsHook';

// -------------------------------------------------------------------------------------------------

const useStyles = makeStyles(() => ({
  alignRightRow: {
    display: 'flex',
    flexDirection: 'row-reverse',
    marginBottom: '0.5rem',
    alignItems: 'center',
    width: '100%',
  },
}));

function MultiCheckoutSummaryPanel(props) {
  const classes = useStyles();

  const {
    onCheckOutClick,
    showGst = true,
    showButtons = true,
    showEstimateDeliveryDate = false,
    currency,
    exchangeRate,
    shippingCountry,
    buttonText = 'Checkout',
    userID,
    getUserCredits,
  } = props;

  const { customerID } = useContext(ProjectItemsContext);

  const { customerSettings } = useCustomerSettingsHook(customerID);

  const multiCheckoutForm = useSelector(getMultiCheckoutFormSelector);
  const checkoutPriceSummary = multiCheckoutForm.checkoutPriceSummary;

  const totalCost = extractPriceFromCurrencyString(
    checkoutPriceSummary.totalCostInclGst
  );

  const { userCredits, creditBalanceAfter } = useCreditsBalance(totalCost);

  const subTotal =
    customerSettings?.combinedServiceFee === 1
      ? checkoutPriceSummary?.totalCostInclPlatformFee || 0
      : extractPriceFromCurrencyString(checkoutPriceSummary?.totalCost) || 0;

  const subTotalStr = convertPriceToCurrency({
    price: subTotal,
    currency,
    exchangeRate: 1,
  });

  useEffect(() => {
    getUserCredits(userID);
  }, [userID]);

  if (isEmptyValue(checkoutPriceSummary)) {
    return null;
  }

  const renderCheckoutButton = () => {
    return (
      <Button
        data-cy='checkout-btn'
        variant='contained'
        color='primary'
        fullWidth
        onClick={onCheckOutClick}
        disabled={
          isEmptyValue(multiCheckoutForm.selectedQuotes) ||
          multiCheckoutForm.status !== 'success'
        }
      >
        {buttonText}
      </Button>
    );
  };

  const renderPriceDetails = () => {
    return (
      <>
        <div className={classes.alignRightRow}>
          <Typography>
            <span style={{ color: colors.blue050, fontWeight: 'bold' }}>
              {checkoutPriceSummary.numOfSelectedQuotes}
            </span>{' '}
            <span>
              {checkoutPriceSummary.numOfSelectedQuotes === 1
                ? 'quote'
                : 'quotes'}
            </span>{' '}
            selected
          </Typography>
        </div>
        <div className={classes.alignRightRow}>
          <Typography>
            Subtotal:{' '}
            <span style={{ color: colors.blue050, fontWeight: 'bold' }}>
              {subTotalStr}
            </span>
          </Typography>
        </div>
        <div
          style={{
            display: 'flex',
            width: '100%',
            alignItems: 'center',
            justifyContent: 'end',
            marginBottom: '0.5rem',
          }}
        >
          <Typography>
            {showGst ? 'Delivery fee (excl. GST)' : 'Delivery fee'}
          </Typography>
          &nbsp;
          {shippingCountry === COUNTRY_NAMES.SINGAPORE && (
            <InfoIcon
              toolTipText={`Free delivery for orders of more than ${convertPriceToCurrency(
                {
                  price: 1000,
                  currency,
                  exchangeRate,
                }
              )}`}
            />
          )}
          :&nbsp;
          <span style={{ color: colors.blue050, fontWeight: 'bold' }}>
            {checkoutPriceSummary.totalCost !== 0 &&
            (checkoutPriceSummary.deliveryFee === 0 ||
              (checkoutPriceSummary.deliveryFee &&
                checkoutPriceSummary.deliveryFee.split('').length > 1 &&
                checkoutPriceSummary.deliveryFee.split(' ') === '0.00')) ? (
              <span style={{ color: 'green', fontWeight: 'bold' }}>Free</span>
            ) : (
              checkoutPriceSummary.deliveryFee
            )}
          </span>
        </div>
        {checkoutPriceSummary.qcReportFee > 0 && (
          <div className={classes.alignRightRow}>
            <Typography>
              {showGst ? 'QC Report fee (excl. GST)' : 'QC Report fee'}:{' '}
              <span style={{ color: colors.blue050, fontWeight: 'bold' }}>
                {checkoutPriceSummary.qcReportFeeStr}{' '}
              </span>
            </Typography>
          </div>
        )}
        {renderServiceFeeSummary()}
        {showGst && (
          <div className={classes.alignRightRow}>
            <Typography>
              GST ({Number(checkoutPriceSummary.gstPercent * 100).toFixed(2)}%):{' '}
              <span style={{ color: colors.blue050, fontWeight: 'bold' }}>
                {checkoutPriceSummary.gstInclAllFee}{' '}
              </span>
            </Typography>
          </div>
        )}
        <div className={classes.alignRightRow}>
          <Typography>
            {showGst ? 'Total cost incl. GST' : 'Total cost'}:{' '}
            <span style={{ color: colors.blue050, fontWeight: 'bold' }}>
              {checkoutPriceSummary.totalCostInclGst}
            </span>
          </Typography>
        </div>
        {userCredits > 0 && (
          <div className={classes.alignRightRow}>
            <Typography>
              Credit Available:
              <span style={{ color: 'orange', fontWeight: 'bold' }}>
                {' '}
                {getCurrencySymbol(currency)}{' '}
                {beautifyNumber(parseFloat(userCredits).toFixed(2))}
              </span>
            </Typography>
          </div>
        )}
        {userCredits > 0 && (
          <div className={classes.alignRightRow}>
            <Typography>
              Credit Balance (after transaction):
              <span style={{ color: 'orange', fontWeight: 'bold' }}>
                {' '}
                {getCurrencySymbol(currency)}{' '}
                {beautifyNumber(parseFloat(creditBalanceAfter).toFixed(2))}
              </span>
            </Typography>
          </div>
        )}
      </>
    );
  };

  const renderPriceDetailsSkeleton = () => {
    return (
      <div
        style={{
          minWidth: 250,
        }}
      >
        {Array.apply(null, Array(6)).map((index) => {
          return (
            <Skeleton
              key={index}
              style={{
                minHeight: 30,
              }}
            />
          );
        })}
      </div>
    );
  };

  return (
    <FlexColumn
      style={{
        position: 'sticky',
        top: '2rem',
      }}
    >
      <div
        style={{
          marginBottom: '1rem',
        }}
      >
        <OutlinedDiv label='Checkout Summary'>
          {multiCheckoutForm.status === 'success' && renderPriceDetails()}
          {multiCheckoutForm.status === 'loading' &&
            renderPriceDetailsSkeleton()}
        </OutlinedDiv>
        {showEstimateDeliveryDate && (
          <div
            className={classes.alignRightRow}
            style={{
              marginTop: '1rem',
            }}
          >
            <Typography>
              Estimate Delivery Date: by{' '}
              <span
                style={{
                  color: 'green',
                  fontWeight: 'bold',
                  fontStyle: 'italic',
                }}
              >
                {checkoutPriceSummary.deliveryDate}&nbsp;&nbsp;&nbsp;
              </span>
            </Typography>
          </div>
        )}
      </div>
      {showButtons && renderCheckoutButton()}
    </FlexColumn>
  );

  function renderServiceFeeSummary() {
    if (customerSettings?.combinedServiceFee === 1) {
      return;
    }

    return (
      checkoutPriceSummary.platformFee > 0 && (
        <FlexRowEnd
          style={{
            width: '100%',
            marginBottom: '0.5rem',
          }}
        >
          <Typography>
            {showGst ? 'Service fee (excl. GST)' : 'Service fee'}&nbsp;
          </Typography>
          <InfoIcon
            toolTipText={`The service fee helps us maintain and enhance the Factorem manufacturing platform, ensuring a seamless experience for users like you. It covers the costs associated with providing support on-demand, server maintenance, software updates, security measures, and continuous improvements to provide you with a reliable and efficient manufacturing service.`}
          />
          :&nbsp;
          <span style={{ color: colors.blue050, fontWeight: 'bold' }}>
            {checkoutPriceSummary.platformFeeStr}
          </span>
        </FlexRowEnd>
      )
    );
  }
}

function mapStateToProps(state) {
  return {
    currency: state.auth.location.currency,
    exchangeRate: state.auth.rates[state.auth.location.currency],
    userID: state.auth.user.userID,
    credits: state.auth.user.credits,
  };
}

function matchDispatchToProps(dispatch) {
  return {
    getUserCredits: (userID) => dispatch(retrieveUserCredits(userID)),
  };
}

const withConnect = connect(mapStateToProps, matchDispatchToProps);

export default withConnect(MultiCheckoutSummaryPanel);
