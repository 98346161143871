import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles/index';

import { Tab, Tabs } from '@material-ui/core';

import MultipleCheckoutTrackingDataGrid from './multiple-checkout-tracking/MultipleCheckoutTrackingDataGrid';

import withMctDeliveryBreakdownPopupHOC from '../hocs/withMctDeliveryBreakdownPopupHOC';

import AdminMctPageContext from '../context/AdminMctPageContext';

import { PROD_ENV, REACT_APP_ENV } from '../constants';

import { colors } from '../palette';

// -------------------------------------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  body: {
    padding: theme.spacing(5),
    paddingTop: theme.spacing(5),
  },
  tabRoot: {
    '&:hover': {
      color: 'white',
      backgroundColor: colors.blue050,
    },
  },
  selectedTab: {
    '&:hover': {
      backgroundColor: colors.secondaryBlue,
      color: colors.blue050,
    },
    backgroundColor: colors.secondaryBlue,
    color: colors.blue050,
  },
}));

export const TAB_KEYS = {
  ONGOING_ORDERS: 'ONGOING ORDERS',
  COMPLETED_ORDERS: 'COMPLETED ORDERS',
  ALL_ORDERS: 'ALL ORDERS',
};

const TABS = [
  TAB_KEYS.ALL_ORDERS,
  TAB_KEYS.ONGOING_ORDERS,
  TAB_KEYS.COMPLETED_ORDERS,
];

const filterByOngoingOrders = (data) => {
  return data
    .filter((checkout) => {
      if (REACT_APP_ENV !== PROD_ENV) {
        return true;
      }
      // https://trello.com/c/n6ixXxVI/2273-imagepng
      return checkout.id > 198;
    })
    .filter(
      (checkout) =>
        checkout.acceptedItems.some((part) => part.delivered !== true) ||
        (checkout.acceptedItems.every((part) => part.delivered === true) &&
          !checkout.isInvoiced)
    );
};

const filterByCompletedOrders = (data) => {
  return data.filter(
    (item) =>
      item.acceptedItems.every((part) =>
        part.invoiced !== undefined ? part.invoiced : part.shipItem
      ) && item.isInvoiced
  );
};

function MultipleCheckoutTracking(props) {
  const classes = useStyles();

  const { updateMctDeliveryBreakdownPopupHOCState = () => {} } = props;

  const [selectedTab, setSelectedTab] = useState(0);

  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const renderTab = () => {
    const tabName = TABS[selectedTab];
    switch (tabName) {
      case TAB_KEYS.ONGOING_ORDERS:
        return (
          <MultipleCheckoutTrackingDataGrid
            fileName={'Ongoing Multiple Checkout Trackings'}
            tabFilterFunc={filterByOngoingOrders}
            tabKey={TAB_KEYS.ONGOING_ORDERS}
            key={TAB_KEYS.ONGOING_ORDERS}
          />
        );
      case TAB_KEYS.COMPLETED_ORDERS:
        return (
          <MultipleCheckoutTrackingDataGrid
            fileName={'Completed Multiple Checkout Trackings'}
            tabFilterFunc={filterByCompletedOrders}
            tabKey={TAB_KEYS.COMPLETED_ORDERS}
            key={TAB_KEYS.COMPLETED_ORDERS}
          />
        );
      case TAB_KEYS.ALL_ORDERS:
        return (
          <MultipleCheckoutTrackingDataGrid
            fileName={'All Multiple Checkout Trackings'}
            tabKey={TAB_KEYS.ALL_ORDERS}
            key={TAB_KEYS.ALL_ORDERS}
          />
        );
      default:
        return null;
    }
  };

  return (
    <AdminMctPageContext.Provider
      value={{
        updateMctDeliveryBreakdownPopupHOCState,
      }}
    >
      <div className={classes.body}>
        <h1>Multiple Checkout Tracking</h1>
        <Tabs
          value={selectedTab}
          onChange={handleChange}
          variant='scrollable'
          scrollButtons='auto'
        >
          {TABS.map((tab) => {
            return (
              <Tab
                classes={{
                  root: classes.tabRoot,
                  selected: classes.selectedTab,
                }}
                key={tab}
                label={tab}
              />
            );
          })}
        </Tabs>
        {renderTab()}
      </div>
    </AdminMctPageContext.Provider>
  );
}

export default withMctDeliveryBreakdownPopupHOC(MultipleCheckoutTracking);
