import React, { useState } from 'react';

import { makeStyles } from '@material-ui/core/styles/index';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from '@material-ui/core';

import factoremIcon from '../../assets/factorem_favicon.png';

import { getUserFirstName } from '../../utils/userUtils';

import { colors } from '../../palette';

const useStyles = makeStyles(() => ({
  title: {
    fontWeight: 'bold',
    textAlign: 'center',
    letterSpacing: -0.1,
  },
  subtitle: {
    textAlign: 'center',
    padding: '0 1rem 0 1rem',
  },
  dialog: {
    alignItems: 'center',
  },
  nextButton: {
    border: 'none',
    fontWeight: 500,
    textTransform: 'none',
    backgroundColor: colors.warningYellow,
    '&:hover': {
      backgroundColor: colors.orange,
    },
  },
  skipButton: {
    color: colors.bgGrey,
    textTransform: 'none',
    '&:hover': {
      background: colors.fontWhite,
      color: colors.fontBlackGrey,
    },
  },
}));

function AddPartsTourPopup(props) {
  const classes = useStyles();

  const {
    userName,
    handleStartTour = () => {},
    handleSkipTour = () => {},
  } = props;

  const [open, setOpen] = useState(true);

  return (
    <Dialog open={open}>
      <Box style={{ display: 'flex', justifyContent: 'center' }}>
        <img
          src={factoremIcon}
          alt='logo'
          style={{
            height: '5rem',
            width: '5rem',
            paddingTop: '2rem',
          }}
        />
      </Box>
      <DialogTitle>
        <Typography className={classes.title} variant='h6'>
          Welcome to Factorem, {getUserFirstName(userName)}!
        </Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText className={classes.subtitle}>
          You can now order custom parts in clicks and hassle-free. At every
          step of your manufacturing journey, we are here to support you.
        </DialogContentText>
        <DialogContentText className={classes.subtitle}>
          Let’s get started!
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          className={classes.skipButton}
          variant='outlined'
          onClick={() => {
            handleSkipTour();
            setOpen(false);
          }}
        >
          Skip tour
        </Button>
        <Button
          className={classes.nextButton}
          variant='outlined'
          onClick={() => {
            handleStartTour();
            setOpen(false);
          }}
        >
          Show me around
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default AddPartsTourPopup;
