export const RESET_COST_ESTIMATION = 'RESET_COST_ESTIMATION';
export const UPDATE_COST_ESTIMATION = 'UPDATE_COST_ESTIMATION';

export const resetCostEstimation = () => {
  return {
    type: RESET_COST_ESTIMATION,
  };
};

export const updateCostEstimation = (data) => {
  return {
    type: UPDATE_COST_ESTIMATION,
    payload: data,
  };
};
