// Import settings
import React, { Fragment } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

// Import color palette
import { colors } from '../../palette';

import { Tooltip } from '@material-ui/core';

import { Alert, AlertTitle } from '@material-ui/lab';

// Import material UI components
import { makeStyles } from '@material-ui/core/styles/index';

// Import customized components
import UploadPartsStepper from '../../components/labels/UploadPartsStepper';
import { FEATURE_FLAG_PART_UPLOAD_REVAMP } from '../../constants/featureFlagConstants';

// Style components
const useStyles = makeStyles(() => ({
  formComponent: {
    height: '75%',
    overflowY: 'scroll',
    padding: '25px 45px',
  },
  title: {
    fontWeight: '600',
    color: colors.fontDarkGrey,
  },
  subtitle: {
    display: 'flex',
    color: colors.fontGrey,
    alignItems: 'end',
  },
  link: {
    color: colors.blue050,
  },
}));

const ItemCreatedSuccess = (props) => {
  const classes = useStyles();
  const projectID = props.location.state.projectID;

  if (props.location.key == null) {
    return (
      <Redirect
        to={{
          pathname: '/summary',
          state: { from: props.location },
        }}
      />
    );
  }

  return (
    <Fragment>
      <div className={classes.formComponent}>
        <div
          style={{
            marginBottom: 10,
          }}
        >
          {FEATURE_FLAG_PART_UPLOAD_REVAMP !== 'true' && (
            <UploadPartsStepper activeStep={2} />
          )}
          <Alert severity='success'>
            <AlertTitle className={classes.title}>
              Your order has been uploaded.
            </AlertTitle>
            <div className={classes.subtitle}>
              Please review your order and accept quotations&nbsp;
              <Link
                data-cy='go-to-project'
                className={classes.link}
                to={{ pathname: `/manage-projects/${projectID}` }}
              >
                here
              </Link>
              .
            </div>
            <br />
            <div className={classes.subtitle}>
              For manual RFQs, quotations will be available within 24 hours.
            </div>
            <br />
            <div className={classes.subtitle}>
              In case of any urgent requests, please contact us via&nbsp;
              <Tooltip title='+65 8925 8615'>
                <a
                  href='https://api.whatsapp.com/send?phone=6589258615&text='
                  className={classes.link}
                >
                  WhatsApp
                </a>
              </Tooltip>
              &nbsp;or&nbsp;
              <Tooltip title='sales@factorem.co'>
                <a href='mailto:sales@factorem.co' className={classes.link}>
                  Email
                </a>
              </Tooltip>
              .
            </div>
            ‌
          </Alert>
        </div>
      </div>
    </Fragment>
  );
};

// eslint-disable-next-line no-unused-vars
function mapStateToProps(state) {
  return {};
}

// eslint-disable-next-line no-unused-vars
function matchDispatchToProps(dispatch, props) {
  return {};
}

const withConnect = connect(mapStateToProps, matchDispatchToProps);

export default withConnect(ItemCreatedSuccess);
