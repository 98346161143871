// Import settings
import React, { useEffect, useState } from 'react';

// Import color palette
import { colors } from '../../palette';

// Import customised components
import BlueButton from '../buttons/BlueButton';
import GreyButton from '../buttons/GreyButton';
import Title from '../Title';

// Import material UI components
import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles/index';

// Style the components
const useStyles = makeStyles(() => ({
  paper: {
    borderRadius: 0,
  },
  rowDetail: {
    color: colors.fontGrey,
    display: 'flex',
    fontSize: '15px',
    marginBottom: 9,
  },
  rowTitle: {
    fontWeight: 600,
  },
  rowContent: {
    marginLeft: 5,
  },
  error: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: colors.errorRed,
    margin: '10px 0',
  },
}));

function SupplierSubmitQuotationPopup(props) {
  const classes = useStyles();
  const {
    dialog,
    onClose,
    onConfirmAcceptQuote,
    label = 'Before you proceed, please ensure that you are able to complete this job by the given lead time. A PO will be sent upon final confirmation.',
  } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [checked, setChecked] = useState(false);

  useEffect(() => {
    setChecked(false);
  }, [dialog]);

  return (
    <Dialog
      maxWidth='xl'
      open={dialog}
      onClose={onClose}
      aria-labelledby='confirmation-dialog-title'
      classes={{ paper: classes.paper }}
    >
      <DialogTitle id='confirmation-dialog-title'>
        <Title contentTitle='Confirm Accept Quote' />
      </DialogTitle>
      <DialogContent style={isMobile ? undefined : { padding: '0 300px' }}>
        <div>
          <FormGroup
            row
            style={{ marginTop: 15, padding: `0 ${isMobile ? 0 : '65px'}` }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  checked={checked}
                  onChange={(evt) => setChecked(evt.target.checked)}
                  name='checkAck'
                  data-cy='checkbox-submit-quotation-popup'
                />
              }
              label={label}
            />
          </FormGroup>
          {!checked ? (
            <div className={classes.error}>
              Please ensure that the checkbox is checked.
            </div>
          ) : (
            <div />
          )}
        </div>
      </DialogContent>
      <DialogActions style={{ padding: 30, justifyContent: 'center' }}>
        <GreyButton onBtnClick={onClose} btnContent='Cancel' />
        <BlueButton
          id='accept-job-popup-btn'
          onBtnClick={() => onConfirmAcceptQuote()}
          btnContent='ACCEPT JOB'
          disabled={!checked}
        />
      </DialogActions>
    </Dialog>
  );
}

export default SupplierSubmitQuotationPopup;
