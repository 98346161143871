import React, { useState, useRef, useEffect } from 'react';

import { makeStyles } from '@material-ui/core/styles';

import {
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Chip,
  Input,
  FormHelperText,
  TextField,
  InputAdornment,
  IconButton,
} from '@material-ui/core';

import {
  Search as SearchIcon,
  Close as CloseIcon,
  HighlightOff as HighlightOffIcon,
} from '@material-ui/icons';

import { isEmptyValue } from '../../utils/commonUtils';

import { colors } from '../../palette';

const useStyles = makeStyles((theme) => ({
  body: {
    padding: theme.spacing(12),
    paddingTop: theme.spacing(5),
  },
  container: {
    padding: 0,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    width: '100%',
    boxSizing: 'border-box',
  },
  field: {
    marginBottom: theme.spacing(2),
  },
  formControl: {
    minWidth: 120,
    boxSizing: 'border-box',
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
    backgroundColor: colors.menuItemSelected,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
  menuItem: {
    backgroundColor: 'white',
    '&:hover': {
      backgroundColor: colors.menuItemHover,
    },
  },
  menuItemSelected: {
    '&.Mui-selected': {
      backgroundColor: colors.menuItemSelected,
      '&:hover': {
        backgroundColor: colors.menuItemSelected,
      },
    },
  },
  chipDeleteIcon: {
    color: colors.blue060,
    '&:hover': {
      color: colors.blue060,
    },
  },
  chipDeletable: {
    '&:focus': {
      backgroundColor: colors.menuItemSelected,
    },
  },
  closeButton: {
    marginLeft: '0.3rem',
    marginRight: '0.3rem',
    '&:hover': {
      backgroundColor: colors.expansionBackgroundColor,
      color: colors.blue050,
    },
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
  },
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'left',
  },
  getContentAnchorEl: null,
};

const DEFAULT_ITEM_LIST = [
  {
    key: 'Oliver Hansen',
    text: 'Oliver Hansen',
  },
  {
    key: 'Van Henry',
    text: 'Van Henry',
  },
  {
    key: 'April Tucker',
    text: 'April Tucker',
  },
];

function MultiSelectChipDropdown(props) {
  const classes = useStyles();
  const inputRef = useRef();

  const {
    id = 'multi-select-chip-dropdown',
    itemList = DEFAULT_ITEM_LIST,
    selectedItems = [],
    label = 'Select',
    onSelect = () => {},
    error,
    errorMessage,
    searchable = false,
  } = props;

  const [displayedItems, setDisplayedItems] = useState(itemList);
  const [filterText, setFilterText] = useState('');

  useEffect(() => {
    const newDisplayedItems =
      searchable && !isEmptyValue(filterText)
        ? itemList.filter((item) =>
            item.text.toLowerCase().includes(filterText.toLowerCase())
          )
        : itemList;
    setDisplayedItems(newDisplayedItems);
    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }, 200);
  }, [itemList, filterText]);

  const handleChange = (event) => {
    const newSelectedItems = event.target.value;
    if (typeof onSelect === 'function') {
      onSelect(newSelectedItems);
    }
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  const handleChipDelete = (key) => {
    const newSelectedItems = selectedItems.filter((item) => item !== key);
    if (typeof onSelect === 'function') {
      onSelect(newSelectedItems);
    }
  };

  const renderChipSelectedItem = (selectedKeys) => {
    return (
      <div className={classes.chips}>
        {selectedKeys.map((key) => {
          const item = itemList.find((item) => item.key === key);
          const label = item ? item.text : '';
          return (
            <Chip
              classes={{
                deleteIcon: classes.chipDeleteIcon,
                deletable: classes.chipDeletable,
              }}
              className={classes.chip}
              key={key}
              label={label}
              onMouseDown={(event) => event.stopPropagation()}
              onDelete={() => handleChipDelete(key)}
              deleteIcon={<HighlightOffIcon />}
            />
          );
        })}
      </div>
    );
  };

  const renderMenuItem = ({ key, text }) => {
    return (
      <MenuItem
        classes={{
          root: classes.menuItem,
          selected: classes.menuItemSelected,
        }}
        className={classes.menuItem}
        key={key}
        value={key}
      >
        {text}
      </MenuItem>
    );
  };

  const renderSearchInput = () => {
    return (
      <div
        style={{
          display: 'flex',
          width: '100%',
          alignItems: 'center',
          position: 'sticky',
          top: 0,
          zIndex: 9999,
          backgroundColor: 'white',
        }}
        key={`search-input-${id}`}
      >
        <IconButton
          className={classes.closeButton}
          aria-label='delete'
          size='small'
          onClick={(event) => {
            event.stopPropagation();
            setFilterText('');
            if (inputRef.current) {
              inputRef.current.focus();
            }
          }}
        >
          <CloseIcon style={{ fontSize: '15pt' }} />
        </IconButton>
        <TextField
          id={`search-input-${id}`}
          onClick={(event) => {
            event.stopPropagation();
          }}
          onFocus={(event) => {
            event.stopPropagation();
          }}
          onSelect={(event) => {
            event.stopPropagation();
          }}
          onKeyUp={(event) => {
            event.stopPropagation();
          }}
          inputRef={inputRef}
          value={filterText}
          onChange={(event) => {
            setFilterText(event.target.value);
            event.stopPropagation();
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          fullWidth
          autoFocus
        />
      </div>
    );
  };

  return (
    <div className={classes.container}>
      <FormControl className={classes.formControl} error={error}>
        <InputLabel id={id}>{label}</InputLabel>
        <Select
          labelId={id}
          id={id}
          variant='outlined'
          multiple
          value={selectedItems}
          onChange={handleChange}
          input={<Input id='select-multiple-chip' />}
          renderValue={(selectedKeys) => renderChipSelectedItem(selectedKeys)}
          MenuProps={MenuProps}
          onKeyPress={(event) => {
            event.preventDefault();
            if (event.key === 'Enter') {
              return;
            }
            setFilterText(`${filterText}${event.key}`);
            if (inputRef.current) {
              inputRef.current.focus();
            }
          }}
        >
          {searchable && renderSearchInput()}
          {displayedItems.map(({ key, text }) => renderMenuItem({ key, text }))}
        </Select>
        {error && <FormHelperText>{errorMessage}</FormHelperText>}
      </FormControl>
    </div>
  );
}

export default MultiSelectChipDropdown;
