import { backAction, continueAction } from './tourConstants';

export const getAddMultiplePartsTourSteps = (params = {}) => {
  const isMobile = params.isMobile;
  const createOpenDrawerPromise =
    params.createOpenDrawerPromise ?? function() {};
  const createCloseDrawerPromise =
    params.createCloseDrawerPromise ?? function() {};
  return [
    {
      attachTo: {
        element: '#upload-parts',
        on: isMobile ? 'top' : 'right-end',
      },
      buttons: [
        {
          ...backAction,
          disabled: true,
        },
        continueAction,
      ],
      beforeShowPromise: createCloseDrawerPromise,
      title: 'Upload Your Design Files',
      text: ['Simply drag and drop multiple 3D files for your project here.'],
    },
    {
      attachTo: { element: '#not-have-3d-cad-file', on: 'bottom-start' },
      buttons: [backAction, continueAction],
      title: 'Only have PDF?',
      text: [
        'Don’t worry if you do not have a 3D model at this point. Simply check this box and upload your pdf drawings instead.',
      ],
    },
    {
      attachTo: {
        element: '#configure-parts',
        on: isMobile ? 'top' : 'left-end',
      },
      buttons: [backAction, continueAction],
      beforeShowPromise: createCloseDrawerPromise,
      title: 'Easily Configure Your Requirements',
      text: [
        'Specify your requirements, change technology for diverse file type uploads and add additional notes in the next step under the “Comments” section.',
      ],
    },
    {
      attachTo: {
        element: '#manage-projects-tab',
        on: isMobile ? 'bottom' : 'right',
      },
      buttons: [backAction, continueAction],
      beforeShowPromise: createOpenDrawerPromise,
      title: 'Track Project Progress',
      text: [
        'Easily monitor the status of your projects, receive quotations and check out all in one handy tab.',
      ],
    },
    {
      attachTo: {
        element: '#order-history-tab',
        on: isMobile ? 'bottom' : 'right',
      },
      buttons: [backAction, continueAction],
      beforeShowPromise: createOpenDrawerPromise,
      title: 'Review Your Past Orders',
      text: [
        'Access your past order history here, and reorder them seamlessly with a single button.',
      ],
    },
    {
      attachTo: {
        element: '#price-matcher-tab',
        on: isMobile ? 'top' : 'right',
      },
      buttons: [backAction, continueAction],
      beforeShowPromise: createOpenDrawerPromise,
      title: 'Unsatisfied with Our Quotes?',
      text: [
        'We are committed to meeting your expectations. Have an alternate quote from a different vendor? Share it here and we will do our best to match, or even beat it! 🙂 ',
      ],
    },
    {
      attachTo: {
        element: '#buyer-profile',
        on: isMobile ? 'bottom' : 'right',
      },
      buttons: [backAction, continueAction],
      beforeShowPromise: createOpenDrawerPromise,
      title: 'Update Your Buyer Profile',
      text: [
        'Enter your favourite headshot and contact details here. Need to sign additional NDA with Factorem? Upload it in this step.',
      ],
    },
    {
      attachTo: {
        element: '#getting-started-tab',
        on: isMobile ? 'bottom' : 'right',
      },
      buttons: [
        backAction,
        {
          classes: 'shepherd-button-primary',
          text: 'Got it!',
          action() {
            this.next();
          },
        },
      ],
      beforeShowPromise: createOpenDrawerPromise,
      title: 'Restart Tour',
      text: [
        'Want to review the steps again? Let’s start the tour from the beginning! ',
      ],
    },
  ];
};
