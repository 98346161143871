import { useReducer } from 'react';

import { TextField } from '@material-ui/core';

import { formatSimpleDate } from '../../../utils/dateTimeUtils';
import { FtrBoldText } from '../../ftr-components';

export const ConsigneeDetailsForm = (props) => {
  const { title = 'Consignee Details', onChangeState, initialValues } = props;

  const [localState, updateLocalState] = useReducer(
    (prev, next) => {
      const updatedState = { ...prev, ...next };
      onChangeState(updatedState);
      return updatedState;
    },
    {
      factoremContactName: 'Factorem',
      factoremPhoneNumber: '+65 8925 8615',
      factoremAddress: '81 Ayer Rajah Crescent, #01-54, Singapore',
      factoremPostalCode: '139967',
      consigneeInstructions: null,
      plannedDeliveryDate: formatSimpleDate(new Date()),
      ...initialValues,
    }
  );

  return (
    <div>
      <FtrBoldText fontSize='16'>{title}</FtrBoldText>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(4, 1fr)',
          columnGap: '1rem',
          placeItems: 'baseline',
          marginTop: '1rem',
        }}
      >
        <TextField
          label='Contact Name'
          value={localState.factoremContactName}
          variant='outlined'
          margin='dense'
          style={{ marginRight: '1.25rem' }}
          required
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(evt) => {
            updateLocalState({
              factoremContactName: evt.target.value,
            });
          }}
        />
        <TextField
          label='Phone Number'
          value={localState.factoremPhoneNumber}
          variant='outlined'
          margin='dense'
          style={{ marginRight: '1.25rem' }}
          required
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(evt) => {
            updateLocalState({
              factoremPhoneNumber: evt.target.value,
            });
          }}
        />
        <TextField
          label='Address'
          value={localState.factoremAddress}
          variant='outlined'
          margin='dense'
          style={{ marginRight: '1.25rem' }}
          required
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(evt) => {
            updateLocalState({
              factoremAddress: evt.target.value,
            });
          }}
        />
        <TextField
          label='Postcode'
          value={localState.factoremPostalCode}
          variant='outlined'
          margin='dense'
          style={{ marginRight: '1.25rem' }}
          fullWidth
          required
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(evt) => {
            updateLocalState({
              factoremPostalCode: evt.target.value,
            });
          }}
        />
        <TextField
          label='Instructions'
          value={localState.consigneeInstructions}
          variant='outlined'
          margin='dense'
          style={{ marginRight: '1.25rem' }}
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          multiline
          onChange={(evt) => {
            updateLocalState({
              consigneeInstructions: evt.target.value,
            });
          }}
        />
      </div>
    </div>
  );
};
