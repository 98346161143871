import React, { Fragment, useReducer, useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles/index';
import { Tooltip } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';

import {
  Box,
  Checkbox,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  useMediaQuery,
} from '@material-ui/core';

import Skeleton from '@material-ui/lab/Skeleton';

import ConfigurePartFormV2 from '../ConfigurePartFormV2';
import DrawingDragDrop from '../../DrawingDragDrop';
import LoadingBackDropText from '../../LoadingBackDropText';
import Row from '../../Row';
import { FtrBanner, FtrButton, FtrTypography } from '../../ftr-components';
import DisplayFeedbackCard from '../../cards/DisplayFeedbackCard';
import { upsertPriceFeedback } from '../../../apis/ppeFeedbackApi';
import ImageWith3DViewer from '../../images/ImageWith3DViewer';
import { getUserIDSelector } from '../../../selectors/userSelector';
import withPriceFeedbackPopupHOC from '../../../hocs/withPriceFeedbackPopupHOC';
import FtrSvgImage from '../../images/FtrSvgImage';
import QuantityField from './QuantityField';
import { TdeWarning } from './DisplayItemsTable';

import DeleteDrawingIcon from '../../../assets/icons/delete_drawing.svg';
import RedWarningSvgIcon from '../../../assets/icons/red_warning.svg';
import AutoAwesomeIcon from '../../../assets/icons/auto_awesome.svg';

import {
  addTechnicalDrawingAndGetPrice,
  getPpePriceForCadPart,
  removeTechnicalDrawing,
} from '../../../actions';

import { getTechnicalDrawingFiles } from '../../../utils/tdeUtils';
import { getDateStrWithMonth } from '../../../utils/dateTimeUtils';
import { isEmptyValue } from '../../../utils/commonUtils';
import { showUpload2DFileText } from '../../../utils/partUploadUtils';
import {
  convertPriceToCurrency,
  convertPriceWithQuantityToCurrency,
} from '../../../utils/currencyUtils';
import {
  isCustomMaterial,
  isCustomSurfaceFinish,
  isCustomTechnology,
} from '../../../utils/inputUtils';
import {
  getFileNameFromCadFile,
  getMaterialWithColorText,
  getSurfaceFinishWithCustomizationsText,
  is3DPTechnology,
  isPpeItem,
} from '../../../utils/itemUtils';
import {
  displayBoundingBoxValues,
  showUnitValueFromMetric,
} from '../../../utils/userUtils';

import { uploadCadFileToS3 } from '../../../services/s3Service';
import { notifyError } from '../../../services/notificationService';

import { THREE_D_P_FDM_TECH } from '../../../constants/NewPartConstants';
import { TWO_D_IMAGE_URLS_KEY } from '../../../constants';
import { UNIT_TYPES } from '../../../constants/unitConstants';
import { FEEDBACK_STATUS } from '../../../constants/feedbackConstants';

import { colors } from '../../../palette';

import { FlexColumn } from '../../layouts/FlexLayouts';

import FeedbackThumbs from '../../fields/FeedbackThumbs';
import { FlexRow } from '../../layouts/FlexLayouts';

import { updateCadPartIfExists } from '../../../actions';
import { isNumber } from '../../../utils/numberUtils';

const useStyles = makeStyles(() => ({
  tableRowRoot: {
    verticalAlign: 'top',
    position: 'relative',
  },
  noBottomBorder: {
    borderBottom: 'none',
  },
  warningRow: {
    padding: '2rem',
  },
  renderImage: {
    width: '160px',
    cursor: 'pointer',
    position: 'relative',
    '&:hover': {
      '& img': {
        filter: 'brightness(0.8)',
      },
      '& $render3dCAD': {
        display: 'block',
      },
    },
  },
  render3dCAD: {
    display: 'none',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    color: 'white',
    fontSize: '1.25rem',
  },
}));

function TechnicalDrawingFileDisplay(props) {
  const { item, fileStr } = props;

  const dispatch = useDispatch();

  return (
    <Box
      style={{
        width: '137.6px',
        display: 'flex',
        alignItems: 'center',
        borderRadius: '10px',
        padding: '0.5rem 0.7rem',
        justifyContent: 'space-between',
        backgroundColor: colors.neutral020,
        border: `0.5px solid ${colors.neutral030}`,
        minHeight: 24,
      }}
    >
      <FtrTypography
        type='subHeading'
        fontSize='12'
        style={{
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          color: colors.neutral070,
        }}
      >
        {getFileNameFromCadFile(fileStr)}
      </FtrTypography>
      <img
        src={DeleteDrawingIcon}
        alt='delete'
        style={{ cursor: 'pointer' }}
        onClick={() => {
          dispatch(removeTechnicalDrawing(item.id, fileStr));
        }}
      />
    </Box>
  );
}

function DisplayItemsCard(props) {
  const {
    itemState,
    selectedUnitType = UNIT_TYPES.METRIC,
    handleCheckboxClickItemTableRow = () => {},
    updatePriceFeedbackState = () => {},
  } = props;

  const classes = useStyles();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const dispatch = useDispatch();

  const currency = useSelector((state) => state.auth.location.currency);
  const exchangeRates = useSelector((state) => state.auth.rates);
  const userID = useSelector(getUserIDSelector);
  const exchangeRate = exchangeRates[currency];

  const [configurePartPopupState, updateConfigurePartPopupState] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      open: false,
      item: null,
      itemID: null,
    }
  );
  const [uploadingTechnicalFile, setUploadingTechnicalFile] = useState(false);

  const navigateItem = (value) => {
    const { itemIndex } = configurePartPopupState;
    const newIndex = itemIndex + value;
    updateConfigurePartPopupState({
      itemIndex: newIndex,
    });
  };

  const removeTDEGenField = (item, fieldsToRemove) => {
    const generatedFields = new Set(item.generatedFields || []);
    fieldsToRemove.forEach((field) => generatedFields.delete(field));
    dispatch(
      updateCadPartIfExists({
        id: item.id,
        generatedFields: Array.from(generatedFields),
      })
    );
  };

  const render2DImage = (item, isPPEItem) => {
    const { imageStatus } = item;

    const imageUrl = item[TWO_D_IMAGE_URLS_KEY]
      ? item[TWO_D_IMAGE_URLS_KEY][0]
      : null;

    const technicalDrawingFiles = getTechnicalDrawingFiles(item.cadPart);
    function handleDrawingFilesChange(value, newFiles) {
      const uploadedUrls = [];
      setUploadingTechnicalFile(true);
      Promise.all(
        Array.from(newFiles).map(async (file) => {
          const { s3ObjectUrl } = await uploadCadFileToS3(file);
          if (!isEmptyValue(s3ObjectUrl)) {
            uploadedUrls.push(s3ObjectUrl);
          }
        })
      )
        .then(() => {
          setUploadingTechnicalFile(false);
          dispatch(addTechnicalDrawingAndGetPrice(item.id, uploadedUrls));
        })
        .catch(() => {
          setUploadingTechnicalFile(false);
          notifyError('File uploaded failed');
        });
    }

    return imageUrl !== 'loading' ? (
      <Box
        className={showUpload2DFileText(item) && classes.noBottomBorder}
        key={item.id}
        style={{ width: isMobile ? 60 : '160px', padding: '1.5rem' }}
      >
        <ImageWith3DViewer
          key={item.id}
          cadFile={item?.cadPart?.join(',')}
          twoDImageUrl={imageUrl}
          width={'160px'}
          height={'160px'}
          noBorder
          isLoading={imageStatus === 'loading'}
          borderRadius={12}
        />
        <Box height='0.5rem' />
        {technicalDrawingFiles?.map((fileStr, index) => {
          return (
            <div key={fileStr}>
              <TechnicalDrawingFileDisplay
                item={item}
                index={index}
                fileStr={fileStr}
                isPPEItem={isPPEItem}
              />
              <Box height='6px' />
            </div>
          );
        })}
        {isEmptyValue(technicalDrawingFiles) && (
          <DrawingDragDrop
            id={item.id?.toString()}
            handleFiles={(files) => {
              handleDrawingFilesChange(item, files);
            }}
          />
        )}
      </Box>
    ) : (
      <Box
        className={classes.twoDImageContainer}
        style={{
          width: isMobile ? 60 : '160px',
          minWidth: isMobile ? 60 : '160px',
          height: isMobile ? 60 : '160px',
          minHeight: isMobile ? 60 : '160px',
          paddingLeft: '2rem',
        }}
      >
        <CircularProgress
          style={{
            width: isMobile ? 20 : 30,
            height: isMobile ? 20 : 30,
          }}
        />
      </Box>
    );
  };

  const renderTDEGenImage = (item, fieldNames) => {
    const generatedFieldsSet = new Set(item.generatedFields || []);
    if (fieldNames.some((fieldName) => generatedFieldsSet.has(fieldName))) {
      return (
        <Tooltip title={'Automatically extracted parameter'} arrow>
          <div>
            <FtrSvgImage
              src={AutoAwesomeIcon}
              className={classes.autoAwesomeIcon}
            />
          </div>
        </Tooltip>
      );
    }
    return null;
  };

  const renderFeedbackThumbs = (curr) => {
    return (
      <div
        style={{
          display: 'inline-block',
        }}
      >
        {/* if ppePricingStatus === success then render */}
        {isNumber(curr.price) && curr.showFeedbackCard !== true && (
          <FeedbackThumbs
            color={'grey'}
            upIsActive={curr.priceFeedback === FEEDBACK_STATUS.GOOD}
            upTooltip={'Price is good'}
            upOnclick={() => {
              dispatch(
                updateCadPartIfExists({
                  id: curr.id,
                  priceFeedback: FEEDBACK_STATUS.GOOD,
                })
              );
              upsertPriceFeedback({
                iqLogsID: curr.ppePriceLogId,
                userID,
                priceFeedback: FEEDBACK_STATUS.GOOD,
              });
            }}
            downIsActive={curr.priceFeedback === FEEDBACK_STATUS.BAD}
            downTooltip={'Price too high'}
            downOnclick={() => {
              dispatch(
                updateCadPartIfExists({
                  id: curr.id,
                  priceFeedback: FEEDBACK_STATUS.BAD,
                  showFeedbackCard: true,
                })
              );
              upsertPriceFeedback({
                iqLogsID: curr.ppePriceLogId,
                userID,
                priceFeedback: FEEDBACK_STATUS.BAD,
              });
            }}
          />
        )}
        {curr.showFeedbackCard === true && isNumber(curr.price) && (
          <FlexRow>
            <DisplayFeedbackCard
              onClickAction={() => {
                updatePriceFeedbackState({
                  open: true,
                  item: curr,
                });
              }}
              onCloseAction={() => {
                dispatch(
                  updateCadPartIfExists({
                    id: curr.id,
                    showFeedbackCard: false,
                  })
                );
              }}
              text='Target Price?'
            />
          </FlexRow>
        )}
      </div>
    );
  };

  const renderConfigurePartButton = (curr, index) => {
    return (
      <FtrButton
        key={`configure-part-${curr.id}-button`}
        color='blue'
        size='small'
        id={`configure-part-${index}-button`}
        onClick={() => {
          updateConfigurePartPopupState({
            open: true,
            itemIndex: index,
          });
          if (curr.tdeStatus === 'error') {
            dispatch(
              updateCadPartIfExists({
                id: curr.id,
                tdeStatus: null,
              })
            );
          }
        }}
        style={{ marginTop: '1rem', width: '100%' }}
      >
        Configure part
      </FtrButton>
    );
  };

  const renderPriceBlock = (curr, isPPEItem) => {
    return (
      <Box
        className={showUpload2DFileText(curr) && classes.noBottomBorder}
        style={{ padding: '0 2rem' }}
      >
        <FtrTypography
          type='subHeading'
          fontSize='14'
          style={{
            paddingBottom: '0.5rem',
            color: colors.neutral050,
          }}
        >
          Price
        </FtrTypography>
        {isPPEItem
          ? (() => {
              const {
                unitPriceStr,
                totalPriceStr,
              } = convertPriceWithQuantityToCurrency({
                totalPrice: curr.totalPrice ?? 0,
                quantity: curr.qty ?? 1,
                currency,
                exchangeRate,
              });
              return (
                <div id='ppe-item-price'>
                  <FtrTypography type='heading' fontSize='18'>
                    {totalPriceStr}
                  </FtrTypography>
                  <FtrTypography type='body' fontSize='12'>
                    {unitPriceStr}/unit
                  </FtrTypography>
                </div>
              );
            })()
          : (() => {
              return (
                <FtrTypography
                  type='heading'
                  fontSize='14'
                  style={{
                    color: colors.neutral060,
                    display: 'flex',
                  }}
                  id='manual-rfq-required'
                >
                  Manual Quotation required
                </FtrTypography>
              );
            })()}
        {renderFeedbackThumbs(curr)}
      </Box>
    );
  };

  const renderSkeleton = () => (
    <div>
      <FtrTypography
        type='body'
        fontSize='14'
        style={{ color: colors.neutral060, paddingBottom: '0.5rem' }}
      >
        Extracting information from your technical drawing
      </FtrTypography>
      <FlexColumn>
        <Skeleton variant='text' width='20rem' height='1.5rem' />
        <Skeleton variant='rect' width='2.5rem' height='2.5rem' />
        <Skeleton variant='rect' width='20rem' height='1.5rem' />
        <Skeleton variant='rect' width='15rem' height='1rem' />
      </FlexColumn>
    </div>
  );

  const renderItemSpecifications = (item) => {
    const {
      name,
      boundingBoxX,
      boundingBoxY,
      boundingBoxZ,
      technology,
      otherTechnology,
      material,
      otherMaterial,
      surfaceFinish,
      otherSurfaceFinish,
      tolerance,
      threeDTechnology,
      threeDInfill,
      threeDLayerThickness,
      remarks,
      targetPrice,
      expectedLeadTime,
    } = item;

    const isSkeleton = item.tdeStatus === 'loading';
    const isTdeError = item.tdeStatus === 'error';

    return (
      <Box
        className={showUpload2DFileText(item) && classes.noBottomBorder}
        style={{ paddingLeft: '2rem' }}
      >
        <div>
          <FtrTypography
            type='subHeading'
            fontSize='16'
            style={{ paddingBottom: '0.5rem' }}
          >
            {name}
          </FtrTypography>
        </div>
        {isSkeleton ? (
          renderSkeleton(name)
        ) : (
          <div>
            <FtrTypography
              type='subHeading'
              fontSize='14'
              style={{ paddingBottom: '5px', color: colors.neutral050 }}
            >
              Specifications
            </FtrTypography>
            {isTdeError && <TdeWarning />}
            <FtrTypography
              type='body'
              fontSize='14'
              style={{ color: colors.neutral060, paddingBottom: '0.5rem' }}
            >
              {displayBoundingBoxValues(
                boundingBoxX,
                boundingBoxY,
                boundingBoxZ,
                item.unitType ?? selectedUnitType
              )}
            </FtrTypography>
            <FtrTypography
              type='subHeading'
              fontSize='14'
              style={{ color: colors.neutral060, lineHeight: '22px' }}
            >
              {isCustomTechnology(technology) ? otherTechnology : technology}
            </FtrTypography>
            {is3DPTechnology(technology) && (
              <Fragment>
                <FtrTypography
                  type='subHeading'
                  fontSize='14'
                  style={{ color: colors.neutral060, lineHeight: '22px' }}
                >
                  {threeDTechnology}
                </FtrTypography>
                {threeDTechnology === THREE_D_P_FDM_TECH && (
                  <FtrTypography
                    type='subHeading'
                    fontSize='14'
                    style={{ color: colors.neutral060, lineHeight: '22px' }}
                  >
                    3D Infill {Number(threeDInfill * 100).toFixed(0)}%
                  </FtrTypography>
                )}
                {threeDTechnology === THREE_D_P_FDM_TECH && (
                  <FtrTypography
                    type='subHeading'
                    fontSize='14'
                    style={{ color: colors.neutral060, lineHeight: '22px' }}
                  >
                    3D Layer Thickness {threeDLayerThickness}mm
                  </FtrTypography>
                )}
              </Fragment>
            )}
            <FtrTypography
              type='subHeading'
              fontSize='14'
              style={{ color: colors.neutral060, lineHeight: '22px' }}
            >
              <FlexRow>
                {isCustomMaterial(material)
                  ? otherMaterial
                  : getMaterialWithColorText(item)}
                {renderTDEGenImage(item, ['material', 'otherMaterial'])}
              </FlexRow>
            </FtrTypography>
            <FtrTypography
              type='subHeading'
              fontSize='14'
              style={{ color: colors.neutral060, lineHeight: '22px' }}
            >
              <FlexRow>
                {isCustomSurfaceFinish(surfaceFinish)
                  ? otherSurfaceFinish
                  : getSurfaceFinishWithCustomizationsText(item)}
                {renderTDEGenImage(item, [
                  'surfaceFinish',
                  'otherSurfaceFinish',
                ])}
              </FlexRow>
            </FtrTypography>
            <FtrTypography
              type='subHeading'
              fontSize='14'
              style={{ color: colors.neutral060, lineHeight: '22px' }}
            >
              <FlexRow>
                {`Tolerance ${showUnitValueFromMetric(
                  tolerance,
                  item.unitType ?? selectedUnitType
                )}`}
                {renderTDEGenImage(item, ['tolerance'])}
              </FlexRow>
            </FtrTypography>
            {remarks && (
              <FtrTypography
                type='subHeading'
                fontSize='14'
                style={{ color: colors.neutral060, lineHeight: '22px' }}
              >
                Remarks: {remarks}
              </FtrTypography>
            )}
            {targetPrice && (
              <FtrTypography
                type='subHeading'
                fontSize='14'
                style={{ color: colors.neutral060, lineHeight: '22px' }}
              >
                Target price{' '}
                {convertPriceToCurrency({
                  price: Number(targetPrice),
                  currency,
                  exchangeRate,
                })}
                /unit
              </FtrTypography>
            )}
            {expectedLeadTime && (
              <FtrTypography
                type='subHeading'
                fontSize='14'
                style={{ color: colors.neutral060, lineHeight: '22px' }}
              >
                Target Delivery {getDateStrWithMonth(expectedLeadTime)}
              </FtrTypography>
            )}
          </div>
        )}
      </Box>
    );
  };

  return (
    <Fragment>
      <TableContainer component={Paper} style={{ borderRadius: '16px' }}>
        <Table>
          <TableBody>
            {itemState.map((curr, index) => {
              const isPPEItem = isPpeItem(curr);
              return (
                <TableCell
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    position: 'relative',
                  }}
                  key={curr.id}
                >
                  {render2DImage(curr, isPPEItem)}
                  <div
                    id={`specifications-column-${
                      renderTDEGenImage(curr, curr.generatedFields || [])
                        ? 'tde-gen'
                        : ''
                    }`}
                  >
                    {renderItemSpecifications(curr)}
                    <div
                      style={{
                        position: 'absolute',
                        top: '10px',
                        right: '10px',
                        zIndex: 0,
                      }}
                    >
                      <Checkbox
                        color='primary'
                        checked={curr.checked}
                        onChange={handleCheckboxClickItemTableRow}
                        name={curr.id?.toString()}
                        onClick={(event) => {
                          event.stopPropagation();
                        }}
                      />
                    </div>
                    <Box
                      className={
                        showUpload2DFileText(curr) && classes.noBottomBorder
                      }
                      style={{ padding: '1rem 2rem' }}
                    >
                      <FtrTypography
                        type='subHeading'
                        fontSize='14'
                        style={{
                          paddingBottom: '0.5rem',
                          color: colors.neutral050,
                        }}
                      >
                        Qty
                      </FtrTypography>
                      <FlexRow style={{ gap: 0 }}>
                        <QuantityField
                          value={curr.qty}
                          onBlur={(evt) => {
                            let newValue = Number(evt.target.value);
                            dispatch(
                              updateCadPartIfExists({
                                id: curr.id,
                                qty: newValue,
                              })
                            );
                            dispatch(getPpePriceForCadPart(curr.id));
                            if (Number(curr.qty) !== Number(newValue)) {
                              removeTDEGenField(curr, ['qty']);
                            }
                          }}
                        />
                        <Box style={{ width: '0.5rem' }} />
                        {renderTDEGenImage(curr, ['qty'])}
                      </FlexRow>
                    </Box>
                  </div>
                  {renderPriceBlock(curr, isPPEItem)}
                  {!showUpload2DFileText(curr) && (
                    <div style={{ padding: '2rem' }}>
                      {renderConfigurePartButton(curr, index)}
                    </div>
                  )}
                  {showUpload2DFileText(curr) && (
                    <TableRow
                      classes={{
                        root: classes.tableRowRoot,
                      }}
                      className={classes.rowHover}
                    >
                      <Box className={classes.warningRow} colSpan={3}>
                        <FtrBanner type='warning'>
                          <Row>
                            <img src={RedWarningSvgIcon} />
                            <FtrTypography type='body' fontSize='14'>
                              Technical drawing required for precise quotation
                              and DFM feedback.
                            </FtrTypography>
                          </Row>
                        </FtrBanner>
                        {renderConfigurePartButton(curr, index)}
                      </Box>
                    </TableRow>
                  )}
                </TableCell>
              );
            })}
          </TableBody>
          {configurePartPopupState.open && (
            <ConfigurePartFormV2
              key={itemState[configurePartPopupState.itemIndex]}
              open={configurePartPopupState.open}
              selectedItemIndex={configurePartPopupState.itemIndex}
              onClose={() => {
                updateConfigurePartPopupState({ open: false });
              }}
              navigateItem={navigateItem}
            />
          )}
          {uploadingTechnicalFile && (
            <LoadingBackDropText
              open={uploadingTechnicalFile}
              text='Uploading...'
            />
          )}
        </Table>
      </TableContainer>
    </Fragment>
  );
}

export default withPriceFeedbackPopupHOC(DisplayItemsCard);
