import React, { useEffect, useReducer } from 'react';

import VerifyBalloonPopup from '../pages/order-ready-management/VerifyBalloonPopup';

import withLoadingBackDropTextHOC from './withLoadingBackDropTextHOC';

import useItemAutoBalloon from '../hooks/useItemAutoBalloonHook';

import { isEmptyValue } from '../utils/commonUtils';

import { notifyError, notifySuccess } from '../services/notificationService';

// -------------------------------------------------------------------------------------------------

function withVerifyBalloonPopupHOC(WrappedComponent) {
  function WrappedComponentWithHOC(props) {
    const { setLoadingMessage = () => {} } = props;

    const defaultLocalState = {
      open: false,
      itemID: null,
      pdfUrl: null,
    };

    const {
      autoBallooning,
      manualBallooning,
      deletingBalloon,
      downloadingPdf,
      verifying,
      saving,
      downloadingReport,
      originalImageUrls,
      annotatedImageUrls,
      annotationData,
      totalPages,
      setDownloadingReport,
      setAnnotationData,
      generateAutoBallonForItem,
      addManualBalloons,
      deleteBalloon,
      downloadBalloonsReportCsv,
      downloadBalloonedPdf,
      verifyItemBalloon,
      saveItemBalloon,
    } = useItemAutoBalloon();

    const [localState, updateLocalState] = useReducer((prev, next) => {
      if (isEmptyValue(next)) {
        return defaultLocalState;
      }
      return { ...prev, ...next };
    }, defaultLocalState);

    useEffect(() => {
      if (isEmptyValue(localState.itemID) || isEmptyValue(localState.pdfUrl)) {
        return;
      }

      generateAutoBallonForItem({
        pdfUrl: localState.pdfUrl,
        itemID: localState.itemID,
      });
    }, [localState.itemID, localState.pdfUrl]);

    const handleDownloadReportCsv = () => {
      downloadBalloonsReportCsv()
        .then(() => setDownloadingReport(false))
        .catch(() => {
          setDownloadingReport(false);
          notifyError('Download report csv failed');
        });
    };

    const handleDeleteBalloon = (data) => {
      const balloonNumber = data?.number;
      if (isEmptyValue(balloonNumber)) {
        return;
      }

      setLoadingMessage('Deleting balloon...');
      deleteBalloon(balloonNumber)
        .then(() => {
          notifySuccess('Balloon removed successfully');
          setLoadingMessage('');
        })
        .catch(() => {
          notifyError('Balloon removal failed');
          setLoadingMessage('');
        });
    };

    const handleDownloadPdf = async () => {
      await downloadBalloonedPdf().catch(() => {
        notifyError('Download pdf failed');
      });
    };

    const handleVerifyItemBalloon = async () => {
      verifyItemBalloon()
        .then(() => {
          updateLocalState({ open: false });
        })
        .catch(() => {
          notifyError('Balloon verification failed');
        });
    };

    return (
      <>
        <WrappedComponent
          updateVerifyBalloonPopupState={updateLocalState}
          {...props}
        />
        {localState.open && (
          <VerifyBalloonPopup
            open={localState.open}
            handleClose={() => updateLocalState({ open: false })}
            autoBallooning={autoBallooning}
            manualBallooning={manualBallooning}
            deletingBalloon={deletingBalloon}
            verifying={verifying}
            saving={saving}
            originalImageUrls={originalImageUrls}
            annotationData={annotationData}
            totalPages={totalPages}
            annotatedImageUrls={annotatedImageUrls}
            downloadingPdf={downloadingPdf}
            downloadingReport={downloadingReport}
            handleDeleteBalloon={handleDeleteBalloon}
            addManualBalloons={addManualBalloons}
            setAnnotationData={setAnnotationData}
            handleDownloadPdf={handleDownloadPdf}
            handleDownloadReportCsv={handleDownloadReportCsv}
            verifyItemBalloon={handleVerifyItemBalloon}
            saveItemBalloon={saveItemBalloon}
          />
        )}
      </>
    );
  }

  // Set the displayName for the HOC
  const wrappedComponentName =
    WrappedComponent.displayName || WrappedComponent.name || 'Component';
  WrappedComponentWithHOC.displayName = `withSignUpPopup(${wrappedComponentName})`;

  return withLoadingBackDropTextHOC(WrappedComponentWithHOC);
}

export default withVerifyBalloonPopupHOC;
