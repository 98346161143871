import React from 'react';

import { IconButton } from '@material-ui/core';

import { MoreVert } from '@material-ui/icons';

import withPopupMenuListHOC from '../../hocs/withPopupMenuListHOC';

// -------------------------------------------------------------------------------------------------

function MoreActionsButton(props) {
  const { anchorRef, onClick = () => {}, style = {} } = props;

  return (
    <IconButton
      aria-label='more-actions'
      onClick={(event) => {
        event.stopPropagation();
        event.preventDefault();
        onClick();
      }}
      ref={anchorRef}
      style={{ ...style }}
    >
      <MoreVert />
    </IconButton>
  );
}

export default withPopupMenuListHOC(MoreActionsButton);
