import React, { useMemo } from 'react';
import { get } from 'lodash';

import { isEmptyValue } from '../utils/commonUtils';
import { getItemStageStatusText } from '../utils/itemUtils';
import { getFromToDateFilter } from '../utils/filterUtils';
import { isSameOrAfterDate } from '../utils/dateTimeUtils';

// -----------------------------------------------------------------------------------------------

function withCustomerProjectsFilterHOC(WrappedComponent) {
  function WrappedComponentWithHOC(props) {
    const {
      searchStr = '',
      statusFilterList = [],
      createdDateFilter = null, // eg: 'Last 7 days', 'Last 30 days'
      createdDateField = 'dateCreated',
      columns = [],
      projects = [],
    } = props;

    const searching = useMemo(() => {
      return (
        !isEmptyValue(searchStr) ||
        !isEmptyValue(statusFilterList) ||
        !isEmptyValue(createdDateFilter)
      );
    }, [searchStr, statusFilterList, createdDateFilter]);

    const filteredData = useMemo(() => {
      if (!searching) {
        return projects;
      }

      let _filteredData = projects;
      if (!isEmptyValue(searchStr)) {
        _filteredData = _filteredData?.filter((data) => {
          for (const col of columns) {
            const valueGetterFunc = col.valueGetter;
            let value = '';
            if (typeof valueGetterFunc === 'function') {
              value = valueGetterFunc({ row: data }) ?? '';
              value = String(value).toLowerCase();
            } else {
              value = `${get(data, col.field ?? '') ?? ''}`.toLowerCase();
            }
            if (value.includes(searchStr.toLowerCase())) {
              return true;
            }
          }
          return false;
        });

        return _filteredData;
      }

      if (!isEmptyValue(statusFilterList)) {
        _filteredData = _filteredData?.filter((project) => {
          const projectItemStatus = project.items.map((item) =>
            getItemStageStatusText(item)
          );
          return statusFilterList.some((status) =>
            projectItemStatus.includes(status)
          );
        });
      }

      const createdDateFilterInfo = getFromToDateFilter(createdDateFilter);
      if (!isEmptyValue(createdDateFilterInfo)) {
        _filteredData = _filteredData?.filter((project) => {
          const createdDate = new Date(project[createdDateField]);
          return (
            isSameOrAfterDate(createdDate, createdDateFilterInfo.from) &&
            isSameOrAfterDate(createdDateFilterInfo.to, createdDate)
          );
        });
      }

      return _filteredData;
    }, [
      searching,
      searchStr,
      statusFilterList,
      createdDateFilter,
      createdDateField,
      projects,
      columns,
    ]);

    return (
      <>
        <WrappedComponent
          {...props}
          projects={filteredData}
          searching={searching}
        />
      </>
    );
  }

  return WrappedComponentWithHOC;
}

export default withCustomerProjectsFilterHOC;
