import React from 'react';

function FinanceEmailInfo({ isForSupplier }) {
  return (
    <>
      <p style={{ marginTop: 4 }}>
        You are about to send a notification to&nbsp;
        <a href='mailto:finance@factorem.co'>finance@factorem.co</a> to
        {isForSupplier ? ' pay the supplier' : ' invoice the client'}. Please
        note that this action cannot be undone.
      </p>
      {isForSupplier && (
        <p style={{ marginTop: 0 }}>
          Note that you can only send one invoice per PO to finance. To make any
          further changes to the invoice or to upload another invoice, please
          reach out to both the finance and developer teams.
        </p>
      )}
    </>
  );
}

export default FinanceEmailInfo;
