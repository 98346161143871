// Import settings
import React, { useEffect, useReducer, useState } from 'react';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
const { Decimal } = require('decimal.js');
import { connect } from 'react-redux';
import { get, isEmpty } from 'lodash';
import { useQuery } from 'react-query';

// Import color palette
import { colors } from '../palette';

// Import material UI components
import { FormHelperText, Grid, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles/index';

import AddressAutocomplete from './AddressAutocomplete';
import OutlinedDiv from './panels/OutlinedDiv';
import BlueButton from './buttons/BlueButton';
import InfoIcon from '../components/icons/InfoIcon';
import Addresses from './Addresses';
import ContactField from './fields/ContactField';
import SingleImage from './images/SingleImage';

import { DEFAULT_FACTOREM_GST } from '../constants';
import { COUNTRY_NAMES } from '../constants/countryConstants';
import {
  TECHNOLOGY_OPTION_TYPE,
  THREE_D_P_FDM_TECH,
  THREE_D_P_TECH_ABBRE_MAPPING,
} from '../constants/NewPartConstants';

import {
  convertPriceToCurrency,
  convertPriceWithQuantityToCurrency,
} from '../utils/currencyUtils';
import {
  getMaterialWithColorText,
  getSurfaceFinishWithCustomizationsText,
  getTechnologyDisplayText,
} from '../utils/itemUtils';
import { getQuotationExchangeRate } from '../utils/quotationUtils';
import { addressInfoText, checkCountryAddress } from '../utils/addressUtils';
import { showUnitValueFromMetric } from '../utils/userUtils';

import { createDeliveryInfo } from '../apis/deliveryInfoApi';
import { getUserAddresses, getUserInfoWithCache } from '../apis/userApi';

import { notifyError, notifySuccess } from '../services/notificationService';

// Style components
const useStyles = makeStyles(() => ({
  input: {
    marginTop: 5,
  },
  itemText: {
    '& span, & svg': {
      fontSize: 12,
    },
  },
  rowDetail: {
    color: colors.fontGrey,
    display: 'flex',
    fontSize: '15px',
    marginBottom: 9,
  },
  rowTitle: {
    fontWeight: 600,
  },
  rowContent: {
    margin: '0 5px',
  },
  inputField: {
    padding: '5px 14px',
    background: colors.fontWhite,
    border: `1px solid ${colors.inputBorderBlue}`,
    boxSizing: 'border-box',
    borderRadius: 5,
    color: `${colors.fontGrey}`,
    marginBottom: 13,
  },
  radioLabel: {
    '& .MuiFormControlLabel-label': {
      width: '100%',
    },
  },
}));

function QuoteDetails(props) {
  const {
    partInfo,
    quoteInfo,
    customerRemarks,
    handleRemarks,
    currency,
    exchangeRate,
    country,
    handleAddresses,
  } = props;

  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [itemImageUrl, setItemImageUrl] = useState(null);
  const [billingAddress, setBillingAddress] = useState(null);
  const [shippingAddress, setShippingAddress] = useState(null);
  const [switchDifferentAddress, setSwitchDifferentAddress] = useState(false);
  const [userInfo, setUserInfo] = useState(null);

  const [addressState, updateAddressState] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      address: '',
      postalCode: '',
      unitNo: '',
      country: '',
      contactName: '',
      contactNumber: '',
      onlyContactNumber: '',
      isContactValidated: false,
    }
  );

  const { data: addresses, isSuccess: isAddressesSuccess, refetch } = useQuery(
    ['getUserAddresses', partInfo?.userID],
    () => getUserAddresses(partInfo?.userID)
  );

  const factoremGst =
    country === COUNTRY_NAMES.SINGAPORE ? DEFAULT_FACTOREM_GST : 0;

  useEffect(() => {
    const url = partInfo.imageFile || partInfo.twoDImageUrl || '';
    setItemImageUrl(url.startsWith('http') ? url : null);
  }, [partInfo]);

  useEffect(() => {
    const _billing = switchDifferentAddress ? billingAddress : shippingAddress;
    handleAddresses({
      id: shippingAddress?.deliveryInfoID,
      shipping: {
        address: addressInfoText(shippingAddress),
        contactNumber: shippingAddress?.contactNumber || userInfo?.contact,
        contactName: shippingAddress?.contactName || userInfo?.name,
        country: shippingAddress?.country || userInfo?.country,
      },
      billing: {
        address: addressInfoText(_billing),
        contactNumber: _billing?.contactNumber || userInfo?.contact,
        contactName: _billing?.contactName || userInfo?.name,
        country:
          _billing?.country ||
          checkCountryAddress(_billing?.address) ||
          userInfo?.country,
      },
    });
  }, [
    shippingAddress,
    billingAddress,
    switchDifferentAddress,
    userInfo,
    addresses,
  ]);

  useEffect(() => {
    if (partInfo?.userID) {
      getUserInfoWithCache(partInfo?.userID).then((user) => {
        updateAddressState({
          contactName: user?.name,
          contactNumber: user?.contact,
        });
        setUserInfo(user);
      });
    }
  }, [partInfo?.userID]);

  const handleCreateAddress = () => {
    const body = {
      userID: partInfo.userID,
      contactName: addressState?.contactName,
      contactNumber: addressState?.contactNumber,
      switchDefaultShipping: true,
      country: addressState?.country,
      address: addressState?.address,
      postalCode: addressState?.postalCode,
      unitNo: addressState?.unitNo,
    };
    createDeliveryInfo(body)
      .then(() => {
        notifySuccess('Added new address successfully');
        refetch();
      })
      .catch(() => {
        notifyError('Failed created new address');
      });
  };

  const render3DPrintingInfo = () => {
    if (partInfo.technology !== TECHNOLOGY_OPTION_TYPE.THREE_D_PRINTING) {
      return null;
    }
    const threeDTech = get(quoteInfo, ['metadata', 'threeDTechnology']);
    const threeDInfill = get(quoteInfo, ['metadata', 'threeDInfill']);
    const threeDLayerThickness = get(quoteInfo, [
      'metadata',
      'threeDLayerThickness',
    ]);
    return (
      <div>
        <div className={classes.rowDetail}>
          <div className={classes.rowTitle}>3D Tech:</div>
          <div className={classes.rowContent}>
            {THREE_D_P_TECH_ABBRE_MAPPING[threeDTech]}
          </div>
        </div>
        {threeDTech === THREE_D_P_FDM_TECH && (
          <div className={classes.rowDetail}>
            <div className={classes.rowTitle}>3D Infill:</div>
            <div className={classes.rowContent}>
              {Number(threeDInfill * 100).toFixed(2)}%
            </div>
          </div>
        )}
        {threeDTech === THREE_D_P_FDM_TECH && (
          <div className={classes.rowDetail}>
            <div className={classes.rowTitle}>3D Layer Thickness:</div>
            <div className={classes.rowContent}>{threeDLayerThickness}mm</div>
          </div>
        )}
      </div>
    );
  };

  const renderOtherInformation = () => {
    const {
      totalPrice,
      totalPriceStr: quotePriceStr,
    } = convertPriceWithQuantityToCurrency({
      totalPrice: quoteInfo.totalPrice,
      quantity: quoteInfo.quantity,
      currency,
      exchangeRate:
        getQuotationExchangeRate(quoteInfo, currency) || exchangeRate,
    });

    const _totalPriceStr = convertPriceToCurrency({
      price: new Decimal(totalPrice)
        .times(new Decimal(1).plus(new Decimal(factoremGst)))
        .toNumber(),
      currency,
      exchangeRate: 1, // because totalPrice is already converted to target currency
    });

    return (
      <div
        style={{
          width: '100%',
        }}
      >
        <div className={classes.rowDetail}>
          <div className={classes.rowTitle}>Technology:</div>
          <div className={classes.rowContent}>
            {getTechnologyDisplayText(partInfo)}
          </div>
        </div>
        <div className={classes.rowDetail}>
          <div className={classes.rowTitle}>Material:</div>
          <div className={classes.rowContent}>
            {getMaterialWithColorText(quoteInfo)}
          </div>
        </div>
        {render3DPrintingInfo()}
        <div className={classes.rowDetail}>
          <div className={classes.rowTitle}>Surface Finish:</div>
          <div className={classes.rowContent}>
            {getSurfaceFinishWithCustomizationsText(quoteInfo)}
          </div>
        </div>
        <div className={classes.rowDetail}>
          <div className={classes.rowTitle}>Tolerance:</div>
          <div className={classes.rowContent}>
            +/- {showUnitValueFromMetric(partInfo.tolerance, partInfo.unitType)}
            &nbsp;
          </div>
          <InfoIcon toolTipText='This is the tightest tolerance indicated in your design' />
        </div>
        <div className={classes.rowDetail}>
          <div className={classes.rowTitle}>Quantity:</div>
          <div className={classes.rowContent}>
            {quoteInfo.quantity ? quoteInfo.quantity : 'NIL'}
          </div>
        </div>
        <div className={classes.rowDetail}>
          <div className={classes.rowTitle}>Quote Price:</div>
          <div
            className={classes.rowContent}
            style={{
              color: colors.blue050,
            }}
          >
            <b>{quotePriceStr || ''}</b>
          </div>
        </div>
        <div className={classes.rowDetail}>
          <div className={classes.rowTitle}>Lead Time:</div>
          <div className={classes.rowContent}>
            {quoteInfo.leadTime
              ? parseInt(quoteInfo.leadTime, 10) +
                quoteInfo.markupLeadTime +
                ' working days'
              : 'NIL'}
          </div>
        </div>
        {partInfo.partApplication && (
          <div className={classes.rowDetail}>
            <div className={classes.rowTitle}>Part Application:</div>
            <div className={classes.rowContent}>{partInfo.partApplication}</div>
          </div>
        )}
      </div>
    );
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} md={6}>
        <div
          className={classes.rowTitle}
          style={{ textDecoration: 'underline' }}
        >
          Part Details
        </div>
        <div className={classes.rowDetail}>
          <div className={classes.rowTitle}>Name:</div>
          <div className={classes.rowContent}>
            {partInfo.name ? partInfo.name : ''}
          </div>
        </div>

        <div className={classes.rowDetail}>
          <div className={classes.rowTitle}>Description:</div>
          <div className={classes.rowContent}>
            {partInfo.description ? partInfo.description : 'NIL'}
          </div>
        </div>
        <Grid container spacing={1}>
          <Grid item xs={12} md={6}>
            {renderOtherInformation()}
          </Grid>
          <Grid item xs={12} md={6}>
            {itemImageUrl && (
              <div
                style={{
                  marginRight: '1.5rem',
                  display: 'flex',
                  flexDirection: isMobile ? 'row' : 'row-reverse',
                }}
              >
                <SingleImage
                  width={150}
                  height={150}
                  url={itemImageUrl}
                  noBorder
                />
              </div>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={6}>
        <div>
          <div className={classes.rowTitle}>Supplier comments:</div>
          <div className={classes.rowContent}>
            {quoteInfo.remarks ? quoteInfo.remarks : 'NIL'}
          </div>
        </div>
        <div style={{ marginTop: 16 }}>
          <div className={classes.rowTitle}>Final remarks (optional):</div>
          <div className={classes.input}>
            <TextField
              variant='outlined'
              placeholder='Final remarks (optional)'
              multiline={true}
              minRows={2}
              maxRows={2}
              onChange={handleRemarks}
              value={customerRemarks || ''}
              margin='dense'
              fullWidth
            />
          </div>
        </div>
        {isAddressesSuccess && isEmpty(addresses) && userInfo ? (
          <div style={{ marginTop: '1rem' }}>
            <OutlinedDiv label='Delivery Info'>
              <TextField
                label='Contact Name'
                placeholder='Please Enter Your Full Name'
                variant='outlined'
                onChange={(evt) => {
                  updateAddressState({ contactName: evt.target.value });
                }}
                value={addressState?.contactName}
                margin='dense'
                fullWidth
                required
              />
              {!addressState?.contactName && (
                <FormHelperText
                  style={{ color: colors.errorRed, margin: '-1px 0 0.5rem' }}
                >
                  Contact Name is required!
                </FormHelperText>
              )}
              <div style={{ marginTop: '0.5rem' }}>
                <ContactField
                  required
                  onChange={({ fullContact, contact, isContactValidated }) => {
                    updateAddressState({
                      contactNumber: fullContact,
                      onlyContactNumber: contact,
                      isContactValidated,
                    });
                  }}
                  initialValue={addressState?.contactNumber}
                />
              </div>
              <AddressAutocomplete
                showCountry
                required
                margin='dense'
                style={{ marginTop: '0.75rem' }}
                styleChild={{ marginTop: '0' }}
                onChange={({ postalCode, address, unitNo, country }) =>
                  updateAddressState({ postalCode, address, unitNo, country })
                }
              />
              {!addressState?.address && (
                <FormHelperText
                  style={{ color: colors.errorRed, marginTop: '-1px' }}
                >
                  Address is required!
                </FormHelperText>
              )}
              <div
                style={{
                  display: 'flex',
                  alignItems: 'end',
                  flexDirection: 'column',
                  marginTop: '5px',
                }}
              >
                {addressState?.address &&
                  addressState?.onlyContactNumber &&
                  addressState?.contactName &&
                  addressState?.country &&
                  addressState?.isContactValidated && (
                    <>
                      <BlueButton
                        size='small'
                        onBtnClick={handleCreateAddress}
                        btnContent='Add'
                      />
                      <FormHelperText style={{ color: colors.errorRed }}>
                        Please add to confirm!
                      </FormHelperText>
                    </>
                  )}
              </div>
            </OutlinedDiv>
          </div>
        ) : (
          <Addresses
            handlers={{
              setBillingAddress,
              setShippingAddress,
              setSwitchDifferentAddress,
            }}
            values={{
              userID: partInfo?.userID,
              billingAddress,
              shippingAddress,
              switchDifferentAddress,
            }}
          />
        )}
      </Grid>
    </Grid>
  );
}
function mapStateToProps(state) {
  return {
    userID: state.auth.user.userID,
    currency: state.auth.location.currency,
    exchangeRate: state.auth.rates[state.auth.location.currency],
    country: state.auth.user.country,
  };
}

const withConnect = connect(mapStateToProps, null);

export default withConnect(QuoteDetails);
