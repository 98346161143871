// Import settings
import { CsvBuilder } from 'filefy';
import { ceil, sortBy } from 'lodash';
import React, { useEffect, useReducer, useState } from 'react';
import { connect } from 'react-redux';
import { Link, Redirect, withRouter } from 'react-router-dom';

// Import material UI components
import { makeStyles } from '@material-ui/core/styles/index';

import { DataGrid } from '@mui/x-data-grid';

import HorizontalExpandSpace from '../../components/ftr-components/HorizontalExpandSpace';
import { ExportCsvButton } from '../../components/grid-data/buttons/ExportCsvButton';
import SearchBar from '../../components/grid-data/buttons/SearchBar';
import CustomToolbar, {
  DataGridToolbarLayout,
} from '../../components/grid-data/CustomToolbar';
import GridDataPagination from '../../components/grid-data/GridDataPagination';
import DataGridWrapTextCell from '../../components/tables/cells/DataGridWrapTextCell';

import { getQuotesWithItemDetails } from '../../apis/quotationApi';

import { useDataGridFilterHook } from '../../hooks/useDataGridFilterHook';

// Import utilities
import { generateCurrentCustomDateTimeString } from '../../util';
import { transformCsvExportDataGrid } from '../../utils/csvExportUtils';
import { downloadS3File } from '../../utils/fileUtils';
import { getBackgroundColorByQuoteStatus } from '../../utils/quotationUtils';
import { isSuperAdminRole } from '../../utils/roleUtils';
import { includesIgnoreCase } from '../../utils/stringUtils';

import { notifyError } from '../../services/notificationService';

import {
  customerCadFileLinksDesignCol,
  customerCadFileLinksPdfCol,
  factoremCadFileLinksDesignCol,
  factoremCadFileLinksPdfCol,
  materialWithColorCol,
  surfaceFinishingWithColorCol,
} from '../../constants/itemTableConstants';
import {
  platformFeeCol,
  threeDInfillCol,
  threeDLayerThicknessCol,
  threeDTechnologyCol,
} from '../../constants/quotationTableConstants';

import { colors } from '../../palette';

// -------------------------------------------------------------------------------------------------

const useStyles = makeStyles(() => ({
  body: {
    paddingTop: '1rem',
    '& .MuiTablePagination-root': {
      marginRight: '4rem',
    },
    marginBottom: '2rem',
    '& .MuiDataGrid-columnHeaderTitle': {
      lineHeight: 'normal',
      wordBreak: 'break-word',
      whiteSpace: 'normal',
      color: colors.blue060,
      fontSize: '11pt',
      fontWeight: 600,
      margin: '0 auto',
      textAlign: 'center',
    },
    '& .MuiDataGrid-columnsContainer': {
      display: 'flex',
      justifyContent: 'center',
    },
    '& .MuiDataGrid-cell': {
      textAlign: 'center',
      justifyContent: 'center !important',
    },
  },
}));

export function PpeDataAllOrdersDataGrid(props) {
  const classes = useStyles();
  const { role } = props;
  const [sortedQuotesById, setQuotesSortById] = useState([]);

  const [tableQueryParams, updateTableQueryParams] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      page: 0,
      pageSize: 10,
      search: '',
      totalCount: 0,
      loading: false,
    }
  );

  const [
    filteredData,
    { setSearchStr, setColumnsDef, setSourceData },
  ] = useDataGridFilterHook({
    search: '',
    source: sortedQuotesById,
  });

  useEffect(() => {
    setSearchStr(tableQueryParams.search);
  }, [tableQueryParams.search]);

  const defaultColumns = [
    { headerName: 'Quote ID', title: 'Quote ID', field: 'quotationID' },
    { headerName: 'Part ID', field: 'itemID' },
    { headerName: 'Name', field: 'name' },
    {
      headerName: 'Date of Part Upload',
      field: 'dateOfPartUpload',
      renderCell: ({ row: rowData }) =>
        rowData.datePosted ? rowData.datePosted.substring(0, 10) : '',
      valueGetter: ({ row: rowData }) =>
        rowData.datePosted ? rowData.datePosted.substring(0, 10) : '',
      customFilterAndSearch: (term, rowData) => {
        const data = rowData.datePosted
          ? rowData.datePosted.substring(0, 10)
          : '';
        return includesIgnoreCase(data, term);
      },
      width: 130,
    },
    {
      headerName: 'Date of Quote',
      field: 'dateOfQuote',
      renderCell: ({ row: rowData }) =>
        rowData.dateOfOrder ? rowData.dateOfOrder.substring(0, 10) : '',
      valueGetter: ({ row: rowData }) =>
        rowData.dateOfOrder ? rowData.dateOfOrder.substring(0, 10) : '',
      customFilterAndSearch: (term, rowData) => {
        const data = rowData.dateOfOrder
          ? rowData.dateOfOrder.substring(0, 10)
          : '';
        return includesIgnoreCase(data, term);
      },
      width: 130,
    },
    {
      headerName: 'Expiry date',
      field: 'expiryDate',
      renderCell: ({ row: rowData }) =>
        rowData.dateOfExpiry ? rowData.dateOfExpiry.substring(0, 10) : '',
      valueGetter: ({ row: rowData }) =>
        rowData.dateOfExpiry ? rowData.dateOfExpiry.substring(0, 10) : '',
      customFilterAndSearch: (term, rowData) => {
        const data = rowData.dateOfExpiry
          ? rowData.dateOfExpiry.substring(0, 10)
          : '';
        return includesIgnoreCase(data, term);
      },
      width: 130,
    },
    { headerName: 'Quantity', field: 'quantity' },
    {
      ...materialWithColorCol,
    },
    {
      ...surfaceFinishingWithColorCol,
    },
    { headerName: 'Technology', field: 'technology', width: 130 },
    {
      ...threeDTechnologyCol,
    },
    {
      ...threeDInfillCol,
    },
    {
      ...threeDLayerThicknessCol,
    },
    { headerName: 'Minimum Tolerance (mm)', field: 'tolerance', width: 130 },
    {
      headerName: 'Color',
      field: 'color',
      renderCell: ({ row: rowData }) => (
        <DataGridWrapTextCell text={rowData.color} />
      ),
    },
    {
      headerName: 'PPE Price',
      field: 'price',
    },
    {
      headerName: 'Price from supplier (excl. GST)',
      field: 'priceBidded',
      valueGetter: ({ row: rowData }) => '$' + rowData.priceBidded,
      renderCell: ({ row: rowData }) => '$' + rowData.priceBidded,
      width: 130,
    },
    {
      headerName: 'GST',
      field: 'gst',
      renderCell: ({ row: rowData }) =>
        '$' + (rowData.priceBidded * (rowData.gst / 100)).toFixed(2),
      valueGetter: ({ row: rowData }) =>
        '$' + (rowData.priceBidded * (rowData.gst / 100)).toFixed(2),
      customFilterAndSearch: (term, rowData) => {
        const data =
          '$' + (rowData.priceBidded * (rowData.gst / 100)).toFixed(2);
        return includesIgnoreCase(data, term);
      },
    },
    {
      headerName: 'Delivery Fees',
      field: 'deliveryFee',
      valueGetter: ({ row: rowData }) => '$' + rowData.deliveryFee,
      renderCell: ({ row: rowData }) => '$' + rowData.deliveryFee,
    },
    {
      headerName: 'Total Price shown to customer (excl. Factorem GST)',
      field: 'totalPrice',
      valueGetter: ({ row: rowData }) =>
        rowData.totalPrice ? `$${rowData.totalPrice}` : '',
      renderCell: ({ row: rowData }) =>
        rowData.totalPrice ? `$${rowData.totalPrice}` : '',
      width: 170,
    },
    {
      ...platformFeeCol,
    },
    {
      headerName: 'Lead time (original + markup)',
      field: 'leadTimeOriginalMarkup',
      renderCell: ({ row: rowData }) =>
        `${rowData.leadTime} + ${rowData.markupLeadTime} working days`,
      valueGetter: ({ row: rowData }) =>
        `${rowData.leadTime} + ${rowData.markupLeadTime} working days`,
      customFilterAndSearch: (term, rowData) => {
        const data = `${rowData.leadTime} + ${rowData.markupLeadTime} working days`;
        return includesIgnoreCase(data, term);
      },
      width: 130,
    },
    { headerName: 'Supplier user ID', field: 'supplierUserID' },
    {
      headerName: 'Supplier name',
      field: 'supplierName',
      width: 140,
      renderCell: ({ row: rowData }) => (
        <DataGridWrapTextCell text={`${rowData.supplierName}`} />
      ),
    },
    { headerName: 'Customer user ID', field: 'userID', width: 120 },
    {
      headerName: 'Customer name',
      field: 'customerName',
      renderCell: ({ row: rowData }) => (
        <DataGridWrapTextCell text={`${rowData.customerName}`} />
      ),
      width: 140,
    },
    {
      headerName: 'Status',
      field: 'status',
      renderCell: ({ row: rowData }) => {
        const bgColor = getBackgroundColorByQuoteStatus(rowData.status);
        return (
          <div
            style={{
              backgroundColor: bgColor,
              display: 'flex',
              justifyContent: 'center',
              textTransform: 'uppercase',
              fontWeight: 'bold',
              lineHeight: 'normal',
            }}
          >
            {rowData.status}
          </div>
        );
      },
    },
    {
      headerName: 'Date of acceptance',
      field: 'dateOfConfirmation',
      valueGetter: ({ row: rowData }) =>
        rowData.status === 'accepted' && rowData.dateOfConfirmation
          ? rowData.dateOfConfirmation.substring(0, 10)
          : '',
      renderCell: ({ row: rowData }) =>
        rowData.status === 'accepted' && rowData.dateOfConfirmation
          ? rowData.dateOfConfirmation.substring(0, 10)
          : '',
      width: 120,
    },
    {
      headerName: 'Estimated Collection Date',
      field: 'collectionDate',
      valueGetter: ({ row: rowData }) =>
        rowData.collectionDate ? rowData.collectionDate.substring(0, 10) : '',
      renderCell: ({ row: rowData }) =>
        rowData.collectionDate ? rowData.collectionDate.substring(0, 10) : '',
      width: 140,
    },
    {
      headerName: 'Actual Collection Date',
      field: 'actualCollectionDate',
      valueGetter: ({ row: rowData }) =>
        rowData.actualCollectionDate
          ? rowData.actualCollectionDate.substring(0, 10)
          : '',
      renderCell: ({ row: rowData }) =>
        rowData.actualCollectionDate
          ? rowData.actualCollectionDate.substring(0, 10)
          : '',
      width: 140,
    },
    {
      headerName: 'Estimated Delivery Date',
      field: 'deliveryDate',
      valueGetter: ({ row: rowData }) =>
        rowData.deliveryDate ? rowData.deliveryDate.substring(0, 10) : '',
      renderCell: ({ row: rowData }) =>
        rowData.deliveryDate ? rowData.deliveryDate.substring(0, 10) : '',
      width: 140,
    },
    {
      headerName: 'Actual Delivery Date',
      field: 'actualDeliveryDate',
      valueGetter: ({ row: rowData }) =>
        rowData.actualDeliveryDate
          ? rowData.actualDeliveryDate.substring(0, 10)
          : '',
      renderCell: ({ row: rowData }) =>
        rowData.actualDeliveryDate
          ? rowData.actualDeliveryDate.substring(0, 10)
          : '',
      width: 140,
    },
    {
      headerName: 'Required Rework',
      field: 'requiredRework',
      valueGetter: ({ row: rowData }) =>
        rowData.requiredRework ? 'Yes' : 'No',
      renderCell: ({ row: rowData }) => (rowData.requiredRework ? 'Yes' : 'No'),
    },
    {
      headerName: 'Customer remarks',
      field: 'customerRemarks',
      width: 200,
    },
    {
      headerName: 'Supplier remarks',
      field: 'supplierRemarks',
      width: 200,
    },
    {
      headerName: 'Image file link',
      field: 'imageFile',
      renderCell: ({ row: rowData }) => (
        <DataGridWrapTextCell text={`${rowData.imageFile})`} />
      ),
      width: 380,
      justifyContent: 'start',
    },
    {
      ...customerCadFileLinksDesignCol,
    },
    {
      ...customerCadFileLinksPdfCol,
    },
    {
      ...factoremCadFileLinksDesignCol,
    },
    {
      ...factoremCadFileLinksPdfCol,
    },
    {
      headerName: 'Quotation Form',
      field: 'quotationForm',
      renderCell: ({ row: rowData }) =>
        rowData.quotationForm ? (
          <Link
            onClick={(e) => {
              e.stopPropagation();
              downloadS3File(rowData.quotationForm);
            }}
          >
            Download Quotation
          </Link>
        ) : (
          <div>N.A.</div>
        ),
      width: 160,
    },
    {
      headerName: 'Purchase Order',
      field: 'purchaseOrderForm',
      renderCell: ({ row: rowData }) =>
        rowData.purchaseOrderForm ? (
          <Link
            onClick={(e) => {
              e.stopPropagation();
              downloadS3File(rowData.purchaseOrderForm);
            }}
          >
            Download PO
          </Link>
        ) : (
          <div>N.A.</div>
        ),
      width: 160,
    },
    {
      headerName: 'Quote ppeDataPoint',
      field: 'ppeDataPoint',
      renderCell: ({ row: rowData }) => (rowData.ppeDataPoint ? 1 : 0),
      valueGetter: ({ row: rowData }) => (rowData.ppeDataPoint ? 1 : 0),
      width: 130,
    },
    {
      headerName: 'Quote biDataPoint',
      field: 'biDataPoint',
      renderCell: ({ row: rowData }) => (rowData.biDataPoint ? 1 : 0),
      valueGetter: ({ row: rowData }) => (rowData.biDataPoint ? 1 : 0),
      width: 130,
    },
    {
      headerName: 'Item ppeDataPoint',
      field: 'itemPpeDataPoint',
      renderCell: ({ row: rowData }) => (rowData.itemPpeDataPoint ? 1 : 0),
      valueGetter: ({ row: rowData }) => (rowData.itemPpeDataPoint ? 1 : 0),
      width: 130,
    },
    {
      headerName: 'Item biDataPoint',
      field: 'itemBiDataPoint',
      renderCell: ({ row: rowData }) => (rowData.itemBiDataPoint ? 1 : 0),
      valueGetter: ({ row: rowData }) => (rowData.itemBiDataPoint ? 1 : 0),
      width: 130,
    },
  ];

  const getTableData = () => {
    updateTableQueryParams({ loading: true });
    getQuotesWithItemDetails()
      .then((data) => {
        setQuotesSortById(sortBy(data, (order) => order.quotationID).reverse());
      })
      .catch(() => {
        notifyError('Unable to retrieve quote information. Unknown error.');
      })
      .finally(() => updateTableQueryParams({ loading: false }));
  };

  const [columns, setColumns] = useState(defaultColumns);

  useEffect(() => {
    getTableData();
    const _columns = defaultColumns.filter((col) => !!col);
    setColumns(_columns);
    setColumnsDef(_columns);
  }, []);

  useEffect(() => {
    setSearchStr(tableQueryParams.search);
  }, [tableQueryParams.search]);

  useEffect(() => {
    updateTableQueryParams({ totalCount: filteredData.length });
  }, [filteredData]);

  useEffect(() => {
    setSourceData(sortedQuotesById);
  }, [sortedQuotesById]);

  const handleSearch = (searchTerm) => {
    updateTableQueryParams({
      page: 0,
      search: searchTerm,
    });
  };

  const handleExportCSV = () => {
    const fileName = `All PPE Quotes ${generateCurrentCustomDateTimeString()}.csv`;
    const { exportedColumns, exportedData } = transformCsvExportDataGrid(
      columns,
      filteredData
    );
    const builder = new CsvBuilder(fileName);
    builder
      .setDelimeter(',')
      .setColumns(exportedColumns)
      .addRows(exportedData)
      .exportFile();
  };

  const getToolbar = () => {
    return (
      <DataGridToolbarLayout>
        <HorizontalExpandSpace />
        <CustomToolbar
          buttons={[
            <SearchBar
              key='search'
              onSearch={handleSearch}
              searchTerm={tableQueryParams.search}
            />,
            <ExportCsvButton
              key='export-csv'
              handleClick={handleExportCSV}
              show={isSuperAdminRole(role)}
            />,
          ]}
        />
      </DataGridToolbarLayout>
    );
  };

  return role === 'buyer' || role === 'supplier' ? (
    <Redirect
      to={{
        pathname: '/',
        state: { from: props.location },
      }}
    />
  ) : (
    <div className={classes.body}>
      <div style={{ height: '100%', width: '100%' }}>
        <DataGrid
          autoHeight
          paginationMode='server'
          rows={filteredData}
          columns={columns.map((col) => ({
            ...col,
            sortable: false,
          }))}
          getRowId={(row) => row.quotationID}
          rowHeight={100}
          headerHeight={80}
          components={{
            Toolbar: getToolbar,
            Pagination: () => (
              <GridDataPagination
                pageCount={ceil(
                  tableQueryParams.totalCount / tableQueryParams.pageSize
                )}
              />
            ),
          }}
          pageSize={tableQueryParams.pageSize}
          rowsPerPageOptions={[10, 20, 50]}
          onPageSizeChange={(newPageSize) =>
            updateTableQueryParams({ pageSize: newPageSize })
          }
          page={tableQueryParams.page}
          onPageChange={(newPage) => updateTableQueryParams({ page: newPage })}
          rowCount={tableQueryParams.totalCount}
          loading={tableQueryParams.loading}
          disableRowSelectionOnClick
          disableSelectionOnClick
          disableColumnMenu
        />
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    users: state.users.users,
    role: state.auth.user.role,
    myUserId: state.auth.user.userID,
  };
}

const withConnect = connect(mapStateToProps);

export default withRouter(withConnect(PpeDataAllOrdersDataGrid));
