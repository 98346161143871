export const ADDRESS_TYPES = {
  BILLING: 'billing',
  SHIPPING: 'shipping',
};

export const ADDRESS_COMPONENTS = {
  COUNTRY: 'country',
  POSTAL_CODE: 'postal_code',
};

export const DEFAULT_DELIVERY_FEE_SGP = 20;

export const MAX_TOTAL_WEIGHT_FEDEX_IN_KG = 998;
