/* eslint-disable react/prop-types */
import React, { Fragment, useEffect, useState, useRef } from 'react';
import { connect, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';

import { colors } from '../../palette';

import { openPartSummaryPage } from '../../actions';

import {
  Backdrop,
  Breadcrumbs,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  InputBase,
  InputLabel,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  MenuItem,
  Typography,
  Link,
  Select,
} from '@material-ui/core';

import { makeStyles, withStyles } from '@material-ui/core/styles';

import {
  Cancel as CancelIcon,
  InsertDriveFileOutlined as InsertDriveFileOutlinedIcon,
  FolderOutlined as FolderOutlinedIcon,
} from '@material-ui/icons';

import { DatePicker } from '@material-ui/pickers';

import {
  STANDARD_TOLERANCE_OPTIONS,
  threeDPrintingInfillDefault,
  threeDPrintingLayerThicknessDefault,
  THREE_D_P_FDM_TECH,
  TECHNOLOGY_OPTION_TYPE,
  TOLERANCE_OPTIONS,
  THREE_D_TOLERANCE_OPTIONS,
} from '../../constants/NewPartConstants';

import BlueDragDropSmall from '../../components/BlueDragDropSmall';

import FileIcon from '../../assets/img/file.png';
import { useCadPartFileUpload } from '../../hooks/useCadPartFileUpload';
import { isEmpty } from 'lodash';
import { TWO_D_IMAGE_URLS_KEY } from '../../constants';
import {
  getFileNameFromCadFile,
  getItemNameFromCadFile,
} from '../../utils/itemUtils';
import { downloadS3File } from '../../utils/fileUtils';
import { getCurrencySymbol } from '../../utils/currencyUtils';

import {
  ppe3dpInfillOptions,
  ppe3dpLayerThicknessOptions,
} from '../../constants/PPEConstants';
import TechnologyInputField from '../../components/inputs/TechnologyInputField';
import { useItemInputConfig } from '../../hooks/useItemInputConfig';
import MaterialCategoriesInputField from '../../components/inputs/MaterialCategoriesInputField';
import {
  isCustomMaterial,
  isCustomSurfaceFinish,
  isCustomTechnology,
} from '../../utils/inputUtils';
import clsx from 'clsx';
import ThreeDPrintingTechnologyInputField from '../../components/inputs/ThreeDPrintingTechnologyInputField';
import ThreeDPrintingMaterialField from '../../components/inputs/ThreeDPrintingMaterialField';
import SurfaceFinishingField from '../../components/inputs/SurfaceFinishingField';
import ColorInputField from '../../components/inputs/ColorInputField';
import { getPpePriceForItem } from '../../utils/ppeUtils';
import InfoIcon from '../../components/icons/InfoIcon';
import ToleranceDropdown from '../../components/dropdowns/ToleranceDropdown';
import { convertFromMetric, convertToMetric } from '../../utils/userUtils';
import { isEmptyValue } from '../../utils/commonUtils';
import { notifyError } from '../../services/notificationService';

// Style component
const useStyles = makeStyles((theme) => ({
  body: {
    height: '100%',
  },
  breadcrumbsWrapper: {
    padding: '14px 45px 0',
    fontSize: '11pt',
  },
  breadcrumbsLink: {
    textDecoration: 'none',
    color: colors.solidBlue,
  },
  colorButton: {
    fontSize: 15,
    padding: '0px 8px',
    border: `1px solid ${colors.inputBorderBlue}`,
    boxSizing: 'border-box',
    borderRadius: '5px',
    color: `${colors.fontGrey}`,
    textTransform: 'none',
    justifyContent: 'left',
    height: '32px',
  },
  formComponent: {
    height: '75%',
    padding: '1rem 2rem',
  },
  inputField: {
    fontSize: 15,
    padding: '0px 8px',
    background: colors.fontWhite,
    border: `1px solid ${colors.inputBorderBlue}`,
    boxSizing: 'border-box',
    borderRadius: '5px',
    color: `${colors.fontGrey}`,
    width: '60%',
  },
  inputFieldRight: {
    fontSize: 15,
    padding: '0px 8px',
    background: colors.fontWhite,
    border: `1px solid ${colors.inputBorderBlue}`,
    boxSizing: 'border-box',
    borderRadius: '5px',
    color: `${colors.fontGrey}`,
    width: '40%',
    marginLeft: 10,
  },
  disabledInputField: {
    border: `1px solid ${colors.lightGray}`,
  },
  inputLabel: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 12,
    color: colors.fontLightGrey,
    fontWeight: '600',
    textTransform: 'uppercase',
    // width: '20%',
    width: 150,
    minWidth: 150,
  },
  rightSectionLabel: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 12,
    color: colors.fontLightGrey,
    fontWeight: '600',
    textTransform: 'uppercase',
  },
  rightSectionField: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 12,
    color: colors.fontDarkGrey,
    fontWeight: '600',
    textTransform: 'uppercase',
    marginTop: 7,
  },
  sectionTitle: {
    textTransform: 'uppercase',
    letterSpacing: '0.05em',
    fontSize: 12,
    color: colors.fontDarkGrey,
    fontWeight: '600',
  },
  sectionSubtitle: {
    fontSize: 13,
    color: colors.fontLightGrey,
    marginTop: 7,
  },
  cncErrorMessage: {
    fontSize: 13,
    color: colors.errorRed,
    marginTop: 5,
    fontWeight: '100',
  },
  gridBorder: {
    border: `1px solid ${colors.bgLightGrey}`,
    padding: 10,
    borderBottom: 0,
    display: 'flex',
    flexDirection: 'column',
  },
  rightGridBorder: {
    border: `1px solid ${colors.bgLightGrey}`,
    padding: 10,
    borderLeft: 0,
    display: 'flex',
    flexDirection: 'column',
  },
  formHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 8,
  },
  sectionForm: {
    margin: '20px 0',
  },
  sectionFormField: {
    display: 'flex',
    margin: '15px 0',
  },
  rightSectionFormField: {
    display: 'flex',
    flexDirection: 'column',
    margin: '15px 0',
  },
  commentInputField: {
    fontSize: 15,
    padding: 8,
    background: colors.fontWhite,
    border: `1px solid ${colors.inputBorderBlue}`,
    boxSizing: 'border-box',
    borderRadius: '5px',
    color: `${colors.fontGrey}`,
    width: '100%',
  },
  sectionFiles: {
    margin: '15px 0 0',
  },
  noFilesSection: {
    height: 40,
    background: colors.bgLightGrey,
    borderRadius: '5px',
    display: 'flex',
    alignItems: 'center',
    padding: '0 10px',
  },
  fileUploadWording: {
    fontSize: 14,
    color: colors.fontLightGrey,
    marginLeft: 10,
  },
  buttonWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  fileItem: {
    backgroundColor: colors.uploadFileBg,
    margin: '5px 0',
    height: 35,
    border: `solid 1px ${colors.uploadFileBorder}`,
    borderRadius: '5px',
    color: 'inherit',
    cursor: 'pointer',
  },
  itemText: {
    '& span, & svg': {
      fontSize: 14,
    },
  },
  breadcrumbLink: {
    color: colors.tertiaryBlue,
    fontSize: '11pt',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  breadcrumbLinkBold: {
    fontSize: '11pt',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: colors.fontWhite,
  },
  backdropContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const BootstrapInput = withStyles((theme) => ({
  input: {
    minWidth: '200px',
    borderRadius: 5,
    position: 'relative',
    backgroundColor: colors.fontWhite,
    border: `1px solid ${colors.inputBorderBlue}`,
    height: '26px',
    display: 'flex',
    alignItems: 'center',
    fontSize: 15,
    padding: '3px 8px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    '&:focus': {
      borderRadius: 4,
      borderColor: colors.inputBorderBlue,
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}))(InputBase);

function SummaryAddPart(props) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { openSummaryPage, userID, currency, myUnitType } = props;

  const [otherTechnology, setOtherTechnology] = useState('');
  const [otherMaterial, setOtherMaterial] = useState('');
  const [otherSurfaceFinish, setOtherSurfaceFinish] = useState('');
  const [itemName, setItemName] = useState('');
  const itemStatus = 1;
  // const [technology, setTechnology] = useState(TECHNOLOGY_OPTIONS[0]);
  // const [material, setMaterial] = useState(ALUMINUM_6061);
  const [material, setMaterial] = useState(null);
  const [technology, setTechnology] = useState(null);
  const [threeDTechnology, setThreeDTechnology] = useState(null);
  const [surfaceFinish, setSurfaceFinish] = useState(null);
  // const [materialColorOptions, setMaterialColorOptions] = useState(null);
  const [materialColor, setMaterialColor] = useState(null);
  const [color, setColor] = useState('');

  const [
    {
      technologyOptions,
      materialCategoryOptions,
      threeDTechnologyOptions,
      threeDMaterialOptions,
      surfaceFinishOptions,
      materialColorOptions,
      surfaceFinishColorOptions,
      defaultThreeDMaterial,
      selectColorSurfaces,
    },
    {
      loadSelectColorSurfaces,
      loadTechnologyOptions,
      loadMaterialCategoryOptions,
      load3DTechnologyOptions,
      loadThreeDMaterialOptions,
      loadSurfaceFinishOptions,
      loadMaterialColorOptions,
      loadSurfaceFinishColorOptions,
    },
  ] = useItemInputConfig({
    setTechnology,
    setMaterial,
    setThreeDTechnology,
    setSurfaceFinish,
    setMaterialColor,
    setColor,
  });

  const [threeDInfill, setThreeDInfill] = useState('');
  const [threeDLayerThickness, setThreeDLayerThickness] = useState('');
  const [itemTolerance, setItemTolerance] = useState(
    STANDARD_TOLERANCE_OPTIONS[myUnitType][3]
  );
  const [itemRemarks, setItemRemarks] = useState('');
  const [itemQty, setItemQty] = useState(1);
  const [itemPartApplication, setItemPartApplication] = useState('');
  const [
    cadPartListState,
    { removeCadPart, uploadCadFile },
  ] = useCadPartFileUpload(dispatch);
  const techIs3DPrinting = technology === '3D Printing';
  const surfaceFinishIsPainting = surfaceFinish === 'Painting';
  const surfaceFinishIsCoating = surfaceFinish === 'Powder Coating';
  const [loading, setLoading] = useState(false);
  const isMounted = useRef(true);

  const [targetPrice, setTargetPrice] = useState('');
  const [expectedLeadTime, setExpectedLeadTime] = useState(null);
  const [unitType, setUnitType] = useState(myUnitType);

  const toleranceInMetric = convertToMetric(itemTolerance, unitType);

  /**
   * If technology is 3D Printing and surface finish is painting
   * or powder coating, color input label will take on the label
   * of the surface finish
   */
  let colorInputLabel = surfaceFinishIsPainting
    ? 'Paint Color'
    : surfaceFinishIsCoating
    ? 'Powder Coat Color'
    : 'Color';

  useEffect(() => {
    loadTechnologyOptions();
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (isEmpty(technology)) {
      return;
    }
    const params = {
      technology,
    };
    loadSelectColorSurfaces(params);
    setItemTolerance(
      TOLERANCE_OPTIONS[unitType][technology] ||
        STANDARD_TOLERANCE_OPTIONS[unitType][3]
    );
    if (technology !== TECHNOLOGY_OPTION_TYPE.THREE_D_PRINTING) {
      loadMaterialCategoryOptions(params);
    } else {
      load3DTechnologyOptions();
    }
  }, [technology]);

  useEffect(() => {
    if (isEmpty(threeDTechnology)) {
      return;
    }
    const params = {
      technology,
      threeDTechnology,
    };
    loadThreeDMaterialOptions(params);
    setItemTolerance(
      THREE_D_TOLERANCE_OPTIONS[unitType][threeDTechnology] ||
        STANDARD_TOLERANCE_OPTIONS[unitType][1]
    );
    if (threeDTechnology === THREE_D_P_FDM_TECH) {
      setThreeDInfill(threeDPrintingInfillDefault);
      setThreeDLayerThickness(threeDPrintingLayerThicknessDefault);
    } else {
      setThreeDInfill('');
      setThreeDLayerThickness('');
    }
  }, [threeDTechnology]);

  useEffect(() => {
    if (isEmpty(material) || isEmpty(technology)) {
      return;
    }
    const params = {
      technology,
      threeDTechnology,
      material,
    };
    loadSurfaceFinishOptions(params);
    loadMaterialColorOptions(params);
  }, [material]);

  useEffect(() => {
    if (isEmpty(surfaceFinish)) {
      return;
    }
    const params = {
      technology,
      material,
      surfaceFinish,
    };
    loadSurfaceFinishColorOptions(params);
  }, [surfaceFinish]);

  useEffect(() => {
    if (cadPartListState && !isEmpty(cadPartListState.data)) {
      const name = getItemNameFromCadFile(cadPartListState.data[0].link);
      setItemName(name);
      // setIs2DImageGenerating(cadPartListState.data.some(item => item.imageLoading === true));
    }
  }, [cadPartListState]);

  const handleSubmitColor = (selectedColor) => {
    setColor(selectedColor || color);
  };

  function handleCadFilesChange(files) {
    Promise.all(
      Array.from(files).map(async (file) => {
        uploadCadFile(file);
      })
    );
  }
  const renderAddPartButton = () => {
    return (
      <div className={classes.buttonWrapper}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {cadPartListState.filesUploadStatus === 'ongoing' && (
            <CircularProgress
              style={{
                width: '1.2rem',
                height: '1.2rem',
              }}
              color='primary'
            />
          )}
          &nbsp;
          <Button
            size='large'
            style={{ color: colors.tertiaryBlue }}
            onClick={() => handleSubmitForm()}
            disabled={cadPartListState.filesUploadStatus === 'ongoing'}
          >
            <FolderOutlinedIcon style={{ fontSize: 20 }} />
            <div style={{ marginLeft: 4 }}>Add Part</div>
          </Button>
        </div>
      </div>
    );
  };

  async function handleSubmitForm() {
    if (!itemName) {
      toast.error('Missing name field.', {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else if (!itemQty) {
      toast.error('Missing quantity field.', {
        position: toast.POSITION.TOP_RIGHT,
      });
      // } else if (itemFiles.length === 0) {
    } else if (isEmpty(cadPartListState.data)) {
      toast.error('No files were uploaded. Please upload at least one file', {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      if (isCustomTechnology(technology) && isEmpty(otherTechnology)) {
        notifyError('Missing Custom Technology field.');
        return;
      }
      if (isCustomMaterial(material) && isEmpty(otherMaterial)) {
        notifyError('Missing Custom Material field.');
        return;
      }
      if (isCustomSurfaceFinish(surfaceFinish) && isEmpty(otherSurfaceFinish)) {
        notifyError('Missing Custom Surface Finish field.');
        return;
      }
      if (!isEmpty(surfaceFinishColorOptions) && isEmptyValue(color)) {
        notifyError('Missing Color field.');
        return;
      }
      setLoading(true);
      const formDataAvailable = props.location.state.formDataAvailable;

      // Create new part and details
      let updatedPart = {
        id: formDataAvailable.length,
        status: itemStatus,
        qty: itemQty,
        technology,
        otherTechnology,
        surfaceFinish,
        otherSurfaceFinish,
        material,
        materialColor,
        otherMaterial,
        cadPart: cadPartListState.data.map((item) => item.link),
        cadPartObjectList: [...cadPartListState.data],
        userID: userID,
        tolerance: toleranceInMetric,
        remarks: itemRemarks,
        checked: false,
        partApplication: itemPartApplication,
        name: itemName,
        deleted: false,
        imageFile: '',
        deliveryPreference: 'on_premise',
        color,
        expectedLeadTime: expectedLeadTime
          ? expectedLeadTime.toISOString()
          : null, // to match summary page format
        targetPrice: targetPrice ? targetPrice : null,
        price: null,
        markupPercent: null,
        totalPrice: null,
        originalPrice: null,
        [TWO_D_IMAGE_URLS_KEY]: cadPartListState.data.map(
          (item) => item['2DImageUrl']
        ),
        onlySingleFile: true,
        unitType,
      };
      if (techIs3DPrinting) {
        updatedPart = {
          ...updatedPart,
          threeDTechnology,
          threeDInfill,
          threeDLayerThickness,
        };
      }

      try {
        updatedPart = await getPpePriceForItem(updatedPart);
      } catch (err) {
        //pass
      }

      // Copy and push the new item into the form
      const newFormData = formDataAvailable;
      newFormData.push(updatedPart);
      if (isMounted.current) {
        setLoading(false);
      }
      openSummaryPage(newFormData);
    }
  }

  const render3DInfillSelect = () => {
    return (
      <div className={classes.sectionFormField}>
        <InputLabel className={classes.inputLabel}>3D Infill</InputLabel>
        <Select
          id='3d-infill'
          input={<BootstrapInput />}
          value={threeDInfill}
          onChange={(evt) => setThreeDInfill(evt.target.value)}
        >
          {ppe3dpInfillOptions.map((option) => {
            const displayedText = `${Math.round(option * 100)}%`;
            return (
              <MenuItem key={displayedText} value={option}>
                {displayedText}
              </MenuItem>
            );
          })}
        </Select>
      </div>
    );
  };

  const render3DLayerThicknessSelect = () => {
    return (
      <div className={classes.sectionFormField}>
        <InputLabel className={classes.inputLabel}>Layer Thickness</InputLabel>
        <Select
          id='3d-layer-thickness'
          input={<BootstrapInput />}
          value={threeDLayerThickness}
          onChange={(evt) => setThreeDLayerThickness(evt.target.value)}
        >
          {ppe3dpLayerThicknessOptions['FDM'].map((option) => {
            const displayedText = `${option.toFixed(2)} mm`;
            return (
              <MenuItem key={displayedText} value={option}>
                {displayedText}
              </MenuItem>
            );
          })}
        </Select>
      </div>
    );
  };

  const renderTargetPriceField = () => {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <InputLabel
          className={classes.inputLabel}
          style={{
            width: 'auto',
          }}
        >
          Target Unit Price
        </InputLabel>
        <Input
          className={classes.inputField}
          style={{
            width: '100%',
          }}
          disableUnderline={true}
          inputProps={{
            type: 'number',
          }}
          value={targetPrice}
          onChange={(evt) => setTargetPrice(evt.target.value)}
          startAdornment={
            <InputAdornment position='start'>
              {getCurrencySymbol(currency)}
            </InputAdornment>
          }
        />
      </div>
    );
  };

  const renderTargetPriceAndExpectedLeadTime = () => {
    return (
      <Grid
        container
        spacing={2}
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Grid item xs={4}>
          {renderTargetPriceField()}
        </Grid>
        <Grid item xs={5}>
          <Grid
            container
            spacing={1}
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Grid item xs={4}>
              <InputLabel
                className={classes.inputLabel}
                style={{
                  width: 'auto',
                }}
              >
                <span
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  Lead Time&nbsp;
                  <InfoIcon toolTipText='When do you need these parts by?' />
                </span>
              </InputLabel>
            </Grid>
            <Grid item xs={8}>
              <DatePicker
                value={expectedLeadTime}
                onChange={setExpectedLeadTime}
                animateYearScrolling
                inputVariant='outlined'
                margin='dense'
                fullWidth
                clearable
                clearLabel='No Preference'
                emptyLabel='No Preference'
                disablePast
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const renderSurfaceFinishColor = () => {
    return (
      <ColorInputField
        visible={!isEmpty(surfaceFinishColorOptions)}
        value={color}
        colorInputLabel={colorInputLabel}
        colorPalette={surfaceFinishColorOptions}
        onSubmit={(selectedColor) => handleSubmitColor(selectedColor)}
        rowDisplay
      />
    );
  };

  const renderMaterialColorInputField = () => {
    return (
      <ColorInputField
        visible={!isEmpty(materialColorOptions)}
        value={materialColor}
        colorInputLabel='Material Color'
        colorPalette={materialColorOptions}
        onSubmit={(selectedColor) =>
          setMaterialColor(selectedColor || materialColor)
        }
        rowDisplay
      />
    );
  };

  const content = (
    <Fragment>
      <Grid container spacing={0}>
        <Grid container item direction='column' spacing={0} xs={12} md={8}>
          <Grid item>
            <div className={classes.gridBorder}>
              <div className={classes.sectionTitle}>
                Upload technical drawings
              </div>
              <div className={classes.sectionSubtitle}>
                Upload technical drawings indicating critical requirements and
                specify standard. Any dimensions not indicated will be kept to
                our{' '}
                <a
                  href='https://factorem.medium.com/factorems-iso-2768-machining-guidelines-74a44cc75e5b'
                  style={{ color: colors.fontDarkGrey }}
                >
                  ISO 2768 Medium standard
                </a>
                . You may also upload any additional files relevant to this part
                here.
              </div>
              {technology === 'CNC Machining' &&
              !cadPartListState.data.some(
                ({ link: fileName }) =>
                  fileName && fileName.toLowerCase().includes('.pdf')
              ) ? (
                <div className={classes.cncErrorMessage}>
                  Please upload complementing 2D files/technical drawings to
                  your parts here. This will help us ensure that we don’t miss
                  out on any component details.
                </div>
              ) : (
                <div />
              )}
              <div className={classes.sectionForm}>
                <BlueDragDropSmall handleFiles={handleCadFilesChange} />
                <div className={classes.sectionFiles}>
                  {isEmpty(cadPartListState.data) ? (
                    <div className={classes.noFilesSection}>
                      <InsertDriveFileOutlinedIcon
                        style={{ color: colors.fontLightGrey, fontSize: 20 }}
                      />
                      <div className={classes.fileUploadWording}>
                        No files uploaded
                      </div>
                    </div>
                  ) : (
                    <div />
                  )}
                  <List>
                    {cadPartListState.data.map(({ link }, index) => {
                      if (isEmptyValue(link)) {
                        return;
                      }
                      const fileName = getFileNameFromCadFile(link);
                      return (
                        <ListItem
                          key={index}
                          className={classes.fileItem}
                          component='a'
                          onClick={(e) => {
                            e.stopPropagation();
                            downloadS3File(link);
                          }}
                        >
                          <ListItemAvatar style={{ display: 'flex' }}>
                            <img
                              src={FileIcon}
                              alt='icon'
                              style={{ height: 15 }}
                            />
                          </ListItemAvatar>
                          <ListItemText
                            style={{
                              width: 130,
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                              fontSize: 12,
                            }}
                            primary={fileName}
                            className={classes.itemText}
                          />
                          <ListItemSecondaryAction>
                            <IconButton
                              edge='end'
                              aria-label='delete'
                              onClick={() => removeCadPart(link)}
                            >
                              <CancelIcon
                                color='error'
                                style={{ fontSize: '15pt' }}
                              />
                            </IconButton>
                          </ListItemSecondaryAction>
                        </ListItem>
                      );
                    })}
                  </List>
                </div>
              </div>
            </div>
          </Grid>
          <Grid item>
            <div className={classes.gridBorder}>
              <div className={classes.sectionTitle}>Order specifications</div>
              <div className={classes.sectionForm}>
                <div className={classes.sectionFormField}>
                  <InputLabel className={classes.inputLabel}>
                    Order Name
                  </InputLabel>
                  <InputBase
                    id='name'
                    name='name'
                    className={classes.inputField}
                    value={itemName}
                    onChange={(evt) => {
                      setItemName(evt.target.value);
                    }}
                  />
                </div>
                <div className={classes.sectionFormField}>
                  <InputLabel className={classes.inputLabel}>
                    Part Application
                  </InputLabel>
                  <InputBase
                    id='partApplication'
                    name='partApplication'
                    className={classes.inputField}
                    value={itemPartApplication}
                    onChange={(evt) => {
                      setItemPartApplication(evt.target.value);
                    }}
                  />
                </div>
                <div className={classes.sectionFormField}>
                  <InputLabel className={classes.inputLabel}>
                    Quantity
                  </InputLabel>
                  <Input
                    className={classes.inputField}
                    disableUnderline={true}
                    inputProps={{
                      step: 1,
                      min: 0,
                      max: 100000,
                      type: 'number',
                    }}
                    value={itemQty}
                    onChange={(evt) => {
                      setItemQty(evt.target.value);
                    }}
                  />
                </div>
                {renderTargetPriceAndExpectedLeadTime()}
              </div>
            </div>
          </Grid>
          <Grid item>
            <div className={classes.gridBorder}>
              <div className={classes.sectionTitle}>Part specifications</div>
              <div className={classes.sectionForm}>
                <div className={classes.sectionFormField}>
                  <TechnologyInputField
                    value={technology}
                    onChange={(newTech) => setTechnology(newTech)}
                    technologyOptions={technologyOptions}
                    rowDisplay
                  />
                  <InputBase
                    disabled={!isCustomTechnology(technology)}
                    id='otherTechnology'
                    name='otherTechnology'
                    placeholder='Custom Technology'
                    type='text'
                    className={clsx(
                      classes.inputFieldRight,
                      !isCustomTechnology(technology)
                        ? classes.disabledInputField
                        : null
                    )}
                    onChange={(evt) => setOtherTechnology(evt.target.value)}
                    value={otherTechnology}
                  />
                </div>
                <ThreeDPrintingTechnologyInputField
                  visible={techIs3DPrinting}
                  value={threeDTechnology}
                  onChange={(newValue) => setThreeDTechnology(newValue)}
                  threeDTechnologyOptions={threeDTechnologyOptions}
                  rowDisplay
                  shortLabel
                />
                <div className={classes.sectionFormField}>
                  <MaterialCategoriesInputField
                    technology={technology}
                    visible={!techIs3DPrinting}
                    value={material}
                    onSelect={(value) => setMaterial(value)}
                    materialCategoryOptions={materialCategoryOptions}
                    rowDisplay
                  />
                  <ThreeDPrintingMaterialField
                    technology={technology}
                    threeDTechnology={threeDTechnology}
                    visible={techIs3DPrinting}
                    value={material}
                    onSelect={(_material) => setMaterial(_material)}
                    threeDMaterialOptions={threeDMaterialOptions}
                    defaultThreeDMaterial={defaultThreeDMaterial}
                    rowDisplay
                  />
                  <InputBase
                    disabled={!isCustomMaterial(material)}
                    id='otherMaterial'
                    name='otherMaterial'
                    placeholder='Custom Material'
                    type='text'
                    className={clsx(
                      classes.inputFieldRight,
                      !isCustomMaterial(material)
                        ? classes.disabledInputField
                        : null
                    )}
                    onChange={(evt) => {
                      setOtherMaterial(evt.target.value);
                    }}
                    value={otherMaterial}
                  />
                </div>
                {!isEmpty(materialColorOptions) && (
                  <div className={classes.sectionFormField}>
                    {renderMaterialColorInputField()}
                  </div>
                )}
                {techIs3DPrinting &&
                  threeDTechnology === THREE_D_P_FDM_TECH &&
                  render3DInfillSelect()}
                {techIs3DPrinting &&
                  threeDTechnology === THREE_D_P_FDM_TECH &&
                  render3DLayerThicknessSelect()}
                {(!isEmpty(surfaceFinishOptions) ||
                  isCustomSurfaceFinish(surfaceFinish)) && (
                  <div className={classes.sectionFormField}>
                    <SurfaceFinishingField
                      visible={
                        !isEmpty(surfaceFinishOptions) ||
                        isCustomSurfaceFinish(surfaceFinish)
                      }
                      value={surfaceFinish}
                      onSelect={(newValue) => setSurfaceFinish(newValue)}
                      surfaceFinishOptions={surfaceFinishOptions}
                      selectColorSurfaces={selectColorSurfaces}
                      rowDisplay
                    />
                    {!isEmpty(surfaceFinishOptions) && (
                      <InputBase
                        disabled={!isCustomSurfaceFinish(surfaceFinish)}
                        id='otherSurfaceFinish'
                        name='otherSurfaceFinish'
                        placeholder='Other surface finish'
                        type='text'
                        className={clsx(
                          classes.inputFieldRight,
                          !isCustomSurfaceFinish(surfaceFinish)
                            ? classes.disabledInputField
                            : null
                        )}
                        onChange={(evt) => {
                          setOtherSurfaceFinish(evt.target.value);
                        }}
                        value={otherSurfaceFinish}
                      />
                    )}
                  </div>
                )}
                {!isEmpty(surfaceFinishColorOptions) && (
                  <div className={classes.sectionFormField}>
                    {renderSurfaceFinishColor()}
                  </div>
                )}
                <div className={classes.sectionFormField}>
                  <InputLabel className={classes.inputLabel}>
                    Tolerance&nbsp;
                    <InfoIcon toolTipText='Please indicate tightest tolerance in your design' />
                  </InputLabel>
                  <ToleranceDropdown
                    value={itemTolerance}
                    onChange={(evt) => {
                      setItemTolerance(evt.target.value);
                    }}
                    setUnitType={(value) => {
                      setUnitType(value);
                      setItemTolerance(
                        convertFromMetric(toleranceInMetric, value)
                      );
                    }}
                    unitType={unitType}
                  />
                </div>
              </div>
            </div>
          </Grid>
          <Grid item>
            <div
              className={classes.gridBorder}
              style={{ borderBottom: `1px solid ${colors.bgLightGrey}` }}
            >
              <div className={classes.sectionTitle}>Comments</div>
              <div className={classes.sectionFormField}>
                <InputBase
                  multiline
                  rows='6'
                  id='remarks'
                  name='remarks'
                  type='text'
                  placeholder='Type additional comments here'
                  value={itemRemarks}
                  onChange={(evt) => {
                    setItemRemarks(evt.target.value);
                  }}
                  className={classes.commentInputField}
                />
              </div>
              {renderAddPartButton()}
            </div>
          </Grid>
        </Grid>
        <Grid item xs={12} md={4}>
          <div className={classes.rightGridBorder}>
            <div className={classes.rightSectionFormField}>
              <div className={classes.rightSectionLabel}>Qty required</div>
              <div className={classes.rightSectionField}>{itemQty}</div>
            </div>
          </div>
        </Grid>
      </Grid>
    </Fragment>
  );

  return (
    <Fragment>
      <div className={classes.formComponent}>
        <div className={classes.formHeader}>
          <div>
            <Breadcrumbs aria-label='breadcrumb'>
              <Link
                className={classes.breadcrumbLink}
                onClick={() => props.history.goBack()}
                aria-current='page'
              >
                All parts
              </Link>
              <Typography className={classes.breadcrumbLinkBold}>
                Add new part
              </Typography>
            </Breadcrumbs>
          </div>
          {renderAddPartButton()}
        </div>
        {content}
      </div>
      <Backdrop className={classes.backdrop} open={loading}>
        <div className={classes.backdropContent}>
          <CircularProgress color='inherit' />
          <Typography
            style={{
              textAlign: 'center',
            }}
          >
            Please wait for a couple of seconds while we generate the best price
            for you. Do not refresh this page or close the browser.
          </Typography>
        </div>
      </Backdrop>
    </Fragment>
  );
}

function mapStateToProps(state) {
  return {
    userId: state.auth.user.userID,
    currency: state.auth.location.currency,
    exchangeRate: state.auth.rates[state.auth.location.currency],
    myUnitType: state.auth.user.unitType,
  };
}

function matchDispatchToProps(dispatch, props) {
  return {
    openSummaryPage: (formDataAvailable) =>
      dispatch(openPartSummaryPage(formDataAvailable, props)),
  };
}

const withConnect = connect(mapStateToProps, matchDispatchToProps);
export default withRouter(withConnect(SummaryAddPart));
