import React from 'react';

import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Slide,
} from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close';

import { FtrButton } from '../../components/ftr-components';
import {
  FlexColumn,
  FlexRowCenter,
} from '../../components/layouts/FlexLayouts';
import AutoBalloonPresentational from '../generation-tools/AutoBalloonPresentational';

import { isEmptyValue } from '../../utils/commonUtils';

import { colors } from '../../palette';

// -------------------------------------------------------------------------------------------------

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='left' ref={ref} {...props} />;
});

function VerifyBalloonPopup(props) {
  const {
    open,
    handleClose,
    originalImageUrls = [],
    annotationData = [],
    totalPages = 0,
    annotatedImageUrls = [],
    autoBallooning = false,
    manualBallooning = false,
    deletingBalloon = false,
    downloadingReport = false,
    downloadingPdf = false,
    verifying = false,
    saving = false,
    handleDeleteBalloon = () => {},
    addManualBalloons = () => {},
    setAnnotationData = () => {},
    handleDownloadReportCsv = () => {},
    handleDownloadPdf = () => {},
    verifyItemBalloon = () => {},
    saveItemBalloon = () => {},
  } = props;

  const processing =
    verifying ||
    saving ||
    deletingBalloon ||
    manualBallooning ||
    downloadingReport ||
    downloadingPdf;

  return (
    <Dialog
      fullScreen
      open={open}
      aria-labelledby='form-dialog-title'
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <DialogTitle style={{ padding: 0 }}>
        <IconButton
          onClick={handleClose}
          style={{
            position: 'absolute',
            right: 20,
            top: 10,
            color: 'black',
            backgroundColor: colors.lightGray,
            padding: '0.3rem',
          }}
          size='small'
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <FlexColumn style={{ padding: '1rem', boxSizing: 'border-box' }}>
          <AutoBalloonPresentational
            autoBallooning={autoBallooning}
            manualBallooning={manualBallooning}
            processing={processing}
            originalImageUrls={originalImageUrls}
            annotationData={annotationData}
            totalPages={totalPages}
            annotatedImageUrls={annotatedImageUrls}
            handleDeleteBalloon={handleDeleteBalloon}
            addManualBalloons={addManualBalloons}
            setAnnotationData={setAnnotationData}
          />
        </FlexColumn>
      </DialogContent>
      <DialogActions
        style={{ padding: '1rem', borderTop: '1px solid #e0e0e0' }}
      >
        <FlexRowCenter
          style={{ width: '100%', gap: '1rem', boxSizing: 'border-box' }}
        >
          {!isEmptyValue(annotationData) && (
            <FtrButton
              color='blue'
              variant='outlined'
              onClick={handleDownloadReportCsv}
              disabled={processing}
              loading={downloadingReport}
            >
              Download Report CSV
            </FtrButton>
          )}
          {!isEmptyValue(annotatedImageUrls) && (
            <FtrButton
              color='blue'
              variant='outlined'
              onClick={handleDownloadPdf}
              disabled={processing}
              loading={downloadingPdf}
            >
              Download PDF
            </FtrButton>
          )}
          <Box style={{ width: '3rem' }} />
          <FtrButton
            color='gray'
            variant='contained'
            onClick={handleClose}
            disabled={processing}
          >
            Cancel
          </FtrButton>
          <FtrButton
            color='blue'
            variant='contained'
            onClick={saveItemBalloon}
            disabled={processing}
            loading={saving}
          >
            Save
          </FtrButton>
          <Box style={{ width: '3rem' }} />
          <FtrButton
            variant='contained'
            onClick={verifyItemBalloon}
            disabled={processing}
            loading={verifying}
            style={{ backgroundColor: colors.green040 }}
          >
            Verify Balloon
          </FtrButton>
        </FlexRowCenter>
      </DialogActions>
    </Dialog>
  );
}

export default VerifyBalloonPopup;
