import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import ImageWith3DViewer from '../../images/ImageWith3DViewer';
import QuoteDetailsV2InfoPresentational from './QuoteDetailsV2InfoPresentational';
import { FlexColumn, FlexRow } from '../../layouts/FlexLayouts';
import { FtrBoldText, FtrNormalText, FtrTextArea } from '../../ftr-components';

import useDeviceCheck from '../../../hooks/useDeviceCheck';

import { getExchangeRateSelector } from '../../../selectors/exchangeRatesSelector';
import { getUserCurrencySelector } from '../../../selectors/userSelector';

import { colors } from '../../../palette';

// ------------------------------------------------------------------------------

function QuoteDetailsV2(props) {
  const {
    partInfo = {},
    quoteInfo,
    remark = '',
    handleRemarks = () => {},
  } = props;

  const exchangeRate = useSelector(getExchangeRateSelector);
  const currency = useSelector(getUserCurrencySelector);

  const [{ isMobile }] = useDeviceCheck();

  const itemImageUrl = useMemo(() => {
    const url = partInfo.imageFile || partInfo.twoDImageUrl || '';
    return url.startsWith('http') ? url : null;
  }, [partInfo]);

  const imageSize = isMobile ? 160 : 120;

  const renderImage = () => {
    return (
      <ImageWith3DViewer
        key={itemImageUrl}
        twoDImageUrl={itemImageUrl}
        cadFile={partInfo.cadFile || partInfo.originalFiles}
        width={imageSize}
        height={imageSize}
        noBorder
        borderRadius={12}
      />
    );
  };

  const renderQuoteDetailsInfo = () => {
    return (
      <QuoteDetailsV2InfoPresentational
        quoteInfo={quoteInfo}
        partInfo={partInfo}
        currency={currency}
        exchangeRate={exchangeRate}
      />
    );
  };

  const renderRemarks = () => {
    return (
      <FlexColumn style={{ flex: '1 1 auto' }}>
        <FtrBoldText fontSize='16'>Supplier Comments</FtrBoldText>
        <FtrNormalText style={{ color: colors.neutral060 }}>
          {quoteInfo.remarks ? quoteInfo.remarks : 'NIL'}
        </FtrNormalText>
        <FtrBoldText fontSize='16' style={{ marginTop: '1rem' }}>
          Final Remarks
        </FtrBoldText>
        <FtrTextArea
          placeholder='Optional'
          value={remark}
          onChange={handleRemarks}
        />
      </FlexColumn>
    );
  };

  return (
    <>
      {!isMobile && (
        <FlexRow
          style={{
            alignItems: 'start',
            width: '100%',
          }}
        >
          {renderImage()}
          <span style={{ width: '2rem' }} />
          {renderQuoteDetailsInfo()}
          {renderRemarks()}
        </FlexRow>
      )}
      {isMobile && (
        <FlexColumn
          style={{
            alignItems: 'start',
            width: '100%',
          }}
        >
          {renderImage()}
          <span style={{ height: '2rem' }} />
          {renderQuoteDetailsInfo()}
          <span style={{ height: '2rem' }} />
          {renderRemarks()}
        </FlexColumn>
      )}
    </>
  );
}

export default QuoteDetailsV2;
