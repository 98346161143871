import React from 'react';
import { makeStyles } from '@material-ui/core/styles/index';

import { InputBase } from '@material-ui/core';

import { colors } from '../../../../palette';
import { renderSkeletonOverlay } from '../../../../components/util/skeleton';

const useStyles = makeStyles(() => ({
  inputField: {
    boxSizing: 'border-box',
    width: '100%',
    background: colors.fontWhite,
    minWidth: 150,
    fontSize: 16,
    fontWeight: 600,
    height: 40,
    color: colors.neutral080,
    padding: '2px 14px',
    borderRadius: 10,
    border: '0.8px solid #CCC',
  },
  emptyField: {
    '& .MuiInputBase-input': {
      color: colors.neutral040,
    },
  },
}));

function CustomInputField(props) {
  const classes = useStyles();

  const {
    visible = false,
    value,
    placeholder,
    onChange,
    loading,
    fullWidth = true,
  } = props;

  if (!visible) {
    return null;
  }

  return (
    <div
      style={{
        position: 'relative',
        ...(fullWidth && { width: '100%' }),
      }}
    >
      {loading && renderSkeletonOverlay()}
      <InputBase
        type='text'
        onChange={(evt) => onChange(evt.target.value)}
        className={`${classes.inputField}`}
        value={value}
        placeholder={placeholder}
        fullWidth={fullWidth}
      />
    </div>
  );
}

export default CustomInputField;
