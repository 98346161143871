import { ceil, get } from 'lodash';
import React, { useEffect, useReducer, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles/index';

import {
  Button,
  Icon,
  IconButton,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';

import { GetApp, Info, Visibility } from '@material-ui/icons';

import { DataGrid } from '@mui/x-data-grid';

import { PageTitle } from '../components/PageTitle';
import DownloadIconButton from '../components/buttons/DownloadIconButton';
import HorizontalExpandSpace from '../components/ftr-components/HorizontalExpandSpace';
import CustomToolbar, {
  DataGridToolbarLayout,
} from '../components/grid-data/CustomToolbar';
import GridDataPagination from '../components/grid-data/GridDataPagination';
import { ExportCsvButton } from '../components/grid-data/buttons/ExportCsvButton';
import SearchBar from '../components/grid-data/buttons/SearchBar';
import { InvoiceIcon } from '../components/icons/InvoiceIcon';
import ImageWith3DViewer from '../components/images/ImageWith3DViewer';
import SupplierQuoteDetailsDisplayPopup from '../components/popups/SupplierQuoteDetailsDisplayPopup';
import YesNoPopup from '../components/popups/YesNoPopup';
import OrderReadyStatusDisplay from './orderReady/OrderReadyStatusDisplay';

import FactCheckIcon from '../assets/icons/fact_check_blue.svg';

import withActionTrackingHOC from '../hocs/withActionTrackingHOC';

import { getUnAcknowledgedQuotationsCountAction } from '../actions/poAcknowledge';

import { combinedPOs } from '../apis/pdfApi';
import {
  getAcceptedQuotationsBySupplier,
  supplierBulkAcknowledgePO,
  supplierExportAcceptedQuotationsAsCsv,
} from '../apis/quotationApi';

import { isEmptyValue } from '../utils/commonUtils';
import { exportCsvApi } from '../utils/csvExportUtils';
import { convertPriceToCurrency } from '../utils/currencyUtils';
import {
  getDateStrWithMonth,
  getSGTDateStrWithMonth,
} from '../utils/dateTimeUtils';
import * as fileUtils from '../utils/fileUtils';
import {
  getPurchaseOrderID,
  getQuotationExchangeRate,
} from '../utils/quotationUtils';

import useDownloadS3FileWithTracking from '../hooks/useDownloadS3FileWithTrackingHook';

import { notifyError, notifySuccess } from '../services/notificationService';

import {
  materialWithColorCol,
  surfaceFinishingWithColorCol,
} from '../constants/itemTableConstants';
import {
  dateAcceptedCol,
  projectIDCol,
  quotationStatusColumn,
  supplierPriceStrDisplayCol,
} from '../constants/quotationTableConstants';

import { ORDER_STATUS, ROLE_TYPES } from '../constants';
import { TECHNOLOGY_OPTION_TYPE } from '../constants/NewPartConstants';
import { ACTION_TRACKING_TYPE } from '../constants/actionTrackingConstants';
import { ORDER_READY_STATUS } from '../constants/orderReadyConstants';

import { colors } from '../palette';

// -------------------------------------------------------------------------------------------------

const DownloadListFilesWithTrackingIconButton = withActionTrackingHOC(
  DownloadIconButton
);

const useStyles = makeStyles((theme) => ({
  label: {
    textAlign: 'right',
  },
  value: {
    textAlign: 'left',
  },
  body: {
    '& .MuiTablePagination-root': {
      marginRight: '4rem',
    },
  },
  imageIcon: {
    display: 'flex',
    height: 'inherit',
    width: 'inherit',
  },
  containerMenu: {
    padding: '0',
    display: 'flex',
    flexDirection: 'column',
    '& > :not(:last-child)': {
      borderBottom: `1px solid ${colors.lightGray}`,
    },
  },
  itemMenu: {
    padding: '5px',
    textDecoration: 'none',
    color: colors.fontGrey,
    fontSize: '0.8125rem',
    cursor: 'pointer',
    '&:is(:hover, :active)': {
      backgroundColor: colors.solidBlue,
      color: colors.fontWhite,
    },
  },
  listAcknowledgePartIDs: {
    textAlign: 'start',
    marginLeft: '50px',
  },
  title: {
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
  cardDisplay: {
    minWidth: 275,
    variant: 'elevated',
  },
  header: {
    fontSize: 14,
    paddingBottom: theme.spacing(1),
  },
  itemText: {
    '& span, & svg': {
      fontSize: 12,
    },
  },
  rowDetail: {
    color: colors.fontGrey,
    display: 'flex',
    fontSize: '15px',
    marginTop: 5,
    marginLeft: 20,
  },
  rowTitle: {
    fontWeight: 600,
  },
  rowContent: {
    marginLeft: 5,
    whiteSpace: 'pre-wrap',
    wordBreak: 'break-word',
  },
  orderReadyButton: {
    minWidth: '120px',
    textTransform: 'uppercase',
    background: colors.buttonColorBlueGradient,
    fontSize: '0.8rem',
    color: colors.fontWhite,
  },
  linkButton: {
    color: colors.blue050,
    textDecoration: 'underline',
    background: colors.paleBlue,
  },
}));

function SupplierAcceptedOrdersDataGrid(props) {
  const classes = useStyles();

  const dispatch = useDispatch();
  const history = useHistory();

  const { user, role, currency, exchangeRate } = props;

  const { downloadS3File } = useDownloadS3FileWithTracking();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { techTags, userID } = user;
  const is3DPrintingSupplier =
    techTags &&
    techTags.includes(TECHNOLOGY_OPTION_TYPE.THREE_D_PRINTING.toLowerCase());

  const [tableData, setTableData] = useState({
    rows: [],
    projectItemsCount: {},
  });
  const [
    selectedAcknowledgeQuotation,
    setSelectedAcknowledgeQuotation,
  ] = useState(null);
  const [
    openAcknowledgeQuotationPopup,
    setOpenAcknowledgeQuotationPopup,
  ] = useState(false);
  const [openQuoteDetails, setOpenQuoteDetails] = useState(false);
  const [selectedQuoteDetails, setSelectedQuoteDetails] = useState({});
  const [isFetching, setIsFetching] = useState(false);
  const [tableQueryParams, updateTableQueryParams] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      page: 0,
      pageSize: 10,
      search: '',
      totalCount: 0,
      loading: false,
    }
  );

  const loadTableData = () => {
    setIsFetching(true);
    getAcceptedQuotationsBySupplier(userID, tableQueryParams)
      .then((data) => {
        const { rows: allAcceptedQuotes } = data;
        if (isEmptyValue(allAcceptedQuotes)) {
          setTableData({
            rows: [],
            projectItemsCount: {},
          });
          return [];
        }

        const projectItemsCount = allAcceptedQuotes.reduce((acc, quote) => {
          const key = `${quote.projectID}-${quote.poAcknowledgedVersion}`;
          acc[key] = {
            total: (acc[key] ? acc[key].total : 0) + 1,
            itemIDs: acc[key]
              ? [...acc[key].itemIDs, quote.itemID]
              : [quote.itemID],
          };
          return acc;
        }, {});

        setTableData({
          rows: allAcceptedQuotes,
          projectItemsCount,
        });

        updateTableQueryParams({ totalCount: data.totalCount });
      })
      .finally(() => {
        setIsFetching(false);
      });
  };

  useEffect(() => {
    if (isEmptyValue(userID)) {
      return;
    }
    loadTableData();
  }, [userID]);

  const handleBulkAcknowledgePO = (projectID, poAcknowledgedVersion) => {
    supplierBulkAcknowledgePO({
      projectID,
      supplierID: userID,
      poAcknowledgedVersion,
    })
      .then(() => {
        notifySuccess('Acknowledged PO (project) Successfully');
        loadTableData();
        dispatch(getUnAcknowledgedQuotationsCountAction(userID));
      })
      .catch(() => notifyError('Acknowledged PO (project) failed'));
  };

  const handleDownloadPOProject = (projectID, combinedPOsForm) => {
    if (combinedPOsForm !== null) {
      downloadS3File(combinedPOsForm);
      return;
    }
    combinedPOs({ projectID, supplierID: userID })
      .then((data) => {
        downloadS3File(data.pdfUrl);
        loadTableData();
      })
      .catch(() => notifyError('Download PO (project) failed'));
  };

  const renderYes = (date) => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          lineHeight: 'normal',
        }}
      >
        <span style={{ color: 'green' }}>Yes</span>
        <div
          style={{
            fontWeight: 'bold',
            fontSize: '0.75rem',
            whiteSpace: 'nowrap',
          }}
        >
          {date}
        </div>
      </div>
    );
  };

  const onClickAcknowledgePO = (rowData) => {
    setSelectedAcknowledgeQuotation(rowData);
    setOpenAcknowledgeQuotationPopup(true);
  };

  const handleDownloadItemFiles = (rowData) => {
    const urlList = rowData.cadFile.split(',');
    const folderName = `part-${rowData.itemID}`;
    fileUtils.downloadAllS3FilesWithWaterMark(urlList, folderName);
  };

  const renderButtonAcknowledgePO = () => {
    return (
      <Tooltip title='Acknowledge PO'>
        <IconButton color='primary'>
          <Icon>
            <img
              className={classes.imageIcon}
              src={FactCheckIcon}
              color='primary'
              alt=''
            />
          </Icon>
        </IconButton>
      </Tooltip>
    );
  };

  const renderNoWithAcknowledgeButton = (rowData) => {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography>
          <span style={{ color: 'orange' }}>No</span>
        </Typography>
        <div onClick={() => onClickAcknowledgePO(rowData)}>
          {renderButtonAcknowledgePO()}
        </div>
      </div>
    );
  };

  const colPoAcknowledged = (rowData) => {
    return {
      exportData: () => {
        if (rowData.poAcknowledged === null) {
          return 'N.A.';
        }
        if (Boolean(rowData.poAcknowledged) === true) {
          return 'Yes';
        } else if (Boolean(rowData.poAcknowledged) === false) {
          return 'No';
        } else {
          return 'N.A.';
        }
      },
      render: () => {
        if (rowData.poAcknowledged === null) {
          return 'N.A.';
        }
        if (Boolean(rowData.poAcknowledged) === true) {
          let date = '';
          if (rowData.acknowledgedDate) {
            date = getDateStrWithMonth(rowData.acknowledgedDate);
          }
          return renderYes(date);
        } else if (Boolean(rowData.poAcknowledged) === false) {
          return renderNoWithAcknowledgeButton(rowData);
        } else {
          return 'N.A.';
        }
      },
    };
  };

  const colOrderReady = (rowData) => {
    const hasSupplierInvoices = !isEmptyValue(rowData.supplierInvoiceUrl);

    return {
      render: () => {
        const orderReadyPath = `projects/${rowData.projectID}/order-ready?version=${rowData.poAcknowledgedVersion}`;
        if (
          [
            ORDER_READY_STATUS.NEED_UPDATE,
            ORDER_READY_STATUS.SUBMITTED,
            ORDER_READY_STATUS.VERIFIED,
            ORDER_READY_STATUS.IN_TRANSIT,
            ORDER_READY_STATUS.READY_TO_SHIP,
            ORDER_READY_STATUS.DELIVERED,
          ].includes(rowData.orderReadyStatus)
        ) {
          const rowStatus = rowData.orderReadyStatus;

          return (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <IconButton
                color='primary'
                onClick={(e) => {
                  e.stopPropagation();
                  history.push(orderReadyPath);
                }}
              >
                {rowData.projectOrderReadyStatus ===
                ORDER_READY_STATUS.DELIVERED ? (
                  <InvoiceIcon
                    hasSupplierInvoices={hasSupplierInvoices}
                    invoiceSentToHubdoc={rowData.invoiceSentToHubdoc}
                    porCreatedDate={rowData.porCreatedDate}
                  />
                ) : (
                  <Visibility />
                )}
              </IconButton>
              <OrderReadyStatusDisplay
                status={rowStatus}
                onAcceptedOrderPage={true}
                onClick={(e) => {
                  e.stopPropagation();
                  history.push(orderReadyPath);
                }}
              />
            </div>
          );
        } else if (
          isEmptyValue(rowData.orderReadyStatus) ||
          rowData.orderReadyStatus === ORDER_READY_STATUS.NOT_STARTED
        ) {
          return (
            <Tooltip
              title={
                Boolean(rowData.poAcknowledged) !== true
                  ? 'Start Order Ready Management (ORM) Process after the order is ready for shipment. Acknowledge PO to enable.'
                  : 'Start Order Ready Management (ORM) Process after the order is ready for shipment.'
              }
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '100%',
                }}
              >
                <Button
                  size='small'
                  variant='outlined'
                  disableElevation
                  className={classes.orderReadyButton}
                  onClick={() => history.push(orderReadyPath)}
                  disabled={Boolean(rowData.poAcknowledged) !== true}
                >
                  Start Order Ready
                </Button>
              </div>
            </Tooltip>
          );
        } else {
          return (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              N.A.
            </div>
          );
        }
      },
    };
  };

  const getColumns = () => [
    {
      headerName: ' ',
      field: 'actions',
      renderCell: ({ row: rowData }) => {
        return (
          <Tooltip title='View quote details'>
            <IconButton
              edge='end'
              aria-label='delete'
              onClick={() => {
                setOpenQuoteDetails(true);
                setSelectedQuoteDetails(rowData);
              }}
            >
              <Info color='primary' style={{ fontSize: '15pt' }} />
            </IconButton>
          </Tooltip>
        );
      },
      export: false,
      width: 60,
    },
    {
      title: 'Quote ID',
      headerName: 'Quote ID',
      field: 'quotationID',
      cellStyle: {
        textAlign: 'center',
      },
      hidden: true,
      hide: true,
      export: true,
    },
    {
      title: 'Image',
      headerName: 'Image',
      field: 'image',
      renderCell: ({ row: rowData }) => {
        const url = rowData.imageFile || rowData.twoDImageUrl;
        return (
          <ImageWith3DViewer
            key={url}
            twoDImageUrl={url}
            cadFile={rowData.cadFile || rowData.originalFiles}
            width={65}
            height={65}
            borderRadius={0}
            noBorder={false}
            textRenderStyle={{ fontSize: 9 }}
          />
        );
      },
      width: 87,
    },
    {
      title: 'Part ID',
      headerName: 'Part ID',
      field: 'itemID',
      cellStyle: isMobile
        ? {
            padding: '0px',
            margin: '0px',
          }
        : { textAlign: 'center' },
      renderCell: (params) => {
        const rowData = params.row;
        const projectPath = `projects/${rowData.projectID}/quote?technology=${rowData.technology}&viewQuote=true&supplierID=${rowData.userID}`;
        return (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Tooltip title='View part details'>
              <Button
                className={classes.linkButton}
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  window.open(projectPath, '_blank');
                }}
              >
                {rowData.itemID}
              </Button>
            </Tooltip>
            <DownloadListFilesWithTrackingIconButton
              actionTrackingKey={ACTION_TRACKING_TYPE.DOWNLOAD_FILE}
              additionalInfo={{
                urls: rowData.cadFile?.split(','),
              }}
              trackingInfo={{
                itemID: rowData.itemID,
                projectID: rowData.projectID,
                quotationID: rowData.quotationID,
              }}
              id={`download-item-files-${rowData.itemID}`}
              tooltip='Download Design Files'
              icon={GetApp}
              onClick={() => handleDownloadItemFiles(rowData)}
            />
          </div>
        );
      },
      width: 130,
    },
    {
      title: 'PO Acknowledged',
      headerName: 'PO Acknowledged',
      field: 'poAcknowledgedMobile', // this is only shown in mobile view
      renderCell: (params) => {
        const rowData = params.row;
        return colPoAcknowledged(rowData).render();
      },
      cellStyle: {
        padding: '0px',
        margin: '0px',
        textAlign: 'center',
      },
      hidden:
        role !== ROLE_TYPES.SUPPLIER ||
        (role === ROLE_TYPES.SUPPLIER && !isMobile),
      hide:
        role !== ROLE_TYPES.SUPPLIER ||
        (role === ROLE_TYPES.SUPPLIER && !isMobile),
      export: false,
    },
    {
      ...projectIDCol,
    },
    {
      title: 'Delivery Date',
      headerName: 'Delivery Date',
      field: 'deliveryDate',
      cellStyle: {
        textAlign: 'center',
      },
      hide: true,
      hidden: true,
      export: true,
      valueGetter: ({ row: rowData }) => {
        return isEmptyValue(rowData.maxCollectionDate)
          ? 'N.A.'
          : getSGTDateStrWithMonth(rowData.maxCollectionDate);
      },
      customSort: (a, b) => {
        if (a.maxCollectionDate === 'N.A.') return 1;
        if (b.maxCollectionDate === 'N.A.') return -1;
        return new Date(a.maxCollectionDate) - new Date(b.maxCollectionDate);
      },
    },
    {
      ...supplierPriceStrDisplayCol({ currency, exchangeRate }),
    },
    {
      ...quotationStatusColumn,
    },
    {
      title: 'Purchase Order',
      headerName: 'Purchase Order',
      field: 'purchaseOrder',
      valueGetter: ({ row: rowData }) => {
        const status = rowData.ppeQuoteStatus || rowData.status;
        const pdfUrl = rowData.combinedPOsForm || rowData.purchaseOrderForm;
        if (pdfUrl && status === ORDER_STATUS.ACCEPTED) {
          return `PO ${getPurchaseOrderID(pdfUrl)}`;
        } else {
          return 'N.A.';
        }
      },
      renderCell: (params) => {
        const rowData = params.row;
        const status = rowData.ppeQuoteStatus || rowData.status;
        const pdfUrl = rowData.combinedPOsForm || rowData.purchaseOrderForm;

        if (isEmptyValue(pdfUrl) || status !== ORDER_STATUS.ACCEPTED) {
          return <div>N.A.</div>;
        }

        return (
          <Tooltip title='Download' arrow>
            <Button
              data-cy='download-btn'
              size='small'
              variant='outlined'
              onClick={(e) => {
                e.stopPropagation();
                handleDownloadPOProject(rowData.projectID, pdfUrl);
              }}
            >
              PO {getPurchaseOrderID(pdfUrl)}
            </Button>
          </Tooltip>
        );
      },
      cellStyle: {
        textAlign: 'center',
      },
      width: 150,
    },
    {
      title: 'PO Acknowledged',
      headerName: 'PO Acknowledged',
      field: 'poAcknowledged',
      exportData: (rowData) => colPoAcknowledged(rowData).exportData(),
      valueGetter: ({ row: rowData }) =>
        colPoAcknowledged(rowData).exportData(),
      renderCell: (params) => {
        const rowData = params.row;
        return colPoAcknowledged(rowData).render();
      },
      cellStyle: {
        textAlign: 'center',
      },
      hidden: role === ROLE_TYPES.SUPPLIER && isMobile,
      hide: role === ROLE_TYPES.SUPPLIER && isMobile,
      export: true,
      width: 150,
    },
    {
      title: 'Order Ready Status',
      headerName: 'Order Ready Status',
      field: 'orderReadyStatus',
      renderCell: (params) => {
        const rowData = params.row;
        return colOrderReady(rowData).render();
      },
      cellStyle: {
        minWidth: 220,
      },
      width: 220,
    },
    {
      title: 'Date of Quote',
      headerName: 'Date of Quote',
      field: 'dateOfOrder',
      valueGetter: ({ row: rowData }) =>
        rowData.dateOfOrder ? getDateStrWithMonth(rowData.dateOfOrder) : '',
      cellStyle: {
        minWidth: 150,
        textAlign: 'center',
      },
      hidden: true,
      hide: true,
      export: true,
    },
    {
      ...dateAcceptedCol,
    },
    {
      title: 'Date of Acknowledgement',
      headerName: 'Date of Acknowledgement',
      field: 'acknowledgedDate',
      valueGetter: ({ row: rowData }) =>
        rowData.acknowledgedDate
          ? getDateStrWithMonth(rowData.acknowledgedDate)
          : '',
      cellStyle: {
        minWidth: 150,
        textAlign: 'center',
      },
      hidden: true,
      hide: true,
      export: true,
    },
    {
      title: 'Customer remarks',
      headerName: 'Customer remarks',
      field: 'customerRemarks',
      valueGetter: ({ row: rowData }) => rowData.customerRemarks || 'N.A',
      cellStyle: {
        maxWidth: '280px',
        textAlign: 'center',
      },
      hidden: true,
      hide: true,
      export: true,
    },
    {
      title: 'Quantity',
      headerName: 'Quantity',
      field: 'quantity',
      hidden: true,
      hide: true,
      export: true,
    },
    {
      title: 'Lead time',
      headerName: 'Lead time',
      valueGetter: ({ row: rowData }) => `${rowData.leadTime} working day(s)`,
      hidden: true,
      hide: true,
      export: true,
    },
    {
      ...materialWithColorCol,
      hidden: true,
      hide: true,
      export: true,
    },
    {
      ...surfaceFinishingWithColorCol,
      hidden: true,
      hide: true,
      export: true,
    },
    {
      title: 'CMM Price',
      headerName: 'CMM Price',
      field: 'cmmPrice',
      valueGetter: ({ row: rowData }) => {
        return convertPriceToCurrency({
          price: (rowData.metadata && rowData.metadata.cmmPrice) || 0,
          currency,
          exchangeRate:
            getQuotationExchangeRate(rowData, currency) || exchangeRate,
        });
      },
      hidden: true,
      hide: true,
      export: true,
      cellStyle: {
        minWidth: 200,
        textAlign: 'center',
      },
    },
    {
      title: 'My remarks',
      headerName: 'My remarks',
      field: 'myRemarks',
      cellStyle: {
        maxWidth: '250px',
      },
      valueGetter: ({ row: rowData }) =>
        `${rowData.ppeQuoteRemarks || rowData.remarks || 'N.A'}`,
      hidden: true,
      hide: true,
      export: true,
    },
    is3DPrintingSupplier
      ? {
          title: '3D Printing Technology',
          headerName: '3D Printing Technology',
          field: 'threeDTechnology',
          valueGetter: ({ row: rowData }) => {
            const technology = get(rowData, 'metadata.threeDTechnology');
            return isEmptyValue(technology) ? 'N.A' : technology;
          },
          hidden: true,
          hide: true,
          export: true,
        }
      : null,
    is3DPrintingSupplier
      ? {
          title: '3D Infill',
          headerName: '3D Infill',
          field: 'threeDInfill',
          valueGetter: ({ row: rowData }) => {
            const infill = get(rowData, 'metadata.threeDInfill');
            return isEmptyValue(infill)
              ? 'N.A'
              : `${(infill * 100).toFixed(0)}%`;
          },
          hidden: true,
          hide: true,
          export: true,
        }
      : null,
    is3DPrintingSupplier
      ? {
          title: '3D Layer Thickness',
          headerName: '3D Layer Thickness',
          field: 'threeDLayerThickness',
          valueGetter: ({ row: rowData }) => {
            const thickness = get(rowData, 'metadata.threeDLayerThickness');
            return isEmptyValue(thickness) ? 'N.A' : `${thickness}mm`;
          },
          hidden: true,
          hide: true,
          export: true,
        }
      : null,
  ];

  const [columns, setColumns] = useState([]);

  let timer;
  useEffect(() => {
    if (!tableQueryParams.search) {
      loadTableData();
    } else {
      clearTimeout(timer);
      timer = setTimeout(() => {
        loadTableData();
      }, 300);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [
    tableQueryParams.search,
    tableQueryParams.page,
    tableQueryParams.pageSize,
  ]);

  useEffect(() => {
    let _columns = getColumns();
    _columns = _columns.filter((col) => !!col);
    setColumns(_columns);
  }, [tableData, isMobile]);

  const handleSearch = (searchTerm) => {
    updateTableQueryParams({
      page: 0,
      search: searchTerm,
    });
  };

  const handleDownloadCsv = () => {
    const fileName = `All My Accepted Quotes`;
    exportCsvApi(
      supplierExportAcceptedQuotationsAsCsv(userID, tableQueryParams),
      fileName
    );
  };

  const getCustomerToolbar = () => {
    return (
      <DataGridToolbarLayout>
        <HorizontalExpandSpace />
        <CustomToolbar
          buttons={[
            <SearchBar
              key='search'
              onSearch={handleSearch}
              searchTerm={tableQueryParams.search}
            />,
            <ExportCsvButton
              key='export-csv'
              handleClick={handleDownloadCsv}
            />,
          ]}
        />
      </DataGridToolbarLayout>
    );
  };

  return (
    <div className={classes.body}>
      <PageTitle title='My Accepted Quotes' />
      <div
        style={{
          padding: '0 1rem',
        }}
      >
        <DataGrid
          autoHeight
          paginationMode='server'
          rows={tableData.rows}
          columns={columns.map((col) => ({
            ...col,
            sortable: false,
          }))}
          getRowId={(row) => row.quotationID}
          rowHeight={80}
          headerHeight={80}
          components={{
            Toolbar: getCustomerToolbar,
            Pagination: () => (
              <GridDataPagination
                pageCount={ceil(
                  tableQueryParams.totalCount / tableQueryParams.pageSize
                )}
              />
            ),
          }}
          pageSize={tableQueryParams.pageSize}
          rowCount={tableQueryParams.totalCount}
          rowsPerPageOptions={[10, 20, 50]}
          onPageSizeChange={(newPageSize) =>
            updateTableQueryParams({ pageSize: newPageSize })
          }
          onPageChange={(newPage) => updateTableQueryParams({ page: newPage })}
          disableRowSelectionOnClick
          disableSelectionOnClick
          disableColumnMenu
          loading={isFetching}
        />
      </div>
      <YesNoPopup
        open={openAcknowledgeQuotationPopup}
        handleYes={() => {
          handleBulkAcknowledgePO(
            selectedAcknowledgeQuotation.projectID,
            selectedAcknowledgeQuotation.poAcknowledgedVersion
          );
          setOpenAcknowledgeQuotationPopup(false);
        }}
        handleNo={() => setOpenAcknowledgeQuotationPopup(false)}
        noButtonLabel='Cancel'
        yesButtonLabel='Acknowledge'
        title='Please acknowledge this Purchase Order'
        body={
          selectedAcknowledgeQuotation &&
          selectedAcknowledgeQuotation.purchaseOrderForm ? (
            <div>
              <div>
                Acknowledge PO for the following Part ID(s) in Project{' '}
                {selectedAcknowledgeQuotation.projectID}:
              </div>
              <ul className={classes.listAcknowledgePartIDs}>
                {selectedAcknowledgeQuotation &&
                  tableData.projectItemsCount[
                    `${selectedAcknowledgeQuotation.projectID}-${selectedAcknowledgeQuotation.poAcknowledgedVersion}`
                  ]?.itemIDs?.map((itemID, index) => (
                    <li key={index}>{itemID}</li>
                  ))}
              </ul>
              <div>
                View Project PO:&nbsp;
                <Link
                  onClick={() =>
                    handleDownloadPOProject(
                      selectedAcknowledgeQuotation.projectID,
                      selectedAcknowledgeQuotation.combinedPOsForm
                    )
                  }
                >
                  PO #
                  {getPurchaseOrderID(
                    selectedAcknowledgeQuotation.combinedPOsForm ||
                      selectedAcknowledgeQuotation.purchaseOrderForm
                  )}
                </Link>
              </div>
            </div>
          ) : null
        }
      />
      {openQuoteDetails && (
        <SupplierQuoteDetailsDisplayPopup
          open={openQuoteDetails}
          rowData={selectedQuoteDetails}
          onClose={() => setOpenQuoteDetails(false)}
        />
      )}
    </div>
  );
}

function mapStateToProps(state) {
  return {
    user: state.auth.user,
    userId: state.auth.user.userID,
    currency: state.auth.location.currency,
    role: state.auth.user.role,
    exchangeRate: state.auth.rates[state.auth.location.currency],
  };
}

const withConnect = connect(mapStateToProps, null);

export default withConnect(SupplierAcceptedOrdersDataGrid);
