import React, { useReducer } from 'react';

import WelcomeBonusPopup from '../components/popups/WelcomeBonusPopup';

import { isEmptyValue } from '../utils/commonUtils';

// -------------------------------------------------------------------------------------------------

function withWelcomeBonusPopupHOC(WrappedComponent) {
  function WrappedComponentWithHOC(props) {
    const defaultLocalState = {
      open: false,
      actionTrackingKey: '',
      refetch: () => {},
    };

    const [localState, updateLocalState] = useReducer((prev, next) => {
      if (isEmptyValue(next)) {
        return defaultLocalState;
      }
      return { ...prev, ...next };
    }, defaultLocalState);

    return (
      <>
        <WrappedComponent
          {...props}
          updateWelcomeBonusPopupHOCState={updateLocalState}
        />
        {localState.open && (
          <WelcomeBonusPopup
            open={localState.open}
            onClose={() => {
              updateLocalState({ open: false });
              if (localState.refetch) {
                setTimeout(() => {
                  localState.refetch();
                }, 2000);
              }
            }}
            actionTrackingKey={localState.actionTrackingKey}
          />
        )}
      </>
    );
  }

  // Set the displayName for the HOC
  const wrappedComponentName =
    WrappedComponent.displayName || WrappedComponent.name || 'Component';
  WrappedComponentWithHOC.displayName = `withWelcomeBonusPopup(${wrappedComponentName})`;

  return WrappedComponentWithHOC;
}

export default withWelcomeBonusPopupHOC;
