import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { Box, useMediaQuery } from '@material-ui/core';

import { FtrTypography } from '../../ftr-components';

import DeleteDrawingIcon from '../../../assets/icons/delete_drawing.svg';

import { removeTechnicalDrawing } from '../../../actions';

import { getFileNameFromCadFile } from '../../../utils/itemUtils';

import { colors } from '../../../palette';

// --------------------------------------------------------------------------------

function TechnicalDrawingFileDisplay(props) {
  const { customWidth, item, fileStr } = props;

  const dispatch = useDispatch();
  const isMediumScreen = useMediaQuery('(max-width:1440px)');

  const [hovered, setHoveredRow] = useState(false);

  return (
    <Box
      style={{
        width: customWidth ? customWidth : isMediumScreen ? '107px' : '137.6px',
        display: 'flex',
        alignItems: 'center',
        borderRadius: '10px',
        padding: '0.5rem 0.7rem',
        justifyContent: 'space-between',
        backgroundColor: colors.neutral020,
        border: `0.5px solid ${colors.neutral030}`,
        minHeight: 24,
      }}
      onMouseEnter={() => setHoveredRow(true)}
      onMouseLeave={() => setHoveredRow(false)}
    >
      <FtrTypography
        type='subHeading'
        fontSize='12'
        style={{
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          color: colors.neutral070,
        }}
      >
        {getFileNameFromCadFile(fileStr)}
      </FtrTypography>
      {hovered && (
        <img
          src={DeleteDrawingIcon}
          alt='delete'
          style={{ cursor: 'pointer' }}
          onClick={() => {
            dispatch(removeTechnicalDrawing(item.id, fileStr));
          }}
          draggable={false}
        />
      )}
    </Box>
  );
}

export default TechnicalDrawingFileDisplay;
