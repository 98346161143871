import { isEmpty } from 'lodash';

export const isEmptyValue = (value) => {
  if (typeof value === 'undefined' || value == null) {
    return true;
  }
  if (
    typeof value === 'number' ||
    typeof value === 'boolean' ||
    value instanceof Date
  ) {
    return false;
  }
  if (typeof value === 'string') {
    return value.trim().length === 0;
  }
  return isEmpty(value);
};

export const debounce = (func, timeout = 300) => {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
};

export const copyToClipboard = (text) => {
  return navigator.clipboard.writeText(text);
};

export const isJsonString = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

/**
 * Sleep for a specified duration in seconds
 * @param {Number} timeInSeconds
 */
export const sleep = async (timeInSeconds) => {
  await new Promise((resolve) => setTimeout(resolve, timeInSeconds * 1000));
};
