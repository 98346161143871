import { isCustomSurfaceFinish } from '../utils/inputUtils';

import { getCache } from '../services/localStorageCacheService';

import { isEmptyValue } from '../utils/commonUtils';
import { findInArray } from '../utils/arrayUtils';
import { isObjectIncludes } from '../utils/objectUtils';
import { isNilString } from '../utils/stringUtils';
import { is3DPTechnology } from '../utils/itemUtils';

import { SURFACE_FINISH_NIL_MAPPING_KEY } from '../constants/cookiesConstants';

// -------------------------------------------------------------------------------------------------

export const itemSurfaceFinishProxy = {
  // Trap for property getting
  get: function(target, prop, receiver) {
    if (prop === 'getSurfaceFinishMappingConfig') {
      return function() {
        const nilMappingStr = getCache(SURFACE_FINISH_NIL_MAPPING_KEY);
        const nilMapping = !isEmptyValue(nilMappingStr)
          ? JSON.parse(nilMappingStr)
          : {};

        const { technology, material } = receiver;

        receiver.threeDTechnology =
          receiver.threeDTechnology || receiver.metadata?.threeDTechnology;

        const config = findInArray(nilMapping, (obj) => {
          const threeDTech = receiver.threeDTechnology;

          return is3DPTechnology(obj.technology)
            ? isObjectIncludes(obj, {
                technology,
                threeDTech,
                surfaceFinish: 'NIL',
              })
            : isObjectIncludes(obj, {
                technology,
                material,
                surfaceFinish: 'NIL',
              });
        });

        return config;
      };
    }

    if (prop === 'getSurfaceFinishText') {
      return function() {
        const config = receiver.getSurfaceFinishMappingConfig();

        const nilDisplay = config?.displaySurfaceFinish?.displayText || 'NIL';

        const surfaceFinish = isCustomSurfaceFinish(receiver.surfaceFinish)
          ? receiver.otherSurfaceFinish ||
            receiver.customSurfaceFinish ||
            nilDisplay
          : isNilString(receiver.surfaceFinish)
          ? nilDisplay
          : receiver.surfaceFinish || 'No surface finish';

        return surfaceFinish;
      };
    }

    if (prop === 'getSurfaceFinishTooltip') {
      return function() {
        const config = receiver.getSurfaceFinishMappingConfig();

        const nilTooltip = config?.displaySurfaceFinish?.tooltip || '';

        return nilTooltip;
      };
    }

    // Use Reflect.get to ensure correct 'this' binding
    return Reflect.get(target, prop, receiver);
  },
};
