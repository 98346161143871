// Import settings
import React, { Fragment } from 'react';
import { sampleSize } from 'lodash';
import { makeStyles } from '@material-ui/core/styles/index';

import { Grid } from '@material-ui/core';

import {
  Settings,
  WatchLaterOutlined,
  VerifiedUserOutlined,
  TrendingUpOutlined,
  SupervisedUserCircle,
  BuildRounded,
  ShoppingCartRounded,
} from '@material-ui/icons';

const useStyles = makeStyles({
  mobileIcon: {
    marginRight: '0.3rem',
    verticalAlign: 'middle',
  },
});

/**
 * @param {Object} props
 * @param {Number} props.size
 */
function Description(props) {
  const classes = useStyles();
  const { size } = props;
  return (
    <Fragment>
      {sampleSize(
        [
          <Grid key='supply-chain' item>
            <Settings className={classes.mobileIcon} />
            Digital Supply Chain At Your Fingertips
          </Grid>,
          <Grid key='order' item>
            <WatchLaterOutlined className={classes.mobileIcon} />
            Order In Clicks, Delivery In Days
          </Grid>,
          <Grid key='delivery' item>
            <VerifiedUserOutlined className={classes.mobileIcon} />
            On-Time Delivery, Uncompromised Quality
          </Grid>,
          <Grid key='moq' item>
            <ShoppingCartRounded className={classes.mobileIcon} />
            No Minimum Order Quantity
          </Grid>,
          <Grid key='custom-parts' item>
            <BuildRounded className={classes.mobileIcon} />
            Custom Parts On-Demand
          </Grid>,
          <Grid key='production' item>
            <TrendingUpOutlined className={classes.mobileIcon} />
            Power Your Production Process
          </Grid>,
          <Grid key='access' item>
            <SupervisedUserCircle className={classes.mobileIcon} />
            Access Southeast Asia’s Largest Verified Capacity
          </Grid>,
        ],
        size
      )}
    </Fragment>
  );
}

export default Description;
