import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Box, useTheme, useMediaQuery } from '@material-ui/core';

import { getFormDataAvailableSelector } from '../../selectors/formDataAvailableSelector';
import { getCreateUserSelector } from '../../selectors/createUserSelector';

import { resendEmail, verifyEmail } from '../../actions';

import VerifyCodePopup from './VerifyCodePopup';

import { FACTOREM_SAMPLE_PART_NAME } from '../../constants/itemConstants';

function VerifyEmailPopup(props) {
  const dispatch = useDispatch();

  const { open, delay } = props;

  const formDataAvailable = useSelector(getFormDataAvailableSelector);

  const {
    userEmail,
    userName,
    resendEmailSuccess,
    isInvalidCode,
    isVerifiedSuccess,
    password,
  } = useSelector(getCreateUserSelector);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const isWithSamplePart = formDataAvailable?.some(
    (item) => item.name === FACTOREM_SAMPLE_PART_NAME
  );

  const handleInputComplete = (value) => {
    dispatch(
      verifyEmail({
        verificationNumber: value,
        userEmail,
        password,
        isWithSamplePart,
      })
    );
  };

  const handleResend = () => {
    dispatch(resendEmail(userEmail, userName, true));
  };

  const renderDialogContent = () => {
    return (
      <>
        We&apos;ve sent a 6-digit code to{' '}
        <span style={{ fontWeight: 'bold' }}>{userEmail}</span>.
        {isMobile && <Box style={{ height: '1rem' }}></Box>}
        <span style={{ fontWeight: 'bold' }}>
          &nbsp;If you don&apos;t see it,
        </span>{' '}
        check your spam folder or&nbsp;
        <span
          style={{
            cursor: 'pointer',
            fontWeight: 'bold',
            textDecoration: 'underline',
          }}
          onClick={handleResend}
        >
          resend code
        </span>
        .
      </>
    );
  };

  const dialogTitleText = 'Verify your email address';

  const errorMessage =
    isInvalidCode === true &&
    !resendEmailSuccess &&
    'The code is invalid or has expired. Please try again.';

  const resendText =
    resendEmailSuccess &&
    isInvalidCode === false &&
    'Verification code has been sent again.';

  return (
    <VerifyCodePopup
      open={open}
      delay={delay}
      dialogTitleText={dialogTitleText}
      renderDialogContent={renderDialogContent}
      handleInputComplete={handleInputComplete}
      isCodeCorrect={isVerifiedSuccess}
      errorMessage={errorMessage}
      resendText={resendText}
    />
  );
}

export default VerifyEmailPopup;
