import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { get } from 'lodash';
import { useQuery } from 'react-query';

import ManageProjectItemsPresentationalV2 from '../../pages/manage-project-items/ManageProjectItemsPresentationalV2';

import withManageProjectItemsPopupsHOC from '../../pages/manage-project-items/withManageProjectItemsPopupsHOC';

import { editMultipleProjects, getProjectOwners } from '../../apis/projectApi';

import {
  initMultiCheckoutFormState,
  resetCheckoutPricingSummary,
  updateShippingMode,
  removeSelectedQuotes,
  UPDATE_CHECKOUT_SUMMARY,
} from '../../actions/multiCheckoutForm';

import { getMultiCheckoutFormSelector } from '../../selectors/multiCheckoutFormSelector';
import { getUserCurrencySelector } from '../../selectors/userSelector';

import useCustomerProjectInfo from '../../pages/manage-project-items/useCustomerProjectInfoHook';
import useIsAdminOrHigherUser from '../../hooks/useIsAdminOrHigherUser';
import useUserShippingAddress from '../../hooks/useUserShippingAddressHook';

import ProjectItemsContext from '../../context/ProjectItemsContext';

import { isEmptyValue } from '../../utils/commonUtils';
import { formatDeliveryDate } from '../../utils/dateTimeUtils';
import * as deliveryUtils from '../../utils/deliveryUtils';
import { getItemStageStatusText, isItemEditable } from '../../utils/itemUtils';
import { isUnverifiedQuote } from '../../utils/quotationUtils';

import { notifyError, notifySuccess } from '../../services/notificationService';

import { COUNTRY_NAMES } from '../../constants/countryConstants';
import { SHIPPING_MODES } from '../../constants/checkoutConstants';
import { DEFAULT_SHIP_MODE_LIST } from '../../constants/itemConstants';
import useUpdatePaymentType from '../../hooks/useUpdatePaymentTypeHook';
import useUpdateCheckoutFormCreditType from '../../pages/manage-project-items/useUpdateCheckoutFormCreditTypeHook';

import { getUserInfo } from '../../apis/userApi';

// --------------------------------------------------------------------------------

/**
 * @deprecated
 * @param {*} props
 * @returns
 */
function ManageProjectItemsV2(props) {
  const {
    isAdminView = false,
    toggleAdminView = () => {},
    setLoadingMessage = () => {},
    updateMovePartsPopupState = () => {},
    updateShareQuotePopupState = () => {},
    updateConfirmOrderPopupState = () => {},
    updateDeleteProjectFeedbackPopupState = () => {},
    updateEditProjectOwnerPopupState = () => {},
    // updateQualityCertificationsPopupState = () => { },
  } = props;

  const dispatch = useDispatch();

  const projectIdFromUri = window.location.href.split('/')[4];

  const projectID = projectIdFromUri
    ? projectIdFromUri
    : props.location?.state?.projectID;

  const multiCheckoutForm = useSelector(getMultiCheckoutFormSelector);
  const selectedQuotes = multiCheckoutForm?.selectedQuotes || [];
  const { status: multiCheckoutFormStatus } = multiCheckoutForm;

  const currency = useSelector(getUserCurrencySelector);
  const isAdminOrHigherUser = useIsAdminOrHigherUser();

  const [
    {
      customerID,
      currentProjectDetail,
      allParts = [],
      currentProjectName: originProjectName,
      isProjectDeletable,
      projectOwner: originProjectOwner,
      creatorEmail,
      isProjectLoading = false,
    },
    { refetchProjectInfoByID },
  ] = useCustomerProjectInfo(projectID);

  const { data: userInfo } = useQuery(['getUserInfo', customerID], () => {
    if (!customerID) {
      return null;
    }
    return getUserInfo(customerID);
  });

  const [filteredParts, setFilteredParts] = useState([]);
  const [currentProjectName, setCurrentProjectName] = useState(
    originProjectName
  );
  const [onEditProject, setOnEditProject] = useState(false);
  const [projectOwner, setProjectOwner] = useState(originProjectOwner);
  const [selectedFilters] = useState([]);
  const [qcReports, setQcReports] = useState(null);
  const [shippingModeList, setShippingModeList] = useState(
    Object.values(DEFAULT_SHIP_MODE_LIST)
  );
  const [shippingMode, setShippingMode] = useState(null);
  const [disableShippingMode, setDisableShippingMode] = useState(true);
  const [
    showCheckoutSummaryFullPanel,
    setShowCheckoutSummaryFullPanel,
  ] = useState(isAdminView);
  const [userSwitchShippingMode, setUserSwitchShippingMode] = useState(false);
  const [isShareQuotationForm, setIsShareQuotationForm] = useState(false);

  const { creditType } = useUpdateCheckoutFormCreditType(customerID);

  const [{ addresses }, setAddresses] = useUserShippingAddress(customerID, {
    shouldUpdateState: true,
  });

  const MoreProjectActionsMenuList = useMemo(() => {
    const list = [];
    if (isProjectDeletable) {
      list.push({
        text: 'Delete Project',
        onClick: () => {
          handleDeleteProject(currentProjectDetail);
        },
      });
    }

    list.push({
      text: 'Move Parts',
      onClick: () => {
        updateMovePartsPopupState({
          open: true,
          items: currentProjectDetail?.items,
        });
      },
    });

    if (isAdminOrHigherUser) {
      list.push({
        text: isAdminView ? 'Change to customer view' : 'Change to admin view',
        onClick: handleToggleAdminView,
      });
    }

    return list;
  }, [isProjectDeletable, isAdminOrHigherUser, isAdminView]);

  // --

  useEffect(() => {
    dispatch(initMultiCheckoutFormState());
    return () => {
      dispatch(resetCheckoutPricingSummary());
    };
  }, []);

  useUpdatePaymentType(currency);

  useEffect(() => {
    if (isProjectLoading) {
      setLoadingMessage('Loading project...');
      return;
    }

    setLoadingMessage('');

    if (isEmptyValue(currentProjectName)) {
      setCurrentProjectName(originProjectName);
    }

    if (isEmptyValue(projectOwner)) {
      setProjectOwner(originProjectOwner);
    }
  }, [isProjectLoading]);

  useEffect(() => {
    setShowCheckoutSummaryFullPanel(isAdminView);
  }, [isAdminView]);

  useEffect(() => {
    refetchProjectInfoByID().then(updateProjectOwnerDisplay);
    dispatch(initMultiCheckoutFormState());
  }, [projectID]);

  useEffect(() => {
    const newFilteredParts = isEmptyValue(selectedFilters)
      ? allParts
      : allParts.filter((item) => {
          const itemStatusText = getItemStageStatusText(item);
          return selectedFilters.includes(itemStatusText);
        });
    setFilteredParts(newFilteredParts);
  }, [allParts, selectedFilters]);

  useEffect(() => {
    if (!isEmptyValue(currentProjectDetail)) {
      setQcReports(currentProjectDetail.qcReports);
    }
  }, [currentProjectDetail?.qcReports]);

  useEffect(() => {
    const { selectedQuotes } = multiCheckoutForm ?? {};

    if (isEmptyValue(selectedQuotes) && !isAdminView) {
      setShowCheckoutSummaryFullPanel(false);
    }

    const deliveryDateModes = get(multiCheckoutForm, [
      'checkoutPriceSummary',
      'deliveryDateModes',
    ]);
    const deliveryModePrices = get(multiCheckoutForm, [
      'checkoutPriceSummary',
      'deliveryModePrices',
    ]);
    if (!isEmptyValue(deliveryDateModes)) {
      setShippingModeList(
        Object.values(DEFAULT_SHIP_MODE_LIST)
          .filter((mode) =>
            Object.keys(deliveryModePrices).includes(mode.value)
          )
          .map((mode) => {
            const dateStr = deliveryDateModes[mode.value];
            return {
              ...mode,
              date: formatDeliveryDate(dateStr),
              fee:
                Number(deliveryModePrices[mode.value].fee) === 0
                  ? 'Free'
                  : deliveryModePrices[mode.value].feeStr,
            };
          })
      );

      const bestShippingMode = deliveryUtils.getBestShippingMode(
        deliveryModePrices
      );
      if (
        bestShippingMode &&
        bestShippingMode !== shippingMode &&
        !userSwitchShippingMode
      ) {
        setShippingMode(bestShippingMode);
        dispatch(
          updateShippingMode(bestShippingMode, UPDATE_CHECKOUT_SUMMARY.NO)
        );
      }
      setDisableShippingMode(false);
    } else {
      setShippingModeList(Object.values(DEFAULT_SHIP_MODE_LIST));
      setDisableShippingMode(true);
      adjustShippingModeIfNeeded();
    }
  }, [
    multiCheckoutForm?.selectedQuotes,
    multiCheckoutForm?.checkoutPriceSummary,
  ]);

  useEffect(() => {
    adjustShippingModeIfNeeded();
  }, [addresses?.shipping?.country]);

  function adjustShippingModeIfNeeded() {
    if (isEmptyValue(addresses?.shipping?.country)) {
      return;
    }

    // if shipping destination is outside of SGP then only SAME_DATE shipping mode is allowed
    const _shippingMode =
      addresses.shipping.country !== COUNTRY_NAMES.SINGAPORE
        ? SHIPPING_MODES.SAME_DATE
        : SHIPPING_MODES.ONE_THREE_DAYS;

    setShippingMode(_shippingMode);
    dispatch(updateShippingMode(_shippingMode, UPDATE_CHECKOUT_SUMMARY.NO));
  }

  async function handleEditProject(projects) {
    const payload = {
      projects: projects,
      userID: customerID,
    };
    try {
      await editMultipleProjects(payload);
      notifySuccess('Your project name has been changed!');
      refetchProjectInfoByID();
    } catch {
      notifyError(
        'Your project name cannot be changed. Please try again later.'
      );
    }
  }

  async function updateProjectOwnerDisplay() {
    return getProjectOwners(projectID).then((owners) => {
      if (!isEmptyValue(owners)) {
        setProjectOwner(owners[0]);
      } else if (currentProjectDetail) {
        setProjectOwner({
          ownerEmail: currentProjectDetail.userEmail,
          ownerName: currentProjectDetail.userName,
        });
      }
    });
  }

  const handleProjectNameOnChange = (event) => {
    setCurrentProjectName(event.target.value);
  };

  const handleEditButtonOnClick = () => {
    setOnEditProject(true);
  };

  const handleDoneButtonOnClick = () => {
    let newProjectDetail = {
      name: currentProjectName,
      dateCreated: currentProjectDetail.dateCreated,
      status: currentProjectDetail.status,
      userID: currentProjectDetail.userID,
      projectID: currentProjectDetail.projectID,
      items: currentProjectDetail.items,
      qcReports,
    };
    handleEditProject([newProjectDetail]);
    setOnEditProject(false);
  };

  function handleDeleteProject(project) {
    updateDeleteProjectFeedbackPopupState({
      open: true,
      projectID: project.projectID,
    });
  }

  function handleToggleAdminView() {
    const isNextViewAdmin = !isAdminView;
    if (!isNextViewAdmin) {
      const unverifiedQuotes = selectedQuotes
        .filter((selectedQuote) => isUnverifiedQuote(selectedQuote))
        .map((quote) => quote.quotationID);
      if (!isEmptyValue(unverifiedQuotes)) {
        dispatch(removeSelectedQuotes(unverifiedQuotes));
      }
    }

    toggleAdminView();
  }

  // TODO: temporarily disabled this
  // const handleFilterChange = (event) => {
  //   const { name, checked } = event.target;
  //   const newFilters = checked
  //     ? [...selectedFilters, name]
  //     : selectedFilters.filter(item => item !== name);
  //   setSelectedFilters(newFilters);
  // }

  return (
    <ProjectItemsContext.Provider
      value={{
        addresses,
        creditType,
        isAdminView,
        projectID,
        projectOwner,
        qcReports,
        currentProjectName,
        onEditProject,
        currentProjectDetail,
        creatorEmail,
        customerID,
        isProjectDeletable,
        multiCheckoutForm,
        shippingModeList,
        disableShippingMode,
        multiCheckoutFormStatus,
        selectedQuotes,
        shippingMode,
        currency,
        isShareQuotationForm,
        showCheckoutSummaryFullPanel,
        filteredParts,
        customerCountry: userInfo?.country,
        isItemEditable,
        allParts,
        setShowCheckoutSummaryFullPanel,
        refetchProjectInfoByID,
        setAddresses,
        MoreProjectActionsMenuList,
        handleDoneButtonOnClick,
        handleProjectNameOnChange,
        handleDeleteProject,
        updateEditProjectOwnerPopupState,
        updateProjectOwnerDisplay,
        handleEditButtonOnClick,
        setShippingMode,
        updateShippingMode,
        setUserSwitchShippingMode,
        updateShareQuotePopupState,
        updateConfirmOrderPopupState,
        setIsShareQuotationForm,
      }}
    >
      <ManageProjectItemsPresentationalV2 />
    </ProjectItemsContext.Provider>
  );
}

export default withRouter(
  withManageProjectItemsPopupsHOC(ManageProjectItemsV2)
);
