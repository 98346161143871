import React from 'react';
import { useSelector } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles/index';

import { Avatar } from '@material-ui/core';

import { ExpandMore } from '@material-ui/icons';

import {
  FlexColumn,
  FlexRow,
  FlexRowSpaceBetween,
} from '../layouts/FlexLayouts';
import { FtrLightText } from '../ftr-components/FtrTypography';

import withUserMenu from '../sidebar/withUserMenu';

import { getUserName } from '../../utils/userUtils';

import { colors } from '../../palette';

const useStyles = makeStyles(() => ({
  profileLink: {
    color: colors.fontGrey,
    padding: 20,
    display: 'flex',
    justifyContent: 'center',
    textDecoration: 'none',
    width: '100%',
  },
  displayImage: {
    height: 45,
    width: 45,
    border: `2px solid ${colors.inputLabelGrey}`,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  displayName: {
    fontWeight: 700,
    fontSize: 16,
    textTransform: 'capitalize',
    display: 'flex',
    alignItems: 'center',
  },
}));

function ProfileMenuItemV2(props) {
  const classes = useStyles();

  const { showUserMenu = () => {}, userMenuAnchorRef } = props;

  const name = useSelector(
    (state) => state.editProfile.name || state.auth.user.name
  );
  const email = useSelector(
    (state) => state.editProfile.email || state.auth.user.email
  );
  const profilePic = useSelector(
    (state) => state.editProfile.profilePic || state.auth.user.profilePic
  );

  return (
    <div
      onClick={() => {
        showUserMenu();
      }}
      ref={userMenuAnchorRef}
      id='profile-menu-item'
    >
      <FlexRow>
        <Avatar src={profilePic} className={classes.displayImage} />
        <FlexColumn style={{ width: '100%' }}>
          <FlexRowSpaceBetween>
            <div className={classes.displayName}>{getUserName(name)}</div>
            <ExpandMore style={{ fontSize: '1rem' }} />
          </FlexRowSpaceBetween>
          <FtrLightText
            fontSize='12'
            style={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              maxWidth: 150,
            }}
          >
            {email}
          </FtrLightText>
        </FlexColumn>
      </FlexRow>
    </div>
  );
}

export default withUserMenu(ProfileMenuItemV2);
