import React, { useState, useEffect } from 'react';

import { makeStyles, Box, Typography, LinearProgress } from '@material-ui/core';

import { colors } from '../../../palette';

const useStyles = makeStyles(() => ({
  uploadProgressBarContainer: {
    '& .MuiLinearProgress-root': {
      height: 6,
      borderRadius: 10,
      backgroundColor: colors.blue010,
    },
    '& .MuiLinearProgress-bar': {
      borderRadius: 10,
      backgroundColor: colors.blue060,
    },
  },
  progressText: {
    color: colors.neutral070,
  },
  uploadProgressBar: {
    marginTop: 4,
  },
}));

export default function UploadProgressBar(props) {
  const classes = useStyles();

  const { numSuccessUploadedFiles, numTotalFiles } = props;

  const [progress, setProgress] = useState(0);

  useEffect(() => {
    setProgress((numSuccessUploadedFiles / numTotalFiles) * 100);
  }, [numSuccessUploadedFiles, numTotalFiles]);

  return (
    <Box className={classes.uploadProgressBarContainer}>
      <Typography variant='caption' className={classes.progressText}>
        Uploading {numSuccessUploadedFiles} of {numTotalFiles} files
      </Typography>
      <LinearProgress
        className={classes.uploadProgressBar}
        variant='determinate'
        value={progress}
      />
    </Box>
  );
}
