import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';

import { Box, InputAdornment, TextField } from '@material-ui/core';

import FtrInfoIconTooltip from '../ftr-components/FtrInfoIconTooltip';
import { FlexRow } from '../layouts/FlexLayouts';
import { FtrBoldText } from '../ftr-components';

import withViewAsSupplierHOC from '../../hocs/withViewAsSupplierHOC';

import { getQcReportsList } from '../../utils/projectUtils';
import { getCurrencySymbol } from '../../utils/currencyUtils';
import { isEmptyValue } from '../../utils/commonUtils';

import {
  QC_FORMAL_CMM_REPORT,
  QC_NO_QC_REPORT,
  QC_REPORTS_ALL_OPTIONS,
} from '../../constants/projectConstants';

import { colors } from '../../palette';

// -------------------------------------------------------------------------------------------------

function QcReportsDisplayV2(props) {
  const {
    qcReports,
    onChange,
    isError,
    disabled = false,
    value,
    showTextField = false,
    currency,
  } = props;

  const [qcReportList, setQcReportList] = useState([]);
  const [inputValue, setInputValue] = useState(value);
  const [cmmPriceError, setCmmPriceError] = useState(null);
  const [isDisabled, setIsDisabled] = useState(disabled);

  useEffect(() => {
    setIsDisabled(disabled);
    setInputValue(value || QC_NO_QC_REPORT);
  }, [value, disabled]);

  useEffect(() => {
    if (isError && !inputValue) {
      setCmmPriceError('Required');
    } else {
      setCmmPriceError(null);
    }
  }, [isError, inputValue]);

  useEffect(() => {
    const _qcReportList = getQcReportsList(qcReports);
    if (!isEmptyValue(_qcReportList)) {
      setQcReportList(_qcReportList);
    } else {
      setQcReportList([QC_NO_QC_REPORT]);
    }
  }, [qcReports]);

  return (
    <div>
      {qcReportList.map((report) => {
        const option =
          QC_REPORTS_ALL_OPTIONS.find((o) => o.name === report) || {};
        const { tooltip } = option;
        return (
          <FlexRow key={report}>
            {report === QC_FORMAL_CMM_REPORT && showTextField ? (
              <TextField
                disabled={isDisabled}
                type='number'
                variant='outlined'
                placeholder='00.00'
                label={QC_FORMAL_CMM_REPORT}
                onChange={({ target }) => {
                  setInputValue(target.valueAsNumber);
                  onChange && onChange(target.valueAsNumber);
                }}
                value={inputValue}
                margin='dense'
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      {getCurrencySymbol(currency)}
                    </InputAdornment>
                  ),
                }}
                onFocus={() => setCmmPriceError(null)}
                error={cmmPriceError}
                helperText={cmmPriceError}
                required
              />
            ) : (
              <FtrBoldText fontSize='16' style={{ color: colors.neutral070 }}>
                {report}
              </FtrBoldText>
            )}
            {tooltip && <Box style={{ width: '0.5rem' }} />}
            {tooltip && <FtrInfoIconTooltip toolTipText={tooltip} />}
          </FlexRow>
        );
      })}
    </div>
  );
}

export default withRouter(withViewAsSupplierHOC(QcReportsDisplayV2));
