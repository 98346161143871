import React, { useReducer } from 'react';

import ConfirmOrderPopup from '../../components/popups/ConfirmOrderPopup';

import { isEmptyValue } from '../../utils/commonUtils';

// -------------------------------------------------------------------------------------------------

function withConfirmOrderPopupHOC(WrappedComponent) {
  function WrappedComponentWithHOC(props) {
    const defaultLocalState = {
      open: false,
      selectedQuotes: [],
      onClose: () => {},
      refreshProject: () => {},
      onShippingModeChange: () => {},
      setAddresses: () => {},
    };

    const [localState, updateLocalState] = useReducer((prev, next) => {
      if (isEmptyValue(next)) {
        return defaultLocalState;
      }
      return { ...prev, ...next };
    }, defaultLocalState);

    return (
      <>
        <WrappedComponent
          updateConfirmOrderPopupState={updateLocalState}
          {...props}
        />
        {localState.open && (
          <ConfirmOrderPopup
            open={localState.open}
            customerID={localState.customerID}
            onClose={() => {
              updateLocalState({ open: false });
              localState.onClose();
            }}
            quotes={localState.selectedQuotes}
            shippingMode={localState.shippingMode}
            onShippingModeChange={localState.onShippingModeChange}
            shippingModeList={localState.shippingModeList}
            refreshProject={() => {
              localState.refreshProject();
              updateLocalState({ open: false });
            }}
            currency={localState.currency}
            project={localState.currentProjectDetail}
            onAddressesChange={localState.setAddresses}
          />
        )}
      </>
    );
  }

  return WrappedComponentWithHOC;
}

export default withConfirmOrderPopupHOC;
