import React from 'react';
import pluralize from 'pluralize';
import {
  Grid,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  Table,
  TableBody,
  Paper,
  LinearProgress,
} from '@material-ui/core';
import { Warning as WarningIcon } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { useQuery } from 'react-query';
import { compose } from 'redux';
import { RepeatOrderIcon } from '../components/icons/ItemIcon';
import { FtrButton } from '../components/ftr-components';
import { withAutomationRfqFailuresHOC } from '../hocs/withAutomationRfqFailuresHOC';

import { getAdminDashboardItems } from '../apis/dashboardApi';

const useStyles = makeStyles((theme) => ({
  body: {
    padding: theme.spacing(5),
    paddingTop: theme.spacing(5),
  },
}));

function AdminDashboard(props) {
  const classes = useStyles();
  const { status: loading, data } = useQuery(
    'getAdminDashboardItems',
    getAdminDashboardItems
  );

  const { updateAutomationRfqFailuresPopupState = () => {} } = props;

  return (
    <div className={classes.body}>
      {loading !== 'success' ? (
        <LinearProgress />
      ) : (
        <>
          <h1>Dashboard</h1>
          <Grid container spacing={3}>
            <Grid item xs={4}>
              <h2>Live Orders: {data?.dashboardItems?.allLiveOrders}</h2>
              <h2>
                <RepeatOrderIcon /> Repeat Live Orders:{' '}
                {data?.dashboardItems?.allRepeatLiveOrders}
              </h2>
            </Grid>
            <Grid item xs={8}>
              <div style={{ height: '50vh' }}>
                <TableContainer component={Paper}>
                  <Table className={classes.table} aria-label='simple table'>
                    <TableHead>
                      <TableRow>
                        <TableCell>Status</TableCell>
                        <TableCell align='right'>
                          # of Repeated Orders
                        </TableCell>
                        <TableCell align='right'>Issues</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell>Verifying</TableCell>
                        <TableCell align='right'>
                          {data?.dashboardItems?.verifyingItems}
                        </TableCell>
                        <TableCell align='right'></TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Quoted</TableCell>
                        <TableCell align='right'>
                          {data?.dashboardItems?.quotedItems}
                        </TableCell>
                        <TableCell align='right'></TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Processed: Pending Verification</TableCell>
                        <TableCell align='right'>
                          {
                            data.dashboardItems
                              .processedPendingVerificationItems
                          }
                        </TableCell>
                        <TableCell align='right'></TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Processed: Pending RFQ</TableCell>
                        <TableCell align='right'>
                          {data?.dashboardItems?.processedPendingRFQItems}
                        </TableCell>
                        <TableCell align='right'>
                          {data.rfqFailures.length > 0 ? (
                            <FtrButton
                              color='red'
                              variant='contained'
                              onClick={() => {
                                updateAutomationRfqFailuresPopupState({
                                  open: true,
                                  rfqFailures: data.rfqFailures,
                                });
                              }}
                              endIcon={<WarningIcon />}
                            >
                              View{' '}
                              {pluralize(
                                'issue',
                                data.rfqFailures.length,
                                true
                              )}
                            </FtrButton>
                          ) : (
                            <FtrButton
                              color='blue'
                              variant='contained'
                              disabled={true}
                            >
                              No issues
                            </FtrButton>
                          )}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Processed: Pending Supplier</TableCell>
                        <TableCell align='right'>
                          {
                            data.dashboardItems
                              .processingPendingSupplierAcceptanceItems
                          }
                        </TableCell>
                        <TableCell align='right'></TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          Processed: Pending Quote Verification
                        </TableCell>
                        <TableCell align='right'>
                          {
                            data.dashboardItems
                              .processingPendingQuoteVerificationItems
                          }
                        </TableCell>
                        <TableCell align='right'></TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          Processed: Pending PO Acknowledgement
                        </TableCell>
                        <TableCell align='right'>
                          {
                            data.dashboardItems
                              .processingPendingPOAcknowledgementItems
                          }
                        </TableCell>
                        <TableCell align='right'></TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Order-in-progress</TableCell>
                        <TableCell align='right'>
                          {data?.dashboardItems?.orderInProgressItems}
                        </TableCell>
                        <TableCell align='right'></TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Order ready</TableCell>
                        <TableCell align='right'>
                          {data?.dashboardItems?.orderReadyItems}
                        </TableCell>
                        <TableCell align='right'></TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </Grid>
          </Grid>
        </>
      )}
    </div>
  );
}

function mapStateToProps() {
  return {};
}

function matchDispatchToProps() {
  return {};
}

const withConnect = connect(mapStateToProps, matchDispatchToProps);

export default compose(
  withConnect,
  withAutomationRfqFailuresHOC
)(AdminDashboard);
