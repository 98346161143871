import React, { useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import { Close } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 300,
    margin: '0.3rem',
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: '0.3rem',
  },
  divider: {
    height: 28,
    margin: 4,
  },
}));

export default function SearchBar(props) {
  const classes = useStyles();

  const {
    onSearch = () => {},
    onFocus = () => {},
    onBlur = () => {},
    searchTerm: searchTermParam = '',
    useDebounceSearch = false,
    autoFocus = true,
  } = props;

  const inputRef = useRef(null);

  const [searchTerm, setSearchTerm] = useState(
    useDebounceSearch ? '' : searchTermParam
  );
  const [timer, setTimer] = useState(null);

  if (autoFocus) {
    inputRef?.current?.focus();
  }

  const handleSearch = (e) => {
    const _searchTerm = e.target.value;
    setSearchTerm(_searchTerm);
    if (timer) {
      clearTimeout(timer);
    }
    if (!useDebounceSearch) {
      onSearch(_searchTerm);
      inputRef?.current?.focus();
    } else {
      const _timer = setTimeout(() => {
        onSearch(_searchTerm);
        inputRef?.current?.focus();
      }, 300);
      setTimer(_timer);
    }
  };

  const handleResetSearchTerm = () => {
    inputRef?.current?.focus();
    setSearchTerm('');
    onSearch('');
  };

  return (
    <Paper
      component='form'
      className={classes.root}
      elevation={1}
      variant='outlined'
      square
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <InputBase
        inputRef={inputRef}
        className={classes.input}
        placeholder='Search'
        inputProps={{ 'aria-label': 'search google maps' }}
        onChange={handleSearch}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault();
          }
        }}
        onFocus={onFocus}
        value={searchTerm}
        autoFocus={autoFocus}
        onBlur={onBlur}
        data-cy='search-field'
      />
      <IconButton
        className={classes.iconButton}
        aria-label='search'
        onClick={handleResetSearchTerm}
      >
        <Close />
      </IconButton>
    </Paper>
  );
}
