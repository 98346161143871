// Import settings
import React, { useEffect, useState } from 'react';

// Import customised components
import CompareQuoteTable from '../tables/CompareQuoteTable';
import Desc from '../Desc';
import SubDesc from '../SubDesc';
import Title from '../Title';

// Import material UI components
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles/index';

// Style the components
const useStyles = makeStyles((theme) => ({
  paper: {
    minWidth: 800,
    borderRadius: 0,
    padding: '30px 10px 60px',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

function CompareQuotationsPopup(props) {
  const classes = useStyles();
  const { dialog, handleClose, itemID, data } = props;

  // Declare component states
  const [item, setItem] = useState(null);

  // Run when popup first loads
  useEffect(() => {
    setItem(data.find((item) => item.itemID === itemID));
  }, [itemID]);

  return (
    <Dialog
      maxWidth='xl'
      open={dialog}
      onClose={handleClose}
      aria-labelledby='confirmation-dialog-title'
      classes={{ paper: classes.paper }}
    >
      <DialogTitle id='confirmation-dialog-title'>
        <Title contentTitle='Compare Quotes' />
        <Desc contentTitle={item ? item.name : ''} />
        <SubDesc content={item ? `Order #${item.itemID}` : ''} />
      </DialogTitle>
      <DialogContent style={{ padding: '0 30px', marginTop: 10 }}>
        <CompareQuoteTable
          item={item ? item : {}}
          quotations={item ? item.quotations : []}
          onClose={handleClose}
        />
      </DialogContent>
      {dialog ? (
        <IconButton
          aria-label='close'
          className={classes.closeButton}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </Dialog>
  );
}

export default CompareQuotationsPopup;
