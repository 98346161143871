import React, { useState } from 'react';

import {
  Dialog,
  DialogContent,
  DialogTitle,
  makeStyles,
  TextField,
} from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';

import { FtrH5 } from '../../../ftr-components/FtrTypography';
import { FtrSmallButton } from '../../../ftr-components/FtrButton';
import { colors } from '../../../../palette';

const useStyles = makeStyles(() => ({
  backdrop: {
    backdropFilter: 'blur(3px)',
  },
  dialog: {
    padding: '1rem',
    borderRadius: '1rem',
    position: 'relative',
    margin: 0,
  },
  title: {
    display: 'flex',
    fontSize: '22px',
    fontWeight: '700',
    justifyContent: 'center',
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
  },
  closeIcon: {
    position: 'absolute',
    right: '1rem',
    top: '1rem',
    color: colors.neutral060,
    width: '1.2rem',
    height: '1.2rem',
    cursor: 'pointer',
  },
}));

function RenameFieldPopup(props) {
  const {
    open,
    name,
    onClose = () => {},
    onUpdate,
    title = 'Rename Field',
  } = props;

  const [editName, setEditName] = useState(name);

  const classes = useStyles();

  return (
    <Dialog
      open={open}
      scroll='body'
      BackdropProps={{
        classes: {
          root: classes.backdrop,
        },
      }}
      PaperProps={{ className: classes.dialog }}
      onClose={onClose}
    >
      <DialogTitle disableTypography className={classes.title}>
        <FtrH5 style={{ color: colors.neutral070 }}>{title}</FtrH5>
      </DialogTitle>
      <CloseIcon className={classes.closeIcon} onClick={onClose} />
      <DialogContent className={classes.dialogContent}>
        <TextField
          size='small'
          margin='none'
          variant='outlined'
          style={{ width: '100%' }}
          InputProps={{
            style: {
              borderRadius: '10px',
            },
          }}
          value={editName}
          onChange={(event) => setEditName(event.target.value)}
        />
        <FtrSmallButton
          style={{ marginTop: '1.5rem' }}
          color='blue'
          onClick={() => {
            onUpdate(editName);
            onClose();
          }}
        >
          Update
        </FtrSmallButton>
      </DialogContent>
    </Dialog>
  );
}

export default RenameFieldPopup;
