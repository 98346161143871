import React from 'react';

import FtrTypography from '../FtrTypography';

import { colors } from '../../../palette';

// --------------------------------------------------------------------------------------------

function CustomerTableRowText(props) {
  return (
    <FtrTypography
      fontSize='16'
      style={{
        color: colors.neutral070,
        fontWeight: 600,
      }}
    >
      {props.children}
    </FtrTypography>
  );
}

export default CustomerTableRowText;
