import {
  GET_PDFS_LOADING,
  GET_PDFS_SUCCESS,
  GET_PDFS_FAILURE,
  CREATE_PDF_SUCCESS,
  CREATE_PDF_LOADING,
  CREATE_PDF_FAILURE,
} from '../actions';
import { LOGOUT_SUCCESS } from '../actions/';

const defaultState = {
  getPdfsLoading: false,
  getPdfsSuccess: false,
  getPdfsFailure: false,
  pdfs: [],
  createPdfStatus: '',
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case GET_PDFS_LOADING:
      return {
        ...state,
        getPdfsLoading: true,
        getPdfsSuccess: false,
        getPdfsFailure: false,
      };
    case GET_PDFS_SUCCESS:
      return {
        ...state,
        getPdfsLoading: false,
        getPdfsSuccess: true,
        getPdfsFailure: false,
        pdfs: action.pdfs,
      };
    case GET_PDFS_FAILURE:
      return {
        ...state,
        getPdfsLoading: false,
        getPdfsSuccess: false,
        getPdfsFailure: true,
      };
    case CREATE_PDF_LOADING:
      return {
        ...state,
        createPdfStatus: 'loading',
      };
    case CREATE_PDF_SUCCESS:
      return {
        ...state,
        createPdfStatus: 'success',
      };
    case CREATE_PDF_FAILURE:
      return {
        ...state,
        createPdfStatus: 'failure',
      };
    case LOGOUT_SUCCESS:
      return defaultState;
    default:
      return state;
  }
};
