import { getAuthorizedHeader, witchCacheResponse } from '../utils/apiUtils';

import { BACKEND_SERVICE_URL } from '../constants';

export const getQcOptionPricesApi = async (body) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/qc-report/prices`;
  return fetch(requestUrl, {
    method: 'POST',
    headers: getAuthorizedHeader(),
    body: JSON.stringify(body),
  }).then(async (response) => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return (await response.json()).data;
  });
};

export const getQcOptionPrices = witchCacheResponse(
  getQcOptionPricesApi,
  60 * 60 // 1 hour in seconds
);
