// Import settings
import React, { useState } from 'react';

// Import color palette
import { colors } from '../palette';

// Import material UI components
import { Publish as PublishIcon } from '@material-ui/icons';

function BlueDragDrop(props) {
  const { handleFiles } = props;
  const [dragging, setDragging] = useState(false);
  const [isEnter, setIsEnter] = useState(false);

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(true);
  };
  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsEnter(true);
  };
  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsEnter(false);
  };
  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);
    setIsEnter(false);
    handleFiles(e.dataTransfer.files);
  };

  return (
    <div style={{ marginTop: 15 }}>
      <div
        style={{
          fontSize: 16,
          marginBottom: 12,
          color: colors.fontGrey,
          fontWeight: 600,
        }}
      >
        Upload Design Files*
        <div style={{ fontWeight: '100', fontSize: '10pt', marginTop: 3 }}>
          Upload your 3D models and accompanying files for this part. You may
          select specific configurations below.
        </div>
      </div>
      <input
        id='cad-part-file'
        type='file'
        multiple
        onChange={(evt) => handleFiles(evt.target.files)}
        onClick={(event) => (event.target.value = null)}
        style={{
          display: 'none',
        }}
      />
      <label
        htmlFor='cad-part-file'
        onDragOver={handleDragOver}
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
      >
        <div
          style={{
            borderRadius: '5px',
            border: `dashed ${colors.blue060} 1px`,
            backgroundColor: colors.dragdropBg,
            height: '285px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer',
            opacity: isEnter ? 0.8 : 1,
          }}
        >
          <PublishIcon style={{ marginRight: 10, fontSize: 43 }} />
          {dragging ? (
            'Release to upload files'
          ) : (
            <div
              style={{
                color: colors.fontGrey,
                fontSize: 16,
                lineHeight: '21px',
              }}
            >
              <div>Drop your files here.</div>
              <div style={{ display: 'flex' }}>
                <div>Or</div>
                <div
                  style={{
                    marginLeft: 4,
                    color: colors.blue060,
                    fontWeight: 600,
                    textDecoration: 'underline',
                  }}
                >
                  Browse
                </div>
              </div>
            </div>
          )}
        </div>
      </label>
    </div>
  );
}

export default BlueDragDrop;
