import React, { Fragment, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import isURL from 'validator/lib/isURL';

import { colors } from '../palette';

import { customerEditItem } from '../actions';

// Import constants
import {
  deliveryOptions,
  TECHNOLOGY_OPTION_TYPE,
  THREE_D_P_FDM_TECH,
  threeDPrintingInfillDefault,
  threeDPrintingLayerThicknessDefault,
} from '../constants/NewPartConstants';

// Import material UI components
import {
  Backdrop,
  CircularProgress,
  IconButton,
  Input,
  InputBase,
  InputLabel,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from '@material-ui/core';
import { Cancel as CancelIcon } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles/index';

import BlueButton from './buttons/BlueButton';
import BlueDragDrop from './BlueDragDrop';
import ReviewPartPopup from './popups/ReviewPartPopup';
import Title from './Title';

import FileIcon from '../assets/img/file.png';
import InfoIcon from '../components/icons/InfoIcon';
import { getFileNameFromCadFile } from '../utils/itemUtils';
import TechnologyInputField from './inputs/TechnologyInputField';
import { useItemInputConfig } from '../hooks/useItemInputConfig';
import MaterialCategoriesInputField from './inputs/MaterialCategoriesInputField';
import SurfaceFinishingField from './inputs/SurfaceFinishingField';
import { get, isEmpty } from 'lodash';
import ColorInputField from './inputs/ColorInputField';
import ThreeDPrintingTechnologyInputField from './inputs/ThreeDPrintingTechnologyInputField';
import ThreeDPrintingMaterialField from './inputs/ThreeDPrintingMaterialField';
import ThreeDInfillSelectField from './inputs/ThreeDInfillSelectField';
import { ppe3dpInfillOptions } from '../constants/PPEConstants';
import ThreeDLayerThicknessField from './inputs/ThreeDLayerThicknessField';
import {
  isCustomMaterial,
  isCustomSurfaceFinish,
  isCustomTechnology,
} from '../utils/inputUtils';
import { CADPART_DIRNAME, IMG_PLACE_HOLDER_URL } from '../constants';
import { uploadFileToS3 } from '../services/s3Service';
import { downloadS3File, findCadFiles } from '../utils/fileUtils';
import { convert2DImage } from '../services/convert2DImageService';
import ToleranceDropdown from './dropdowns/ToleranceDropdown';
import { convertFromMetric, convertToMetric } from '../utils/userUtils';
import { CAD_RENDERER_TO_2D_TYPES } from '../constants/cadRendererConstants';

// Style components
const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: colors.fontWhite,
  },
  colorButton: {
    padding: '5px 14px',
    width: '100%',
    justifyContent: 'left',
    background: colors.fontWhite,
    border: `1px solid ${colors.inputBorderBlue}`,
    boxSizing: 'border-box',
    borderRadius: '5px',
    color: colors.fontGrey,
    marginBottom: '13px',
    textTransform: 'none',
    fontSize: '16px',
  },
  formComponent: {
    height: '75%',
    padding: '25px 45px',
  },
  inputContainer: {
    display: 'flex',
    marginBottom: 12,
  },
  inputField: {
    padding: '5px 14px',
    background: colors.fontWhite,
    border: `1px solid ${colors.inputBorderBlue}`,
    boxSizing: 'border-box',
    borderRadius: '5px',
    color: `${colors.fontGrey}`,
    marginBottom: '13px',
  },
  inputLabel: {
    fontSize: 16,
    marginBottom: 7,
    color: colors.fontGrey,
    fontWeight: 600,
  },
  inputFieldGrey: {
    padding: '5px 14px',
    background: colors.fontWhite,
    border: `1px solid ${colors.inputLabelGrey}`,
    boxSizing: 'border-box',
    borderRadius: '5px',
    color: colors.fontGrey,
    marginBottom: '13px',
  },
  itemText: {
    '& span, & svg': {
      fontSize: 12,
    },
  },
  cancelBtn: {
    '& span, & svg': {
      color: colors.fontWhite,
    },
  },
}));

const NewEditPartForm = ({
  submitEditPart,
  savedPartDetails,
  editItemSuccess,
  myUserId,
  closeDialog,
  getItems,
  userUnitType,
}) => {
  const cafFileUploadRef = useRef(null);
  const [status] = useState(savedPartDetails.status);
  const [name, setItemName] = useState(savedPartDetails.name);
  const [referenceName] = useState(savedPartDetails.referenceName);
  const [price] = useState(savedPartDetails.price);
  const [qty, setItemQty] = useState(savedPartDetails.quantity);
  const [technology, setTechnology] = useState(savedPartDetails.technology);
  const [otherTechnology, setOtherTechnology] = useState('');
  const [deliveryPref] = useState(
    savedPartDetails.deliveryPreference || deliveryOptions[0].value
  );
  const [datePosted] = useState(savedPartDetails.datePosted || new Date());
  const [description, setItemDescription] = useState(
    savedPartDetails.description || ''
  );
  const [surfaceFinish, setSurfaceFinish] = useState(
    savedPartDetails.surfaceFinish
  );
  const [otherSurfaceFinish, setOtherSurfaceFinish] = useState(
    savedPartDetails.customSurfaceFinish || ''
  );
  const [partApplication, setPartApplication] = useState(
    savedPartDetails.partApplication || ''
  );
  const [tolerance, setItemTolerance] = useState('0.1');
  const [material, setMaterial] = useState(savedPartDetails.material);
  const [otherMaterial, setOtherMaterial] = useState(
    savedPartDetails.customMaterial || ''
  );
  const [color, setColor] = useState(savedPartDetails.color || '');
  const [cadPart, setCadPart] = useState(
    savedPartDetails.originalFiles
      ? savedPartDetails.originalFiles.split(',')
      : []
  );
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [formError, setFormError] = useState({});
  const [itemToEdit, setItemToEdit] = useState(null);
  const [itemID] = useState(savedPartDetails.itemID);
  const [loading, setLoading] = useState(false);
  const [reviewDialog, setReviewDialogOpen] = useState(false);
  const [threeDTechnology, setThreeDTechnology] = useState(
    get(savedPartDetails, ['metadata', 'threeDTechnology']) || ''
  );
  const [materialColor, setMaterialColor] = useState(
    savedPartDetails.materialColor || ''
  );
  const [threeDInfill, setThreeDInfill] = useState(
    get(savedPartDetails, ['metadata', 'threeDInfill']) || ''
  );
  const [threeDLayerThickness, setThreeDLayerThickness] = useState(
    get(savedPartDetails, ['metadata', 'threeDLayerThickness']) || ''
  );
  const [unitType, setUnitType] = useState(
    savedPartDetails.unitType || userUnitType
  );
  const [twoDImageUrl, setTwoDImageUrl] = useState(
    savedPartDetails.twoDImageUrl || ''
  );

  const [
    {
      technologyOptions,
      materialCategoryOptions,
      threeDTechnologyOptions,
      threeDMaterialOptions,
      surfaceFinishOptions,
      materialColorOptions,
      surfaceFinishColorOptions,
      defaultThreeDMaterial,
      selectColorSurfaces,
    },
    {
      loadTechnologyOptions,
      loadMaterialCategoryOptions,
      load3DTechnologyOptions,
      loadThreeDMaterialOptions,
      loadSurfaceFinishOptions,
      loadMaterialColorOptions,
      loadSurfaceFinishColorOptions,
      technologyHasChanged,
      materialHasChanged,
      threeDTechnologyHasChanged,
      surfaceFinishHasChanged,
    },
  ] = useItemInputConfig({
    setTechnology,
    setMaterial,
    setThreeDTechnology,
    setSurfaceFinish,
    setMaterialColor,
    setColor,
  });

  const classes = useStyles();
  const techIs3DPrinting = technology === '3D Printing';
  const surfaceFinishIsPainting = surfaceFinish === 'Painting';
  const surfaceFinishIsCoating = surfaceFinish === 'Powder Coating';
  let colorInputLabel = surfaceFinishIsPainting
    ? 'Paint Color'
    : surfaceFinishIsCoating
    ? 'Powder Coat Color'
    : 'Color';

  useEffect(() => {
    if (editItemSuccess && formSubmitted) {
      closeDialog();
      setLoading(false);
      getItems();
    }
  }, [editItemSuccess]);

  useEffect(() => {
    if (savedPartDetails) {
      loadTechnologyOptions(false);
      const _technology = savedPartDetails.technology;
      loadMaterialCategoryOptions({ technology: _technology }, false);
      if (techIs3DPrinting) {
        load3DTechnologyOptions(false);
        loadThreeDMaterialOptions(
          {
            technology: _technology,
            threeDTechnology,
          },
          false
        );
      }
      const params = {
        technology: _technology,
        threeDTechnology,
        material,
      };
      loadSurfaceFinishOptions(params, false);
      if (savedPartDetails.materialColor) {
        loadMaterialColorOptions(params, false);
      }
      if (savedPartDetails.color) {
        loadSurfaceFinishColorOptions(
          {
            technology: _technology,
            surfaceFinish,
          },
          false
        );
      }
    }
  }, []);

  useEffect(() => {
    if (isEmpty(savedPartDetails)) {
      return;
    }
    let _tolerance = convertFromMetric(savedPartDetails.tolerance, unitType);
    setItemTolerance(_tolerance);
  }, [savedPartDetails, unitType]);

  const updateTwoDImageUrl = async (stringOfLinks) => {
    const s3ObjectUrl = findCadFiles(stringOfLinks, CAD_RENDERER_TO_2D_TYPES);
    let _twoDImageUrl = IMG_PLACE_HOLDER_URL;
    if (s3ObjectUrl) {
      const respConvert2DImage = await convert2DImage({ file_url: s3ObjectUrl })
        .then((result) => {
          if (!isEmpty(result.error)) {
            return {
              s3_file_url: IMG_PLACE_HOLDER_URL,
            };
          }
          return result;
        })
        .catch(() => ({
          s3_file_url: IMG_PLACE_HOLDER_URL,
        }));
      _twoDImageUrl = respConvert2DImage['s3_file_url'];
    }
    const url = isURL(_twoDImageUrl) ? _twoDImageUrl : null;
    setTwoDImageUrl(url);
  };

  useEffect(() => {
    if (!isEmpty(cadPart)) {
      updateTwoDImageUrl(cadPart);
    }
  }, [cadPart]);

  function handleCadFilesChange(files) {
    const toastId = toast('Uploading file...', {
      type: toast.TYPE.INFO,
      autoClose: false,
    });
    let stringOfLinks = [...cadPart];
    Promise.all(
      Array.from(files).map((file) => {
        const splitFilename = file.name.split('.');
        const preFilename = splitFilename.slice(0, -1).join('.');
        const fileExtension = splitFilename[splitFilename.length - 1];
        const s3ObjectKey = `${CADPART_DIRNAME}/${preFilename}_${file.lastModified}.${fileExtension}`;
        return uploadFileToS3(file, s3ObjectKey)
          .then((data) => {
            stringOfLinks.push(data.Location.split(' ').join('%20'));
          })
          .catch((err) => {
            alert(err);
          });
      })
    ).then(async () => {
      toast.update(toastId, {
        render: `File Uploaded!`,
        type: toast.TYPE.SUCCESS,
        autoClose: 3000,
      });
      setCadPart(stringOfLinks);
    });
  }

  const handleConfirmPart = () => {
    if (itemToEdit.name && itemToEdit.qty) {
      setLoading(true);
      submitEditPart(itemToEdit);
      setFormSubmitted(true);
      setReviewDialogOpen(false);
    } else {
      toast.error('Missing name or quantity field.', {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const handleEditPart = async () => {
    const data = {
      name,
      referenceName,
      status,
      qty,
      deliveryPref,
      technology: isCustomTechnology(technology) ? otherTechnology : technology,
      description,
      surfaceFinish: isCustomSurfaceFinish(surfaceFinish)
        ? otherSurfaceFinish
        : surfaceFinish,
      partApplication,
      tolerance: convertToMetric(tolerance, unitType),
      material: isCustomMaterial(material) ? otherMaterial : material,
      cadPart,
      datePosted,
      myUserId,
      itemID,
      price,
      color,
      materialColor,
      unitType,
      twoDImageUrl,
      imageFile: twoDImageUrl,
    };
    if (techIs3DPrinting) {
      data.threeDTechnology = threeDTechnology;
      data.threeDInfill = threeDInfill;
      data.threeDLayerThickness = threeDLayerThickness;
    }
    if (isEmpty(cadPart)) {
      setFormError({
        editCadFile: 'Design file is required',
      });
      cafFileUploadRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
      return;
    }

    setItemToEdit(data);
    setReviewDialogOpen(true);
  };

  const handleSubmitColor = (selectedColor) => {
    setColor(selectedColor || color);
    // setColorDialogOpen(false);
  };

  const renderSurfaceFinishColor = () => {
    return (
      <ColorInputField
        visible={!isEmpty(surfaceFinishColorOptions)}
        value={color}
        colorInputLabel={colorInputLabel}
        colorPalette={surfaceFinishColorOptions}
        onSubmit={(selectedColor) => handleSubmitColor(selectedColor)}
      />
    );
  };

  const renderMaterialColorInputField = () => {
    return (
      <ColorInputField
        visible={!isEmpty(materialColorOptions)}
        value={materialColor}
        colorInputLabel='Material Color'
        colorPalette={materialColorOptions}
        onSubmit={(selectedColor) =>
          setMaterialColor(selectedColor || materialColor)
        }
      />
    );
  };

  const contents = (
    <Fragment>
      <BlueDragDrop handleFiles={handleCadFilesChange} />
      <div style={{ margin: '20px 0 45px', display: 'flex' }}>
        <InputLabel
          style={{
            width: 140,
            fontSize: 14,
            fontWeight: 600,
            display: 'flex',
            marginTop: 32,
            marginRight: 15,
          }}
        >
          Uploaded files:
        </InputLabel>
        <div ref={cafFileUploadRef}>
          <List style={{ display: 'flex', flexWrap: 'wrap' }}>
            {cadPart.map((link, index) => {
              const fileName = getFileNameFromCadFile(link);
              return (
                <ListItem
                  key={index}
                  style={{
                    backgroundColor: colors.uploadFileBg,
                    width: 260,
                    margin: 5,
                    border: `solid 1px ${colors.uploadFileBorder}`,
                    borderRadius: '5px',
                    height: '62px',
                    color: 'inherit',
                    cursor: 'pointer',
                  }}
                  component='a'
                  onClick={(e) => {
                    e.stopPropagation();
                    downloadS3File(link);
                  }}
                >
                  <ListItemAvatar style={{ display: 'flex', minWidth: '45px' }}>
                    <img src={FileIcon} alt='icon' style={{ height: '30px' }} />
                  </ListItemAvatar>
                  <ListItemText
                    style={{
                      width: 130,
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      fontSize: 12,
                    }}
                    primary={fileName}
                    className={classes.itemText}
                  />
                  <ListItemSecondaryAction>
                    <IconButton
                      edge='end'
                      aria-label='delete'
                      onClick={() => {
                        const cadPartFiles = [...cadPart];
                        cadPartFiles.splice(index, 1);
                        setCadPart(cadPartFiles);
                      }}
                    >
                      <CancelIcon color='error' style={{ fontSize: '15pt' }} />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              );
            })}
          </List>
        </div>
        {formError.editCadFile && (
          <Typography
            style={{
              marginTop: '1.875rem',
              display: 'flex',
              color: 'red',
              fontStyle: 'italic',
            }}
            variant='caption'
          >
            {formError.editCadFile}
          </Typography>
        )}
      </div>
      <InputLabel className={classes.inputLabel}>Name of Part*</InputLabel>
      <InputBase
        id='name'
        name='name'
        className={classes.inputField}
        value={name}
        onChange={(evt) => {
          setItemName(evt.target.value);
        }}
        style={{ minWidth: '438px' }}
      />
      <InputLabel className={classes.inputLabel}>Quantity</InputLabel>
      <Input
        className={classes.inputField}
        disableUnderline={true}
        inputProps={{
          step: 1,
          min: 0,
          max: 100000,
          type: 'number',
        }}
        value={qty}
        onChange={(evt) => {
          setItemQty(evt.target.value);
        }}
      />
      <div>
        <InputLabel className={classes.inputLabel}>
          Application of Part
        </InputLabel>
        <InputBase
          id='partApplication'
          name='partApplication'
          className={classes.inputField}
          value={partApplication}
          onChange={(evt) => {
            setPartApplication(evt.target.value);
          }}
          placeholder='E.g. Vacuum Chamber, Container for Liquid, etc.'
          style={{ minWidth: '438px' }}
        />
      </div>
      <div className={classes.inputContainer}>
        <TechnologyInputField
          value={technology}
          onChange={(newTech) => {
            setTechnology(newTech);
            technologyHasChanged(newTech);
            if (newTech === TECHNOLOGY_OPTION_TYPE.THREE_D_PRINTING) {
              setThreeDInfill(threeDPrintingInfillDefault);
              setThreeDLayerThickness(threeDPrintingLayerThicknessDefault);
            }
          }}
          technologyOptions={technologyOptions}
        />
        {isCustomTechnology(technology) && (
          <div style={{ marginLeft: 40 }}>
            <InputLabel className={classes.inputLabel}>
              Custom Technology
            </InputLabel>
            <InputBase
              id='otherTechnology'
              name='otherTechnology'
              type='text'
              onChange={(evt) => {
                setOtherTechnology(evt.target.value);
              }}
              className={classes.inputField}
              value={otherTechnology}
              style={{ minWidth: '400px' }}
            />
          </div>
        )}
      </div>
      <div className={classes.inputContainer}>
        <ThreeDPrintingTechnologyInputField
          visible={techIs3DPrinting}
          value={threeDTechnology}
          onChange={(newValue) => {
            setThreeDTechnology(newValue);
            const params = {
              technology,
              threeDTechnology: newValue,
            };
            threeDTechnologyHasChanged(params);
            if (newValue === THREE_D_P_FDM_TECH) {
              setThreeDInfill(threeDPrintingInfillDefault);
              setThreeDLayerThickness(threeDPrintingLayerThicknessDefault);
            } else {
              setThreeDInfill('');
              setThreeDLayerThickness('');
            }
          }}
          threeDTechnologyOptions={threeDTechnologyOptions}
        />
      </div>
      <div className={classes.inputContainer}>
        <MaterialCategoriesInputField
          technology={technology}
          visible={!techIs3DPrinting}
          value={material}
          onSelect={(value) => {
            setMaterial(value);
            const params = {
              technology,
              threeDTechnology,
              material: value,
            };
            materialHasChanged(params);
          }}
          materialCategoryOptions={materialCategoryOptions}
        />
        <ThreeDPrintingMaterialField
          technology={technology}
          threeDTechnology={threeDTechnology}
          visible={techIs3DPrinting}
          value={material}
          onSelect={(_material) => {
            setMaterial(_material);
            const params = {
              technology,
              threeDTechnology,
              material: _material,
            };
            materialHasChanged(params);
          }}
          threeDMaterialOptions={threeDMaterialOptions}
          defaultThreeDMaterial={defaultThreeDMaterial}
        />
        {isCustomMaterial(material) && (
          <div style={{ marginLeft: 40 }}>
            <InputLabel className={classes.inputLabel}>
              Other material
            </InputLabel>
            <InputBase
              id='otherMaterial'
              name='otherMaterial'
              className={classes.inputField}
              value={otherMaterial}
              onChange={(evt) => {
                setOtherMaterial(evt.target.value);
              }}
              style={{ minWidth: '400px' }}
            />
          </div>
        )}
      </div>
      {!isEmpty(materialColorOptions) && (
        <div className={classes.sectionFormField}>
          {renderMaterialColorInputField()}
        </div>
      )}
      <div className={classes.inputContainer}>
        <ThreeDInfillSelectField
          visible={techIs3DPrinting && threeDTechnology === THREE_D_P_FDM_TECH}
          value={threeDInfill}
          onSelect={(newValue) => setThreeDInfill(newValue)}
          ppe3dpInfillOptions={ppe3dpInfillOptions}
        />
      </div>
      <div className={classes.inputContainer}>
        <ThreeDLayerThicknessField
          visible={techIs3DPrinting && threeDTechnology === THREE_D_P_FDM_TECH}
          value={threeDLayerThickness}
          onSelect={(newValue) => setThreeDLayerThickness(newValue)}
        />
      </div>
      <div className={classes.inputContainer}>
        <SurfaceFinishingField
          visible={
            !isEmpty(surfaceFinishOptions) ||
            isCustomSurfaceFinish(surfaceFinish)
          }
          value={surfaceFinish}
          onSelect={(newValue) => {
            setSurfaceFinish(newValue);
            const params = {
              technology,
              material,
              surfaceFinish: newValue,
            };
            surfaceFinishHasChanged(params);
          }}
          surfaceFinishOptions={surfaceFinishOptions}
          selectColorSurfaces={selectColorSurfaces}
        />
        {isCustomSurfaceFinish(surfaceFinish) && (
          <div style={{ marginLeft: 40 }}>
            <InputLabel className={classes.inputLabel}>
              Custom Surface Finish
            </InputLabel>
            <InputBase
              id='otherSurfaceFinish'
              name='otherSurfaceFinish'
              type='text'
              onChange={(evt) => {
                setOtherSurfaceFinish(evt.target.value);
              }}
              className={classes.inputField}
              value={otherSurfaceFinish}
              style={{ minWidth: '400px' }}
            />
          </div>
        )}
      </div>
      {!isEmpty(surfaceFinishColorOptions) && (
        <div className={classes.sectionFormField}>
          {renderSurfaceFinishColor()}
        </div>
      )}
      <div className={classes.inputContainer}>
        <div>
          <InputLabel className={classes.inputLabel}>
            Tolerance&nbsp;
            <InfoIcon toolTipText='Please indicate tightest tolerance in your design' />
          </InputLabel>
          <ToleranceDropdown
            onChange={(evt) => {
              setItemTolerance(evt.target.value);
            }}
            value={tolerance}
            setUnitType={setUnitType}
            unitType={unitType}
            size='big'
          />
        </div>
      </div>
      <div>
        <InputLabel className={classes.inputLabel}>Comments</InputLabel>
        <InputBase
          multiline
          rows='6'
          id='remarks'
          name='remarks'
          type='text'
          placeholder='Do include any additional requests or remarks that we should take note of. Please also indicate if you wish for quotations for varying quantities, materials or technologies.'
          className={classes.inputField}
          onChange={(evt) => {
            setItemDescription(evt.target.value);
          }}
          value={description}
          style={{ width: '100%' }}
        />
      </div>
      <ReviewPartPopup
        dialog={reviewDialog}
        handleConfirmPart={handleConfirmPart}
        itemToCreate={itemToEdit}
        handleClose={() => setReviewDialogOpen(false)}
      />
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color='inherit' />
      </Backdrop>
    </Fragment>
  );

  return (
    <Fragment>
      <Title contentTitle='Edit Part' />
      <div className={classes.formComponent}>
        {contents}
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <BlueButton onBtnClick={() => handleEditPart()} btnContent='Save' />
        </div>
      </div>
    </Fragment>
  );
};

function mapStateToProps(state) {
  return {
    editItemSuccess: state.item.editItemSuccess,
    users: state.users.users,
    myUserId: state.auth.user.userID,
    userUnitType: state.auth.user.unitType,
  };
}

function matchDispatchToProps(dispatch) {
  return {
    submitEditPart: (editedPart) => dispatch(customerEditItem(editedPart)),
  };
}

const withConnect = connect(mapStateToProps, matchDispatchToProps);

export default withConnect(NewEditPartForm);
