import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles/index';

import { Button, Tooltip } from '@material-ui/core';

import { colors } from '../../palette';

import { isEmptyValue } from '../../utils/commonUtils';

import ItemColorPalettePopup from '../popups/ItemColorPalettePopup';
import clsx from 'clsx';
import InfoMatchValue from '../info/InfoMatchValue';
import FtrFieldLabel from '../ftr-components/FtrFieldLabel';

const useStyles = makeStyles(() => ({
  optionalBox: {
    marginTop: 15,
  },
  inputLabel: {
    fontSize: 16,
    marginBottom: 7,
    color: colors.fontGrey,
    fontWeight: 600,
  },
  inputLabelRowDisplay: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 12,
    color: colors.fontLightGrey,
    fontWeight: '600',
    textTransform: 'uppercase',
    width: 150,
    minWidth: 150,
  },
  selectField: {
    width: '100%',
  },
  rowDisplay: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  colorButton: {
    padding: '5px 14px',
    width: '100%',
    justifyContent: 'left',
    background: colors.fontWhite,
    border: `1px solid ${colors.inputBorderBlue}`,
    boxSizing: 'border-box',
    borderRadius: '5px',
    color: colors.fontGrey,
    textTransform: 'none',
    fontSize: '16px',
    minHeight: 40,
  },
  colorButtonSmall: {
    width: '100%',
    fontSize: 15,
    padding: '0px 8px',
    border: `1px solid ${colors.inputBorderBlue}`,
    boxSizing: 'border-box',
    borderRadius: '5px',
    color: `${colors.fontGrey}`,
    textTransform: 'none',
    justifyContent: 'left',
    height: '32px',
  },
  colorButtonMaterial: {
    borderColor: `${colors.inputBorderGrey}`,
  },
  quotationField: {
    marginTop: 8,
  },
  rowContainer: {
    display: 'flex',
    alignItems: 'center',
    '& > div': {
      width: '100%',
    },
  },
  container: {
    marginBottom: '13px',
  },
  v2: {
    '& .MuiButtonBase-root': {
      minHeight: 32,
      minWidth: 150,
      fontSize: 14,
      fontWeight: 600,
      color: colors.neutral080,
      padding: '4px 16px',
      borderRadius: 10,
      border: '0.8px solid #CCC',
    },
  },
  emptyValue: {
    '& .MuiButton-label': {
      color: colors.neutral040,
    },
  },
}));

function ColorInputField(props) {
  const classes = useStyles();

  const {
    visible = false,
    value,
    colorInputLabel,
    colorPalette,
    onSubmit,
    rowDisplay = false,
    bootstrapStyle = true,
    quotationField = false,
    valueCustomer,
    disabled = false,
    disableLabelOnBootstrapStyle = false,
    v2 = false,
    placeholder = '',
    isVerifyQuote = false,
  } = props;

  const [colorDialogOpen, setColorDialogOpen] = useState(false);

  if (!visible) {
    return null;
  }

  const renderButton = () => {
    return (
      <Button
        className={clsx(
          rowDisplay ? classes.colorButtonSmall : classes.colorButton,
          bootstrapStyle ? null : classes.colorButtonMaterial,
          isEmptyValue(value) ? classes.emptyValue : null
        )}
        onClick={() => setColorDialogOpen(true)}
        disabled={disabled}
      >
        {value ?? placeholder}
      </Button>
    );
  };

  const renderContent = () => {
    return (
      <div
        className={clsx(
          v2 ? classes.v2 : null,
          rowDisplay ? classes.rowDisplay : null,
          quotationField ? classes.quotationField : null
        )}
      >
        {!quotationField && !disableLabelOnBootstrapStyle && (
          <FtrFieldLabel>{colorInputLabel}</FtrFieldLabel>
        )}
        {isVerifyQuote ? (
          <div className={classes.rowContainer}>
            {renderButton()}
            <InfoMatchValue
              name='Material Color'
              valueCustomer={valueCustomer}
              valueSupplier={value}
            />
          </div>
        ) : quotationField ? (
          <Tooltip title={colorInputLabel}>{renderButton()}</Tooltip>
        ) : (
          renderButton()
        )}
      </div>
    );
  };

  return (
    <div className={!v2 ? classes.container : ''}>
      {renderContent()}
      {colorDialogOpen && (
        <ItemColorPalettePopup
          dialogOpen={colorDialogOpen}
          colorPalette={colorPalette}
          handleDialogClose={() => setColorDialogOpen(false)}
          handleSubmitColor={(selectedColor) => {
            onSubmit(selectedColor);
            setColorDialogOpen(false);
          }}
          value={value}
        />
      )}
    </div>
  );
}

export default ColorInputField;
