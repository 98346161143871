export const PRECISIONS_MAPPING = [0.5, 0.05, 0.005];

export const MIN_PRECISION = 0.001;
export const MAX_PRECISION = 5.0;
export const DEFAULT_PRECISION = 0.05;

export const DEFAULT_IMG_HEIGHT = 1920;
export const DEFAULT_IMG_WIDTH = 1920;
export const DEFAULT_MAX_FILE_SIZE = 13.0;

export const DEFAULT_VIEW = 'wireframe';
export const DEFAULT_ANGLE = 'isometric';
export const DEFAULT_COLOR_SCHEMA = 'Metal-gray';

export const VIEW_MAPPING = ['wireframe', 'axes', 'edges'];

export const ANGLE_MAPPING = [
  'isometric',
  'top',
  'bottom',
  'left',
  'right',
  'front',
  'back',
  'rearLeftBottom',
  'rearLeftTop',
];

export const COLOR_SCHEMA_MAPPING = [
  'Metal-gray',
  'Metal-blue',
  'Lightslategray',
  'Gray',
  'Recommender-green',
];
