// Import settings
import React, { useState, createContext, useRef } from 'react';
import {
  Drawer,
  Fab,
  IconButton,
  useTheme,
  useMediaQuery,
  makeStyles,
} from '@material-ui/core';
import {
  Forward as ForwardIcon,
  NavigateBefore as NavigateBeforeIcon,
} from '@material-ui/icons';

// Import color palette
import { colors } from '../palette';

// Style component
const useStyles = makeStyles({
  root: {
    display: 'flex',
    height: '100%',
  },
  drawer: (props) => ({
    flexShrink: 0,
    width: props.drawerWidth,
  }),
  drawerPaper: (props) => ({
    color: colors.fontGrey,
    display: 'flex',
    flexDirection: 'column',
    width: props.drawerWidth,
    justifyContent: 'space-between',
  }),
  sidebar: {
    display: 'flex',
  },
});

/**
 *
 * @param {Object} props
 * @param {Number} props.drawerWidth
 * @param {React.ReactElement} props.children
 * @param {React.ReactElement} props.Content
 */

export const DrawerButtonsContext = createContext(null);

function ResponsiveSidebar(props) {
  const { children, drawerWidth, Content } = props;
  const [showDrawer, setShowDrawer] = useState(false);
  const expandDrawerButtonRef = useRef(null);
  const closeDrawerButtonRef = useRef(null);

  const classes = useStyles({ drawerWidth });

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const renderDrawer = () => {
    return (
      <Drawer
        className={classes.drawer}
        style={{ width: drawerWidth }}
        variant={isMobile ? 'temporary' : 'permanent'}
        classes={{
          paper: classes.drawerPaper,
        }}
        open={showDrawer}
        onClose={() => setShowDrawer(false)}
        anchor='left'
      >
        {isMobile && showDrawer && (
          <IconButton
            ref={closeDrawerButtonRef}
            style={{
              position: 'fixed',
              top: '50%',
              left: drawerWidth - 45,
              borderRadius: '50%',
              borderLeft: `1px solid ${colors.blue050}`,
              padding: 8,
              zIndex: 1,
            }}
            id='sidebar-mobile-close'
            onClick={() => setShowDrawer(false)}
          >
            <NavigateBeforeIcon
              style={{ fontSize: '2rem', color: colors.blue050 }}
            />
          </IconButton>
        )}
        {children}
      </Drawer>
    );
  };
  const renderLeftSideBar = () => {
    return <div className={classes.sidebar}>{renderDrawer()}</div>;
  };

  const renderExpandDrawerButton = () => {
    return (
      <Fab
        style={{
          position: 'fixed',
          top: '50%',
          left: -22,
          padding: 2,
          zIndex: 999,
        }}
        ref={expandDrawerButtonRef}
        onClick={() => setShowDrawer(true)}
        color='primary'
        size='large'
        id='sidebar-mobile-open'
      >
        <ForwardIcon style={{ fontSize: '3rem' }} />
      </Fab>
    );
  };

  return (
    <DrawerButtonsContext.Provider
      value={{ expandDrawerButtonRef, closeDrawerButtonRef }}
    >
      <div className={classes.root}>
        {!isMobile && renderLeftSideBar()}
        {isMobile && renderDrawer()}
        {isMobile && renderExpandDrawerButton()}
        {Content}
      </div>
    </DrawerButtonsContext.Provider>
  );
}

export default ResponsiveSidebar;
