import React from 'react';
import clsx from 'clsx';

import { Publish as PublishIcon } from '@material-ui/icons';

import { makeStyles } from '@material-ui/core/styles/index';

import { FlexColumn, FlexRow, FlexRowEnd } from './layouts/FlexLayouts';
import { FtrCaption, FtrTooltip } from './ftr-components';

import { colors } from '../palette';

// -------------------------------------------------------------------------------------------------

const useStyles = makeStyles(() => ({
  button: {
    display: 'flex',
    alignItems: 'center',
    width: 'fit-content',
    color: colors.blue050,
    minWidth: 120,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  buttonWithText: {
    padding: '0.5rem',
    borderRadius: 12,
    minWidth: 120,
    '&:hover': {
      backgroundColor: colors.blue010,
    },
  },
  disabled: {
    color: colors.neutral040,
    '&:hover': {
      cursor: 'not-allowed',
    },
  },
}));

function FilesUploadButton(props) {
  const classes = useStyles();

  const {
    id = 'file-upload',
    buttonText = 'Upload Files',
    handleUploadFiles,
    multiple = true,
    accept = '*',
    disabled = false,
    showButtonText = true,
    tooltip = 'Upload Files',
    showTooltip = false,
    icon = <PublishIcon />,
    note = '',
    align = 'left',
  } = props;

  const Layout = align === 'left' ? FlexRow : FlexRowEnd;

  return (
    <FtrTooltip description={showTooltip && tooltip}>
      <div>
        <FlexColumn style={{ gap: 0 }}>
          <Layout>
            <input
              id={id}
              type='file'
              multiple={multiple}
              onChange={(evt) => handleUploadFiles(evt.target.files)}
              onClick={(event) => (event.target.value = null)}
              style={{
                display: 'none',
              }}
              className={clsx({ [classes.disabled]: disabled })}
              accept={accept}
              disabled={disabled}
            />
            <label
              className={clsx(
                classes.button,
                {
                  [classes.disabled]: disabled,
                },
                showButtonText && classes.buttonWithText
              )}
              htmlFor={id}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'end',
              }}
            >
              {icon}&nbsp;
              {showButtonText && buttonText}
            </label>
          </Layout>
          {note && (
            <FtrCaption style={{ marginLeft: '2.3rem' }}>
              Note: {note}
            </FtrCaption>
          )}
        </FlexColumn>
      </div>
    </FtrTooltip>
  );
}

export default FilesUploadButton;
