import React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles/index';

import { ReactComponent as IdeaIcon } from '../../assets/icons/idea_icon.svg';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';

import { FtrButton, FtrHeading, FtrLightText } from '../ftr-components';
import { FlexRowCenter } from '../layouts/FlexLayouts';
import { updateGuidedTourCompletionStatus } from '../../apis/tourApi';
import { getUserIDSelector } from '../../selectors/userSelector';
import {
  COMPLETED_VALUE,
  PARTS_LIBRARY_FIRST_TIME,
} from '../tour/guidedTours/tourConstants';

const useStyles = makeStyles((theme) => ({
  paper: {
    borderRadius: 30,
    position: 'relative',
    padding: '2rem',
    maxWidth: 385,
    [theme.breakpoints.down('xs')]: {
      padding: '1rem',
    },
  },
  actions: {
    justifyContent: 'center',
    paddingBottom: '1rem',
  },
  contents: {
    padding: '0 2rem 2rem',
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      padding: '0 0.5rem 0.5rem',
    },
  },
  header: {
    fontSize: 22,
    [theme.breakpoints.down('xs')]: {
      fontSize: '18px !important',
      textAlign: 'center',
    },
  },
  title: {
    [theme.breakpoints.down('xs')]: {
      padding: '0.5rem 0.5rem',
    },
  },
}));

function PartsLibraryFirstTimePopup(props) {
  const { open, setOpen, withApi = true } = props;

  const classes = useStyles();

  const userID = useSelector(getUserIDSelector);

  const handleGotIt = () => {
    if (withApi) {
      updateGuidedTourCompletionStatus(
        userID,
        PARTS_LIBRARY_FIRST_TIME,
        COMPLETED_VALUE
      );
    }
    setOpen(false);
  };

  return (
    <Dialog
      maxWidth='xl'
      open={open}
      aria-labelledby='parts-library-first-time'
      classes={{ paper: classes.paper }}
    >
      <DialogTitle id='parts-library-first-time' className={classes.title}>
        <FlexRowCenter style={{ marginBottom: '2rem' }}>
          <IdeaIcon />
        </FlexRowCenter>
        <FtrHeading className={classes.header}>
          Welcome to your Parts Library
        </FtrHeading>
      </DialogTitle>
      <DialogContent className={classes.contents}>
        <FtrLightText>
          The Parts Library is your dedicated space to store and organise your
          parts. Use it to keep track of all your components.
        </FtrLightText>
        <br />
        <FtrLightText>
          {
            "When you're ready to purchase, head over to Upload Parts to get quotes and place orders."
          }
        </FtrLightText>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <FtrButton color='blue' onClick={handleGotIt}>
          Got it
        </FtrButton>
      </DialogActions>
    </Dialog>
  );
}

export default PartsLibraryFirstTimePopup;
