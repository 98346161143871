import React, { useEffect, useMemo } from 'react';

import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import { colors } from '../../palette';

// -------------------------------------------------------------------------------------------------

function FtrSnackbar(props) {
  const {
    open,
    autoHideDuration = 3000,
    message = 'This is a success message!',
    severity = 'info',
    handleClose = () => {},
  } = props;

  useEffect(() => {
    if (open) {
      setTimeout(() => {
        handleClose();
      }, autoHideDuration);
    }
  }, [open]);

  const backgroundColor = useMemo(() => {
    switch (severity) {
      case 'error':
        return colors.red010;
      case 'info':
        return colors.blue020;
      default:
        return colors.blue020;
    }
  }, [severity]);

  return (
    <Snackbar open={open} autoHideDuration={autoHideDuration} {...props}>
      <Alert
        onClose={handleClose}
        severity={severity}
        style={{
          borderRadius: 10,
          backgroundColor,
        }}
      >
        {message}
      </Alert>
    </Snackbar>
  );
}

export default FtrSnackbar;
