// Import settings
import React, { useEffect, useState } from 'react';

// Import color palette
import { colors } from '../../palette';

// Import material UI components
import { withStyles } from '@material-ui/core/styles/index';
import { Step, Stepper, StepConnector, StepLabel } from '@material-ui/core';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';

// Style components
const QontoConnector = withStyles({
  alternativeLabel: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  active: {
    '& $line': {
      borderColor: colors.solidBlue,
    },
  },
  completed: {
    '& $line': {
      borderColor: colors.solidBlue,
    },
  },
  line: {
    borderColor: colors.bgLightGrey,
    borderTopWidth: 2,
    borderRadius: 1,
  },
})(StepConnector);

function UploadPartsStepper(props) {
  const { activeStep, uploadProjectFilesSteps = [] } = props;

  const [steps, setSteps] = useState([]);

  useEffect(() => {
    if (!isEmpty(uploadProjectFilesSteps)) {
      setSteps(uploadProjectFilesSteps);
    }
  }, [uploadProjectFilesSteps]);

  return (
    <Stepper
      activeStep={activeStep}
      connector={<QontoConnector />}
      style={{ padding: '0px 0px 30px' }}
    >
      {steps.map((label) => {
        return (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        );
      })}
    </Stepper>
  );
}
function mapStateToProps(state) {
  return {
    uploadProjectFilesSteps: state.item.uploadProjectFilesSteps,
  };
}

function matchDispatchToProps() {
  return {};
}

const withConnect = connect(mapStateToProps, matchDispatchToProps);

export default withConnect(UploadPartsStepper);
