/* eslint-disable react/no-unknown-property */
import { Vector3 } from 'three';
import { Scene, Matrix4 } from 'three';
import React, { useRef, useMemo, Fragment } from 'react';
import { useFrame, useThree, createPortal } from 'react-three-fiber';
import { OrthographicCamera, useCamera } from '@react-three/drei';

function ViewCube() {
  const { gl, scene, camera, size } = useThree();
  const virtualScene = useMemo(() => new Scene(), []);
  const virtualCam = useRef();
  const ref = useRef();
  const matrix = new Matrix4();

  useFrame(() => {
    // matrix.getInverse(camera.matrix)
    matrix.copy(camera.matrix).invert();
    ref.current.quaternion.setFromRotationMatrix(matrix);
    gl.autoClear = true;
    gl.render(scene, camera);
    gl.autoClear = false;
    gl.clearDepth();
    gl.render(virtualScene, virtualCam.current);
  }, 1);

  return createPortal(
    <Fragment>
      <OrthographicCamera
        ref={virtualCam}
        makeDefault={false}
        position={[0, 0, 100]}
      />
      <mesh
        ref={ref}
        raycast={useCamera(virtualCam)}
        position={[size.width / 2 - 80, size.height / 4 - 250, 0]}
      >
        <axesHelper args={[60]} position={new Vector3(0, 0, 0)} />
      </mesh>
      <ambientLight intensity={0.5} />
      <pointLight position={[10, 10, 10]} intensity={0.5} />
    </Fragment>,
    virtualScene
  );
}

export default ViewCube;
