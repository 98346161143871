import React from 'react';

import { FtrButton, FtrTypography } from '../../ftr-components';

import BulkPricingIcon from '../../../assets/icons/bulk_pricing_icon.svg';

import { colors } from '../../../palette';

function ViewBulkPricingButton(props) {
  const { onClick = () => {} } = props;

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'end',
        backgroundColor: 'inherit',
      }}
    >
      <FtrButton
        variant='text'
        style={{
          padding: '0.4rem 0',
          backgroundColor: 'inherit',
          position: 'static',
        }}
        startIcon={<img src={BulkPricingIcon} alt='bulk-pricing-icon' />}
        onClick={onClick}
      >
        <FtrTypography
          type='body'
          fontSize='14'
          style={{ color: colors.blue060, fontWeight: 600 }}
        >
          View Bulk Pricing
        </FtrTypography>
      </FtrButton>
    </div>
  );
}

export default ViewBulkPricingButton;
