import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import {
  Checkbox,
  FormControlLabel,
  makeStyles,
  useMediaQuery,
  useTheme,
  Tooltip,
} from '@material-ui/core';
import { Edit as EditIcon, Add as AddIcon } from '@material-ui/icons';

import { colors } from '../palette';
import OutlinedDiv from './panels/OutlinedDiv';
import EditorAddressesPopup from './popups/EditorAddressesPopup';
import CreateUpdateDeliveryInfoPopup from './popups/CreateUpdateAddressPopup';
import SelectAddresses from './selects/SelectAddresses';
import { getDefaultAddresses } from '../utils/addressUtils';

import { usePrevious } from '../hooks/usePrevious';

import { ADDRESS_TYPES } from '../constants/deliveryInfoConstants';

import { getUserAddresses } from '../apis/userApi';

// Style components
const useStyles = makeStyles(() => ({
  rowTitle: {
    fontWeight: 600,
  },
  rowContent: {
    margin: '0 5px',
  },
  addresses: {
    marginTop: '1rem',
    position: 'relative',
    '&:hover $iconBtn': {
      opacity: 1,
    },
  },
  checkboxDifferentAddress: {
    '& .MuiFormControlLabel-label': {
      fontSize: '0.8rem',
    },
    '& .MuiSvgIcon-root': {
      height: '1rem',
    },
  },
  sameAsShippingAddress: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
    padding: '4px 0 6px',
    fontSize: '14px',
  },
  iconBtn: {
    cursor: 'pointer',
    opacity: 0,
  },
}));

/**
 *
 * @param {object} props
 * @param {object} props.handlers
 * @param {() => void} props.handlers.setShippingAddress
 * @param {() => void} props.handlers.setSwitchDifferentAddress
 * @param {() => void} props.handlers.setBillingAddress
 * @param {object} props.values
 * @param {object} props.values.shippingAddress
 * @param {object} props.values.billingAddress
 * @param {boolean} props.values.switchDifferentAddress
 * @param {object} props.values.userID
 * @param {boolean} props.maxWidth
 * @returns
 */
function Addresses(props) {
  const classes = useStyles();

  const { handlers, values, maxWidth = true } = props;

  const {
    setShippingAddress = () => null,
    setSwitchDifferentAddress = () => null,
    setBillingAddress = () => null,
  } = handlers;

  const {
    shippingAddress,
    billingAddress,
    switchDifferentAddress,
    userID,
  } = values;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [showEditorAddresses, setShowEditorAddresses] = useState(false);
  const [showCreateAddressPopup, setShowCreateAddressPopup] = useState({
    open: false,
    type: '',
  });

  const { data: addresses, refetch } = useQuery(
    ['getUserAddresses', userID],
    () => getUserAddresses(userID)
  );

  const previousAddresses = usePrevious(addresses);

  useEffect(() => {
    if (userID) {
      refetch();
    }
  }, [userID]);

  useEffect(() => {
    if (addresses && !previousAddresses) {
      const result = getDefaultAddresses(addresses);
      setShippingAddress(result?.defaultShipping || {});
      if (result?.defaultBilling) {
        setSwitchDifferentAddress(true);
        setBillingAddress(result?.defaultBilling);
      }
    }
  }, [addresses]);

  const onSuccessCreate = ({ result, addressType }) => {
    if (addressType === ADDRESS_TYPES.SHIPPING) {
      setShippingAddress(result);
    } else if (addressType === ADDRESS_TYPES.BILLING) {
      setSwitchDifferentAddress(true);
      setBillingAddress(result);
    }
  };

  /**
   *
   * @param {'billing'|'shipping'} type
   * @returns
   */
  const renderActionButtons = (type) => {
    if (isMobile) {
      return (
        <div style={{ display: 'flex', gap: '5px' }}>
          <span
            onClick={() => setShowCreateAddressPopup({ open: true, type })}
            style={{
              cursor: 'pointer',
              color: colors.solidBlue,
              marginRight: '5px',
            }}
          >
            Add
          </span>
          <span
            onClick={() => setShowEditorAddresses(true)}
            style={{ cursor: 'pointer', color: colors.solidBlue }}
          >
            Edit
          </span>
        </div>
      );
    }
    return (
      <div style={{ display: 'flex', gap: '5px' }}>
        <Tooltip
          title='Add address'
          onClick={() => setShowCreateAddressPopup({ open: true, type })}
          arrow
        >
          <AddIcon className={classes.iconBtn} />
        </Tooltip>
        <Tooltip
          title='Edit address'
          onClick={() => setShowEditorAddresses(true)}
          arrow
        >
          <EditIcon className={classes.iconBtn} />
        </Tooltip>
      </div>
    );
  };

  return (
    <div className={classes.addresses}>
      <OutlinedDiv label='Addresses'>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div className={classes.rowTitle}>Shipping address:</div>
          {renderActionButtons('shipping')}
        </div>
        <div className={classes.rowContent} style={{ paddingTop: '5px' }}>
          <SelectAddresses
            id='shipping-address'
            handler={setShippingAddress}
            value={shippingAddress?.deliveryInfoID || null}
            data={addresses}
            maxWidth={maxWidth}
            field='Shipping address'
            required
          />
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: '1rem',
          }}
        >
          <div className={classes.rowTitle}>Billing address:</div>
          {renderActionButtons('billing')}
        </div>
        <div
          className={classes.rowContent}
          style={{
            display: 'flex',
            flexDirection: 'column',
            paddingTop: '5px',
          }}
        >
          {switchDifferentAddress ? (
            <SelectAddresses
              id='billing-address'
              handler={setBillingAddress}
              value={billingAddress?.deliveryInfoID || null}
              data={addresses}
              maxWidth={maxWidth}
            />
          ) : (
            <span className={classes.sameAsShippingAddress}>
              Same as shipping address
            </span>
          )}
          <FormControlLabel
            className={classes.checkboxDifferentAddress}
            control={
              <Checkbox
                checked={switchDifferentAddress}
                onChange={(e) => setSwitchDifferentAddress(e.target.checked)}
                name='checkedDifferentAddress'
              />
            }
            label='Bill to different address'
          />
        </div>
      </OutlinedDiv>
      {showEditorAddresses && (
        <EditorAddressesPopup
          open={showEditorAddresses}
          onClose={() => setShowEditorAddresses(false)}
          userID={userID}
        />
      )}
      {showCreateAddressPopup?.open && (
        <CreateUpdateDeliveryInfoPopup
          open={showCreateAddressPopup?.open}
          onClose={() => setShowCreateAddressPopup({ open: false, type: '' })}
          userID={userID}
          type='create'
          addressType={showCreateAddressPopup?.type}
          onSuccess={onSuccessCreate}
        />
      )}
    </div>
  );
}

export default Addresses;
