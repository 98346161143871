import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { InputAdornment } from '@material-ui/core';

import FtrTextField from './FtrTextField';
import FtrSvgImage from '../images/FtrSvgImage';
import { FlexRow } from '../layouts/FlexLayouts';
import { FtrSmallButton } from './FtrButton';

import GreenTickIcon from '../../assets/icons/green_tick.svg';
import AlertIcon from '../../assets/icons/red_alert.svg';

import { setPromoCodeAction } from '../../actions/multiCheckoutForm';

import { toUpperCase } from '../../utils/stringUtils';

import { getMultiCheckoutFormSelector } from '../../selectors/multiCheckoutFormSelector';

// -------------------------------------------------------------------------------------------------

function FtrPromoCodeField(props) {
  const { disabled = false } = props;

  const dispatch = useDispatch();

  const multiCheckoutForm = useSelector(getMultiCheckoutFormSelector);

  const checkoutPriceSummary = multiCheckoutForm?.checkoutPriceSummary || {};
  const factoremDiscountInfo = checkoutPriceSummary?.factoremDiscountInfo || {};
  const promoCodeValid = factoremDiscountInfo?.promoCodeValid || false;
  const currentPromoCode =
    factoremDiscountInfo?.factoremCustomDiscountCode || '';
  const message = factoremDiscountInfo?.message || '';

  const [promoCode, setPromoCode] = React.useState('');

  const codeError =
    !promoCodeValid && currentPromoCode && currentPromoCode === promoCode;
  const codeValid =
    promoCodeValid && currentPromoCode && currentPromoCode === promoCode;

  useEffect(() => {
    setPromoCode(multiCheckoutForm.factoremCustomDiscountCode);
  }, [multiCheckoutForm.factoremCustomDiscountCode]);

  const handleApplyCode = (code) => {
    const newCode = code || promoCode || '';
    if (currentPromoCode === newCode) {
      return;
    }

    dispatch(setPromoCodeAction(newCode));
  };

  const handleUpdateCode = (evt) => {
    const value = evt.target.value;
    const upperCaseValue = toUpperCase(value);
    setPromoCode(upperCaseValue);
  };

  const getPromoCodeIcon = () => {
    if (codeValid) {
      return <FtrSvgImage src={GreenTickIcon} />;
    }

    if (codeError) {
      return <FtrSvgImage src={AlertIcon} />;
    }

    return null;
  };

  return (
    <FlexRow style={{ alignItems: 'start', marginBottom: 8 }}>
      <FtrTextField
        style={{ marginBottom: 0 }}
        value={promoCode}
        onChange={handleUpdateCode}
        onKeyDown={(evt) => {
          if (evt.key === 'Enter') {
            handleApplyCode();
            return;
          }
          handleUpdateCode(evt);
        }}
        error={codeError}
        helperText={codeError && message}
        InputProps={{
          endAdornment: (
            <InputAdornment position='end'>{getPromoCodeIcon()}</InputAdornment>
          ),
        }}
        placeholder='Enter Promo Code'
        onBlur={(evt) => {
          handleApplyCode(evt.target.value);
        }}
      />
      <FtrSmallButton
        onClick={() => handleApplyCode()}
        disabled={disabled || !promoCode}
        style={{ marginTop: 2 }}
      >
        Apply
      </FtrSmallButton>
    </FlexRow>
  );
}

export default FtrPromoCodeField;
