import { isCadContainPdf } from '../utils/partUploadUtils';
import { isEmptyValue } from '../utils/commonUtils';
import { isPpeItem } from '../utils/itemUtils';

// ------------------------------------------------------------------------------

/**
 * This is proxy to add utils to part upload item
 * which is in formDataAvailable redux
 */
export const partUploadProxy = {
  // Trap for property getting
  get: function(target, prop, receiver) {
    if (prop === 'isPpeItem') {
      return function() {
        return isPpeItem(target);
      };
    }

    if (prop === 'hasPdfUploaded') {
      return function() {
        return isCadContainPdf(target.cadPart);
      };
    }

    // check if TDE fields are generated already
    if (prop === 'hasTdeGeneratedFields') {
      return function() {
        return !isEmptyValue(target.generatedFields);
      };
    }

    if (prop === 'isOrderAgain') {
      return function() {
        return target.orderAgain === true;
      };
    }

    if (prop === 'isRfqConverted') {
      return function() {
        return (
          !receiver.isPpeItem() &&
          (!isEmptyValue(target.originalTargetPrice) ||
            !isEmptyValue(target.expectedLeadTime))
        );
      };
    }

    // Use Reflect.get to ensure correct 'this' binding
    return Reflect.get(target, prop, receiver);
  },
};
