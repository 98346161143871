import React, { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Checkbox, FormControlLabel } from '@material-ui/core';

import YesNoPopup from './YesNoPopup';
import SingleImage from '../images/SingleImage';
import Row from '../Row';
import { FlexColumn } from '../layouts/FlexLayouts';

import { isEmptyValue } from '../../utils/commonUtils';
import { FtrErrorText } from '../ftr-components/FtrTypography';

function OrderProjectAgainPopup(props) {
  const { open, items = [], handleCancel, handleOk } = props;

  const history = useHistory();

  const allItemIDs = useMemo(() => items.map((item) => item.itemID), [items]);

  const [selectedItemIDs, setSelectedItemIDs] = useState([]);
  const [error, setError] = useState('');

  const allSelected = useMemo(
    () => selectedItemIDs.length === allItemIDs.length,
    [selectedItemIDs, allItemIDs]
  );

  useEffect(() => {
    if (isEmptyValue(items)) {
      return;
    }
    setSelectedItemIDs(items.map((item) => item.itemID));
  }, [items]);

  const handleItemClick = (itemID) => {
    const _selectedItemIDs = selectedItemIDs.includes(itemID)
      ? selectedItemIDs.filter((i) => i !== itemID)
      : [...selectedItemIDs, itemID];
    setSelectedItemIDs(_selectedItemIDs);
    setError('');
  };

  const renderItems = () => {
    return (
      <FlexColumn
        style={{
          alignItems: 'start',
          width: 'auto',
          gap: '1rem',
        }}
      >
        {allItemIDs.length > 1 && (
          <FormControlLabel
            control={
              <Checkbox
                checked={allSelected}
                onChange={(e) => {
                  if (e.target.checked) {
                    setSelectedItemIDs(allItemIDs);
                  } else {
                    setSelectedItemIDs([]);
                  }
                  setError('');
                }}
                name='select-all'
              />
            }
            label='Select All'
          />
        )}
        {items.map((item) => {
          return (
            <FormControlLabel
              key={item.itemID}
              control={
                <Row>
                  <SingleImage
                    width={50}
                    height={50}
                    url={item.imageUrl ?? item.twoDImageUrl}
                    noBorder
                  />
                  <Checkbox
                    checked={selectedItemIDs.includes(item.itemID)}
                    onChange={() => handleItemClick(item.itemID)}
                    name={`checkbox-${item.itemID}`}
                  />
                </Row>
              }
              label={`${item.name} (#${item.itemID})`}
            />
          );
        })}
        {error && <FtrErrorText>{error}</FtrErrorText>}
      </FlexColumn>
    );
  };

  const renderBody = () => {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        {renderItems()}
      </div>
    );
  };

  return (
    <YesNoPopup
      open={open}
      title='Please select item(s) to re-order'
      body={renderBody()}
      handleNo={handleCancel}
      handleYes={() => {
        if (isEmptyValue(selectedItemIDs)) {
          setError('Please select item(s) to re-order');
          return;
        }
        handleOk(selectedItemIDs);
        history.push('/summary');
      }}
      noButtonLabel='Cancel'
      yesButtonLabel='Ok'
    />
  );
}

export default OrderProjectAgainPopup;
