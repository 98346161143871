import React, { useReducer, forwardRef } from 'react';

import VerifyPartPopup from '../components/popups/VerifyPartPopup';
// ------------------------------------------------------------------------------

function withVerifyPartPopupHOC(WrappedComponent) {
  const verifyPartPopupHOC = forwardRef((props, ref) => {
    const [localState, updateLocalState] = useReducer(
      (prev, next) => ({ ...prev, ...next }),
      { open: false }
    );

    return (
      <>
        <WrappedComponent
          ref={ref} // Forward the ref to the WrappedComponent
          openVerifyPartPopup={({ itemID, refetch }) =>
            updateLocalState({
              open: true,
              itemID,
              refetch,
            })
          }
          {...props}
        />

        <VerifyPartPopup
          open={localState.open}
          handleClose={() => updateLocalState({ open: false })}
          handleRefetch={() => {
            localState.refetch();
            updateLocalState({ open: false });
          }}
          itemID={localState.itemID}
        />
      </>
    );
  });

  // Set the displayName for the HOC
  const wrappedComponentName =
    WrappedComponent.displayName || WrappedComponent.name || 'Component';
  verifyPartPopupHOC.displayName = `withVerifyPartPopup(${wrappedComponentName})`;

  return verifyPartPopupHOC;
}

export default withVerifyPartPopupHOC;
