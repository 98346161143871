/* eslint-disable react/no-unknown-property */
import React, {
  Suspense,
  useRef,
  useImperativeHandle,
  forwardRef,
  Fragment,
} from 'react';
import { Canvas } from 'react-three-fiber';
import Controls from './Controls';
import Model from './Model';
import ViewAxis from './ViewAxis';

const CanvasScreen = ({ stlFile, modelColor, bgColor }, ref) => {
  const orientationRef = useRef();
  const ctrlRef = useRef();

  useImperativeHandle(ref, () => ({
    resetCamera: () => {
      ctrlRef.current.resetControls();
      return orientationRef?.current?.resetCamera();
    },
    frontView: () => {
      return orientationRef?.current?.frontView();
    },
    topView: () => {
      return orientationRef?.current?.topView();
    },
    rightView: () => {
      return orientationRef?.current?.rightView();
    },
    setTransparent: () => {
      return orientationRef?.current?.setTransparent();
    },
  }));

  return (
    <Fragment>
      <Canvas
        style={{ background: bgColor }}
        pixelRatio={window.devicePixelRatio}
      >
        <Controls ref={ctrlRef} />
        <ambientLight />
        <Suspense fallback={null}>
          <Model
            ref={orientationRef}
            stlFile={stlFile}
            modelColor={modelColor}
          />
        </Suspense>
        <gridHelper args={[100, 10]} />
        <ViewAxis />
      </Canvas>
    </Fragment>
  );
};

export default forwardRef(CanvasScreen);
