import React, { useState } from 'react';

import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@material-ui/core';

import { Star } from '@material-ui/icons';

import { notifyError } from '../services/notificationService';

import { QUESTION_TYPES } from '../constants/userFeedbackConstants';

import { colors } from '../palette';
import { FtrTextField } from '../components/ftr-components/FtrField';
import {
  FlexColumn,
  FlexColumnCenter,
  FlexRow,
} from '../components/layouts/FlexLayouts';
import { FtrBoldText, FtrButton } from '../components/ftr-components';

export const renderShortAnswerQuestion = (question, handler) => {
  return (
    <div style={{ textAlign: 'start', color: colors.blue050 }}>
      <Typography>
        {question.id}. {question.question}
      </Typography>
      <TextField
        id='standard-basic'
        fullWidth
        onChange={(event) => {
          const value = event.target.value;
          handler({
            id: question.id,
            answer: value,
          });
        }}
      />
    </div>
  );
};

export const renderShortAnswerQuestionV2 = (question, handler) => {
  return (
    <FtrTextField
      title={question.question}
      titleFontSize='16'
      id={question.id}
      onChange={(event) => {
        const value = event.target.value;
        handler({
          id: question.id,
          answer: value,
        });
      }}
      style={{
        gap: '0.8rem',
      }}
      placeholder='Type here'
    />
  );
};

export const renderRadioQuestion = (question, handler) => {
  return (
    <FlexColumn>
      <FormControl component='fieldset' style={{ marginTop: '2rem' }}>
        <FormLabel style={{ color: colors.blue050 }} id={question.id}>
          {question.id}. {question.question}
        </FormLabel>
        <RadioGroup
          onChange={(event) => {
            handler({
              id: question.id,
              answer: event.target.value,
            });
          }}
        >
          {Object.entries(question.options).map(([key, value]) => {
            return (
              <FormControlLabel
                key={key}
                value={value}
                control={<Radio color='primary' />}
                label={value}
              />
            );
          })}
        </RadioGroup>
      </FormControl>
    </FlexColumn>
  );
};

export const renderRadioQuestionV2 = (question, handler) => {
  return (
    <div style={{ textAlign: 'start' }}>
      <FormControl component='fieldset'>
        <FormLabel id={question.id}>
          <FtrBoldText
            fontSize='16'
            style={{
              color: colors.neutral070,
              marginBottom: '1rem',
            }}
          >
            {question.question ===
            'How would you feel if you could no longer use Factorem?' ? (
              <span>
                How would you feel if you could <strong>no longer</strong> use
                Factorem?
              </span>
            ) : (
              question.question
            )}
          </FtrBoldText>
        </FormLabel>
        <FlexRow>
          {Object.entries(question.options).map(([key, value]) => {
            return (
              <FtrButton
                key={key}
                variant={question.answer === value ? 'contained' : 'outlined'}
                color='blue'
                onClick={() => {
                  handler({
                    id: question.id,
                    answer: value,
                  });
                }}
              >
                {value}
              </FtrButton>
            );
          })}
        </FlexRow>
      </FormControl>
    </div>
  );
};

export const RateQuestion = (props) => {
  const { question, handler } = props;
  const [hoverValue, setHoverValue] = useState(0);
  const [isHovering, setIsHovering] = useState(false);

  const _hoverValue = isHovering && hoverValue;

  return (
    <div style={{ textAlign: 'start' }}>
      <FormControl component='fieldset'>
        <FormLabel id={question.id}>
          <FtrBoldText
            fontSize='16'
            style={{
              color: colors.neutral070,
              marginBottom: '0.5rem',
            }}
          >
            {question.question}
          </FtrBoldText>
        </FormLabel>
        <FlexRow>
          {[...Array(question.max - question.min + 1).keys()].map((i) => {
            const value = i + question.min;
            return (
              <Star
                onMouseEnter={() => {
                  setHoverValue(value);
                  setIsHovering(true);
                }}
                onMouseLeave={() => {
                  setHoverValue(value);
                  setIsHovering(false);
                }}
                style={{
                  cursor: 'pointer',
                  height: 36,
                  width: 36,
                  transition: 'color 0.3s ease',
                }}
                htmlColor={
                  value <= (_hoverValue || question.answer)
                    ? colors.orange
                    : colors.neutral040
                }
                key={i}
                onClick={() => {
                  handler({
                    id: question.id,
                    answer: value,
                  });
                }}
              />
            );
          })}
        </FlexRow>
      </FormControl>
    </div>
  );
};

export const ScaleLinearQuestion = ({ question, handler }) => {
  return (
    <div style={{ textAlign: 'start' }}>
      <FormControl component='fieldset'>
        <FormLabel id={question.id}>
          <FtrBoldText
            fontSize='16'
            style={{
              color: colors.neutral070,
              marginBottom: '0.5rem',
            }}
          >
            {question.question}
          </FtrBoldText>
        </FormLabel>
        <FlexRow style={{ gap: 0, alignItems: 'flex-end' }}>
          {question.leftLabel && (
            <Typography style={{ marginRight: '0.5rem', fontSize: '14px' }}>
              {question.leftLabel}
            </Typography>
          )}

          {[...Array(question.max - question.min + 1).keys()].map((i) => {
            const value = i + question.min;
            return (
              <FlexColumnCenter key={i}>
                <Typography style={{ fontSize: '14px' }}>{value}</Typography>
                <Radio
                  color='primary'
                  key={i}
                  onChange={() => {
                    handler({
                      id: question.id,
                      answer: value,
                    });
                  }}
                  checked={value === question.answer}
                  value={value}
                />
              </FlexColumnCenter>
            );
          })}
          {question.rightLabel && (
            <Typography style={{ marginLeft: '0.5rem', fontSize: '14px' }}>
              {question.rightLabel}
            </Typography>
          )}
        </FlexRow>
      </FormControl>
    </div>
  );
};

export const MultiSelectionQuestion = (props) => {
  const { question, handler } = props;

  const isOtherSelected = question.answer?.some((a) => a.includes('Other:'));

  return (
    <div style={{ textAlign: 'start' }}>
      <FormControl component='fieldset' style={{ width: '100%' }}>
        <FormLabel id={question.id}>
          <FtrBoldText
            fontSize='16'
            style={{
              color: colors.neutral070,
              marginBottom: '0.5rem',
            }}
          >
            {question.question}
          </FtrBoldText>
        </FormLabel>
        <FormGroup
          aria-label='position'
          onChange={(event) => {
            const value = event.target.value;
            const answer = question.answer || [];
            handler({
              id: question.id,
              answer: answer.includes(value)
                ? answer.filter((a) => a !== value)
                : [...answer, value],
            });
          }}
        >
          {Object.entries(question.options).map(([key, value]) => {
            return (
              <FormControlLabel
                key={key}
                value={value}
                control={
                  <Checkbox
                    color='primary'
                    checked={question.answer?.includes(value)}
                  />
                }
                label={value}
              />
            );
          })}
        </FormGroup>
        {question.useOther && (
          <FlexRow>
            <FormControlLabel
              value={'Other:'}
              control={<Checkbox color='primary' checked={isOtherSelected} />}
              label={'Other:'}
              onChange={() => {
                const answer = question.answer || [];
                handler({
                  id: question.id,
                  answer: isOtherSelected
                    ? answer.filter((a) => !a.includes('Other:'))
                    : [...answer, 'Other:'],
                });
              }}
            />
            {isOtherSelected && (
              <TextField
                id='standard-basic'
                fullWidth
                onChange={(event) => {
                  const value = event.target.value;
                  const answer = question.answer || [];
                  const newAnswer = answer.map((a) => {
                    if (a.includes('Other:')) {
                      return `Other: ${value}`;
                    }
                    return a;
                  });
                  handler({
                    id: question.id,
                    answer: newAnswer,
                  });
                }}
              />
            )}
          </FlexRow>
        )}
      </FormControl>
    </div>
  );
};

export const renderQuestion = ({ question, handler }) => {
  switch (question.type) {
    case QUESTION_TYPES.SHORT_ANSWER:
      return renderShortAnswerQuestion(question, handler);
    case QUESTION_TYPES.RADIO:
      return renderRadioQuestion(question, handler);
    case QUESTION_TYPES.RATE:
      return <RateQuestion question={question} handler={handler} />;
    case QUESTION_TYPES.LINEAR_SCALE:
      return <ScaleLinearQuestion question={question} handler={handler} />;
    case QUESTION_TYPES.MULTI_SELECTION:
      return <MultiSelectionQuestion question={question} handler={handler} />;
    default:
      notifyError(`Cannot render for question ${question.question}`);
      return null;
  }
};

export const renderQuestionV2 = ({ question, handler }) => {
  switch (question.type) {
    case QUESTION_TYPES.SHORT_ANSWER:
      return renderShortAnswerQuestionV2(question, handler);
    case QUESTION_TYPES.RADIO:
      return renderRadioQuestionV2(question, handler);
    case QUESTION_TYPES.RATE:
      return <RateQuestion question={question} handler={handler} />;
    case QUESTION_TYPES.LINEAR_SCALE:
      return <ScaleLinearQuestion question={question} handler={handler} />;
    case QUESTION_TYPES.MULTI_SELECTION:
      return <MultiSelectionQuestion question={question} handler={handler} />;
    default:
      notifyError(`Cannot render for question ${question.question}`);
      return null;
  }
};
