import {
  fetchWithErrorHandling,
  getAuthorizedHeader,
  witchCacheResponse,
} from '../utils/apiUtils';

import { BACKEND_SERVICE_URL } from '../constants';

// -------------------------------------------------------------------------------------------------

export const clearCachePPE = async () => {
  const requestUrl = `${BACKEND_SERVICE_URL}/ppe/clear-cache`;
  return fetchWithErrorHandling(requestUrl, {
    headers: getAuthorizedHeader(),
  });
};

export const getSimilarParts = async (body) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/ppe/similar-parts`;
  return fetch(requestUrl, {
    method: 'POST',
    headers: getAuthorizedHeader(),
    body: JSON.stringify(body),
  }).then((response) => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return response.json();
  });
};

export const getAllPPEFeedback = async () => {
  const requestUrl = `${process.env.REACT_APP_BACKEND_SERVICE}/ppe/feedback/all`;
  return fetch(requestUrl, {
    headers: getAuthorizedHeader(),
  }).then((response) => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return response.json();
  });
};

export const deletePPEFeedbackByID = async (id) => {
  const requestUrl = `${process.env.REACT_APP_BACKEND_SERVICE}/ppe/feedback/delete/${id}`;
  return fetch(requestUrl, {
    headers: getAuthorizedHeader(),
  }).then((response) => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return response.json();
  });
};

/**
 * Need a mechanism to abort previous call if item technology params has been changed
 *
 * @param {*} body
 * @returns
 */
const CONTROLLER_MAPPING = {};
export const getPpePriceForItem = async (body) => {
  const { id } = body;
  const url = `${BACKEND_SERVICE_URL}/ppe/v2/verify`;
  let controller = CONTROLLER_MAPPING[id];
  if (controller) {
    controller.abort();
  }
  controller = new AbortController();
  CONTROLLER_MAPPING[id] = controller;
  const headers = getAuthorizedHeader();
  delete body.id;
  const requestOptions = {
    method: 'POST',
    headers,
    body: JSON.stringify(body),
    signal: controller.signal,
  };
  return fetch(url, requestOptions).then(async (response) => {
    if (!response.ok) {
      const errorResponse = JSON.parse(await response.text());
      return {
        additionalParameters: errorResponse.data,
        ppePriceLogId: errorResponse.ppePriceLogId,
      };
    }
    CONTROLLER_MAPPING[id] = null;
    return response.json();
  });
};

export const getPpePriceForItemWithCache = witchCacheResponse(
  getPpePriceForItem,
  60 * 60 // 1 hour in seconds
);

export const ppeFeedback = async (body) => {
  const url = `${BACKEND_SERVICE_URL}/ppe/feedback/submit`;
  const response = await fetch(url, {
    method: 'POST',
    headers: getAuthorizedHeader(),
    body: JSON.stringify(body),
  });
  if (!response.ok) {
    throw Error(response.statusText);
  }
  return response.json();
};

/**
 * Need a mechanism to abort previous call if item technology params has been changed
 *
 * @param {*} body
 * @returns
 */
const CONTROLLER_MANUAL_PRICE_MAPPING = {};
export const getDimensionPricingForItem = async (body) => {
  const { id } = body;
  const url = `${BACKEND_SERVICE_URL}/ppe/dimension-pricing`;
  let controller = CONTROLLER_MANUAL_PRICE_MAPPING[id];
  if (controller) {
    controller.abort();
  }
  controller = new AbortController();
  CONTROLLER_MANUAL_PRICE_MAPPING[id] = controller;
  const headers = getAuthorizedHeader();
  delete body.id;
  const requestOptions = {
    method: 'POST',
    headers,
    body: JSON.stringify(body),
    signal: controller.signal,
  };
  return fetchWithErrorHandling(url, requestOptions).then(async (data) => {
    CONTROLLER_MAPPING[id] = null;
    return data;
  });
};

export const getDimensionPricingForItemWithCache = witchCacheResponse(
  getDimensionPricingForItem,
  60 * 60 // 1 hour in seconds
);
