import { BACKEND_SERVICE_URL } from '../constants';
import { getAuthorizedHeader } from '../utils/apiUtils';
import queryString from 'query-string';

export const getUnproceededPPELogs = async (params) => {
  let requestUrl = `${BACKEND_SERVICE_URL}/logs/instant-quotes`;
  if (params && typeof params === 'object') {
    requestUrl += `?${queryString.stringify(params)}`;
  }
  return fetch(requestUrl, {
    method: 'GET',
    headers: getAuthorizedHeader(),
  }).then((response) => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return response.json();
  });
};

export const adminSearchPPELogs = async (params) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/logs/instant-quotes/search?${queryString.stringify(
    params
  )}`;
  return fetch(requestUrl, {
    method: 'GET',
    headers: getAuthorizedHeader(),
  }).then(async (response) => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    const json = await response.json();
    return json.data;
  });
};

export const adminExportInstantLogAsCsv = async (params) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/logs/instant-quotes/search/csv-export?${queryString.stringify(
    params
  )}`;
  return fetch(requestUrl, {
    method: 'GET',
    headers: getAuthorizedHeader(),
  }).then(async (response) => {
    return response;
  });
};

export const updateUserIDForInstantLogs = async (body) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/logs/instant-quotes`;
  return fetch(requestUrl, {
    method: 'PUT',
    headers: getAuthorizedHeader(),
    body: JSON.stringify(body),
  }).then(async (response) => {
    return response;
  });
};
