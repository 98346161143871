import React, { useState } from 'react';

import { makeStyles } from '@material-ui/core/styles/index';

import { Tab, Tabs } from '@material-ui/core';

import FactoremPromotionCodesDataGrid from './FactoremPromotionCodesDataGrid';
import StripeDiscountDataGrid from './StripeDiscountDataGrid';

import { colors } from '../../palette';

// -------------------------------------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  body: {
    padding: theme.spacing(5),
    paddingTop: theme.spacing(5),
  },
  tabRoot: {
    '&:hover': {
      color: 'white',
      backgroundColor: colors.blue050,
    },
  },
  selectedTab: {
    '&:hover': {
      backgroundColor: colors.secondaryBlue,
      color: colors.blue050,
    },
    backgroundColor: colors.secondaryBlue,
    color: colors.blue050,
  },
}));

const TAB_KEYS = {
  FACTOREM_PROMOTION_CODES: 'Factorem Promo Codes',
  STRIPE_DISCOUNT: 'Stripe Discount',
};

const TABS = [TAB_KEYS.FACTOREM_PROMOTION_CODES, TAB_KEYS.STRIPE_DISCOUNT];

function PromotionCodes() {
  const classes = useStyles();

  const [tabs, _setTabs] = useState(TABS);
  const [selectedTab, setSelectedTab] = useState(0);

  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const renderTab = () => {
    const tabName = tabs[selectedTab];
    switch (tabName) {
      case TAB_KEYS.FACTOREM_PROMOTION_CODES:
        return <FactoremPromotionCodesDataGrid />;
      case TAB_KEYS.STRIPE_DISCOUNT:
        return <StripeDiscountDataGrid />;
      default:
        return null;
    }
  };

  return (
    <div style={{ margin: '1.5rem 0' }}>
      <Tabs value={selectedTab} onChange={handleChange}>
        {tabs.map((tab) => {
          return (
            <Tab
              classes={{
                root: classes.tabRoot,
                selected: classes.selectedTab,
              }}
              key={tab}
              label={tab}
            />
          );
        })}
      </Tabs>
      <div style={{ margin: '2rem 0' }}>{renderTab()}</div>
    </div>
  );
}

export default PromotionCodes;
