import React, { useReducer, useState } from 'react';
import { useQueryClient } from 'react-query';

import CreateUpdateCompanyPopup from '../components/popups/CreateUpdateCompanyPopup';
import CheckDifferentValueCompanyAndUserPopup from '../components/popups/CheckDifferentValueCompanyAndUserPopup';

import { getDifferentValuedCompanyAndUsers } from '../apis/companiesApi';

import { isEmptyValue } from '../utils/commonUtils';

// -------------------------------------------------------------------------------------------------

function withCreateUpdateCompanyPopupHOC(WrappedComponent) {
  function WrappedComponentWithHOC(props) {
    const queryClient = useQueryClient();

    const defaultLocalState = {
      open: false,
      onSuccess: () => {},
    };

    const [localState, updateLocalState] = useReducer((prev, next) => {
      if (isEmptyValue(next)) {
        return defaultLocalState;
      }
      return { ...prev, ...next };
    }, defaultLocalState);

    const [
      showDifferentValuesCompanyAndUsersPopup,
      setShowDifferentValuesCompanyAndUsersPopup,
    ] = useState(false);

    const [
      differentValuesCompanyAndUsers,
      setDifferentValuesCompanyAndUsers,
    ] = useState([]);

    const updateCompanySuccess = async () => {
      queryClient.invalidateQueries('getAllCompanies');
      if (localState.type === 'update') {
        const differentValueCompanyAndUserApi = await getDifferentValuedCompanyAndUsers(
          { companyID: localState.company.companyID }
        );
        if (differentValueCompanyAndUserApi.length) {
          setDifferentValuesCompanyAndUsers(differentValueCompanyAndUserApi);
          setShowDifferentValuesCompanyAndUsersPopup(true);
        }
      }
    };

    return (
      <>
        <WrappedComponent
          {...props}
          updateCreateUpdateCompanyPopupHOCState={updateLocalState}
        />
        {showDifferentValuesCompanyAndUsersPopup && (
          <CheckDifferentValueCompanyAndUserPopup
            open={showDifferentValuesCompanyAndUsersPopup}
            onClose={() => setShowDifferentValuesCompanyAndUsersPopup(false)}
            data={differentValuesCompanyAndUsers}
          />
        )}
        {localState.open && (
          <CreateUpdateCompanyPopup
            open={localState.open}
            onClose={() => updateLocalState({ open: false })}
            onSuccess={updateCompanySuccess}
            type={localState.type}
            data={localState.company}
          />
        )}
      </>
    );
  }

  // Set the displayName for the HOC
  const wrappedComponentName =
    WrappedComponent.displayName || WrappedComponent.name || 'Component';
  WrappedComponentWithHOC.displayName = `withSignUpPopup(${wrappedComponentName})`;

  return WrappedComponentWithHOC;
}

export default withCreateUpdateCompanyPopupHOC;
