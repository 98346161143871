import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Box } from '@material-ui/core';

import CustomerAddressDisplay from './CustomerAddressDisplay';
import DeliveryTimeSelection from './DeliveryTimeSelection';
import DisplayShippingOptions from '../../forms/part-upload-step-two/DisplayShippingOptions';
import DownloadCombinedQuotePDFButton from '../../ftr-components/buttons/DownloadCombinedQuotePDFButton';
import FtrCheckbox from '../../ftr-components/FtrCheckbox';
import FtrSvgImage from '../../images/FtrSvgImage';
import MultiCheckoutSummaryPanelV2 from './MultiCheckoutSummaryPanelV2';
import PaymentOptionsPanelV2 from './PaymentOptionsPanelV2';
import { FlexColumn, FlexRow } from '../../layouts/FlexLayouts';
import { FtrBoldText, FtrIconButton, FtrTooltip } from '../../ftr-components';

import { ConfirmOrderPopupContext } from '../ConfirmOrderPopup';

import EmailIcon from '../../../assets/icons/email_icon.svg';

import { getCombinedQuotationsFormUrl } from '../../../apis/multiCheckoutApi';

import {
  updateMultiCheckoutFormState,
  updateShippingMode,
} from '../../../actions/multiCheckoutForm';

import useDeviceCheck from '../../../hooks/useDeviceCheck';

import { getMultiCheckoutFormSelector } from '../../../selectors/multiCheckoutFormSelector';
import { getUserCurrencySelector } from '../../../selectors/userSelector';
import { getExchangeRateSelector } from '../../../selectors/exchangeRatesSelector';

import { downloadS3File } from '../../../utils/fileUtils';
import { getFullDeliveryAddress } from '../../../utils/addressUtils';
import { isEmptyValue } from '../../../utils/commonUtils';
import { formatDeliveryDate } from '../../../utils/dateTimeUtils';
import { getShippingTooltipText } from '../../../utils/deliveryUtils';
import { getShippingCostOptions } from '../../../utils/shippingUtils';

import { notifyError } from '../../../services/notificationService';

import { DELIVERY_OPTIONS_DISPLAY_MAPPING } from '../../../constants/itemConstants';

import { colors } from '../../../palette';

// ------------------------------------------------------------------------

function ConfirmOrderOrderDetailsStepPage() {
  const { updatePopupsHocState = () => {} } = useContext(
    ConfirmOrderPopupContext
  );

  const dispatch = useDispatch();

  const [{ isMobile }] = useDeviceCheck();

  const currency = useSelector(getUserCurrencySelector);
  const exchangeRate = useSelector(getExchangeRateSelector);

  const multiCheckoutForm = useSelector(getMultiCheckoutFormSelector);

  const pricingStatus = multiCheckoutForm.status;
  const customerAddresses = multiCheckoutForm.customerDeliveryInfoList || [];
  const checkoutPriceSummary = multiCheckoutForm?.checkoutPriceSummary || {};
  const deliveryTime = multiCheckoutForm.deliveryTime;

  // combine shipping cost options and delivery date information
  const shippingCostOptions = useMemo(() => {
    return getShippingCostOptions({
      deliveryDateModes: checkoutPriceSummary?.deliveryDateModes,
      deliveryModePrices: checkoutPriceSummary.deliveryModePrices,
    });
  }, [
    checkoutPriceSummary?.deliveryDateModes,
    checkoutPriceSummary.deliveryModePrices,
  ]);

  const shippingMode = checkoutPriceSummary?.shippingMode;
  const shippingID = multiCheckoutForm.deliveryInfoID;
  const billingAddressID = multiCheckoutForm.billingAddressID;
  const country = multiCheckoutForm.country;

  const initialSameAsShipping =
    !!shippingID && !!billingAddressID && shippingID === billingAddressID;

  const shippingAddress = useMemo(() => {
    if (isEmptyValue(shippingID) || isEmptyValue(customerAddresses)) {
      return null;
    }
    return customerAddresses.find((add) => add.deliveryInfoID === shippingID);
  }, [customerAddresses, shippingID]);

  const billingAddress = useMemo(() => {
    if (isEmptyValue(billingAddressID) || isEmptyValue(customerAddresses)) {
      return null;
    }
    return customerAddresses.find(
      (add) => add.deliveryInfoID === billingAddressID
    );
  }, [customerAddresses, billingAddressID]);

  const shippingTooltip = useMemo(() =>
    getShippingTooltipText(
      {
        country,
        currency,
        exchangeRate,
      },
      [country, currency, exchangeRate]
    )
  );

  const [sameAsShipping, setSameAsShipping] = useState(initialSameAsShipping);
  const [prepareDownloadUrl, setPrepareDownloadUrl] = useState(false);

  useEffect(() => {
    setSameAsShipping(initialSameAsShipping);
  }, [initialSameAsShipping]);

  useEffect(() => {
    const addresses = {
      shipping: getFullDeliveryAddress(shippingAddress),
      billing: getFullDeliveryAddress(billingAddress),
    };
    dispatch(updateMultiCheckoutFormState({ addresses }));
  }, [shippingAddress, billingAddress]);

  const downloadCombinedQuotationsForm = () => {
    setPrepareDownloadUrl(true);
    const body = {
      quotations: multiCheckoutForm.selectedQuotes.map(
        (quote) => quote.quotationID
      ),
      shippingMode,
      exchangeRate: multiCheckoutForm.exchangeRate,
      addresses: multiCheckoutForm.addresses,
      paymentType: multiCheckoutForm.paymentType,
      deliveryInfoID: multiCheckoutForm.deliveryInfoID,
    };
    getCombinedQuotationsFormUrl(body)
      .then(downloadS3File)
      .catch(() => {
        notifyError(`Get combined quotations form failed!`);
      })
      .finally(() => setPrepareDownloadUrl(false));
  };

  const renderSameAsShippingCheckbox = () => {
    return (
      <FtrCheckbox
        key='same-as-shipping'
        label='Same as shipping address'
        checked={sameAsShipping}
        onChange={() => {
          if (!sameAsShipping) {
            dispatch(
              updateMultiCheckoutFormState({
                billingAddressID: shippingID,
              })
            );
          }
          setSameAsShipping((previous) => !previous);
        }}
      />
    );
  };

  const renderDownloadCombinedQuotePdfButton = () => {
    return (
      <DownloadCombinedQuotePDFButton
        onClick={downloadCombinedQuotationsForm}
        loading={prepareDownloadUrl}
        disabled={prepareDownloadUrl}
      />
    );
  };

  const renderShareQuoteViaEmailButton = () => {
    return (
      <FtrTooltip description='Share Quote via Email'>
        <div>
          <FtrIconButton
            onClick={() => updatePopupsHocState({ showShareQuotePopup: true })}
          >
            <FtrSvgImage
              src={EmailIcon}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            />
          </FtrIconButton>
        </div>
      </FtrTooltip>
    );
  };

  const renderShippingArea = () => {
    return (
      <FlexColumn style={{ flex: '1 1' }}>
        <div style={{ maxWidth: '80%' }}>
          <CustomerAddressDisplay
            addressObj={shippingAddress}
            addressList={customerAddresses}
            type='shipping'
          />
        </div>
        <FlexColumn style={{ maxWidth: '90%', marginTop: '1rem' }}>
          <FtrBoldText style={{ color: colors.neutral070, fontWeight: 700 }}>
            Shipping Option
          </FtrBoldText>
          <DisplayShippingOptions
            title={DELIVERY_OPTIONS_DISPLAY_MAPPING[shippingMode]}
            subInfo={
              checkoutPriceSummary.deliveryDate
                ? `Receive by ${formatDeliveryDate(
                    checkoutPriceSummary.deliveryDate
                  )}`
                : 'No estimation available'
            }
            price={checkoutPriceSummary.deliveryFeeStr}
            titleTooltip={shippingTooltip}
            shippingMode={shippingMode}
            shippingCostOptions={shippingCostOptions}
            selectShippingOption={(newValue) => {
              dispatch(updateShippingMode(newValue));
            }}
            loading={pricingStatus === 'loading'}
          />
        </FlexColumn>
        <FlexColumn style={{ maxWidth: '90%', marginTop: '1rem' }}>
          <FtrBoldText style={{ color: colors.neutral070, fontWeight: 700 }}>
            Delivery Time
          </FtrBoldText>
          <DeliveryTimeSelection
            value={deliveryTime}
            onChange={(value) => {
              dispatch(updateMultiCheckoutFormState({ deliveryTime: value }));
            }}
          />
        </FlexColumn>
      </FlexColumn>
    );
  };

  const renderBillingArea = () => {
    return (
      <FlexColumn style={{ flex: '1 1' }}>
        <div style={{ maxWidth: '80%' }}>
          <CustomerAddressDisplay
            type='billing'
            addressObj={billingAddress}
            addressList={customerAddresses}
          />
        </div>
        {renderSameAsShippingCheckbox()}
        <div style={{ marginTop: '1rem' }}>
          <PaymentOptionsPanelV2 />
        </div>
      </FlexColumn>
    );
  };

  return (
    <FlexColumn>
      <FlexRow
        style={{ marginBottom: '1rem', justifyContent: 'space-between' }}
      >
        <FtrBoldText fontSize='18'>Order Details</FtrBoldText>
        <FlexRow>
          {renderDownloadCombinedQuotePdfButton()}
          {renderShareQuoteViaEmailButton()}
        </FlexRow>
      </FlexRow>
      {!isMobile && (
        <FlexRow style={{ alignItems: 'start' }}>
          {renderShippingArea()}
          {renderBillingArea()}
          <FlexColumn
            style={{ flex: '1 1 auto', maxWidth: 360, minWidth: 360 }}
          >
            <MultiCheckoutSummaryPanelV2 />
          </FlexColumn>
        </FlexRow>
      )}
      {isMobile && (
        <FlexColumn style={{ alignItems: 'start' }}>
          {renderShippingArea()}
          <Box style={{ height: '1rem' }} />
          {renderBillingArea()}
          <Box style={{ height: '1rem' }} />
          <MultiCheckoutSummaryPanelV2 />
        </FlexColumn>
      )}
    </FlexColumn>
  );
}

export default ConfirmOrderOrderDetailsStepPage;
