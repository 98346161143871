// Import settings
import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles/index';

// Import color palette
import { colors } from '../../palette';

// Import material UI components
import { Box, InputAdornment, Popper, TextField } from '@material-ui/core';
import Autocomplete, {
  createFilterOptions,
} from '@material-ui/lab/Autocomplete';

import { renderFlag } from '../images/CountryFlag';

import { COUNTRY_LIST, COUNTRY_NAMES } from '../../constants/countryConstants';

// -------------------------------------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  adminTextfield: {
    marginTop: '8px',
    borderRadius: 5,
  },
  country: {
    width: '100%',
  },
  countryCode: {
    width: '30%',
    [theme.breakpoints.down('sm')]: {
      width: '40%',
    },
  },
  popper: {
    width: 'fit-content',
  },
  textfield: {
    borderRadius: 5,
    '& .MuiAutocomplete-inputRoot': {
      padding: '1px',
      overflow: 'hidden',
      '& fieldset': {
        border: `1px solid ${colors.fontLightGrey}`,
      },
      '&:hover fieldset': {
        border: `1px solid ${colors.fontLightGrey}`,
      },
      '&.Mui-focused fieldset': {
        border: `2px solid ${colors.blue050}`,
      },
    },
    '& .MuiOutlinedInput-root': {
      paddingRight: '2px!important',
      borderRadius: 10,
    },
  },
}));

function CountryDropDown(props) {
  const classes = useStyles();
  const {
    id,
    isAdmin,
    isCountryCodeDropDown,
    onSelect,
    valueSelected,
    showDefaultFlag,
    showFlag = true,
    overrideStyle,
  } = props;

  const countries = Object.values(COUNTRY_NAMES);
  const [countryValue, setCountryValue] = useState(countries[0]);

  const MyPopper = function(props) {
    return (
      <Popper
        {...props}
        style={{ width: 'fit-content' }}
        placement='bottom-start'
      />
    );
  };

  const filterOptions = createFilterOptions({
    stringify: (option) => {
      const { code, phone, fullName } = COUNTRY_LIST[option] || {};
      return `${code}${phone}${option}${fullName}`;
    },
  });

  function getCountryCodeDisplay(country) {
    const { phone } = COUNTRY_LIST[country] || {};
    return `${phone}`;
  }

  const getLabel = () => {
    if (isAdmin && isCountryCodeDropDown) {
      return 'Country Code *';
    } else if (isAdmin && !isCountryCodeDropDown) {
      return 'Country *';
    } else {
      return '';
    }
  };

  const handleChange = (event, value) => {
    onSelect(value);
    setCountryValue(value);
  };

  return (
    <Autocomplete
      id={id}
      autoComplete
      disableClearable
      PopperComponent={MyPopper}
      filterOptions={filterOptions}
      options={countries}
      onChange={handleChange}
      defaultValue={countries[0]}
      value={valueSelected}
      className={isCountryCodeDropDown ? classes.countryCode : classes.country}
      getOptionLabel={(option) =>
        isCountryCodeDropDown ? getCountryCodeDisplay(option) : option
      }
      renderOption={(option) => {
        return (
          <Box>
            {renderFlag(option)}
            &nbsp;{COUNTRY_LIST[option].fullName || option}{' '}
            {isCountryCodeDropDown && COUNTRY_LIST[option].phone}
          </Box>
        );
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          className={isAdmin ? classes.adminTextfield : classes.textfield}
          InputProps={{
            ...params.InputProps,
            autoComplete: 'new-password',
            startAdornment: showFlag && (
              <InputAdornment position='start'>
                &nbsp;
                {isCountryCodeDropDown && !showDefaultFlag
                  ? renderFlag(countryValue)
                  : renderFlag(valueSelected)}
              </InputAdornment>
            ),
          }}
          label={getLabel()}
          variant='outlined'
        />
      )}
      style={overrideStyle}
    />
  );
}

export default CountryDropDown;
