import React from 'react';

import Thumb from '../fields/Thumb';
import { FlexRow } from '../layouts/FlexLayouts';

import { FEEDBACK_STATUS } from '../../constants/feedbackConstants';

import { colors } from '../../palette';

// -------------------------------------------------------------------------------------------------

const FtrAdminFeedbackThumbsComponent = (props) => {
  const {
    thumbUpTooltip = 'Good',
    thumbDownTooltip = 'Not Good',
    value,
    onThumbUpClick = () => {},
    onThumbDownClick = () => {},
  } = props;

  return (
    <FlexRow
      style={{
        padding: '5px',
        borderRadius: '5px',
        gap: 0,
        backgroundColor: colors.neutral030,
        transform: 'scale(0.8)',
      }}
    >
      <Thumb
        variant='up'
        isActive={value === FEEDBACK_STATUS.GOOD}
        tooltipText={thumbUpTooltip}
        onClick={onThumbUpClick}
      />
      <Thumb
        variant='down'
        isActive={value === FEEDBACK_STATUS.BAD}
        tooltipText={thumbDownTooltip}
        onClick={onThumbDownClick}
      />
    </FlexRow>
  );
};

export default FtrAdminFeedbackThumbsComponent;
