import React, { useEffect, useMemo, useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles/index';
import { isEmpty } from 'lodash';

import {
  ButtonBase,
  Card,
  CardContent,
  ClickAwayListener,
  Grid,
  Grow,
  Link,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';

import { colors } from '../../palette';

import { FtrTypography } from '../../components/ftr-components';

import LinkIcon from '../../assets/icons/link.svg';

// Import constants
import {
  THREE_D_P_FDM_TECH,
  threeDPrintingInfillDefault,
  threeDPrintingLayerThicknessDefault,
  TOLERANCE_OPTIONS,
  STANDARD_TOLERANCE_OPTIONS,
  THREE_D_TOLERANCE_OPTIONS,
  TECHNOLOGY_OPTION_TYPE,
} from '../../constants/NewPartConstants';

import { UNIT_TYPES } from '../../constants/unitConstants';

import { useItemInputConfig } from '../../hooks/useItemInputConfig';
import { is3DPTechnology } from '../../utils/itemUtils';

const useStyles = makeStyles((theme) => ({
  '@global': {
    '*::-webkit-scrollbar': {
      width: '0.9em',
    },
    '*::-webkit-scrollbar-track': {
      display: 'block',
    },
    '*::-webkit-scrollbar-thumb': {
      width: '6px',
      borderRadius: '10px',
      backgroundColor: colors.neutral050,
      border: '4px white solid',
    },
  },
  card: {
    boxShadow: '0px 2px 10px 0px #0000000D',
    border: '0px',
    width: '100%',
    height: '159px',
    borderRadius: '10px',
    [theme.breakpoints.down('sm')]: {
      height: 'auto',
    },
  },
  longCard: {
    height: '130px',
  },
  selectedCard: {
    backgroundColor: colors.blue010,
    border: `3px solid ${colors.blue050}`,
  },
  techButton: {
    padding: '0.3rem',
    width: '100%',
    height: '100%',
    textAlign: 'left',
    justifyContent: 'left',
  },
  text: {
    paddingBottom: '0.3rem',
  },
  link: {
    display: 'flex',
    fontWeight: 600,
    width: 'fit-content',
    alignItems: 'center',
    textDecoration: 'none',
    color: colors.neutral050,
  },
  menuItemRoot: {
    padding: '12px',
    marginBottom: '3px',
    borderRadius: '0.75rem',
    whiteSpace: 'break-spaces',
    '&:hover': {
      backgroundColor: '#EDEDED',
    },
    '&.Mui-selected': {
      backgroundColor: '#EDEDED',
    },
  },
  menuOther: {
    '& [role="tooltip"]': {
      zIndex: 1,
    },
  },
}));

function SelectTechnologies(props) {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const {
    id,
    width = 'short',
    selectedTechnology,
    onChange,
    isPopup = false,
  } = props;

  const [technology, setTechnology] = useState(
    TECHNOLOGY_OPTION_TYPE.CNC_MACHINING
  );
  const [material, setMaterial] = useState(null);
  const [threeDTechnology, setThreeDTechnology] = useState(null);
  const [surfaceFinish, setSurfaceFinish] = useState(null);
  const [materialColor, setMaterialColor] = useState(null);
  const [color, setColor] = useState('');
  const [threeDInfill, setThreeDInfill] = useState('');
  const [threeDLayerThickness, setThreeDLayerThickness] = useState('');
  const [partApplication, setPartApplication] = useState('');
  const [updateParamTimer, setUpdateParamTimer] = useState(null);

  const surfaceFinishIsPainting = surfaceFinish === 'Painting';
  const surfaceFinishIsCoating = surfaceFinish === 'Powder Coating';

  const [
    { technologyOptions },
    {
      loadTechnologyOptions,
      loadMaterialCategoryOptions,
      load3DTechnologyOptions,
      loadThreeDMaterialOptions,
      loadSurfaceFinishOptions,
      loadMaterialColorOptions,
      loadSurfaceFinishColorOptions,
      setSurfaceFinishColorOptions,
    },
  ] = useItemInputConfig({
    setTechnology,
    setMaterial,
    setThreeDTechnology,
    setSurfaceFinish,
    setMaterialColor,
    setColor,
  });

  const technologyList = useMemo(() => {
    if (!technologyOptions) {
      return { mainTech: [], otherTech: [] };
    }
    const mainTech = technologyOptions.filter(
      (tech) => !tech.isOtherTechnology
    );
    const otherTech = technologyOptions.filter(
      (tech) => tech.isOtherTechnology
    );
    return { mainTech, otherTech };
  }, [technologyOptions]);

  const othersTechnologySelected = technologyList?.otherTech?.find(
    (tech) => tech.technology == technology
  );

  useEffect(() => {
    loadTechnologyOptions(false, { withDetail: true });
  }, []);

  useEffect(() => {
    if (isEmpty(selectedTechnology)) {
      return;
    }
    setTechnology(selectedTechnology);

    // clean the state when dismount
    return () => {
      setTechnology(null);
      setMaterial(null);
      setThreeDTechnology(null);
      setSurfaceFinish(null);
      setMaterialColor(null);
      setColor(null);
      setThreeDInfill('');
      setThreeDLayerThickness('');
      setPartApplication('');
    };
  }, []);

  useEffect(() => {
    if (isEmpty(technology)) {
      setThreeDTechnology(null);
      return;
    }
    const params = {
      technology,
      threeDTechnology,
    };
    if (!is3DPTechnology(technology)) {
      loadMaterialCategoryOptions(params);
      return;
    }
    load3DTechnologyOptions();
    loadThreeDMaterialOptions(params);
    if (threeDTechnology === THREE_D_P_FDM_TECH) {
      setThreeDInfill(threeDPrintingInfillDefault);
      setThreeDLayerThickness(threeDPrintingLayerThicknessDefault);
    } else {
      setThreeDInfill('');
      setThreeDLayerThickness('');
    }
  }, [technology, threeDTechnology]);

  useEffect(() => {
    if (isEmpty(material) || isEmpty(technology)) {
      setSurfaceFinish(null);
      setColor(null);
      return;
    }
    const params = {
      technology,
      threeDTechnology,
      material,
    };
    loadSurfaceFinishOptions(params);
    loadMaterialColorOptions(params);
  }, [material, technology, threeDTechnology]);

  useEffect(() => {
    if (
      surfaceFinishIsPainting ||
      surfaceFinishIsCoating ||
      is3DPTechnology(technology)
    ) {
      setColor(color || 'Does not matter');
    } else {
      setColor('');
    }
  }, [technology, surfaceFinish]);

  useEffect(() => {
    if (isEmpty(surfaceFinish)) {
      setSurfaceFinishColorOptions([]);
      return;
    }
    const params = {
      technology,
      material,
      surfaceFinish,
    };
    loadSurfaceFinishColorOptions(params);
  }, [surfaceFinish, technology, material]);

  useEffect(() => {
    const params = {
      technology,
      material,
      surfaceFinish,
      materialColor,
      color,
      partApplication,
      tolerance:
        TOLERANCE_OPTIONS[UNIT_TYPES.METRIC][technology] ||
        STANDARD_TOLERANCE_OPTIONS[UNIT_TYPES.METRIC][3],
      ...(is3DPTechnology(technology) && {
        threeDTechnology,
        threeDInfill,
        threeDLayerThickness,
        tolerance:
          THREE_D_TOLERANCE_OPTIONS[UNIT_TYPES.METRIC][threeDTechnology] ||
          STANDARD_TOLERANCE_OPTIONS[UNIT_TYPES.METRIC][1],
      }),
    };
    if (updateParamTimer) {
      clearTimeout(updateParamTimer);
    }
    const timer = setTimeout(() => {
      onChange(params);
    }, 200);
    setUpdateParamTimer(timer);
  }, [technology, threeDTechnology, material, materialColor, surfaceFinish]);

  const renderOthers = () => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [isMenuClicked, setIsMenuClicked] = useState(false);

    const handleClick = (event) => {
      event.preventDefault();
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
      setIsMenuClicked(false);
    };

    return (
      <Grid
        item
        key={othersTechnologySelected ? technology : 'Others'}
        xs={!isMobile && 3}
        md={6}
        lg={3}
        className={classes.menuOther}
      >
        <Card
          variant='outlined'
          className={clsx(classes.card, {
            [classes.selectedCard]: othersTechnologySelected,
            [classes.longCard]: width === 'long',
          })}
          onClick={(event) => {
            handleClick(event);
            setIsMenuClicked(event);
          }}
          onMouseEnter={handleClick}
          data-cy={'others-technology'}
        >
          <ButtonBase disableRipple className={classes.techButton}>
            <CardContent style={{ padding: isMobile && '8px' }}>
              <FtrTypography
                type='heading'
                fontSize={width === 'long' ? '18' : '16'}
                className={classes.text}
              >
                {othersTechnologySelected ? technology : 'Others'}
              </FtrTypography>
              <FtrTypography type='body' fontSize='13' className={classes.text}>
                {othersTechnologySelected
                  ? othersTechnologySelected?.technologyDesc
                  : 'Customised services for specialised or unconventional manufacturing needs.'}
              </FtrTypography>
              <Link
                id='others'
                href={
                  othersTechnologySelected?.technologyLink ||
                  'https://www.factorem.co/how-it-works'
                }
                onClick={(e) => {
                  e.stopPropagation();
                }}
                target='_blank'
                className={classes.link}
              >
                More
                <img
                  src={LinkIcon}
                  alt='icon'
                  style={{ height: '12px', paddingLeft: '6px' }}
                />
              </Link>
            </CardContent>
          </ButtonBase>
        </Card>
        <Popper
          open={isPopup || isMobile ? isMenuClicked : Boolean(anchorEl)}
          anchorEl={anchorEl}
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === 'bottom' ? 'center top' : 'center bottom',
              }}
            >
              <Paper
                style={{
                  padding: '0 8px',
                  margin: isMobile ? '10px 15px 10px 25px' : '10px',
                  maxHeight: isPopup ? '305px' : '400px',
                  overflow: 'scroll',
                  borderRadius: '10px',
                  width: isMobile ? 'auto' : '295px',
                }}
              >
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList>
                    {technologyList?.otherTech.map((tech) => {
                      return (
                        <MenuItem
                          key={tech.technology}
                          onClick={() => {
                            setTechnology(tech.technology);
                            handleClose();
                          }}
                          classes={{
                            root: clsx(classes.menuItemRoot, {
                              'Mui-selected': technology === tech.technology,
                            }),
                          }}
                          value={tech.technology}
                        >
                          <div>
                            <FtrTypography
                              type='heading'
                              fontSize='16'
                              className={classes.text}
                            >
                              {tech.technology}
                            </FtrTypography>
                            <FtrTypography type='body' fontSize='13'>
                              {tech.technologyDesc}
                            </FtrTypography>
                          </div>
                        </MenuItem>
                      );
                    })}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </Grid>
    );
  };

  return (
    <Grid
      container
      key={id}
      direction={isMobile ? 'column' : 'row'}
      spacing={2}
    >
      {technologyList.mainTech.map((tech) => {
        return (
          <Grid
            key={tech.technology}
            item
            xs={!isMobile && 3}
            md={6}
            lg={3}
            data-cy={tech.technology}
          >
            <Card
              variant='outlined'
              className={clsx(classes.card, {
                [classes.selectedCard]: technology == tech.technology,
                [classes.longCard]: width === 'long',
              })}
              onClick={() => setTechnology(tech.technology)}
            >
              <ButtonBase disableRipple className={classes.techButton}>
                <CardContent style={{ padding: isMobile && '8px' }}>
                  <FtrTypography
                    type='heading'
                    fontSize={width === 'long' ? '18' : '16'}
                    className={classes.text}
                  >
                    {tech.technology}
                  </FtrTypography>
                  <FtrTypography
                    type='body'
                    fontSize='13'
                    className={classes.text}
                  >
                    {tech.technologyDesc}
                  </FtrTypography>
                  <Link
                    id={tech.technology}
                    href={tech.technologyLink}
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                    target='_blank'
                    className={classes.link}
                  >
                    More
                    <img
                      src={LinkIcon}
                      alt='icon'
                      style={{ height: '12px', paddingLeft: '6px' }}
                    />
                  </Link>
                </CardContent>
              </ButtonBase>
            </Card>
          </Grid>
        );
      })}
      {renderOthers()}
    </Grid>
  );
}

export default SelectTechnologies;
