import {
  UPDATE_PARTS_LIBRARY_ALL_DATA,
  UPDATE_PARTS_LIBRARY_SINGLE_DATA,
  UPDATE_PARTS_LIBRARY_ITEM_DATA,
  DELETE_PARTS_LIBRARY_ITEM_DATA,
  ADD_NEW_PARTS_LIBRARY_ITEM_DATA,
  VIEW_PARTS_LIBRARY_ITEM_DATA,
} from '../actions';
import { isEmptyValue } from '../utils/commonUtils';
import { isIDSame, isVersionSame } from '../utils/partsLibraryUtils';

const defaultState = {
  data: {},
  viewItem: null,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case UPDATE_PARTS_LIBRARY_ALL_DATA: {
      return {
        ...state,
        data: action.payload,
      };
    }
    case UPDATE_PARTS_LIBRARY_SINGLE_DATA: {
      if (!action.payload.projectLibraryID) {
        return state;
      }
      let newData = action.payload?.data;
      if (state?.data?.projectLibraryID === action.payload.projectLibraryID) {
        newData = { ...state?.data, ...action.payload?.data };
      }
      return {
        ...state,
        data: newData,
      };
    }
    case UPDATE_PARTS_LIBRARY_ITEM_DATA: {
      if (state?.data?.projectLibraryID !== action.payload.projectLibraryID) {
        return state;
      }
      const newItems = state?.data?.items?.map((item) => {
        const checkID = isIDSame(item, action.payload);
        const versionSame = isEmptyValue(action.payload.version)
          ? true
          : isVersionSame(item, action.payload);

        if (!isEmptyValue(action.payload.originalPayload)) {
          item.originalPayload = action.payload.originalPayload;
        }

        if (checkID && versionSame) {
          return { ...item, ...action.payload.data };
        }
        return item;
      });
      return {
        ...state,
        data: { ...state.data, items: newItems },
      };
    }
    case DELETE_PARTS_LIBRARY_ITEM_DATA: {
      if (state?.data?.projectLibraryID !== action.payload.projectLibraryID) {
        return state;
      }
      const newItems = state?.data?.items?.map((item) => {
        const _id = item.itemLibraryID || item.id;
        const isMatch =
          action.payload.itemLibraryIDs?.includes(_id) ||
          action.payload.ids?.includes(_id);
        if (isMatch) {
          return { ...item, isDeleted: true };
        }
        return item;
      });
      return {
        ...state,
        data: { ...state.data, items: newItems },
      };
    }
    case ADD_NEW_PARTS_LIBRARY_ITEM_DATA: {
      if (state?.data?.projectLibraryID !== action.payload.projectLibraryID) {
        return state;
      }
      if (!state?.data?.items || !action.payload.data) {
        return;
      }
      const newItems = [...state.data.items, action.payload.data];
      return {
        ...state,
        data: { ...state.data, items: newItems },
      };
    }
    case VIEW_PARTS_LIBRARY_ITEM_DATA: {
      return {
        ...state,
        viewItem: action.payload.item,
      };
    }
    default:
      return state;
  }
};
