import { useSelector } from 'react-redux';

import { trackActionApi } from '../apis/actionTrackingApi';

import { getUserIDSelector } from '../selectors/userSelector';

import * as fileUtils from '../utils/fileUtils';

import { ACTION_TRACKING_TYPE } from '../constants/actionTrackingConstants';

// -------------------------------------------------------------------------------------------------

const useDownloadS3FileWithTracking = () => {
  const userID = useSelector(getUserIDSelector);

  const downloadS3File = async (url, options = {}) => {
    trackActionApi({
      actionTrackingKey: ACTION_TRACKING_TYPE.DOWNLOAD_FILE,
      additionalInfo: {
        urls: [url],
      },
    }).catch((err) => {
      console.log('Error tracking action:', err);
    });

    return fileUtils.downloadS3File(url, { userID, ...options });
  };

  return {
    downloadS3File,
  };
};

export default useDownloadS3FileWithTracking;
