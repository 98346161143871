import React, { Fragment, useReducer } from 'react';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';

import { makeStyles } from '@material-ui/core/styles/index';

import { Container, CircularProgress, Button, Grid } from '@material-ui/core';

import ShippingForm, {
  ShipmentInfo,
} from '../../components/forms/shipping-form/ShippingForm';
import { FlexColumn } from '../../components/layouts/FlexLayouts';
import { FtrBoldText } from '../../components/ftr-components';

import {
  getFedExRates,
  getDhlRates,
  getJntRates,
} from '../../apis/shipmentApi';

import { getUserCurrencySelector } from '../../selectors/userSelector';
import {
  getExchangeRateSelector,
  getExchangeRatesSelector,
} from '../../selectors/exchangeRatesSelector';

import { formatSimpleDate } from '../../utils/dateTimeUtils';
import { isEmptyValue } from '../../utils/commonUtils';
import { convertPriceWithQuantityToCurrency } from '../../utils/currencyUtils';

import {
  notifyErrorContent,
  notifySuccess,
} from '../../services/notificationService';

import { COUNTRY_LIST } from '../../constants/countryConstants';
import { CURRENCY_CODE } from '../../constants/currencyConstants';

import { colors } from '../../palette';
import { reciprocal } from '../../utils/numberUtils';
// --------------------------------------------------------------------------------------

const useStyles = makeStyles(() => ({
  buttonUpdate: {
    padding: '6px 16px',
    fontSize: '0.8rem',
  },
  editIcon: {
    fontSize: '0.9rem',
    '&:hover': {
      backgroundColor: colors.blue050,
      color: 'white',
      cursor: 'pointer',
      borderRadius: '50%',
    },
    padding: 2,
  },
}));

/**
 * this will support multiple items and quotes
 *
 * @returns
 */
function InternationalShippingCalculatorMultipleItems() {
  const classes = useStyles();

  const currency = useSelector(getUserCurrencySelector);
  const exchangeRate = useSelector(getExchangeRateSelector);
  const exchangeRates = useSelector(getExchangeRatesSelector);

  const [localState, updateLocalState] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      itemDetail: null,
      itemID: null,
      destinationCountry: 'America',
      destinationPostalCode: null,
      destinationCityName: null,
      originCountry: 'Singapore',
      originPostalCode: '139967',
      originCityName: 'Singapore',
      weight: null,
      length: null,
      width: null,
      height: null,
      plannedShippingDate: dayjs().add(2, 'day').format('YYYY-MM-DD'),
      isCustomsDeclarable: false,
      isItemProcessing: false,
      processed: undefined,
      price: '',
      estimatedDeliveryDateAndTime: '',
      isSuccessGetDhlRates: false,
      isGetDhlRatesProcessing: false,
      isSuccessGetFedExRates: false,
      isGetFedExRatesProcessing: false,
      fedexRateDetails: [],
      imageUrl: '',
      quantity: null,
      totalWeight: null,
      address: null,

      // when admin update the shipping info manually then set this to true,
      // and use this to not update when items/quotes are added or updated
      retainShippingAddress: false,
    }
  );

  const isGetRatesProcessing =
    localState.isGetDhlRatesProcessing === true ||
    localState.isGetFedExRatesProcessing === true;

  const paramsRates = {
    originCountryCode: COUNTRY_LIST[localState.originCountry].code,
    originCityName: localState.originCityName,
    originPostalCode: localState.originPostalCode,
    destinationCountryCode: COUNTRY_LIST[localState.destinationCountry].code,
    destinationCityName: localState.destinationCityName,
    destinationPostalCode: localState.destinationPostalCode,
    weight: localState.totalWeight,
    length: localState.length,
    width: localState.width,
    height: localState.height,
    plannedShippingDate: formatSimpleDate(localState.plannedShippingDate),
  };

  const isAllRequiresFilled = () => {
    let isFilled = true;
    const negativeValue = ['Invalid Date', null, 0, undefined, '-', 'N.A.', ''];
    const allowedEmptyFields = ['destinationCityName'];
    for (const state in paramsRates) {
      if (
        negativeValue.includes(paramsRates[state]) &&
        !allowedEmptyFields.includes(state)
      ) {
        isFilled = false;
      }
    }
    return isFilled;
  };

  const handleGetDHLShipmentFee = async () => {
    updateLocalState({
      isGetDhlRatesProcessing: true,
      processed: undefined,
    });
    getDhlRates(paramsRates)
      .then((data) => {
        let price = `${data.priceCurrency} ${data.price}`;
        const estimatedDeliveryDateAndTime = data.estimatedDeliveryDateAndTime;
        if (currency !== CURRENCY_CODE.SGD) {
          const { totalPriceStr: convertedPrice } =
            convertPriceWithQuantityToCurrency({
              totalPrice: Number(data.price),
              currency,
              exchangeRate,
            });
          price = `${price} (${convertedPrice})`;
        }
        updateLocalState({
          price,
          estimatedDeliveryDateAndTime,
          isSuccessGetDhlRates: true,
        });
        notifySuccess('DHL Shipment fee has been updated');
      })
      .catch((err) => {
        notifyErrorContent('Failed to get DHL Shipment Fee', err.message);
        updateLocalState({
          isSuccessGetDhlRates: false,
        });
      })
      .finally(() => updateLocalState({ isGetDhlRatesProcessing: false }));
  };

  const handleGetFedExShipmentFee = async () => {
    const paramsFedExRates = {
      shipper: {
        address: {
          postalCode: localState.originPostalCode,
          countryCode: COUNTRY_LIST[localState.originCountry].code,
        },
      },
      recipient: {
        address: {
          postalCode: localState.destinationPostalCode,
          countryCode: COUNTRY_LIST[localState.destinationCountry].code,
        },
      },
      shipDateStamp: formatSimpleDate(localState.plannedShippingDate),
      requestedPackageLineItems: [
        {
          weight: {
            units: 'KG',
            value: Number(localState.totalWeight),
          },
          dimensions: {
            length: Number(localState.length),
            width: Number(localState.width),
            height: Number(localState.height),
            units: 'CM',
          },
        },
      ],
      customsClearanceDetail: {
        commodities: [
          {
            name: 'NON_DOCUMENTS',
            numberOfPieces: 1,
            description: '',
            countryOfManufacture: '',
            harmonizedCode: '',
            harmonizedCodeDescription: '',
            itemDescriptionForClearance: '',
            weight: {
              units: 'KG',
              value: Number(localState.totalWeight),
            },
            quantity: 1,
            quantityUnits: '',
            unitPrice: {
              currency: 'SID',
              amount: null,
              currencySymbol: '',
            },
            customsValue: {
              currency: 'SID',
              amount: null,
              currencySymbol: '',
            },
            exportLicenseNumber: '',
            partNumber: '',
            exportLicenseExpirationDate: '',
            getcIMarksAndNumbers: '',
          },
        ],
      },
    };
    updateLocalState({
      isGetFedExRatesProcessing: true,
    });
    getFedExRates(paramsFedExRates)
      .then((data) => {
        updateLocalState({
          fedexRateDetails: data?.output?.rateReplyDetails,
          isSuccessGetFedExRates: true,
        });
        notifySuccess('FedEx Shipment fee has been updated');
      })
      .catch((err) => {
        notifyErrorContent('Failed to get FedEx Shipment Fee', err.message);
        updateLocalState({
          isSuccessGetFedExRates: false,
        });
      })
      .finally(() => updateLocalState({ isGetFedExRatesProcessing: false }));
  };

  const handleGetJNTShipmentFee = () => {
    const body = {
      weight: Number(localState.totalWeight),
      // service_code: requestedShipment.service_code,
      length: Number(localState.length),
      width: Number(localState.width),
      height: Number(localState.height),
      country_code: COUNTRY_LIST[localState.destinationCountry].code,
    };
    getJntRates(body).then((data) => {
      console.log(data, 'data');
    });
  };

  const renderFedExShipment = () => {
    if (localState.isGetFedExRatesProcessing) {
      return (
        <CircularProgress className={classes.circularProgress} size={20} />
      );
    }
    if (
      !localState.isSuccessGetFedExRates ||
      isEmptyValue(localState.fedexRateDetails)
    ) {
      return null;
    }
    return (
      <Fragment>
        <FtrBoldText fontSize='16'>FedEx Shipment</FtrBoldText>
        {localState.fedexRateDetails?.map((rate, index) => {
          const dataPrice = rate?.ratedShipmentDetails[0];
          const estimatedDeliveryDateAndTime =
            rate?.operationalDetail?.deliveryDate || 'N/A';
          let price = `${dataPrice?.currency} ${dataPrice?.totalNetCharge}`;
          if (dataPrice?.currency !== CURRENCY_CODE.SGD) {
            const { totalPriceStr: convertedPrice } =
              convertPriceWithQuantityToCurrency({
                totalPrice: Number(dataPrice?.totalNetCharge),
                currency,
                exchangeRate: reciprocal(exchangeRates[dataPrice?.currency]),
              });
            const convertedPriceValue = convertedPrice.split(' ')[1];
            price = `${price} (SGD ${convertedPriceValue})`;
          }
          return (
            <ShipmentInfo
              price={price}
              estimatedDeliveryDateAndTime={estimatedDeliveryDateAndTime}
              title={rate.serviceName}
              isLoading={localState.isGetFedExRatesProcessing}
              isSuccess={localState.isSuccessGetFedExRates}
              key={index}
            />
          );
        })}
      </Fragment>
    );
  };

  return (
    <Container>
      <div>
        <ShippingForm
          onChangeState={(objStates) => updateLocalState(objStates)}
        />
      </div>
      <div style={{ marginTop: '1rem' }}>
        <Button
          variant='contained'
          color='primary'
          fullWidth
          onClick={() => {
            handleGetDHLShipmentFee();
            handleGetFedExShipmentFee();
            handleGetJNTShipmentFee();
          }}
          disabled={isGetRatesProcessing || !isAllRequiresFilled()}
        >
          {isGetRatesProcessing && (
            <CircularProgress className={classes.circularProgress} size={20} />
          )}
          &nbsp;Get International shipment fee
        </Button>
      </div>
      <Grid container spacing={1} style={{ paddingTop: '1.5rem' }}>
        <Grid item xs={6}>
          <ShipmentInfo
            price={localState.price}
            estimatedDeliveryDateAndTime={
              localState.estimatedDeliveryDateAndTime
            }
            title='DHL Shipment'
            isLoading={localState.isGetDhlRatesProcessing}
            isSuccess={localState.isSuccessGetDhlRates}
          />
        </Grid>
        <Grid item xs={6}>
          <FlexColumn style={{ gap: '1rem' }}>
            {renderFedExShipment()}
          </FlexColumn>
        </Grid>
      </Grid>
    </Container>
  );
}

export default InternationalShippingCalculatorMultipleItems;
