import React from 'react';

import { Badge, Chip } from '@material-ui/core';

import MonetizationOnOutlinedIcon from '@material-ui/icons/MonetizationOnOutlined';
import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined';
import HourglassEmptyOutlinedIcon from '@material-ui/icons/HourglassEmptyOutlined';
import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlined';
import LocalShippingOutlinedIcon from '@material-ui/icons/LocalShippingOutlined';
import BeenhereOutlinedIcon from '@material-ui/icons/BeenhereOutlined';
import Brightness7OutlinedIcon from '@material-ui/icons/Brightness7Outlined';

const getIcon = (text) => {
  switch (text) {
    case 'Verifying':
      return <BeenhereOutlinedIcon />;
    case 'Quotes Available':
      return <MonetizationOnOutlinedIcon />;
    case 'Sourcing':
      return <SearchOutlinedIcon />;
    case 'Processing':
      return <Brightness7OutlinedIcon />;
    case 'Order In Progress':
      return <HourglassEmptyOutlinedIcon />;
    case 'Order Ready':
      return <CheckCircleOutlinedIcon />;
    case 'Delivered':
      return <LocalShippingOutlinedIcon />;
    default:
      return null;
  }
};

function ProjectItemStatusInfo(props) {
  const { text = 'Text', numOfItems = 0 } = props;

  const IconComp = getIcon(text);

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        bottom: '4px',
      }}
    >
      <Badge badgeContent={numOfItems} color='primary'>
        <Chip icon={IconComp} label={text} variant='outlined' fullWidth />
      </Badge>
    </div>
  );
}

export default ProjectItemStatusInfo;
