import React, { useMemo, useReducer, useState } from 'react';
import { DateRangePicker } from 'react-date-range';
import { clsx } from 'clsx';

import { makeStyles } from '@material-ui/core/styles/index';

import { Popover } from '@material-ui/core';

import { Cancel } from '@material-ui/icons';

import FtrButton from './FtrButton';
import FtrTooltip from './FtrTooltip';
import { FlexRow } from '../layouts/FlexLayouts';
import { FtrNormalText } from './FtrTypography';

import { isEmptyValue } from '../../utils/commonUtils';
import { formatDate } from '../../utils/dateTimeUtils';

import { colors } from '../../palette';

// ------------------------------------------------------------------------------

const useStyles = makeStyles(() => ({
  button: {
    cursor: 'pointer',
    border: `1px solid ${colors.neutral030}`,
    width: 'fit-content',
    padding: '0.3rem 0.8rem',
    borderRadius: '0.5rem',
    '&:hover': {
      border: `1px solid ${colors.blue020}`,
      backgroundColor: colors.neutral030,
    },
  },
  highlighted: {
    border: `1px solid ${colors.blue030}`,
  },
  clearIcon: {
    color: colors.neutral050,
    '&:hover': {
      color: colors.neutral060,
    },
  },
}));

const DEFAULT_SELECTION = [
  {
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection',
  },
];

function FtrDateRangePicker(props) {
  const classes = useStyles();

  const { dateRange, onOk = () => {}, onClear = () => {} } = props;

  const [anchorEl, setAnchorEl] = useState(null);

  const [localState, updateLocalState] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      openPopover: false,
      previousSelectionRange: dateRange ? [dateRange] : [...DEFAULT_SELECTION],
      selectionRange: dateRange ? [dateRange] : [...DEFAULT_SELECTION],
    }
  );

  const displayText = useMemo(() => {
    if (isEmptyValue(dateRange)) {
      return 'Select';
    }

    if (isEmptyValue(localState.previousSelectionRange)) {
      return 'Select';
    }

    const { startDate, endDate } = localState.previousSelectionRange[0];
    return `${formatDate(startDate, 'DD MMM YYYY')} - ${formatDate(
      endDate,
      'DD MMM YYYY'
    )}`;
  }, [dateRange, localState.previousSelectionRange]);

  const handleChange = (item) => {
    updateLocalState({ selectionRange: [item.selection] });
  };

  const handleClear = () => {
    updateLocalState({
      openPopover: false,
      previousSelectionRange: dateRange ? [dateRange] : [...DEFAULT_SELECTION],
      selectionRange: dateRange ? [dateRange] : [...DEFAULT_SELECTION],
    });
    onClear();
  };

  const handleCancel = () => {
    updateLocalState({
      openPopover: false,
      selectionRange: localState.previousSelectionRange,
    });
  };

  return (
    <>
      <FlexRow
        className={clsx(
          classes.button,
          !isEmptyValue(dateRange) &&
            !isEmptyValue(localState.previousSelectionRange)
            ? classes.highlighted
            : null
        )}
        onClick={(event) => {
          setAnchorEl(event.currentTarget);
          updateLocalState({ openPopover: true });
        }}
      >
        <FtrNormalText>{displayText}</FtrNormalText>
        {displayText !== 'Select' && (
          <FtrTooltip description='Clear'>
            <Cancel
              className={classes.clearIcon}
              onClick={(e) => {
                e.stopPropagation();
                handleClear();
              }}
            />
          </FtrTooltip>
        )}
      </FlexRow>
      <Popover
        open={localState.openPopover}
        anchorEl={anchorEl}
        onClose={handleCancel}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <DateRangePicker
          onChange={handleChange}
          showSelectionPreview={true}
          moveRangeOnFirstSelection={false}
          months={2}
          ranges={localState.selectionRange}
          direction='horizontal'
        />
        <FlexRow
          style={{
            padding: '0.5rem',
          }}
        >
          <FtrButton size='small' onClick={handleClear}>
            Clear
          </FtrButton>
          <span style={{ flex: '1 1 auto' }} />
          <FtrButton color='white' size='small' onClick={handleCancel}>
            Cancel
          </FtrButton>
          <FtrButton
            color='blue'
            size='small'
            onClick={() => {
              updateLocalState({
                openPopover: false,
                previousSelectionRange: localState.selectionRange,
              });
              onOk(localState.selectionRange[0]);
            }}
          >
            OK
          </FtrButton>
        </FlexRow>
      </Popover>
    </>
  );
}

export default FtrDateRangePicker;
