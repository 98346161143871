import { ceil } from 'lodash';
import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles/index';

import { DataGrid } from '@mui/x-data-grid';

import { IconButton, Tooltip } from '@material-ui/core';
import { Cached as CachedIcon, Delete as DeleteIcon } from '@material-ui/icons';

import { FtrButton } from '../../components/ftr-components';
import HorizontalExpandSpace from '../../components/ftr-components/HorizontalExpandSpace';
import CustomToolbar, {
  DataGridToolbarLayout,
} from '../../components/grid-data/CustomToolbar';
import GridDataPagination from '../../components/grid-data/GridDataPagination';
import { ExportCsvButton } from '../../components/grid-data/buttons/ExportCsvButton';
import SearchBar from '../../components/grid-data/buttons/SearchBar';
import FtrSvgImage from '../../components/images/FtrSvgImage';
import { FlexRow } from '../../components/layouts/FlexLayouts';
import DataGridWrapTextCell from '../../components/tables/cells/DataGridWrapTextCell';

import DeletedIcon from '../../assets/img/deleted_icon.png';

import withCreateXeroInvoiceItemsPopupHoC from '../../components/popups/withCreateXeroInvoiceItemsPopupHoC';
import withConfirmDeleteXeroInvoiceItemsPopupHOC from '../../hocs/withConfirmDeleteXeroInvoiceItemsPopupHOC';

import { exportCsvApi } from '../../utils/csvExportUtils';
import { dateTzSingapore } from '../../utils/dateTimeUtils';
import { downloadS3File } from '../../utils/fileUtils';
import { openInNewTab } from '../../utils/navigationUtils';

import {
  adminSearchXeroInvoiceItems,
  adminSearchXeroInvoiceItemsAsCsv,
  regenerateS3PdfInvoice,
} from '../../apis/xeroApi';

import useDataGridPaginationHook from '../../hooks/useDataGridPaginationHook';
import useDeviceCheck from '../../hooks/useDeviceCheck';

import { getUserRoleSelector } from '../../selectors/userSelector';

import { isSuperAdminRole } from '../../utils/roleUtils';

import { notifyError, notifySuccess } from '../../services/notificationService';
import { generatePresignedUrl } from '../../services/s3Service';

import { FIVE_MINUTES_IN_SECOND } from '../../constants/dateTimeConstants';

import { colors } from '../../palette';

// -------------------------------------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  body: {
    paddingTop: theme.spacing(2),
    '& .MuiDataGrid-columnSeparator': {
      display: 'none',
    },
    ' & .MuiDataGrid-columnHeaderTitleContainer': {
      padding: '0',
    },
    '& .MuiTablePagination-root': {
      marginRight: '4rem',
    },
    '& .MuiDataGrid-columnHeaderTitle': {
      lineHeight: 'normal',
      wordBreak: 'break-word',
      whiteSpace: 'normal',
      color: colors.blue060,
      fontSize: '11pt',
      fontWeight: 600,
    },
    '& .MuiDataGrid-columnsContainer': {
      display: 'flex',
      justifyContent: 'center',
    },
  },
  linkButton: {
    color: colors.blue050,
    textDecoration: 'underline',
  },
  iconButton: {
    '&:hover': {
      backgroundColor: colors.secondaryBlue,
    },
  },
  seeMoreButton: {
    textTransform: 'none',
    cursor: 'pointer',
  },
}));

const ROW_HEIGHT = 80;

function XeroInvoicesItemsDataGrid(props) {
  const classes = useStyles();

  const {
    updateXeroInvoiceItemPopupHoC = () => {},
    updateConfirmDeletePopupHoCState = () => {},
  } = props;

  const role = useSelector(getUserRoleSelector);

  const [{ isIPad, isMobile, isTablet }] = useDeviceCheck();

  const [
    { isFetching, tableQueryParams, tableData },
    { handleSearch, updateTableQueryParams, refetch },
  ] = useDataGridPaginationHook(adminSearchXeroInvoiceItems);

  const [searchAutoFocus, setSearchAutoFocus] = useState(false);

  const handleRegenerateS3Invoice = (id) => {
    regenerateS3PdfInvoice(id)
      .then(() => {
        notifySuccess('Invoice regenerated successfully');
        refetch();
      })
      .catch((err) => {
        notifyError(err.message || 'Invoice regeneration failed');
      });
  };

  const columns = [
    {
      headerName: 'Item IDs',
      field: 'itemIDs',
      renderCell: ({ row: rowData }) => (
        <DataGridWrapTextCell
          text={rowData.itemIDs}
          style={{ maxHeight: ROW_HEIGHT }}
        />
      ),
      width: 180,
    },
    {
      headerName: 'Customer',
      field: 'customer',
      renderCell: ({ row: rowData }) => (
        <DataGridWrapTextCell
          text={`${rowData.customerName} (${rowData.customerID})`}
        />
      ),
      valueGetter: ({ row: rowData }) =>
        `${rowData.customerName} (${rowData.customerID})`,
      width: 220,
    },
    {
      headerName: 'Xero Invoice Url',
      field: 'xeroInvoiceUrl',
      valueGetter: ({ row: rowData }) => rowData.xeroInvoiceUrl || 'N.A.',
      renderCell: ({ row: rowData }) => (
        <FlexRow>
          <a href={rowData.xeroInvoiceUrl} target='_blank' rel='noreferrer'>
            Link
          </a>
          {rowData.xeroInvoiceDeleted === 1 && (
            <Tooltip title='Deleted' arrow>
              <div>
                <FtrSvgImage src={DeletedIcon} />
              </div>
            </Tooltip>
          )}
          {rowData.xeroInvoiceDeleted !== 1 && (
            <Tooltip
              title='Update invoice status to "DELETED". Once it is deleted, it cannot be updated.'
              arrow
            >
              <IconButton
                onClick={() => {
                  updateConfirmDeletePopupHoCState({
                    open: true,
                    xeroInvoiceID: rowData.xeroInvoiceID,
                    xeroInvoiceUrl: rowData.xeroInvoiceUrl,
                    refetchTableData: refetch,
                  });
                }}
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          )}
        </FlexRow>
      ),
      width: 140,
    },
    {
      headerName: 'S3 Url',
      field: 'xeroInvoiceS3Url',
      valueGetter: ({ row: rowData }) => rowData.xeroInvoiceS3Url || 'N.A.',
      renderCell: ({ row: rowData }) => (
        <FlexRow>
          <Link
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              if (!isMobile && !isTablet && !isIPad) {
                generatePresignedUrl(
                  rowData.xeroInvoiceS3Url,
                  FIVE_MINUTES_IN_SECOND,
                  true
                ).then((presignedUrl) => {
                  openInNewTab(presignedUrl);
                });
              } else {
                downloadS3File(rowData.xeroInvoiceS3Url);
              }
            }}
          >
            Link
          </Link>
          <Tooltip title='Regenerate Xero Invoice S3 PDF' arrow>
            <IconButton onClick={() => handleRegenerateS3Invoice(rowData.id)}>
              <CachedIcon />
            </IconButton>
          </Tooltip>
        </FlexRow>
      ),
      width: 120,
    },
    {
      headerName: 'Created At',
      field: 'createdAt',
      renderCell: ({ row }) => {
        return <DataGridWrapTextCell text={dateTzSingapore(row.createdAt)} />;
      },
      valueGetter: ({ row }) => {
        return dateTzSingapore(row.createdAt);
      },
      width: 140,
    },
    {
      headerName: 'Created By',
      field: 'createdBy',
      renderCell: ({ row: rowData }) => (
        <DataGridWrapTextCell
          text={`${rowData.createdByName} (${rowData.createdBy})`}
        />
      ),
      valueGetter: ({ row: rowData }) =>
        `${rowData.createdByName} (${rowData.createdBy})`,
      width: 220,
    },
    {
      headerName: 'Xero Invoice ID',
      field: 'xeroInvoiceID',
      width: 280,
      renderCell: ({ row: rowData }) => (
        <DataGridWrapTextCell text={rowData.xeroInvoiceID} />
      ),
    },
    { headerName: 'ID', field: 'id' },
  ];

  const getToolbar = useCallback(() => {
    return (
      <DataGridToolbarLayout>
        <HorizontalExpandSpace />
        <CustomToolbar
          buttons={[
            <FtrButton
              key='create-xero-invoice-items'
              color='blue'
              onClick={() =>
                updateXeroInvoiceItemPopupHoC({
                  openCreateXeroInvoicePopup: true,
                  refetchTableData: refetch,
                })
              }
            >
              Create Xero Invoice
            </FtrButton>,
            <SearchBar
              key='search'
              onSearch={handleSearch}
              searchTerm={tableQueryParams.search}
              autoFocus={searchAutoFocus}
              onFocus={() => {
                setSearchAutoFocus(true);
              }}
              onBlur={() => {
                setSearchAutoFocus(false);
              }}
            />,
            <ExportCsvButton
              key='export-csv'
              handleClick={() =>
                exportCsvApi(
                  adminSearchXeroInvoiceItemsAsCsv(tableQueryParams),
                  'All Xero Invoice Items'
                )
              }
              show={isSuperAdminRole(role)}
            />,
          ]}
        />
      </DataGridToolbarLayout>
    );
  }, []);

  return (
    <div className={classes.body}>
      <div
        style={{
          height: '100%',
          width: '100%',
        }}
      >
        <DataGrid
          autoHeight
          paginationMode='server'
          rows={tableData}
          columns={columns.map((col) => ({
            ...col,
            sortable: false,
          }))}
          getRowId={(row) => row.id}
          rowHeight={ROW_HEIGHT}
          headerHeight={60}
          components={{
            Toolbar: getToolbar,
            Pagination: () => (
              <GridDataPagination
                pageCount={ceil(
                  tableQueryParams.totalCount / tableQueryParams.pageSize
                )}
              />
            ),
          }}
          pageSize={tableQueryParams.pageSize}
          onPageChange={(newPage) => updateTableQueryParams({ page: newPage })}
          onPageSizeChange={(newPageSize) =>
            updateTableQueryParams({
              pageSize: newPageSize,
            })
          }
          rowsPerPageOptions={[10, 20, 50]}
          rowCount={tableQueryParams.totalCount}
          loading={isFetching}
          disableColumnMenu
          localeText={{
            toolbarExport: 'Export CSV',
          }}
        />
      </div>
    </div>
  );
}

export default withCreateXeroInvoiceItemsPopupHoC(
  withConfirmDeleteXeroInvoiceItemsPopupHOC(XeroInvoicesItemsDataGrid)
);
