import React, { useState } from 'react';

import { makeStyles } from '@material-ui/core/styles/index';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  IconButton,
} from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';

import QuantityField from '../forms/part-upload-step-two/QuantityField';
import ErrorLabel from '../labels/ErrorLabel';
import { FtrButton, FtrTypography } from '../ftr-components';
import { FlexColumn, FlexRow } from '../layouts/FlexLayouts';

// --------------------------------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  paper: {
    borderRadius: '30px',
    [theme.breakpoints.down('sm')]: {
      borderRadius: '0',
    },
    overflow: 'hidden',
    position: 'relative',
    width: '450px',
  },
  space: {
    width: theme.spacing(2),
  },
}));

function RequestMoreQuotesPopupV2(props) {
  const classes = useStyles();

  const {
    open,
    itemQuantity = 1,
    processing = false,
    formError = {},
    handleClose = () => {},
    handleRequest = () => {},
    resetFormError = () => {},
  } = props;

  const [quantity, setQuantity] = useState(itemQuantity * 10);

  const renderBody = () => {
    return (
      <React.Fragment>
        <FtrTypography
          type='subHeading'
          fontSize='14'
          style={{
            marginBottom: '8px',
          }}
        >
          Quantity
        </FtrTypography>
        <FlexRow style={{ gap: 0 }}>
          <FlexColumn style={{ gap: 4 }}>
            <QuantityField
              id='request-more-quantity-field'
              value={quantity}
              onChange={(evt) => {
                let newQuantity = Number(evt.target.value);
                setQuantity(newQuantity);
                resetFormError();
              }}
              style={{
                width: '100%',
                marginBottom: 0,
              }}
              inputProps={{ min: 1, style: { textAlign: 'left' } }}
            />
            {formError.quantity && <ErrorLabel message={formError.quantity} />}
          </FlexColumn>
        </FlexRow>
      </React.Fragment>
    );
  };

  return (
    <div>
      <Dialog
        maxWidth='md'
        open={open}
        aria-labelledby='confirmation-dialog-title'
        classes={{ paper: classes.paper }}
        onClose={handleClose}
      >
        <DialogTitle
          id='confirmation-dialog-title'
          style={{ marginTop: '1rem' }}
        >
          <FtrTypography type='heading' fontSize='24'>
            Request more quotes
          </FtrTypography>
        </DialogTitle>
        <IconButton
          id='request-more-quotes-close-btn'
          aria-label='close'
          onClick={handleClose}
          style={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
        <div
          style={{
            paddingBottom: '1rem',
          }}
        >
          <DialogContent style={{ paddingTop: 0 }}>
            <React.Fragment>
              <FtrTypography
                type='body'
                fontSize='16'
                style={{
                  marginBottom: '2rem',
                }}
              >
                Request quote for different item quantity
              </FtrTypography>
              {renderBody()}
            </React.Fragment>
          </DialogContent>
          <DialogActions
            style={{
              paddingLeft: '24px',
              paddingRight: '24px',
            }}
          >
            <div>
              <FtrButton
                color='blue'
                onClick={() => {
                  handleRequest(quantity);
                }}
                disabled={quantity <= 0 || processing}
                loading={processing}
              >
                Request
              </FtrButton>
            </div>
          </DialogActions>
        </div>
      </Dialog>
    </div>
  );
}

export default RequestMoreQuotesPopupV2;
