import React from 'react';
import { makeStyles } from '@material-ui/core/styles/index';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  filterBar: {
    marginRight: theme.spacing(2),
    minWidth: 120,
  },
}));

function TechFiltersBar(props) {
  const classes = useStyles();

  const { filterStatus, handleFilterChange } = props;

  return (
    <FormControl variant='outlined' className={classes.filterBar}>
      <FormGroup row={true}>
        <FormControlLabel
          control={
            <Checkbox
              checked={filterStatus.checkedCNC}
              onChange={handleFilterChange}
              name='checkedCNC'
            />
          }
          label='CNC Machining'
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={filterStatus.checked3d}
              onChange={handleFilterChange}
              name='checked3d'
            />
          }
          label='3D Printing'
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={filterStatus.checkedSheetMetal}
              onChange={handleFilterChange}
              name='checkedSheetMetal'
            />
          }
          label='Sheet Metal Fabrication'
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={filterStatus.checkedDesignService}
              onChange={handleFilterChange}
              name='checkedDesignService'
            />
          }
          label='Design Service'
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={filterStatus.checkedOthers}
              onChange={handleFilterChange}
              name='checkedOthers'
            />
          }
          label='Others'
        />
      </FormGroup>
    </FormControl>
  );
}

export default TechFiltersBar;
