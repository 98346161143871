import { CsvBuilder } from 'filefy';
import React, { useCallback, useEffect, useReducer, useState } from 'react';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles/index';

import { DatePicker } from '@material-ui/pickers';

import { Box, IconButton, Tooltip } from '@material-ui/core';
import FlightIcon from '@material-ui/icons/Flight';

import { DataGrid } from '@mui/x-data-grid';

import FilterBar from '../components/filters/FilterBar';
import {
  FtrBoldText,
  FtrButton,
  FtrCaption,
  FtrTooltip,
  FtrTypography,
} from '../components/ftr-components';
import FtrDateRangePicker from '../components/ftr-components/FtrDateRangePicker';
import { ExportCsvButton } from '../components/grid-data/buttons/ExportCsvButton';
import SearchBar from '../components/grid-data/buttons/SearchBar';
import CustomToolbar, {
  DataGridToolbarLayout,
} from '../components/grid-data/CustomToolbar';
import GridDataPagination from '../components/grid-data/GridDataPagination';
import EmailStatusIcon from '../components/icons/EmailStatusIcon';
import {
  FlexColumn,
  FlexRow,
  FlexRowCenter,
} from '../components/layouts/FlexLayouts';
import DataGridWrapTextCell from '../components/tables/cells/DataGridWrapTextCell';
import OrderReadyStatusDisplay from './orderReady/OrderReadyStatusDisplay';

import { InvoiceIcon } from '../components/icons/InvoiceIcon';

import withInternalNotePopupHOC from '../hocs/withInternalNotePopupHOC';
import withShipmentInfoPopupHOC from '../hocs/withShipmentInfoPopupHOC';

import { editItemV2Api } from '../apis/itemApi';
import { getAllProjectOrderReady } from '../apis/orderReadyApi';

import useDeviceCheck from '../hooks/useDeviceCheck';

import { getUserRoleSelector } from '../selectors/userSelector';

import { generateCurrentCustomDateTimeString } from '../util';
import { isEmptyValue } from '../utils/commonUtils';
import { transformCsvExportDataGrid } from '../utils/csvExportUtils';
import {
  addBusinessDays,
  formatDeliveryDateWithCheck,
  isSameOrAfterDate,
} from '../utils/dateTimeUtils';
import { downloadS3File } from '../utils/fileUtils';
import {
  getCollectionDatesFromItemList,
  getDeliveryDatesFromItemList,
  getLatestCollectionDate,
  getLatestCollectionDateStr,
  getLatestDeliveryDate,
  getLatestDeliveryDateStr,
} from '../utils/itemUtils';
import { openInNewTab } from '../utils/navigationUtils';
import { getPoNumber, getPurchaseOrderID } from '../utils/quotationUtils';
import { isSuperAdminRole } from '../utils/roleUtils';
import { isTrackingNumberValid } from '../utils/shipmentUtils';
import { normalizeString } from '../utils/stringUtils';

import { notifyError, notifySuccess } from '../services/notificationService';
import { generatePresignedUrl } from '../services/s3Service';

import { FIVE_MINUTES_IN_SECOND } from '../constants/dateTimeConstants';
import {
  ORDER_READY_STATUS,
  ORDER_READY_STATUS_FILTER_ARRAY,
} from '../constants/orderReadyConstants';

import { colors } from '../palette';
import { compose } from 'redux';

// ----------------------------------------------------------------------------

const useStyles = makeStyles(() => ({
  body: {
    padding: '0 1rem',
    '& .MuiDataGrid-columnSeparator': {
      display: 'none',
    },
    '& .MuiTablePagination-root': {
      marginRight: '4rem',
    },
    ' & .MuiDataGrid-columnHeaderTitleContainer': {
      padding: '0',
    },
    '& .MuiDataGrid-columnHeaderTitle': {
      color: colors.blue060,
      fontSize: '11pt',
      fontWeight: 600,
      margin: '0 auto',
      whiteSpace: 'normal',
      lineHeight: 'normal',
      textAlign: 'center',
    },
    '& .MuiDataGrid-columnsContainer': {
      display: 'flex',
      justifyContent: 'center',
    },
    '& .MuiDataGrid-menuIcon > button': {
      padding: 0,
    },
  },
  internalNote: {
    overflowY: 'auto',
    maxHeight: 51,
    cursor: 'pointer',
    minWidth: 160,
    whiteSpace: 'normal',
    lineHeight: 'normal',
    verticalAlign: 'middle',
  },
}));

const getDateDetails = ({ isDelivered, baseDate, revisedDate, actualDate }) => {
  const baseDateStr = formatDeliveryDateWithCheck(baseDate);
  const revisedDateStr = formatDeliveryDateWithCheck(revisedDate);
  const actualDateStr = formatDeliveryDateWithCheck(actualDate);

  const displayDateStr = isDelivered
    ? actualDateStr || revisedDateStr || baseDateStr
    : revisedDateStr || baseDateStr;
  return { baseDateStr, revisedDateStr, actualDateStr, displayDateStr };
};

const getDateColor = ({ isDelivered, baseDate }) => {
  if (isDelivered) return 'inherit';
  if (isSameOrAfterDate(new Date(), new Date(baseDate))) return 'red';
  if (isSameOrAfterDate(addBusinessDays(new Date(), 5), new Date(baseDate)))
    return '#ff9800';
  return 'inherit';
};

const DateCell = ({
  type,
  dates,
  rowData,
  pickerState,
  handleCloseDatePicker,
  handleOpenDatePicker,
  onUpdateDate,
  emailIcons,
}) => {
  const isDelivered = rowData.status === ORDER_READY_STATUS.DELIVERED;
  const { baseDate, revisedDate, actualDate } = dates;
  const { baseDateStr, revisedDateStr, displayDateStr } = getDateDetails({
    isDelivered,
    baseDate,
    revisedDate,
    actualDate,
  });

  const color = getDateColor({ isDelivered, baseDate });

  const isPickerOpen =
    pickerState.open &&
    pickerState.type === type &&
    pickerState.projectOrderReadyID === rowData.projectOrderReadyID;

  return (
    <FlexRow>
      {rowData.status === ORDER_READY_STATUS.NOT_STARTED && emailIcons}
      {!isDelivered && isPickerOpen && (
        <DatePicker
          value={revisedDate || baseDate}
          open={pickerState.open}
          onChange={onUpdateDate}
          animateYearScrolling
          margin='dense'
          clearable
          clearLabel='No Preference'
          onClose={handleCloseDatePicker}
        />
      )}
      <div
        style={{ color, cursor: isDelivered ? 'default' : 'pointer' }}
        onClick={(e) => handleOpenDatePicker(e, type, rowData)}
      >
        <FlexColumn style={{ width: 'auto' }}>
          <FtrTypography fontSize={14} style={{ color }}>
            {displayDateStr}
          </FtrTypography>
          {!isDelivered && revisedDateStr && (
            <FtrCaption fontSize={10} style={{ color }}>
              {`Orig: ${baseDateStr}`}
            </FtrCaption>
          )}
        </FlexColumn>
      </div>
    </FlexRow>
  );
};

function AllOrderReadyManagementDataGrid(props) {
  const classes = useStyles();

  const {
    internalNotePopupState,
    updateInternalNotePopupState = () => {},
    updateShipmentInfoPopupState = () => {},
  } = props;

  const role = useSelector(getUserRoleSelector);

  const [{ isIPad, isMobile, isTablet }] = useDeviceCheck();

  const defaultDatePickerState = {
    open: false,
    itemIDList: [],
    type: '',
    projectOrderReadyID: null,
    rowData: null,
  };

  const [datePickerState, setDatePickerState] = useReducer((prev, next) => {
    if (isEmptyValue(next)) {
      return defaultDatePickerState;
    }
    return { ...prev, ...next };
  }, defaultDatePickerState);

  const {
    data: allProjectOrderReady,
    refetch,
  } = useQuery('getAllProjectOrderReady', () => getAllProjectOrderReady());

  const getAllOrmsWithShipmentNumber = (currentPor) => {
    if (isEmptyValue(currentPor)) {
      return [];
    }
    const { trackingNumber } = currentPor;
    // return an array consisting only of the current ORM row if shipment number is empty
    if (!isTrackingNumberValid(trackingNumber)) {
      return [currentPor];
    }
    return allProjectOrderReady.filter(
      (orm) => orm.trackingNumber === trackingNumber
    );
  };

  const getValuePoNumber = (rowData) => {
    if (isEmptyValue(rowData)) {
      return null;
    }
    if (rowData.itemOrderReadyList[0].combinedPOsForm) {
      return (
        '#' + getPurchaseOrderID(rowData.itemOrderReadyList[0].combinedPOsForm)
      );
    }
    return getPoNumber(
      rowData.supplierID,
      rowData.projectID,
      rowData.poAcknowledgedVersion
    );
  };

  const setInternalNoteState = (rowData) => {
    updateInternalNotePopupState({
      selectedData: rowData,
      open: true,
      refreshFn: refetch,
      label: 'Please add your notes for this ORM here.',
    });
  };

  const handleOpenNotePopup = (e, rowData) => {
    e.stopPropagation();
    e.preventDefault();
    setInternalNoteState(rowData);
  };

  const handleOpenShipmentInfoPopup = (e, currentPor) => {
    e.stopPropagation();
    e.preventDefault();
    updateShipmentInfoPopupState({
      selectedData: { porList: getAllOrmsWithShipmentNumber(currentPor) },
      open: true,
    });
  };

  const handleOpenDatePicker = (e, type, rowData) => {
    e.stopPropagation();
    e.preventDefault();

    const { projectOrderReadyID, itemOrderReadyList } = rowData;
    const itemIDList = itemOrderReadyList.map((ior) => ior.itemID);

    setDatePickerState({
      open: true,
      type,
      itemIDList,
      projectOrderReadyID,
      rowData,
    });
  };

  const handleCloseDatePicker = () => {
    setDatePickerState(defaultDatePickerState);
  };

  const onUpdateDate = (selectedDate) => {
    const updateBody = {
      [datePickerState.type === 'delivery'
        ? 'revisedDeliveryDate'
        : 'revisedCollectionDate']: selectedDate,
    };
    const promises = datePickerState.itemIDList.map((itemID) =>
      editItemV2Api(itemID, updateBody)
    );
    Promise.all(promises)
      .then(() => {
        notifySuccess(`Date updated successfully`);
        refetch();
        setInternalNoteState(datePickerState.rowData);
      })
      .catch(() => {
        notifyError(`Date could not be updated`);
      })
      .finally(() => {
        handleCloseDatePicker();
      });
  };

  const columns = [
    {
      headerName: 'PO Number',
      field: 'poNumber',
      renderCell: ({ row: rowData }) => {
        const combinedPOsForm = rowData.itemOrderReadyList[0].combinedPOsForm;
        if (combinedPOsForm) {
          return (
            <Link
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                if (combinedPOsForm) {
                  if (!isMobile && !isTablet && !isIPad) {
                    generatePresignedUrl(
                      combinedPOsForm,
                      FIVE_MINUTES_IN_SECOND,
                      true
                    ).then((presignedUrl) => {
                      openInNewTab(presignedUrl);
                    });
                  } else {
                    downloadS3File(combinedPOsForm);
                  }
                }
              }}
            >
              #
              {getPurchaseOrderID(
                rowData.itemOrderReadyList[0].combinedPOsForm
              )}
            </Link>
          );
        }
        return getPoNumber(
          rowData.supplierID,
          rowData.projectID,
          rowData.poAcknowledgedVersion
        );
      },
      valueGetter: ({ row: rowData }) => getValuePoNumber(rowData),
      minWidth: 110,
    },
    {
      headerName: 'Project ID',
      field: 'projectID',
      align: 'center',
      minWidth: 100,
    },
    {
      headerName: 'Part ID(s)',
      field: 'partIDs',
      renderCell: (params) => {
        return (
          <DataGridWrapTextCell
            text={params.row.itemOrderReadyList
              .map((item) => item.itemID)
              .join(', ')}
          />
        );
      },
      valueGetter: (params) => {
        return params.row.itemOrderReadyList
          .map((item) => item.itemID)
          .join(', ');
      },
      align: 'center',
      minWidth: 120,
      flex: 0.5,
    },
    {
      headerName: 'Customer',
      field: 'customer',
      renderCell: ({ row: rowData }) => {
        const item = rowData.itemOrderReadyList[0];
        const { customerName, customerID, customerCompanyName } = item;
        return (
          <FlexColumn
            style={{
              gap: 0,
              wordBreak: 'break-word',
              whiteSpace: 'normal',
              lineHeight: 'normal',
            }}
          >
            <div>
              {customerName} ({customerID})
            </div>
            <FtrTypography type='subHeading' fontSize='12'>
              {customerCompanyName}
            </FtrTypography>
          </FlexColumn>
        );
      },
      valueGetter: ({ row: rowData }) => {
        const item = rowData.itemOrderReadyList[0];
        const { customerName, customerID, customerCompanyName } = item;
        const customerText = `${customerName} (${customerID}) | ${customerCompanyName}`;
        return customerText;
      },
      align: 'center',
      minWidth: 140,
      flex: 1,
    },
    {
      headerName: 'Supplier',
      field: 'supplier',
      renderCell: ({ row: rowData }) => {
        const supplierText = `${rowData.supplierName} (${rowData.supplierID})`;
        return <DataGridWrapTextCell text={supplierText} />;
      },
      valueGetter: ({ row: rowData }) => {
        const supplierText = `${rowData.supplierName} (${rowData.supplierID})`;
        return supplierText;
      },
      align: 'center',
      minWidth: 140,
      flex: 1,
    },
    {
      headerName: 'Status',
      field: 'status',
      renderCell: (param) => {
        const projectOrderReadyStatus = param.row.status;

        const renderShipmentInfo = () => {
          return (
            <Tooltip title='View shipment info and customs tax details'>
              <IconButton
                color='primary'
                onClick={(e) => handleOpenShipmentInfoPopup(e, param.row)}
              >
                <FlightIcon />
              </IconButton>
            </Tooltip>
          );
        };

        if (projectOrderReadyStatus === ORDER_READY_STATUS.DELIVERED) {
          const hasSupplierInvoices = !isEmptyValue(
            param.row.itemOrderReadyList[0].supplierInvoiceUrl
          );
          return (
            <FlexRowCenter style={{ width: '100%', gap: 0 }}>
              <IconButton color='primary'>
                <InvoiceIcon
                  hasSupplierInvoices={hasSupplierInvoices}
                  invoiceSentToHubdoc={param.row.invoiceSentToHubdoc}
                  porCreatedDate={param.row.createdDate}
                />
              </IconButton>
              <div style={{ marginRight: '5px' }}>
                <EmailStatusIcon
                  emailType={param.row.scheduleReminderSubmitInvoice?.brevoType}
                  status={param.row.scheduleReminderSubmitInvoice?.brevoStatus}
                  date={param.row.scheduleReminderSubmitInvoice?.scheduledAt}
                />
                <EmailStatusIcon
                  emailType={param.row.brevoReminderSubmitInvoice?.type}
                  status={param.row.brevoReminderSubmitInvoice?.lastStatus}
                  date={param.row.brevoReminderSubmitInvoice?.updatedAt}
                />
              </div>
              <OrderReadyStatusDisplay status={projectOrderReadyStatus} />
            </FlexRowCenter>
          );
        } else {
          // order is not delivered and no invoice info should be displayed yet
          return (
            <FlexRowCenter style={{ width: '100%', gap: 0 }}>
              {projectOrderReadyStatus === ORDER_READY_STATUS.IN_TRANSIT &&
                param.row.shipmentProvider &&
                renderShipmentInfo()}
              <OrderReadyStatusDisplay status={projectOrderReadyStatus} />
            </FlexRowCenter>
          );
        }
      },
      minWidth: 245,
    },
    {
      headerName: 'Collection Date',
      field: 'collectionDate',
      valueGetter: (params) => {
        return getLatestCollectionDateStr(params.row.itemOrderReadyList);
      },
      renderCell: ({ row: rowData }) => {
        const {
          collectionDate,
          revisedCollectionDate,
          actualCollectionDate,
        } = getCollectionDatesFromItemList(rowData.itemOrderReadyList);

        const emailIcons = (
          <>
            <EmailStatusIcon
              status={rowData.scheduleOrderDeliveryDueSoon?.status}
              emailType={rowData.scheduleOrderDeliveryDueSoon?.type}
              date={rowData.scheduleOrderDeliveryDueSoon?.date}
            />
            <EmailStatusIcon
              status={rowData.brevoReminderOrderDeliveryDueSoon?.lastStatus}
              emailType={rowData.brevoReminderOrderDeliveryDueSoon?.type}
              date={rowData.brevoReminderOrderDeliveryDueSoon?.updatedAt}
            />
          </>
        );

        return (
          <DateCell
            type='collection'
            dates={{
              baseDate: collectionDate,
              revisedDate: revisedCollectionDate,
              actualDate: actualCollectionDate,
            }}
            rowData={rowData}
            pickerState={datePickerState}
            handleOpenDatePicker={handleOpenDatePicker}
            handleCloseDatePicker={handleCloseDatePicker}
            onUpdateDate={onUpdateDate}
            emailIcons={emailIcons}
          />
        );
      },
      align: 'center',
      minWidth: 160,
    },
    {
      headerName: 'Internal Note',
      field: 'notes',
      align: 'center',
      renderCell: ({ row: rowData }) => {
        if (rowData.notes) {
          return (
            <FtrTooltip description='Click to view or edit'>
              <div
                className={classes.internalNote}
                onClick={(e) => handleOpenNotePopup(e, rowData)}
              >
                {rowData.notes}
              </div>
            </FtrTooltip>
          );
        }
        return (
          <FtrButton
            variant='text'
            color='blue'
            onClick={(e) => handleOpenNotePopup(e, rowData)}
          >
            + Add note
          </FtrButton>
        );
      },
      minWidth: 160,
    },
    {
      headerName: 'Delivery Date',
      field: 'deliveryDate',
      valueGetter: (params) => {
        return getLatestDeliveryDateStr(params.row.itemOrderReadyList);
      },
      renderCell: ({ row: rowData }) => {
        const {
          deliveryDate,
          revisedDeliveryDate,
          actualDeliveryDate,
        } = getDeliveryDatesFromItemList(rowData.itemOrderReadyList);

        return (
          <DateCell
            type='delivery'
            dates={{
              baseDate: deliveryDate,
              revisedDate: revisedDeliveryDate,
              actualDate: actualDeliveryDate,
            }}
            rowData={rowData}
            pickerState={datePickerState}
            handleCloseDatePicker={handleCloseDatePicker}
            handleOpenDatePicker={handleOpenDatePicker}
            onUpdateDate={onUpdateDate}
          />
        );
      },
      align: 'center',
      minWidth: 120,
    },
  ];

  const handleRowClick = (params) => {
    const url = `/projects/${params.row.projectID}/order-ready?supplierID=${params.row.supplierID}&version=${params.row.poAcknowledgedVersion}`;
    openInNewTab(url);
  };

  const [tableQueryParams, updateTableQueryParams] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      page: 0,
      pageSize: 20,
      search: '',
      status: [],
      totalCount: 0,
      loading: false,
    }
  );

  const [filteredTableData, setFilterTableData] = useState([]);

  const handleDownloadCsv = () => {
    const fileName = `All Project Order Ready ${generateCurrentCustomDateTimeString()}.csv`;
    const { exportedColumns, exportedData } = transformCsvExportDataGrid(
      columns,
      filteredTableData
    );
    const builder = new CsvBuilder(fileName);
    builder
      .setDelimeter(',')
      .setColumns(exportedColumns)
      .addRows(exportedData)
      .exportFile();
  };

  let timer;
  useEffect(() => {
    updateTableQueryParams({ loading: true });
    let filteredData = allProjectOrderReady || [];
    if (!isEmptyValue(tableQueryParams.status)) {
      filteredData = filteredData.filter((row) => {
        const included = tableQueryParams.status.includes(row.status);
        if (included) {
          return true;
        }

        for (const itemOrderReady of row.itemOrderReadyList) {
          if (tableQueryParams.status.includes(itemOrderReady.status)) {
            return true;
          }
        }

        return false;
      });
    }

    if (!isEmptyValue(tableQueryParams.collectionDateFilter)) {
      const { startDate, endDate } = tableQueryParams.collectionDateFilter;
      filteredData = filteredData.filter((row) => {
        const collectionDate = getLatestCollectionDate(row.itemOrderReadyList);
        return (
          isSameOrAfterDate(collectionDate, startDate) &&
          isSameOrAfterDate(endDate, collectionDate)
        );
      });
    }

    if (!isEmptyValue(tableQueryParams.deliveryDateFilter)) {
      const { startDate, endDate } = tableQueryParams.deliveryDateFilter;
      filteredData = filteredData.filter((row) => {
        const deliveryDate = getLatestDeliveryDate(row.itemOrderReadyList);
        return (
          isSameOrAfterDate(deliveryDate, startDate) &&
          isSameOrAfterDate(endDate, deliveryDate)
        );
      });
    }

    if (!tableQueryParams.search) {
      setFilterTableData(filteredData);
      updateTableQueryParams({
        loading: false,
        totalCount: filteredData?.length || 0,
      });
      return;
    }

    clearTimeout(timer);
    timer = setTimeout(() => {
      filteredData = filteredData.filter((row) => {
        const item = row.itemOrderReadyList[0];
        const searchFields = [
          getPoNumber(row.supplierID, row.projectID, row.poAcknowledgedVersion),
          row.projectID,
          row.itemOrderReadyList.map((item) => item.itemID).join(', '),
          row.supplierEmail.toLowerCase(),
          row.supplierName.toLowerCase(),
          item.customerName.toLowerCase(),
          normalizeString(item.customerCompanyName),
          // Search by trackingNumber if search query is at least 5 characters long
          tableQueryParams.search && tableQueryParams.search?.length >= 5
            ? row.trackingNumber
            : null,
        ].filter(Boolean); // Remove null values if trackingNumber is excluded
        return searchFields
          .map((field) =>
            String(field).includes(tableQueryParams.search.toLowerCase())
          )
          .some(Boolean);
      });
      setFilterTableData(filteredData);
      updateTableQueryParams({
        loading: false,
        totalCount: filteredData.length,
      });
    }, 300);

    return () => {
      clearTimeout(timer);
    };
  }, [
    tableQueryParams.search,
    tableQueryParams.page,
    tableQueryParams.pageSize,
    tableQueryParams.status,
    tableQueryParams.collectionDateFilter,
    tableQueryParams.deliveryDateFilter,
  ]);

  useEffect(() => {
    if (!allProjectOrderReady) {
      return;
    }

    setFilterTableData(allProjectOrderReady);
    updateTableQueryParams({
      totalCount: allProjectOrderReady.length,
    });
  }, [allProjectOrderReady]);

  const handleSearch = (searchTerm) => {
    updateTableQueryParams({
      page: 0,
      search: searchTerm,
    });
  };

  const getCustomToolbar = () => {
    return (
      <DataGridToolbarLayout>
        <FlexRow>
          <FtrTypography>Filter by</FtrTypography>
          <FtrBoldText style={{ color: colors.blue060 }}>
            Collection Date:
          </FtrBoldText>
          <FtrDateRangePicker
            onOk={useCallback(
              (value) => {
                updateTableQueryParams({
                  collectionDateFilter: value,
                });
              },
              [updateTableQueryParams]
            )}
            onClear={() => {
              updateTableQueryParams({
                collectionDateFilter: null,
              });
            }}
            dateRange={tableQueryParams.collectionDateFilter}
          />
          <Box style={{ width: '0.5rem' }} />
          <FtrBoldText style={{ color: colors.blue060 }}>
            Delivery Date:
          </FtrBoldText>
          <FtrDateRangePicker
            onOk={useCallback(
              (value) => {
                updateTableQueryParams({
                  deliveryDateFilter: value,
                });
              },
              [updateTableQueryParams]
            )}
            onClear={() => {
              updateTableQueryParams({
                deliveryDateFilter: null,
              });
            }}
            dateRange={tableQueryParams.deliveryDateFilter}
          />
        </FlexRow>
        <CustomToolbar
          buttons={[
            <SearchBar
              key='search'
              onSearch={handleSearch}
              searchTerm={tableQueryParams.search}
              autoFocus={!internalNotePopupState.open}
            />,
            <ExportCsvButton
              key='export-csv'
              handleClick={handleDownloadCsv}
              show={isSuperAdminRole(role)}
            />,
          ]}
        />
      </DataGridToolbarLayout>
    );
  };

  const handleFilterChange = (event) => {
    const { name, checked } = event.target;
    if (checked) {
      updateTableQueryParams({
        status: [...tableQueryParams.status, name],
      });
    } else {
      updateTableQueryParams({
        status: tableQueryParams.status.filter((status) => status !== name),
      });
    }
  };

  return (
    <>
      <FilterBar
        optionList={ORDER_READY_STATUS_FILTER_ARRAY}
        onFilterChange={handleFilterChange}
      />
      <DataGrid
        autoHeight
        headerHeight={90}
        columns={columns}
        rows={isEmptyValue(filteredTableData) ? [] : filteredTableData}
        getRowId={(row) => row.projectOrderReadyID}
        onRowClick={handleRowClick}
        pageSize={tableQueryParams.pageSize}
        onPageSizeChange={(newPageSize) =>
          updateTableQueryParams({ pageSize: newPageSize })
        }
        rowsPerPageOptions={[10, 20, 50]}
        loading={tableQueryParams.loading}
        disableSelectionOnClick
        disableRowSelectionOnClick
        components={{
          Toolbar: getCustomToolbar,
          Pagination: () => {
            return (
              <GridDataPagination
                pageCount={Math.ceil(
                  tableQueryParams.totalCount / tableQueryParams.pageSize
                )}
              />
            );
          },
        }}
        localeText={{
          toolbarExport: 'Export CSV',
        }}
        disableColumnMenu
      />
    </>
  );
}

export default compose(
  withInternalNotePopupHOC,
  withShipmentInfoPopupHOC
)(AllOrderReadyManagementDataGrid);
