import React, {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import { isEqual } from 'lodash';

import { makeStyles } from '@material-ui/core/styles';

import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Divider,
  Grid,
  InputAdornment,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
  withStyles,
} from '@material-ui/core';

import { Refresh } from '@material-ui/icons';

import { DatePicker } from '@material-ui/pickers';

import ColorFtrDropdown from '../ftr-components/ColorFtrDropdown';
import FtrSvgImage from '../images/FtrSvgImage';
import ImperialMetricSwitchV2 from '../ImperialMetricSwitch/ImperialMetricSwitchV2';
import MaterialCategoriesInputField from '../inputs/MaterialCategoriesInputField';
import PartNameField from '../fields/PartNameField';
import ReplacePartPopup from './part-upload-step-two/configure-part-form/ReplacePartPopup';
import ThreeDInfillSelectField from '../inputs/ThreeDInfillSelectField';
import ThreeDLayerThicknessField from '../inputs/ThreeDLayerThicknessField';
import ThreeDPrintingMaterialField from '../inputs/ThreeDPrintingMaterialField';
import ThreeDPrintingTechnologyInputField from '../inputs/ThreeDPrintingTechnologyInputField';
import {
  FlexColumn,
  FlexRowSpaceBetween,
  FlexRow,
} from '../layouts/FlexLayouts';
import TechnicalFileNameDisplay from './TechnicalFileNameDisplay';
import {
  FtrButton,
  FtrDropdown,
  FtrLightText,
  FtrSmallButton,
  FtrTextField,
  FtrTypography,
  FtrUploadFileSmallButton,
} from '../ftr-components';

import InfoIcon from '../../assets/icons/info_icon.svg';
import AutoAwesomeIcon from '../../assets/icons/auto_awesome.svg';

import { renderSkeletonOverlay } from '../util/skeleton';

import { useItemInputConfig } from '../../hooks/useItemInputConfig';

import { isEmptyValue } from '../../utils/commonUtils';
import { getCurrencySymbol } from '../../utils/currencyUtils';
import { getDateStrWithMonth } from '../../utils/dateTimeUtils';
import { getTechnicalDrawingFiles } from '../../utils/tdeUtils';

import { getDefaultTolerance } from '../../utils/toleranceUtils';
import {
  isCustomColor,
  isCustomMaterial,
  isCustomMaterialColor,
  isCustomSurfaceFinish,
  isCustomTechnology,
} from '../../utils/inputUtils';
import { getItemNameFromCadFile, is3DPTechnology } from '../../utils/itemUtils';
import {
  convertImperialToMetric,
  convertMetricToImperial,
  displayBoundingBoxValues,
  showUnitValueFromMetric,
} from '../../utils/userUtils';

import { uploadCadFileToS3 } from '../../services/s3Service';
import { notifyError } from '../../services/notificationService';

import { ppe3dpInfillOptions, techMapping } from '../../constants/PPEConstants';
import {
  UNIT_TYPES,
  DISPLAY_UNIT_OPTIONS,
} from '../../constants/unitConstants';
import {
  STANDARD_TOLERANCE_OPTIONS,
  TECHNOLOGY_OPTION_TYPE,
  THREE_D_P_FDM_TECH,
  threeDPrintingInfillDefault,
  threeDPrintingLayerThicknessDefault,
} from '../../constants/NewPartConstants';

import { colors } from '../../palette';
import ImageWith3DViewer from '../images/ImageWith3DViewer';
import { editItemApi } from '../../apis/itemApi';
import { extractTdeForItem } from '../../actions/utils/tdeActionUtils';
import { convert2DImageWithPlaceholder } from '../../services/convert2DImageService';

import AppContext from '../../context/AppContext';
import DfmAnalysisButton from '../DfmDefects/DfmAnalysisButton';
import {
  isSourceUrlAndTechValid,
  showDfmPueMpr,
} from '../../utils/dfmExtractionUtils';
import { findCadFiles } from '../../utils/fileUtils';
import { DFM_AUTOMATION_EXTENSIONS } from '../../constants/dfmConstants';
import { extractDfmDefectByItemID } from '../../apis/dfmDefectExtractionApi';

const useStyles = makeStyles((theme) => ({
  '@global': {
    '*::-webkit-scrollbar': {
      width: '0.4em',
    },
    '*::-webkit-scrollbar-track': {
      display: 'block',
    },
    '*::-webkit-scrollbar-thumb': {
      width: '6px',
      borderRadius: '10px',
      backgroundColor: colors.neutral030,
    },
  },
  paper: {
    borderRadius: '30px',
    [theme.breakpoints.down('sm')]: {
      borderRadius: '0',
    },
    overflow: 'hidden',
  },
  autoAwesomeIcon: {
    height: '1rem !important',
    width: '1rem !important',
    filter: `invert(41%) sepia(90%) saturate(5329%) hue-rotate(203deg) brightness(96%) contrast(86%);`,
  },
  itemField: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '3px 0',
  },
  renderImage: {
    cursor: 'pointer',
    position: 'relative',
    '&:hover': {
      '& img': {
        filter: 'brightness(0.8)',
      },
      '& $render3dCAD': {
        display: 'block',
      },
    },
  },
  render3dCAD: {
    display: 'none',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    color: 'white',
    fontSize: '1.25rem',
  },
  bounce: {
    animation: '$bounce 2s',
    animationIterationCount: 'infinite',
  },
  '@keyframes bounce': {
    '0%, 20%, 50%, 80%, 100%': {
      transform: 'translateY(0)',
    },
    '40%': {
      transform: 'translateY(-10px)',
    },
    '60%': {
      transform: 'translateY(-5px)',
    },
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '1rem 0',
  },
  orderSpecs: {
    marginTop: '1rem',
  },
  partSpecLeftSide: {
    padding: '0 2rem 2rem 2rem',
    [theme.breakpoints.down('sm')]: {
      padding: '0 1rem 2rem 1rem',
    },
  },
  fileName: {
    color: colors.neutral070,
    wordBreak: 'break-all',
    paddingRight: '1rem',
  },
  hide: {
    visibility: 'hidden',
    opacity: 0,
  },
  fadeIn: {
    visibility: 'visible',
    opacity: 1,
    transition: 'opacity 150ms ease-in-out',
  },
}));

const FieldLabel = withStyles({
  root: {
    padding: '0.3rem 0',
    color: colors.neutral070,
  },
})((props) => <FtrTypography type='heading' fontSize='16' {...props} />);

const ItemFieldText = withStyles({
  root: {
    textAlign: 'left',
    color: colors.neutral060,
  },
})((props) => <FtrTypography type='body' fontSize='14' {...props} />);

function ConfigureEditRfqPartForm(props) {
  const { open, item, getItems, onClose = () => {} } = props;

  const classes = useStyles();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const currency = useSelector((state) => state.auth.location.currency);
  const user = useSelector((state) => state.auth.user);

  const { setSnackbarMessage } = useContext(AppContext);

  const imageContainerRef = useRef(null);
  const imageContainerWidth =
    imageContainerRef?.current?.getBoundingClientRect()?.width ?? 0;

  const defaultFormState = {
    ...item,
    otherTechnology: '',
    material: item.material,
    otherMaterial: item.customMaterial,
    otherSurfaceFinish: item.customSurfaceFinish,
    threeDTechnology: '',
    threeDInfill: '',
    threeDLayerThickness: '',
    remarks: '',
    price: null,
    totalPrice: null,
    partApplication: '',
    customMaterialColor: '',
    customColor: '',
    targetPrice: '',
    originalTargetPrice: '',
    expectedLeadTime: null,
    unitType: item.unitType || UNIT_TYPES.METRIC,
    ...item.metadata,
  };

  const [formState, updateFormState] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      ...defaultFormState,
    }
  );

  const [
    {
      technologyOptions,
      materialCategoryOptions,
      threeDTechnologyOptions,
      threeDMaterialOptions,
      surfaceFinishOptions,
      materialColorOptions,
      surfaceFinishColorOptions,
      defaultThreeDMaterial,
      loadingStatus,
      isConfigurationLoading,
    },
    {
      loadSelectColorSurfaces,
      loadTechnologyOptions,
      loadMaterialCategoryOptions,
      load3DTechnologyOptions,
      loadThreeDMaterialOptions,
      loadSurfaceFinishOptions,
      loadMaterialColorOptions,
      loadSurfaceFinishColorOptions,
      technologyHasChanged,
      materialHasChanged,
      threeDTechnologyHasChanged,
      surfaceFinishHasChanged,
      setThreeDMaterialOptions,
      setMaterialColorOptions,
      setSurfaceFinishColorOptions,
    },
  ] = useItemInputConfig({
    setTechnology: (technology) => {
      updateFormState({ technology });
    },
    setMaterial: (material) => {
      updateFormState({ material });
    },
    setThreeDTechnology: (threeDTechnology) => {
      updateFormState({ threeDTechnology });
    },
    setSurfaceFinish: (surfaceFinish) => {
      updateFormState({ surfaceFinish });
    },
    setMaterialColor: (materialColor) => {
      updateFormState({ materialColor });
    },
    setColor: (color) => {
      updateFormState({ color });
    },
  });

  const [isAddingRemarks, setIsAddingRemarks] = useState(
    Boolean(item.description)
  );
  const [showReplacePartPopup, setShowReplacePartPopup] = useState(false);
  const [loadingDfmApi, setLoadingDfmApi] = useState(false);

  const drawingFiles = getTechnicalDrawingFiles(
    formState.originalFiles?.split(',')
  );

  const isSkeleton = formState.tdeStatus === 'loading';

  // --------------------------------------------------------------------------

  const validateForm = useCallback(() => {
    if (isEmptyValue(formState.quantity)) {
      return false;
    }
    if (isEmptyValue(formState.originalFiles)) {
      return false;
    }
    if (
      isCustomTechnology(formState.technology) &&
      isEmptyValue(formState.otherTechnology)
    ) {
      return false;
    }
    if (
      isCustomMaterial(formState.material) &&
      isEmptyValue(formState.otherMaterial)
    ) {
      return false;
    }
    if (
      isCustomSurfaceFinish(formState.surfaceFinish) &&
      isEmptyValue(formState.otherSurfaceFinish)
    ) {
      return false;
    }
    if (
      formState.materialColor === 'Custom Color' &&
      isEmptyValue(formState.customMaterialColor)
    ) {
      return false;
    }
    if (
      formState.color === 'Custom Color' &&
      isEmptyValue(formState.customColor)
    ) {
      return false;
    }
    return true;
  }, [
    formState.quantity,
    formState.technology,
    formState.otherTechnology,
    formState.material,
    formState.otherMaterial,
    formState.surfaceFinish,
    formState.otherSurfaceFinish,
    formState.materialColor,
    formState.customMaterialColor,
    formState.color,
    formState.customColor,
  ]);

  const isFormValid = useMemo(() => validateForm(), [
    formState.quantity,
    formState.technology,
    formState.otherTechnology,
    formState.material,
    formState.otherMaterial,
    formState.surfaceFinish,
    formState.otherSurfaceFinish,
    formState.materialColor,
    formState.customMaterialColor,
    formState.color,
    formState.customColor,
  ]);

  const waiting = formState.loading === true;
  const isFormLoading =
    isConfigurationLoading || isSkeleton || formState.imageStatus === 'loading';

  useEffect(() => {
    if (isEmptyValue(formState.technology)) {
      return;
    }

    loadTechnologyOptions(false);
    loadSelectColorSurfaces({ technology: formState.technology });
    loadMaterialCategoryOptions({ technology: formState.technology }, false);
    if (is3DPTechnology(formState.technology)) {
      load3DTechnologyOptions(false);
      loadThreeDMaterialOptions(
        {
          technology: formState.technology,
          threeDTechnology: formState.threeDTechnology,
        },
        false
      ).catch(() => {
        setThreeDMaterialOptions(['Custom Material']);
      });
    }

    const params = {
      technology: formState.technology,
      threeDTechnology: formState.threeDTechnology,
      material: formState.material,
    };
    loadSurfaceFinishOptions(params, false);
    if (formState.materialColor) {
      loadMaterialColorOptions(params, false);
    }
  }, [formState.technology]);

  useEffect(() => {
    if (isEmptyValue(formState.materialColor)) {
      setMaterialColorOptions(null);
      return;
    }

    if (!isEmptyValue(materialCategoryOptions)) {
      return;
    }

    const params = {
      technology: formState.technology,
      threeDTechnology: formState.threeDTechnology,
      material: formState.material,
    };
    loadMaterialColorOptions(params, false);
  }, [formState.materialColor]);

  useEffect(() => {
    if (isEmptyValue(formState.color)) {
      setSurfaceFinishColorOptions([]);
      return;
    }

    loadSurfaceFinishColorOptions(
      {
        technology: formState.technology,
        surfaceFinish: formState.surfaceFinish,
      },
      false
    );
  }, [formState.color]);

  useEffect(() => {
    // Update custom fields if needed
    let timerID = setTimeout(() => {
      const isCustomMaterialColor =
        !isEmptyValue(materialColorOptions) &&
        !Object.keys(materialColorOptions).includes(formState.materialColor);
      const isCustomColor =
        !isEmptyValue(surfaceFinishColorOptions) &&
        !Object.keys(surfaceFinishColorOptions).includes(formState.color);
      const isCustomTechnology =
        !isEmptyValue(technologyOptions) &&
        !technologyOptions.includes(formState.technology);

      if (isCustomMaterialColor || isCustomColor || isCustomTechnology) {
        updateFormState({
          ...(isCustomMaterialColor && {
            materialColor: 'Custom Color',
            customMaterialColor: formState.materialColor,
          }),
          ...(isCustomColor && {
            color: 'Custom Color',
            customColor: formState.color,
          }),
          ...(isCustomTechnology && {
            technology: TECHNOLOGY_OPTION_TYPE.CUSTOM_FABRICATION_TECHNOLOGY,
            otherTechnology: formState.technology,
          }),
        });
      }
    }, 500);
    return () => {
      clearTimeout(timerID);
    };
  }, [
    technologyOptions,
    materialCategoryOptions,
    threeDTechnologyOptions,
    threeDMaterialOptions,
    surfaceFinishOptions,
    materialColorOptions,
    surfaceFinishColorOptions,
  ]);

  const removeTDEGenField = (fieldsToRemove) => {
    const generatedFields = new Set(formState.generatedFields || []);
    fieldsToRemove.forEach((field) => generatedFields.delete(field));
    updateFormState({ generatedFields: Array.from(generatedFields) });
  };

  const handleChangeUnitType = (unitType) => {
    removeTDEGenField(['unitType']);
    updateFormState({ unitType });
  };

  const handleSave = () => {
    if (!isFormValid || isConfigurationLoading || isSkeleton) {
      return;
    }
    const itemBody = {
      ...formState,
      technology: isCustomTechnology(formState.technology)
        ? formState.otherTechnology
        : formState.technology,
      materialColor: isCustomMaterialColor(formState.materialColor)
        ? formState.customMaterialColor
        : formState.materialColor,
      color: isCustomColor(formState.color)
        ? formState.customColor
        : formState.color,
    };
    return editItemApi(item.itemID, itemBody);
  };

  const handleSaveWithDelay = () => {
    setTimeout(() => {
      handleSave();
    }, 500);
  };

  const handleClose = () => {
    if (!isFormValid || isFormLoading) {
      return;
    }
    onClose();
    if (isEqual(defaultFormState, formState)) {
      return;
    }
    handleSave().then(() => {
      getItems();
      setSnackbarMessage('Part has been updated');
    });
  };

  const handleUploadTechnicalDrawing = async (newFiles) => {
    const uploadedUrls = [];
    await Promise.all(
      Array.from(newFiles).map(async (file) => {
        const { s3ObjectUrl } = await uploadCadFileToS3(file);
        if (!isEmptyValue(s3ObjectUrl)) {
          uploadedUrls.push(s3ObjectUrl);
        }
      })
    ).catch(() => {
      notifyError('Error with upload');
    });
    const _originalFiles =
      typeof formState.originalFiles === 'string'
        ? formState.originalFiles.split(',')
        : formState.originalFiles;
    updateFormState({
      originalFiles: _originalFiles.concat(uploadedUrls).join(','),
      tdeStatus: 'loading',
    });
    const newCadPart = await extractTdeForItem({
      part: { cadPart: uploadedUrls },
      user,
    });

    updateFormState({ tdeStatus: newCadPart.tdeStatus });
    if (!isEmptyValue(newCadPart.generatedFields)) {
      const genFields = {};
      for (const genField of newCadPart.generatedFields) {
        const key = genField === 'qty' ? 'quantity' : genField;
        genFields[key] = newCadPart[genField];
      }
      updateFormState({
        generatedFields: newCadPart.generatedFields,
        ...genFields,
      });
      handleSaveWithDelay();
    }
  };

  const handleReplaceCadFile = async (files) => {
    const file = files[0];
    const newName = getItemNameFromCadFile(file.name);
    if (formState.nameEdited !== true) {
      updateFormState({
        name: newName,
      });
    }
    updateFormState({ imageStatus: 'loading' });
    const { s3ObjectUrl } = await uploadCadFileToS3(file);
    const twoDImageUrl = await convert2DImageWithPlaceholder({
      file_url: s3ObjectUrl,
    });

    const originalFilesArr = formState.originalFiles?.split(',') || [];
    const cadFileArr = originalFilesArr.filter(
      (link) => !link.toLowerCase().endsWith('.pdf')
    );
    let remainFiles = !isEmptyValue(cadFileArr)
      ? originalFilesArr.filter((link) => link.toLowerCase().endsWith('.pdf'))
      : originalFilesArr.slice(1);
    const newOriginalFilesArr = [s3ObjectUrl, ...remainFiles];

    updateFormState({
      twoDImageUrl,
      imageFile: twoDImageUrl,
      originalFiles: newOriginalFilesArr.join(','),
      imageStatus: 'finished',
    });
    handleSaveWithDelay();
  };

  const handleGetDfmAnalysis = (rowData) => {
    const cadFile = rowData.cadFile || rowData.originalFiles;
    const technology = rowData.technology;
    const body = {
      process: techMapping[technology] || technology,
      sourceUrl: findCadFiles(cadFile.split(','), DFM_AUTOMATION_EXTENSIONS),
    };
    setLoadingDfmApi((prev) => ({ ...prev, [rowData.itemID]: true }));
    extractDfmDefectByItemID(rowData.itemID, body)
      .then(() => {
        getItems();
      })
      .catch(() => {
        notifyError(`Couldn't get DFM analysis.`);
      })
      .finally(() => {
        setLoadingDfmApi(false);
      });
  };

  const renderTDEGenImage = (fieldNames) => {
    const generatedFieldsSet = new Set(formState.generatedFields || []);
    if (fieldNames.some((fieldName) => generatedFieldsSet.has(fieldName))) {
      return (
        <Tooltip title={'Automatically extracted parameter'} arrow>
          <div>
            <FtrSvgImage
              src={AutoAwesomeIcon}
              className={classes.autoAwesomeIcon}
            />
          </div>
        </Tooltip>
      );
    }
    return null;
  };

  const render2DImage = () => {
    return (
      <div
        ref={imageContainerRef}
        style={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <ImageWith3DViewer
          key={item.itemID}
          cadFile={formState.originalFiles}
          twoDImageUrl={formState.twoDImageUrl}
          width={imageContainerWidth}
          height={imageContainerWidth}
          noBorder
          isLoading={formState.imageStatus === 'loading'}
          borderRadius={20}
        />
      </div>
    );
  };

  const renderPartSpecsSummary = () => {
    return (
      <>
        <Grid item style={{ paddingBottom: isMobile ? '2rem' : 0 }}>
          <FtrTypography
            type='heading'
            fontSize='14'
            style={{ padding: '4px 0' }}
          >
            Part Specs
          </FtrTypography>
          <div className={classes.itemField}>
            <ItemFieldText>Dimensions</ItemFieldText>
            <FtrTypography type='body' fontSize='14'>
              {displayBoundingBoxValues(
                item.ppeInformation?.boundingBoxX,
                item.ppeInformation?.boundingBoxY,
                item.ppeInformation?.boundingBoxZ,
                formState.unitType
              )}
            </FtrTypography>
          </div>
          <div className={classes.itemField}>
            <ItemFieldText>Technology</ItemFieldText>
            <FtrTypography type='body' fontSize='14'>
              {formState.technology}
            </FtrTypography>
          </div>
          {is3DPTechnology(formState.technology) && (
            <div className={classes.itemField}>
              <ItemFieldText>3D Printing Technology</ItemFieldText>
              <FtrTypography
                type='body'
                fontSize='14'
                style={{ textAlign: 'right' }}
              >
                {formState.threeDTechnology}
              </FtrTypography>
            </div>
          )}
          {isCustomTechnology(formState.technology) &&
            formState.otherTechnology && (
              <div className={classes.itemField}>
                <ItemFieldText>Custom Technology</ItemFieldText>
                <FtrTypography type='body' fontSize='14'>
                  {formState.otherTechnology}
                </FtrTypography>
              </div>
            )}
          <div className={classes.itemField}>
            <ItemFieldText>Material</ItemFieldText>
            <FtrTypography type='body' fontSize='14'>
              {formState.material}
            </FtrTypography>
          </div>
          {isCustomMaterial(formState.material) && formState.otherMaterial && (
            <div className={classes.itemField}>
              <ItemFieldText>Custom Material</ItemFieldText>
              <FtrTypography type='body' fontSize='14'>
                {formState.otherMaterial}
              </FtrTypography>
            </div>
          )}
          {!isEmptyValue(materialColorOptions) && (
            <div className={classes.itemField}>
              <ItemFieldText>Material Color</ItemFieldText>
              <FtrTypography type='body' fontSize='14'>
                {formState.materialColor}
              </FtrTypography>
            </div>
          )}
          {is3DPTechnology(formState.technology) &&
            formState.threeDTechnology === THREE_D_P_FDM_TECH && (
              <Fragment>
                <div className={classes.itemField}>
                  <ItemFieldText>3D Infill</ItemFieldText>
                  <FtrTypography type='body' fontSize='14'>
                    {Number(formState.threeDInfill * 100).toFixed(0)}%
                  </FtrTypography>
                </div>
                <div className={classes.itemField}>
                  <ItemFieldText>Layer Thickness</ItemFieldText>
                  <FtrTypography type='body' fontSize='14'>
                    {formState.threeDLayerThickness}mm
                  </FtrTypography>
                </div>
              </Fragment>
            )}
          <div className={classes.itemField}>
            <ItemFieldText>Surface Finish</ItemFieldText>
            <FtrTypography type='body' fontSize='14'>
              {formState.surfaceFinish == 'NIL' ||
              formState.surfaceFinish == null
                ? 'NIL'
                : formState.surfaceFinish}
            </FtrTypography>
          </div>
          {isCustomSurfaceFinish(formState.surfaceFinish) &&
            formState.otherSurfaceFinish && (
              <div className={classes.itemField}>
                <ItemFieldText>Custom Surface Finish</ItemFieldText>
                <FtrTypography type='body' fontSize='14'>
                  {formState.otherSurfaceFinish}
                </FtrTypography>
              </div>
            )}
          {formState.color && (
            <div className={classes.itemField}>
              <ItemFieldText>Finish Color</ItemFieldText>
              <FtrTypography type='body' fontSize='14'>
                {formState.color}
              </FtrTypography>
            </div>
          )}
          <div className={classes.itemField}>
            <ItemFieldText>Tolerance</ItemFieldText>
            <FtrTypography type='body' fontSize='14'>
              ±
              {showUnitValueFromMetric(formState.tolerance, formState.unitType)}
            </FtrTypography>
          </div>
        </Grid>
        {(formState.expectedPrice || formState.expectedLeadTime) && (
          <Grid item className={classes.orderSpecs}>
            <FtrTypography
              type='heading'
              fontSize='14'
              style={{ padding: '4px 0' }}
            >
              Order Specs
            </FtrTypography>
            {formState.expectedPrice && (
              <div className={classes.itemField}>
                <ItemFieldText>Target Unit Price</ItemFieldText>
                <FtrTypography type='body' fontSize='14'>
                  {getCurrencySymbol(currency)}
                  {formState.expectedPrice} / unit
                </FtrTypography>
              </div>
            )}
            {formState.expectedLeadTime && (
              <div className={classes.itemField}>
                <ItemFieldText>Target Delivery Date</ItemFieldText>
                <FtrTypography type='body' fontSize='14'>
                  {getDateStrWithMonth(formState.expectedLeadTime)}
                </FtrTypography>
              </div>
            )}
          </Grid>
        )}
      </>
    );
  };

  const renderTargetUnitPriceField = () => {
    return (
      <Fragment>
        <FieldLabel id='target-unit-price-text'>Target Unit Price</FieldLabel>
        <TextField
          size='small'
          margin='none'
          type='number'
          variant='outlined'
          style={{ width: '100%' }}
          inputProps={{ min: 0 }}
          value={formState.expectedPrice}
          onChange={(evt) => {
            updateFormState({ expectedPrice: evt.target.value });
          }}
          InputProps={{
            style: {
              borderRadius: '10px',
            },
            startAdornment: (
              <InputAdornment position='start'>
                {getCurrencySymbol(currency)}
              </InputAdornment>
            ),
          }}
          onBlur={handleSave}
          disabled={waiting}
          data-cy='target-unit-price-field'
        />
      </Fragment>
    );
  };

  const renderTargetLeadTimeField = () => {
    return (
      <>
        <FieldLabel>Target Delivery Date</FieldLabel>
        <DatePicker
          InputProps={{
            style: {
              borderRadius: '10px',
            },
          }}
          disableToolbar
          value={formState.deadline ?? null}
          onChange={(deadline) => {
            updateFormState({ deadline });
          }}
          animateYearScrolling
          inputVariant='outlined'
          margin='dense'
          onBlur={handleSave}
          fullWidth
          clearable
          clearLabel='No Preference'
          emptyLabel='No Preference'
          disablePast
        />
      </>
    );
  };

  const renderQuantityField = () => {
    return (
      <Fragment>
        <FieldLabel>
          <FlexRow>
            Quantity
            {renderTDEGenImage(['qty'])}
          </FlexRow>
        </FieldLabel>
        <div style={{ position: 'relative' }}>
          {isSkeleton && renderSkeletonOverlay()}
          <TextField
            size='small'
            type='number'
            margin='none'
            value={formState.quantity}
            variant='outlined'
            inputProps={{ min: 1 }}
            InputProps={{
              style: {
                borderRadius: '10px',
              },
              inputProps: { min: 1 },
            }}
            style={{ width: '100%' }}
            onChange={(evt) => {
              let value = evt.target.value;
              updateFormState({ quantity: value });
              if (isEmptyValue(value) || Number(value) <= 0) {
                return;
              }
              removeTDEGenField(['qty']);
            }}
            // disable change value when mouse scroll
            onWheel={(e) => e.target.blur()}
            onBlur={handleSave}
            error={
              isEmptyValue(formState.quantity) ||
              Number(formState.quantity) <= 0
            }
            helperText={
              isEmptyValue(formState.quantity) ||
              Number(formState.quantity) <= 0
                ? 'Invalid value'
                : null
            }
            disabled={waiting || isSkeleton}
          />
        </div>
      </Fragment>
    );
  };

  const resetMaterialAndSurfaceFinishTDEGen = () => {
    if (!isEmptyValue(formState?.generatedFields)) {
      const updatedGeneratedFields = new Set(
        [
          formState.generatedFields.includes('unitType')
            ? 'unitType'
            : undefined,
          formState.generatedFields.includes('qty') ? 'qty' : undefined,
        ].filter((field) => field !== undefined)
      );
      updateFormState({ generatedFields: Array.from(updatedGeneratedFields) });
    }
  };

  const renderTechnologyField = () => {
    return (
      <>
        <FieldLabel>Technology</FieldLabel>
        <FtrDropdown
          key='technology-dropdown'
          fullWidth
          value={formState.technology}
          handleChange={(newTech) => {
            const technology = newTech;
            let tolerance = getDefaultTolerance({
              technology,
              unitType: formState.unitType,
            });
            let updatedFormState = {
              technology,
              tolerance,
              otherTechnology: null,
              otherMaterial: null,
              otherSurfaceFinish: null,
            };
            if (is3DPTechnology(technology)) {
              updatedFormState = {
                ...updatedFormState,
                threeDTechnology: THREE_D_P_FDM_TECH,
                threeDInfill: threeDPrintingInfillDefault,
                threeDLayerThickness: threeDPrintingLayerThicknessDefault,
              };
            } else {
              updatedFormState = {
                ...updatedFormState,
                threeDTechnology: null,
                threeDInfill: null,
                threeDLayerThickness: null,
              };
            }
            updateFormState(updatedFormState);
            resetMaterialAndSurfaceFinishTDEGen();
            technologyHasChanged(technology);
          }}
          onBlur={handleSave}
          items={technologyOptions}
          loading={isSkeleton}
          disabled={waiting || isConfigurationLoading || isSkeleton}
        />
      </>
    );
  };

  const renderCustomTechnologyField = () => {
    return (
      <>
        <FieldLabel>Custom Technology</FieldLabel>
        <div style={{ position: 'relative' }}>
          {isSkeleton && renderSkeletonOverlay()}
          <TextField
            size='small'
            type='text'
            margin='none'
            value={formState.otherTechnology}
            variant='outlined'
            InputProps={{
              style: {
                borderRadius: '10px',
              },
            }}
            style={{ width: '100%' }}
            onChange={(evt) =>
              updateFormState({ otherTechnology: evt.target.value })
            }
            disabled={waiting || isSkeleton}
            error={
              isCustomTechnology(formState.technology) &&
              isEmptyValue(formState.otherTechnology)
            }
            onBlur={handleSave}
            helperText={
              isCustomTechnology(formState.technology) &&
              isEmptyValue(formState.otherTechnology)
                ? 'Required'
                : ''
            }
          />
        </div>
      </>
    );
  };

  const renderPartNameField = () => {
    function updatePartName(partNameValue) {
      updateFormState({
        name: partNameValue,
        nameEdited: true,
      });
    }

    return (
      <PartNameField
        key={formState.name}
        partName={formState.name}
        updatePartName={updatePartName}
      />
    );
  };

  const renderSurfaceFinishField = () => {
    if (
      isEmptyValue(formState.surfaceFinish) ||
      isEmptyValue(surfaceFinishOptions)
    ) {
      return null;
    }

    return (
      <>
        <FieldLabel>
          <FlexRow>
            Surface Finish
            {renderTDEGenImage(['surfaceFinish', 'otherSurfaceFinish'])}
          </FlexRow>
        </FieldLabel>
        <FtrDropdown
          id='surface-finish-dropdown'
          key='surface-finish-dropdown'
          fullWidth
          value={formState.surfaceFinish}
          handleChange={(newValue) => {
            removeTDEGenField([
              'surfaceFinish',
              'otherSurfaceFinish',
              'color',
              'customColor',
            ]);
            updateFormState({
              surfaceFinish: newValue,
              otherSurfaceFinish: null,
              color: null,
              customColor: null,
            });
            const params = {
              technology: formState.technology,
              material: formState.material,
              surfaceFinish: newValue,
            };
            surfaceFinishHasChanged(params);
          }}
          onBlur={handleSave}
          items={surfaceFinishOptions}
          disabled={waiting || isSkeleton}
          loading={
            loadingStatus.loadSurfaceFinishOptions === true ||
            loadingStatus.loadMaterialCategoryOptions === true ||
            loadingStatus.loadThreeDMaterialOptions === true ||
            loadingStatus.loadTechnologyOptions === true ||
            isSkeleton
          }
        />
      </>
    );
  };

  const renderMaterialInputField = () => {
    return (
      <MaterialCategoriesInputField
        technology={formState.technology}
        visible={!is3DPTechnology(formState.technology)}
        value={formState.material}
        TDEGen={renderTDEGenImage(['material', 'otherMaterial'])}
        onSelect={(newValue) => {
          const params = {
            technology: formState.technology,
            threeDTechnology: formState.threeDTechnology,
            material: newValue,
          };
          resetMaterialAndSurfaceFinishTDEGen();
          updateFormState({
            material: newValue,
            otherMaterial: null,
          });
          materialHasChanged(params);
        }}
        onBlur={handleSave}
        materialCategoryOptions={materialCategoryOptions}
        disabled={
          waiting ||
          loadingStatus.loadSurfaceFinishOptions === true ||
          isSkeleton
        }
        loading={
          loadingStatus.loadMaterialCategoryOptions === true ||
          loadingStatus.loadTechnologyOptions === true ||
          isSkeleton
        }
        isBuyer={true}
      />
    );
  };

  const renderThreeDPrintingMaterialField = () => {
    return (
      <ThreeDPrintingMaterialField
        technology={formState.technology}
        threeDTechnology={formState.threeDTechnology}
        visible={is3DPTechnology(formState.technology)}
        value={formState.material}
        onSelect={(_material) => {
          updateFormState({
            material: _material,
            otherMaterial: null,
          });
          const params = {
            technology: formState.technology,
            threeDTechnology: formState.threeDTechnology,
            material: _material,
          };

          resetMaterialAndSurfaceFinishTDEGen();
          materialHasChanged(params);
        }}
        onBlur={handleSave}
        threeDMaterialOptions={threeDMaterialOptions}
        defaultThreeDMaterial={defaultThreeDMaterial}
        disabled={
          waiting ||
          loadingStatus.loadSurfaceFinishOptions === true ||
          isSkeleton
        }
        loading={loadingStatus.loadThreeDMaterialOptions === true || isSkeleton}
      />
    );
  };

  const renderCustomMaterialField = () => {
    return (
      isCustomMaterial(formState.material) &&
      !loadingStatus.loadMaterialCategoryOptions && (
        <Grid item>
          <FieldLabel>
            <FlexRow>
              Custom Material
              {renderTDEGenImage(['otherMaterial'])}
            </FlexRow>
          </FieldLabel>
          <div style={{ position: 'relative' }}>
            {isSkeleton && renderSkeletonOverlay()}
            <TextField
              size='small'
              type='text'
              margin='none'
              value={formState.otherMaterial}
              variant='outlined'
              InputProps={{
                style: {
                  borderRadius: '10px',
                },
              }}
              style={{ width: '100%' }}
              onChange={(evt) => {
                updateFormState({
                  otherMaterial: evt.target.value,
                });
              }}
              onBlur={() => {
                removeTDEGenField(['otherMaterial']);
                handleSave();
              }}
              error={
                isCustomMaterial(formState.material) &&
                isEmptyValue(formState.otherMaterial)
              }
              helperText={
                isCustomMaterial(formState.material) &&
                isEmptyValue(formState.otherMaterial)
                  ? 'Required'
                  : ''
              }
              disabled={waiting || isSkeleton}
            />
          </div>
        </Grid>
      )
    );
  };

  const renderThreeDPrintingTechnologyInputField = () => {
    return (
      <ThreeDPrintingTechnologyInputField
        visible={is3DPTechnology(formState.technology)}
        value={formState.threeDTechnology}
        onChange={(newValue) => {
          const threeDTechnology = newValue;
          const tolerance = getDefaultTolerance({
            technology: formState.technology,
            unitType: formState.unitType,
            threeDTechnology,
          });
          let updatedFormState = {
            threeDTechnology,
            tolerance,
          };
          if (newValue === THREE_D_P_FDM_TECH) {
            updatedFormState = {
              ...updatedFormState,
              threeDInfill: threeDPrintingInfillDefault,
              threeDLayerThickness: threeDPrintingLayerThicknessDefault,
            };
          } else {
            updatedFormState = {
              ...updatedFormState,
              threeDInfill: '',
              threeDLayerThickness: '',
            };
          }
          updateFormState(updatedFormState);
          const params = {
            technology: formState.technology,
            threeDTechnology: newValue,
          };
          threeDTechnologyHasChanged(params);
        }}
        onBlur={handleSave}
        threeDTechnologyOptions={threeDTechnologyOptions}
        skeletonOverlay={isSkeleton}
        disabled={
          waiting ||
          loadingStatus.loadThreeDMaterialOptions === true ||
          loadingStatus.loadSurfaceFinishOptions === true ||
          isSkeleton
        }
        loading={
          loadingStatus.load3DTechnologyOptions === true ||
          loadingStatus.loadTechnologyOptions === true
        }
      />
    );
  };

  const renderCustomSurfaceFinishField = () => {
    return (
      !isEmptyValue(surfaceFinishOptions) &&
      isCustomSurfaceFinish(formState.surfaceFinish) &&
      loadingStatus.loadSurfaceFinishOptions !== true && (
        <Grid item>
          <FieldLabel>
            <FlexRow>
              Custom Surface Finish
              {renderTDEGenImage(['otherSurfaceFinish'])}
            </FlexRow>
          </FieldLabel>
          <div style={{ position: 'relative' }}>
            {isSkeleton && renderSkeletonOverlay()}
            <TextField
              size='small'
              type='text'
              margin='none'
              value={formState.otherSurfaceFinish}
              variant='outlined'
              InputProps={{
                style: {
                  borderRadius: '10px',
                },
              }}
              style={{ width: '100%' }}
              onChange={(evt) => {
                updateFormState({
                  otherSurfaceFinish: evt.target.value,
                });
              }}
              onBlur={() => {
                removeTDEGenField(['otherSurfaceFinish']);
                handleSave();
              }}
              error={
                isCustomSurfaceFinish(formState.surfaceFinish) &&
                isEmptyValue(formState.otherSurfaceFinish)
              }
              helperText={
                isCustomSurfaceFinish(formState.surfaceFinish) &&
                isEmptyValue(formState.otherSurfaceFinish)
                  ? 'Required'
                  : ''
              }
              disabled={waiting || isSkeleton}
            />
          </div>
        </Grid>
      )
    );
  };

  const renderFinishColorField = () => {
    if (
      isEmptyValue(surfaceFinishOptions) ||
      isCustomSurfaceFinish(formState.surfaceFinish) ||
      isEmptyValue(surfaceFinishColorOptions) ||
      isEmptyValue(formState.color)
    ) {
      return null;
    }

    return (
      <Grid item>
        <FieldLabel>
          <FlexRow>
            Finish Color
            {renderTDEGenImage(['color', 'customColor'])}
          </FlexRow>
        </FieldLabel>
        <ColorFtrDropdown
          key='surface-finish-color-dropdown'
          fullWidth
          value={formState.color}
          handleChange={(newValue) => {
            const color = newValue || formState.color;
            removeTDEGenField(['color', 'customColor']);
            updateFormState({
              color,
              customColor: null,
            });
          }}
          onBlur={handleSave}
          colorPalette={surfaceFinishColorOptions}
          disabled={waiting || isSkeleton}
          loading={isSkeleton}
        />
      </Grid>
    );
  };

  const renderCustomFinishColorField = () => {
    if (
      (isEmptyValue(surfaceFinishOptions) &&
        !isCustomSurfaceFinish(formState.surfaceFinish)) ||
      isEmptyValue(surfaceFinishColorOptions) ||
      (formState.color !== 'Custom Color' &&
        isEmptyValue(formState.customColor))
    ) {
      return;
    }

    return (
      <Grid item>
        <FieldLabel>
          <FlexRow>
            Custom Finish Color
            {renderTDEGenImage(['customColor'])}
          </FlexRow>
        </FieldLabel>
        <div style={{ position: 'relative' }}>
          {isSkeleton && renderSkeletonOverlay()}
          <TextField
            size='small'
            type='text'
            margin='none'
            placeholder='e.g. Yellow orange (RAL 2000)'
            value={formState.customColor}
            variant='outlined'
            InputProps={{
              style: {
                borderRadius: '10px',
              },
            }}
            style={{ width: '100%' }}
            onChange={(evt) => {
              const color = evt.target.value;
              updateFormState({
                customColor: color,
              });
            }}
            onBlur={() => {
              removeTDEGenField(['customColor']);
              handleSave();
            }}
            error={
              formState.color === 'Custom Color' &&
              isEmptyValue(formState.customColor)
            }
            helperText={
              formState.color === 'Custom Color' &&
              isEmptyValue(formState.customColor)
                ? 'Required'
                : ''
            }
            disabled={waiting || isSkeleton}
          />
        </div>
        <Typography
          variant='body2'
          color='textSecondary'
          style={{ fontStyle: 'italic', fontSize: 'smaller' }}
        >
          Refer{' '}
          <a
            href='https://www.ralcolor.com/'
            target='_blank'
            rel='noopener noreferrer'
          >
            https://www.ralcolor.com/
          </a>{' '}
          for more colors.
        </Typography>
      </Grid>
    );
  };

  const renderMaterialColorField = () => {
    if (
      (isEmptyValue(materialCategoryOptions) &&
        isCustomMaterial(formState.material)) ||
      isEmptyValue(materialColorOptions) ||
      isEmptyValue(formState.materialColor)
    ) {
      return null;
    }

    return (
      <Grid item>
        <FieldLabel>
          <FlexRow>
            Material Color
            {renderTDEGenImage(['materialColor', 'customMaterialColor'])}
          </FlexRow>
        </FieldLabel>
        <ColorFtrDropdown
          key='material-color-dropdown'
          fullWidth
          value={formState.materialColor}
          handleChange={(newValue) => {
            const materialColor = newValue;
            updateFormState({
              materialColor,
            });
            removeTDEGenField(['materialColor', 'customMaterialColor']);
          }}
          onBlur={handleSave}
          colorPalette={materialColorOptions || []}
          disabled={waiting || isSkeleton}
          loading={isSkeleton}
        />
      </Grid>
    );
  };

  const renderCustomMaterialColorField = () => {
    if (
      (isEmptyValue(materialCategoryOptions) &&
        isCustomMaterial(formState.material)) ||
      isEmptyValue(materialColorOptions) ||
      formState.materialColor !== 'Custom Color'
    ) {
      return null;
    }

    return (
      <Grid item>
        <FieldLabel>
          <FlexRow>
            Custom Material Color
            {renderTDEGenImage(['customMaterialColor'])}
          </FlexRow>
        </FieldLabel>
        <div style={{ position: 'relative' }}>
          {isSkeleton && renderSkeletonOverlay()}
          <TextField
            size='small'
            type='text'
            margin='none'
            placeholder='e.g. Yellow orange (RAL 2000)'
            value={formState.customMaterialColor}
            variant='outlined'
            InputProps={{
              style: {
                borderRadius: '10px',
              },
            }}
            style={{ width: '100%' }}
            onChange={(evt) => {
              const materialColor = evt.target.value;
              updateFormState({
                customMaterialColor: materialColor,
              });
            }}
            onBlur={() => {
              removeTDEGenField(['customMaterialColor']);
              handleSave();
            }}
            error={
              formState.materialColor === 'Custom Color' &&
              isEmptyValue(formState.customMaterialColor)
            }
            helperText={
              formState.materialColor === 'Custom Color' &&
              isEmptyValue(formState.customMaterialColor)
                ? 'Required'
                : ''
            }
            disabled={waiting || isSkeleton}
          />
        </div>
        <Typography
          variant='body2'
          color='textSecondary'
          style={{ fontStyle: 'italic', fontSize: 'smaller' }}
        >
          Refer{' '}
          <a
            href='https://www.ralcolor.com/'
            target='_blank'
            rel='noopener noreferrer'
          >
            https://www.ralcolor.com/
          </a>{' '}
          for more colors.
        </Typography>
      </Grid>
    );
  };

  const renderThreeDInfillField = () => {
    return (
      <ThreeDInfillSelectField
        visible
        value={formState.threeDInfill}
        onSelect={(newValue) => {
          updateFormState({
            threeDInfill: newValue,
          });
        }}
        onBlur={handleSave}
        ppe3dpInfillOptions={ppe3dpInfillOptions}
        disabled={waiting}
      />
    );
  };

  const renderThreeDLayerThicknessField = () => {
    return (
      <ThreeDLayerThicknessField
        visible
        value={formState.threeDLayerThickness}
        onSelect={(newValue) => {
          updateFormState({
            threeDLayerThickness: newValue,
          });
        }}
        onBlur={handleSave}
        disabled={waiting}
      />
    );
  };

  const renderToleranceField = () => {
    return (
      <>
        <FlexRow>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <FieldLabel>Tolerance&nbsp;</FieldLabel>
            <Tooltip
              title='Please indicate tightest tolerance in your design.'
              placement='right'
            >
              <img src={InfoIcon} alt='info-icon' />
            </Tooltip>
          </div>
          {renderTDEGenImage(['tolerance'])}
        </FlexRow>
        <FtrDropdown
          key='tolerance-dropdown'
          fullWidth
          value={
            formState.unitType === UNIT_TYPES.IMPERIAL
              ? convertMetricToImperial(formState.tolerance)
              : formState.tolerance
          }
          handleChange={(value) => {
            let tolerance = value;
            removeTDEGenField(['tolerance']);
            if (formState.unitType === UNIT_TYPES.IMPERIAL) {
              tolerance = convertImperialToMetric(tolerance);
            }
            updateFormState({ tolerance });
          }}
          items={STANDARD_TOLERANCE_OPTIONS[formState.unitType]}
          renderValue={(selected) => {
            // selected value here is the converted value
            const value = `±${`${selected}${
              DISPLAY_UNIT_OPTIONS[formState.unitType]?.LENGTH
            }`}`;
            return value;
          }}
          onBlur={handleSave}
          disabled={waiting || isSkeleton}
          loading={isSkeleton}
        />
      </>
    );
  };

  const renderTechnicalDrawingUploadArea = () => {
    return (
      <>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <FieldLabel>Technical Drawings&nbsp;</FieldLabel>
          <Tooltip
            title='Please upload complementing 2D files/technical drawings to your parts here. This will help us ensure that we don’t miss out on any component details.'
            placement='right'
          >
            <img src={InfoIcon} alt='info-icon' />
          </Tooltip>
        </div>
        <Box
          style={{
            borderRadius: '10px',
            border: `1px dashed ${colors.neutral050}`,
          }}
        >
          <FlexRowSpaceBetween
            style={{
              alignItems: 'start',
              padding: '0.7rem',
              gap: '1rem',
            }}
          >
            <FlexColumn>
              {drawingFiles?.length === 0 && <TechnicalFileNameDisplay />}
              {drawingFiles?.map((file) => {
                return (
                  <TechnicalFileNameDisplay
                    key={file}
                    file={file}
                    item={item}
                    onDelete={(file) => {
                      updateFormState({
                        originalFiles: formState.originalFiles
                          ?.split(',')
                          .filter((f) => f !== file)
                          .join(','),
                      });
                      handleSaveWithDelay();
                    }}
                  />
                );
              })}
            </FlexColumn>
            <FtrUploadFileSmallButton
              accept='.pdf, image/*'
              handleFiles={handleUploadTechnicalDrawing}
              disabled={waiting}
            >
              Upload
            </FtrUploadFileSmallButton>
          </FlexRowSpaceBetween>
        </Box>
      </>
    );
  };

  const renderRemarksField = () => {
    return (
      (isAddingRemarks || formState.description) && (
        <FlexColumn style={{ gap: 0 }}>
          <FtrTextField
            style={{
              width: '100%',
              marginBottom: '0.5rem',
            }}
            disableUnderline
            multiline
            minRows={3}
            value={formState.description}
            onChange={(evt) => {
              const description = evt.target.value;
              updateFormState({
                description,
                remarksSaved: false,
              });
            }}
            onBlur={handleSave}
          />
          <div
            className={clsx(
              classes.hide,
              formState.remarksSaved ? classes.fadeIn : null
            )}
          >
            <FtrLightText
              fontSize='13'
              style={{
                textAlign: 'end',
                width: '100%',
                color: colors.blue050,
              }}
            >
              Changes saved
            </FtrLightText>
          </div>
        </FlexColumn>
      )
    );
  };

  const renderDfmAnalysisButton = () => {
    const cadFile = formState.cadFile || formState.originalFiles;
    const isDfmAnalysisValid = isSourceUrlAndTechValid(
      cadFile.split(','),
      formState.technology
    );
    const isDefectEmptyAndInvalid =
      isEmptyValue(formState.dfmDefectOutput) && !isDfmAnalysisValid;
    if (isDefectEmptyAndInvalid || !showDfmPueMpr(user.dfmType)) {
      return null;
    }
    if (!isEmptyValue(formState?.dfmDefectOutput)) {
      return (
        <DfmAnalysisButton
          showIcon
          dfmDefectOutput={
            formState.dfmDefectOutput.defects ?? formState.dfmDefectOutput
          }
          isLoading={formState.dfmDefectOutput === 'loading'}
          cadFile={formState.cadFile || formState.originalFiles}
          twoDImageUrl={formState.imageFile || formState.twoDImageUrl}
          itemID={formState.itemID}
        />
      );
    }
    if (isDfmAnalysisValid) {
      return (
        <DfmAnalysisButton
          onClick={(event) => {
            event.preventDefault();
            event.stopPropagation();
            handleGetDfmAnalysis(formState);
          }}
          isLoading={loadingDfmApi}
          disabled={loadingDfmApi}
          style={{
            paddingLeft: '5px',
          }}
          text='Get DFM Analysis'
        />
      );
    }
  };

  return (
    <Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth='md'
        fullWidth
        classes={{ paper: classes.paper }}
        scroll='paper'
        fullScreen={isMobile}
        style={{
          cursor: waiting === true ? 'wait' : null,
        }}
      >
        <DialogContent>
          <Grid container direction='row'>
            <Grid item xs={12} sm={7} className={classes.partSpecLeftSide}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  padding: '0.5rem 0',
                }}
              >
                <FtrButton
                  variant='text'
                  color='blue'
                  style={{
                    padding: 'inherit',
                    cursor: waiting === true ? 'wait' : null,
                  }}
                  onClick={handleClose}
                >
                  <FtrTypography
                    type='heading'
                    fontSize='16'
                    style={{
                      color:
                        !isFormValid || isFormLoading
                          ? colors.neutral050
                          : colors.blue050,
                    }}
                    id='return-to-quote-btn'
                  >
                    ← Back
                  </FtrTypography>
                </FtrButton>
              </div>
              <div className={classes.container}>
                <FtrTypography type='heading' fontSize='22'>
                  Part Specifications
                </FtrTypography>
                <FlexRow>
                  <ImperialMetricSwitchV2
                    key={formState.unitType}
                    selectedUnitType={formState.unitType}
                    onChange={handleChangeUnitType}
                    name='imperial-metric-switch'
                  />
                  {renderTDEGenImage(['unitType'])}
                </FlexRow>
              </div>
              {isSkeleton && (
                <div style={{ textAlign: 'right' }}>
                  <Typography
                    variant='subtitle2'
                    style={{ color: colors.neutral060 }}
                  >
                    Extracting Information from your technical drawing
                  </Typography>
                </div>
              )}
              <Grid container direction='column' spacing={2}>
                <Grid item>{renderQuantityField()}</Grid>
                <Grid item>{renderTechnologyField()}</Grid>
                {is3DPTechnology(formState.technology) && (
                  <Grid item>{renderThreeDPrintingTechnologyInputField()}</Grid>
                )}
                {isCustomTechnology(formState.technology) && (
                  <Grid item>{renderCustomTechnologyField()}</Grid>
                )}
                {!is3DPTechnology(formState.technology) && (
                  <Grid item>{renderMaterialInputField()}</Grid>
                )}
                {is3DPTechnology(formState.technology) && (
                  <Grid item>{renderThreeDPrintingMaterialField()}</Grid>
                )}
                {renderCustomMaterialField()}
                {/* No custom material. Material color options are available. */}
                {renderMaterialColorField()}
                {/* No custom material. Material color options are available. */}
                {renderCustomMaterialColorField()}
                {is3DPTechnology(formState.technology) &&
                  formState.threeDTechnology === THREE_D_P_FDM_TECH && (
                    <Fragment>
                      <Grid item>{renderThreeDInfillField()}</Grid>
                      <Grid item>{renderThreeDLayerThicknessField()}</Grid>
                    </Fragment>
                  )}
                <Grid item>{renderSurfaceFinishField()}</Grid>
                {renderCustomSurfaceFinishField()}
                {/* No custom surface. Surface finish color options are available. */}
                {renderFinishColorField()}
                {/* No custom surface. Surface finish color options are available. */}
                {renderCustomFinishColorField()}
                <Grid item>{renderToleranceField()}</Grid>
                <Grid item>{renderTechnicalDrawingUploadArea()}</Grid>
                <Grid item>{renderDfmAnalysisButton()}</Grid>
                <Grid item>
                  <FieldLabel>Have additional requirements?</FieldLabel>
                  <Button
                    variant='text'
                    disableFocusRipple
                    style={{
                      color: colors.blue050,
                      textTransform: 'none',
                      fontWeight: 700,
                      fontSize: '14px',
                    }}
                    onClick={() => {
                      if (isAddingRemarks) {
                        updateFormState({
                          description: '',
                          remarksSaved: false,
                        });
                        setIsAddingRemarks(false);
                      } else {
                        setIsAddingRemarks(true);
                      }
                    }}
                  >
                    {isAddingRemarks || formState.remarks
                      ? '-  Remove remarks'
                      : '+  Add custom requirements'}
                  </Button>
                  <Box style={{ height: '0.5rem' }} />
                  {renderRemarksField()}
                </Grid>
              </Grid>
              <FtrTypography
                type='heading'
                fontSize='18'
                style={{ padding: '1rem 0' }}
              >
                Order Specifications
              </FtrTypography>
              <Grid container direction='column' spacing={2}>
                <Grid item>{renderTargetUnitPriceField()}</Grid>
                <Grid item>{renderTargetLeadTimeField()}</Grid>
              </Grid>
            </Grid>
            {!isMobile && (
              <Divider
                flexItem
                orientation='vertical'
                style={{ marginRight: '-1px' }}
              />
            )}

            <Grid
              item
              xs={12}
              sm={5}
              style={{ padding: isMobile ? 'auto' : '2rem 1rem 0 2rem' }}
            >
              <Grid container direction='column' spacing={2}>
                <Grid item xs={12}>
                  {render2DImage()}
                </Grid>
                <Grid item xs={12}>
                  <FlexRowSpaceBetween style={{ width: '100%' }}>
                    <div
                      style={{
                        flexGrow: '1 auto',
                      }}
                    >
                      {renderPartNameField()}
                    </div>
                    <FtrSmallButton
                      style={{
                        minWidth: 100,
                        justifySelf: 'end',
                      }}
                      color='blue'
                      startIcon={<Refresh />}
                      onClick={() => setShowReplacePartPopup(true)}
                      disabled={waiting}
                    >
                      Replace
                    </FtrSmallButton>
                  </FlexRowSpaceBetween>
                </Grid>
              </Grid>
              <Divider style={{ margin: '1rem 0' }} />
              {renderPartSpecsSummary()}
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
      {showReplacePartPopup && (
        <ReplacePartPopup
          open={showReplacePartPopup}
          technology={item.technology}
          handleCadFilesChange={handleReplaceCadFile}
          onClose={() => setShowReplacePartPopup(false)}
        />
      )}
    </Fragment>
  );
}

export default ConfigureEditRfqPartForm;
