import React from 'react';
import { Button } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { colors } from '../../palette';

import { Tooltip } from '@material-ui/core';

const GreenStyleButton = withStyles({
  root: {
    backgroundColor: colors.greenTagColor,
    color: 'white',
    borderRadius: 0,
    padding: '16px 20px',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: colors.blue050,
    },
    '&:disabled': {
      backgroundColor: 'white',
    },
  },
})(Button);

function GreenButton(props) {
  const { text = 'Button', onClick, disabled, id, tooltipTitle } = props;
  return tooltipTitle ? (
    <Tooltip title={tooltipTitle}>
      <GreenStyleButton
        data-cy={id}
        fullWidth
        onClick={onClick}
        disabled={disabled}
      >
        {text}
      </GreenStyleButton>
    </Tooltip>
  ) : (
    <GreenStyleButton
      data-cy={id}
      fullWidth
      onClick={onClick}
      disabled={disabled}
    >
      {text}
    </GreenStyleButton>
  );
}

export default GreenButton;
