import { get } from 'lodash';

import { isEmptyValue } from './commonUtils';
import { extractPriceFromCurrencyString } from './currencyUtils';

import { COUNTRY_NAMES } from '../constants/countryConstants';

// -------------------------------------------------------------------------------------------------

export const undeliveredItemsFilter = (item) => item.delivered !== true;

export const uncollectedItemsFilter = (item) =>
  item.delivered === true && item.collected !== true;

export const isMctItemCollected = (item) => item.delivered && item.collected;

export const unshippedItemsFilter = (item) =>
  item.collected === true && item.shipItem !== true;

export const unInvoicedItemsFilter = (item) => item.shipItem && !item.invoiced;

export const generateDeliveryMessage = ({
  selectedItemsIDs,
  trackingInfo,
  pickUpUserInfo,
  dropOffUserInfo,
  isPackagingRequired,
  isQCRequired,
  isShippingToCustomer,
  isDHLInternationalShipping,
  isDescriptionRequired,
  invoiceAmountStr = '',
}) => {
  if (
    isEmptyValue(trackingInfo) ||
    isEmptyValue(dropOffUserInfo) ||
    isEmptyValue(pickUpUserInfo)
  ) {
    return '';
  }
  const currentDate = new Date();
  const options = { day: 'numeric', month: 'long', year: 'numeric' };
  const displayDate = new Date(currentDate);
  displayDate.setDate(currentDate.getDate() + 1);
  const displayDateStr = displayDate.toLocaleDateString('en-GB', options);
  const isOverseas = dropOffUserInfo.country !== COUNTRY_NAMES.SINGAPORE;

  let message = `${trackingInfo.referenceName}: ${selectedItemsIDs.join(
    ', '
  )} (${selectedItemsIDs.length}/${trackingInfo?.acceptedItemsInfo.length})

_____________________________
*PICK UP*
Location:
${pickUpUserInfo.companyName}
${pickUpUserInfo.address || '*No address found!*'}

Date / Time Range: ${displayDateStr}, after 9am
POC: ${pickUpUserInfo.name}
Contact: ${pickUpUserInfo.contact}

_____________________________
${isOverseas && isShippingToCustomer ? '*Overseas Shipment*' : '*DROP OFF*'}
Location:
${dropOffUserInfo.companyName}
${dropOffUserInfo.address || '*No address found!*'}

Date / Time Range: ${displayDateStr}, by 5pm
POC: ${dropOffUserInfo.name}
Contact: ${dropOffUserInfo.contact}
`;

  if (isDHLInternationalShipping || isDescriptionRequired) {
    message += `\nNotes:
`;
    if (isDHLInternationalShipping) {
      message += `*DHL International Shipping
`;
    }

    if (isDescriptionRequired) {
      message += `*Description:
`;
    }
  }

  message += `\n_____________________________
${isPackagingRequired ? '*' : ''}Packaging: ${
    isPackagingRequired ? 'Required' : 'Not Required'
  }${isPackagingRequired ? '*' : ''}
`;

  message += `${isQCRequired ? '*' : ''}Quality Check: ${
    isQCRequired ? 'Required' : 'Not Required'
  }${isQCRequired ? '*' : ''}
`;

  if (isOverseas && isShippingToCustomer) {
    message += `*Invoice Amount: ${invoiceAmountStr}*
`;
  }

  return message;
};

export const getTotalPriceBeforeGst = (mctPriceDetails) => {
  const {
    totalCost: totalCostStr,
    deliveryFee: deliveryFeeStr,
    qcReportFeeStr,
    platformFee,
  } = mctPriceDetails;
  const [currencySymbol] = totalCostStr.split(' ');
  const deliveryFee = extractPriceFromCurrencyString(deliveryFeeStr);
  const qcReportFee =
    qcReportFeeStr && qcReportFeeStr.split(' ').length > 1
      ? extractPriceFromCurrencyString(qcReportFeeStr)
      : 0;
  const total =
    extractPriceFromCurrencyString(totalCostStr) +
    Number(deliveryFee) +
    Number(qcReportFee) +
    Number(platformFee || 0);
  return `${currencySymbol} ${total.toFixed(2)}`;
};

export const getDiscountAmount = (multiCheckout) => {
  const totalPriceBeforeGst = getTotalPriceBeforeGst(
    multiCheckout.priceDetails
  );
  const [unitPrice] = totalPriceBeforeGst.split(' ');
  const percentOff = get(multiCheckout, [
    'stripeDiscountInfo',
    'coupon',
    'percent_off',
  ]);

  if (isNaN(+percentOff) || +percentOff <= 0) {
    return 'N.A.';
  }

  const totalPriceBeforeGstNumber = extractPriceFromCurrencyString(
    totalPriceBeforeGst
  );
  const discountAmount = (
    (totalPriceBeforeGstNumber * percentOff) /
    100
  ).toFixed(2);
  return `-${unitPrice} ${discountAmount}`;
};

export const getStripePromoCodeDetails = (multiCheckout) => {
  const couponName = get(multiCheckout, [
    'stripeDiscountInfo',
    'coupon',
    'name',
  ]);

  if (isEmptyValue(couponName)) {
    return 'N.A.';
  }

  const arr = couponName.split('_');
  const promotionCode = arr.slice(0, arr.length - 3).join('_');
  const percentOff = get(multiCheckout, [
    'stripeDiscountInfo',
    'coupon',
    'percent_off',
  ]);

  return {
    promotionCode,
    percentOff,
    discountAmount: getDiscountAmount(multiCheckout),
  };
};

export const getFactoremCustomCodeDetails = (multiCheckout) => {
  const stripeDiscountInfo = multiCheckout.stripeDiscountInfo || {};
  const priceDetails = multiCheckout.priceDetails || {};
  const promotionCode = get(stripeDiscountInfo, ['coupon', 'name']);
  const percentOff =
    get(stripeDiscountInfo, ['coupon', 'actualDiscountPercent']) || 0;

  const discountAmount = `-${priceDetails.totalDiscountStr}`;

  return {
    promotionCode,
    percentOff,
    discountAmount,
  };
};

export const getPromotionCodeDetailsForMctTable = (multiCheckout) => {
  const couponName = get(multiCheckout, [
    'stripeDiscountInfo',
    'coupon',
    'name',
  ]);

  if (isEmptyValue(couponName)) {
    return 'N.A.';
  }

  const stripeDiscountInfo = multiCheckout.stripeDiscountInfo || {};

  if (stripeDiscountInfo.isFactoremCustomCode === true) {
    return getFactoremCustomCodeDetails(multiCheckout);
  }

  return getStripePromoCodeDetails(multiCheckout);
};
