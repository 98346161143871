/**
  Logic is described in this ticket https://trello.com/c/aYP6eDEC/2197-epic-part-upload-revamp

  Lead Time Display logic, a lead time is displayed IF all items that are selected were instant quoted.

  Case 1: If >=1 PPE items are non-repeat orders

  Display a range 7 - 16 working days, avg. 11 working days
  Case 2A: If only 1 item is selected and it is a repeat order

  Display x working days, x is based on PPE output, do not display average
  Case 2B: If >1 items are selected and they are all repeat orders

  If the lead time for the items selected are different, show min - max working days, avg working days = rounddown[(max + min) / 2]
  If the lead time for the items selected are the same, show x working days, do not display average
  Case 3: If >1 items are selected and they are a mix of repeat orders and non-repeat orders

  Display a range with min - max working days, avg working days = rounddown[(max + min) / 2]

 * @param {*} selectedItems 
 * @returns 
 */
export const getMinMaxLeadTimeFromItems = (selectedItems) => {
  return selectedItems.reduce(
    (acc, item) => {
      let itemMinLeadTime, itemMaxLeadTime;
      if (item.leadTime) {
        itemMinLeadTime = itemMaxLeadTime =
          Number(item.leadTime) + Number(item.markupLeadTime);
      } else {
        itemMinLeadTime = 7;
        itemMaxLeadTime = 16;
      }
      if (acc.minLeadTime === null) {
        acc.minLeadTime = itemMinLeadTime;
        acc.maxLeadTime = itemMaxLeadTime;
        return acc;
      }
      if (itemMaxLeadTime > acc.maxLeadTime) {
        acc.maxLeadTime = itemMaxLeadTime;
      }
      if (itemMinLeadTime < acc.minLeadTime) {
        acc.minLeadTime = itemMinLeadTime;
      }
      return acc;
    },
    {
      minLeadTime: null,
      maxLeadTime: null,
    }
  );
};
