// Import settings
import React, { useEffect } from 'react';

// Import customised components
import OrderDetails from '../OrderDetails';
import SubDesc from '../SubDesc';
import Title from '../Title';

// Import colors
import { colors } from '../../palette';

// Import material UI components
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Chip,
} from '@material-ui/core';
import {
  Close as CloseIcon,
  HourglassEmpty as HourglassEmptyIcon,
} from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles/index';
import DoneIcon from '@material-ui/icons/Done';

// Style the components
const useStyles = makeStyles((theme) => ({
  paper: {
    minWidth: 800,
    borderRadius: 0,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  pendingChip: {
    background: colors.pendingLightYellow,
  },
  successChip: {
    background: colors.successLightGreen,
  },
}));

function OrderDetailsPopup(props) {
  const classes = useStyles();
  const { item, dialog, handleClose } = props;

  // Refresh states for item and quotation to be approved
  useEffect(() => {}, [item]);

  return (
    <Dialog
      maxWidth='xl'
      open={dialog}
      onClose={handleClose}
      aria-labelledby='confirmation-dialog-title'
      classes={{ paper: classes.paper }}
    >
      {item ? (
        <div style={{ padding: '35px 0 50px' }}>
          <DialogTitle id='confirmation-dialog-title'>
            <SubDesc content={`Item #${item.itemID}`} />
            <Title
              contentTitle={[
                item.name,
                <span
                  key={'paid'}
                  style={{ display: 'inherit', marginTop: 15 }}
                >
                  {/* hardcoded key so compiler wouldn't complain */}
                  {item.paymentStatus === 'paid' ? (
                    <Chip
                      className={classes.successChip}
                      label='Payment Received'
                      icon={<DoneIcon />}
                    />
                  ) : (
                    <React.Fragment></React.Fragment>
                  )}
                </span>,
                <span
                  key={'pending'}
                  style={{ display: 'inherit', marginTop: 15 }}
                >
                  {item.paymentStatus === 'pending' ? (
                    <Chip
                      className={classes.pendingChip}
                      label='Payment Pending'
                      icon={<HourglassEmptyIcon />}
                    />
                  ) : (
                    <React.Fragment></React.Fragment>
                  )}
                </span>,
              ]}
            />
          </DialogTitle>
          <DialogContent>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                padding: '0 55px',
              }}
            >
              <OrderDetails partInfo={item} />
            </div>
          </DialogContent>
        </div>
      ) : (
        <div style={{ padding: '35px 0 50px' }}>
          <div>Please try again.</div>
        </div>
      )}
      {dialog ? (
        <IconButton
          aria-label='close'
          className={classes.closeButton}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </Dialog>
  );
}

export default OrderDetailsPopup;
