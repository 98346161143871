import React from 'react';

import PricingSummaryDisplayPresentational from './PricingSummaryDisplayPresentational';

// --------------------------------------------------------------------------

function PricingSummaryDisplay(props) {
  const {
    shippingCost,
    subTotal = 0,
    currency,
    exchangeRate,
    qcReportFee = 0,
    gst,
    gstValue = 0,
    shippingTooltip = '',
    estimatedTotal = 0,
  } = props;

  return (
    <PricingSummaryDisplayPresentational.Container>
      <PricingSummaryDisplayPresentational.ConvertedPriceSubRow
        title='Subtotal'
        price={subTotal}
        currency={currency}
        exchangeRate={exchangeRate ?? 1}
      />
      <PricingSummaryDisplayPresentational.ConvertedPriceSubRow
        title='Shipping fees'
        price={shippingCost}
        currency={currency}
        exchangeRate={exchangeRate ?? 1}
        tooltip={shippingTooltip}
      />
      {qcReportFee > 0 && (
        <PricingSummaryDisplayPresentational.ConvertedPriceSubRow
          title='Certification'
          price={qcReportFee}
          currency={currency}
          exchangeRate={exchangeRate ?? 1}
        />
      )}
      {gstValue !== 0 && (
        <PricingSummaryDisplayPresentational.ConvertedPriceSubRow
          title={`GST (${gst * 100}%)`}
          price={gstValue}
          currency={currency}
          exchangeRate={exchangeRate ?? 1}
        />
      )}
      <PricingSummaryDisplayPresentational.ConvertedPriceTotalRow
        title='Estimated Total'
        price={estimatedTotal}
        currency={currency}
        exchangeRate={exchangeRate ?? 1}
      />
    </PricingSummaryDisplayPresentational.Container>
  );
}

export default PricingSummaryDisplay;
