import {
  SET_PART_UPLOAD_REVAMP,
  TOGGLE_PART_UPLOAD_REVAMP,
  UPDATE_CUSTOMER_SETTINGS,
} from '../actions/customerSettings';

// --------------------------------------------------------------------------------------------

const defaultState = {
  partUploadRevamp: true,
  seePromoCode: 1,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case TOGGLE_PART_UPLOAD_REVAMP:
      return {
        ...state,
        partUploadRevamp: !state.partUploadRevamp,
      };
    case SET_PART_UPLOAD_REVAMP:
      return {
        ...state,
        partUploadRevamp: action.payload,
      };
    case UPDATE_CUSTOMER_SETTINGS:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
