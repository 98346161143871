export const convertCm2ToMm2 = (value) =>
  !isNaN(+value) && typeof value === 'number' ? value * 100 : null;

export const convertCm3ToMm3 = (value) =>
  !isNaN(+value) && typeof value === 'number' ? value * 1000 : null;

export const convertMm2ToCm2 = (value) =>
  !isNaN(+value) && typeof value === 'number' ? value / 100 : null;

export const convertMm3ToCm3 = (value) =>
  !isNaN(+value) && typeof value === 'number' ? value / 1000 : null;
