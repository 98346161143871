import { useCallback, useEffect, useRef, useState } from 'react';
import * as fabric from 'fabric';

import { isEmptyValue } from '../utils/commonUtils';
import { notifyWarning } from '../services/notificationService';

// -------------------------------------------------------------------------------------------------

function useAutoBalloonCanvas() {
  const [canvas, setCanvas] = useState(null);

  const [wrapperDimensions, setWrapperDimensions] = useState({
    width: 0,
    height: 0,
  });
  const [pageImageRatioMapping, setPageImageRatioMapping] = useState({});

  const [imageUrls, setImageUrls] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);

  const canvasImageMapping = {};

  let isDrawing = false; // Flag to track drawing state
  let rect; // Variable to hold the current rectangle
  let startX, startY; // Starting coordinates

  useEffect(() => {
    if (isEmptyValue(canvas)) {
      return;
    }

    // Mouse down: Start drawing
    canvas.on('mouse:down', function(event) {
      const pointer = canvas.getPointer(event.e);
      startX = pointer.x;
      startY = pointer.y;

      if (event.target === canvas) {
        canvas.discardActiveObject().renderAll();
      }

      const activeObject = canvas.getActiveObject();

      if (activeObject) {
        isDrawing = false;
        return;
      }

      isDrawing = true;

      // Create a rectangle at the starting position
      rect = new fabric.Rect({
        left: startX,
        top: startY,
        width: 0,
        height: 0,
        fill: 'rgba(0, 0, 255, 0.3)', // Semi-transparent fill
        stroke: 'blue', // Border color
        strokeWidth: 2,
        selectable: true, // Allow selection after drawing
      });

      canvas.add(rect);
    });

    // Mouse move: Update rectangle dimensions
    canvas.on('mouse:move', function(event) {
      if (!isDrawing) {
        return;
      }

      const pointer = canvas.getPointer(event.e);
      const width = pointer.x - startX;
      const height = pointer.y - startY;

      rect.set({
        width: Math.abs(width),
        height: Math.abs(height),
        left: width < 0 ? pointer.x : startX,
        top: height < 0 ? pointer.y : startY,
      });

      canvas.renderAll(); // Re-render the canvas to show updates
    });

    // Mouse up: Finish drawing
    canvas.on('mouse:up', function() {
      isDrawing = false;
      const allRects = getRectangles();
      if (isEmptyValue(allRects)) {
        return;
      }

      const minWidth = 15;

      let shouldNotifyWarning = false;
      const rectToRemove = [];
      for (const rectangle of allRects) {
        if (
          rectangle &&
          (rectangle.width < minWidth || rectangle.height < minWidth)
        ) {
          rectToRemove.push(rectangle);
          shouldNotifyWarning =
            shouldNotifyWarning ||
            (rectangle.width > 3 && rectangle.height > 3);
        }
      }

      if (isEmptyValue(rectToRemove)) {
        return;
      }

      if (shouldNotifyWarning) {
        notifyWarning(
          'The balloon rectangle is too small, please adjust the size of the balloon!'
        );
      }

      rectToRemove.forEach((rect) => {
        canvas.remove(rect);
      });

      canvas.renderAll();
    });

    // Key press: Delete selected rectangle
    document.addEventListener('keydown', function(event) {
      if (event.key === 'Delete' || event.key === 'Backspace') {
        // Get the active object
        const activeObject = canvas.getActiveObject();

        if (activeObject) {
          canvas.remove(activeObject); // Remove the selected rectangle
          canvas.discardActiveObject(); // Deselect the object
          canvas.renderAll(); // Re-render the canvas
        }
      }
    });
  }, [canvas]);

  function getRectangles() {
    if (!canvas) {
      return [];
    }

    return canvas.getObjects('rect');
  }

  const initCanvas = (node) => {
    const option = {
      isDrawingMode: false,
      backgroundColor: 'rgba(0,0,0,0)',
      width: 1200,
      height: 800,
    };

    if (canvas) {
      canvas.dispose(); // Dispose the existing Fabric.js canvas
    }

    const newCanvas = new fabric.Canvas(node, option);
    newCanvas?.requestRenderAll();
    setCanvas(newCanvas);
  };

  // all the canvas and page refs
  const pdfCanvasRef = useRef(null);
  const printableRef = useRef(null);
  const canvasWrapperRef = useRef(null);

  const overlayCanvasRef = useCallback(
    (node) => {
      if (!isEmptyValue(node)) {
        initCanvas(node);
      }
    },
    [open]
  );

  useEffect(() => {
    if (!canvas || isEmptyValue(wrapperDimensions)) {
      return;
    }

    try {
      canvas.setDimensions({
        width: wrapperDimensions?.width || 100,
        height: wrapperDimensions?.height || 100,
      });
    } catch (error) {
      console.warn('Error setting canvas dimensions:', error);
    }
  }, [wrapperDimensions, canvas]);

  useEffect(() => {
    if (isEmptyValue(imageUrls) || isEmptyValue(canvas)) {
      return;
    }

    async function getCanvasImage(imageUrls, page) {
      const imageUrl = imageUrls[page];

      const canvasImage = await getFabricImage(imageUrl);

      const ratio =
        pageImageRatioMapping[page] || canvas.getWidth() / canvasImage.width;

      setPageImageRatioMapping((prev) => ({
        ...prev,
        [page]: ratio,
      }));

      canvas.setDimensions({
        height: canvasImage.height * ratio,
      });

      canvasImage.scaleToWidth(canvas.getWidth());

      canvasImage.set({
        selectable: false, // Prevent selection and movement
        evented: false, // Disable interaction events
      });

      return canvasImage;
    }

    getCanvasImage(imageUrls, currentPage).then((canvasImage) => {
      canvas.add(canvasImage);
      canvas.requestRenderAll();
    });
  }, [imageUrls, canvas, currentPage]);

  useEffect(() => {
    setPageImageRatioMapping({});
  }, [imageUrls]);

  async function getFabricImage(imageUrl) {
    let canvasImage = canvasImageMapping[imageUrl];

    if (canvasImage) {
      return canvasImage;
    }

    canvasImage = await fabric.FabricImage.fromURL(imageUrl);
    canvasImageMapping[imageUrl] = canvasImage;

    return canvasImage;
  }

  function setCanvasDimension(dimensions) {
    if (!canvas) {
      return;
    }

    canvas.setDimensions(dimensions);
  }

  function removeAllRectangles() {
    const allRects = getRectangles();
    allRects.forEach((rect) => {
      canvas.remove(rect);
    });

    canvas.renderAll();
  }

  return {
    pdfCanvasRef,
    printableRef,
    canvasWrapperRef,
    overlayCanvasRef,
    currentPage,
    pageImageRatioMapping,
    getRectangles,
    setImageUrls,
    setCurrentPage,
    setWrapperDimensions,
    setCanvasDimension,
    removeAllRectangles,
  };
}

export default useAutoBalloonCanvas;
