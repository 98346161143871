/* eslint-disable react/display-name */
import React, { memo } from 'react';

import { Add } from '@material-ui/icons';

import { CustomGridDataToolbarButton } from './CustomGridDataToolbarButton';

export const AddNewButton = memo(
  ({ handleClick = () => {}, disabled = false, name }) => {
    return (
      <CustomGridDataToolbarButton
        icon={<Add />}
        handleClick={handleClick}
        disabled={disabled}
        name={name}
      />
    );
  }
);
