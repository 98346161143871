import { createAlertNotification } from '../apis/alertApi';
import { verifyTokenV2ApiWithCache } from '../apis/jwtApi';

import { isEmptyValue } from '../utils/commonUtils';
import { decodeJwtToken } from '../utils/jwtUtils';
import { includesIgnoreCase } from '../utils/stringUtils';

import { ERROR_MESSAGE } from '../constants/errorMessageConstants';

// -------------------------------------------------------------------------------------------------

export const verifyTokenV2 = async ({ token }) => {
  if (isEmptyValue(token)) {
    return { valid: false };
  }

  const decodedToken = decodeJwtToken(token);

  const data = await verifyTokenV2ApiWithCache({ token }).catch((error) => {
    const body = {
      title: '[WARNING] [FE] Verify Token V2 error!',
      message: error.message,
      errorStack: error.stack,
      additionalInfo: {
        token,
        decodedToken,
      },
    };

    // Check if the error is an internet connection error
    if (
      includesIgnoreCase(error?.message, ERROR_MESSAGE.INTERNET_CONNECTION) ||
      includesIgnoreCase(error?.message, 'Failed to fetch') ||
      includesIgnoreCase(error?.message, 'Load failed')
    ) {
      body.message = 'Network error: Unable to reach the server';
    }

    createAlertNotification(body).catch((error) => console.warn(error));

    // still return true if there is any error, if return false then the user will be logged out
    // investigate the log to improve this case by case
    return { valid: true };
  });

  const { valid } = data;
  return valid;
};
