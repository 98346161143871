import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { Tab, Tabs } from '@material-ui/core';

import { colors } from '../palette';
import GenerateRFQEmailsTab from './GenerateRFQEmailsTab';
import GenerateRFQEmailsTrackingTabDataGrid from './GenerateRFQEmailsTrackingTabDataGrid';
import GenerateRFQEmailsTrackingWithoutQuoteInfoTab from './GenerateRFQEmailsTrackingWithoutQuoteInfoTab';
import BlacklistedSuppliersTab from './BlacklistedSuppliersTab';

const useStyles = makeStyles((theme) => ({
  body: {
    padding: theme.spacing(5),
    paddingTop: theme.spacing(5),
  },
  tabRoot: {
    '&:hover': {
      color: 'white',
      backgroundColor: colors.blue050,
    },
  },
  selectedTab: {
    '&:hover': {
      backgroundColor: colors.secondaryBlue,
      color: colors.blue050,
    },
    backgroundColor: colors.secondaryBlue,
    color: colors.blue050,
  },
}));

const TABS = [
  'Generate RFQ Emails',
  'Tracking Without Quote Info',
  'Tracking',
  'Blacklisted suppliers',
];

function GenerateRFQ() {
  const classes = useStyles();

  const [selectedTab, setSelectedTab] = useState(0); // 'Generate EFQ Emails' tab

  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <div className={classes.body}>
      <h1>Generate RFQ Emails</h1>
      <Tabs value={selectedTab} onChange={handleChange}>
        {TABS.map((tab) => {
          return (
            <Tab
              classes={{
                root: classes.tabRoot,
                selected: classes.selectedTab,
              }}
              key={tab}
              label={tab}
            />
          );
        })}
      </Tabs>
      {selectedTab === 0 && <GenerateRFQEmailsTab />}
      {selectedTab === 1 && <GenerateRFQEmailsTrackingWithoutQuoteInfoTab />}
      {selectedTab === 2 && <GenerateRFQEmailsTrackingTabDataGrid />}
      {selectedTab === 3 && <BlacklistedSuppliersTab />}
    </div>
  );
}

export default GenerateRFQ;
