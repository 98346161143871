import React from 'react';

import DownloadIcon from '../../assets/icons/download-icon.svg';

import PartDetailsV2InfoPresentational from '../popups/confirm-order-popup/PartDetailsV2InfoPresentational';
import GrayBox from '../ftr-components/GrayBox';
import { FlexColumn, FlexRow } from '../layouts/FlexLayouts';
import {
  FtrBoldText,
  FtrButton,
  FtrIconButton,
  FtrTooltip,
} from '../ftr-components';
import FtrSvgImage from '../images/FtrSvgImage';

import { getTechnicalDrawingFiles } from '../../utils/tdeUtils';
import { isEmptyValue } from '../../utils/commonUtils';
import {
  downloadS3File,
  extractAndDecodeFileNameAndExtensionFromUrl,
  getCadFileTo3DRenderer,
} from '../../utils/fileUtils';

import { colors } from '../../palette';

import { ITEM_STATUS_MAPPING } from '../../constants';

import withConfigureEditRfqPartFormHOC from '../../hocs/withConfigureEditRfqPartFormHOC';

import { isPpeItem } from '../../utils/itemUtils';

// ------------------------------------------------------------------------------------

function FileDisplay(props) {
  const { fileName, fileExtension, fileUrl } = props;

  const [waiting, setWaiting] = React.useState(false);

  return (
    <GrayBox
      style={{
        width: 'fit-content',
        cursor: !isEmptyValue(fileUrl)
          ? waiting
            ? 'wait'
            : 'pointer'
          : 'auto',
      }}
    >
      <FlexRow style={{ gap: 0 }}>
        <FtrBoldText
          style={{ color: colors.neutral060 }}
          onClick={() => {
            if (!isEmptyValue(fileUrl)) {
              setWaiting(true);
              document.body.style.cursor = 'wait';
              downloadS3File(fileUrl, {
                fileNameParam: `${fileName}.${fileExtension}`,
              }).then(() => {
                document.body.style.cursor = 'auto';
                setWaiting(false);
              });
            }
          }}
        >
          {fileName}
          <span style={{ fontWeight: 400 }}>.{fileExtension}</span>
        </FtrBoldText>
      </FlexRow>
    </GrayBox>
  );
}

function PartDetailsDropdownV2(props) {
  const { partInfo = {}, updateConfigureEditRfqPartState } = props;

  const renderCadFile = () => {
    const cadFileLink = getCadFileTo3DRenderer(
      partInfo.originalFiles || partInfo.cadFile
    );
    const [
      fileName,
      fileExtension,
    ] = extractAndDecodeFileNameAndExtensionFromUrl(cadFileLink);

    return (
      <FlexColumn style={{ gap: '1rem' }}>
        <FtrBoldText style={{ color: colors.headerGrey }}>
          Design/CAD File
        </FtrBoldText>
        <FlexRow key={fileName}>
          <FileDisplay
            fileName={fileName}
            fileExtension={fileExtension}
            fileUrl={cadFileLink}
          />
          <FtrTooltip description='Click to download'>
            <div>
              <FtrIconButton
                variant='text'
                onClick={() => downloadS3File(cadFileLink)}
              >
                <FtrSvgImage
                  src={DownloadIcon}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                />
              </FtrIconButton>
            </div>
          </FtrTooltip>
        </FlexRow>
      </FlexColumn>
    );
  };

  const renderTechnicalDrawingFiles = () => {
    const files = partInfo.originalFiles || partInfo.cadFile;
    const drawingFiles = getTechnicalDrawingFiles(files.split(','));

    if (isEmptyValue(drawingFiles)) {
      return;
    }

    return (
      <FlexColumn style={{ gap: '1rem' }}>
        <FtrBoldText style={{ color: colors.headerGrey }}>
          Technical Drawing
        </FtrBoldText>
        {drawingFiles.map((drawingFile) => {
          const [
            fileName,
            fileExtension,
          ] = extractAndDecodeFileNameAndExtensionFromUrl(drawingFile);
          return (
            <FlexRow key={fileName}>
              <FileDisplay
                key={fileName}
                fileName={fileName}
                fileExtension={fileExtension}
                fileUrl={drawingFile}
              />
              <FtrTooltip description='Click to download'>
                <div>
                  <FtrIconButton
                    variant='text'
                    onClick={() => downloadS3File(drawingFile)}
                  >
                    <FtrSvgImage
                      src={DownloadIcon}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    />
                  </FtrIconButton>
                </div>
              </FtrTooltip>
            </FlexRow>
          );
        })}
      </FlexColumn>
    );
  };

  const renderEditConfiguration = () => {
    if (
      isPpeItem(partInfo) ||
      partInfo?.status !== ITEM_STATUS_MAPPING.VERIFYING
    ) {
      return null;
    }

    return (
      <FlexRow>
        <FtrButton
          style={{ padding: 0 }}
          variant='text'
          color='blue'
          onClick={() => {
            updateConfigureEditRfqPartState({
              open: true,
              item: partInfo,
            });
          }}
        >
          Edit configurations
        </FtrButton>
      </FlexRow>
    );
  };

  return (
    <FlexRow style={{ padding: '2rem', alignItems: 'flex-start' }}>
      <PartDetailsV2InfoPresentational partInfo={partInfo} />
      <FlexColumn style={{ gap: '1.5rem' }}>
        {renderCadFile()}
        {renderTechnicalDrawingFiles()}
        {renderEditConfiguration()}
      </FlexColumn>
    </FlexRow>
  );
}

export default withConfigureEditRfqPartFormHOC(PartDetailsDropdownV2);
