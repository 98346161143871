import { CsvBuilder } from 'filefy';
import React, { useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles/index';

import {
  Collapse,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';

import { Pagination } from '@material-ui/lab';

import { KeyboardArrowDown, KeyboardArrowRight } from '@material-ui/icons';

import HorizontalExpandSpace from '../../components/ftr-components/HorizontalExpandSpace';
import { ExportCsvButton } from '../../components/grid-data/buttons/ExportCsvButton';
import SearchBar from '../../components/grid-data/buttons/SearchBar';
import { DataGridToolbarLayout } from '../../components/grid-data/CustomToolbar';
import { FlexRow } from '../../components/layouts/FlexLayouts';
import RowsPerPageMenu from '../../components/tables/RowsPerPageMenu';

import { useMuiTableSearchAndPagination } from '../../hooks/useMuiTablePagination';

import { getUserRoleSelector } from '../../selectors/userSelector';

import { getPdfByType } from '../../actions';

import formulas from '../../formulas/formulas';

import { generateCurrentCustomDateTimeString } from '../../util';
import { transformCsvExportData } from '../../utils/csvExportUtils';
import { downloadS3File } from '../../utils/fileUtils';
import { isSuperAdminRole } from '../../utils/roleUtils';

import {
  materialWithColorCol,
  surfaceFinishingWithColorCol,
} from '../../constants/itemTableConstants';

import { colors } from '../../palette';

// -------------------------------------------------------------------------------------------------

const useStyles = makeStyles(() => ({
  tableContainer: {
    '& .MuiTableCell-root': {
      padding: '0.8rem',
    },
  },
  iconExpandRow: {
    '& .MuiIconButton-root:not(.MuiCheckbox-root) > .MuiIconButton-label': {
      background: colors.buttonColorBlueGradient,
      borderRadius: '50%',
      '& .MuiSvgIcon-root': {
        fill: colors.fontWhite,
      },
    },
  },
  columnName: {
    '& table > tbody > tr > td:nth-child(4)': {
      wordBreak: 'break-all',
      whiteSpace: 'normal !important',
      minWidth: '10rem',
    },
  },
  tableRow: {
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: colors.tableRowHover,
    },
  },
  uploadButton: {
    display: 'flex',
    alignItems: 'center',
    width: 'fit-content',
    color: 'black',
    padding: '0.4rem',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: '#0000000a',
    },
    borderRadius: 4,
    textTransform: 'uppercase',
    fontSize: '0.6rem',
    lineHeight: '0.8rem',
    whiteSpace: 'nowrap',
  },
  checkIcon: {
    color: colors.uploadPOBorder,
  },
  uploaded: {
    backgroundColor: colors.uploadPOBg,
    '&:hover': {
      backgroundColor: colors.uploadPOBg,
    },
    userSelect: 'none',
  },
  customExportSvg: {
    margin: '0.3rem',
  },
}));

function CollapsibleRow(props) {
  const classes = useStyles();

  const { row, columns } = props;

  const [open, setOpen] = React.useState(false);

  const itemsTableColumns = [
    { title: 'ItemID', field: 'itemID' },
    { title: 'Name', field: 'name' },
    { title: 'QuotationID', field: 'quotationID' },
    { title: 'Supplier', field: 'supplierName' },
    {
      ...materialWithColorCol,
    },
    {
      ...surfaceFinishingWithColorCol,
    },
    { title: 'Quantity', field: 'quantity' },
    {
      title: 'Accepted Quote Price (excl. GST)',
      field: 'priceBidded',
      render: (rowData) => '$' + rowData.priceBidded,
    },
    {
      title: 'Subtotal',
      render: (rowData) => '$' + formulas.calculateSubTotal(rowData),
    },
    {
      title: 'Total Price shown to customer',
      render: (rowData) => (rowData.totalPrice ? '$' + rowData.totalPrice : ''),
    },
    {
      title: 'Deadline',
      field: 'deadline',
      render: (rowData) =>
        rowData.deadline ? rowData.deadline.substring(0, 10) : '',
    },
  ];

  return (
    <React.Fragment>
      <TableRow
        key={row.name}
        onClick={() => setOpen(!open)}
        className={classes.tableRow}
      >
        <TableCell className={classes.iconExpandRow} style={{ width: 50 }}>
          <IconButton
            aria-label='expand row'
            size='small'
            onClick={() => setOpen(!open)}
          >
            {open ? (
              <KeyboardArrowDown />
            ) : (
              <KeyboardArrowRight data-cy='expand-row-btn' />
            )}
          </IconButton>
        </TableCell>
        {columns.map((column) => {
          const renderFunc = column.render;
          if (!renderFunc && !column.field) {
            return null;
          }
          return (
            <TableCell key={row.field} style={{ width: column.width }}>
              {typeof renderFunc === 'function'
                ? renderFunc(row)
                : row[column.field]}
            </TableCell>
          );
        })}
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={11}>
          <Collapse in={open} timeout='auto' unmountOnExit>
            <Table aria-label='table'>
              <TableHead>
                <TableRow>
                  {itemsTableColumns.map((column) => {
                    if (column.hidden) {
                      return null;
                    }
                    return (
                      <TableCell
                        key={column.title}
                        style={{
                          position: 'sticky',
                          top: 0,
                          fontWeight: 600,
                        }}
                      >
                        {column.title}
                      </TableCell>
                    );
                  })}
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {row.items.map((item) => {
                  return (
                    <TableRow key={item.name}>
                      {itemsTableColumns.map((column) => {
                        if (column.hidden) {
                          return null;
                        }
                        const renderFunc = column.render;
                        return (
                          <TableCell key={item.field}>
                            {typeof renderFunc === 'function'
                              ? renderFunc(item)
                              : item[column.field]}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

function ViewDeliveryOrdersMuiTable(props) {
  const classes = useStyles();

  const { onPageLoad, pdfs } = props;

  const role = useSelector(getUserRoleSelector);

  const columns = [
    {
      title: '',
      width: 50,
    },
    {
      title: 'Delivery Order ID',
      field: 'pdfID',
    },
    {
      title: 'Part ID(s)',
      field: 'rowData.items.itemID',
      exportData: (rowData) => {
        return `${rowData?.items?.map((part) => part.itemID).join(', ')}`;
      },
      render: (rowData) => (
        <div>{rowData?.items?.map((part) => part.itemID).join(', ')}</div>
      ),
    },
    {
      title: 'Part name(s)',
      field: 'rowData.items.name',
      exportData: (rowData) => {
        return `${rowData?.items?.map((part) => part.name).join(', ')}`;
      },
      render: (rowData) => (
        <div>{rowData?.items?.map((part) => part.name).join(', ')}</div>
      ),
    },
    {
      title: 'PDF',
      field: 'url',
      render: (rowData) => (
        <Link
          onClick={(e) => {
            e.stopPropagation();
            downloadS3File(rowData.url);
          }}
          to='#'
        >
          Download
        </Link>
      ),
    },
  ];

  const [
    {
      displayRows,
      filteredRows,
      totalCount,
      totalPages,
      currentPage,
      rowsPerPage,
      from,
      to,
      searchTerm,
    },
    { setAllRows, updateRowsPerPage, setCurrentPage, setSearchTerm },
  ] = useMuiTableSearchAndPagination({
    initialColumns: columns,
    rowsPerPage: 20,
  });

  useEffect(() => {
    onPageLoad();
  }, []);

  useEffect(() => {
    setAllRows(pdfs);
  }, [pdfs]);

  return (
    <Paper
      style={{
        marginTop: '1rem',
      }}
    >
      <DataGridToolbarLayout>
        <HorizontalExpandSpace />
        <FlexRow>
          <SearchBar onSearch={setSearchTerm} searchTerm={searchTerm} />
          {isSuperAdminRole(role) && (
            <ExportCsvButton
              iconOnly
              border={false}
              customStyle={classes.customExportSvg}
              handleClick={() => {
                const fileName = `All DOs ${generateCurrentCustomDateTimeString()}.csv`;
                const {
                  exportedColumns,
                  exportedData,
                } = transformCsvExportData(columns, filteredRows);
                const builder = new CsvBuilder(fileName);
                builder
                  .setDelimeter(',')
                  .setColumns(exportedColumns)
                  .addRows(exportedData)
                  .exportFile();
              }}
            />
          )}
        </FlexRow>
      </DataGridToolbarLayout>
      <Table aria-label='table'>
        <TableHead>
          <TableRow>
            {columns.map((column) => {
              if (column.hidden) {
                return <TableCell key={column.title}></TableCell>;
              }
              return (
                <TableCell
                  key={column.title}
                  style={{
                    top: 0,
                    color: colors.blue060,
                    fontSize: '11pt',
                    fontWeight: 600,
                  }}
                >
                  {column.title}
                </TableCell>
              );
            })}
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {displayRows.map((row) => {
            return (
              <CollapsibleRow key={row.name} row={row} columns={columns} />
            );
          })}
        </TableBody>
      </Table>
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          padding: '0.5rem 0',
          marginRight: '3rem',
        }}
      >
        <div
          style={{
            marginRight: '1.5rem',
          }}
        >
          <RowsPerPageMenu
            onRowsPerPageChange={(value) => updateRowsPerPage(value)}
            value={rowsPerPage}
          />
        </div>
        <Typography
          style={{
            marginRight: '1.5rem',
          }}
          variant='body2'
        >
          {from + 1}-{to} of {totalCount}
        </Typography>
        <Pagination
          count={totalPages}
          showFirstButton
          showLastButton
          page={currentPage}
          onChange={(e, pageNumber) => setCurrentPage(pageNumber)}
          color='primary'
        />
      </div>
    </Paper>
  );
}

function mapStateToProps(state) {
  return {
    pdfs: state.pdf.pdfs,
  };
}

function matchDispatchToProps(dispatch) {
  return {
    onPageLoad: () => dispatch(getPdfByType(1)),
  };
}

const withConnect = connect(mapStateToProps, matchDispatchToProps);

export default withRouter(withConnect(ViewDeliveryOrdersMuiTable));
