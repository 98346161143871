/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';

import { Breadcrumbs, Link, Typography } from '@material-ui/core';

import CustomerOrderHistoryDataGrid from '../../components/tables/CustomerOrderHistoryDataGrid';
import BlueButton from '../../components/buttons/BlueButton';
import OrderProjectAgainPopup from '../../components/popups/OrderProjectAgainPopup';
import PpePriceLoadingBackdrop from '../../components/backdrops/PpePriceLoadingBackdrop';

import { useOrderItemsAgainHook } from '../../hooks/useOrderItemsAgainHook';

import { colors } from '../../palette';

const useStyles = makeStyles(() => ({
  body: {
    height: '100%',
  },
  select: {
    '& .MuiSelect-select:focus': {
      backgroundColor: colors.fontWhite,
    },
  },
  formComponent: {
    padding: '10px 45px',
  },
  projectLabel: {
    margin: '30px 0 0',
    display: 'flex',
  },
  projectHeader: {
    display: 'flex',
    alignItems: 'center',
  },
  textField: {
    color: colors.fontBlack,
  },
  headerStyle: {
    color: colors.blue060,
    fontSize: '12pt',
    fontWeight: 600,
    marginRight: '10px',
  },
  textStyle: {
    fontSize: '12pt',
  },
  breadcrumbLink: {
    color: colors.tertiaryBlue,
    fontSize: '11pt',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  breadcrumbLinkBold: {
    fontSize: '11pt',
  },
  orderAgainButton: {
    marginRight: '16px',
    marginBottom: '16px',
  },
}));

export function ManageProjectHistoryItems(props) {
  const classes = useStyles();

  let project = props.location.state.project;

  const [showOrderProjectAgainPopup, setShowOrderProjectAgainPopup] = useState(
    false
  );
  const [loading, setLoading] = useState(false);

  const [
    { setItemList, handleOrderItemsAgain: handleOrderItemsAgainHook },
  ] = useOrderItemsAgainHook();

  useEffect(() => {
    setItemList(project.items);
  }, []);

  const handleOrderItemsAgain = (selectedItemIDs) => {
    setLoading(true);
    handleOrderItemsAgainHook(selectedItemIDs);
  };

  return (
    <div className={classes.formComponent}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Breadcrumbs aria-label='breadcrumb'>
          <Link
            className={classes.breadcrumbLink}
            onClick={() => props.history.goBack()}
            aria-current='page'
          >
            Order History
          </Link>
          <Typography className={classes.breadcrumbLinkBold}>
            {project.name}
          </Typography>
        </Breadcrumbs>
        <BlueButton
          btnContent='Order Project Again'
          size='small'
          onBtnClick={() => setShowOrderProjectAgainPopup(true)}
          className={classes.orderAgainButton}
        />
      </div>
      <div className={classes.body}>
        <CustomerOrderHistoryDataGrid data={project.items} />
      </div>
      {showOrderProjectAgainPopup && (
        <OrderProjectAgainPopup
          open={showOrderProjectAgainPopup}
          items={project.items}
          handleCancel={() => setShowOrderProjectAgainPopup(false)}
          handleOk={handleOrderItemsAgain}
        />
      )}
      <PpePriceLoadingBackdrop open={loading} />
    </div>
  );
}

export default withRouter(ManageProjectHistoryItems);
