import React from 'react';

import FtrSvgImage from '../../images/FtrSvgImage';
import FtrTooltip from '../FtrTooltip';
import { FtrIconButton } from '../FtrButton';

import InvoiceIcon from '../../../assets/icons/invoice_icon.svg';

// --------------------------------------------------------------------------------------------

function ViewXeroInvoiceButton(props) {
  const {
    loading = false,
    disabled = false,
    tooltip = '',
    onClick = () => {},
  } = props;

  return (
    <FtrTooltip description={tooltip}>
      <div>
        <FtrIconButton onClick={onClick} loading={loading} disabled={disabled}>
          <FtrSvgImage
            src={InvoiceIcon}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          />
        </FtrIconButton>
      </div>
    </FtrTooltip>
  );
}

export default ViewXeroInvoiceButton;
