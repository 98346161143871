import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';

import { getUserAddresses } from '../apis/userApi';

import {
  updateMulticheckoutFormAddress,
  updateMultiCheckoutFormState,
} from '../actions/multiCheckoutForm';

import useUserInfo from './useUserInfoHook';

import {
  checkCountryAddress,
  getDefaultAddresses,
} from '../utils/addressUtils';
import { isEmptyValue } from '../utils/commonUtils';

// ------------------------------------------------------------------------------------

const useUserShippingAddress = (
  customerID,
  { shouldUpdateState = false } = {}
) => {
  const dispatch = useDispatch();

  const [addresses, setAddresses] = useState(null);

  const {
    data: userInfo,
    isLoading: isLoadingUserInfo,
    isFetching: isFetchingUserInfo,
  } = useUserInfo(customerID);

  const {
    data: userAddresses,
    isLoading: isLoadingUserAddresses,
    isFetching: isFetchingUserAddresses,
  } = useQuery(['getUserAddresses', customerID], () => {
    if (isEmptyValue(customerID)) {
      return null;
    }
    return getUserAddresses(customerID);
  });

  useEffect(() => {
    if (
      isLoadingUserInfo ||
      isFetchingUserInfo ||
      isLoadingUserAddresses ||
      isFetchingUserAddresses
    ) {
      return;
    }

    const { defaultBilling, defaultShipping } = getDefaultAddresses(
      userAddresses
    );
    const _billing = defaultBilling || defaultShipping;
    let _addresses = {
      id: defaultShipping?.deliveryInfoID,
      shipping: {
        deliveryInfoID: defaultShipping?.deliveryInfoID,
        address: defaultShipping?.address || userInfo?.address,
        unitNo: defaultShipping?.unitNo,
        contactNumber: defaultShipping?.contactNumber || userInfo?.contact,
        contactName: defaultShipping?.contactName || userInfo?.name,
        country:
          defaultShipping?.country ||
          checkCountryAddress(defaultShipping?.address) ||
          userInfo?.country,
        postalCode: defaultShipping?.postalCode,
      },
      billing: {
        deliveryInfoID: _billing?.deliveryInfoID,
        address: _billing?.address,
        contactNumber: _billing?.contactNumber || userInfo?.contact,
        unitNo: _billing?.unitNo,
        contactName: _billing?.contactName || userInfo?.name,
        country:
          _billing?.country ||
          checkCountryAddress(_billing?.address) ||
          userInfo?.country,
        postalCode: _billing?.postalCode,
      },
    };
    setAddresses(_addresses);
    if (shouldUpdateState) {
      dispatch(
        updateMulticheckoutFormAddress({
          country: _addresses?.shipping?.country,
          deliveryInfoID: _addresses?.id,
        })
      );
      dispatch(
        updateMultiCheckoutFormState({
          billingAddressID: _billing?.deliveryInfoID,
        })
      );
    }
  }, [
    userInfo,
    userAddresses,
    isLoadingUserInfo,
    isFetchingUserInfo,
    isLoadingUserAddresses,
    isFetchingUserAddresses,
    shouldUpdateState,
  ]);

  return [
    {
      userInfo,
      addresses,
    },
    setAddresses,
  ];
};

export default useUserShippingAddress;
