import { useQuery, useQueryClient } from 'react-query';

import { getActionTrackingApi } from '../apis/actionTrackingApi';

import { isEmptyValue } from '../utils/commonUtils';

// -------------------------------------------------------------------------------------------------

const useActionTracking = (userID, actionTrackingKey) => {
  const queryClient = useQueryClient();

  const queryKey = `getActionTrackings-${actionTrackingKey}`;

  const { data, isLoading, isFetching, refetch } = useQuery(
    [queryKey, userID, actionTrackingKey],
    () =>
      getActionTrackingApi({
        userID,
        actionTrackingKey,
      }),
    {
      enabled: !isEmptyValue(userID) && !isEmptyValue(actionTrackingKey),
    }
  );

  const invalidateQuery = () => {
    queryClient.invalidateQueries(queryKey);
  };

  return {
    data,
    isLoading,
    isFetching,
    refetch,
    invalidateQuery,
  };
};

export default useActionTracking;
