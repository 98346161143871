import React, { useReducer } from 'react';

import { makeStyles } from '@material-ui/core/styles/index';

import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';

import ChatSvgIcon from '../../assets/icons/chat.svg';

import { FlexColumn, FlexColumnCenter } from '../layouts/FlexLayouts';
import {
  FtrBoldText,
  FtrButton,
  FtrDropdownV2,
  FtrH6,
  FtrTextArea,
} from '../ftr-components';

import { isEmptyValue } from '../../utils/commonUtils';

import { CUSTOMER_CANCEL_ORDER_REASON } from '../../constants/itemConstants';

const useStyles = makeStyles(() => ({
  backdrop: {
    backdropFilter: 'blur(3px)',
  },
  dialog: {
    padding: '1.5rem',
    borderRadius: '1.5rem',
  },
  title: {
    display: 'flex',
    fontSize: '22px',
    fontWeight: '700',
    alignItems: 'center',
    justifyContent: 'center',
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

function DeleteWarningPopupV2(props) {
  const classes = useStyles();

  const {
    open,
    onClose = () => {},
    onCancel = () => {},
    onConfirm = () => {},
  } = props;

  const [localState, updateLocalState] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      feedback: null,
      additionalRemarks: '',
    }
  );

  const handleConfirm = () => {
    onConfirm(localState);
    updateLocalState({
      feedback: null,
      additionalRemarks: '',
    });
  };

  const handleFeedback = (value) => {
    updateLocalState({ feedback: value });
  };

  const renderAdditionalRemarks = () => {
    const label =
      localState.feedback === CUSTOMER_CANCEL_ORDER_REASON.OTHERS
        ? 'Tell us more *'
        : 'Tell us more (optional)';
    return (
      <FlexColumn
        style={{
          gap: '1rem',
          marginTop: '1rem',
        }}
      >
        <FtrBoldText>{label}</FtrBoldText>
        <FtrTextArea
          value={localState.additionalRemarks}
          onChange={(e) =>
            updateLocalState({ additionalRemarks: e.target.value })
          }
        />
      </FlexColumn>
    );
  };

  const renderDialogActions = () => {
    return (
      <FlexColumnCenter
        style={{
          gap: '1rem',
          marginTop: '2rem',
          maxWidth: 200,
          width: '100%',
          alignSelf: 'center',
        }}
      >
        <FtrButton
          id='proceed-to-create-project-btn'
          color='blue'
          variant='contained'
          onClick={() => {
            handleConfirm();
            onClose();
          }}
          autoFocus
          fullWidth
          disabled={
            isEmptyValue(localState.feedback) ||
            (localState.feedback === CUSTOMER_CANCEL_ORDER_REASON.OTHERS &&
              isEmptyValue(localState.additionalRemarks))
          }
        >
          Confirm Delete
        </FtrButton>
        <FtrButton variant='text-black' onClick={onCancel}>
          Go back to order
        </FtrButton>
      </FlexColumnCenter>
    );
  };

  return (
    <Dialog
      open={open}
      maxWidth='xs'
      fullWidth
      scroll='body'
      BackdropProps={{
        classes: {
          root: classes.backdrop,
        },
      }}
      PaperProps={{ className: classes.dialog }}
      onClose={onClose}
    >
      <DialogTitle disableTypography className={classes.title}>
        <img style={{ padding: '1rem 1rem' }} src={ChatSvgIcon} alt='icon' />
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <FtrH6 style={{ marginBottom: '1rem' }}>
          Why are you deleting this order?
        </FtrH6>
        <FlexColumn
          style={{
            width: '100%',
            gap: '1rem',
            padding: '1rem',
            boxSizing: 'border-box',
          }}
        >
          <FtrDropdownV2
            key='question-dropdown'
            fullWidth
            value={localState.feedback}
            handleChange={handleFeedback}
            items={Object.values(CUSTOMER_CANCEL_ORDER_REASON)}
            placeholder='Select reason'
          />
          {renderAdditionalRemarks()}
        </FlexColumn>
        {renderDialogActions()}
      </DialogContent>
    </Dialog>
  );
}

export default DeleteWarningPopupV2;
