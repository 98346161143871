import ProjectMultiCheckoutInfoPopup from '../components/popups/ProjectMultiCheckoutInfoPopup';

import withPopupHOC from './withPopupHOC';

// -------------------------------------------------------------------------------------------------

export const withProjectMultiCheckoutInfoPopupHOC = (WrappedComponent) =>
  withPopupHOC({
    WrappedComponent,
    ModalComponent: ProjectMultiCheckoutInfoPopup,
    defaultLocalState: {
      open: false,
      projectID: null,
      itemsFilterFn: () => true,
    },
    statePropName: 'projectMultiCheckoutInfoPopupState',
    updaterPropName: 'updateProjectMultiCheckoutInfoPopupState',
  });
