import { makeStyles } from '@material-ui/core';
import React from 'react';
import { colors } from '../../palette';

const useStyles = makeStyles(() => ({
  container: {
    position: 'relative',
  },
  label: {
    backgroundColor: colors.green050,
    padding: '2px 8px',
    position: 'absolute',
    bottom: '12px',
    right: '12px',
    color: 'white',
    fontSize: '12px',
    textAlign: 'center',
    borderRadius: '10px',
  },
}));

function withLabelHoC(WrappedComponent) {
  return function WrappedComponentWithLabelHoC(props) {
    const { label } = props;
    const classes = useStyles();

    return (
      <div className={classes.container}>
        <WrappedComponent {...props} />
        {label && <div className={classes.label}>{label}</div>}
      </div>
    );
  };
}

export default withLabelHoC;
