import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Tooltip } from '@material-ui/core';

import { FtrBanner } from '../ftr-components';
import { colors } from '../../palette';
import { ReactComponent as InfoIcon } from '../../assets/icons/info_icon_blue.svg';

const DescTooltip = withStyles({
  tooltip: {
    backgroundColor: 'transparent',
  },
})(Tooltip);

/**
 * @deprecated
 * Use FtrTooltip instead
 * @param tooltipContent
 * @returns {Element}
 * @constructor
 */
function DescriptiveTooltip({ tooltipContent }) {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <DescTooltip
        title={
          <FtrBanner
            type='info'
            style={{
              backgroundColor: 'white',
              boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
            }}
          >
            {tooltipContent}
          </FtrBanner>
        }
        placement='bottom'
      >
        <InfoIcon style={{ color: colors.neutral040 }} />
      </DescTooltip>
    </div>
  );
}

export default DescriptiveTooltip;
