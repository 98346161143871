import React from 'react';

import { FormControlLabel } from '@material-ui/core';

import FtrBlueCheckbox from '../FtrBlueCheckbox';

import { colors } from '../../../palette';

function FtrCheckboxRow(props) {
  const { children, onChange, checked, isLastItem, disabled = false } = props;

  return (
    <FormControlLabel
      control={
        <FtrBlueCheckbox
          onChange={onChange}
          checked={checked}
          style={{ padding: '0px 10px' }}
          disabled={disabled}
        />
      }
      label={children}
      style={{
        margin: '0 0 -0.1rem 0',
        width: '100%',
        backgroundColor: checked ? colors.paleBlue : colors.neutral010,
        border: `1px solid ${colors.neutral030}`,
        boxSizing: 'border-box', // Ensure consistent box sizing
        borderRadius: isLastItem ? '0px 0px 20px 20px' : '0px',
      }}
    />
  );
}

export default FtrCheckboxRow;
