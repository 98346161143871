import { get } from 'lodash';

import {
  convertPriceToCurrency,
  convertPriceWithQuantityToCurrency,
} from './currencyUtils';
import { convertToDigits } from './stringUtils';
import { isDateInThePast } from './dateTimeUtils';
import { isEmptyValue } from './commonUtils';
import { reduce } from './arrayUtils';

import { ORDER_STATUS } from '../constants';
import { ITEM_STATUS_MAPPING } from '../constants/itemStatus';
import { CURRENCY_CODE } from '../constants/currencyConstants';

import { colors } from '../palette';

// -------------------------------------------------------------------------------------------------

export const getBackgroundColorByQuoteStatus = (status) => {
  let bgColor = '';
  switch (status) {
    case 'verifying':
      bgColor = colors.bgUnverifiedQuote;
      break;
    case 'pending':
    case 'submitted':
      bgColor = colors.bgSubmittedStatus;
      break;
    case 'accepted':
      bgColor = '#a1e21e';
      break;
    case 'rejected':
      bgColor = '#ffa37c';
      break;
    default:
      bgColor = '#cecece';
      break;
  }
  return bgColor;
};

export const getQuotationExchangeRate = (quotation, currency) => {
  const exchangeRate = get(
    quotation,
    ['exchangeRates', 'exchangeRates', 'exchange_rates'],
    {}
  )[currency];
  return exchangeRate;
};

export const reasonsTextUtil = (reasons) => {
  if (!reasons?.length) {
    return '';
  }
  return reasons
    ?.map((reason) => {
      if (reason.includes('Others:')) {
        return `• ${reason.substring(7)}`;
      }
      return `• ${reason}`;
    })
    .join(' ');
};

export const isRocketQuote = (quotation) => {
  return (
    quotation.rfqQuote !== 1 &&
    (quotation.rocketQuote || !isEmptyValue(quotation.adminID))
  );
};

export const getDiscountAmountUtil = (data) => {
  const totalPriceBeforeGst = convertPriceToCurrency(
    {
      price: data.totalPrice,
      currency: CURRENCY_CODE.SGD,
      exchangeRate: 1,
    },
    ''
  );
  const [unitPrice, totalPrice] = totalPriceBeforeGst.split(' ');
  const percentOff = get(data, ['stripeDiscountInfo', 'coupon', 'percent_off']);
  if (isNaN(+percentOff) || +percentOff <= 0) {
    return 'N.A.';
  }
  const discountAmount = ((totalPrice * percentOff) / 100).toFixed(2);
  return `-${unitPrice} ${discountAmount}`;
};

export const isPpeQuote = (quotation) => {
  return quotation.rfqQuote === 0;
};

/**
 * get purchase order ID from pdf URL
 * If the value less than 8, convert to 8 digit
 * Else keep the format
 * @param {string} pdfUrl
 */
export const getPurchaseOrderID = (pdfUrl) => {
  if (isEmptyValue(pdfUrl)) {
    return null;
  }

  const fileName = pdfUrl?.split('/').reverse()[0];
  const fileNameArr = fileName?.split('_').reverse();
  // check for revision. normal: 0421244101; revision: 0421244101_Rev2.pdf
  let purchaseOrderID = isNaN(+fileNameArr[0])
    ? fileNameArr[1]
    : fileNameArr[0];
  return purchaseOrderID?.length < 8
    ? convertToDigits(purchaseOrderID, 8)
    : purchaseOrderID;
};

export const isUnverifiedQuote = (quotation) => {
  return (
    quotation.status === ORDER_STATUS.VERIFYING &&
    isEmptyValue(quotation.verifiedDate)
  );
};

export const isWaitingQuote = (quotation) => {
  return quotation.status === ORDER_STATUS.WAITING_FOR_QUOTE;
};

export const isPendingQuote = (quotation) => {
  return quotation.status === ORDER_STATUS.PENDING;
};

export const isSelectableQuote = (quotation, allowUnverifiedQuotes = false) => {
  return (
    (isPendingQuote(quotation) &&
      (!quotation.dateOfExpiry || !isDateInThePast(quotation.dateOfExpiry))) ||
    (allowUnverifiedQuotes && isUnverifiedQuote(quotation))
  );
};

// unchecked-out parts can have unverified rfq quotes for admin too
export const isUncheckedOutPartWithQuotes = (part, isAdminView) => {
  return (
    part.status === ITEM_STATUS_MAPPING.QUOTES_AVAILABLE ||
    (isAdminView &&
      ITEM_STATUS_MAPPING.SOURCING &&
      !isEmptyValue(part.quotations))
  );
};

/**
 *
 * @param {Number} supplierID
 * @param {Number} projectID
 * @param {Number} poAcknowledgedVersion
 * @returns 10 digits of po number
 * @example #0123456701
 */
export const getPoNumber = (supplierID, projectID, poAcknowledgedVersion) => {
  return `#${convertToDigits(supplierID)}${convertToDigits(
    projectID
  )}${convertToDigits(poAcknowledgedVersion, 2)}`;
};

/**
 * Get Format of Combination Supplier ID and QuotationID
 * @param {*} supplierID
 * @param {*} quotationID
 * @returns
 */
export const getSupplierQuoteID = (supplierID, quotationID) => {
  return `${convertToDigits(supplierID)}${convertToDigits(quotationID)}`;
};

/**
 * Finds and returns the quotation with the lowest unit price from an array of quotations.
 * Each quotation object should contain the properties: totalPrice, currency, exchangeRate, and quantity.
 *
 * @param {Array} quotations - An array of quotation objects to compare.
 * @returns {Object} - The quotation object with the lowest unit price.
 */
export const getCheapestQuote = (quotations = []) => {
  return reduce(
    quotations,
    (result, currentQuotation) => {
      if (isEmptyValue(result)) {
        return currentQuotation;
      }

      const {
        unitPrice: currentUnitPrice,
      } = convertPriceWithQuantityToCurrency({
        totalPrice: currentQuotation.totalPrice,
        currency: currentQuotation.currency,
        exchangeRate: currentQuotation.exchangeRate,
        quantity: currentQuotation.quantity,
      });

      const { unitPrice: minUnitPrice } = convertPriceWithQuantityToCurrency({
        totalPrice: result.totalPrice,
        currency: result.currency,
        exchangeRate: result.exchangeRate,
        quantity: result.quantity,
      });

      if (currentUnitPrice < minUnitPrice) {
        return currentQuotation;
      }

      return result;
    },
    undefined
  );
};

/**
 * Selects the best quotation from an array of quotations for an item.
 *
 * Order of selection:
 * 1. Accepted quotations.
 * 2. Pending quotations with the lowest unit price.
 * 3. Verifying quotations with the lowest unit price.
 * 4. The quotation with the lowest unit price from the remaining quotations.
 *
 * @param {Array} allItemQuotations - An array of quotation objects to select from.
 * @returns {Object} - The selected quotation object.
 */
export const selectQuotationForItem = (allItemQuotations = []) => {
  const acceptedQuotation = allItemQuotations?.find(
    (q) => q.status === ORDER_STATUS.ACCEPTED
  );
  if (!isEmptyValue(acceptedQuotation)) {
    return acceptedQuotation;
  }

  const pendingQuotations = allItemQuotations?.filter(
    (q) => q.status === ORDER_STATUS.PENDING
  );
  const cheapestPending = getCheapestQuote(pendingQuotations);
  if (!isEmptyValue(cheapestPending)) {
    return cheapestPending;
  }

  const verifyingQuotations = allItemQuotations?.filter(
    (q) => q.status === ORDER_STATUS.VERIFYING
  );
  const cheapestVerifying = getCheapestQuote(verifyingQuotations);
  if (!isEmptyValue(cheapestVerifying)) {
    return cheapestVerifying;
  }

  return getCheapestQuote(
    allItemQuotations?.filter(
      (q) => ![ORDER_STATUS.WAITING_FOR_QUOTE].includes(q.status)
    )
  );
};
