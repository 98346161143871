import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import withGuidedTour from './withGuidedTour';

import useGuidedTour from '../../hooks/useGuidedTourHook';

import { getUserIDSelector } from '../../selectors/userSelector';

import { REQUEST_MORE_QUOTES_FEATURE_TOUR } from './guidedTours/tourConstants';

// -------------------------------------------------------------------------------------------------

function RequestMoreQuotesFeatureTour(props) {
  const { tour, updateTourState, tourKey, startTour = false } = props;

  const userID = useSelector(getUserIDSelector);

  const { dataTour, isTourLoading } = useGuidedTour(userID, tourKey);

  useEffect(() => {
    if (tourKey === REQUEST_MORE_QUOTES_FEATURE_TOUR) {
      return;
    }

    updateTourState({
      tourKey: REQUEST_MORE_QUOTES_FEATURE_TOUR,
    });
  }, []);

  useEffect(() => {
    if (
      tourKey !== REQUEST_MORE_QUOTES_FEATURE_TOUR ||
      !tour ||
      dataTour?.completed ||
      isTourLoading ||
      !startTour
    ) {
      return;
    }

    setTimeout(() => {
      tour.start();
    }, 1000);
  }, [tourKey, tour, dataTour?.completed, isTourLoading, startTour]);

  return;
}

export default withGuidedTour(RequestMoreQuotesFeatureTour);
