import React from 'react';

import { Typography } from '@material-ui/core';

function DisplayItemTech(props) {
  const { text } = props;

  return (
    <Typography
      variant='body2'
      color='textSecondary'
      component='span'
      display='block'
    >
      {text ? text : <div style={{ height: '24px' }} />}
    </Typography>
  );
}

export default DisplayItemTech;
