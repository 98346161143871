import { get } from 'lodash';

import { TWO_D_IMAGE_URLS_KEY } from '../constants';

const FORM_FIELDS = [
  'id',
  'name',
  'technology',
  'otherTechnology',
  'material',
  'otherMaterial',
  'surfaceFinish',
  'otherSurfaceFinish',
  'anodizingType',
  'threeDTechnology',
  'threeDInfill',
  'threeDLayerThickness',
  'tolerance',
  'remarks',
  'qty',
  'price',
  'totalPrice',
  'partApplication',
  'materialColor',
  'customMaterialColor',
  'color',
  'customColor',
  'targetPrice',
  'originalTargetPrice',
  'expectedLeadTime',
  'unitType',
  'cadPart',
  TWO_D_IMAGE_URLS_KEY,
];

export const extractFormStateFromItem = (item) => {
  return FORM_FIELDS.reduce((acc, field) => {
    acc[field] = get(item, field);
    return acc;
  }, {});
};
