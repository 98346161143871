import { useQuery } from 'react-query';

import { getSurfaceFinishNilMappingApi } from '../apis/configurationApi';

import { setCache } from '../services/localStorageCacheService';

import { SURFACE_FINISH_NIL_MAPPING_KEY } from '../constants/cookiesConstants';

// -------------------------------------------------------------------------------------------------

const useSurfaceFinishNilMappingHook = () => {
  const { data, refetch } = useQuery(
    ['getSurfaceFinishNilMapping'],
    async () => {
      const result = await getSurfaceFinishNilMappingApi();
      setCache(SURFACE_FINISH_NIL_MAPPING_KEY, JSON.stringify(result));
    }
  );

  return {
    data,
    refetch,
  };
};

export default useSurfaceFinishNilMappingHook;
