import React from 'react';
import { makeStyles } from '@material-ui/core/styles/index';

import { FlexRow } from '../../layouts/FlexLayouts';
import { FtrBoldText } from '../../ftr-components';

import PaynowLogoIcon from '../../../assets/icons/paynow_logo.svg';
import CreditCardIcon from '../../../assets/icons/credit_card_icon.svg';

import { colors } from '../../../palette';

// ------------------------------------------------------------------------------

const useStyles = makeStyles(() => ({
  root: {
    width: 228,
    height: 52,
    border: `1px solid ${colors.neutral030}`,
    borderRadius: 8,
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    padding: '0 1rem',
    boxSizing: 'border-box',
  },
  selected: {
    border: `2px solid ${colors.blue050}`,
  },
}));

function CheckoutPaymentOption(props) {
  const classes = useStyles();

  const { children, selected = false, onClick = () => {} } = props;

  return (
    <div
      className={`${classes.root} ${selected ? classes.selected : ''}`}
      onClick={onClick}
    >
      {children}
    </div>
  );
}

export function StripePaynowPaymentOption(props) {
  const { selected, onClick } = props;

  return (
    <CheckoutPaymentOption selected={selected} onClick={onClick}>
      <FlexRow style={{ justifyContent: 'space-between', width: '100%' }}>
        <img src={PaynowLogoIcon} width={82} height={20} />
      </FlexRow>
    </CheckoutPaymentOption>
  );
}

export function StripeCreditCardPaymentOption(props) {
  const { selected, onClick } = props;

  return (
    <CheckoutPaymentOption selected={selected} onClick={onClick}>
      <FlexRow
        style={{ gap: '1rem', width: '100%' }}
        data-cy='payment-credit-card'
      >
        <img src={CreditCardIcon} width={20} height={20} />
        <FtrBoldText
          style={{
            color: colors.neutral070,
          }}
        >
          Card
        </FtrBoldText>
        <span style={{ flex: '1 1 auto' }}></span>
        <FtrBoldText
          style={{
            color: selected ? colors.blue050 : colors.neutral070,
          }}
        >
          +3.5% fee
        </FtrBoldText>
      </FlexRow>
    </CheckoutPaymentOption>
  );
}

export default CheckoutPaymentOption;
