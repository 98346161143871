import { isEmptyValue } from './commonUtils';

// -------------------------------------------------------------------------------------------------

export const includesIgnoreCase = (str = '', term = '') => {
  if (isEmptyValue(str)) {
    return false;
  }
  return str.toLowerCase().includes(term.toLowerCase());
};

export const normalizeString = (str) => {
  if (isEmptyValue(str)) {
    return '';
  }
  return `${str}`.trim().toLowerCase();
};

/**
 * to check is empty with additional value
 * @param {*} value
 * @returns {Boolean}
 * @constant additionalValue = ['N/A', '-']
 */
export const isEmptyWithAdditionalValue = (value) => {
  const additionalEmptyValue = ['N/A', '-'];
  return additionalEmptyValue.includes(value) || isEmptyValue(value);
};

/**
 *
 * @param {String} text
 * @example 'defaultSurfaceFinish' to 'Default Surface Finish'
 * @example 'threeDTechnology' to '3 D Technology'
 */
export const convertCamelCaseToTitle = (text) => {
  if (text.startsWith('threeD')) {
    text = text.replace(/threeD/g, '3D');
  }
  const result = text.replace(/([A-Z])/g, ' $1');
  return result.charAt(0).toUpperCase() + result.slice(1);
};

/**
 *
 * @param {String} snakeCaseString
 * @returns {String}
 * @example 'example_snake_case_string' to 'Example Snake Case String'
 * @example 'loaded_by_proxy' to 'Loaded by Proxy'
 */
export const snakeCaseToTitleCase = (snakeCaseString) => {
  return normalizeString(snakeCaseString)
    .split('_')
    .map((word) => {
      const firstCharWord = ['to', 'by', 'of'].includes(word)
        ? word.charAt(0)
        : word.charAt(0).toUpperCase();
      return firstCharWord + word.slice(1);
    })
    .join(' ');
};

/**
 *
 * @param {string} str
 * @example VERIFY_EMAIL_1 -> 1
 */
export const getStepFromSnakeCase = (str) => {
  return Number(str?.split('_').slice(-1));
};

/**
 *
 * @param {*} data
 * @param {*} numDigits
 * @examples
 * 12 -> 0012
 */
export const convertToDigits = (data, numDigits = 4) => {
  return data ? data.toString().padStart(numDigits, '0') : '';
};

/**
 *
 * @param {String} text
 * @param {Object} options
 * @param {Number} options.maxLength - Default 35
 * @param {Number} options.start
 * @param {Number} options.end
 * @returns Ellipsis Text
 * @example 'Lorem ipsum dolor sit amet. Ut ornare dignissim ligula sed commodo.' to
 * 'Lorem ipsum dolor sit amet ... commodo.'
 */
export const ellipsisMiddleText = (str, options = {}) => {
  const { maxLength = 25, start, end } = options;
  const sliceNumber = Math.ceil((maxLength - 5) / 2);
  if (str.length > maxLength) {
    return (
      str.slice(0, start ?? sliceNumber) +
      '...' +
      str.slice(-(end ?? sliceNumber))
    );
  }
  return str;
};

export const compareStringsIgnoreCase = (str1, str2) => {
  const normalizedStr1 = normalizeString(str1);
  const normalizedStr2 = normalizeString(str2);
  return normalizedStr1 > normalizedStr2
    ? 1
    : normalizedStr1 < normalizedStr2
    ? -1
    : 0;
};

export const compareStringsEqualsIgnoreCase = (str1, str2) => {
  return normalizeString(str1) === normalizeString(str2);
};

/**
 * Converts a string of comma-separated numbers into an array of numbers.
 *
 * @param {string} str - The input string of comma-separated numbers.
 * @return {number[]} An array of numbers parsed from the input string.
 */
export const stringToNumberArray = (str) => {
  if (isEmptyValue(str)) {
    return [];
  }
  return str
    ?.trim()
    .split(',')
    .map((item) => Number(item));
};

export const toCamelCase = (str) => {
  if (isEmptyValue(str)) {
    return '';
  }

  return str
    .toLowerCase() // Convert the entire string to lowercase
    .split(' ') // Split the string by spaces
    .map((word, index) => {
      if (index === 0) {
        return word; // Leave the first word lowercase
      }
      // Capitalize the first letter of the remaining words
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join(''); // Join all the words back together
};

export const toUpperCase = (str) => {
  if (isEmptyValue(str)) {
    return '';
  }

  return str.toUpperCase();
};

export const toLowerCaseString = (str) => {
  if (isEmptyValue(str)) {
    return '';
  }

  return `${str}`.trim().toLowerCase();
};

/**
 * Capitalize first string of a string.
 *
 * @param {string} str - The input string of comma-separated numbers.
 * @return {string} The capitalized string.
 */
export const capitalizeString = (str) => {
  if (isEmptyValue(str)) return '';
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const isNilString = (value) => {
  if (typeof value !== 'string') {
    return false;
  }

  if (isEmptyValue(value)) {
    return false;
  }

  return toLowerCaseString(value).trim() === 'nil';
};
