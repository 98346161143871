import { GET_SUPPLIER_ADMIN_CONTROL } from '../actions/supplierControlSettings';

const defaultState = {
  allowViewDashboard: 0,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case GET_SUPPLIER_ADMIN_CONTROL: {
      return {
        ...state,
        ...action.payload,
      };
    }
    default:
      return {
        ...state,
      };
  }
};
