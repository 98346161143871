import { Grid } from '@material-ui/core';
import MDEditor from '@uiw/react-md-editor';
// No import is required in the WebPack.
import '@uiw/react-md-editor/markdown-editor.css';
// No import is required in the WebPack.
import '@uiw/react-markdown-preview/markdown.css';

import { FtrTextField } from '../../ftr-components';
import FtrFieldLabel from '../../ftr-components/FtrFieldLabel';
import FtrNumberField from '../../ftr-components/FtrNumberField';

import DragDrop from '../../DragDrop';

const TitleBlockForm = (props) => {
  const { updateTitleblockState, handleChange, titleblockState } = props;

  const handleExtraNoteImageUpload = (files) => {
    Promise.all(
      Array.from(files).map((file) => {
        const reader = new FileReader();
        reader.onload = () => {
          updateTitleblockState({
            extraNote: `<img src="${reader.result}"/>`,
          });
        };
        reader.readAsDataURL(file);
      })
    );
  };

  return (
    <Grid item xs={6} style={{ maxHeight: '800px', overflowY: 'scroll' }}>
      <FtrTextField
        name='title'
        label={'Title'}
        id='title'
        onChange={handleChange('title')}
        value={titleblockState.title}
      />
      <FtrTextField
        name='itemID'
        label={'Part ID'}
        id='itemID'
        onChange={handleChange('itemID')}
        value={titleblockState.itemID}
      />
      <FtrTextField
        name='material'
        label={'Material'}
        id='material'
        onChange={handleChange('material')}
        value={titleblockState.material}
      />
      <FtrTextField
        name='color-surface-finish'
        label={'Finish'}
        id='color-surface-finish'
        onChange={handleChange('colorSurfaceFinish')}
        value={titleblockState.colorSurfaceFinish}
      />
      <FtrNumberField
        name='quantity'
        label={'Quantity'}
        id='quantity'
        onChange={handleChange('quantity')}
        value={titleblockState.quantity}
        minValue={1}
      />
      <FtrNumberField
        name='tolerance'
        label={'Tightest tolerance'}
        id='tolerance'
        onChange={handleChange('tolerance')}
        value={titleblockState.tolerance}
        minValue={0}
      />
      <FtrTextField
        name='name'
        label={'POC Name'}
        id='name'
        onChange={handleChange('name')}
        value={titleblockState.name}
      />
      <FtrTextField
        name='contact'
        label={'POC Number'}
        id='contact'
        onChange={handleChange('contact')}
        value={titleblockState.contact}
      />
      <FtrTextField
        name='address'
        label={'Address'}
        id='address'
        onChange={handleChange('address')}
        value={titleblockState.address}
      />
      <FtrTextField
        name='region'
        label={'Region'}
        id='region'
        onChange={handleChange('region')}
        value={titleblockState.region}
      />
      <FtrTextField
        name='rev'
        label={'Revision'}
        id='rev'
        onChange={handleChange('rev')}
        value={titleblockState.rev}
      />
      <FtrTextField
        name='scale'
        label={'Scale'}
        id='scale'
        onChange={handleChange('scale')}
        value={titleblockState.scale}
      />
      <FtrTextField
        name='paperSize'
        label={'Size'}
        id='paperSize'
        onChange={handleChange('paperSize')}
        value={titleblockState.paperSize}
      />
      <FtrFieldLabel>Main note</FtrFieldLabel>
      <div data-color-mode='light'>
        <MDEditor
          height={200}
          value={titleblockState.mainNote}
          onChange={(value) => {
            updateTitleblockState({
              mainNote: value,
            });
          }}
          preview='edit'
        />
      </div>
      <div style={{ margin: '2rem 0' }}>
        <DragDrop
          text='Extra note image'
          handleFiles={handleExtraNoteImageUpload}
          multiple={false}
        />
        <FtrFieldLabel>Extra note</FtrFieldLabel>
        <div data-color-mode='light'>
          <MDEditor
            height={200}
            value={titleblockState.extraNote}
            onChange={(value) => {
              updateTitleblockState({
                extraNote: value,
              });
            }}
            preview='edit'
          />
        </div>
      </div>
    </Grid>
  );
};

export default TitleBlockForm;
