import React, { useContext, useReducer } from 'react';

import LeadTimeFeedbackSupplierPopup from '../components/popups/LeadTimeFeedbackSupplierPopup';

import { isEmptyValue } from '../utils/commonUtils';

import AppContext from '../context/AppContext';

// -------------------------------------------------------------------------------------------------

function withLeadTimeFeedbackSupplierPopupHOC(WrappedComponent) {
  function WrappedComponentWithHOC(props) {
    const { setSnackbarMessage } = useContext(AppContext);

    const defaultLocalState = {
      open: false,
    };

    const [localState, updateLocalState] = useReducer((prev, next) => {
      if (isEmptyValue(next)) {
        return defaultLocalState;
      }
      return { ...prev, ...next };
    }, defaultLocalState);

    const handleSubmit = (data) => {
      if (!isEmptyValue(data.feedbackDetails)) {
        data.feedbackDetails = JSON.stringify(data.feedbackDetails);
      }

      if (!isEmptyValue(data.targetDeliveryDate)) {
        data.targetDeliveryDate = new Date(data.targetDeliveryDate);
      }

      localState.upsertSupplierLeadTimeFeedback(data);

      setSnackbarMessage('Thanks for your feedback.');
    };

    return (
      <>
        <WrappedComponent
          updateLeadTimeFeedbackSupplierPopupHOCState={updateLocalState}
          {...props}
        />
        {localState.open && (
          <LeadTimeFeedbackSupplierPopup
            open={localState.open}
            deliveryDate={localState.deliveryDate}
            targetDeliveryDate={localState.targetDeliveryDate}
            feedbackDetails={localState.feedbackDetails}
            additionalRemarks={localState.additionalRemarks}
            onClose={() => updateLocalState({ open: false })}
            onSubmit={handleSubmit}
          />
        )}
      </>
    );
  }

  // Set the displayName for the HOC
  const wrappedComponentName =
    WrappedComponent.displayName || WrappedComponent.name || 'Component';
  WrappedComponentWithHOC.displayName = `withSignUpPopup(${wrappedComponentName})`;

  return WrappedComponentWithHOC;
}

export default withLeadTimeFeedbackSupplierPopupHOC;
