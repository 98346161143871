// Import settings
import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { withRouter, Redirect } from 'react-router-dom';
// import { Cookies } from "react-cookie";

// // Import material UI componetns
// import { makeStyles } from "@material-ui/core/styles";

// // import customised components
// import { colors } from "../../palette";

// import CustomerItemTable from "../../components/tables/CustomerItemTable";

// const cookies = new Cookies();

// const useStyles = makeStyles(() => ({
//   body: {
//     height: "100%"
//   },
//   select: {
//     "& .MuiSelect-select:focus": {
//       backgroundColor: colors.fontWhite
//     }
//   },
//   formComponent: {
//     height: "75%",
//     padding: "25px 60px"
//   }
// }));

export function ManageOrders(props) {
  // const classes = useStyles();
  // const { userID } = props;
  // const [allParts, setAllParts] = useState([]);

  // const getItems = id => {
  //   const requestUrl = `${process.env.REACT_APP_BACKEND_SERVICE}/buyer/all-parts/${id}`;
  //   return fetch(requestUrl, {
  //     method: "GET",
  //     headers: {
  //       "Content-Type": "application/json; charset=utf-8",
  //       Authorization: `Bearer ${cookies.get("token")}`
  //     }
  //   })
  //     .then(resp => {
  //       resp.json().then(response =>
  //         setAllParts(
  //           _.sortBy(
  //             response.data.filter(object => {
  //               return (
  //                 (object.status !== 0 && object.status !== 6) ||
  //                 object.paymentStatus === "pending"
  //               );
  //             }),
  //             item => item.itemID
  //           ).reverse()
  //         )
  //       );
  //     })
  //     .catch(e => console.log(e));
  // };

  // useEffect(() => {
  //   getItems(userID);
  // }, []);

  return (
    <Redirect
      to={{
        pathname: '/manage-projects',
        state: { from: props.location },
      }}
    />
    // <div className={classes.body}>
    //   <Fragment>
    //     <div className={classes.formComponent}>
    //       <CustomerItemTable
    //         data={allParts}
    //         getItems={getItems}
    //       />
    //     </div>
    //   </Fragment>
    // </div>
  );
}

function mapStateToProps(state) {
  return {
    userID: state.auth.user.userID,
  };
}

function matchDispatchToProps() {
  return {};
}

const withConnect = connect(mapStateToProps, matchDispatchToProps);

export default withRouter(withConnect(ManageOrders));
