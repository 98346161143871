// Import settings
import React from 'react';

// Import color palette
import { colors } from '../../palette';

// Import material UI components
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles/index';
import clsx from 'clsx';

// Style components
const useStyles = makeStyles(() => ({
  submitBtn: {
    textTransform: 'uppercase',
    background: colors.fontWhite,
    border: `solid 1px ${colors.inputBorderBlue}`,
    borderRadius: '5px',
    fontSize: '9pt',
    letterSpacing: '0.15em',
    color: colors.blue060,
    padding: '10px 15px',
    textAlign: 'center',
  },
  cancelEditBtn: {
    textTransform: 'uppercase',
    background: colors.fontWhite,
    border: `solid 1px ${colors.inputBorderBlue}`,
    letterSpacing: '0.15em',
    color: colors.blue060,
    textAlign: 'center',
    borderRadius: '0.3125rem',
    fontSize: '0.5625rem',
    padding: '0.625rem 0.9375rem',
  },
  fullWidth: {
    width: '100%',
  },
}));

function WhiteButton(props) {
  const classes = useStyles();
  const {
    btnContent,
    onBtnClick,
    component,
    to,
    fullWidth = false,
    type,
  } = props;
  return (
    <Button
      className={
        // cancelEdit type changes css style to match the other save edit button
        // in EditPartForm, without affecting css of white button elsewhere
        clsx(
          type === 'cancelEdit' ? classes.cancelEditBtn : classes.submitBtn,
          fullWidth ? classes.fullWidth : null
        )
      }
      onClick={onBtnClick}
      variant='contained'
      component={component ? component : 'span'}
      to={to ? to : null}
    >
      {btnContent}
    </Button>
  );
}

export default WhiteButton;
