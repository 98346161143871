import React, { useEffect } from 'react';

import { makeStyles } from '@material-ui/core/styles/index';

import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';

import { FtrButton, FtrH5, FtrLightText } from '../ftr-components';
import { FlexColumn } from '../layouts/FlexLayouts';

import { useUserFeedbackHook } from '../../hooks/useUserFeedbackHook';
import { usePrevious } from '../../hooks/usePrevious';

import { renderQuestionV2 } from '../../utils/userFeedbackUtils';
import { isEmptyValue } from '../../utils/commonUtils';

import { sendUserFeedback } from '../../apis/userFeedbackApi';

import { ROLE_TYPES } from '../../constants';

import { colors } from '../../palette';
import { USER_FEEDBACK_STATUSES } from '../../constants/userFeedbackConstants';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    backdropFilter: 'blur(3px)',
  },
  dialog: {
    padding: '1.5rem',
    borderRadius: '1.5rem',
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      padding: '1rem',
      borderRadius: '1rem',
      width: 'auto',
    },
  },
  title: {
    display: 'flex',
    fontSize: '22px',
    fontWeight: '700',
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
  },
  closeIcon: {
    position: 'absolute',
    right: '1.5rem',
    top: '1.5rem',
    color: colors.neutral060,
    cursor: 'pointer',
  },
}));

function UserFeedbackPopupV2(props) {
  const classes = useStyles();

  const {
    open,
    handleClose = () => {},
    userID,
    questionList = [],
    userRole = ROLE_TYPES.BUYER,
    submitLocation,
    checkoutID,
  } = props;

  const [
    { qAndA, hasAllQuestionAnswered },
    { updateAnswer, submitUserFeedback },
  ] = useUserFeedbackHook({ questionList, checkoutID });

  const getSubmitLocation = () => {
    const submitRoleLocation =
      userRole === ROLE_TYPES.SUPPLIER
        ? 'First order shipped'
        : 'First checkout';
    return submitLocation ?? submitRoleLocation;
  };

  const prevQAndA = usePrevious(qAndA);

  // for tracking unanswered feedback
  useEffect(() => {
    const allowedRoles = [ROLE_TYPES.BUYER, ROLE_TYPES.SUPPLIER];
    const isInitialDataPopulated =
      isEmptyValue(prevQAndA) && !isEmptyValue(qAndA);
    if (allowedRoles.includes(userRole) && isInitialDataPopulated) {
      const body = {
        qAndA,
        submitLocation: getSubmitLocation(),
        checkoutID,
        status: USER_FEEDBACK_STATUSES.UNANSWERED,
      };
      sendUserFeedback(userID, body);
    }
  }, [qAndA]);

  const renderBody = () => {
    return (
      <FlexColumn
        style={{
          gap: '1.5rem',
        }}
      >
        {qAndA?.map((question) => {
          return renderQuestionV2({
            question,
            handler: updateAnswer,
          });
        })}
      </FlexColumn>
    );
  };

  return (
    <Dialog
      open={open}
      maxWidth='md'
      scroll='body'
      BackdropProps={{
        classes: {
          root: classes.backdrop,
        },
      }}
      PaperProps={{ className: classes.dialog }}
    >
      <DialogTitle disableTypography className={classes.title}>
        <FlexColumn>
          <FtrH5>Just a minute for your feedback</FtrH5>
          <FtrLightText>
            We’re constantly working on improving experience at Factorem and
            value your feedback.
          </FtrLightText>
        </FlexColumn>
      </DialogTitle>
      <div
        style={{
          padding: '1rem 0 1rem',
        }}
      >
        <DialogContent
          style={{
            padding: '0 30px',
            paddingBottom: '2rem',
          }}
        >
          {renderBody()}
        </DialogContent>
        <div
          style={{
            display: 'flex',
            justifyContent: 'end',
            paddingRight: '1rem',
          }}
        >
          <FtrButton
            color='blue'
            onClick={() => {
              const _submitLocation = getSubmitLocation();
              submitUserFeedback(userID, _submitLocation);
              handleClose();
            }}
            disabled={!hasAllQuestionAnswered}
          >
            Submit
          </FtrButton>
        </div>
      </div>
    </Dialog>
  );
}

export default UserFeedbackPopupV2;
