// Import settings
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

// Import material UI components
import { Button, Divider, Grid, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles/index';

// Import actions
import { getOrderDetails } from '../actions';

const useStyles = makeStyles((theme) => ({
  body: {
    padding: theme.spacing(5),
    paddingTop: theme.spacing(3),
  },
  card: {
    minWidth: 340,
    maxWidth: 380,
  },
  media: {
    height: 160,
  },
  container: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  property: {
    marginTop: '20px',
  },
}));

export function Order(props) {
  const { match, onPageLoad, order } = props;
  const classes = useStyles();
  useEffect(() => {
    onPageLoad(match.params.orderID);
  }, []);

  return (
    <div className={classes.body}>
      <Paper
        elevation={1}
        variant='outlined'
        style={{ padding: '70px', margin: '0 50px' }}
      >
        <div className={classes.container}>
          <Grid
            container
            fluid='true'
            spacing={3}
            direction='column'
            style={{ marginRight: '70px' }}
          >
            <Grid item>
              {order.name ? (
                <Typography
                  gutterBottom
                  variant='h5'
                  style={{ fontWeight: 'bold', margin: '6px 0' }}
                >
                  {order.name}
                </Typography>
              ) : (
                <div />
              )}
              <Divider />
              <div style={{ marginTop: '14px' }}>
                {order.quotationID ? (
                  <div className={classes.property}>
                    <Typography
                      gutterBottom
                      variant='body1'
                      style={{ fontWeight: 'bold', margin: 0 }}
                    >
                      ID
                    </Typography>
                    <Typography gutterBottom variant='body2'>
                      {order.quotationID}
                    </Typography>
                  </div>
                ) : (
                  <div />
                )}
                {order.status ? (
                  <div className={classes.property}>
                    <Typography
                      gutterBottom
                      variant='body1'
                      style={{ fontWeight: 'bold', margin: 0 }}
                    >
                      Status
                    </Typography>
                    <Typography gutterBottom variant='body2'>
                      {order.status}
                    </Typography>
                  </div>
                ) : (
                  <div />
                )}
                {order.dateOfOrder ? (
                  <div className={classes.property}>
                    <Typography
                      gutterBottom
                      variant='body1'
                      style={{ fontWeight: 'bold', margin: 0 }}
                    >
                      Date of Quote
                    </Typography>
                    <Typography gutterBottom variant='body2'>
                      {order.dateOfOrder.substring(0, 10)}
                    </Typography>
                  </div>
                ) : (
                  <div />
                )}
                {order.material ? (
                  <div className={classes.property}>
                    <Typography
                      gutterBottom
                      variant='body1'
                      style={{ fontWeight: 'bold', margin: 0 }}
                    >
                      Material
                    </Typography>
                    <Typography gutterBottom variant='body2'>
                      {order.material}
                    </Typography>
                  </div>
                ) : (
                  <div />
                )}
                {order.priceBidded ? (
                  <div className={classes.property}>
                    <Typography
                      gutterBottom
                      variant='body1'
                      style={{ fontWeight: 'bold', margin: 0 }}
                    >
                      Price bidded
                    </Typography>
                    <Typography gutterBottom variant='body2'>
                      ${order.priceBidded}
                    </Typography>
                  </div>
                ) : (
                  <div />
                )}
                {order.remarks ? (
                  <div className={classes.property}>
                    <Typography
                      gutterBottom
                      variant='body1'
                      style={{ fontWeight: 'bold', margin: 0 }}
                    >
                      Remarks
                    </Typography>
                    <Typography gutterBottom variant='body2'>
                      {order.remarks}
                    </Typography>
                  </div>
                ) : (
                  <div />
                )}
                <div style={{ marginTop: '30px' }}>
                  <Button
                    variant='contained'
                    color='primary'
                    component={Link}
                    to={`/items/${order.itemID}`}
                  >
                    View Item
                  </Button>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </Paper>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    order: state.order.order,
  };
}

function matchDispatchToProps(dispatch) {
  return {
    onPageLoad: (orderID) => dispatch(getOrderDetails(orderID)),
  };
}

const withConnect = connect(mapStateToProps, matchDispatchToProps);

export default withConnect(Order);
