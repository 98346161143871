import { UNIT_TYPES } from './unitConstants';

export const TECHNOLOGY_OPTION_TYPE = {
  CNC_MACHINING: 'CNC Machining',
  THREE_D_PRINTING: '3D Printing',
  SHEET_METAL_FABRICATION: 'Sheet Metal Fabrication',
  DESIGN_SERVICE: 'Design Service',
  TREATMENT: 'Treatment',
  INJECTION_MOLDING: 'Injection Molding',
  URETHANE_CASTING: 'Urethane Casting',
  NOT_SURE: 'Not Sure',
  OTHERS: 'Others',
  CUSTOM_FABRICATION_TECHNOLOGY: 'Custom Fabrication Technology',
};

export const TECHNOLOGY_OPTIONS = [
  TECHNOLOGY_OPTION_TYPE.CNC_MACHINING,
  TECHNOLOGY_OPTION_TYPE.THREE_D_PRINTING,
  TECHNOLOGY_OPTION_TYPE.SHEET_METAL_FABRICATION,
  TECHNOLOGY_OPTION_TYPE.DESIGN_SERVICE,
  TECHNOLOGY_OPTION_TYPE.INJECTION_MOLDING,
  TECHNOLOGY_OPTION_TYPE.URETHANE_CASTING,
  TECHNOLOGY_OPTION_TYPE.NOT_SURE,
  TECHNOLOGY_OPTION_TYPE.OTHERS,
  TECHNOLOGY_OPTION_TYPE.CUSTOM_FABRICATION_TECHNOLOGY,
];

export const ONBOARDING_TECHNOLOGY_OPTION_TYPE = {
  'CNC Machining (Metals)': 'CNC Machining',
  'CNC Machining (Plastics)': 'CNC Machining',
  '3D Printing': '3D Printing',
  '3D Printing & Additive Manufacturing': '3D Printing',
  FDM: '3D Printing',
  SLA: '3D Printing',
  SLS: '3D Printing',
  MJF: '3D Printing',
  SLM: '3D Printing',
  DMLS: '3D Printing',
  EBF: '3D Printing',
  LMD: '3D Printing',
  APF: '3D Printing',
  'Sheet Metal Fabrication': 'Sheet Metal Fabrication',
  'Design Service': 'Design Service',
  'Design Service/ Consultancy': 'Design Service',
  'Injection Molding': 'Injection Molding',
  'Vacuum Casting': 'Vacuum Casting',
  Other: 'Others',
};

export const TECH_KEY_NAME_MAPPING = Object.values(
  TECHNOLOGY_OPTION_TYPE
).reduce((acc, value) => {
  acc[value.toLowerCase()] = value;
  return acc;
}, {});

export const PART_APPLICATION_TECHS = [
  TECHNOLOGY_OPTION_TYPE.SHEET_METAL_FABRICATION,
  TECHNOLOGY_OPTION_TYPE.DESIGN_SERVICE,
  TECHNOLOGY_OPTION_TYPE.INJECTION_MOLDING,
  TECHNOLOGY_OPTION_TYPE.URETHANE_CASTING,
  TECHNOLOGY_OPTION_TYPE.NOT_SURE,
  TECHNOLOGY_OPTION_TYPE.CUSTOM_FABRICATION_TECHNOLOGY,
];

export const ALUMINUM_6061 = 'Aluminum 6061';

export const MATERIAL_OPTIONS = {
  'CNC Machining': [
    'ABS',
    'Acrylic',
    'Aluminum 2024',
    'Aluminum 6061',
    'Aluminum 6061-T6',
    'Aluminum 6082',
    'Aluminum 7075',
    'Aluminum 7075-T6',
    'Alloy Steel 4140',
    'Mild Steel A36',
    'Mild Steel 1018',
    'Stainless Steel 303',
    'Stainless Steel 304',
    'Stainless Steel 316',
    'Delrin',
    'Brass C360',
    'Copper 101',
    'Polycarbonate',
    'Others',
  ],
  '3D Printing': [
    'ABS-M30',
    'Accura Xtreme',
    'Photopolymer',
    'TPU 95A',
    'Standard PLA',
    'Standard ABS',
    'Standard resin',
    'Standard PETG',
    'Standard ASA',
    'Standard Nylon',
    'Polycarbonate',
    'SLS Nylon',
    'Nylon',
    'Polyamide Nylon',
    'Standard TPU',
    'Markforged Onyx',
    'Stainless Steel',
    'Stainless 17-4',
    'Titanium',
    'Aluminium (AlSi10Mg)',
    'Others',
  ],
  'Sheet Metal Fabrication': [
    'Acrylic',
    'Aluminum 5052',
    'Aluminum 6061',
    'Aluminum 6061-T6',
    'Copper 101',
    'Copper 260',
    'Stainless Steel 316L',
    'Steel 1008',
    'Steel 1008/10',
    'Raw Steel S235',
    'Delrin',
    'Others',
  ],
  'Design Service': ['Others'],
  'Not Sure': ['Others'],
  Others: ['Others'],
};

export const THREE_D_P_FDM_TECH = 'Fused Deposit Modelling (FDM)';
export const THREE_D_P_LMD_TECH = 'Laser Metal Deposition (LMD)';
export const THREE_D_P_MJF_TECH = 'Multi Jet Fusion (MJF)';
export const THREE_D_P_SLA_TECH = 'Stereolithography (SLA)';
export const THREE_D_P_OLD_SLA_VALUE_TECH = 'Laser Stereolithography (SLA)';
export const THREE_D_P_SLS_TECH = 'Selective Laser Sintering (SLS)';
export const THREE_D_P_SLM_TECH = 'Selective Laser Melting (SLM)';
export const THREE_D_P_DMLS_TECH = 'Direct Metal Laser Sintering (DMLS)';
export const THREE_D_P_EBM_TECH = 'Electron-Beam Melting (EBM)';
export const THREE_D_P_APF_TECH = 'Freeforming (APF)';

export const THREE_D_P_TECH_OPTIONS = [
  THREE_D_P_FDM_TECH,
  THREE_D_P_LMD_TECH,
  THREE_D_P_MJF_TECH,
  THREE_D_P_SLA_TECH,
  THREE_D_P_SLS_TECH,
  THREE_D_P_SLM_TECH,
];

export const THREE_D_P_TECH_ABBRE_MAPPING = {
  [THREE_D_P_FDM_TECH]: 'FDM',
  [THREE_D_P_LMD_TECH]: 'LMD',
  [THREE_D_P_MJF_TECH]: 'MJF',
  [THREE_D_P_SLA_TECH]: 'SLA',
  [THREE_D_P_SLS_TECH]: 'SLS',
  [THREE_D_P_SLM_TECH]: 'SLM',
  [THREE_D_P_DMLS_TECH]: 'DMLS',
  [THREE_D_P_EBM_TECH]: 'EBM',
  [THREE_D_P_APF_TECH]: 'APF',
};

/**
 * @deprecated
 */
export const toleranceOptions = {
  'CNC Machining': '0.1',
  'Sheet Metal Fabrication': '0.3',
};

/**
 * @deprecated
 */
export const threeDToleranceOptions = {
  [THREE_D_P_FDM_TECH]: '0.5',
  [THREE_D_P_LMD_TECH]: '0.3',
  [THREE_D_P_MJF_TECH]: '0.3',
  [THREE_D_P_SLA_TECH]: '0.1',
  [THREE_D_P_SLS_TECH]: '0.3',
  [THREE_D_P_SLM_TECH]: '0.3',
  [THREE_D_P_DMLS_TECH]: '0.3',
  [THREE_D_P_EBM_TECH]: '0.3',
  [THREE_D_P_APF_TECH]: '0.3',
};

/**
 * @deprecated
 */
export const standardToleranceOptions = [
  '0.01',
  '0.051',
  '0.076',
  '0.1',
  '0.127',
  '0.3',
  '0.5',
];

export const STANDARD_TOLERANCE_OPTIONS = {
  [UNIT_TYPES.METRIC]: [
    '0.508', // Default 3DP (FDM)
    '0.254', // Default SMF, 3DP (LMD,MJF,SLS,SLM,DMLS,EBM,APF)
    '0.127',
    '0.1', // Default CNC, 3DP (SLA)
    '0.076',
    '0.051',
    '0.025',
    '0.01',
  ],
  [UNIT_TYPES.IMPERIAL]: [
    '0.02', // Default 3DP (FDM)
    '0.01', // Default SMF, 3DP (LMD,MJF,SLS,SLM,DMLS,EBM,APF)
    '0.005',
    '0.004', // Default CNC, 3DP (SLA)
    '0.003',
    '0.002',
    '0.001',
    '0.0004',
  ],
};

export const TOLERANCE_OPTIONS = {
  [UNIT_TYPES.METRIC]: {
    [TECHNOLOGY_OPTION_TYPE.CNC_MACHINING]:
      STANDARD_TOLERANCE_OPTIONS[UNIT_TYPES.METRIC][3],
    [TECHNOLOGY_OPTION_TYPE.SHEET_METAL_FABRICATION]:
      STANDARD_TOLERANCE_OPTIONS[UNIT_TYPES.METRIC][1],
  },
  [UNIT_TYPES.IMPERIAL]: {
    [TECHNOLOGY_OPTION_TYPE.CNC_MACHINING]:
      STANDARD_TOLERANCE_OPTIONS[UNIT_TYPES.IMPERIAL][3],
    [TECHNOLOGY_OPTION_TYPE.SHEET_METAL_FABRICATION]:
      STANDARD_TOLERANCE_OPTIONS[UNIT_TYPES.IMPERIAL][1],
  },
};

export const THREE_D_TOLERANCE_OPTIONS = {
  [UNIT_TYPES.METRIC]: {
    [THREE_D_P_FDM_TECH]: STANDARD_TOLERANCE_OPTIONS[UNIT_TYPES.METRIC][0],
    [THREE_D_P_LMD_TECH]: STANDARD_TOLERANCE_OPTIONS[UNIT_TYPES.METRIC][1],
    [THREE_D_P_MJF_TECH]: STANDARD_TOLERANCE_OPTIONS[UNIT_TYPES.METRIC][1],
    [THREE_D_P_SLA_TECH]: STANDARD_TOLERANCE_OPTIONS[UNIT_TYPES.METRIC][3],
    [THREE_D_P_SLS_TECH]: STANDARD_TOLERANCE_OPTIONS[UNIT_TYPES.METRIC][1],
    [THREE_D_P_SLM_TECH]: STANDARD_TOLERANCE_OPTIONS[UNIT_TYPES.METRIC][1],
    [THREE_D_P_DMLS_TECH]: STANDARD_TOLERANCE_OPTIONS[UNIT_TYPES.METRIC][1],
    [THREE_D_P_EBM_TECH]: STANDARD_TOLERANCE_OPTIONS[UNIT_TYPES.METRIC][1],
    [THREE_D_P_APF_TECH]: STANDARD_TOLERANCE_OPTIONS[UNIT_TYPES.METRIC][1],
  },
  [UNIT_TYPES.IMPERIAL]: {
    [THREE_D_P_FDM_TECH]: STANDARD_TOLERANCE_OPTIONS[UNIT_TYPES.IMPERIAL][0],
    [THREE_D_P_LMD_TECH]: STANDARD_TOLERANCE_OPTIONS[UNIT_TYPES.IMPERIAL][1],
    [THREE_D_P_MJF_TECH]: STANDARD_TOLERANCE_OPTIONS[UNIT_TYPES.IMPERIAL][1],
    [THREE_D_P_SLA_TECH]: STANDARD_TOLERANCE_OPTIONS[UNIT_TYPES.IMPERIAL][3],
    [THREE_D_P_SLS_TECH]: STANDARD_TOLERANCE_OPTIONS[UNIT_TYPES.IMPERIAL][1],
    [THREE_D_P_SLM_TECH]: STANDARD_TOLERANCE_OPTIONS[UNIT_TYPES.IMPERIAL][1],
    [THREE_D_P_DMLS_TECH]: STANDARD_TOLERANCE_OPTIONS[UNIT_TYPES.IMPERIAL][1],
    [THREE_D_P_EBM_TECH]: STANDARD_TOLERANCE_OPTIONS[UNIT_TYPES.IMPERIAL][1],
    [THREE_D_P_APF_TECH]: STANDARD_TOLERANCE_OPTIONS[UNIT_TYPES.IMPERIAL][1],
  },
};

let _toleranceImperialToMetric = {};
let _toleranceMetricToImperial = {};
STANDARD_TOLERANCE_OPTIONS[UNIT_TYPES.METRIC].forEach((metricValue, index) => {
  const imperialValue = STANDARD_TOLERANCE_OPTIONS[UNIT_TYPES.IMPERIAL][index];
  _toleranceImperialToMetric[imperialValue] = metricValue;
  _toleranceMetricToImperial[metricValue] = imperialValue;
});
export const TOLERANCE_IMPERIAL_TO_METRIC = _toleranceImperialToMetric;
export const TOLERANCE_METRIC_TO_IMPERIAL = _toleranceMetricToImperial;

export const surfaceFinishOptions = [
  'NIL',
  'Anodizing Type II',
  'Anodizing Type III (Hardcoat)',
  'Sanding',
  'Tumble Polishing',
  'Polishing',
  'Painting',
  'Bead Blasting',
  'Powder Coating',
  'Others',
];

export const THREE_D_SURFACE_OPTIONS = [
  'NIL',
  'Bead Blasting',
  'Chemical Vapor Smoothing',
  'Flat Matte',
  'Metallic Shine',
  'Nickel Plating',
  'Painting',
  'Tumble Polishing',
  'UV Protection Coating',
  'Others',
];

export const deliveryOptions = [
  {
    value: 'on_premise',
    label: 'On-Site Delivery',
  },
  // {
  //   value: "self_collect",
  //   label: "Self-collection"
  // }
];

export const threeDPrintingInfillDefault = 0.2;

export const threeDPrintingLayerThicknessDefault = 0.2;

const DEFAULT_DESCRIPTION =
  'Please upload 1 file for each component. Additional files can be added at next stage';
const TWO_D_FILES_TEXT =
  '2D files (.pdf) can be uploaded at next page as you configure your order';

export const SUPPORT_FILES_TEXT_MAPPING = {
  [TECHNOLOGY_OPTION_TYPE.CNC_MACHINING]: {
    supportFileTypes: [
      'step',
      'stp',
      'x_t',
      'sldprt',
      '3dxml',
      'catpart',
      'ipt',
    ],
    descriptionText: TWO_D_FILES_TEXT,
  },
  [TECHNOLOGY_OPTION_TYPE.SHEET_METAL_FABRICATION]: {
    supportFileTypes: [
      'step',
      'stp',
      'x_t',
      'sldprt',
      '3dxml',
      'catpart',
      'ipt',
      'dxf',
      'dwg',
    ],
    descriptionText: TWO_D_FILES_TEXT,
  },
  [TECHNOLOGY_OPTION_TYPE.THREE_D_PRINTING]: {
    supportFileTypes: ['step', 'stp', 'stl', 'obj', 'aml', '3mf'],
    descriptionText: TWO_D_FILES_TEXT,
  },
  [TECHNOLOGY_OPTION_TYPE.TREATMENT]: {
    supportFileTypes: [
      'step',
      'stp',
      'x_t',
      'sldprt',
      '3dxml',
      'catpart',
      'ipt',
      'dxf',
      'dwg',
      'pdf',
    ],
    descriptionText: DEFAULT_DESCRIPTION,
  },
};

export const ALL_FILES_TYPES = ['All File Types'];

// combination of all unique file types accepted
export const ALL_3D_DESIGN_SUPPORT_FILES_TYPES = [
  'step',
  'stp',
  'x_t',
  'sldprt',
  '3dxml',
  'catpart',
  'ipt',
  'dxf',
  'dwg',
  'stl',
  'obj',
  'aml',
  '3mf',
];

export const ALLOWED_DEFAULT_FILE_TYPES = ['pdf', 'zip'];

export const ALL_ACCEPTED_FILES_TYPES = [
  ...ALL_3D_DESIGN_SUPPORT_FILES_TYPES,
  ...ALLOWED_DEFAULT_FILE_TYPES,
];

export const DEFAULT_SUPPORT_FILES = {
  supportFileTypes: ALL_FILES_TYPES,
  descriptionText: DEFAULT_DESCRIPTION,
};

export const ALL_DESIGN_FILE_TYPES = [
  ...SUPPORT_FILES_TEXT_MAPPING[TECHNOLOGY_OPTION_TYPE.CNC_MACHINING]
    .supportFileTypes,
  ...SUPPORT_FILES_TEXT_MAPPING[TECHNOLOGY_OPTION_TYPE.SHEET_METAL_FABRICATION]
    .supportFileTypes,
  ...SUPPORT_FILES_TEXT_MAPPING[TECHNOLOGY_OPTION_TYPE.THREE_D_PRINTING]
    .supportFileTypes,
  ...SUPPORT_FILES_TEXT_MAPPING[TECHNOLOGY_OPTION_TYPE.TREATMENT]
    .supportFileTypes,
];

export const MAX_UPLOAD_FILES = 25;
export const MAX_UPLOAD_FILES_GUEST_USER = 25;

export const DEFAULT_SETTINGS = {
  technology: TECHNOLOGY_OPTION_TYPE.CNC_MACHINING,
  material: 'Aluminum 6061-T6',
  surfaceFinish: 'NIL',
};

export const MAX_UPLOAD_FILES_ERROR_MESSAGE = `Maximum ${MAX_UPLOAD_FILES} files are allowed. Please submit remaining files in a separate project - upload and rearrange items later.`;
