import React from 'react';
import { useSelector } from 'react-redux';
import {
  MenuItem,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
} from '@material-ui/core';
import { get } from 'lodash';

import BlueButton from '../../components/buttons/BlueButton';
import { FtrTypography } from '../../components/ftr-components';

import { isAdminOrHigherRole } from '../../utils/roleUtils';

import useDeviceCheck from '../../hooks/useDeviceCheck';

import { getUserSelector } from '../../selectors/userSelector';

import {
  ORDER_READY_RESOURCE_TYPES,
  ORDER_READY_STATUS,
  SHIPMENT_PROVIDERS,
} from '../../constants/orderReadyConstants';
import { FlexColumn } from '../../components/layouts/FlexLayouts';
import { isEmptyValue } from '../../utils/commonUtils';

const NextSteps = ({
  localState,
  handleOrderShipped,
  onUpdateShipmentInfo,
  onUpdateIsTrackingNumberNA,
}) => {
  const [{ isMobile }] = useDeviceCheck();

  const { role: userRole } = useSelector(getUserSelector);

  const itemOrderReadyList = localState.itemOrderReadyList || [];
  const readyToShipItems = itemOrderReadyList.filter(
    (itemOrderReady) =>
      itemOrderReady.info.status === ORDER_READY_STATUS.READY_TO_SHIP
  );

  const projectOrderReadyStatus = get(localState.projectOrderReadyInfo, [
    'info',
    'status',
  ]);

  if (
    readyToShipItems.length === 0 ||
    projectOrderReadyStatus !== ORDER_READY_STATUS.READY_TO_SHIP
  ) {
    return null;
  }

  const renderOrderShippedButton = () => {
    const deliveryDocs = (
      localState.projectOrderReadyInfo?.info?.resources || []
    ).filter((resource) =>
      [
        ORDER_READY_RESOURCE_TYPES.DELIVERY_ORDER,
        ORDER_READY_RESOURCE_TYPES.WAYBILL,
      ].includes(resource.type)
    );

    const hasPackageDimensions =
      !isEmptyValue(localState.packageLength) &&
      !isEmptyValue(localState.packageWidth) &&
      !isEmptyValue(localState.packageHeight) &&
      !isEmptyValue(localState.packageWeight);

    const hasDownloadDeliverFiles =
      localState.hasDownloadDeliverFiles || localState.noDeliveryDocRequired;

    const buttonTooltip = !hasDownloadDeliverFiles
      ? `Please download delivery document${deliveryDocs.length > 1 ? 's' : ''} above before shipping`
      : !hasPackageDimensions
        ? 'Please complete the packaging information above to proceed'
        : '';

    const isDisabled =
      (!isAdminOrHigherRole(userRole) &&
        (!hasDownloadDeliverFiles || !hasPackageDimensions)) ||
      localState.orderShippedProcessing;

    return (
      <BlueButton
        size={isMobile ? 'small' : 'large'}
        onBtnClick={handleOrderShipped}
        btnContent='Order Shipped'
        disabled={isDisabled}
        tooltip={buttonTooltip}
        loading={localState.orderShippedProcessing}
        tooltipPlacement='right-end'
      />
    );
  };

  const renderShipmentInfoForm = () => {
    return (
      localState.isNonSgpSupplier && (
        <FlexColumn
          style={{
            gap: '1rem',
            maxWidth: 800,
          }}
        >
          <Grid container spacing={1}>
            <Grid container spacing={1}>
              <Grid item xs={12} md={6}>
                <TextField
                  labelId='shipment-provider'
                  label='Shipment Provider'
                  id='shipment-provider'
                  variant='outlined'
                  select
                  onChange={onUpdateShipmentInfo('shipmentProvider')}
                  margin='dense'
                  fullWidth
                  error={!!localState.errors.shipmentProvider}
                  helperText={localState.errors.shipmentProvider}
                >
                  {SHIPMENT_PROVIDERS.map((shipment) => {
                    return (
                      <MenuItem key={shipment} value={shipment}>
                        {shipment}
                      </MenuItem>
                    );
                  })}
                </TextField>
              </Grid>
              {localState.shipmentProvider === 'Other' && (
                <Grid item xs={12} md={6}>
                  <TextField
                    id='other-shipment-provider'
                    label='Other Shipment Provider'
                    variant='outlined'
                    fullWidth
                    size='small'
                    margin='dense'
                    onChange={onUpdateShipmentInfo('otherShipmentProvider')}
                    error={!!localState.errors.otherShipmentProvider}
                    helperText={localState.errors.otherShipmentProvider}
                  />
                </Grid>
              )}
            </Grid>
            <Grid item xs={6}>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name='tracking-number-na'
                      onChange={onUpdateIsTrackingNumberNA}
                    />
                  }
                  label='Shipment number not available'
                />
              </Grid>
              {localState.shipmentTrackingNA !== true && (
                <Grid item xs={12}>
                  <TextField
                    id='shipment-tracking-number'
                    label='Shipment Tracking Number'
                    variant='outlined'
                    fullWidth
                    size='small'
                    margin='dense'
                    onChange={onUpdateShipmentInfo('shipmentTrackingNumber')}
                    error={!!localState.errors.shipmentTrackingNumber}
                    helperText={localState.errors.shipmentTrackingNumber}
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
        </FlexColumn>
      )
    );
  };

  const renderPackageDimensionsForm = () => {
    return (
      <FlexColumn
        style={{
          gap: '1rem',
          maxWidth: 800,
        }}
      >
        <Grid container spacing={1}>
          <Grid item xs={12} md={6}>
            <TextField
              id='package-length'
              label='Package Length (cm)'
              variant='outlined'
              fullWidth
              size='small'
              margin='dense'
              onChange={onUpdateShipmentInfo('packageLength')}
              error={!!localState.errors.packageLength}
              helperText={localState.errors.packageLength}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              id='package-width'
              label='Package Width (cm)'
              variant='outlined'
              fullWidth
              size='small'
              margin='dense'
              onChange={onUpdateShipmentInfo('packageWidth')}
              error={!!localState.errors.packageWidth}
              helperText={localState.errors.packageWidth}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              id='package-height'
              label='Package Height (cm)'
              variant='outlined'
              fullWidth
              size='small'
              margin='dense'
              onChange={onUpdateShipmentInfo('packageHeight')}
              error={!!localState.errors.packageHeight}
              helperText={localState.errors.packageHeight}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              id='package-weight'
              label='Package Weight (kg)'
              variant='outlined'
              fullWidth
              size='small'
              margin='dense'
              onChange={onUpdateShipmentInfo('packageWeight')}
              error={!!localState.errors.packageWeight}
              helperText={localState.errors.packageWeight}
            />
          </Grid>
        </Grid>
      </FlexColumn>
    );
  };

  return (
    <>
      <div style={{ marginTop: '25px', fontWeight: 'bold' }}>Next Steps</div>
      <FlexColumn>
        <FtrTypography>
          {localState.isNonSgpSupplier
            ? "Please provide the following packaging information, shipment tracking number and shipment provider, and click 'Order Shipped' once the order has left your facility."
            : "Please provide the following packaging information and click 'Order Shipped' once the order has left your facility."}
        </FtrTypography>
        {renderShipmentInfoForm()}
        {renderPackageDimensionsForm()}
        {renderOrderShippedButton()}
      </FlexColumn>
    </>
  );
};

export default NextSteps;
