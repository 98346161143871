// Import settings
import React from 'react';

// Import customised components
import BlueButton from '../buttons/BlueButton';
import PartDetails from '../PartDetails';
import Title from '../Title';

// Import material UI components
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles/index';

// Style the components
const useStyles = makeStyles((theme) => ({
  paper: {
    minWidth: 800,
    borderRadius: 0,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

function ReviewPartPopup(props) {
  const classes = useStyles();
  const { itemToCreate, dialog, handleConfirmPart, handleClose } = props;

  return (
    <Dialog
      maxWidth='xl'
      open={dialog}
      onClose={handleClose}
      aria-labelledby='confirmation-dialog-title'
      classes={{ paper: classes.paper }}
    >
      <DialogTitle id='confirmation-dialog-title'>
        <Title contentTitle='Review your part' />
      </DialogTitle>
      <DialogContent>
        <PartDetails partInfo={itemToCreate} />
      </DialogContent>
      <DialogActions style={{ padding: 30, justifyContent: 'center' }}>
        <BlueButton
          onBtnClick={() => handleConfirmPart()}
          btnContent='Confirm Part'
        />
      </DialogActions>
      {dialog ? (
        <IconButton
          aria-label='close'
          className={classes.closeButton}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </Dialog>
  );
}

export default ReviewPartPopup;
