import {
  closeConfigurePart,
  closeSidebarMobile,
  openConfigurePart,
} from './tourUtils';
import { sleep } from '../../../utils/commonUtils';

import { GTM_ID_MAP } from '../../../constants/GTMConstants';

export const PART_UPLOAD_STEP_TWO_TOUR_INFO = {
  STEP_1: {
    STEP: '1',
    TITLE: 'Need to add more parts to the project?',
    ELEMENT: '#add-more-parts',
    TEXT: [
      'You can still add more parts by clicking here or dragging your files into this box.',
    ],
    OPTIONS: {
      extraShowAction: () => closeSidebarMobile(),
      extraContinueAction: () => closeSidebarMobile(),
      customBackClasses: 'visibility-hidden',
    },
  },
  STEP_2A: {
    STEP: '2A',
    TITLE: 'Quote in seconds!',
    ELEMENT: '#ppe-item-price',
    TEXT: ['Simply drag and drop multiple 3D files for your project here.'],
    OPTIONS: {
      onShow: () => {
        const itemPriceEl = document.querySelector('#ppe-item-price');
        if (itemPriceEl) {
          itemPriceEl.style.cssText = 'padding: 10px 20px';
        }
      },

      onHide: () => {
        const itemPriceEl = document.querySelector('#ppe-item-price');
        if (itemPriceEl) {
          itemPriceEl.style.cssText = '';
        }
      },
    },
  },
  STEP_2B: {
    STEP: '2B',
    TITLE: 'Manual RFQ',
    ELEMENT: '#manual-rfq-required',
    TEXT: [
      'We will take a closer look at these items and revert with a quotation and get back to you within 24 hours.',
    ],
  },
  STEP_3A: {
    STEP: '3A',
    ELEMENT: '#drawing-drag-drop',
    TITLE:
      'Upload Technical Drawing to configure your item automatically and get accurate quotes!',
    TEXT: [
      'The Factorem AI automatically reads your drawings to populate item configurations! Drag and drop your drawing here and let us take care of the rest.',
    ],
  },
  STEP_3B: {
    STEP: '3B',
    ELEMENT: '#specifications-column-tde-gen',
    TITLE: 'Automated data extraction',
    TEXT: [
      'We automatically extracted information from your PDF drawing to set up the part details. Please review it before moving forward. Upload your Technical Drawings (if any!).',
    ],
  },
  STEP_4: {
    STEP: '4',
    ELEMENT: '#configure-part-0-button',
    TITLE: 'Further customization needed?',
    TEXT: [
      'If you do not have any PDF file or need to make more changes to the part details, you can click this button.',
    ],
    OPTIONS: {
      extraShowAction: async () => closeConfigurePart(),
    },
  },
  STEP_5: {
    STEP: '5',
    ELEMENT: '#tolerance-field',
    TITLE: 'Set tightest tolerance',
    TEXT: [
      'Use this to define the tightest tolerances that we should work towards achieving.',
    ],
    OPTIONS: {
      beforeShowPromise: async () => sleep(1),
      extraShowAction: async () => openConfigurePart(),
    },
  },
  STEP_6: {
    STEP: '6',
    ELEMENT: '#remarks-field',
    TITLE: 'Anything else to add?',
    TEXT: [
      'Tell us anything else that we should know to best service your order with us!',
    ],
    OPTIONS: {
      extraShowAction: async () => openConfigurePart(),
    },
  },
  STEP_7: {
    STEP: '7',
    ELEMENT: '#target-price-and-lead-time-field',
    TITLE: 'Set your targets',
    TEXT: [
      'Easily set your target lead time and prices for us to find you the best match in manufacturing capacity.',
    ],
    OPTIONS: {
      extraShowAction: async () => openConfigurePart(),
    },
  },
  STEP_8: {
    STEP: '8',
    ELEMENT: '#return-to-quote-btn',
    TITLE: 'All done?',
    TEXT: [
      'Click here to save your changes once you are done configuring your part.',
    ],
    OPTIONS: {
      extraShowAction: async () => openConfigurePart(),
      extraContinueAction: async () => closeConfigurePart(),
    },
  },
  STEP_9: {
    STEP: '9',
    ELEMENT: '#certification-summary',
    TITLE: 'Add QC reports or other certifications',
    TEXT: [
      'Click here to add QC report and other certifications into your project.',
    ],
    OPTIONS: {
      extraContinueAction: async () => closeConfigurePart(),
      extraBackAction: async () => openConfigurePart(),
    },
  },
  STEP_10: {
    STEP: '10',
    ELEMENT: `#${GTM_ID_MAP.BTN_PROCEED_CREATE_PROJECT}`,
    TITLE: 'Proceed to review',
    TEXT: [
      'Click here once you are done with your configurations to review your items and quotes with Factorem.',
    ],
  },
};
