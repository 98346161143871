import { capitalize } from 'lodash';

import { upsertOrderReadyResource } from '../apis/orderReadyApi';

import { isEmptyValue } from './commonUtils';

import {
  notifyOngoing,
  updateNotification,
} from '../services/notificationService';
import { uploadFileToS3 } from '../services/s3Service';

import { ORDER_READY_RESOURCE_TYPES } from '../constants/orderReadyConstants';
import { S3_ORDER_READY_IMG_DIR_NAME } from '../constants';

// ------------------------------------------------------------------------------------------------

export const sortOrderReadyResource = (a, b) => {
  const aOrder = Object.values(ORDER_READY_RESOURCE_TYPES).indexOf(a.type);
  const bOrder = Object.values(ORDER_READY_RESOURCE_TYPES).indexOf(b.type);
  return aOrder - bOrder;
};

export const uploadOrderReadyResource = async ({
  itemOrderReadyID,
  resourceID,
  file,
  resourceType,
  porID,
}) => {
  const splitFilename = file.name.split('.');
  const preFilename = splitFilename.slice(0, -1).join('.');
  const fileExtension = splitFilename[splitFilename.length - 1];
  // projectOrderReadyID
  const s3Path = isEmptyValue(itemOrderReadyID)
    ? `${S3_ORDER_READY_IMG_DIR_NAME}/por-${porID}`
    : `${S3_ORDER_READY_IMG_DIR_NAME}/por-${porID}/ior-${itemOrderReadyID}`;
  const s3ObjectKey = `${s3Path}/${preFilename}_${Date.now()}.${fileExtension}`;
  const resourceName = capitalize(resourceType.replace(/_/g, ' '));
  const toastId = notifyOngoing(`Uploading ${resourceName}...`);
  const { size } = file;
  return uploadFileToS3(file, s3ObjectKey)
    .then(async (data) => {
      const url = data.Location.split(' ').join('%20');
      const body = {
        itemOrderReadyID,
        resourceID,
        fileURL: url,
        type: resourceType,
        fileSize: size,
      };
      try {
        await upsertOrderReadyResource(porID, body);
        updateNotification(
          toastId,
          `${resourceName} uploaded successfully.`,
          'success'
        );
      } catch {
        updateNotification(
          toastId,
          `${resourceName} uploaded failed.`,
          'error'
        );
      }
    })
    .catch(() => {
      updateNotification(toastId, `${resourceName} uploaded failed.`, 'error');
    });
};

export const isLegacyInvoice = (projectOrderReadyCreatedDate) => {
  const projectDate = new Date(projectOrderReadyCreatedDate);
  const cutoffDate = new Date('2024-07-20T00:00:00.000Z');

  return projectDate < cutoffDate;
};

/**
 * Returns true if an invoice is sent to Hubdoc, with handling for legacy ORMs.
 *
 * For legacy ORMs before the implemetation of invoiceSentToHubdoc, since
 * there is no tracking data, assume all such invoices are sent to Hubdoc.
 *
 * Note that this also means that missing invoices for legacy ORMs will also
 * be ignored if this util is used.
 */
export const isInvoiceProcessed = (
  projectOrderReadyCreatedDate,
  invoiceSentToHubdoc
) => {
  return isLegacyInvoice(projectOrderReadyCreatedDate) || invoiceSentToHubdoc;
};
