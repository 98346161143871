export const OMS_TECHNOLOGY_LIST = {
  CNC: ['Turning', 'Milling', '5-Axis', 'Die-sink EDM', 'Wirecut', 'Grinding'],
  SMF: [
    'Laser Cutting',
    'Waterjet Cutting',
    'Welding',
    'Bending',
    'Punching',
    'Stamping',
    'Tube Cutting',
  ],
  '3DP': ['FDM', 'SLA', 'MJF', 'SLM', 'DMLS', 'LMD', 'APF', 'DLP', 'WAAM'],
};
