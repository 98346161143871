import React, { useReducer } from 'react';

import SalesCustomersAssociationPopup from '../components/popups/SalesCustomersAssociationPopup';

import { isEmptyValue } from '../utils/commonUtils';

// -------------------------------------------------------------------------------------------------

function withSalesCustomersAssociationPopupHOC(WrappedComponent) {
  function WrappedComponentWithHOC(props) {
    const defaultLocalState = {
      open: false,
    };

    const [localState, updateLocalState] = useReducer((prev, next) => {
      if (isEmptyValue(next)) {
        return defaultLocalState;
      }
      return { ...prev, ...next };
    }, defaultLocalState);

    return (
      <>
        <WrappedComponent
          updateSalesCustomersAssociationPopupHOCState={updateLocalState}
          {...props}
        />
        {localState.open && (
          <SalesCustomersAssociationPopup
            open={localState.open}
            onClose={() => updateLocalState({ open: false })}
            userID={localState?.userID}
          />
        )}
      </>
    );
  }

  // Set the displayName for the HOC
  const wrappedComponentName =
    WrappedComponent.displayName || WrappedComponent.name || 'Component';
  WrappedComponentWithHOC.displayName = `withSignUpPopup(${wrappedComponentName})`;

  return WrappedComponentWithHOC;
}

export default withSalesCustomersAssociationPopupHOC;
