import React, { useReducer } from 'react';
import { Switch, FormControlLabel } from '@material-ui/core';

import YesNoPopup from '../components/popups/YesNoPopup';
import FinanceEmailInfo from '../components/info/FinanceEmailInfo';
import FinanceNoteInput from '../components/forms/FinanceNoteInput';

import {
  sendSupplierInvoiceToHubdoc,
  updateSupplierInvoiceInfo,
} from '../apis/supplierInvoiceApi';

import {
  notifyOngoing,
  updateNotification,
} from '../services/notificationService';
import { isEmptyValue } from '../utils/commonUtils';

// ---------------------------------------------------------------------------------

function withSendSupplierInvoiceToHubdocPopupHOC(WrappedComponent) {
  return function SendSupplierInvoiceToHubdocPopupHOC(props) {
    const { refreshFunc } = props;

    const [localState, updateLocalState] = useReducer(
      (prev, next) => {
        return { ...prev, ...next };
      },
      {
        open: false,
        supplierInvoiceInfoID: null,
        isInvoiceReconciled: false,
        financeNote: null,
      }
    );

    const isFinanceNoteRequired = !localState.isInvoiceReconciled;

    const InvoiceReconciledSwitch = () => {
      return (
        <FormControlLabel
          control={
            <Switch
              color='primary'
              defaultChecked={localState.isInvoiceReconciled}
              onChange={(e) => {
                updateLocalState({
                  isInvoiceReconciled: e.target.checked,
                });
              }}
              name='setIsInvoiceReconciled'
            />
          }
          label={
            <>
              The amount in this invoice{' '}
              <strong>
                {localState.isInvoiceReconciled ? 'matches' : 'does not match'}
              </strong>{' '}
              the PO&apos;s.
            </>
          }
        />
      );
    };

    const closePopup = () => {
      refreshFunc();
      updateLocalState({ open: false });
    };

    return (
      <>
        <WrappedComponent
          updateSupplierInvoiceInfoState={updateLocalState}
          {...props}
        />
        {localState.open && (
          <YesNoPopup
            title='Are you sure that you want to send this invoice to finance?'
            body={
              <>
                <FinanceNoteInput
                  financeNote={localState.financeNote}
                  setFinanceNote={(financeNote) =>
                    updateLocalState({ financeNote })
                  }
                  isRequired={isFinanceNoteRequired}
                />
                <div style={{ textAlign: 'left' }}>
                  <InvoiceReconciledSwitch />
                </div>
                <FinanceEmailInfo isForSupplier={true} />
              </>
            }
            open={localState.open}
            handleNo={() => updateLocalState({ open: false })}
            handleYes={async () => {
              const toastID = notifyOngoing('Sending invoice to finance');
              try {
                await updateSupplierInvoiceInfo(
                  localState.supplierInvoiceInfoID,
                  {
                    isInvoiceReconciled: localState.isInvoiceReconciled,
                  }
                );
                await sendSupplierInvoiceToHubdoc(
                  localState.supplierInvoiceInfoID,
                  { financeNote: localState.financeNote }
                );
                // a timeout is used here because the BE code emits an event to handle
                // sending an email and returns 200 immediately regardless of success or failure
                // to fix in the future by making this route return result of event emitter
                setTimeout(() => {
                  closePopup();
                }, 1000);
                updateNotification(
                  toastID,
                  'Sent the invoice to finance',
                  'success'
                );
              } catch (err) {
                updateNotification(
                  toastID,
                  'Could not send the invoice to finance',
                  'error'
                );
                closePopup();
              }
            }}
            isSubmitDisabled={
              isFinanceNoteRequired &&
              isEmptyValue(localState.financeNote?.trim())
            }
            showCloseButton={false}
          />
        )}
      </>
    );
  };
}

export default withSendSupplierInvoiceToHubdocPopupHOC;
