import { isEmptyValue } from './commonUtils';
import { is3DPTechnology } from './itemUtils';

import { TECHNOLOGY_OPTION_TYPE } from '../constants/NewPartConstants';

export const isCadContainPdf = (cadPart) => {
  if (isEmptyValue(cadPart)) {
    return false;
  }
  return cadPart.some((link) => link?.toLowerCase()?.endsWith('.pdf'));
};

/**
 * Filters through all elements in itemState.
 *
 * For elements whose 'delete' property === false,
 * check cadPart array for string elements that ends with 'pdf'.
 *
 * @returns obj element in itemState array that has pdf attached
 */
export const checkPartsWithNo2dFile = (itemState) => {
  const partsWithNo2dFiles = itemState.filter((item) => {
    if (item.deleted) return false;

    const pdfPresent = item.cadPart.filter((link) => {
      const fileName = link.split('/').reverse()[0];
      const last3Chars = fileName.slice(fileName.length - 3).toLowerCase();
      if (last3Chars === 'pdf') return true;
      return false;
    });
    if (pdfPresent.length) return false;

    return true;
  });
  return partsWithNo2dFiles;
};

export const showUpload2DFileText = (item) => {
  const show =
    [
      TECHNOLOGY_OPTION_TYPE.CNC_MACHINING,
      TECHNOLOGY_OPTION_TYPE.SHEET_METAL_FABRICATION,
    ].includes(item.technology) && !isCadContainPdf(item.cadPart);
  return show;
};

export const getPartsNeedClarify = (itemState) => {
  const results = itemState.map((item) => {
    if (item.deleted) {
      return null;
    }
    const sameRepeatOwner = !!item.sameRepeatOwner;
    const mapObj = {
      itemID: item.id,
      name: item.name,
      needsPdf: showUpload2DFileText(item),
      sameRepeatOwner,
      requestSameFabricator: sameRepeatOwner ? false : null,
    };
    return mapObj;
  });
  return results.filter(
    (item) => !!item && (item.needsPdf || item.sameRepeatOwner)
  );
};

/**
 * validate selected items at step 2
 *
 * @param {*} selectedItems
 * @returns
 */
export const validateSelectedItems = (selectedItems) => {
  for (const item of selectedItems) {
    const { technology, threeDTechnology } = item;
    if (is3DPTechnology(technology) && isEmptyValue(threeDTechnology)) {
      return 'missing 3D Printing Technology';
    }
  }
  return null;
};
