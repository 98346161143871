import { format } from 'date-fns';
import { CsvBuilder } from 'filefy';
import { ceil } from 'lodash';
import React, { useEffect, useReducer, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles/index';

import { Tooltip } from '@material-ui/core';

import { Lens as LensIcon } from '@material-ui/icons';

import { DataGrid } from '@mui/x-data-grid';

import InstantLogInfoPopup from '../../components/popups/InstantLogInfoPopup';

import { FtrB2 } from '../../components/ftr-components';
import HorizontalExpandSpace from '../../components/ftr-components/HorizontalExpandSpace';
import CustomToolbar, {
  DataGridToolbarLayout,
} from '../../components/grid-data/CustomToolbar';
import GridDataPagination from '../../components/grid-data/GridDataPagination';
import { ExportCsvButton } from '../../components/grid-data/buttons/ExportCsvButton';
import SearchBar from '../../components/grid-data/buttons/SearchBar';
import { FlexRow } from '../../components/layouts/FlexLayouts';
import DataGridWrapCell from '../../components/tables/cells/DataGridWrapCell';
import DataGridWrapTextCell from '../../components/tables/cells/DataGridWrapTextCell';

import { getAllSupplierLeadTimeFeedbacksApi } from '../../apis/supplierFeedbackApi';

import { useDataGridFilterHook } from '../../hooks/useDataGridFilterHook';

import { getUserRoleSelector } from '../../selectors/userSelector';

import { generateCurrentCustomDateTimeString } from '../../util';
import { transformCsvExportDataGrid } from '../../utils/csvExportUtils';
import { openInNewTab } from '../../utils/navigationUtils';
import { isSuperAdminRole } from '../../utils/roleUtils';

import { colors } from '../../palette';

// -------------------------------------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  body: {
    paddingTop: theme.spacing(2),
    '& .MuiDataGrid-columnSeparator': {
      display: 'none',
    },
    ' & .MuiDataGrid-columnHeaderTitleContainer': {
      padding: '0',
    },
    '& .MuiTablePagination-root': {
      marginRight: '4rem',
    },
    '& .MuiDataGrid-columnHeaderTitle': {
      lineHeight: 'normal',
      wordBreak: 'break-word',
      whiteSpace: 'normal',
      color: colors.blue060,
      fontSize: '11pt',
      fontWeight: 600,
    },
    '& .MuiDataGrid-columnsContainer': {
      display: 'flex',
      justifyContent: 'center',
    },
  },
  linkButton: {
    color: colors.blue050,
    textDecoration: 'underline',
  },
  iconButton: {
    '&:hover': {
      backgroundColor: colors.secondaryBlue,
    },
  },
  seeMoreButton: {
    textTransform: 'none',
    cursor: 'pointer',
  },
}));

const ROW_HEIGHT = 100;

function SupplierLeadTimeFeedbackDataGridPagination() {
  const classes = useStyles();

  const role = useSelector(getUserRoleSelector);

  const [tableData, setTableData] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [tableQueryParams, updateTableQueryParams] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      page: 0,
      pageSize: 20,
      search: '',
      totalCount: 0,
      loading: false,
    }
  );

  const [filteredData, { setSearchStr, setColumnsDef, setSourceData }] =
    useDataGridFilterHook({
      search: '',
      source: tableData,
    });

  const [instantLogInfoPopup, updateInstantLogInfoPopup] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      open: false,
      selectedData: {},
    }
  );

  const getTableData = () => {
    updateTableQueryParams({ loading: true });
    getAllSupplierLeadTimeFeedbacksApi()
      .then((data) => {
        setTableData(data);
      })
      .catch((e) => console.log(e))
      .finally(() => updateTableQueryParams({ loading: false }));
  };

  useEffect(() => {
    setSearchStr(tableQueryParams.search);
  }, [tableQueryParams.search]);

  useEffect(() => {
    updateTableQueryParams({ totalCount: filteredData?.length });
  }, [filteredData]);

  useEffect(() => {
    setSourceData(tableData);
  }, [tableData]);

  const handleSearch = (searchTerm) => {
    updateTableQueryParams({
      page: 0,
      search: searchTerm,
    });
  };

  const defaultColumns = [
    {
      headerName: 'Unseen',
      field: 'hasSeen',
      renderCell: ({ row: rowData }) => {
        if (rowData.hasSeen !== 0) {
          return '';
        }
        return (
          <Tooltip title='Unseen log'>
            <LensIcon color='error' fontSize='small' />
          </Tooltip>
        );
      },
      width: 75,
    },
    {
      headerName: 'Part ID',
      field: 'itemID',
      valueGetter: ({ row: rowData }) => {
        return rowData.quotationInfo
          .map(
            (item) =>
              `ItemID: ${item.itemID} - QuotationID: ${item.quotationID}`
          )
          .join(', ');
      },
      renderCell: ({ row: rowData }) => {
        return (
          <DataGridWrapCell
            style={{
              lineHeight: 'normal',
            }}
          >
            {rowData.quotationInfo.map((item) => {
              return (
                <FlexRow key={item.itemID}>
                  <FtrB2>
                    ItemID:&nbsp;
                    <Link
                      onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        openInNewTab(`/item/edit/${item.itemID}`);
                      }}
                    >
                      {item.itemID}
                    </Link>
                    &nbsp;-&nbsp;QuotationID:&nbsp;
                    <Link
                      onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        openInNewTab(`/order/edit/${item.quotationID}`);
                      }}
                    >
                      {item.quotationID}
                    </Link>
                  </FtrB2>
                </FlexRow>
              );
            })}
          </DataGridWrapCell>
        );
      },
      width: 280,
    },
    {
      headerName: 'Partner info',
      field: 'userID',
      width: 250,
      renderCell: ({ row: rowData }) => {
        const name = rowData.name || 'N.A.';

        return (
          <DataGridWrapCell>
            <FtrB2>{`${name} (${rowData.userID})`}</FtrB2>
            <FtrB2>{rowData.email}</FtrB2>
          </DataGridWrapCell>
        );
      },
    },
    {
      headerName: 'Feedback',
      field: 'feedback',
      width: 120,
    },
    {
      headerName: 'Delivery Date',
      field: 'deliveryDate',
      width: 150,
      renderCell: ({ row: rowData }) => {
        const text = rowData.deliveryDate
          ? format(new Date(rowData.deliveryDate), 'yyyy-MM-dd')
          : 'N.A.';
        return <DataGridWrapTextCell text={text} />;
      },
    },
    {
      headerName: 'Target Delivery Date',
      field: 'targetDeliveryDate',
      width: 180,
      renderCell: ({ row: rowData }) => {
        const text = rowData.targetDeliveryDate
          ? format(new Date(rowData.targetDeliveryDate), 'yyyy-MM-dd')
          : 'N.A.';
        return <DataGridWrapTextCell text={text} />;
      },
    },
    {
      headerName: 'Created At',
      field: 'createdAt',
      width: 150,
      renderCell: ({ row: rowData }) => {
        return (
          <DataGridWrapTextCell
            text={format(new Date(rowData.createdAt), 'yyyy-MM-dd HH:mm')}
          />
        );
      },
    },
    {
      headerName: 'Feedback ID',
      field: 'feedbackID',
      width: 350,
      renderCell: ({ row: rowData }) => (
        <DataGridWrapTextCell text={rowData.feedbackID} />
      ),
    },
  ];

  const [columns, setColumns] = useState(defaultColumns);

  //Retrieve all ppe feedback when page renders
  useEffect(() => {
    getTableData();
    const _columns = defaultColumns.filter((col) => !!col);
    setColumns(_columns);
    setColumnsDef(_columns);
  }, []);

  const handleDownloadCsv = async () => {
    const fileName = `All Supplier Lead Time Feedback ${generateCurrentCustomDateTimeString()}.csv`;
    const { exportedColumns, exportedData } = transformCsvExportDataGrid(
      columns,
      filteredData
    );
    const builder = new CsvBuilder(fileName);
    builder
      .setDelimeter(',')
      .setColumns(exportedColumns)
      .addRows(exportedData)
      .exportFile();
  };

  const getToolbar = () => {
    return (
      <DataGridToolbarLayout>
        <HorizontalExpandSpace />
        <CustomToolbar
          buttons={[
            <SearchBar
              key='search'
              onSearch={handleSearch}
              searchTerm={tableQueryParams.search}
            />,
            <ExportCsvButton
              key='export-csv'
              handleClick={handleDownloadCsv}
              show={isSuperAdminRole(role)}
            />,
          ]}
        />
      </DataGridToolbarLayout>
    );
  };

  return (
    <div className={classes.body}>
      <div
        style={{
          height: '100%',
          width: '100%',
        }}
      >
        <DataGrid
          autoHeight
          rows={filteredData}
          columns={columns.map((col) => ({
            ...col,
            sortable: false,
          }))}
          getRowId={(row) => row.feedbackID}
          rowHeight={ROW_HEIGHT}
          headerHeight={80}
          components={{
            Toolbar: getToolbar,
            Pagination: () => (
              <GridDataPagination
                pageCount={ceil(
                  tableQueryParams.totalCount / tableQueryParams.pageSize
                )}
              />
            ),
          }}
          pageSize={tableQueryParams.pageSize}
          onPageSizeChange={(newPageSize) =>
            updateTableQueryParams({ pageSize: newPageSize })
          }
          rowsPerPageOptions={[10, 20, 50]}
          loading={tableQueryParams.loading}
          disableColumnMenu
          localeText={{
            toolbarExport: 'Export CSV',
          }}
        />
        {instantLogInfoPopup.open && (
          <InstantLogInfoPopup
            open={instantLogInfoPopup.open}
            handleClose={() => updateInstantLogInfoPopup({ open: false })}
            rowData={instantLogInfoPopup.selectedData}
          />
        )}
      </div>
    </div>
  );
}

export default SupplierLeadTimeFeedbackDataGridPagination;
