import React from 'react';

import { Typography } from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles/index';

import { colors } from '../../palette';

const useStyles = makeStyles(() => ({
  heading: {
    fontWeight: 700,
    color: colors.neutral080,
  },
  subHeading: {
    fontWeight: 600,
    color: colors.neutral080,
  },
  body: {
    fontWeight: 400,
    color: colors.neutral080,
  },
}));

/**
 * Customised MUI Typography component
 * Props are passed directly to the MUI Typography component
 *
 * @param {object} props - The properties of the FtrTypography component.
 * @param {String} props.className - Extend the styles applied to the component.
 * @param {String} props.fontSize - The font size of the typography in px.
 * @param {React.CSSProperties} props.style - The style of the typography.
 * @param {'heading' | 'subHeading' | 'body'} props.type - The type of typography to render.
 * @param {String} props.id - to create specific id for test.
 *
 * @returns {React.ReactNode} The rendered FtrTypography component.
 */
export default function FtrTypography(props) {
  const classes = useStyles();
  const { className, fontSize, style = {}, type, id, ...rest } = props;

  return (
    <Typography
      className={`${classes[type]} ${className}`}
      style={{
        ...style,
        color: style.color ? style.color : 'inherit',
        fontSize: `${fontSize}px`,
      }}
      data-cy={id}
      id={id}
      {...rest}
    />
  );
}

export function FtrHeading(props) {
  const { fontSize = '22', ...rest } = props;

  return (
    <FtrTypography
      style={{ color: colors.neutral080 }}
      type='heading'
      fontSize={fontSize}
      {...rest}
    />
  );
}

export function FtrH8(props) {
  const { children, style, className, ...rest } = props;
  return (
    <FtrTypography
      type='heading'
      fontSize='28'
      className={className}
      style={{
        color: colors.neutral080,
        ...style,
      }}
      {...rest}
    >
      {children}
    </FtrTypography>
  );
}

export function FtrH7(props) {
  const { children, style, className, ...rest } = props;
  return (
    <FtrTypography
      type='heading'
      fontSize='24'
      className={className}
      style={{
        color: colors.neutral080,
        ...style,
      }}
      {...rest}
    >
      {children}
    </FtrTypography>
  );
}

export function FtrH6(props) {
  const { children, style, className, ...rest } = props;
  return (
    <FtrTypography
      type='heading'
      fontSize='22'
      className={className}
      style={{
        color: colors.neutral080,
        ...style,
      }}
      {...rest}
    >
      {children}
    </FtrTypography>
  );
}

export function FtrH5(props) {
  const { children, style, ...rest } = props;
  return (
    <FtrTypography
      type='heading'
      fontSize='20'
      style={{
        color: colors.neutral080,
        ...style,
      }}
      {...rest}
    >
      {children}
    </FtrTypography>
  );
}

export function FtrH4(props) {
  const { children, style, ...rest } = props;
  return (
    <FtrTypography
      type='heading'
      fontSize='18'
      style={{
        fontWeight: 700,
        color: colors.neutral080,
        ...style,
      }}
      {...rest}
    >
      {children}
    </FtrTypography>
  );
}

export function FtrH3(props) {
  const { children, style, ...rest } = props;
  return (
    <FtrTypography
      type='heading'
      fontSize='16'
      style={{
        fontWeight: 700,
        color: colors.neutral080,
        ...style,
      }}
      {...rest}
    >
      {children}
    </FtrTypography>
  );
}

export function FtrB1(props) {
  const { children, style, ...rest } = props;
  return (
    <FtrTypography
      type='body'
      fontSize='12'
      style={{
        color: colors.neutral080,
        ...style,
      }}
      {...rest}
    >
      {children}
    </FtrTypography>
  );
}

export function FtrB2(props) {
  const { children, style, ...rest } = props;
  return (
    <FtrTypography
      type='body'
      fontSize='14'
      style={{
        color: colors.neutral080,
        ...style,
      }}
      {...rest}
    >
      {children}
    </FtrTypography>
  );
}

export function FtrB3(props) {
  const { children, style, ...rest } = props;
  return (
    <FtrTypography
      type='body'
      fontSize='16'
      style={{
        color: colors.neutral080,
        ...style,
      }}
      {...rest}
    >
      {children}
    </FtrTypography>
  );
}

export function FtrB4(props) {
  const { children, style, ...rest } = props;
  return (
    <FtrTypography
      type='body'
      fontSize='18'
      style={{
        color: colors.neutral080,
        ...style,
      }}
      {...rest}
    >
      {children}
    </FtrTypography>
  );
}

export function FtrS4(props) {
  const { children, style, ...rest } = props;
  return (
    <FtrTypography
      type='subHeading'
      fontSize='18'
      style={{
        color: colors.neutral080,
        fontWeight: 600,
        ...style,
      }}
      {...rest}
    >
      {children}
    </FtrTypography>
  );
}

export function FtrS3(props) {
  const { children, style, ...rest } = props;
  return (
    <FtrTypography
      type='subHeading'
      fontSize='16'
      style={{
        color: colors.neutral080,
        fontWeight: 600,
        ...style,
      }}
      {...rest}
    >
      {children}
    </FtrTypography>
  );
}

export function FtrS2(props) {
  const { children, style, ...rest } = props;
  return (
    <FtrTypography
      type='subHeading'
      fontSize='14'
      style={{
        color: colors.neutral080,
        fontWeight: 600,
        ...style,
      }}
      {...rest}
    >
      {children}
    </FtrTypography>
  );
}

export function FtrS1(props) {
  const { children, style, ...rest } = props;
  return (
    <FtrTypography
      type='subHeading'
      fontSize='12'
      style={{
        color: colors.neutral080,
        fontWeight: 600,
        ...style,
      }}
      {...rest}
    >
      {children}
    </FtrTypography>
  );
}

export function FtrLightText(props) {
  const { children, style, ...rest } = props;

  return (
    <FtrTypography
      type='body'
      fontSize='14'
      style={{
        color: colors.neutral080,
        fontWeight: 200,
        ...style,
      }}
      {...rest}
    >
      {children}
    </FtrTypography>
  );
}

export function FtrNormalText(props) {
  const { children, fontSize = '14', ...rest } = props;

  return (
    <FtrTypography
      type='body'
      fontSize={fontSize}
      style={{ color: colors.neutral080 }}
      {...rest}
    >
      {children}
    </FtrTypography>
  );
}

export function FtrBoldText(props) {
  const { children, style, fontSize = '14', ...rest } = props;

  return (
    <FtrTypography
      {...props}
      type='body'
      fontSize={fontSize}
      style={{
        color: colors.neutral080,
        fontWeight: 600,
        ...style,
      }}
      {...rest}
    >
      {children}
    </FtrTypography>
  );
}

export function FtrItalicText(props) {
  const { children, style, fontSize = '14', ...rest } = props;

  return (
    <FtrTypography
      {...props}
      type='body'
      fontSize={fontSize}
      style={{
        color: colors.neutral080,
        fontStyle: 'italic',
        ...style,
      }}
      {...rest}
    >
      {children}
    </FtrTypography>
  );
}

export function FtrCaption(props) {
  const { children, style, fontSize = '12', ...rest } = props;

  return (
    <FtrItalicText
      {...props}
      fontSize={fontSize}
      style={{
        color: colors.neutral080,
        ...style,
      }}
      {...rest}
    >
      {children}
    </FtrItalicText>
  );
}

export function FtrErrorText(props) {
  const { children, style, ...rest } = props;

  return (
    <FtrTypography
      type='body'
      fontSize='12'
      style={{
        color: 'red',
        fontStyle: 'italic',
        ...style,
      }}
      {...rest}
    >
      {children}
    </FtrTypography>
  );
}
