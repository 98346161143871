import { fetchWithErrorHandling, getAuthorizedHeader } from '../utils/apiUtils';

import { BACKEND_SERVICE_URL } from '../constants';

// -------------------------------------------------------------------------------------------------

export const getSupplierAdminControl = async (supplierID) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/admin-control/suppliers/${supplierID}`;
  return fetchWithErrorHandling(requestUrl, {
    method: 'GET',
    headers: getAuthorizedHeader(),
  });
};

export const updateSupplierAdminControl = async (supplierID, body) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/admin-control/suppliers/${supplierID}`;
  return fetchWithErrorHandling(requestUrl, {
    method: 'PUT',
    headers: getAuthorizedHeader(),
    body: JSON.stringify(body),
  });
};

export const getViewAllJobsWhitelistAccountsControl = async () => {
  const requestUrl = `${BACKEND_SERVICE_URL}/admin-control/all-jobs-whitelist`;
  return fetchWithErrorHandling(requestUrl, {
    method: 'GET',
    headers: getAuthorizedHeader(),
  });
};

export const getAvailableWhitelistAccountsControl = async () => {
  const requestUrl = `${BACKEND_SERVICE_URL}/admin-control/available-whitelist-accounts`;
  return fetchWithErrorHandling(requestUrl, {
    method: 'GET',
    headers: getAuthorizedHeader(),
  });
};

export const updateWhitelistAccountsControl = async (body) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/admin-control/all-jobs-whitelist`;
  return fetchWithErrorHandling(requestUrl, {
    method: 'POST',
    headers: getAuthorizedHeader(),
    body: JSON.stringify(body),
  });
};
