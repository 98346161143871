import React from 'react';

import ImageWith3DViewer from './ImageWith3DViewer';

// --------------------------------------------------------------------------------------------

function CarouselImageV2(props) {
  const { url, width = 60, height = 60 } = props;

  return (
    <ImageWith3DViewer
      twoDImageUrl={url}
      // cadFile={rowData.cadFile || rowData.originalFiles}
      borderRadius={4.5}
      width={width}
      height={height}
    />
  );
}

export default CarouselImageV2;
