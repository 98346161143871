import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { uniq } from 'lodash';

import { makeStyles } from '@material-ui/core/styles/index';

import { Button, Chip, Grid, TextField, Typography } from '@material-ui/core';

import HighlightOffIcon from '@material-ui/icons/HighlightOff';

import MultiSelectChipDropdown from '../components/dropdowns/MultiSelectChipDropdown';

import { getAllPoAutomationQuotations } from '../apis/quotationApi';
import { getAllSuppliersApi } from '../apis/userApi';
import { sendGeneratePoAutomationEmail } from '../apis/emailApi';

import { validateEmail } from '../utils/validators/emailValidator';

import { isEmptyValue } from '../utils/commonUtils';

import { notifyError, notifySuccess } from '../services/notificationService';

// -------------------------------------------------------------------------------------------------

const useStyles = makeStyles(() => ({
  container: {
    paddingTop: '1rem',
  },
}));

function GeneratePoAutomationTab() {
  const classes = useStyles();

  const { data: quotations = [] } = useQuery('poAutomation', () => {
    return getAllPoAutomationQuotations().catch(() => []);
  });
  const { data: allSuppliers = [] } = useQuery(
    'allSecondarySuppliers',
    getAllSuppliersApi
  );

  const [itemList, setItemList] = useState([]);
  const [itemListError, setItemListError] = useState(null);
  const [selectedItemIds, setSelectedItemIds] = useState([]);
  const [supplierEmails, setSupplierEmails] = useState([]);
  const [secondarySupplierEmails, setSecondarySupplierEmails] = useState([]);
  const [enableSendButton, setEnableSendButton] = useState(true);
  const [otherMailName, setOtherMailName] = useState('');
  const [otherMailNameError, setOtherMailNameError] = useState(null);
  const [otherMailValue, setOtherMailValue] = useState('');
  const [otherMailValueError, setOtherMailValueError] = useState(null);
  const [otherMails, setOtherMails] = useState([]);

  useEffect(() => {
    if (!isEmptyValue(quotations)) {
      setItemList(
        quotations.map((quote) => {
          return {
            key: quote.quotationID,
            text: `${quote.name} - Part ID: ${quote.itemID} - Quote ID: ${quote.quotationID} - Project ID: ${quote.projectID}`,
          };
        })
      );
    }
  }, [quotations]);

  const resetForm = () => {
    setSelectedItemIds([]);
    setSecondarySupplierEmails([]);
    setOtherMails([]);
  };

  const onItemsSelectChange = (newValues) => {
    setSelectedItemIds(newValues);
    setItemListError(null);
    const _supplierEmails = uniq(
      quotations
        .filter((quote) => newValues.includes(quote.quotationID))
        .map((quote) => quote.email)
    );
    setSupplierEmails(_supplierEmails);
    const _secondarySupplierEmails = uniq(
      allSuppliers
        ?.filter((supplier) => _supplierEmails.includes(supplier.email))
        ?.map((secSupplier) => {
          if (
            secSupplier.secondaryEmail !== null &&
            secSupplier.secondaryEmail !== undefined
          ) {
            return {
              name: secSupplier.secondaryName,
              email: secSupplier.secondaryEmail,
            };
          }
        })
        ?.filter((secSupplier) => !!secSupplier)
        ?.filter((secSupplier) => secSupplier.email !== undefined)
    );
    setSecondarySupplierEmails(_secondarySupplierEmails);
  };

  const handleSendPoAutomationEmail = () => {
    if (isEmptyValue(selectedItemIds)) {
      setItemListError('Please select at least one item');
      return;
    }
    const body = {
      quotationIDs: selectedItemIds,
      otherReceivers: otherMails,
    };
    setEnableSendButton(false);
    sendGeneratePoAutomationEmail(body)
      .then(() => {
        notifySuccess('PO emails have been queued for sending successfully.');
        resetForm();
        setEnableSendButton(true);
      })
      .catch((err) => {
        notifyError(err.message || err);
        setEnableSendButton(true);
      });
  };

  const handleOtherEmailChipDelete = (email) => {
    setOtherMails(otherMails.filter((otherMail) => otherMail.email !== email));
  };

  const handleAddReceiver = () => {
    if (isEmptyValue(otherMailName)) {
      setOtherMailNameError('Name is required');
      return;
    }
    if (isEmptyValue(otherMailValue)) {
      setOtherMailValueError('Email is required');
      return;
    }
    if (!validateEmail(otherMailValue)) {
      setOtherMailValueError('Email is invalid');
      return;
    }
    setOtherMails([
      ...otherMails,
      { name: otherMailName, email: otherMailValue },
    ]);
    setOtherMailName('');
    setOtherMailValue('');
  };

  const renderOtherMailsList = () => {
    return (
      <div
        style={{
          padding: '8px',
        }}
      >
        {otherMails.map(({ name, email }) => {
          return (
            <Chip
              classes={{
                deleteIcon: classes.chipDeleteIcon,
                deletable: classes.chipDeletable,
              }}
              className={classes.chip}
              key={email}
              label={`${name} <${email}>`}
              onMouseDown={(event) => event.stopPropagation()}
              onDelete={() => handleOtherEmailChipDelete(email)}
              deleteIcon={<HighlightOffIcon />}
            />
          );
        })}
      </div>
    );
  };

  const renderAddOtherMailsArea = () => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginTop: '16px',
        }}
      >
        <Typography color='inherit'>Add other receivers</Typography>
        <div
          style={{
            padding: '8px',
          }}
        >
          <Grid container className={classes.addOtherMailRow} spacing={2}>
            <Grid item xs={3}>
              <TextField
                variant='outlined'
                margin='dense'
                id='otherMailName'
                label='Name'
                placeholder=''
                type='text'
                fullWidth
                error={!!otherMailNameError}
                helperText={otherMailNameError}
                onFocus={() => {
                  setOtherMailNameError(null);
                }}
                onChange={(evt) => setOtherMailName(evt.target.value)}
                value={otherMailName}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={7}>
              <TextField
                variant='outlined'
                margin='dense'
                id='otherMailValue'
                label='Email'
                placeholder=''
                type='text'
                fullWidth
                error={!!otherMailValueError}
                helperText={otherMailValueError}
                onFocus={() => {
                  setOtherMailValueError(null);
                }}
                onChange={(evt) => setOtherMailValue(evt.target.value)}
                value={otherMailValue}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={2}>
              <Button
                style={{
                  marginTop: '9px',
                }}
                variant='contained'
                color='primary'
                fullWidth
                onClick={handleAddReceiver}
              >
                Add Receiver
              </Button>
            </Grid>
          </Grid>
          {!isEmptyValue(otherMails) && renderOtherMailsList()}
        </div>
      </div>
    );
  };

  const renderSupplierEmails = () => {
    if (isEmptyValue(selectedItemIds)) {
      return null;
    }
    return (
      <div
        style={{
          marginTop: '1rem',
        }}
      >
        <Typography>Partner Emails:</Typography>
        <div
          style={{
            marginTop: '0.5rem',
          }}
        >
          {supplierEmails.map((email) => (
            <Chip
              style={{
                marginRight: '0.2rem',
              }}
              key={email}
              label={email}
            />
          ))}
        </div>
      </div>
    );
  };

  const renderSecondarySupplierEmails = () => {
    if (isEmptyValue(selectedItemIds) || secondarySupplierEmails.length === 0) {
      return null;
    }
    return (
      <div
        style={{
          marginTop: '1rem',
        }}
      >
        <Typography>Secondary Partner Emails:</Typography>
        <div
          style={{
            marginTop: '0.5rem',
          }}
        >
          {secondarySupplierEmails.map((secSupplier) => (
            <Chip
              style={{
                marginRight: '0.2rem',
              }}
              key={secSupplier.email}
              label={secSupplier.email}
            />
          ))}
        </div>
      </div>
    );
  };

  return (
    <div className={classes.body}>
      <div className={classes.container}>
        <MultiSelectChipDropdown
          id='multi-select-items'
          label='Select Parts'
          itemList={itemList}
          selectedItems={selectedItemIds}
          onSelect={onItemsSelectChange}
          error={!!itemListError}
          errorMessage={itemListError}
          searchable
        />
        {renderSupplierEmails()}
        {renderSecondarySupplierEmails()}
        {renderAddOtherMailsArea()}
        <div style={{ marginTop: '20px' }}>
          <Button
            variant='contained'
            color='primary'
            fullWidth
            onClick={handleSendPoAutomationEmail}
            disabled={!enableSendButton}
          >
            Send PO Automation Email
          </Button>
        </div>
      </div>
    </div>
  );
}

export default GeneratePoAutomationTab;
