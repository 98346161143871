import { isEmpty } from 'lodash';

import { convertPriceToCurrency } from './currencyUtils';
import { isEmptyValue } from './commonUtils';

import { SHIPPING_MODES } from '../constants/checkoutConstants';
import { COUNTRY_NAMES } from '../constants/countryConstants';

// ------------------------------------------------------------------------------

/**
 * Returns the default shipping mode for a given country.
 *
 * @param {string} country - The name of the country.
 * @returns {string} - The default shipping mode for the specified country.
 * If the country is America, it returns FedEx priority shipping.
 * If the country is Singapore or not specified, it returns a shipping mode with delivery in 1-3 days.
 * For all other countries, it returns DHL priority shipping.
 */
export const getDefaultShippingModeByCountry = (country, options) => {
  const { deliveryModePrices = {} } = options || {};
  if (country === COUNTRY_NAMES.AMERICA) {
    return SHIPPING_MODES.FEDEX_PRIORITY;
  }
  // for singapore and priority shipping is free then use priority shipping
  if (
    country === COUNTRY_NAMES.SINGAPORE &&
    deliveryModePrices?.[SHIPPING_MODES.SAME_DATE]?.fee === 0
  ) {
    return SHIPPING_MODES.SAME_DATE;
  }
  if (country === COUNTRY_NAMES.SINGAPORE || isEmptyValue(country)) {
    return SHIPPING_MODES.ONE_THREE_DAYS;
  }
  return SHIPPING_MODES.DHL_PRIORITY;
};

/**
 * Given a dictionary of delivery mode prices, returns the best shipping mode
 * based on the cheapest cost. If no prices are given, returns null.
 *
 * @param {Object} deliveryModePrices - A dictionary of delivery mode prices.
 * @param {Object} [options={}] - An object of options.
 * @param {string} [options.country=COUNTRY_NAMES.SINGAPORE] - The country to use
 * for determining the best shipping mode.
 * @returns {string|null} The best shipping mode or null if no prices are given.
 */
export const getBestShippingMode = (deliveryModePrices, options) => {
  const { country = COUNTRY_NAMES.SINGAPORE } = options || {};
  if (isEmpty(deliveryModePrices)) {
    return null;
  }
  let bestMode = getDefaultShippingModeByCountry(country);
  for (const [key, value] of Object.entries(deliveryModePrices)) {
    if (key === bestMode) {
      continue;
    }
    if (deliveryModePrices[bestMode]?.fee > value.fee) {
      bestMode = key;
    }
  }
  return bestMode;
};

export const getShippingTooltipText = ({ country, currency, exchangeRate }) => {
  const shippingTooltip =
    country === COUNTRY_NAMES.SINGAPORE
      ? `Free delivery for orders of more than ${convertPriceToCurrency({
          price: 1000,
          currency,
          exchangeRate,
        })}`
      : '';

  return shippingTooltip;
};
