import React, { useCallback, useReducer, useMemo } from 'react';
import { isEmpty, debounce } from 'lodash';
import { isURL } from 'validator';

import { makeStyles } from '@material-ui/core/styles/index';

import {
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  CircularProgress,
  Container,
  Box,
  Typography,
  Button,
  Divider,
  Grid,
} from '@material-ui/core';

import FilesUploadButton from '../../components/FilesUploadButton';
import FtrFieldLabel from '../../components/ftr-components/FtrFieldLabel';
import { FtrDropdown, FtrTextField } from '../../components/ftr-components';
import ThreeDViewer from './ThreeDViewer';

import { getCadPartS3Key, uploadFileToS3 } from '../../services/s3Service';
import { notifyError, notifySuccess } from '../../services/notificationService';

import { getItemDetailsApi } from '../../apis/itemApi';
import { getDfmExtractDefectsWithCache } from '../../apis/dfmApi';

import { getUrlFileBySupportedFileTypes } from '../../utils/fileUtils';

import { NATIVE_3D_RENDERER_TYPES } from '../../constants/cadRendererConstants';

import DfmDefectsForm from '../../components/forms/DfmDefectsForm';
import withDefectVisualizationPopupHOC from '../../components/popups/withDefectVisualizationPopupHOC';

const useStyles = makeStyles((theme) => ({
  circularProgress: {
    marginLeft: 0,
    marginRight: theme.spacing.unit,
  },
}));

const OPTIONS = {
  PART_ID: 'Part ID',
  THREE_D_PART_URL: '3D Part URL',
  UPLOAD_CAD_MODEL: 'Upload CAD Model',
};

const TECH_OPTIONS = {
  CNC: 'CNC',
  '3DP': '3DP',
  SMF: 'SMF',
};

const DfmDefectsFormWithVisualizationPopup = withDefectVisualizationPopupHOC(
  DfmDefectsForm
);

export default function ExtractDefects() {
  const classes = useStyles();

  // We separate image state and extracted info state
  // since they are using separate endpoints and React does async state updates
  const [localState, updateLocalState] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      fileUrl: null,
      process: TECH_OPTIONS.CNC,
      option: OPTIONS.PART_ID,
      partID: null,
      processing: false,
      uploadedFile: null,
      isFileUploading: false,
      processed: null,
      output: null,
      extracted: false,
      statusFileUrl: false,
    }
  );

  const handleUploadCadFile = (files) => {
    const file = files[0];
    updateLocalState({
      uploadedFile: file,
      isFileUploading: true,
      fileUrl: null,
      extracted: false,
    });
    const s3ObjectKey = getCadPartS3Key(file);
    return uploadFileToS3(file, s3ObjectKey)
      .then((data) => {
        const fileUrl = data.Location.split(' ').join('%20');
        updateLocalState({ fileUrl: fileUrl });
      })
      .catch(() => {
        notifyError(`Couldn't upload CAD to S3.`);
      })
      .finally(() => {
        updateLocalState({ isFileUploading: false });
      });
  };

  const requestForGetCadFile = useCallback(async (partId) => {
    getItemDetailsApi(partId)
      .then(async (itemDetails) => {
        const cadFiles = itemDetails.cadFile || itemDetails.originalFiles;
        const urlCadFile = await getUrlFileBySupportedFileTypes(
          cadFiles?.split(',')
        );
        if (urlCadFile) {
          notifySuccess(`Found cad file for Part ${partId}!`);
          updateLocalState({ fileUrl: urlCadFile, statusFileUrl: 'success' });
        } else {
          // No cad file found
          updateLocalState({ fileUrl: null, statusFileUrl: 'not-found' });
          return;
        }
      })
      .catch(() => {
        // itemApi returns Bad Request
        updateLocalState({ fileUrl: null, statusFileUrl: 'not-found' });
      });
  }, []);

  const debouncedRequestForGetCadFile = useMemo(() => {
    return debounce(requestForGetCadFile, 500);
  }, [requestForGetCadFile]);

  const handlePartIdChange = async (event) => {
    const partId = event.target.value;
    updateLocalState({ partID: partId, extracted: false });

    if (isEmpty(partId)) {
      updateLocalState({ fileUrl: null });
      return;
    }
    debouncedRequestForGetCadFile(partId);
  };

  const handleExtract = async () => {
    const params = {
      file_url: localState.fileUrl,
      process: localState.process,
    };
    updateLocalState({
      processing: true,
      extracted: true,
      processed: false,
    });
    getDfmExtractDefectsWithCache(params)
      .then((res) => {
        const output = res?.data?.defects
          ? { ...res.data.defects, trackingID: res.data.trackingID }
          : res?.data;
        updateLocalState({
          output: output,
          processed: 'done',
        });
      })
      .catch((err) => {
        notifyError(err.message || 'Failed to get dfm extract defects!');
      })
      .finally(() => {
        updateLocalState({
          processing: false,
        });
      });
  };

  return (
    <Container>
      <FtrFieldLabel style={{ marginTop: '2rem' }}>Technology</FtrFieldLabel>
      <FtrDropdown
        key='technology-dropdown'
        fullWidth
        value={localState.process}
        handleChange={(newTech) => updateLocalState({ process: newTech })}
        items={Object.values(TECH_OPTIONS)}
        style={{ marginBottom: '0.5rem' }}
      />

      <FormControl component='fieldset'>
        <FtrFieldLabel>CAD Model</FtrFieldLabel>
        <RadioGroup
          row
          value={localState.option}
          onChange={(event) => {
            // Reset state whenever we change option
            // This is to prevent stale data from previous option and
            // to prevent user from accidentally submitting data from previous option
            updateLocalState({
              option: event.target.value,
              fileUrl: null,
              partID: null,
              uploadedFile: null,
              processing: false,
            });
          }}
        >
          {Object.entries(OPTIONS).map(([key, value]) => {
            return (
              <FormControlLabel
                key={key}
                value={value}
                control={<Radio color='primary' />}
                label={value}
              />
            );
          })}
        </RadioGroup>
      </FormControl>

      {localState.option === OPTIONS.THREE_D_PART_URL && (
        <FtrTextField
          required
          title='3D Part URL'
          id='image-url'
          variant='outlined'
          value={localState.fileUrl || ''}
          onChange={(event) =>
            updateLocalState({ fileUrl: event.target.value, extracted: false })
          }
          error={localState.fileUrl && !isURL(localState.fileUrl)}
          helperText={
            localState.fileUrl && !isURL(localState.fileUrl)
              ? 'Invalid URL'
              : ''
          }
          disabled={localState.processing}
        />
      )}

      {localState.option === OPTIONS.UPLOAD_CAD_MODEL && (
        <div
          style={{
            margin: '0.5rem 0 2rem',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <FilesUploadButton
            buttonText='Upload CAD'
            handleUploadFiles={handleUploadCadFile}
            multiple={false}
            accept={NATIVE_3D_RENDERER_TYPES.map((type) => '.' + type).join(
              ','
            )}
            disabled={localState.isFileUploading || localState.processing}
          />
          <Box style={{ width: '1rem' }} />
          {localState.uploadedFile && (
            <Typography>{localState.uploadedFile.name}</Typography>
          )}
          &nbsp;
          {localState.isFileUploading === true && (
            <CircularProgress className={classes.circularProgress} size={20} />
          )}
        </div>
      )}

      {localState.option === OPTIONS.PART_ID && (
        <FtrTextField
          title='Part ID'
          required
          error={localState.statusFileUrl === 'not-found'}
          helperText={
            localState.statusFileUrl === 'not-found'
              ? 'Part does not have cad file or invalid Part ID'
              : ''
          }
          id='part-id'
          variant='outlined'
          value={localState.partID || ''}
          onChange={handlePartIdChange}
          disabled={localState.processing}
        />
      )}

      <div style={{ marginTop: '1rem' }}>
        <Button
          variant='contained'
          color='primary'
          fullWidth
          onClick={handleExtract}
          disabled={
            isEmpty(localState.fileUrl) ||
            !isURL(localState.fileUrl) ||
            localState.processing === true
          }
        >
          {localState.processing === true && (
            <CircularProgress className={classes.circularProgress} size={20} />
          )}
          &nbsp;Extract
        </Button>
      </div>

      <div
        style={{
          marginTop: '2rem',
          marginBottom: '1rem',
        }}
      >
        <Divider />
      </div>
      <Grid
        container
        spacing={2}
        style={{
          marginTop: '2rem',
          marginBottom: '1rem',
        }}
      >
        {localState.extracted && localState.fileUrl && (
          <Grid item md={8} xs={12}>
            <ThreeDViewer fileUrl={localState.fileUrl} hideUploadFile />
          </Grid>
        )}
        {localState.processed && localState.extracted && localState.fileUrl && (
          <Grid item md={4} xs={12}>
            <DfmDefectsFormWithVisualizationPopup
              defectsOutput={localState.output}
              itemID={
                localState.option === OPTIONS.PART_ID ? localState.partID : null
              }
            />
          </Grid>
        )}
      </Grid>
    </Container>
  );
}
