import { notifyError, notifySuccess } from '../services/notificationService';
import { SHIPMENT_TRACKING_LINKS } from '../constants/orderReadyConstants';

import { isEmptyValue } from './commonUtils';

export const handleCopyText = (e, shipmentTrackingNumber) => {
  e.stopPropagation();
  navigator.clipboard.writeText(shipmentTrackingNumber);
  notifySuccess('Copied shipment tracking number!');
};

export const handleOpenTrackingLink = (
  e,
  shipmentProvider,
  shipmentTrackingNumber
) => {
  e.stopPropagation();
  const trackingLinkFunc = SHIPMENT_TRACKING_LINKS[shipmentProvider];
  if (trackingLinkFunc) {
    window.open(trackingLinkFunc(shipmentTrackingNumber), '_blank');
  } else {
    notifyError(
      `Unable to load page. Please head over to the shipment provider's site to track your package!`
    );
  }
};

export const isTrackingNumberValid = (trackingNumber) => {
  return !isEmptyValue(trackingNumber) && trackingNumber !== 'N.A.';
};
