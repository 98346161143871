import { BACKEND_SERVICE_URL } from '../constants';
import { getAuthorizedHeader } from '../utils/apiUtils';

export const sendGenerateRfqEmails = async (body) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/email/send-generate-rfq`;
  return fetch(requestUrl, {
    method: 'POST',
    headers: getAuthorizedHeader(),
    body: JSON.stringify(body),
  }).then((response) => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return response.json();
  });
};

export const sendGeneratePoAutomationEmail = async (body) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/email/send-po-automation`;
  return fetch(requestUrl, {
    method: 'POST',
    headers: getAuthorizedHeader(),
    body: JSON.stringify(body),
  }).then((response) => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return response.json();
  });
};
