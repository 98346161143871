import React, { useContext, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { connect, useDispatch, useSelector } from 'react-redux';
import { uniqueId } from 'lodash';
import { useQueryClient } from 'react-query';
import { nanoid } from 'nanoid';

import {
  makeStyles,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Typography,
} from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close';

import { colors } from '../../../../palette';

import { FtrLightText } from '../../../../components/ftr-components/FtrTypography';
import FtrSvgImage from '../../../../components/images/FtrSvgImage';
import ConfigurePartsLibrary from '../forms/ConfigurePartsLibrary';
import ProjectNameField from '../../../../components/fields/ProjectNameField';
import { FtrButton } from '../../../../components/ftr-components';
import PpePriceLoadingBackdrop from '../../../../components/backdrops/PpePriceLoadingBackdrop';
import { ReactComponent as BackIcon } from '../../../../assets/icons/back_icon.svg';
import AutoAwesomeIcon from '../../../../assets/icons/auto_awesome.svg';

import {
  FlexRow,
  FlexRowSpaceBetween,
} from '../../../../components/layouts/FlexLayouts';
import UploadFilesField from '../../../../components/forms/form-fields/UploadFilesField';

import { updateProjectItemsPartLibrary } from '../../../../apis/partsLibraryApi';

import { TECHNOLOGY_OPTION_TYPE } from '../../../../constants/NewPartConstants';
import { TWO_D_IMAGE_URLS_KEY } from '../../../../constants';

import {
  extractAndDecodeFileNameAndExtensionFromUrl,
  is2DGenerationSupport,
} from '../../../../utils/fileUtils';
import { getPpePriceForItem } from '../../../../utils/ppeUtils';
import { isEmptyValue } from '../../../../utils/commonUtils';

import {
  addNewPartsLibraryItemData,
  deletePartsLibraryItemData,
  openPartSummaryPage,
  updatePartsLibraryItemData,
  updatePartsLibrarySingleData,
  viewPartsLibraryItemData,
} from '../../../../actions';
import useDeviceCheck from '../../../../hooks/useDeviceCheck';

import { ReactComponent as TrashIcon } from '../../../../assets/icons/trash.svg';

import { convert2DImage } from '../../../../services/convert2DImageService';
import {
  getCadPartS3Key,
  uploadFileToS3,
} from '../../../../services/s3Service';
import {
  isCustomColor,
  isCustomMaterialColor,
} from '../../../../utils/inputUtils';

import ConfigurePartsLibraryContext from '../../../../context/ConfigurePartsLibraryContext';
import AppContext from '../../../../context/AppContext';

const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: 'bold',
    color: colors.neutral080,
    [theme.breakpoints.down('md')]: {
      fontSize: 16,
    },
  },
  autoAwesomeIcon: {
    height: '1rem !important',
    width: '1rem !important',
    filter: `invert(41%) sepia(90%) saturate(5329%) hue-rotate(203deg) brightness(96%) contrast(86%);`,
  },
  actionContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: 12,
    marginTop: 20,
    [theme.breakpoints.down('md')]: {
      margin: '16px',
    },
  },
  imageLoading: {
    marginRight: 8,
  },
}));

const HeaderProject = (props) => {
  const { onClose } = props;
  const [{ isTablet }] = useDeviceCheck();
  const classes = useStyles();
  const dispatch = useDispatch();
  const project = useSelector((state) => state.partsLibrary?.data);

  const inUseItems = project?.items?.filter(
    (item) => item.inUse && !item.isDeleted
  );
  const hasChecked = inUseItems?.some((item) => item.checked);

  const handleUpdateProjectName = (newName) => {
    dispatch(
      updatePartsLibrarySingleData(project?.projectLibraryID, {
        projectName: newName,
      })
    );
  };

  const handleDeleteItems = () => {
    const items = project?.items?.filter((item) => item.inUse && item.checked);
    const itemLibraryIDs = items
      .filter((item) => item.itemLibraryID)
      .map((item) => item.itemLibraryID);
    const ids = items.filter((item) => item.id).map((item) => item.id);
    dispatch(
      deletePartsLibraryItemData(project?.projectLibraryID, {
        itemLibraryIDs,
        ids,
      })
    );
  };

  const handleFiles = async (newFiles) => {
    Promise.all(
      Array.from(newFiles).map(async (file) => {
        const splitFilename = file.name.split('.');
        const fileExtension = splitFilename[splitFilename.length - 1];

        const id = uniqueId('0');
        return uploadFileToS3(file, getCadPartS3Key(file)).then(
          async (data) => {
            const s3ObjectUrl = data.Location.split(' ').join('%20');
            const [fileName] = extractAndDecodeFileNameAndExtensionFromUrl(
              s3ObjectUrl
            );
            let newItem = {
              id: id,
              cadFile: s3ObjectUrl,
              inUse: 1,
              name: fileName,
              projectLibraryID: project.projectLibraryID,
              isNewlyItem: true,
              quantity: 1,
              version: 1,
              is2DImageLoading: true,
            };
            dispatch(
              addNewPartsLibraryItemData(project.projectLibraryID, newItem)
            );
            if (is2DGenerationSupport(fileExtension)) {
              await convert2DImage({ file_url: s3ObjectUrl })
                .then((res) => {
                  const twoDImageUrl = res['s3_file_url'];
                  dispatch(
                    updatePartsLibraryItemData(
                      project.projectLibraryID,
                      { id },
                      { twoDImageUrl }
                    )
                  );
                })
                .finally(() => {
                  dispatch(
                    updatePartsLibraryItemData(
                      project.projectLibraryID,
                      { id },
                      { is2DImageLoading: false }
                    )
                  );
                });
            }
          }
        );
      })
    );
  };

  if (isTablet) {
    return (
      <div>
        <FlexRow>
          <IconButton
            aria-label='close'
            onClick={onClose}
            style={{ marginLeft: '-5px' }}
          >
            <BackIcon />
          </IconButton>
          <Typography className={classes.title} variant='h5'>
            Configure your parts
          </Typography>
        </FlexRow>
        <FlexRowSpaceBetween>
          <ProjectNameField
            projectName={project?.projectName}
            updateProjectName={handleUpdateProjectName}
            fontSize='1rem'
          />
          <UploadFilesField
            technology={TECHNOLOGY_OPTION_TYPE.DESIGN_SERVICE} // to allow all file types
            handleFiles={handleFiles}
            id='drag-parts-library-popup'
            simpleLabel
            labelText='Upload'
          />
        </FlexRowSpaceBetween>
        <div>
          {hasChecked && (
            <FtrButton
              startIcon={<TrashIcon />}
              variant='outlined'
              color='blue'
              onClick={handleDeleteItems}
              style={{ marginTop: 8 }}
            >
              Delete
            </FtrButton>
          )}
        </div>
      </div>
    );
  }

  return (
    <>
      <Typography className={classes.title} variant='h5'>
        Configure your parts
      </Typography>
      <IconButton
        aria-label='close'
        onClick={onClose}
        style={{
          position: 'absolute',
          right: 8,
          top: 10,
        }}
      >
        <CloseIcon />
      </IconButton>
      <ProjectNameField
        projectName={project?.projectName}
        updateProjectName={handleUpdateProjectName}
        style={{ fontSize: '1rem' }}
      />
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <UploadFilesField
          style={{ marginTop: '0.5rem' }}
          technology={TECHNOLOGY_OPTION_TYPE.DESIGN_SERVICE} // to allow all file types
          handleFiles={handleFiles}
          id='drag-parts-library-popup'
          simpleLabel
          labelText='Drag or browse to upload'
          simpleLabelStyle={{ width: 220 }}
        />
        {hasChecked && (
          <FtrButton
            startIcon={<TrashIcon />}
            variant='outlined'
            color='blue'
            onClick={handleDeleteItems}
          >
            Delete
          </FtrButton>
        )}
      </div>
    </>
  );
};

function ConfigurePartsLibraryPopup(props) {
  const classes = useStyles();
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const { open, onClose, openSummaryPage } = props;

  const [loading, setLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);

  const project = useSelector((state) => state.partsLibrary?.data);
  const viewItem = useSelector((state) => state.partsLibrary?.viewItem);
  const user = useSelector((state) => state?.auth?.user);

  const [{ isTablet }] = useDeviceCheck();
  const { setSnackbarMessage } = useContext(AppContext);

  const inUseItems = project?.items?.filter(
    (item) => item.inUse && !item.isDeleted
  );

  const { userID } = user ?? {};

  const hasChecked = inUseItems?.some((item) => item.checked);
  const hasTdeBeenRun = inUseItems?.some(
    (item) => item?.tdeStatus === 'success'
  );
  const selectedItems = hasChecked
    ? inUseItems?.filter((item) => item.checked)
    : inUseItems;

  const isRunningTDE = inUseItems?.some(
    (item) => item?.tdeStatus === 'loading'
  );
  const hasImageLoading = inUseItems?.some((item) => item.is2DImageLoading);

  // Scroll to the item in the middle of the screen
  useEffect(() => {
    let timerID = null;
    if (!isEmptyValue(viewItem)) {
      timerID = setTimeout(() => {
        const itemEl = document.getElementById(
          `ID-${viewItem.itemLibraryID ?? viewItem.id}`
        );
        if (itemEl) {
          itemEl.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
          });
          dispatch(viewPartsLibraryItemData(null));
        }
      }, 1000);
    }
    return () => {
      clearTimeout(timerID);
    };
  }, [viewItem]);

  const handleSavePartsLibrary = async (newItems) => {
    setSaveLoading(true);
    const baseItems = newItems || project?.items;
    const items = baseItems.map((item) => ({
      ...item,
      materialColor: isCustomMaterialColor(item.materialColor)
        ? item.customMaterialColor
        : item.materialColor,
      color: isCustomColor(item.color) ? item.customColor : item.color,
    }));
    return updateProjectItemsPartLibrary(project?.projectLibraryID, {
      items,
      userID,
      projectName: project?.projectName,
    })
      .then(() => {
        setSnackbarMessage('Project updated successfully!');
        queryClient.invalidateQueries(['partsLibraryProjects', userID]);
        const updatedItems = project?.items.map((item) => ({
          ...item,
          isNewlyItem: false,
        }));
        updatePartsLibrarySingleData(project.projectID, {
          items: updatedItems,
        });
        setSaveLoading(false);
      })
      .catch(() => setSaveLoading(false));
  };

  const validateForm = () => {
    for (const item of selectedItems) {
      if (isEmptyValue(item.technology)) {
        dispatch(
          updatePartsLibraryItemData(
            project.projectLibraryID,
            {
              itemLibraryID: item.itemLibraryID,
              id: item.id,
              version: item.version,
            },
            {
              isTechnologyError: true,
            }
          )
        );
        return false;
      }
    }
    return true;
  };

  const handleSaveAndClose = () => {
    handleSavePartsLibrary().then(() => {
      onClose();
    });
  };

  const handleSubmitPart = async () => {
    // if form is not valid stop the function
    if (!validateForm()) {
      return;
    }

    // Split each cad file uploaded into an individual part order
    setLoading(true);
    const dataArray = await Promise.all(
      selectedItems.map(async (item) => {
        const { metadata, ...rest } = item;
        delete rest.tdeStatus;
        delete rest.generatedFields;
        let updatedPart = {
          ...rest,
          ...metadata,
          id: nanoid(), // should be unique
          qty: item.quantity,
          cadPart: item.cadFile.split(','),
          userID: item.userID,
          deleted: false,
          deliveryPreference: 'on_premise',
          checked: true, // should be checked as default
          [TWO_D_IMAGE_URLS_KEY]: [item.twoDImageUrl],
          isPartLibrary: true,
          projectLibraryID: project?.projectLibraryID,
          otherSurfaceFinish: item.customSurfaceFinish,
          otherMaterial: item.customMaterial,
          cadFile: null,
          unitType: item.unitType || user.unitType,
          status: null,
        };
        const itemPrice = await getPpePriceForItem(updatedPart);
        if (!itemPrice?.price) {
          updatedPart.status = 1;
        }
        return {
          ...updatedPart,
          ...itemPrice,
          uploadStatus: 'success',
          ppePricingStatus: 'success',
        };
      })
    );
    setLoading(false);
    if (dataArray?.length) {
      openSummaryPage(dataArray);
    }
    handleSaveAndClose();
  };

  const renderSelectedNumbers = () => {
    if (!hasChecked) {
      return null;
    }
    return `(${inUseItems.filter((item) => item.checked).length})`;
  };

  return (
    <ConfigurePartsLibraryContext.Provider
      value={{
        handleSavePartsLibrary,
      }}
    >
      <Dialog
        open={open}
        maxWidth='xl'
        PaperProps={{
          style: {
            borderRadius: isTablet ? 0 : 30,
            padding: isTablet ? 0 : 16,
            overflowY: 'clip',
          },
        }}
        fullScreen={isTablet}
      >
        <DialogTitle style={{ paddingBottom: 0, paddingTop: '1rem' }}>
          <HeaderProject onClose={onClose} />
        </DialogTitle>
        <DialogContent style={{ padding: '8px 0px' }}>
          <Box style={{ height: '1rem' }} />
          {hasTdeBeenRun && (
            <FlexRow style={{ paddingLeft: '1rem', paddingBottom: '1rem' }}>
              <div>
                <FtrSvgImage
                  src={AutoAwesomeIcon}
                  className={classes.autoAwesomeIcon}
                />
              </div>
              <FtrLightText
                fontSize='13'
                style={{
                  textAlign: 'flex-end',
                  width: '100%',
                  color: colors.blue050,
                }}
              >
                Part configurations have automatically been extracted from your
                technical drawing!
                <br />
                Please verify that configurations are correctly set.
              </FtrLightText>
            </FlexRow>
          )}
          <ConfigurePartsLibrary />
        </DialogContent>
        <Box className={classes.actionContainer}>
          <FtrButton
            color='blue'
            onClick={handleSubmitPart}
            variant='outlined'
            disabled={isRunningTDE}
          >
            Order {renderSelectedNumbers()}
          </FtrButton>
          <FtrButton
            color='blue'
            onClick={handleSaveAndClose}
            variant='contained'
            loading={saveLoading || hasImageLoading}
            disabled={saveLoading || hasImageLoading || isRunningTDE}
          >
            Save
          </FtrButton>
        </Box>
        <PpePriceLoadingBackdrop open={loading} />
      </Dialog>
    </ConfigurePartsLibraryContext.Provider>
  );
}

function matchDispatchToProps(dispatch, props) {
  const from = '/parts-library';
  return {
    openSummaryPage: (formDataAvailable) =>
      dispatch(openPartSummaryPage(formDataAvailable, props, from)),
  };
}

const withConnect = connect(null, matchDispatchToProps);

export default withRouter(withConnect(ConfigurePartsLibraryPopup));
