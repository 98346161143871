import { useQuery } from 'react-query';

import { getAvailablePromotionCodeForUserApi } from '../apis/factoremPromotionCodesApi';

import { isEmptyValue } from '../utils/commonUtils';

// -------------------------------------------------------------------------------------------------

const useCustomerAvailablePromoCode = (customerID) => {
  const { data: promoCode, isLoading: loading } = useQuery(
    ['getUserAddresses'],
    () => {
      if (isEmptyValue(customerID)) {
        return null;
      }

      return getAvailablePromotionCodeForUserApi(customerID);
    },
    {
      enabled: !isEmptyValue(customerID),
    }
  );

  return [
    {
      promoCode,
      loading,
    },
  ];
};

export default useCustomerAvailablePromoCode;
