import thunkMiddleware from 'redux-thunk';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import { applyMiddleware, createStore, compose } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { persistStore, persistReducer } from 'redux-persist';
import * as Sentry from '@sentry/react';

import { verifyAuth } from './actions/';

import rootReducer from './reducers';

import { REACT_APP_ENV } from './constants';

// -------------------------------------------------------------------------------------------------

export default function configureStore(persistedState) {
  const persistConfig = {
    key: 'root',
    storage,
    blacklist: [
      'auth',
      'cadRendererAINC',
      'costEstimationInfo',
      'invoice',
      'items',
      'multiCheckoutForm',
      'myorders',
      'myorders',
      'order',
      'orderReady',
      'partsLibrary',
      'project',
      'projectBulkQuotationsForm',
      'tour',
      'twoDImages',
      'users',
      'viewAsSupplier',
    ],
  };

  const persistedReducer = persistReducer(persistConfig, rootReducer);
  const sentryReduxEnhancer = Sentry.createReduxEnhancer();

  const store = createStore(
    persistedReducer,
    persistedState,
    REACT_APP_ENV === 'prod'
      ? compose(applyMiddleware(thunkMiddleware), sentryReduxEnhancer)
      : composeWithDevTools({ trace: true })(
          compose(applyMiddleware(thunkMiddleware), sentryReduxEnhancer)
        )
  );
  let persistor = persistStore(store, null, () => {
    // This callback will be invoked after the state has been restored
    store.dispatch(verifyAuth());
  });
  return { store, persistor };
}
