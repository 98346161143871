import React from 'react';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles/index';

import { Icon, Tooltip } from '@material-ui/core';

import { AddAlarm } from '@material-ui/icons';

import PendingActionsIcon from '../assets/icons/pending_actions.svg';

import { colors } from '../palette';

const useStyles = makeStyles(() => ({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    color: colors.lightGray,
    zIndex: 1,
    border: `2px solid ${colors.lightGray}`,
    borderRadius: '4px',
    minHeight: '1.1rem',
    minWidth: '2rem',
    width: '9.5rem',
    columnGap: '3px',
    justifyContent: 'center',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    cursor: 'pointer',
  },
  exists: {
    borderColor: colors.orange,
    color: colors.orange,
    width: '9.5rem',
  },
  not_exists: {
    borderColor: colors.lightGray,
    color: colors.lightGray,
    width: '9.5rem',
  },
  onlyIcon: {
    width: '2.5rem',
    height: '1.125rem',
  },
  imageIcon: {
    display: 'flex',
    justifyContent: 'center',
    height: 28,
    width: 22,
    paddingLeft: 3,
  },
}));

export const ItemRfqEmailScheduleConfigButton = (props) => {
  const classes = useStyles();

  const history = useHistory();

  const { item, withText = false } = props;

  return (
    <Tooltip
      title={
        item.configID
          ? 'Edit RFQ Email Schedule Config'
          : 'Create RFQ Email Schedule Config'
      }
      arrow
    >
      <div
        className={clsx(
          classes.wrapper,
          item.configID ? classes.exists : classes.not_exists,
          !withText && classes.onlyIcon
        )}
        onClick={(evt) => {
          evt.preventDefault();
          const projectPath = `/manage-projects/${item.projectID}/rfq-email-schedules`;
          history.push(projectPath);
        }}
        data-cy='view-allow-supplier-button'
      >
        <React.Fragment>
          {item.configID ? (
            <Icon>
              <img
                className={classes.imageIcon}
                src={PendingActionsIcon}
                color='primary'
                alt=''
              />
            </Icon>
          ) : (
            <AddAlarm fontSize='small' />
          )}
        </React.Fragment>
      </div>
    </Tooltip>
  );
};
