import React, { useRef } from 'react';
import { useDispatch } from 'react-redux';

import { makeStyles, Card, Box, Typography } from '@material-ui/core';

import { colors } from '../../../palette';

import ImageWith3DViewer from '../../../components/images/ImageWith3DViewer';
import withLabelHoC from '../../../components/images/withLabelHOC';

import {
  isCustomMaterial,
  isCustomSurfaceFinish,
} from '../../../utils/inputUtils';
import { showLabelPartsLibraryInImage } from '../../../utils/partsLibraryUtils';

import {
  updatePartsLibrarySingleData,
  viewPartsLibraryItemData,
} from '../../../actions';

import useDeviceCheck from '../../../hooks/useDeviceCheck';

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: colors.neutral010,
    borderRadius: 20,
    width: '100%',
    display: 'flex',
    '&:hover': {
      backgroundColor: colors.fontWhite,
    },
  },
  name: {
    color: colors.neutral090,
    fontSize: '1rem',
    fontWeight: 600,
    margin: '16px 0 4px',
    [theme.breakpoints.down('md')]: {
      fontSize: '0.875rem',
    },
  },
  contentContainer: {
    paddingTop: 0,
    position: 'relative',
    paddingLeft: 16,
  },
  partID: {
    backgroundColor: colors.blue060,
    color: colors.fontWhite,
    position: 'absolute',
    left: -82,
    top: 12,
    zIndex: 100,
    borderRadius: 10,
    padding: '2px 8px',
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
    gap: 3,
    color: colors.neutral090,
    fontSize: '0.875rem',
    [theme.breakpoints.down('md')]: {
      fontSize: '0.75rem',
    },
  },
}));

const ImageWithLabel = withLabelHoC(ImageWith3DViewer);

function ItemCard(props) {
  const { item, project, setConfigurePartsPopupOpen, isFetching } = props;
  const {
    name,
    twoDImageUrl,
    technology,
    material,
    customMaterial,
    surfaceFinish,
    customSurfaceFinish,
    cadFile,
    quantity = 1,
    version,
    status,
  } = item;
  const classes = useStyles();
  const dispatch = useDispatch();
  const [{ isTablet }] = useDeviceCheck();

  const itemRef = useRef();

  const handleViewProject = () => {
    if (isFetching) {
      return;
    }
    dispatch(updatePartsLibrarySingleData(project?.projectLibraryID, project));
    dispatch(viewPartsLibraryItemData(item));
    setConfigurePartsPopupOpen(true);
  };

  return (
    <Card
      elevation={1}
      className={classes.container}
      style={{ cursor: isFetching ? 'progress' : 'pointer' }}
      onClick={handleViewProject}
      ref={itemRef}
    >
      <ImageWithLabel
        cadFile={cadFile}
        twoDImageUrl={twoDImageUrl}
        version={version}
        height={isTablet ? 140 : 160}
        width={isTablet ? 140 : 160}
        borderRadius={isTablet ? 15 : 20}
        label={showLabelPartsLibraryInImage(status)}
      />
      <Box className={classes.contentContainer}>
        <Typography className={classes.name}>{name}</Typography>
        <Box className={classes.details}>
          <div>{technology ? technology : ''}</div>
          <div>
            {isCustomMaterial(material) ? customMaterial : material ?? ''}
          </div>
          <div>
            {isCustomSurfaceFinish(surfaceFinish)
              ? customSurfaceFinish
              : surfaceFinish ?? ''}
          </div>
          <div>Qty: {quantity}</div>
        </Box>
      </Box>
    </Card>
  );
}

export default ItemCard;
