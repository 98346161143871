import React, { useReducer } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash';

import { makeStyles } from '@material-ui/core/styles/index';

import {
  Divider,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';

import { Add as AddIcon } from '@material-ui/icons';

import RfqQuotationRowInput from './RfqQuotationRowInput';
import KeyValueInfoDisplay from '../labels/KeyValueInfoDisplay';
import { FtrButton } from '../ftr-components';
import { FlexRow } from '../layouts/FlexLayouts';
import PriceFeedbackSupplierPopup from '../popups/PriceFeedbackSupplierPopup';
import FeedbackThumbsContainer from '../FeedbackPriceSupplier';

import * as formActions from '../../actions/projectBulkQuotationsForm';

import { convertPriceWithQuantityToCurrency } from '../../utils/currencyUtils';
import { getQuotationExchangeRate } from '../../utils/quotationUtils';

import { ITEM_QUOTE_TYPE } from '../../constants/projectConstants';
import {
  CMM_PRICE_STR,
  OTHER_MATERIAL_STR,
  OTHER_SURFACE_FINISH_STR,
  QUANTITY_STR,
  REMARKS_STR,
  UNIT_QUOTE_STR,
} from '../../constants/quotationConstants';
import { SUPPLIER_FEEDBACK } from '../../constants/ppeFeedbackConstants';

import { colors } from '../../palette';

import { upsertPriceFeedback } from '../../apis/ppeFeedbackApi';

import { getItemImageUrl } from '../../utils/itemUtils';
import { reciprocal } from '../../utils/numberUtils';
import { isEmptyValue } from '../../utils/commonUtils';

import { notifySuccess } from '../../services/notificationService';

import { getProjectBulkQuotationsFormSelector } from '../../selectors/projectBulkQuotationsFormSelector';

import withViewAsSupplierHOC from '../../hocs/withViewAsSupplierHOC';

// -------------------------------------------------------------------------------

const useStyles = makeStyles(() => ({
  container: {
    width: '100%',
  },
  containerTitle: {
    margin: 0,
  },
  addButton: {
    borderRadius: 8,
    backgroundColor: colors.menuItemHover,
    '&:hover': {
      backgroundColor: colors.blue050,
      color: 'white',
    },
  },
}));

function KeyValueInfoDisplayWithMargin(props) {
  const { name, value } = props;

  return (
    <div>
      <KeyValueInfoDisplay
        name={name}
        value={value}
        bigValueFontSize
        color={colors.blue050}
      />
    </div>
  );
}

function ProjectItemQuotationForm(props) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const formData = useSelector(getProjectBulkQuotationsFormSelector);

  const { currency, exchangeRate, item, expanded, user } = props;

  const { formError, qcReports } = formData;
  const {
    itemQuoteType,
    quotations: inputFields,
    technology,
    acceptedQuote,
  } = item;

  const {
    unitPrice: defaultPpeFeedbackTargetPrice,
  } = convertPriceWithQuantityToCurrency({
    totalPrice: item.priceFeedback?.supplierLatestFeedback?.targetPrice || 0,
    currency,
    exchangeRate,
  });

  const [feedbackState, updateFeedbackState] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      openPopup: false,
      priceFeedback: item.priceFeedback?.isAdminLatestFeedback
        ? null
        : item.priceFeedback?.supplierLatestFeedback?.feedback,
      unitPriceStr: null,
      targetUnitPrice: item.priceFeedback?.isAdminLatestFeedback
        ? null
        : defaultPpeFeedbackTargetPrice,
      feedbackDetails: item.priceFeedback?.isAdminLatestFeedback
        ? null
        : item.priceFeedback?.supplierLatestFeedback?.feedbackDetails,
      additionalRemarks: item.priceFeedback?.isAdminLatestFeedback
        ? null
        : item.priceFeedback?.supplierLatestFeedback?.additionalRemarks,
      supplierUnitPriceInSGD: null,
      showFeedbackQuestion: item.priceFeedback?.isAdminLatestFeedback
        ? false
        : isEmptyValue(item.priceFeedback?.supplierLatestFeedback?.targetPrice),
    }
  );

  const onInputFieldsChange = (event, quotationID, fieldName) => {
    dispatch(
      formActions.updateQuoteFieldValue({
        itemID: item.itemID,
        quotationID,
        fieldName,
        value: event.target.value,
      })
    );
  };

  const onAddFields = () => {
    dispatch(
      formActions.addQuoteFields({
        itemID: item.itemID,
      })
    );
  };

  const onRemoveFields = (event, quotationID) => {
    dispatch(
      formActions.removeQuotation({
        itemID: item.itemID,
        quotationID,
      })
    );
  };

  const handleUpsertPriceFeedbackApi = (priceFeedbackProperties = {}) => {
    upsertPriceFeedback({
      iqLogsID: item.iqLogsID,
      userID: user?.userID,
      unitPrice: feedbackState.supplierUnitPriceInSGD,
      quotationID: item.itemQuotationID,
      ...priceFeedbackProperties,
    });
    let extraMessage = '';
    if (
      priceFeedbackProperties?.feedbackDetails?.[
        SUPPLIER_FEEDBACK.TAKE_ORDER_WISH_PRICE_TO_REVISE
      ] === true ||
      priceFeedbackProperties?.feedbackDetails?.[
        SUPPLIER_FEEDBACK.TAKE_ORDER_IF_PRICE_IS_REVISED
      ] === true
    ) {
      extraMessage = ' Your submission will be reviewed shortly.';
    }
    notifySuccess(`Thank you for your feedback!${extraMessage}`);
  };

  const onSubmitFeedbackPrice = (params) => {
    const { unitPrice } = convertPriceWithQuantityToCurrency({
      totalPrice: params?.targetUnitPrice,
      currency,
      exchangeRate: reciprocal(exchangeRate),
    });
    const priceFeedbackProperties = {
      priceFeedback: feedbackState.priceFeedback,
      feedbackDetails: params?.feedbackDetails,
      additionalRemarks: params?.additionalRemarks,
    };
    handleUpsertPriceFeedbackApi({
      ...priceFeedbackProperties,
      targetPrice: unitPrice,
    });
    updateFeedbackState({
      ...priceFeedbackProperties,
      targetUnitPrice: params?.targetUnitPrice,
      showFeedbackQuestion: false,
    });
  };

  const renderRfqQuotationInput = (inputField, expanded) => {
    return (
      <RfqQuotationRowInput
        inputField={inputField}
        currency={currency}
        technology={technology}
        qcReports={qcReports}
        showDeleteButton={inputFields.length > 1}
        onInputFieldsChange={onInputFieldsChange}
        quantityError={get(
          formError,
          `${item.itemID}.${inputField.id}.${QUANTITY_STR}`
        )}
        unitQuoteError={get(
          formError,
          `${item.itemID}.${inputField.id}.${UNIT_QUOTE_STR}`
        )}
        otherMaterialError={get(
          formError,
          `${item.itemID}.${inputField.id}.${OTHER_MATERIAL_STR}`
        )}
        otherSurfaceFinishError={get(
          formError,
          `${item.itemID}.${inputField.id}.${OTHER_SURFACE_FINISH_STR}`
        )}
        cmmPriceError={get(
          formError,
          `${item.itemID}.${inputField.id}.${CMM_PRICE_STR}`
        )}
        remarksError={get(
          formError,
          `${item.itemID}.${inputField.id}.${REMARKS_STR}`
        )}
        onDeleteButtonClick={(event) => onRemoveFields(event, inputField.id)}
        expanded={expanded}
      />
    );
  };

  const renderPpeQuotationInfo = (inputField) => {
    if (item.supplierQuoted && item.supplierQuotations?.length !== 1) {
      return null;
    }
    const _exchangeRate =
      getQuotationExchangeRate(inputField, currency) || exchangeRate;
    const quantity = acceptedQuote?.quantity ?? inputField.quantity;

    // check for the accepted quote or supplier quotation  or item price
    const totalPrice =
      acceptedQuote?.priceBidded ||
      item.supplierQuotations?.[0]?.priceBidded ||
      inputField.quote;

    const {
      unitPriceStr: unitQuoteStr,
      totalPriceStr,
    } = convertPriceWithQuantityToCurrency({
      totalPrice,
      quantity,
      currency,
      exchangeRate: _exchangeRate,
    });

    const {
      unitPrice: supplierUnitPriceInSGD,
    } = convertPriceWithQuantityToCurrency({
      totalPrice,
      currency,
      quantity,
      exchangeRate: reciprocal(exchangeRate), // in SGD
    });
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: isMobile ? 'column' : 'row',
          gap: !isMobile && '4rem',
          margin: isMobile && '0 1.2rem',
        }}
      >
        <KeyValueInfoDisplayWithMargin
          name='Quantity'
          value={acceptedQuote?.quantity ?? inputField.quantity}
        />
        <FlexRow>
          <KeyValueInfoDisplay
            name='Unit Price'
            value={unitQuoteStr}
            bigValueFontSize
            color={colors.blue050}
          />
          <FeedbackThumbsContainer
            unitQuoteStr={unitQuoteStr}
            updateFeedbackState={updateFeedbackState}
            onSubmitFeedbackPrice={onSubmitFeedbackPrice}
            feedbackState={feedbackState}
            supplierUnitPriceInSGD={supplierUnitPriceInSGD}
            onUpsertFeedbackApi={handleUpsertPriceFeedbackApi}
            item={item}
            currency={currency}
            exchangeRate={exchangeRate}
          />
        </FlexRow>
        <KeyValueInfoDisplayWithMargin
          name='Total Price'
          value={totalPriceStr}
        />
      </div>
    );
  };

  const renderAddMoreQuoteButton = () => {
    if (itemQuoteType === ITEM_QUOTE_TYPE.PPE || !expanded) {
      return null;
    }

    return (
      <div
        style={{
          padding: '8px 0',
          display: 'flex',
        }}
      >
        <FtrButton
          color='blue'
          onClick={() => onAddFields()}
          id='add-quote-btn'
          size='medium'
          style={{ height: '36px' }}
        >
          <AddIcon />
          &nbsp;Add quote&nbsp;
        </FtrButton>
      </div>
    );
  };

  const showIndex = !acceptedQuote && inputFields.length > 1;

  return (
    <Grid container fluid='true' spacing={3} direction='column'>
      <Grid item className={classes.container}>
        <Grid item>
          {itemQuoteType !== ITEM_QUOTE_TYPE.PPE && expanded && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Typography
                gutterBottom
                variant='body1'
                className={classes.containerTitle}
              >
                Enter quote(s):
              </Typography>
            </div>
          )}
          {!expanded && <Divider />}
          <div
            style={{
              marginTop: 4,
            }}
          >
            {inputFields.map((inputField, index) => (
              <div
                style={{
                  padding: '2px 0',
                }}
                key={`${inputField.id}`}
              >
                <div
                  style={{
                    margin: '2px 0',
                    display: 'flex',
                    justifyContent: isMobile ? 'flex-start' : 'center',
                    alignItems: 'flex-start',
                    gap: '1rem',
                  }}
                >
                  {showIndex && (
                    <Typography
                      variant='body1'
                      style={{
                        paddingTop: '8px',
                      }}
                    >
                      {index + 1}.
                    </Typography>
                  )}
                  {itemQuoteType === ITEM_QUOTE_TYPE.PPE
                    ? renderPpeQuotationInfo(inputField)
                    : renderRfqQuotationInput(inputField, expanded)}
                </div>
              </div>
            ))}
          </div>
          {!item.supplierQuoted && renderAddMoreQuoteButton()}
        </Grid>
      </Grid>
      {feedbackState.openPopup && (
        <PriceFeedbackSupplierPopup
          open={feedbackState.openPopup}
          imageUrl={getItemImageUrl(item)}
          unitPriceStr={feedbackState.unitPriceStr}
          onSubmit={onSubmitFeedbackPrice}
          onClose={() => updateFeedbackState({ openPopup: false })}
          targetUnitPrice={feedbackState.targetUnitPrice}
          feedbackDetails={feedbackState.feedbackDetails}
          additionalRemarks={feedbackState.additionalRemarks}
          supplierQuoted={item.supplierQuoted}
        />
      )}
    </Grid>
  );
}

export default withViewAsSupplierHOC(ProjectItemQuotationForm);
