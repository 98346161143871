import React from 'react';

import StatusButtonV2Presentational from '../buttons/StatusButtonV2Presentational';

import { ITEM_STATUS_TEXT_MAPPING } from '../../constants/itemStatus';

// -----------------------------------------------------------------------------------------------

function ProjectItemStatusInfoV2(props) {
  const { text = 'Text', numOfItems = 0 } = props;

  const getBadgeContent = () => {
    if (text === ITEM_STATUS_TEXT_MAPPING.DELIVERED) {
      return null;
    }
    const badgeContent = numOfItems > 1 ? numOfItems : null;
    return badgeContent;
  };

  return (
    <StatusButtonV2Presentational
      status={text}
      badgeContent={getBadgeContent()}
    />
  );
}

export default ProjectItemStatusInfoV2;
