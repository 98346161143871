import { CsvBuilder } from 'filefy';
import { ceil } from 'lodash';
import React, { useEffect, useReducer, useState } from 'react';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles/index';

import { DataGrid } from '@mui/x-data-grid';

import HorizontalExpandSpace from '../../components/ftr-components/HorizontalExpandSpace';
import CustomToolbar, {
  DataGridToolbarLayout,
} from '../../components/grid-data/CustomToolbar';
import GridDataPagination from '../../components/grid-data/GridDataPagination';
import { ExportCsvButton } from '../../components/grid-data/buttons/ExportCsvButton';
import SearchBar from '../../components/grid-data/buttons/SearchBar';
import DataGridWrapTextCell from '../../components/tables/cells/DataGridWrapTextCell';
import { FtrItalicText } from '../../components/ftr-components';

import { getAllUserFeedback } from '../../apis/userFeedbackApi';

import { getUserRoleSelector } from '../../selectors/userSelector';

import { generateCurrentCustomDateTimeString } from '../../util';
import { arrayToSeparatedComma } from '../../utils/arrayUtils';
import { isEmptyValue } from '../../utils/commonUtils';
import { transformCsvExportDataGrid } from '../../utils/csvExportUtils';
import { dateTzSingapore } from '../../utils/dateTimeUtils';
import { fourDigitUtil } from '../../utils/numberUtils';
import { isSuperAdminRole } from '../../utils/roleUtils';

import { useDataGridFilterHook } from '../../hooks/useDataGridFilterHook';

import {
  USER_FEEDBACK_QUESTION_LIST,
  USER_FEEDBACK_RATING_QUESTION_LIST,
  USER_FEEDBACK_STATUSES,
} from '../../constants/userFeedbackConstants';

import { colors } from '../../palette';

// -------------------------------------------------------------------------------------------------

const useStyles = makeStyles(() => ({
  body: {
    paddingTop: '1rem',
    marginBottom: '2rem',
    '& .MuiDataGrid-columnSeparator': {
      display: 'none',
    },
    ' & .MuiDataGrid-columnHeaderTitleContainer': {
      padding: '0',
    },
    '& .MuiTablePagination-root': {
      marginRight: '4rem',
    },
    '& .MuiDataGrid-columnHeaderTitle': {
      lineHeight: 'normal',
      wordBreak: 'break-word',
      whiteSpace: 'normal',
      color: colors.blue060,
      fontSize: '11pt',
      fontWeight: 600,
    },
    '& .MuiDataGrid-columnsContainer': {
      display: 'flex',
      justifyContent: 'center',
    },
  },
}));

function UserFeedbackDataGrid() {
  const classes = useStyles();

  const role = useSelector(getUserRoleSelector);

  const { data: allFeedback, isLoading } = useQuery(
    'getAllUserFeedback',
    getAllUserFeedback
  );

  const [tableQueryParams, updateTableQueryParams] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      page: 0,
      pageSize: 10,
      search: '',
      totalCount: 0,
      loading: false,
    }
  );

  const [
    filteredData,
    { setSearchStr, setColumnsDef, setSourceData },
  ] = useDataGridFilterHook({
    search: '',
    source: allFeedback,
  });

  const getQuestionHeader = (questionObject) => {
    return `Q${questionObject.id} Response: ${questionObject.question}`;
  };

  const isUnAnsweredQuestion = (rowData, qa) => {
    return (
      rowData.status === USER_FEEDBACK_STATUSES.UNANSWERED && !isEmptyValue(qa)
    );
  };

  const renderUnanswered = () => {
    return (
      <FtrItalicText style={{ color: colors.warningYellow }}>
        Unanswered
      </FtrItalicText>
    );
  };

  const renderRateFeedback = (rowData, options) => {
    const { cell = false } = options || {};
    const qa = rowData.qAndA?.find((q) => q.id === 6);
    if (isEmptyValue(qa?.answer)) {
      return 'N.A.';
    }
    if (isUnAnsweredQuestion(rowData, qa)) {
      if (cell) {
        return 'Unanswered';
      }
      return renderUnanswered();
    }
    return Array(qa?.answer)
      .fill('⭐️')
      .join('');
  };

  const renderCellResponse = (rowData, id) => {
    const qa = rowData.qAndA?.find((q) => q.id === id);
    if (isUnAnsweredQuestion(rowData, qa)) {
      return renderUnanswered();
    }
    return <DataGridWrapTextCell text={qa?.answer ?? 'N.A.'} />;
  };

  const renderGetterResponse = (rowData, id) => {
    const qa = rowData.qAndA?.find((q) => q.id === id);
    if (isUnAnsweredQuestion(rowData, qa)) {
      return 'Unanswered';
    }
    return qa?.answer ?? 'N.A.';
  };

  const defaultColumns = [
    {
      headerName: 'Feedback ID',
      field: 'feedbackID',
      valueGetter: ({ row: rowData }) => {
        return `${fourDigitUtil(rowData.userID)}${fourDigitUtil(
          rowData.feedbackNumber
        )}`;
      },
      width: 120,
    },
    {
      headerName: 'User ID',
      field: 'userID',
      width: 90,
    },
    {
      headerName: 'Order Delivery ID',
      field: 'referenceName',
      width: 120,
      valueGetter: ({ row: rowData }) => {
        return rowData?.referenceName ?? 'N.A.';
      },
      renderCell: ({ row: rowData }) => {
        return <DataGridWrapTextCell text={rowData?.referenceName ?? 'N.A.'} />;
      },
    },
    {
      headerName: 'Name',
      field: 'name',
      renderCell: ({ row: rowData }) => (
        <DataGridWrapTextCell text={rowData.name} />
      ),
      width: 150,
    },
    {
      headerName: 'Role',
      field: 'role',
      renderCell: ({ row: rowData }) => (
        <DataGridWrapTextCell text={rowData.role} />
      ),
    },
    {
      headerName: 'Email',
      field: 'email',
      renderCell: ({ row: rowData }) => (
        <DataGridWrapTextCell text={rowData.email} />
      ),
      width: 180,
    },
    {
      headerName: 'Status',
      field: 'status',
      renderCell: ({ row: rowData }) => {
        <DataGridWrapTextCell text={rowData.status ?? 'N.A.'} />;
      },
      width: 180,
    },
    {
      headerName: getQuestionHeader(
        USER_FEEDBACK_QUESTION_LIST.find((q) => q.id === 1)
      ),
      csvHeader: 'Q1 Response',
      field: 'q1Response',
      valueGetter: ({ row: rowData }) => {
        return renderGetterResponse(rowData, 1);
      },
      renderCell: ({ row: rowData }) => {
        return renderCellResponse(rowData, 1);
      },
      width: 180,
    },
    {
      headerName: getQuestionHeader(
        USER_FEEDBACK_QUESTION_LIST.find((q) => q.id === 2)
      ),
      field: 'q2Response',
      valueGetter: ({ row: rowData }) => {
        const qa = rowData.qAndA?.find((q) => q.id === 2);
        if (isUnAnsweredQuestion(rowData, qa)) {
          return 'Unanswered';
        }
        return qa?.answer ? arrayToSeparatedComma(qa?.answer) : 'N.A.';
      },
      renderCell: ({ row: rowData }) => {
        const qa = rowData.qAndA?.find((q) => q.id === 2);
        if (isUnAnsweredQuestion(rowData, qa)) {
          return renderUnanswered();
        }
        return (
          <DataGridWrapTextCell
            text={qa?.answer ? arrayToSeparatedComma(qa?.answer) : 'N.A.'}
          />
        );
      },
      width: 180,
    },
    {
      headerName: getQuestionHeader(
        USER_FEEDBACK_QUESTION_LIST.find((q) => q.id === 3)
      ),
      field: 'q3Response',
      valueGetter: ({ row: rowData }) => {
        return renderGetterResponse(rowData, 3);
      },
      renderCell: ({ row: rowData }) => {
        return renderCellResponse(rowData, 3);
      },
      width: 180,
    },
    {
      headerName: getQuestionHeader(
        USER_FEEDBACK_QUESTION_LIST.find((q) => q.id === 4)
      ),
      field: 'q4Response',
      valueGetter: ({ row: rowData }) => {
        return renderGetterResponse(rowData, 4);
      },
      renderCell: ({ row: rowData }) => {
        return renderCellResponse(rowData, 4);
      },
      width: 180,
    },
    {
      headerName: getQuestionHeader(
        USER_FEEDBACK_QUESTION_LIST.find((q) => q.id === 5)
      ),
      field: 'q5Response',
      valueGetter: ({ row: rowData }) => {
        return renderGetterResponse(rowData, 5);
      },
      renderCell: ({ row: rowData }) => {
        return renderCellResponse(rowData, 5);
      },
      width: 180,
    },
    {
      headerName: getQuestionHeader(
        USER_FEEDBACK_RATING_QUESTION_LIST.find((q) => q.id === 6)
      ),
      field: 'q6Response',
      valueGetter: ({ row: rowData }) => renderRateFeedback(rowData),
      renderCell: ({ row: rowData }) =>
        renderRateFeedback(rowData, { cell: true }),
      width: 180,
    },
    {
      headerName: getQuestionHeader(
        USER_FEEDBACK_RATING_QUESTION_LIST.find((q) => q.id === 7)
      ),
      field: 'q7Response',
      valueGetter: ({ row: rowData }) => {
        return renderGetterResponse(rowData, 7);
      },
      renderCell: ({ row: rowData }) => {
        return renderCellResponse(rowData, 7);
      },
      width: 180,
    },
    {
      headerName: getQuestionHeader(
        USER_FEEDBACK_RATING_QUESTION_LIST.find((q) => q.id === 8)
      ),
      field: 'q8Response',
      valueGetter: ({ row: rowData }) => {
        const qa = rowData.qAndA?.find((q) => q.id === 8);
        if (isUnAnsweredQuestion(rowData, qa)) {
          return 'Unanswered';
        }
        return qa?.answer ? `${qa.answer}/${qa.max}` : 'N.A.';
      },
      renderCell: ({ row: rowData }) => {
        const qa = rowData.qAndA?.find((q) => q.id === 8);
        if (isUnAnsweredQuestion(rowData, qa)) {
          return renderUnanswered();
        }
        return (
          <DataGridWrapTextCell
            text={qa?.answer ? `${qa.answer}/${qa.max}` : 'N.A.'}
          />
        );
      },
      width: 180,
    },
    {
      headerName: getQuestionHeader(
        USER_FEEDBACK_RATING_QUESTION_LIST.find((q) => q.id === 9)
      ),
      field: 'q9Response',
      valueGetter: ({ row: rowData }) => {
        const qa = rowData.qAndA?.find((q) => q.id === 9);
        if (isUnAnsweredQuestion(rowData, qa)) {
          return 'Unanswered';
        }
        return qa?.answer ? `${qa.answer}/${qa.max}` : 'N.A.';
      },
      renderCell: ({ row: rowData }) => {
        const qa = rowData.qAndA?.find((q) => q.id === 9);
        if (isUnAnsweredQuestion(rowData, qa)) {
          return renderUnanswered();
        }
        return (
          <DataGridWrapTextCell
            text={qa?.answer ? `${qa.answer}/${qa.max}` : 'N.A.'}
          />
        );
      },
      width: 210,
    },
    {
      headerName: 'Date and time',
      field: 'createdAt',
      valueGetter: ({ row: rowData }) => {
        return dateTzSingapore(rowData.createdAt);
      },
      width: 150,
    },
    {
      headerName: 'Location of response submission',
      field: 'submitLocation',
      renderCell: ({ row: rowData }) => {
        <DataGridWrapTextCell text={rowData.submitLocation ?? 'N.A.'} />;
      },
      width: 180,
    },
  ];

  const [columns, _setColumns] = useState(defaultColumns);

  useEffect(() => {
    setSearchStr(tableQueryParams.search);
  }, [tableQueryParams.search]);

  useEffect(() => {
    updateTableQueryParams({ totalCount: filteredData?.length });
  }, [filteredData]);

  useEffect(() => {
    setSourceData(allFeedback);
  }, [allFeedback]);

  useEffect(() => {
    updateTableQueryParams({ loading: isLoading ?? false });
  }, [isLoading]);

  useEffect(() => {
    setColumnsDef(columns);
  }, [columns]);

  const handleSearch = (searchTerm) => {
    updateTableQueryParams({
      page: 0,
      search: searchTerm,
    });
  };

  const handleDownloadCsv = () => {
    const fileName = `All User Feedback ${generateCurrentCustomDateTimeString()}.csv`;
    const { exportedColumns, exportedData } = transformCsvExportDataGrid(
      columns,
      filteredData
    );
    const builder = new CsvBuilder(fileName);
    builder
      .setDelimeter(',')
      .setColumns(exportedColumns)
      .addRows(exportedData)
      .exportFile();
  };

  const getCustomerToolbar = () => {
    const searchBar = (
      <SearchBar
        key='search'
        onSearch={handleSearch}
        searchTerm={tableQueryParams.search}
      />
    );

    const exportButton = (
      <ExportCsvButton
        key='export-csv'
        handleClick={handleDownloadCsv}
        show={isSuperAdminRole(role)}
      />
    );

    const buttons = [searchBar, exportButton];

    return (
      <DataGridToolbarLayout>
        <HorizontalExpandSpace />
        <CustomToolbar buttons={buttons} />
      </DataGridToolbarLayout>
    );
  };

  return (
    <div className={classes.body}>
      <DataGrid
        autoHeight
        rows={filteredData ?? []}
        columns={columns.map((col) => ({
          ...col,
          sortable: false,
        }))}
        getRowId={(row) => row.id}
        rowHeight={80}
        headerHeight={80}
        components={{
          Toolbar: getCustomerToolbar,
          Pagination: () => (
            <GridDataPagination
              pageCount={ceil(
                tableQueryParams.totalCount / tableQueryParams.pageSize
              )}
            />
          ),
        }}
        rowsPerPageOptions={[10, 20, 50]}
        pageSize={tableQueryParams.pageSize}
        onPageSizeChange={(newPageSize) =>
          updateTableQueryParams({ pageSize: newPageSize })
        }
        disableRowSelectionOnClick
        disableSelectionOnClick
        disableColumnMenu
        loading={tableQueryParams.loading}
      />
    </div>
  );
}

export default UserFeedbackDataGrid;
