import React from 'react';

import PaperSummary from './PaperSummary';
import { FtrTypography } from '../../ftr-components';

import { isEmptyValue } from '../../../utils/commonUtils';

import { QC_NO_QC_REPORT, QC_FORMAL_CMM_REPORT } from '../../../constants/';

import { colors } from '../../../palette';

// -------------------------------------------------------------------------------

function CertificationSummary(props) {
  const {
    selectedItems = [],
    mainQcReportCost,
    qcReports = {},
    qcOptionPrices = {},
    onEditClick = () => {},
    editable = true,
  } = props;

  const mainQcReport = qcReports?.main || QC_NO_QC_REPORT;

  if (isEmptyValue(selectedItems)) {
    return;
  }

  const renderMainQcReportCertificate = () => {
    let subInfo = '';
    if (mainQcReport !== QC_NO_QC_REPORT) {
      subInfo =
        selectedItems.length > 1
          ? `${selectedItems.length} parts`
          : `${selectedItems.length} part`;
    }

    let price = mainQcReportCost;
    if (mainQcReport === QC_FORMAL_CMM_REPORT) {
      price = 'Request Quote';
    }

    let title = mainQcReport;
    if (mainQcReport === QC_NO_QC_REPORT) {
      title = 'Not required';
      price = null;
    }

    return (
      <PaperSummary
        title={title}
        onEditClick={onEditClick}
        subInfo={subInfo}
        price={price}
        editable={editable}
      />
    );
  };

  const renderQcReportAddOns = () => {
    const addOns = qcReports?.addOns || [];
    let subInfo = '';
    if (mainQcReport !== QC_NO_QC_REPORT) {
      subInfo =
        selectedItems.length > 1
          ? `${selectedItems.length} parts`
          : `${selectedItems.length} part`;
    }

    return addOns.map((addOn) => {
      const addOnPrice = qcOptionPrices[addOn];

      const price = addOnPrice ? addOnPrice.split(' (')[0] : '';
      return (
        <PaperSummary
          key={addOn}
          title={addOn}
          onEditClick={onEditClick}
          subInfo={subInfo}
          price={price}
          editable={editable}
        />
      );
    });
  };

  return (
    <div id='certification-summary'>
      <FtrTypography
        type='heading'
        fontSize='18'
        style={{ color: colors.neutral080, paddingBottom: '0.5rem' }}
      >
        Certification
      </FtrTypography>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '0.5rem',
        }}
      >
        {renderMainQcReportCertificate()}
        {renderQcReportAddOns()}
      </div>
    </div>
  );
}

export default CertificationSummary;
