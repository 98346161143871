// Import settings
import React from 'react';

// Import material UI components
import { Switch, FormGroup, FormControlLabel } from '@material-ui/core';

function PpeSwitchForm(props) {
  const { switchState, onSwitchChange } = props;

  return (
    <FormGroup row>
      {Object.keys(switchState).map((option, index) => {
        return (
          <FormControlLabel
            key={index}
            control={
              <Switch
                checked={!!+switchState[option]}
                onChange={(event) =>
                  onSwitchChange(event.target.name, event.target.checked)
                }
                name={option}
                color='primary'
              />
            }
            label={option}
          />
        );
      })}
    </FormGroup>
  );
}

export default PpeSwitchForm;
