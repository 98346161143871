import React, { useState } from 'react';

import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogContentText,
  DialogActions,
  Button,
  Typography,
  FormControl,
  FormControlLabel,
  Checkbox,
  MenuItem,
  Select,
  Step,
  StepLabel,
  Stepper,
} from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles/index';

import { isEmpty } from 'lodash';

import InfoIcon from '../icons/InfoIcon';

import { colors } from '../../palette';

import { FEATURE_FLAG_2_STEPS_CLARIFICATION } from '../../constants/featureFlagConstants';
import { CUSTOMER_TYPES, INDUSTRIES } from '../../constants/customerConstants';

const useStyles = makeStyles(() => ({
  checkboxArea: {
    'span.MuiButtonBase-root': {
      padding: '0 !important',
    },
  },
  checkboxRoot: {
    padding: 2,
  },
  formControl: {
    display: 'inline',
    paddingBottom: '20px',
  },
  select: {
    minWidth: '220px',
    margin: '10px',
    width: 'fit-content',
  },
}));

const CLARIFICATION_STEPS = ['QC Report', 'Clarifications'];

function MultiplePartsSummaryClarificationPopup(props) {
  const classes = useStyles();

  const {
    open,
    handleClose,
    handleSubmit,
    partsNeedClarify,
    onSameFactoryCheckboxChange,
    hasIndustry,
    onIndustrySelect,
    hasCustomerType,
    onCustomerTypeSelect,
  } = props;

  const [activeStep, setActiveStep] = useState(0);
  const [industry, setIndustry] = useState('');
  const [customerType, setCustomerType] = useState('');

  const navigateStep = (step) => {
    let nextStep = activeStep + step;
    if (nextStep < 0) {
      nextStep = 0;
    } else if (nextStep > CLARIFICATION_STEPS.length - 1) {
      nextStep = CLARIFICATION_STEPS.length - 1;
    }
    setActiveStep(nextStep);
  };

  const renderCheckboxLabel = (selected) => {
    return (
      <Typography
        style={{
          color: selected ? colors.blue050 : 'black',
        }}
      >
        Use same factory for this order
      </Typography>
    );
  };

  const renderClarificationStep = () => {
    return (
      <div>
        {FEATURE_FLAG_2_STEPS_CLARIFICATION === 'true' && (
          <Typography
            variant='h6'
            style={{
              textAlign: 'center',
              marginBottom: '1rem',
            }}
          >
            Just a few more clarifications!
          </Typography>
        )}
        {!hasCustomerType && (
          <FormControl
            style={{ display: 'block' }}
            variant='filled'
            error={isEmpty(customerType)}
          >
            <Typography
              style={{
                display: 'inline',
              }}
            >
              I am a/an<span style={{ color: 'red' }}>*</span> :
            </Typography>
            <Select
              className={classes.select}
              value={customerType}
              onChange={(event) => {
                setCustomerType(event.target.value);
                onCustomerTypeSelect(event.target.value);
              }}
              label='Customer Type'
            >
              {Object.values(CUSTOMER_TYPES).map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        {!hasIndustry && (
          <FormControl
            style={{ display: 'block' }}
            variant='filled'
            error={isEmpty(industry)}
          >
            <Typography
              style={{
                display: 'inline',
              }}
            >
              Select your industry sector<span style={{ color: 'red' }}>*</span>{' '}
              :
            </Typography>
            <Select
              className={classes.select}
              value={industry}
              onChange={(event) => {
                setIndustry(event.target.value);
                onIndustrySelect(event.target.value);
              }}
              label='Industry'
            >
              {Object.values(INDUSTRIES).map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        {partsNeedClarify.map((part, index) => (
          <div
            key={part.itemID}
            style={{
              marginBottom: '1rem',
            }}
          >
            <DialogContentText key={index}>
              {index + 1}. {part.name}
            </DialogContentText>
            <div
              style={{
                marginLeft: '1rem',
              }}
            >
              {part.sameRepeatOwner && (
                <div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    &#8226;&nbsp;This is a repeat order. Please select the
                    checkbox if you require the same factory to make this order.
                  </div>
                  <div
                    className={classes.checkboxArea}
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      margin: 4,
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <FormControlLabel
                        style={{
                          marginLeft: '0.5rem',
                          marginRight: '0.5rem',
                        }}
                        control={
                          <Checkbox
                            classes={{
                              root: classes.checkboxRoot,
                            }}
                            checked={part.requestSameFabricator}
                            onChange={(event) =>
                              onSameFactoryCheckboxChange(
                                part.itemID,
                                event.target.checked
                              )
                            }
                          />
                        }
                        label={renderCheckboxLabel(part.requestSameFabricator)}
                      />
                      <InfoIcon toolTipText='If not selected, we will match your order to any qualified factories that can meet the requirements for this order.' />
                    </div>
                    {part.requestSameFabricator && (
                      <Typography
                        style={{
                          color: colors.blue050,
                          fontStyle: 'italic',
                          marginLeft: '2.1rem',
                        }}
                        variant='caption'
                      >
                        You will be informed in case the fabricator is not able
                        to take up the order at this stage.
                      </Typography>
                    )}
                  </div>
                </div>
              )}
              {part.needsPdf && (
                <Typography>
                  &#8226;&nbsp;2D files/technical drawings missing for this
                  part.
                </Typography>
              )}
            </div>
          </div>
        ))}
      </div>
    );
  };

  const renderDialogTitle = () => {
    if (FEATURE_FLAG_2_STEPS_CLARIFICATION === 'true') {
      return (
        <Stepper activeStep={activeStep}>
          {CLARIFICATION_STEPS.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      );
    }
    return 'Just a few more clarifications!';
  };

  const renderDialogActions = () => {
    if (FEATURE_FLAG_2_STEPS_CLARIFICATION === 'true') {
      return (
        <DialogActions>
          <Button variant='contained' onClick={handleClose}>
            Cancel
          </Button>
          {activeStep > 0 && (
            <Button
              style={{
                backgroundColor: colors.secondaryBlue,
              }}
              variant='contained'
              onClick={() => navigateStep(-1)}
            >
              Back
            </Button>
          )}
          {activeStep < CLARIFICATION_STEPS.length - 1 && (
            <Button
              style={{
                backgroundColor: colors.secondaryBlue,
              }}
              variant='contained'
              onClick={() => navigateStep(1)}
            >
              Next
            </Button>
          )}
          {activeStep === CLARIFICATION_STEPS.length - 1 && (
            <Button
              data-cy='proceed-to-create-project-btn'
              color='primary'
              variant='contained'
              onClick={handleSubmit}
              autoFocus
            >
              Proceed
            </Button>
          )}
        </DialogActions>
      );
    }
    return (
      <DialogActions>
        <Button variant='contained' onClick={handleClose}>
          Cancel
        </Button>
        <Button
          data-cy='proceed-to-create-project-btn'
          color='primary'
          variant='contained'
          onClick={handleSubmit}
          autoFocus
          disabled={
            (!hasIndustry && isEmpty(industry)) ||
            (!hasCustomerType && isEmpty(customerType))
          }
        >
          Proceed
        </Button>
      </DialogActions>
    );
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth='sm'>
      <DialogTitle>{renderDialogTitle()}</DialogTitle>
      <DialogContent>{renderClarificationStep()}</DialogContent>
      {renderDialogActions()}
    </Dialog>
  );
}

export default MultiplePartsSummaryClarificationPopup;
