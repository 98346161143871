import React, { useMemo } from 'react';

import { makeStyles } from '@material-ui/core/styles/index';

import { Chip } from '@material-ui/core';

import { PAYMENT_STATUS } from '../../constants/paymentConstants';

import { colors } from '../../palette';

// -----------------------------------------------------------------------------------------------

const useStyles = makeStyles({
  chip: {
    border: 'none',
    fontSize: '14px',
    padding: '4px 8px',
    '& .MuiChip-label': {
      padding: 0,
      lineHeight: 1,
      overflow: 'visible',
    },
  },
  pendingChip: {
    background: colors.neutral030,
    color: colors.neutral070,
  },
  partiallyPaidChip: {
    background: '#FFE791',
    color: '#645A00',
    '&:hover': {
      background: '#FFE791',
    },
  },
  successChip: {
    background: colors.green050,
    color: colors.neutral010,
  },
});

function ProjectOrderPaymentStatusInfo(props) {
  const classes = useStyles();

  const { text = 'Text', status } = props;

  const chipClass = useMemo(() => {
    if (status === PAYMENT_STATUS.PAID) {
      return classes.successChip;
    }

    if (status === PAYMENT_STATUS.PARTIALLY_PAID) {
      return classes.partiallyPaidChip;
    }

    return classes.pendingChip;
  }, [status]);

  return (
    <Chip
      label={text}
      variant='outlined'
      fullWidth
      className={`${chipClass} ${classes.chip}`}
    />
  );
}

export default ProjectOrderPaymentStatusInfo;
