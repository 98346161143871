// Import settings
import React, { useEffect } from 'react';

// Import customised components
import GreyButton from '../buttons/GreyButton';
import NewEditPartForm from '../NewEditPartForm';
import SubDesc from '../SubDesc';

// Import material UI components
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles/index';

// Style the components
const useStyles = makeStyles((theme) => ({
  paper: {
    minWidth: 800,
    borderRadius: 0,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

function EditPartPopup(props) {
  const classes = useStyles();
  const { item, dialog, handleClose, getItems } = props;

  useEffect(() => {}, [item]);

  return (
    <Dialog
      maxWidth='xl'
      open={dialog}
      onClose={handleClose}
      aria-labelledby='confirmation-dialog-title'
      classes={{ paper: classes.paper }}
    >
      {item ? (
        <div style={{ padding: '35px 0 50px' }}>
          <DialogTitle id='confirmation-dialog-title'>
            <SubDesc content={`Item #${item.itemID}`} />
          </DialogTitle>
          <DialogContent style={{ padding: '0 30px' }}>
            <NewEditPartForm
              savedPartDetails={item}
              closeDialog={() => handleClose()}
              getItems={getItems}
            />
          </DialogContent>
        </div>
      ) : (
        <div style={{ padding: '35px 0 50px' }}>
          <div>Please try again.</div>
          <GreyButton onBtnClick={handleClose} btnContent='Back' size='small' />
        </div>
      )}
      {dialog ? (
        <IconButton
          aria-label='close'
          className={classes.closeButton}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </Dialog>
  );
}

export default EditPartPopup;
