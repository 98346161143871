import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { makeStyles } from '@material-ui/core/styles/index';

import { CloudUpload as CloudUploadIcon, FileCopy } from '@material-ui/icons';

import { Box, Container, IconButton, Tooltip } from '@material-ui/core';

import { notifySuccess } from '../../services/notificationService';
import { uploadPublicFileToS3 } from '../../services/s3Service';

import { downloadS3File } from '../../utils/fileUtils';
import { copyToClipboard } from '../../utils/commonUtils';

import { S3_PUBLIC_FILES_DIR_NAME } from '../../constants';
import { colors } from '../../palette';

const useStyles = makeStyles(() => ({
  copyButton: {
    '&:hover': {
      backgroundColor: colors.blue050,
    },
  },
}));

function S3UploadFile() {
  const classes = useStyles();

  const [listFiles, setListFiles] = useState([]);

  function handleFilesChange(files) {
    let _listFiles = [];
    const toastId = toast('File(s) is uploading', {
      type: toast.TYPE.INFO,
      autoClose: false,
    });
    Promise.all(
      Array.from(files).map(async (file) => {
        if (file.type === 'application/pdf') {
          const splitFilename = file.name.split('.');
          const preFilename = splitFilename.slice(0, -1).join('.');
          const fileExtension = splitFilename[splitFilename.length - 1];
          const s3ObjectKey = `${S3_PUBLIC_FILES_DIR_NAME}/${preFilename}_${Date.now()}.${fileExtension}`;
          return uploadPublicFileToS3(file, s3ObjectKey)
            .then((data) => {
              _listFiles.push({
                fileName: file.name,
                url: data.Location.split(' ').join('%20'),
              });
            })
            .catch((err) => {
              alert(err);
            });
        }
      })
    ).then(() => {
      setListFiles((prev) => [...prev, ..._listFiles]);
      toast.update(toastId, {
        render: 'File(s) is uploaded successfully',
        type: toast.TYPE.SUCCESS,
        autoClose: 3000,
      });
    });
  }

  return (
    <Container>
      <div
        style={{
          paddingTop: '2rem',
        }}
      >
        <input
          id='files-upload'
          type='file'
          multiple
          accept='.pdf'
          onClick={(event) => (event.target.value = null)}
          onChange={(evt) => handleFilesChange(evt.target.files)}
          style={{
            display: 'none',
          }}
        />
        <label htmlFor='files-upload'>
          <div
            style={{
              outline: 'dashed grey 2px',
              height: '6.25rem',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              cursor: 'pointer',
            }}
          >
            <CloudUploadIcon style={{ margin: '5px' }} />
            Upload Pdf(s)
          </div>
        </label>
      </div>
      {listFiles.length > 0 && (
        <div>
          <span
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginTop: '1.5rem',
            }}
          >
            File URL(s):
          </span>
          <ul>
            {listFiles.map((item) => (
              <li
                key={item}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Link
                  onClick={(e) => {
                    e.stopPropagation();
                    downloadS3File(item.url, { fileNameParam: item.fileName });
                  }}
                >
                  {item.fileName}
                </Link>
                <Box style={{ width: '1rem' }} />
                <Tooltip title='Copy URL'>
                  <IconButton
                    className={classes.copyButton}
                    onClick={() => {
                      copyToClipboard(item.url);
                      notifySuccess('URL copied');
                    }}
                  >
                    <FileCopy style={{ fontSize: '15pt' }} />
                  </IconButton>
                </Tooltip>
              </li>
            ))}
          </ul>
        </div>
      )}
    </Container>
  );
}

export default S3UploadFile;
