import BestCapbilityIcon from '../assets/icons/best_capability.svg';
import BestQualityIcon from '../assets/icons/best_quality.svg';
import BestSpeedIcon from '../assets/icons/best_speed.svg';
import { SUPPLIER_RANK_TYPES } from '../constants/supplierConstants';

export const getSupplierIcon = (supplierType) => {
  switch (supplierType) {
    case SUPPLIER_RANK_TYPES.BEST_CAPABILITY:
      return [BestCapbilityIcon, supplierType];
    case SUPPLIER_RANK_TYPES.BEST_QUALITY:
      return [BestQualityIcon, supplierType];
    case SUPPLIER_RANK_TYPES.BEST_SPEED:
      return [BestSpeedIcon, supplierType];
    default:
      return [undefined, undefined];
  }
};
