import { useHistory } from 'react-router-dom';

const usePageNavigation = () => {
  const history = useHistory();

  const goToCustomerProject = (projectID) => {
    history.push(`/manage-projects/${projectID}`);
  };

  return {
    goToCustomerProject,
  };
};

export default usePageNavigation;
