import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles/index';

import {
  Dialog,
  DialogActions,
  DialogContent,
  FormGroup,
} from '@material-ui/core';

import ImageWith3DViewer from '../images/ImageWith3DViewer';
import FtrCheckboxRow from '../ftr-components/table/FtrCheckboxRow';
import FtrTypography from '../ftr-components/FtrTypography';
import { FlexColumn, FlexRow } from '../layouts/FlexLayouts';
import { FtrCheckboxHeader } from '../ftr-components/table/FtrCheckboxHeader';
import { FtrSmallButton } from '../ftr-components';
import { PopupItemInfo } from '../../pages/manage-project-items/MovePartsPopup';

import useDeviceCheck from '../../hooks/useDeviceCheck';

import { isEmptyValue } from '../../utils/commonUtils';

import { colors } from '../../palette';

// --------------------------------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  '@global': {
    body: {
      backgroundColor: colors.neutral020,
    },
  },
  paper: {
    borderRadius: '30px',
    [theme.breakpoints.down('sm')]: {
      borderRadius: '0',
    },
    maxWidth: 500,
  },
  container: {
    width: '100% !important',
    margin: '0 !important',
    position: 'relative',
    [theme.breakpoints.down('sm')]: {},
  },
  summaryItemName: {
    width: '100%',
    fontSize: '14px',
    display: 'block',
    fontWeight: 600,
    lineHeight: '19.07px',
    paddingLeft: '0.75rem',
    paddingBottom: '5px',
    color: '#565656',
  },
  summaryText: {
    paddingLeft: '0.75rem',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '19.07px',
    color: colors.neutral060,
    alignItems: 'left',
    justifyContent: 'left',
    wordBreak: 'break-word',
  },
  closeIcon: {
    position: 'absolute',
    right: '1.5rem',
    top: '1.5rem',
    color: colors.neutral060,
    width: '1.5rem',
    height: '1.5rem',
    cursor: 'pointer',
  },
}));

function OrderProjectAgainPopupV2(props) {
  const classes = useStyles();

  const history = useHistory();

  const { open, items = [], handleCancel, handleOk } = props;

  const [{ isMobile }] = useDeviceCheck();

  const [selectedItemIDs, setSelectedItemIDs] = useState([]);

  const allFieldsSelected = selectedItemIDs.length === items.length;

  useEffect(() => {
    if (isEmptyValue(items)) {
      return;
    }
    setSelectedItemIDs(items.map((item) => item.itemID));
  }, [items]);

  const handleItemClick = (itemID) => {
    const _selectedItemIDs = selectedItemIDs.includes(itemID)
      ? selectedItemIDs.filter((i) => i !== itemID)
      : [...selectedItemIDs, itemID];
    setSelectedItemIDs(_selectedItemIDs);
  };

  const handleAllFieldsCheck = () => {
    const newValue = !allFieldsSelected;
    if (newValue) {
      setSelectedItemIDs(items.map((item) => item.itemID));
    } else {
      setSelectedItemIDs([]);
    }
  };

  const render2DImage = (item) => {
    const imageUrl = item.imageUrl || item.imageFile || item.twoDImageUrl;

    return (
      <ImageWith3DViewer
        key={item.id}
        twoDImageUrl={imageUrl}
        width={70}
        height={70}
        noBorder
        isLoading={item.imageStatus === 'loading'}
        borderRadius={10}
      />
    );
  };

  const renderItemInfo = (item) => {
    return <PopupItemInfo item={item} />;
  };

  return (
    <Dialog
      open={open}
      onClose={handleCancel}
      classes={{ paper: classes.paper }}
      scroll='paper'
      width='xs'
      fullWidth
      fullScreen={isMobile}
    >
      <FtrTypography
        type='heading'
        fontSize='22'
        style={{ padding: '30px 0px 20px 40px' }}
      >
        Please select item(s) to re-order
      </FtrTypography>
      <DialogContent>
        <FlexColumn style={{ gap: 0 }}>
          <FtrCheckboxHeader
            checked={allFieldsSelected}
            onChange={handleAllFieldsCheck}
            title='Select Parts'
            disabled={items.length === 0}
          />
          <FormGroup>
            {items.map((item, index) => {
              const isLastItem = index === items.length - 1;
              const checked = selectedItemIDs.includes(item.itemID);
              const key = `${item.itemID}-${checked}`;

              return (
                <FtrCheckboxRow
                  key={key}
                  onChange={() => handleItemClick(item.itemID)}
                  checked={checked}
                  isLastItem={isLastItem}
                >
                  <FlexRow style={{ width: '100%', padding: '0.5rem 0' }}>
                    {render2DImage(item)}
                    {renderItemInfo(item)}
                  </FlexRow>
                </FtrCheckboxRow>
              );
            })}
          </FormGroup>
        </FlexColumn>
      </DialogContent>
      <DialogActions>
        <FtrSmallButton variant='text' onClick={handleCancel}>
          Cancel
        </FtrSmallButton>
        <FtrSmallButton
          color='blue'
          onClick={() => {
            if (isEmptyValue(selectedItemIDs)) {
              return;
            }
            handleOk(selectedItemIDs);
            history.push('/summary');
          }}
          disabled={isEmptyValue(selectedItemIDs)}
          style={{ margin: 16 }}
        >
          Proceed
        </FtrSmallButton>
      </DialogActions>
    </Dialog>
  );
}

export default OrderProjectAgainPopupV2;
