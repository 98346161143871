import { fetchWithErrorHandling, getAuthorizedHeader } from '../utils/apiUtils';

import { BACKEND_SERVICE_URL } from '../constants';

// -------------------------------------------------------------------------------------------------

export const getCustomerSettings = async (userID) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/user-settings/${userID}`;
  return fetchWithErrorHandling(requestUrl, {
    method: 'GET',
    headers: getAuthorizedHeader(),
  });
};

export const updateCustomerSettingsApi = async (userID, body) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/user-settings/${userID}`;
  return fetchWithErrorHandling(requestUrl, {
    method: 'PATCH',
    headers: getAuthorizedHeader(),
    body: JSON.stringify(body),
  });
};
