import React from 'react';
import { IconButton, Tooltip, makeStyles, Badge } from '@material-ui/core';

import { Repeat as RepeatIcon } from '@material-ui/icons';

import { ReactComponent as SimilarIcon } from '../../assets/icons/similar_order.svg';
import RocketIcon from '../../assets/icons/rocket.svg';
import PpeNoIcon from '../../assets/icons/ppe_no.svg';
import PpeYesIcon from '../../assets/icons/ppe_yes.svg';

import { colors } from '../../palette';
import FtrSvgImage from '../images/FtrSvgImage';

const useStyles = makeStyles(() => ({
  rocketIcon: {
    display: 'flex',
    height: 'inherit',
    width: 'inherit',
    filter: `invert(41%) sepia(90%) saturate(5329%) hue-rotate(203deg) brightness(96%) contrast(86%);`,
  },
  similarOrder: {
    fill: colors.repeatItemColor,
    height: 20,
  },
}));

export const NonPPEIcon = () => {
  return (
    <Tooltip title='Manual RFQ' arrow>
      <div>
        <FtrSvgImage src={PpeNoIcon} />
      </div>
    </Tooltip>
  );
};

export const PPEIcon = () => {
  return (
    <Tooltip title='Instant / Rocket Quoted' arrow>
      <div>
        <FtrSvgImage src={PpeYesIcon} />
      </div>
    </Tooltip>
  );
};

export const RepeatOrderIcon = (props) => {
  const { onClick = () => null, tooltipText = 'Repeat Order', ...rest } = props;
  return (
    <Tooltip title={tooltipText} arrow>
      <RepeatIcon
        onClick={onClick}
        style={{ color: colors.repeatItemColor, cursor: 'pointer' }}
        {...rest}
      />
    </Tooltip>
  );
};

export const RepeatOrderWithExclamationIcon = (props) => {
  const {
    onClick = () => null,
    tooltipText = 'Repeat Order but with different parameters',
    ...rest
  } = props;
  return (
    <Tooltip title={tooltipText} arrow>
      <Badge
        badgeContent='!'
        color={'error'}
        overlap='circle'
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <RepeatIcon
          onClick={onClick}
          style={{ color: colors.repeatItemColor }}
          {...rest}
        />
      </Badge>
    </Tooltip>
  );
};

export const SimilarOrderIcon = (props) => {
  const { onClick = () => null, ...rest } = props;
  const classes = useStyles();
  return (
    <Tooltip title='Similar Order' arrow>
      <SimilarIcon
        onClick={onClick}
        className={classes.similarOrder}
        {...rest}
        style={{ cursor: 'pointer' }}
      />
    </Tooltip>
  );
};

export const RocketQuoteIconButton = (props) => {
  const { onClick = () => null, ...rest } = props;
  const classes = useStyles();
  return (
    <Tooltip title='Rocket Quote' arrow>
      <IconButton color='primary' onClick={onClick} {...rest}>
        <FtrSvgImage src={RocketIcon} className={classes.rocketIcon} />
      </IconButton>
    </Tooltip>
  );
};
