export const CAD_RENDERER_TO_3D_TYPES = ['stp', 'step', 'stl'];

/**
 * Images of CAD files with STL, STEP, STP, IGES, IGS or DXF formats can be generated.
 * https://github.com/teamfactorem/3d-converter
 */
export const CAD_RENDERER_TO_2D_TYPES = [
  'stl',
  'step',
  'stp',
  'iges',
  'igs',
  'dxf',
];

export const NATIVE_3D_RENDERER_TYPES = ['stl', 'step', 'stp', 'iges', 'igs'];
