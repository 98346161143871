import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { capitalize, orderBy } from 'lodash';

import CustomerPODisplay from '../components/CustomerPODisplay';
import CustomerTableRowText from '../components/ftr-components/table/CustomerTableRowText';
import DateFormatMethod from '../components/DateFormatMethod';
import HorizontalCarouselV2 from '../components/images/HorizontalCarouselV2';
import ProjectItemStatusInfoV2 from '../components/info/ProjectItemStatusInfoV2';
import ProjectOrderPaymentStatusInfo from '../components/info/ProjectOrderPaymentStatusInfo';
import { FlexColumn, FlexRow } from '../components/layouts/FlexLayouts';
import {
  FtrBlueCheckbox,
  FtrButton,
  FtrS1,
  FtrS3,
} from '../components/ftr-components';

import { extractAndDecodeFileNameAndExtensionFromUrl } from '../utils/fileUtils';
import {
  getItemStageStatusText,
  getProjectItemStatusFilterOrder,
} from '../utils/itemUtils';
import { isEmptyValue } from '../utils/commonUtils';
import { isNumber } from '../utils/numberUtils';
import { uniqueObjectsByFields } from '../utils/arrayUtils';

import ManageProjectsOrdersTabContext from '../context/ManageProjectsOrdersTabContext';

import { ITEM_FILE_UPLOAD_TYPE } from './itemConstants';
import { PAYMENT_STATUS } from './paymentConstants';

import { colors } from '../palette';

// ----------------------------------------------------------------------------------------------

const selectCheckboxColumn = ({ addRow, removeRow }) => ({
  title: '',
  field: 'selected',
  render: (rowData) => {
    return (
      <FtrBlueCheckbox
        style={{ paddingTop: 0 }}
        onChange={(e) => {
          const checked = e.target.checked;
          if (checked) {
            addRow(rowData);
          } else {
            removeRow(rowData);
          }
        }}
        onClick={(e) => {
          e.stopPropagation();
        }}
      />
    );
  },
  width: 50,
});

const projectIDColumn = {
  title: 'Project ID',
  field: 'projectID',
  valueGetter: ({ row: rowData }) => {
    const { projectID, items = [] } = rowData;
    const itemIDs = items.map((item) => item.itemID).join(',');
    const text = `${projectID},${itemIDs}`;
    return text;
  },
  render: (rowData) => {
    return rowData.projectID;
  },
  width: 100,
};

const mctIDColumn = {
  title: 'ID',
  field: 'referenceName',
  render: (rowData) => {
    return rowData.referenceName;
  },
  width: 100,
};

const imageColumn = {
  title: 'Name',
  field: 'name',
  render: (rowData) => {
    return (
      <FlexColumn>
        <CustomerTableRowText>{rowData.name}</CustomerTableRowText>
        <HorizontalCarouselV2 urls={rowData.twoDImageUrls} />
      </FlexColumn>
    );
  },
};

const projectMctImageColumn = {
  title: 'Name',
  field: 'name',
  render: (rowData) => {
    return (
      <FlexColumn>
        <HorizontalCarouselV2 urls={rowData.twoDImageUrls} />
      </FlexColumn>
    );
  },
};

const projectStatusColumn = {
  title: 'Status',
  field: 'status',
  valueGetter: (rowData) => {
    const statusMapping = rowData.items?.reduce((acc, item) => {
      const statusText = getItemStageStatusText(item);
      const statusCount = acc[statusText] || 0;
      acc[statusText] = statusCount + 1;
      return acc;
    }, {});

    if (isEmptyValue(statusMapping)) {
      return '';
    }

    return Object.keys(statusMapping).join(',');
  },
  render: (rowData) => {
    const statusMapping = rowData.items?.reduce((acc, item) => {
      const statusText = getItemStageStatusText(item);
      const statusCount = acc[statusText] || 0;
      acc[statusText] = statusCount + 1;
      return acc;
    }, {});

    if (isEmptyValue(statusMapping)) {
      return;
    }

    return (
      <FlexColumn style={{ alignItems: 'start' }}>
        {orderBy(Object.entries(statusMapping), [
          (statusMappingObj) => {
            return getProjectItemStatusFilterOrder(statusMappingObj[0]);
          },
        ]).map(([key, value]) => {
          return (
            <ProjectItemStatusInfoV2 key={key} text={key} numOfItems={value} />
          );
        })}
      </FlexColumn>
    );
  },
  width: 230,
};

const dateCreatedColumn = {
  title: 'Date Created',
  field: 'dateCreated',
  valueGetter: (rowData) => {
    return DateFormatMethod({ date: rowData.dateCreated, monthType: 'short' });
  },
  render: (rowData) => {
    return DateFormatMethod({ date: rowData.dateCreated, monthType: 'short' });
  },
  width: 160,
  minWidth: 120,
};

const mctOrderDateColumn = {
  title: 'Order Date',
  field: 'orderDate',
  valueGetter: (rowData) => {
    return DateFormatMethod({ date: rowData.createdDate, monthType: 'short' });
  },
  render: (rowData) => {
    return DateFormatMethod({ date: rowData.createdDate, monthType: 'short' });
  },
  width: 160,
  minWidth: 120,
};

const mctDeliveryDateColumn = {
  title: 'Delivery Date',
  field: 'latestDeliveryDate',
  valueGetter: (rowData) => {
    return DateFormatMethod({
      date: rowData.latestDeliveryDate,
      monthType: 'short',
    });
  },
  render: (rowData) => {
    return DateFormatMethod({
      date: rowData.latestDeliveryDate,
      monthType: 'short',
    });
  },
  width: 160,
  minWidth: 120,
};

const partsColumn = {
  title: 'Parts',
  field: 'parts',
  render: (rowData) => {
    const partsLength = isEmptyValue(rowData.items)
      ? 0
      : rowData?.items?.length;
    if (partsLength <= 0) {
      return;
    }
    const qty = rowData.items?.reduce((acc, item) => {
      return acc + Number(item.itemQuantity);
    }, 0);
    const partsText = `${partsLength} ${partsLength > 1 ? 'parts' : 'part'}`;
    return isNumber(qty) ? `${partsText}, ${qty} qty` : partsText;
  },
  width: 220,
  minWidth: 180,
};

const orderPartsColumn = {
  title: 'Parts',
  field: 'parts',
  valueGetter: ({ row: rowData }) => {
    const text =
      rowData.items
        ?.map((item) => [item.itemID, item.name])
        .flat()
        .join(',') || '';
    return text;
  },
  render: (rowData) => {
    const {
      updateOrderProjectAgainPopupHOCState,
      allItemsMapping,
    } = useContext(ManageProjectsOrdersTabContext);

    const partsLength = isEmptyValue(rowData.items)
      ? 0
      : rowData?.items?.length;
    if (partsLength <= 0) {
      return;
    }
    const qty = rowData.items?.reduce((acc, item) => {
      return acc + Number(item.itemQuantity);
    }, 0);
    const partsText = `${partsLength} ${partsLength > 1 ? 'parts' : 'part'}`;
    const partsInfo = isNumber(qty) ? `${partsText}, ${qty} qty` : partsText;

    return (
      <FlexColumn>
        {partsInfo}
        <div>
          <FtrButton
            variant='text'
            style={{ paddingLeft: 4, paddingRight: 4 }}
            onClick={(e) => {
              e.stopPropagation();
              const orderAgainItemList = rowData.items.map(
                (item) => allItemsMapping[item.itemID]
              );
              updateOrderProjectAgainPopupHOCState({
                open: true,
                orderAgainItemList: orderAgainItemList,
              });
            }}
          >
            Order Again
          </FtrButton>
        </div>
      </FlexColumn>
    );
  },
  width: 220,
  minWidth: 180,
};

const paymentStatusColumn = {
  title: 'Payment',
  field: 'paymentStatus',
  valueGetter: ({ row: rowData }) => {
    const status = capitalize(rowData.paymentStatus) || 'Pending';
    const customerPOs = rowData.customerPOs || [];
    const poFiles = customerPOs.join(',');
    return `${status},(${poFiles})`;
  },
  render: (rowData) => {
    const { customerPOs = [], items } = rowData;

    let paymentStatus;
    if (items.every((i) => i.paymentStatus === PAYMENT_STATUS.PAID)) {
      paymentStatus = PAYMENT_STATUS.PAID;
    } else if (items.some((i) => i.paymentStatus === PAYMENT_STATUS.PAID)) {
      paymentStatus = PAYMENT_STATUS.PARTIALLY_PAID;
    }

    return (
      <FlexColumn>
        <div>
          <ProjectOrderPaymentStatusInfo
            text={capitalize(paymentStatus) || 'Pending'}
            status={paymentStatus}
          />
        </div>
        {customerPOs.map((f) => {
          const [
            fileName,
            fileExtension,
          ] = extractAndDecodeFileNameAndExtensionFromUrl(f);

          return (
            <CustomerPODisplay
              key={f.fileName}
              showActions={false}
              style={{ paddingTop: 4, paddingBottom: 4, borderRadius: 12 }}
            >
              <FtrS1 style={{ color: colors.green050 }}>
                PO: {fileName}.
                <span style={{ fontWeight: 400 }}>{fileExtension}</span>
              </FtrS1>
            </CustomerPODisplay>
          );
        })}
      </FlexColumn>
    );
  },
  width: 150,
  minWidth: 120,
};

const projectMctActionColumn = {
  title: '',
  field: 'action',
  render: (rowData) => {
    const history = useHistory();

    return (
      <FlexRow>
        <FtrButton
          variant='text'
          style={{ paddingTop: 0 }}
          onClick={() => {
            history.push({
              pathname: `/customer-orders/projects/${rowData.projectID}`,
              state: { projectID: rowData.projectID },
            });
          }}
        >
          View order
        </FtrButton>
      </FlexRow>
    );
  },
  width: 150,
  minWidth: 120,
};

export const getQuotationTabColumns = () => {
  const columns = [
    projectIDColumn,
    imageColumn,
    partsColumn,
    dateCreatedColumn,
    projectStatusColumn,
  ];

  return columns;
};

export const getOrdersTabColumns = ({ addRow, removeRow }) => {
  const columns = [
    selectCheckboxColumn({
      addRow,
      removeRow,
    }),
    mctIDColumn,
    imageColumn,
    orderPartsColumn,
    mctOrderDateColumn,
    paymentStatusColumn,
    {
      ...projectStatusColumn,
      title: 'Project Status',
    },
  ];

  return columns;
};

export const getOrdersCompletedTabColumns = ({ addRow, removeRow }) => {
  const columns = [
    selectCheckboxColumn({
      addRow,
      removeRow,
    }),
    mctIDColumn,
    imageColumn,
    orderPartsColumn,
    mctDeliveryDateColumn,
    paymentStatusColumn,
    {
      ...projectStatusColumn,
      title: 'Project Status',
    },
  ];

  return columns;
};

export const getProjectMctColumns = () => {
  return [
    {
      ...mctIDColumn,
      title: 'Order No.',
      render: (rowData) => {
        const { items } = rowData;
        const mctItemIDs = rowData.acceptedItems.map((ai) => ai.itemID);
        const mctItems = items.filter((i) => mctItemIDs.includes(i.itemID));
        const customerUploadedFiles =
          mctItems.flatMap((i) => i.customerUploadFiles || []) || [];

        let customerPOFiles = customerUploadedFiles.filter(
          (f) => f.type === ITEM_FILE_UPLOAD_TYPE.CUSTOMER_PO
        );

        customerPOFiles = uniqueObjectsByFields(customerPOFiles, [
          'fileName',
          'url',
        ]);
        return (
          <FlexColumn>
            <FtrS3>{rowData.referenceName}</FtrS3>
            {customerPOFiles.map((f) => {
              const [
                fileName,
                fileExtension,
              ] = extractAndDecodeFileNameAndExtensionFromUrl(f.fileName);

              return (
                <CustomerPODisplay key={f.fileName} showActions={false}>
                  <FtrS1
                    style={{ color: colors.green050, width: 'fit-content' }}
                  >
                    PO: {fileName}.
                    <span style={{ fontWeight: 400 }}>{fileExtension}</span>
                  </FtrS1>
                </CustomerPODisplay>
              );
            })}
          </FlexColumn>
        );
      },
      width: 150,
    },
    projectMctImageColumn,
    mctOrderDateColumn,
    projectMctActionColumn,
  ];
};
