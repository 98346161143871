import React from 'react';
import { get } from 'lodash';

import { Box } from '@material-ui/core';

import FtrInfoIconTooltip from '../../ftr-components/FtrInfoIconTooltip';
import { FlexColumn, FlexRow } from '../../layouts/FlexLayouts';
import { FtrBoldText, FtrNormalText } from '../../ftr-components';

import {
  displayBoundingBoxValues,
  showUnitValueFromMetric,
} from '../../../utils/userUtils';
import {
  getMaterialWithColorText,
  getSurfaceFinishWithCustomizationsText,
  is3DPTechnology,
} from '../../../utils/itemUtils';

import { THREE_D_P_FDM_TECH } from '../../../constants/NewPartConstants';

import { colors } from '../../../palette';

// ------------------------------------------------------------------------------

function PartDetailsV2InfoPresentational(props) {
  const { partInfo = {} } = props;

  const { name, technology, tolerance, description, unitType } = partInfo;

  const { boundingBoxX, boundingBoxY, boundingBoxZ } =
    partInfo?.ppeInformation || {};

  const render3DPInfo = () => {
    if (!is3DPTechnology(technology)) {
      return null;
    }

    const threeDTech = get(partInfo, ['metadata', 'threeDTechnology']);
    const threeDInfill = get(partInfo, ['metadata', 'threeDInfill']);
    const threeDLayerThickness = get(partInfo, [
      'metadata',
      'threeDLayerThickness',
    ]);

    return (
      <>
        <FtrBoldText style={{ color: colors.neutral060 }}>
          {threeDTech}
        </FtrBoldText>
        {threeDTech === THREE_D_P_FDM_TECH && (
          <FtrBoldText style={{ color: colors.neutral060 }}>
            3D Infill {Number(threeDInfill * 100).toFixed(0)}%
          </FtrBoldText>
        )}
        {threeDTech === THREE_D_P_FDM_TECH && (
          <FtrBoldText style={{ color: colors.neutral060 }}>
            3D Layer Thickness {threeDLayerThickness}mm
          </FtrBoldText>
        )}
      </>
    );
  };

  const renderQuoteDetailsInfo = () => {
    return (
      <FlexColumn style={{ flex: '1 1 auto' }}>
        <FlexColumn style={{ color: colors.headerGrey }}>
          <FtrBoldText fontSize='16'>{name}</FtrBoldText>
          <FtrNormalText style={{ color: colors.headerGrey }}>
            {displayBoundingBoxValues(
              boundingBoxX,
              boundingBoxY,
              boundingBoxZ,
              unitType
            )}
          </FtrNormalText>
          <FtrNormalText style={{ color: colors.headerGrey }}>
            Qty: {partInfo.quantity || 'NIL'}
          </FtrNormalText>
        </FlexColumn>
        <Box style={{ height: '1rem' }} />
        <FlexColumn style={{ color: colors.neutral060, gap: '0.2rem' }}>
          <FtrBoldText style={{ color: colors.neutral060 }}>
            {technology}
          </FtrBoldText>
          {render3DPInfo()}
          <FtrBoldText style={{ color: colors.neutral060 }}>
            {getMaterialWithColorText(partInfo)}
          </FtrBoldText>
          <FtrBoldText style={{ color: colors.neutral060 }}>
            {getSurfaceFinishWithCustomizationsText(partInfo)}
          </FtrBoldText>
          <FlexRow>
            <FtrBoldText style={{ color: colors.neutral060 }}>
              {`Tolerance ±${showUnitValueFromMetric(tolerance, unitType)}`}
            </FtrBoldText>
            <FtrInfoIconTooltip
              toolTipText={`This is the tightest tolerance indicated in your design`}
            />
          </FlexRow>
          {description && (
            <FtrBoldText style={{ color: colors.neutral060 }}>
              Remarks: {description}
            </FtrBoldText>
          )}
        </FlexColumn>
      </FlexColumn>
    );
  };

  return <>{renderQuoteDetailsInfo()}</>;
}

export default PartDetailsV2InfoPresentational;
