import { useQuery } from 'react-query';

import { getUserCreditType } from '../apis/userApi';

import { isEmptyValue } from '../utils/commonUtils';

import { CUSTOMER_CREDIT_TYPES } from '../constants/customerConstants';

// -------------------------------------------------------------------------------------------------

const useUserCreditType = (customerID) => {
  const { data, isLoading, isFetching } = useQuery(
    ['getUserCreditType', customerID],
    () => {
      if (isEmptyValue(customerID)) {
        return CUSTOMER_CREDIT_TYPES.NON_CREDIT;
      }

      return getUserCreditType(customerID);
    }
  );

  return {
    data,
    isLoading,
    isFetching,
  };
};

export default useUserCreditType;
