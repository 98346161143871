import { BACKEND_SERVICE_URL } from '../constants';
import { getAuthorizedHeader } from '../utils/apiUtils';
import { fetchWithErrorHandling } from '../utils/apiUtils';

export const getAdminDashboardItems = async () => {
  const requestUrl = `${BACKEND_SERVICE_URL}/dashboard/admin`;

  return fetchWithErrorHandling(requestUrl, {
    method: 'GET',
    headers: getAuthorizedHeader(),
  });
};
