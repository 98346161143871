import React from 'react';
import { Slide, Dialog, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import VerifyItem from '../../pages/VerifyItem';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='left' ref={ref} {...props} />;
});

function VerifyPartPopup(props) {
  const { open, handleClose, itemID, handleRefetch } = props;

  return (
    <Dialog
      fullScreen
      open={open}
      aria-labelledby='form-dialog-title'
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <div>
        <IconButton
          onClick={handleClose}
          style={{ position: 'absolute', right: 0 }}
        >
          <CloseIcon />
        </IconButton>
        <VerifyItem
          isDialog={true}
          setOpen={open}
          itemID={itemID}
          handleRefetch={handleRefetch}
          setClose={handleClose}
        />
      </div>
    </Dialog>
  );
}

export default VerifyPartPopup;
