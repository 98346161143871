import { Grid, TextField } from '@material-ui/core';
import React from 'react';

import { isEmptyValue } from '../../utils/commonUtils';

function FinanceNoteInput(props) {
  const { financeNote, setFinanceNote, isRequired } = props;

  return (
    <Grid container style={{ marginTop: '1rem' }}>
      <Grid item sm={6}>
        <TextField
          id='financeNote'
          size='small'
          name='financeNote'
          variant='outlined'
          multiline
          value={financeNote}
          label='Note for Finance'
          onChange={(e) => setFinanceNote(e.target.value)}
          fullWidth
          required={isRequired}
          error={isRequired && isEmptyValue(financeNote?.trim())}
        />
      </Grid>
    </Grid>
  );
}

export default FinanceNoteInput;
