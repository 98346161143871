export const clarity = (c, l, a, r, i, t, y) => {
  c[a] =
    c[a] ||
    function() {
      (c[a].q = c[a].q || []).push(arguments);
    };
  t = l.createElement(r);
  t.async = 1;
  t.src = 'https://www.clarity.ms/tag/' + i;
  y = l.getElementsByTagName(r)[0];
  y.parentNode.insertBefore(t, y);
};

export const pbrain = (d, s, id, elPBrain) => {
  if (elPBrain) elPBrain.style.cssText = 'display: inline';

  var js,
    fjs = d.getElementsByTagName(s)[0];
  if (d.getElementById(id)) {
    return;
  }
  js = d.createElement(s);
  js.id = id;
  js.src = 'https://www.pbrain.biz/js/sdk.js';
  fjs.parentNode.insertBefore(js, fjs);
};

export const crisp = (elCrisp) => {
  if (elCrisp) elCrisp.style.cssText = 'display: block !important';

  var d = document;
  var s = d.createElement('script');

  s.src = 'https://client.crisp.chat/l.js';
  s.async = 1;
  d.getElementsByTagName('head')[0].appendChild(s);
};
