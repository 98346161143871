import React from 'react';

import { FlexColumnCenter } from '../../components/layouts/FlexLayouts';
import FtrSvgImage from '../../components/images/FtrSvgImage';
import { FtrB3 } from '../../components/ftr-components';

import RobotVectorIcon from '../../assets/icons/robot_vector.svg';

import { colors } from '../../palette';

// -----------------------------------------------------------------------------------------------

function EmptyQuote(props) {
  const {
    text = 'Get started by uploading your parts to receive a quotation.',
  } = props;

  return (
    <FlexColumnCenter style={{ padding: '3rem 0' }}>
      <FtrSvgImage src={RobotVectorIcon} width={80} height={80} />
      <FtrB3 style={{ color: colors.neutral060 }}>{text}</FtrB3>
    </FlexColumnCenter>
  );
}

export default EmptyQuote;
