import React from 'react';

import { FlexColumn, FlexRow } from './layouts/FlexLayouts';
import { FtrBoldText } from './ftr-components';
import ToggleColumn from './ToggleColumn';
import CopyToClipboardIcon from './CopyToClipboardIcon';

import { isEmptyValue } from '../utils/commonUtils';

import useDeliveryMessageToggles from '../hooks/useDeliveryMessageToggles';
import useDeliveryMessageConfig from '../hooks/useDeliveryMessageConfig';
import useUserInfo from '../hooks/useUserInfoHook';

import { notifyWarning } from '../services/notificationService';

import { COUNTRY_NAMES } from '../constants/countryConstants';

const DeliveryMessageDisplay = ({
  trackingInfo,
  pickUpUserID,
  dropOffUserID,
  selectedItemsIDs,
  isShippingToCustomer,
  invoiceAmountStr = '',
}) => {
  const nonFactoremUserID = isShippingToCustomer ? dropOffUserID : pickUpUserID;

  const {
    data: nonFactoremUser,
    isFetching: isFetchingNonFactoremUserInfo,
  } = useUserInfo(nonFactoremUserID);

  const isOverseas =
    nonFactoremUser?.country &&
    nonFactoremUser.country !== COUNTRY_NAMES.SINGAPORE;

  const [
    deliveryMessageToggles,
    handleDeliveryMessageToggles,
  ] = useDeliveryMessageToggles(isOverseas);

  const { deliveryMessage, errorMessage } = useDeliveryMessageConfig({
    selectedItemsIDs,
    trackingInfo,
    pickUpUserID,
    dropOffUserID,
    deliveryMessageToggles,
    isShippingToCustomer,
    invoiceAmountStr,
  });

  if (isFetchingNonFactoremUserInfo) {
    return null;
  }

  return (
    <FlexRow>
      <div
        style={{
          border: '1px solid grey',
          padding: '12px',
        }}
      >
        <FlexColumn>
          <FtrBoldText>Generate Delivery Message</FtrBoldText>
          <ToggleColumn
            toggles={deliveryMessageToggles}
            onToggleChange={handleDeliveryMessageToggles}
          />
          <CopyToClipboardIcon
            message={deliveryMessage}
            isDisabled={isEmptyValue(selectedItemsIDs)}
            disabledTooltipText={'Please select some items first.'}
            copyableTooltipTitle={'Copy delivery message to clipboard'}
            afterCopy={() => {
              if (errorMessage) {
                notifyWarning(errorMessage);
              }
            }}
          />
        </FlexColumn>
      </div>
    </FlexRow>
  );
};

export default DeliveryMessageDisplay;
