import { CURRENCY } from '../constants';
import { UNIT_TYPES } from '../constants/unitConstants';
import { USER_FEE_TYPE } from '../constants/userConstant';

// -------------------------------------------------------------------------------------------------

export const getUserSelector = (state) => state.auth?.user;
export const getUserCountrySelector = (state) => state.auth?.user?.country;
export const getUserIDSelector = (state) => state.auth?.user?.userID;
export const getUserRoleSelector = (state) => state.auth?.user?.role;
export const getGuestUserInfoSelector = (state) => state.auth?.guestUserInfo;
export const getUserCurrencySelector = (state) =>
  state.auth?.location?.currency || CURRENCY.SGD;
export const getUserFeeTypeSelector = (state) =>
  state.auth?.user?.feeType || USER_FEE_TYPE.HAS_FEE;
export const getUserUnitTypeSelector = (state) =>
  state.auth?.user?.unitType || UNIT_TYPES.METRIC;
export const getUserCreditsSelector = (state) => state.auth?.user?.credits || 0;
export const getUserStatusSelector = (state) =>
  state.auth?.user?.customerStatus;
