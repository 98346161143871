import {
  backAction,
  completeTourAction,
  continueAction,
  skipTourAction,
} from './tourConstants';

export const getRequestMoreQuotesFeatureTourSteps = (params = {}) => {
  // eslint-disable-next-line no-unused-vars
  const isMobile = params.isMobile;
  return [
    {
      attachTo: { element: '#request-more-quotes-btn', on: 'bottom-start' },
      buttons: [
        {
          ...skipTourAction,
        },
        {
          ...continueAction,
          text: 'Show me',
        },
      ],
      title: 'Get quotes for varying quantity',
      text: [
        'Click here to configure and requests quotes of varying quantity.',
      ],
    },
    {
      attachTo: { element: '#request-more-quantity-field', on: 'bottom-start' },
      buttons: [
        backAction,
        {
          ...completeTourAction,
          action() {
            if (this.isActive()) {
              this.complete(); // Complete the current tour
              document.querySelector('#request-more-quotes-close-btn')?.click();
            }
          },
        },
      ],
      showOn: async function() {
        const element = document.querySelector(this.attachTo.element);
        return Boolean(element);
      },
      beforeShowPromise: async () => {
        document.querySelector('#request-more-quotes-btn')?.click();
      },
      title: 'Get quotes for varying quantity',
      text: [
        'Simply key in a different quantity to get a new quote created with bulk pricing.',
      ],
    },
  ];
};
