import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import CustomerProjectsMuiTable from './CustomerProjectsMuiTable';
import { FtrB3 } from '../../components/ftr-components';
import { FlexColumnCenter } from '../../components/layouts/FlexLayouts';

import withProjectsTableLoadMoreHOC from '../../hocs/withProjectsTableLoadMoreHOC';

import { getQuotationTabColumns } from '../../constants/customerProjectsMuiTableConstants';

import { colors } from '../../palette';

// -------------------------------------------------------------------------------------------------

const QuotationTable = withProjectsTableLoadMoreHOC(CustomerProjectsMuiTable);

function ManageProjectsQuotationTabPage(props) {
  const {
    loading,
    searchStr = '',
    projects = [],
    statusFilterList = [],
    createdDateFilter = null, // eg: 'Last 7 days', 'Last 30 days'
  } = props;

  const history = useHistory();

  const columns = useMemo(() => getQuotationTabColumns(), []);

  const handleRowClick = (rowData) => {
    history.push({
      pathname: `/manage-projects/${rowData.projectID}`,
      state: { projectID: rowData.projectID },
    });
  };

  const renderLoading = () => {
    return (
      <FlexColumnCenter>
        <FtrB3 style={{ color: colors.neutral060 }}>Loading...</FtrB3>
      </FlexColumnCenter>
    );
  };

  return (
    <>
      {loading && renderLoading()}
      {!loading && (
        <QuotationTable
          searchStr={searchStr}
          statusFilterList={statusFilterList}
          createdDateFilter={createdDateFilter}
          projects={projects}
          onRowClick={handleRowClick}
          columns={columns}
        />
      )}
    </>
  );
}

export default ManageProjectsQuotationTabPage;
