import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import auth from './auth';
import customerSettings from './customerSettings';
import editProfile from './editProfile';
import item from './item';
import users from './users';
import items from './items';
// import orders from './orders';
import order from './order';
import myorders from './myorders';
import invoice from './invoice';
import pdf from './pdf';
import createUser from './createUser';
import project from './project';
import twoDImages from './twoDImages';
import projectBulkQuotationsForm from './projectBulkQuotationsForm';
import partUploadForm from './partUploadFormReducer';
import multiCheckoutForm from './multiCheckoutForm';
import poAcknowledge from './poAcknowledge';
import cadRendererAINC from './cadRendererAINC';
import orderReady from './orderReady';
import supplierControlSettings from './supplierControlSettings';
import pageSettings from './pageSettings';
import tour from './tour';
import partsLibrary from './partsLibrary';
import costEstimationInfo from './estimateCostReducer';
import viewAsSupplier from './viewAsSupplier';

const authConfig = {
  key: 'auth',
  storage,
  blacklist: ['rates'],
};

export default combineReducers({
  // auth,
  auth: persistReducer(authConfig, auth),
  customerSettings,
  editProfile,
  item,
  users,
  items,
  // orders,
  order,
  myorders,
  invoice,
  pdf,
  createUser,
  project,
  twoDImages,
  projectBulkQuotationsForm,
  multiCheckoutForm,
  poAcknowledge,
  cadRendererAINC,
  orderReady,
  supplierControlSettings,
  pageSettings,
  tour,
  viewAsSupplier,
  partsLibrary,
  costEstimationInfo,
  partUploadForm,
});
