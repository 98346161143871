/* eslint-disable react/prop-types */
import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import clsx from 'clsx';

import { colors } from '../../palette';

import {
  Backdrop,
  Box,
  CircularProgress,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  InputBase,
  InputLabel,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import {
  CancelOutlined as CancelOutlinedIcon,
  InsertDriveFileOutlined as InsertDriveFileOutlinedIcon,
} from '@material-ui/icons';

import { DatePicker } from '@material-ui/pickers';

import {
  STANDARD_TOLERANCE_OPTIONS,
  THREE_D_P_FDM_TECH,
  threeDPrintingInfillDefault,
  threeDPrintingLayerThicknessDefault,
  TOLERANCE_OPTIONS,
  THREE_D_TOLERANCE_OPTIONS,
} from '../../constants/NewPartConstants';

import BlueDragDropSmall from '../../components/BlueDragDropSmall';

import FileIcon from '../../assets/img/file.png';
import BlueButton from '../buttons/BlueButton';
import { getFileNameFromCadFile, is3DPTechnology } from '../../utils/itemUtils';
import {
  CADPART_DIRNAME,
  CURRENCY,
  IMG_PLACE_HOLDER_URL,
  TWO_D_IMAGE_URLS_KEY,
} from '../../constants';
import { ppe3dpInfillOptions } from '../../constants/PPEConstants';
import WhiteButton from '../buttons/WhiteButton';
import TechnologyInputField from '../inputs/TechnologyInputField';
import { useItemInputConfig } from '../../hooks/useItemInputConfig';
import MaterialCategoriesInputField from '../inputs/MaterialCategoriesInputField';
import ThreeDPrintingTechnologyInputField from '../inputs/ThreeDPrintingTechnologyInputField';
import ThreeDPrintingMaterialField from '../inputs/ThreeDPrintingMaterialField';
import {
  isCustomMaterial,
  isCustomSurfaceFinish,
  isCustomTechnology,
} from '../../utils/inputUtils';
import SurfaceFinishingField from '../inputs/SurfaceFinishingField';
import ColorInputField from '../inputs/ColorInputField';
import ThreeDInfillSelectField from '../inputs/ThreeDInfillSelectField';
import ThreeDLayerThicknessField from '../inputs/ThreeDLayerThicknessField';
import { getPpePriceForItem } from '../../utils/ppeUtils';
import { notifyError } from '../../services/notificationService';
import InfoIcon from '../icons/InfoIcon';
import { getCurrencySymbol } from '../../utils/currencyUtils';
import ApplyToAllDialog from '../dialogs/ApplyToAllDialog';
import { uploadFileToS3 } from '../../services/s3Service';
import {
  downloadS3File,
  getUrlFileBySupportedFileTypes,
} from '../../utils/fileUtils';
import { convert2DImage } from '../../services/convert2DImageService';
import ToleranceDropdown from '../dropdowns/ToleranceDropdown';
import { convertFromMetric, convertToMetric } from '../../utils/userUtils';
import { isEmptyValue } from '../../utils/commonUtils';

// Style component
const useStyles = makeStyles((theme) => ({
  body: {
    height: '100%',
  },
  breadcrumbsWrapper: {
    padding: '14px 45px 0',
    fontSize: '11pt',
  },
  breadcrumbsLink: {
    textDecoration: 'none',
    color: colors.solidBlue,
  },
  colorButton: {
    fontSize: 15,
    padding: '0px 8px',
    border: `1px solid ${colors.inputBorderBlue}`,
    boxSizing: 'border-box',
    borderRadius: '5px',
    color: `${colors.fontGrey}`,
    textTransform: 'none',
    justifyContent: 'left',
    height: '32px',
  },
  formComponent: {},
  inputField: {
    fontSize: 15,
    padding: '0px 8px',
    background: colors.fontWhite,
    border: `1px solid ${colors.inputBorderBlue}`,
    boxSizing: 'border-box',
    borderRadius: '5px',
    color: `${colors.fontGrey}`,
    width: '46%',
  },
  inputFieldRight: {
    fontSize: 15,
    padding: '0px 8px',
    background: colors.fontWhite,
    border: `1px solid ${colors.inputBorderBlue}`,
    boxSizing: 'border-box',
    borderRadius: '5px',
    color: `${colors.fontGrey}`,
    width: '30%',
    marginLeft: 10,
  },
  disabledInputField: {
    border: `1px solid ${colors.lightGray}`,
  },
  inputLabel: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 12,
    color: colors.fontLightGrey,
    fontWeight: '600',
    textTransform: 'uppercase',
    // width: '10%',
    width: 150,
    minWidth: 150,
  },
  rightSectionLabel: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 12,
    color: colors.fontLightGrey,
    fontWeight: '600',
    textTransform: 'uppercase',
  },
  rightSectionField: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 12,
    color: colors.fontDarkGrey,
    fontWeight: '600',
    textTransform: 'uppercase',
    marginTop: 7,
  },
  sectionTitle: {
    textTransform: 'uppercase',
    letterSpacing: '0.05em',
    fontSize: 12,
    color: colors.fontDarkGrey,
    fontWeight: '600',
  },
  sectionSubtitle: {
    fontSize: 13,
    color: colors.fontLightGrey,
    marginTop: 7,
  },
  cncErrorMessage: {
    fontSize: 13,
    color: colors.errorRed,
    marginTop: 5,
    fontWeight: '100',
  },
  gridBorder: {
    padding: 10,
    borderBottom: 0,
    display: 'flex',
    flexDirection: 'column',
  },
  rightGridBorder: {
    borderLeft: `1px solid ${colors.bgLightGrey}`,
    borderTop: `1px solid ${colors.bgLightGrey}`,
    padding: 10,
    display: 'flex',
    flexDirection: 'column',
  },
  formHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 8,
  },
  sectionForm: {
    margin: '10px 0',
  },
  sectionFormField: {
    display: 'flex',
    margin: '8px 0',
  },
  rightSectionFormField: {
    display: 'flex',
    flexDirection: 'column',
    margin: '15px 0',
  },
  commentInputField: {
    fontSize: 15,
    padding: 8,
    background: colors.fontWhite,
    border: `1px solid ${colors.inputBorderBlue}`,
    boxSizing: 'border-box',
    borderRadius: '5px',
    color: `${colors.fontGrey}`,
    width: '100%',
  },
  sectionFiles: {
    margin: '15px 0 0',
  },
  noFilesSection: {
    height: 40,
    background: colors.bgLightGrey,
    borderRadius: '5px',
    display: 'flex',
    alignItems: 'center',
    padding: '0 10px',
  },
  fileUploadWording: {
    fontSize: 14,
    color: colors.fontLightGrey,
    marginLeft: 10,
  },
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  fileItem: {
    backgroundColor: colors.uploadFileBg,
    margin: '5px 0',
    height: 35,
    border: `solid 1px ${colors.uploadFileBorder}`,
    borderRadius: '5px',
    color: 'inherit',
    cursor: 'pointer',
  },
  itemText: {
    '& span, & svg': {
      fontSize: 14,
    },
  },
  breadcrumbLink: {
    color: colors.tertiaryBlue,
    fontSize: '11pt',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  breadcrumbLinkBold: {
    fontSize: '11pt',
  },
  submitButtonWrapper: {
    margin: '20px 0px',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1000,
    color: colors.fontWhite,
  },
  backdropContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

function EditPartForm(props) {
  const classes = useStyles();

  const {
    item,
    itemState,
    submitEditedPart,
    submitUpdatedItemState,
    onCancel,
    currency,
    exchangeRate,
  } = props;

  const [openApplyToAllDialog, setOpenApplyToAllDialog] = useState(false);

  const [otherMaterial, setOtherMaterial] = useState(item.otherMaterial || '');
  const [otherSurfaceFinish, setOtherSurfaceFinish] = useState(
    item.otherSurfaceFinish || ''
  );
  const [itemName, setItemName] = useState(item.name || '');
  const [technology, setTechnology] = useState(item.technology || '');
  const [otherTechnology, setOtherTechnology] = useState(
    item.otherTechnology || ''
  );
  const [material, setMaterial] = useState(item.material || '');
  const [threeDTechnology, setThreeDTechnology] = useState(
    item.threeDTechnology || ''
  );
  const [threeDInfill, setThreeDInfill] = useState(item.threeDInfill || '');
  const [threeDLayerThickness, setThreeDLayerThickness] = useState(
    item.threeDLayerThickness || ''
  );
  const [surfaceFinish, setSurfaceFinish] = useState(item.surfaceFinish);
  const [itemTolerance, setItemTolerance] = useState(
    convertFromMetric(item.tolerance, item.unitType) || ''
  );
  const [itemRemarks, setItemRemarks] = useState(item.remarks || '');
  const [itemQty, setItemQty] = useState(item.qty || 1);
  const [partApplication, setPartApplication] = useState(
    item.partApplication || ''
  );
  const [itemFiles, setItemFiles] = useState(item.cadPart || []);
  const [color, setColor] = useState(item.color || '');
  const [materialColor, setMaterialColor] = useState(item.materialColor || '');
  const [unitType, setUnitType] = useState(item.unitType);

  const [
    {
      technologyOptions,
      materialCategoryOptions,
      threeDTechnologyOptions,
      threeDMaterialOptions,
      surfaceFinishOptions,
      materialColorOptions,
      surfaceFinishColorOptions,
      defaultThreeDMaterial,
      selectColorSurfaces,
    },
    {
      loadSelectColorSurfaces,
      loadTechnologyOptions,
      loadMaterialCategoryOptions,
      load3DTechnologyOptions,
      loadThreeDMaterialOptions,
      loadSurfaceFinishOptions,
      loadMaterialColorOptions,
      loadSurfaceFinishColorOptions,
      technologyHasChanged,
      materialHasChanged,
      threeDTechnologyHasChanged,
      surfaceFinishHasChanged,
      setThreeDMaterialOptions,
    },
  ] = useItemInputConfig({
    setTechnology,
    setMaterial,
    setThreeDTechnology,
    setSurfaceFinish,
    setMaterialColor,
    setColor,
  });

  const surfaceFinishIsPainting = surfaceFinish === 'Painting';
  const surfaceFinishIsCoating = surfaceFinish === 'Powder Coating';

  const [loading, setLoading] = useState(false);
  const [callPPE, setCallPPE] = useState(false);
  const [cadFileChanged, setCadFileChanged] = useState(false);
  const [targetPrice, setTargetPrice] = useState('');
  const [expectedLeadTime, setExpectedLeadTime] = useState(
    item.expectedLeadTime
  );

  /**
   * If technology is 3D Printing and surface finish is painting
   * or powder coating, color input label will take on the label
   * of the surface finish
   */
  let colorInputLabel = surfaceFinishIsPainting
    ? 'Paint Color'
    : surfaceFinishIsCoating
    ? 'Powder Coat Color'
    : 'Color';

  useEffect(() => {
    loadTechnologyOptions(false);
    loadSelectColorSurfaces({ technology });
    loadMaterialCategoryOptions({ technology }, false);
    if (is3DPTechnology(technology)) {
      load3DTechnologyOptions(false);
      loadThreeDMaterialOptions(
        {
          technology,
          threeDTechnology,
        },
        false
      ).catch(() => {
        setThreeDMaterialOptions(['Custom Material']);
      });
    }
    const params = {
      technology,
      threeDTechnology,
      material,
    };
    loadSurfaceFinishOptions(params, false);
    if (item.materialColor) {
      loadMaterialColorOptions(params, false);
    }
    if (item.color) {
      loadSurfaceFinishColorOptions(
        {
          technology,
          surfaceFinish,
        },
        false
      );
    }
  }, []);

  useEffect(() => {
    const originalTargetPrice = item.targetPrice
      ? currency !== CURRENCY.SGD
        ? Number(item.targetPrice) * exchangeRate
        : Number(item.targetPrice)
      : '';
    setTargetPrice(
      originalTargetPrice ? Number(originalTargetPrice).toFixed(2) : ''
    );
  }, [item, currency]);

  const handleSubmitColor = (selectedColor) => {
    setColor(selectedColor || color);
  };

  const handleOpenApplyToAllDialog = () => {
    setOpenApplyToAllDialog(true);
  };

  const handleCloseApplyToAllDialog = () => {
    setOpenApplyToAllDialog(false);
  };

  const handleConfirmApplyToAllDialog = async (
    fieldsToUpdate,
    partsToUpdate
  ) => {
    setLoading(true);
    const partIDsToUpdate = partsToUpdate.map((part) => part.id);
    let newItemState = await Promise.all(
      itemState.map((item) => {
        if (!partIDsToUpdate.includes(item.id)) {
          return item;
        }

        let updatedPart = {
          ...item,
          ...fieldsToUpdate,
        };
        const callPPE = ['qty', 'technology', 'tolerance'].some(
          (key) => key in fieldsToUpdate
        );
        return callPPE ? getPpePriceForItem(updatedPart) : updatedPart;
      })
    );
    setLoading(false);
    submitUpdatedItemState(newItemState);
    setOpenApplyToAllDialog(false);
  };

  const handleSaveEdit = async () => {
    if (isEmpty(itemFiles)) {
      notifyError('Missing CAD Part File.');
      return;
    }
    if (isCustomTechnology(technology) && isEmpty(otherTechnology)) {
      notifyError('Missing Custom Technology Field.');
      return;
    }
    if (isCustomMaterial(material) && isEmpty(otherMaterial)) {
      notifyError('Missing Custom Material Field.');
      return;
    }
    if (isCustomSurfaceFinish(surfaceFinish) && isEmpty(otherSurfaceFinish)) {
      notifyError('Missing Custom Surface Finish Field.');
      return;
    }
    if (!isEmpty(surfaceFinishColorOptions) && isEmptyValue(color)) {
      notifyError('Missing Color field.');
      return;
    }
    if (technology === 'Sheet Metal Fabrication' && isEmpty(partApplication)) {
      notifyError('Missing Part Application Field.');
      return;
    }
    // Quantity is only for positive numbers
    if (!itemQty || Number(itemQty) < 1) {
      notifyError('Invalid quantity.');
      return;
    }
    const sgdTargetPrice = targetPrice
      ? currency !== CURRENCY.SGD
        ? targetPrice / exchangeRate
        : targetPrice
      : '';
    let updatedPart = {
      ...item,
      id: item.id,
      status: item.status,
      qty: itemQty,
      technology,
      otherTechnology,
      surfaceFinish,
      otherSurfaceFinish,
      material,
      materialColor,
      otherMaterial,
      cadPart: itemFiles,
      userID: item.userID,
      tolerance: convertToMetric(itemTolerance, unitType),
      remarks: itemRemarks,
      partApplication: partApplication,
      name: itemName,
      deleted: item.deleted,
      deliveryPreference: 'on_premise',
      color,
      checked: item.checked,
      targetPrice: sgdTargetPrice,
      expectedLeadTime,
    };
    if (is3DPTechnology(technology)) {
      updatedPart = {
        ...updatedPart,
        threeDTechnology,
        threeDInfill,
        threeDLayerThickness,
      };
    }

    // get first cadFile or PDF File
    const cadOrPdfUrl = await getUrlFileBySupportedFileTypes(itemFiles);

    // update image with first cadFile
    let imageFile = !isEmptyValue(updatedPart[TWO_D_IMAGE_URLS_KEY])
      ? updatedPart[TWO_D_IMAGE_URLS_KEY][0]
      : null;
    if (cadOrPdfUrl && cadFileChanged) {
      setLoading(true);
      const respConvert2DImage = await convert2DImage({ file_url: cadOrPdfUrl })
        .then((result) => {
          if (!isEmpty(result.error)) {
            imageFile = null;
            updatedPart.imageConvertingError = result.error.message;
            return {
              s3_file_url: IMG_PLACE_HOLDER_URL,
            };
          }
          imageFile = result['s3_file_url'];
          return result;
        })
        .catch(() => ({
          s3_file_url: IMG_PLACE_HOLDER_URL,
        }));
      const twoDImageUrl = respConvert2DImage['s3_file_url'];
      updatedPart[TWO_D_IMAGE_URLS_KEY] = [twoDImageUrl];
    }

    // call ppe with first cadFile
    if (callPPE) {
      setLoading(true);
      updatedPart = await getPpePriceForItem({
        ...updatedPart,
        [TWO_D_IMAGE_URLS_KEY]: imageFile ? [imageFile] : null,
      });
    }

    submitEditedPart(updatedPart);
    setLoading(false);
    setCallPPE(false);
    setCadFileChanged(false);
  };

  function handleCadFilesChange(files) {
    let stringOfLinks = [...itemFiles];
    Promise.all(
      Array.from(files).map((file) => {
        const splitFilename = file.name.split('.');
        const preFilename = splitFilename.slice(0, -1).join('.');
        const fileExtension = splitFilename[splitFilename.length - 1];
        const s3ObjectKey = `${CADPART_DIRNAME}/${preFilename}_${file.lastModified}.${fileExtension}`;
        return uploadFileToS3(file, s3ObjectKey)
          .then((data) => {
            stringOfLinks.push(data.Location.split(' ').join('%20'));
          })
          .catch((err) => {
            alert(err);
          });
      })
    ).then(() => {
      setItemFiles(stringOfLinks);
      setCallPPE(true);
      setCadFileChanged(true);
    });
  }

  const renderTargetPriceField = () => {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <InputLabel
          className={classes.inputLabel}
          style={{
            width: 'auto',
          }}
        >
          Target Unit Price
        </InputLabel>
        <Input
          className={classes.inputField}
          style={{
            width: '100%',
          }}
          disableUnderline={true}
          inputProps={{
            type: 'number',
          }}
          value={targetPrice}
          onChange={(evt) => setTargetPrice(evt.target.value)}
          startAdornment={
            <InputAdornment position='start'>
              {getCurrencySymbol(currency)}
            </InputAdornment>
          }
        />
      </div>
    );
  };

  const render3DInfillSelect = () => {
    return (
      <div className={classes.sectionFormField}>
        <ThreeDInfillSelectField
          visible={
            is3DPTechnology(technology) &&
            threeDTechnology === THREE_D_P_FDM_TECH
          }
          value={threeDInfill}
          onSelect={(newValue) => setThreeDInfill(newValue)}
          ppe3dpInfillOptions={ppe3dpInfillOptions}
          rowDisplay
        />
      </div>
    );
  };

  const render3DLayerThicknessSelect = () => {
    return (
      <div className={classes.sectionFormField}>
        <ThreeDLayerThicknessField
          visible={
            is3DPTechnology(technology) &&
            threeDTechnology === THREE_D_P_FDM_TECH
          }
          value={threeDLayerThickness}
          onSelect={(newValue) => setThreeDLayerThickness(newValue)}
          rowDisplay
        />
      </div>
    );
  };

  const renderTargetPriceAndExpectedLeadTime = () => {
    return (
      <Grid
        container
        spacing={2}
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Grid item xs={3}>
          {renderTargetPriceField()}
        </Grid>
        <Grid item xs={4}>
          <Grid
            container
            spacing={1}
            id='edit-part-expected-lead-time'
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Grid item xs={4}>
              <InputLabel
                className={classes.inputLabel}
                style={{
                  width: 'auto',
                }}
              >
                <span
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  Lead Time&nbsp;
                  <InfoIcon toolTipText='When do you need these parts by?' />
                </span>
              </InputLabel>
            </Grid>
            <Grid item xs={8}>
              <DatePicker
                value={expectedLeadTime}
                onChange={setExpectedLeadTime}
                animateYearScrolling
                inputVariant='outlined'
                margin='dense'
                fullWidth
                clearable
                clearLabel='No Preference'
                emptyLabel='No Preference'
                disablePast
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const renderSurfaceFinishColor = () => {
    return (
      <ColorInputField
        visible={!isEmpty(surfaceFinishColorOptions)}
        value={color}
        colorInputLabel={colorInputLabel}
        colorPalette={surfaceFinishColorOptions}
        onSubmit={(selectedColor) => handleSubmitColor(selectedColor)}
        rowDisplay
      />
    );
  };

  const renderMaterialColorInputField = () => {
    return (
      <ColorInputField
        visible={!isEmpty(materialColorOptions)}
        value={materialColor}
        colorInputLabel='Material Color'
        colorPalette={materialColorOptions}
        onSubmit={(selectedColor) =>
          setMaterialColor(selectedColor || materialColor)
        }
        rowDisplay
      />
    );
  };

  const content = (
    <Fragment>
      <Grid container spacing={0}>
        <Grid container item direction='column' spacing={0}>
          <Grid item style={{ borderTop: `1px solid ${colors.bgLightGrey}` }}>
            <div className={classes.submitButtonWrapper}>
              <BlueButton
                size='small'
                onBtnClick={handleSaveEdit}
                btnContent='Save Edit'
              />
              <Box style={{ width: '0.5rem' }}></Box>
              {itemState.length > 1 && (
                <BlueButton
                  size='small'
                  onBtnClick={handleOpenApplyToAllDialog}
                  btnContent='Apply to Other Parts'
                />
              )}
              <Box style={{ width: '0.5rem' }}></Box>
              <WhiteButton
                onBtnClick={onCancel}
                btnContent='Cancel'
                size='small'
                type='cancelEdit'
              />
            </div>
            <div className={classes.gridBorder}>
              <div className={classes.sectionTitle}>
                Upload technical drawings
              </div>
              <div className={classes.sectionSubtitle}>
                Upload technical drawings indicating critical requirements and
                specify standard. Any dimensions not indicated will be kept to
                our{' '}
                <a
                  href='https://factorem.medium.com/factorems-iso-2768-machining-guidelines-74a44cc75e5b'
                  style={{ color: colors.fontDarkGrey }}
                >
                  ISO 2768 Medium standard
                </a>
                . You may also upload any additional files relevant to this part
                here.
              </div>
              {technology === 'CNC Machining' &&
              !itemFiles.some((fileName) =>
                fileName.toLowerCase().includes('.pdf')
              ) ? (
                <div className={classes.cncErrorMessage}>
                  Please upload complementing 2D files/technical drawings to
                  your parts here. This will help us ensure that we don’t miss
                  out on any component details.
                </div>
              ) : (
                <div />
              )}
              <div className={classes.sectionForm}>
                <BlueDragDropSmall handleFiles={handleCadFilesChange} />
                <div className={classes.sectionFiles}>
                  {itemFiles.length === 0 && (
                    <div className={classes.noFilesSection}>
                      <InsertDriveFileOutlinedIcon
                        style={{ color: colors.fontLightGrey, fontSize: 20 }}
                      />
                      <div className={classes.fileUploadWording}>
                        No files uploaded
                      </div>
                    </div>
                  )}
                  <List>
                    {itemFiles.map((link, index) => {
                      const fileName = getFileNameFromCadFile(link);
                      return (
                        <ListItem
                          key={index}
                          className={classes.fileItem}
                          component='a'
                          onClick={(e) => {
                            e.stopPropagation();
                            downloadS3File(link);
                          }}
                        >
                          <ListItemAvatar style={{ display: 'flex' }}>
                            <img
                              src={FileIcon}
                              alt='icon'
                              style={{ height: 15 }}
                            />
                          </ListItemAvatar>
                          <ListItemText
                            style={{
                              width: 130,
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                              fontSize: 12,
                            }}
                            primary={fileName}
                            className={classes.itemText}
                          />
                          <ListItemSecondaryAction>
                            <IconButton
                              edge='end'
                              aria-label='delete'
                              onClick={() => {
                                const cadPartFiles = [...itemFiles];
                                cadPartFiles.splice(index, 1);
                                setCallPPE(true);
                                setItemFiles(cadPartFiles);
                                setCadFileChanged(true);
                              }}
                            >
                              <CancelOutlinedIcon
                                color='error'
                                style={{ fontSize: '15pt' }}
                              />
                            </IconButton>
                          </ListItemSecondaryAction>
                        </ListItem>
                      );
                    })}
                  </List>
                </div>
              </div>
            </div>
          </Grid>
          <Grid item>
            <div className={classes.gridBorder}>
              <div className={classes.sectionTitle}>Order specifications</div>
              <div className={classes.sectionForm}>
                <div className={classes.sectionFormField}>
                  <InputLabel className={classes.inputLabel}>
                    Order Name
                  </InputLabel>
                  <InputBase
                    id='name'
                    name='name'
                    className={classes.inputField}
                    value={itemName}
                    onChange={(evt) => {
                      setItemName(evt.target.value);
                    }}
                  />
                </div>
                <div className={classes.sectionFormField}>
                  <InputLabel
                    className={classes.inputLabel}
                    required={technology === 'Sheet Metal Fabrication'}
                  >
                    Part Application
                  </InputLabel>
                  <InputBase
                    id='partApplication'
                    name='partApplication'
                    className={classes.inputField}
                    value={partApplication}
                    onChange={(evt) => setPartApplication(evt.target.value)}
                  />
                </div>
                <div className={classes.sectionFormField}>
                  <InputLabel className={classes.inputLabel}>
                    Quantity
                  </InputLabel>
                  <Input
                    className={classes.inputField}
                    disableUnderline={true}
                    onWheel={(e) => e.target.blur()}
                    inputProps={{
                      step: 1,
                      min: 0,
                      max: 100000,
                      type: 'number',
                    }}
                    value={itemQty}
                    onChange={(evt) => {
                      setItemQty(evt.target.valueAsNumber);
                      setCallPPE(true);
                    }}
                  />
                </div>
                {renderTargetPriceAndExpectedLeadTime()}
              </div>
            </div>
          </Grid>
          <Grid item>
            <div className={classes.gridBorder}>
              <div className={classes.sectionTitle}>Part specifications</div>
              <div className={classes.sectionForm}>
                <div className={classes.sectionFormField}>
                  <TechnologyInputField
                    value={technology}
                    onChange={(newTech) => {
                      setTechnology(newTech);
                      technologyHasChanged(newTech);
                      setCallPPE(true);
                      setItemTolerance(
                        TOLERANCE_OPTIONS[item][newTech] ||
                          STANDARD_TOLERANCE_OPTIONS[item][3]
                      );
                      if (is3DPTechnology(newTech)) {
                        setThreeDInfill(threeDPrintingInfillDefault);
                        setThreeDLayerThickness(
                          threeDPrintingLayerThicknessDefault
                        );
                        setItemTolerance(STANDARD_TOLERANCE_OPTIONS[item][1]);
                      }
                      setOtherTechnology(null);
                    }}
                    technologyOptions={technologyOptions}
                    rowDisplay
                  />
                  {isCustomTechnology(technology) && (
                    <InputBase
                      disabled={!isCustomTechnology(technology)}
                      id='otherTechnology'
                      name='otherTechnology'
                      placeholder='Custom technology'
                      type='text'
                      className={clsx(
                        classes.inputFieldRight,
                        !isCustomTechnology(technology)
                          ? classes.disabledInputField
                          : null
                      )}
                      onChange={(evt) => {
                        setOtherTechnology(evt.target.value);
                      }}
                      value={otherTechnology}
                    />
                  )}
                </div>
                <ThreeDPrintingTechnologyInputField
                  visible={is3DPTechnology(technology)}
                  value={threeDTechnology}
                  onChange={(newValue) => {
                    setThreeDTechnology(newValue);
                    const params = {
                      technology,
                      threeDTechnology: newValue,
                    };
                    threeDTechnologyHasChanged(params);
                    setItemTolerance(
                      THREE_D_TOLERANCE_OPTIONS[unitType][newValue]
                    );
                    if (newValue === THREE_D_P_FDM_TECH) {
                      setThreeDInfill(threeDPrintingInfillDefault);
                      setThreeDLayerThickness(
                        threeDPrintingLayerThicknessDefault
                      );
                    } else {
                      setThreeDInfill('');
                      setThreeDLayerThickness('');
                    }
                    setCallPPE(true);
                  }}
                  threeDTechnologyOptions={threeDTechnologyOptions}
                  rowDisplay
                  shortLabel
                />
                <div className={classes.sectionFormField}>
                  <MaterialCategoriesInputField
                    technology={technology}
                    visible={!is3DPTechnology(technology)}
                    value={material}
                    onSelect={(value) => {
                      setCallPPE(true);
                      setMaterial(value);
                      const params = {
                        technology,
                        threeDTechnology,
                        material: value,
                      };
                      materialHasChanged(params);
                      setOtherMaterial(null);
                    }}
                    materialCategoryOptions={materialCategoryOptions}
                    rowDisplay
                  />
                  <ThreeDPrintingMaterialField
                    technology={technology}
                    threeDTechnology={threeDTechnology}
                    visible={is3DPTechnology(technology)}
                    value={material}
                    onSelect={(_material) => {
                      setMaterial(_material);
                      const params = {
                        technology,
                        threeDTechnology,
                        material: _material,
                      };
                      materialHasChanged(params);
                      setOtherMaterial(null);
                      setCallPPE(true);
                    }}
                    threeDMaterialOptions={threeDMaterialOptions}
                    defaultThreeDMaterial={defaultThreeDMaterial}
                    rowDisplay
                  />
                  {isCustomMaterial(material) && (
                    <InputBase
                      disabled={!isCustomMaterial(material)}
                      id='otherMaterial'
                      name='otherMaterial'
                      placeholder='Other material'
                      type='text'
                      className={clsx(
                        classes.inputFieldRight,
                        !isCustomMaterial(material)
                          ? classes.disabledInputField
                          : null
                      )}
                      onChange={(evt) => {
                        setOtherMaterial(evt.target.value);
                      }}
                      value={otherMaterial}
                    />
                  )}
                </div>
                {!isEmpty(materialColorOptions) && (
                  <div className={classes.sectionFormField}>
                    {renderMaterialColorInputField()}
                  </div>
                )}
                {is3DPTechnology(technology) &&
                  threeDTechnology === THREE_D_P_FDM_TECH &&
                  render3DInfillSelect()}
                {is3DPTechnology(technology) &&
                  threeDTechnology === THREE_D_P_FDM_TECH &&
                  render3DLayerThicknessSelect()}
                {(!isEmpty(surfaceFinishOptions) ||
                  isCustomSurfaceFinish(surfaceFinish)) && (
                  <div className={classes.sectionFormField}>
                    <SurfaceFinishingField
                      visible={
                        !isEmpty(surfaceFinishOptions) ||
                        isCustomSurfaceFinish(surfaceFinish)
                      }
                      value={surfaceFinish}
                      onSelect={(newValue) => {
                        setSurfaceFinish(newValue);
                        const params = {
                          technology,
                          material,
                          surfaceFinish: newValue,
                        };
                        surfaceFinishHasChanged(params);
                        setOtherSurfaceFinish(null);
                        setCallPPE(true);
                      }}
                      surfaceFinishOptions={surfaceFinishOptions}
                      selectColorSurfaces={selectColorSurfaces}
                      rowDisplay
                    />
                    {isCustomSurfaceFinish(surfaceFinish) && (
                      <InputBase
                        disabled={!isCustomSurfaceFinish(surfaceFinish)}
                        id='otherSurfaceFinish'
                        name='otherSurfaceFinish'
                        placeholder='Other surface finish'
                        type='text'
                        className={clsx(
                          classes.inputFieldRight,
                          !isCustomSurfaceFinish(surfaceFinish)
                            ? classes.disabledInputField
                            : null
                        )}
                        onChange={(evt) => {
                          setOtherSurfaceFinish(evt.target.value);
                        }}
                        value={otherSurfaceFinish}
                      />
                    )}
                  </div>
                )}
                {!isEmpty(surfaceFinishColorOptions) && (
                  <div className={classes.sectionFormField}>
                    {renderSurfaceFinishColor()}
                  </div>
                )}
                <div
                  className={classes.sectionFormField}
                  id='edit-part-tolerance'
                >
                  <InputLabel className={classes.inputLabel}>
                    Tolerance&nbsp;
                    <InfoIcon toolTipText='Please indicate tightest tolerance in your design' />
                  </InputLabel>
                  <ToleranceDropdown
                    onChange={(evt) => {
                      setItemTolerance(evt.target.value);
                      setCallPPE(true);
                    }}
                    value={itemTolerance}
                    unitType={unitType}
                    setUnitType={(value) => {
                      item.unitType = value;
                      setUnitType(value);
                      setItemTolerance(
                        convertFromMetric(item.tolerance, item.unitType)
                      );
                    }}
                  />
                </div>
              </div>
            </div>
          </Grid>
          <Grid item>
            <div
              className={classes.gridBorder}
              style={{ borderBottom: `1px solid ${colors.bgLightGrey}` }}
              id='edit-part-remarks'
            >
              <div className={classes.sectionTitle}>Comments</div>
              <div className={classes.sectionFormField}>
                <InputBase
                  multiline
                  rows='6'
                  id='remarks'
                  name='remarks'
                  type='text'
                  placeholder='Type additional comments here'
                  value={itemRemarks}
                  onChange={(evt) => {
                    setItemRemarks(evt.target.value);
                  }}
                  className={classes.commentInputField}
                />
              </div>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Fragment>
  );

  return (
    <Fragment>
      <div className={classes.formComponent}>
        {content}
        <div className={classes.submitButtonWrapper}>
          <BlueButton
            id='edit-part-save-edit'
            size='small'
            onBtnClick={handleSaveEdit}
            btnContent='Save Edit'
          />
          <Box style={{ width: '0.5rem' }}></Box>
          {itemState.length > 1 && (
            <BlueButton
              size='small'
              onBtnClick={handleOpenApplyToAllDialog}
              btnContent='Apply to Other Parts'
            />
          )}
          <Box style={{ width: '0.5rem' }}></Box>
          <WhiteButton
            onBtnClick={onCancel}
            btnContent='Cancel'
            size='small'
            type='cancelEdit'
          />
        </div>
      </div>
      {openApplyToAllDialog && (
        <ApplyToAllDialog
          open={openApplyToAllDialog}
          item={item}
          parts={itemState}
          changes={{
            ...(itemQty && { qty: itemQty }),
            tolerance: itemTolerance,
            ...(itemRemarks && { remarks: itemRemarks }),
            expectedLeadTime: expectedLeadTime,
            ...(partApplication && { partApplication: partApplication }),
          }}
          groupedChanges={{
            technology: technology,
            ...(isCustomTechnology(technology) && {
              otherTechnology: otherTechnology,
            }),
            ...(is3DPTechnology(technology) && {
              threeDTechnology: threeDTechnology,
            }),
            material: material,
            ...(isCustomMaterial(material) && { otherMaterial: otherMaterial }),
            ...(materialColor && { materialColor: materialColor }),
            ...(is3DPTechnology(technology) &&
              threeDTechnology === THREE_D_P_FDM_TECH && {
                threeDInfill: threeDInfill,
              }),
            ...(is3DPTechnology(technology) &&
              threeDTechnology === THREE_D_P_FDM_TECH && {
                threeDLayerThickness: threeDLayerThickness,
              }),
            surfaceFinish: surfaceFinish,
            ...(isCustomSurfaceFinish(surfaceFinish) && {
              otherSurfaceFinish: otherSurfaceFinish,
            }),
            ...(color && { color: color }),
          }}
          handleClose={handleCloseApplyToAllDialog}
          handleConfirm={handleConfirmApplyToAllDialog}
        />
      )}
      <Backdrop className={classes.backdrop} open={loading}>
        <div className={classes.backdropContent}>
          <CircularProgress color='inherit' />
          <Typography
            style={{
              textAlign: 'center',
            }}
          >
            Please wait for a couple of seconds while we generate the best price
            for you. Do not refresh this page or close the browser.
          </Typography>
        </div>
      </Backdrop>
    </Fragment>
  );
}

function mapStateToProps(state) {
  return {
    currency: state.auth.location.currency,
    exchangeRate: state.auth.rates[state.auth.location.currency],
  };
}

const withConnect = connect(mapStateToProps, null);

export default withConnect(EditPartForm);
