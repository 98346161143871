import React from 'react';

import { isEmpty } from 'lodash';

import { COUNTRY_LIST } from '../../constants/countryConstants';
import UnknownCountryFlagIcon from '../../assets/img/unknown-country-flag.png';

export function renderFlag(country) {
  if (isEmpty(country) || !COUNTRY_LIST[country]) {
    return (
      <img src={UnknownCountryFlagIcon} alt='icon' style={{ height: '18px' }} />
    );
  }
  const code = COUNTRY_LIST[country].code;
  return (
    <img
      loading='lazy'
      width='20'
      src={`https://flagcdn.com/w20/${code.toLowerCase()}.png`}
      srcSet={`https://flagcdn.com/w40/${code.toLowerCase()}.png 2x`}
      alt={'icon'}
    />
  );
}
