import { fetchWithErrorHandling, getAuthorizedHeader } from '../utils/apiUtils';

import { BACKEND_SERVICE_URL } from '../constants';

// -------------------------------------------------------------------------------------------------

export const getAllFactoremPromotionCodesApi = async () => {
  const requestUrl = `${BACKEND_SERVICE_URL}/factorem-promocodes`;
  return fetchWithErrorHandling(requestUrl, {
    method: 'GET',
    headers: getAuthorizedHeader(),
  });
};

export const getFactoremPromotionCodeByIDApi = async (id) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/factorem-promocodes/${id}`;
  return fetchWithErrorHandling(requestUrl, {
    method: 'GET',
    headers: getAuthorizedHeader(),
  });
};

export const getAvailablePromotionCodeForUserApi = async (customerID) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/factorem-promocodes/users/${customerID}/available`;
  return fetchWithErrorHandling(requestUrl, {
    method: 'GET',
    headers: getAuthorizedHeader(),
  });
};

export const createFactoremPromotionCodeApi = async (body) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/factorem-promocodes`;
  return fetchWithErrorHandling(requestUrl, {
    method: 'POST',
    headers: getAuthorizedHeader(),
    body: JSON.stringify(body),
  });
};

export const updateFactoremPromotionCodeApi = async (body) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/factorem-promocodes`;
  return fetchWithErrorHandling(requestUrl, {
    method: 'PATCH',
    headers: getAuthorizedHeader(),
    body: JSON.stringify(body),
  });
};

export const deleteFactoremPromotionCode = async (id) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/factorem-promocodes/${id}`;
  return fetchWithErrorHandling(requestUrl, {
    method: 'DELETE',
    headers: getAuthorizedHeader(),
  });
};
