import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles/index';

import { CheckCircle as CheckCircleIcon, RateReview } from '@material-ui/icons';
import { Tooltip } from '@material-ui/core';
import { colors } from '../../palette';

const useStyles = makeStyles(() => ({
  uploadButton: {
    display: 'flex',
    alignItems: 'center',
    width: 'fit-content',
    color: 'black',
    padding: '0.4rem',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: '#0000000a',
    },
    borderRadius: 4,
    textTransform: 'uppercase',
    fontSize: '0.6rem',
    lineHeight: '0.8rem',
    whiteSpace: 'nowrap',
  },
  checkIcon: {
    color: colors.uploadPOBorder,
  },
  reviewIcon: {
    color: colors.warningYellow,
  },
  disabled: {
    color: '#00000042',
    '&:hover': {
      backgroundColor: 'initial',
      cursor: 'default',
    },
    userSelect: 'none',
  },
  uploaded: {
    backgroundColor: colors.uploadPOBg,
    '&:hover': {
      backgroundColor: colors.uploadPOBg,
    },
    userSelect: 'none',
  },
  verifying: {
    backgroundColor: colors.paleYellow,
    '&:hover': {
      backgroundColor: colors.paleYellow,
    },
    userSelect: 'none',
  },
}));

/**
 *
 * @param {object} props
 * @param {string} props.inputID
 * @param {string} props.buttonText
 * @param {boolean} props.uploaded
 * @param {boolean} props.disabled
 * @param {() => void} props.onClick
 * @param {string} props.tooltip
 */
function FilesUploadedActionButton(props) {
  const classes = useStyles();

  const {
    inputID = 'file-uploaded',
    buttonText = 'Upload Files',
    uploaded = false,
    disabled = false,
    verifying = false,
    onClick,
    tooltip = '',
  } = props;

  return (
    <div onClick={(event) => event.stopPropagation()}>
      <input
        style={{
          display: 'none',
        }}
        id={inputID}
        type='button'
        onClick={onClick}
        disabled={disabled}
      />
      <Tooltip title={tooltip}>
        <label
          className={clsx(
            classes.uploadButton,
            disabled && classes.disabled,
            uploaded && classes.uploaded,
            verifying && classes.verifying
          )}
          htmlFor={inputID}
        >
          {verifying && <RateReview className={classes.reviewIcon} />}
          {uploaded && <CheckCircleIcon className={classes.checkIcon} />}
          <span style={{ marginTop: 3, marginLeft: 4, marginRight: 4 }}>
            {buttonText}
          </span>
        </label>
      </Tooltip>
    </div>
  );
}

export default FilesUploadedActionButton;
