import React, { useEffect, useMemo, useState } from 'react';
import Decimal from 'decimal.js';

import {
  Checkbox,
  FormControlLabel,
  InputAdornment,
  MenuItem,
  TextField,
  Tooltip,
} from '@material-ui/core';

import OutlinedDiv from '../panels/OutlinedDiv';
import { FlexRowCenter, FlexRowSpaceBetween } from '../layouts/FlexLayouts';
import { FtrButton } from '../ftr-components';

import { isCustomMaterial } from '../../utils/inputUtils';
import { isPdfFile } from '../../utils/fileUtils';
import { isEmptyValue } from '../../utils/commonUtils';
import { getPpeMaterialCode } from '../../utils/ppeUtils';
import { getFileNameFromCadFile, is3DPTechnology } from '../../utils/itemUtils';
import {
  convertCm2ToMm2,
  convertCm3ToMm3,
  convertMm2ToCm2,
  convertMm3ToCm3,
} from '../../utils/unitUtils';
import { isTechSMF } from '../../utils/technologyUtils';

import { getDfmExtractDimensions } from '../../apis/dfmApi';
import { updatePpeInformationItem } from '../../apis/itemApi';

import { notifyError, notifySuccess } from '../../services/notificationService';

import { techMapping } from '../../constants/PPEConstants';
import { THREE_D_P_TECH_ABBRE_MAPPING } from '../../constants/NewPartConstants';

/**
 * A component to input and display dimensions of a part.
 *
 * It takes in the following props:
 * - `isDimensionDataRequired`: A boolean indicating whether the dimension fields are required or not.
 * - `ppeInformation`: An object containing the DFM data and other related information.
 * - `showDimensionFieldError`: A function to check if a dimension field has an error.
 * - `setIsDimensionDataRequired`: A callback function to update whether the dimension data is required or not.
 * - `showDimensionDataNotRequired`: A boolean indicating whether the dimension data is not required or not.
 * - `updatePpeInformation`: A callback function to update the `ppeInformation` state.
 * - `isDimensionDataRequired`: A boolean indicating whether the dimension data is required or not.
 * - `sizeXRef`, `sizeYRef`, `sizeZRef`: Refs to the size input fields.
 * - `cadFile`, `originalFiles`, `material`, `otherMaterial`, `technology`, `threeDTechnology`, `imageFile`: Values related to the part.
 *
 * It renders a form with input fields for size X, Y, Z, volume, surface area, weight and weight per unit.
 * It also renders a button to fetch the dimensions from the latest CAD file and a button to save the dimensions.
 * It also renders a checkbox to indicate whether the dimension data is required or not.
 *
 * @param {Object} props - The props object.
 * @param {Object} props.ppeInformation - An object containing the DFM data and other related information.
 * @param {Function} props.showDimensionFieldError - A function to check if a dimension field has an error.
 * @param {Function} props.setIsDimensionDataRequired - A callback function to update whether the dimension data is required or not.
 * @param {boolean} props.showDimensionDataNotRequired - A boolean indicating whether the dimension data is not required or not.
 * @param {Function} props.updatePpeInformation - A callback function to update the `ppeInformation` state.
 * @param {boolean} props.isDimensionDataRequired - A boolean indicating whether the dimension data is required or not.
 * @param {React.Ref} props.sizeXRef - A ref to the size X input field.
 * @param {React.Ref} props.sizeYRef - A ref to the size Y input field.
 * @param {React.Ref} props.sizeZRef - A ref to the size Z input field.
 * @param {React.Ref} props.weightRef - A ref to the total weight input field.
 * @param {React.Ref} props.weightPerUnitRef - A ref to the weight per unit input field.
 * @param {File} props.cadFile - The CAD file.
 * @param {File[]} props.originalFiles - The original files.
 * @param {string} props.material - The material.
 * @param {string} props.otherMaterial - The other material.
 * @param {string} props.technology - The technology.
 * @param {string} props.threeDTechnology - The 3D technology.
 * @param {File} props.imageFile - The image file.
 */
function DimensionsInput(props) {
  const {
    ppeInformation,
    showDimensionFieldError,
    setIsDimensionDataRequired,
    showDimensionDataNotRequired,
    updatePpeInformation,
    isDimensionDataRequired,
    sizeXRef,
    sizeYRef,
    sizeZRef,
    weightRef,
    weightPerUnitRef,
    numBendsRef,
    numHolesRef,
    sizeXUnfoldedPartRef,
    sizeYUnfoldedPartRef,
    surfaceAreaRef,
    thicknessRef,
    quantity = 1,
    cadFile,
    originalFiles,
    material,
    otherMaterial,
    technology,
    threeDTechnology,
    imageFile,
    itemID,
    isPpeItem,
  } = props;

  const [volume, setVolume] = useState(null);
  const [surfaceArea, setSurfaceArea] = useState(null);
  const [selectedCadFile, setSelectedCadFile] = useState(null);

  const listFiles = useMemo(() => {
    const files = [...new Set([...cadFile, ...originalFiles])];
    return files.filter((file) => !isPdfFile(file));
  }, [cadFile, originalFiles]);

  useEffect(() => {
    if (ppeInformation) {
      setVolume(
        convertCm3ToMm3(ppeInformation.dfm?.volume ?? ppeInformation.volume)
      );
      setSurfaceArea(
        convertCm2ToMm2(
          ppeInformation.dfm?.surfaceArea ?? ppeInformation.surfaceArea
        )
      );
    }
  }, [ppeInformation]);

  useEffect(() => {
    if (!isEmptyValue(listFiles)) {
      setSelectedCadFile(listFiles[0]);
    }
  }, [listFiles]);

  const handleFetchDimensions = async () => {
    updatePpeInformation({ fetchLoading: true });
    const materialDefault = isCustomMaterial(material)
      ? otherMaterial
      : material;
    const params = {
      process: techMapping[technology] || technology,
      file_url: selectedCadFile,
      material: getPpeMaterialCode({ technology, material }) || materialDefault,
      quantity: Number(quantity),
      customer_image_file_location: imageFile,
    };
    if (is3DPTechnology(technology)) {
      params.threeDTechnology =
        THREE_D_P_TECH_ABBRE_MAPPING[threeDTechnology] || threeDTechnology;
    }
    getDfmExtractDimensions(params)
      .then((data) => {
        if (isEmptyValue(data) || !data?.dim_success) {
          notifyError(
            'Item dimensions cannot be fetched! Please configure dimension and weight parameters manually to proceed.'
          );
        } else {
          const encodedFileName = selectedCadFile?.split('/').pop();
          const fileName = getFileNameFromCadFile(encodedFileName);
          notifySuccess(
            `Item dimensions for ${fileName} fetched successfully!`
          );
          updatePpeInformation({
            dfm: data,
            weightPerUnit: data.weight
              ? new Decimal(data.weight).dividedBy(quantity || 1).toNumber()
              : null,
          });
        }
      })
      .catch((err) => {
        notifyError(err?.message || 'Unexpected error!');
      })
      .finally(() => {
        updatePpeInformation({ fetchLoading: false });
      });
  };

  const handleSavePpeInformation = () => {
    updatePpeInformation({ saveLoading: true });
    const params = {
      boundingBoxX:
        ppeInformation.dfm?.boundingBoxX ?? ppeInformation.boundingBoxX ?? null,
      boundingBoxY:
        ppeInformation.dfm?.boundingBoxY ?? ppeInformation.boundingBoxY ?? null,
      boundingBoxZ:
        ppeInformation.dfm?.boundingBoxZ ?? ppeInformation.boundingBoxZ ?? null,
      volume: ppeInformation.dfm?.volume ?? ppeInformation.volume ?? null,
      weight: ppeInformation.dfm?.weight ?? ppeInformation.weight ?? null,
      surfaceArea:
        ppeInformation.dfm?.surfaceArea ?? ppeInformation.surfaceArea ?? null,
    };
    updatePpeInformationItem(itemID, params)
      .then(() => {
        updatePpeInformation({ dfm: null, saveLoading: false, ...params });
        notifySuccess('Item dimensions have been updated successfully!');
      })
      .catch((err) => {
        notifyError(err?.message || 'Unexpected error!');
        updatePpeInformation({ saveLoading: false });
      });
  };

  const handleUpdateDimension = (key, value) => {
    if (!isEmptyValue(ppeInformation.dfm)) {
      updatePpeInformation({ dfm: { ...ppeInformation.dfm, [key]: value } });
      return;
    }
    updatePpeInformation({ [key]: value });
  };

  function renderWeightPerUnit() {
    const weight = ppeInformation.dfm?.weight ?? ppeInformation.weight;
    const weightPerUnit =
      (ppeInformation.weightPerUnit ?? !isEmptyValue(weight))
        ? new Decimal(weight).dividedBy(quantity || 1).toNumber()
        : null;

    return (
      <TextField
        disabled={!isDimensionDataRequired}
        variant='outlined'
        margin='dense'
        label='Weight Per Unit'
        style={{ flexGrow: '1' }}
        type='number'
        required={isDimensionDataRequired}
        value={weightPerUnit}
        onChange={(evt) => {
          const value = evt.target.valueAsNumber;
          handleUpdateDimension('weightPerUnit', value);
          const totalWeight = value * quantity;
          handleUpdateDimension('weight', totalWeight);
        }}
        InputProps={{
          endAdornment: <InputAdornment position='end'>kgs</InputAdornment>,
        }}
        InputLabelProps={{
          shrink: true,
        }}
        fullWidth
        ref={weightPerUnitRef}
        error={showDimensionFieldError('weightPerUnit')}
        helperText={showDimensionFieldError('weightPerUnit')}
      />
    );
  }

  const renderFieldsForSMF = () => {
    if (!isTechSMF(technology)) {
      return null;
    }
    return (
      <>
        <FlexRowSpaceBetween>
          <TextField
            disabled={!isDimensionDataRequired}
            variant='outlined'
            margin='dense'
            required={isDimensionDataRequired && !isPpeItem}
            style={{ flexGrow: '1' }}
            label='# of Bends'
            type='number'
            fullWidth
            value={
              ppeInformation.dfm?.numBends ?? ppeInformation.numBends ?? ''
            }
            onChange={(evt) =>
              handleUpdateDimension('numBends', evt.target.valueAsNumber)
            }
            ref={numBendsRef}
            error={showDimensionFieldError('numBends')}
            helperText={showDimensionFieldError('numBends')}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            disabled={!isDimensionDataRequired}
            variant='outlined'
            margin='dense'
            fullWidth
            required={isDimensionDataRequired && !isPpeItem}
            style={{ flexGrow: '1' }}
            label='# of Holes'
            type='number'
            value={
              ppeInformation.dfm?.numHoles ?? ppeInformation.numHoles ?? ''
            }
            onChange={(evt) =>
              handleUpdateDimension('numHoles', evt.target.valueAsNumber)
            }
            ref={numHolesRef}
            error={showDimensionFieldError('numHoles')}
            helperText={showDimensionFieldError('numHoles')}
            InputLabelProps={{
              shrink: true,
            }}
          />
          {renderWeightPerUnit()}
        </FlexRowSpaceBetween>
        <FlexRowSpaceBetween>
          <TextField
            disabled={!isDimensionDataRequired}
            variant='outlined'
            margin='dense'
            required={isDimensionDataRequired && !isPpeItem}
            style={{ flexGrow: '1' }}
            label='Size X (Unfolded Part)'
            type='number'
            fullWidth
            value={
              ppeInformation.dfm?.sizeXUnfoldedPart ??
              ppeInformation.sizeXUnfoldedPart ??
              ''
            }
            onChange={(evt) =>
              handleUpdateDimension(
                'sizeXUnfoldedPart',
                evt.target.valueAsNumber
              )
            }
            ref={sizeXUnfoldedPartRef}
            error={showDimensionFieldError('sizeXUnfoldedPart')}
            helperText={showDimensionFieldError('sizeXUnfoldedPart')}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            disabled={!isDimensionDataRequired}
            variant='outlined'
            margin='dense'
            required={isDimensionDataRequired && !isPpeItem}
            style={{ flexGrow: '1' }}
            label='Size Y (Unfolded Part)'
            type='number'
            fullWidth
            value={
              ppeInformation.dfm?.sizeYUnfoldedPart ??
              ppeInformation.sizeYUnfoldedPart ??
              ''
            }
            onChange={(evt) =>
              handleUpdateDimension(
                'sizeYUnfoldedPart',
                evt.target.valueAsNumber
              )
            }
            ref={sizeYUnfoldedPartRef}
            error={showDimensionFieldError('sizeYUnfoldedPart')}
            helperText={showDimensionFieldError('sizeYUnfoldedPart')}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            disabled={!isDimensionDataRequired}
            variant='outlined'
            margin='dense'
            required={isDimensionDataRequired && !isPpeItem}
            style={{ flexGrow: '1' }}
            label='Thickness'
            type='number'
            fullWidth
            value={ppeInformation.thickness}
            onChange={(evt) =>
              handleUpdateDimension('thickness', evt.target.valueAsNumber)
            }
            ref={thicknessRef}
            error={showDimensionFieldError('thickness')}
            helperText={showDimensionFieldError('thickness')}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </FlexRowSpaceBetween>
      </>
    );
  };

  return (
    <OutlinedDiv label='Dimensions'>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(3, 1fr)',
          columnGap: '0.6rem',
        }}
      >
        <FlexRowCenter>
          <TextField
            disabled={!isDimensionDataRequired}
            variant='outlined'
            margin='dense'
            required={isDimensionDataRequired}
            style={{ flexGrow: '1' }}
            label='Size X'
            type='number'
            value={
              ppeInformation.dfm?.boundingBoxX ??
              ppeInformation.boundingBoxX ??
              ''
            }
            onChange={(evt) =>
              handleUpdateDimension('boundingBoxX', evt.target.valueAsNumber)
            }
            InputProps={{
              endAdornment: <InputAdornment position='end'>mm</InputAdornment>,
            }}
            ref={sizeXRef}
            error={showDimensionFieldError('boundingBoxX')}
            helperText={showDimensionFieldError('boundingBoxX')}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </FlexRowCenter>
        <FlexRowCenter>
          <TextField
            disabled={!isDimensionDataRequired}
            variant='outlined'
            margin='dense'
            required={isDimensionDataRequired}
            style={{ flexGrow: '1' }}
            label='Size Y'
            type='number'
            value={
              ppeInformation.dfm?.boundingBoxY ??
              ppeInformation.boundingBoxY ??
              ''
            }
            onChange={(evt) =>
              handleUpdateDimension('boundingBoxY', evt.target.valueAsNumber)
            }
            InputProps={{
              endAdornment: <InputAdornment position='end'>mm</InputAdornment>,
            }}
            ref={sizeYRef}
            error={showDimensionFieldError('boundingBoxY')}
            helperText={showDimensionFieldError('boundingBoxY')}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </FlexRowCenter>
        <FlexRowCenter>
          <TextField
            disabled={!isDimensionDataRequired}
            variant='outlined'
            margin='dense'
            required={isDimensionDataRequired}
            style={{ flexGrow: '1' }}
            label='Size Z'
            type='number'
            value={
              ppeInformation.dfm?.boundingBoxZ ??
              ppeInformation.boundingBoxZ ??
              ''
            }
            onChange={(evt) =>
              handleUpdateDimension('boundingBoxZ', evt.target.valueAsNumber)
            }
            InputProps={{
              endAdornment: <InputAdornment position='end'>mm</InputAdornment>,
            }}
            ref={sizeZRef}
            error={showDimensionFieldError('boundingBoxZ')}
            helperText={showDimensionFieldError('boundingBoxZ')}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </FlexRowCenter>
      </div>
      <FlexRowSpaceBetween>
        <TextField
          disabled={!isDimensionDataRequired}
          variant='outlined'
          margin='dense'
          style={{ flexGrow: '1' }}
          label='Volume'
          type='number'
          value={volume}
          fullWidth
          onChange={(evt) => setVolume(evt.target.valueAsNumber)}
          onBlur={() => {
            const cm3Value = convertMm3ToCm3(volume);
            if (!isEmptyValue(cm3Value)) {
              handleUpdateDimension('volume', cm3Value);
            }
          }}
          InputProps={{
            endAdornment: <InputAdornment position='end'>mm3</InputAdornment>,
          }}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          disabled={!isDimensionDataRequired}
          variant='outlined'
          margin='dense'
          style={{ flexGrow: '1' }}
          label='Surface Area'
          type='number'
          fullWidth
          value={surfaceArea}
          required={isDimensionDataRequired && isTechSMF(technology)}
          onChange={(evt) => setSurfaceArea(evt.target.valueAsNumber)}
          onBlur={() => {
            const cm2Value = convertMm2ToCm2(surfaceArea);
            if (!isEmptyValue(cm2Value)) {
              handleUpdateDimension('surfaceArea', cm2Value);
            }
          }}
          ref={surfaceAreaRef}
          InputProps={{
            endAdornment: <InputAdornment position='end'>mm2</InputAdornment>,
          }}
          InputLabelProps={{
            shrink: true,
          }}
          error={showDimensionFieldError('surfaceArea')}
          helperText={showDimensionFieldError('surfaceArea')}
        />

        <TextField
          disabled={!isDimensionDataRequired}
          variant='outlined'
          margin='dense'
          label={`Total Weight (for quantity ${quantity})`}
          required={isDimensionDataRequired}
          style={{ flexGrow: '1' }}
          type='number'
          fullWidth
          value={ppeInformation.dfm?.weight ?? ppeInformation.weight ?? ''}
          onChange={(evt) => {
            const value = evt.target.valueAsNumber;
            handleUpdateDimension('weight', value);
            const weightPerUnit = value
              ? new Decimal(value).dividedBy(quantity || 1).toNumber()
              : null;
            updatePpeInformation({ weightPerUnit });
          }}
          InputProps={{
            endAdornment: <InputAdornment position='end'>kgs</InputAdornment>,
          }}
          InputLabelProps={{
            shrink: true,
          }}
          ref={weightRef}
          error={showDimensionFieldError('weight')}
          helperText={showDimensionFieldError('weight')}
        />

        {!isTechSMF(technology) && renderWeightPerUnit()}
      </FlexRowSpaceBetween>
      {renderFieldsForSMF()}
      <div
        style={{
          marginTop: '0.5rem',
          display: 'flex',
          gap: '1rem',
          alignItems: 'center',
        }}
      >
        {listFiles?.length > 0 && (
          <TextField
            id='select-cad-file-for-dimension'
            select
            label='Cad File for Dimension'
            value={selectedCadFile}
            onChange={(evt) => setSelectedCadFile(evt.target.value)}
            variant='outlined'
            margin='dense'
            style={{
              marginTop: '1rem',
              marginBottom: '0.5rem',
              width: '200px',
            }}
            InputLabelProps={{
              shrink: true,
            }}
          >
            {listFiles?.map((url, index) => {
              const encodedFileName = url.split('/').pop();
              const fileName = getFileNameFromCadFile(encodedFileName);
              return (
                <MenuItem key={index} value={url}>
                  {fileName}
                </MenuItem>
              );
            })}
          </TextField>
        )}
        <Tooltip title='Regenerate dimensions from the latest CAD file' arrow>
          <FtrButton
            onClick={handleFetchDimensions}
            disabled={ppeInformation.fetchLoading || !isDimensionDataRequired}
            loading={ppeInformation.fetchLoading}
          >
            Fetch Dimensions
          </FtrButton>
        </Tooltip>
        {ppeInformation.dfm && (
          <>
            <FtrButton
              color='blue'
              onClick={handleSavePpeInformation}
              disabled={ppeInformation.saveLoading || !isDimensionDataRequired}
              loading={ppeInformation.saveLoading}
            >
              SAVE
            </FtrButton>
            <FtrButton
              color='red'
              onClick={() => updatePpeInformation({ dfm: null })}
              disabled={!isDimensionDataRequired}
            >
              CANCEL
            </FtrButton>
          </>
        )}
        {showDimensionDataNotRequired && (
          <FormControlLabel
            control={
              <Checkbox
                checked={!isDimensionDataRequired}
                onChange={() => {
                  setIsDimensionDataRequired(!isDimensionDataRequired);
                }}
                name='checkbox-dimension-data-required'
                data-cy='checkbox-dimension-data-not-required'
              />
            }
            label='Dimension Data Not Required'
          />
        )}
      </div>
    </OutlinedDiv>
  );
}

export default DimensionsInput;
