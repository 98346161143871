import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import {
  updateMultiCheckoutCurrency,
  updatePaymentType,
} from '../actions/multiCheckoutForm';

import { PAYMENT_TYPE } from '../constants/paymentConstants';
import { CURRENCY } from '../constants';

// -------------------------------------------------------------------------------------------------

const useUpdatePaymentType = (currency) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(updateMultiCheckoutCurrency(currency));
    if (currency === CURRENCY.SGD) {
      dispatch(updatePaymentType(PAYMENT_TYPE.STRIPE_PAYNOW));
    } else {
      dispatch(updatePaymentType(PAYMENT_TYPE.STRIPE_CREDIT_CARD));
    }
  }, [currency]);
};

export default useUpdatePaymentType;
