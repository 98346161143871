import {
  RESET_VIEW_AS_SUPPLIER,
  SET_VIEW_AS_SUPPLIER,
} from '../actions/viewAsSupplier';
import { LOGOUT_SUCCESS } from '../actions';
import { CURRENCY } from '../constants';

const defaultState = {
  user: null,
  currency: CURRENCY.SGD,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case SET_VIEW_AS_SUPPLIER:
      return {
        ...action.payload,
      };
    case RESET_VIEW_AS_SUPPLIER:
      return defaultState;
    case LOGOUT_SUCCESS:
      return defaultState;
    default:
      return state;
  }
};
