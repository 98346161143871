import { Badge } from '@material-ui/core';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  badge: {
    '& .MuiBadge-badge': {
      top: '50%',
    },
  },
}));

const ActionButtonBadge = ({ children, badgeContent, showBadgeCounter }) => {
  const classes = useStyles();

  return showBadgeCounter ? (
    <Badge badgeContent={badgeContent} color='error' className={classes.badge}>
      {children}
    </Badge>
  ) : (
    <>{children}</>
  );
};

export default ActionButtonBadge;
