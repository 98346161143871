import React, { useContext, useEffect } from 'react';

import { useSelector } from 'react-redux';

import AddPartsTourPopup from './AddPartsTourPopup';
import { DrawerButtonsContext } from '../ResponsiveSidebar';

import withGuidedTour from './withGuidedTour';

import { isAdminOrHigherRole } from '../../utils/roleUtils';

import { BUYER_UPLOAD_PARTS_TOUR } from './guidedTours/tourConstants';

function AddMultiplePartsTour(props) {
  const {
    updateTourState,
    userCompletedTourBefore,
    setTourEnded,
    tour,
  } = props;

  const userName = useSelector((state) => state.auth?.user?.name);
  const role = useSelector((state) => state.auth?.user?.role);

  const { expandDrawerButtonRef, closeDrawerButtonRef } = useContext(
    DrawerButtonsContext
  );

  useEffect(() => {
    updateTourState({
      tourKey: BUYER_UPLOAD_PARTS_TOUR,
      createOpenDrawerPromise,
      createCloseDrawerPromise,
    });
  }, []);

  // In mobile view, we need to make sure the drawer is expanded before showing the step (and the subsequent steps).
  function createOpenDrawerPromise() {
    return new Promise((resolve) => {
      if (expandDrawerButtonRef.current) {
        expandDrawerButtonRef.current.click();
      }
      resolve();
    });
  }

  function createCloseDrawerPromise() {
    return new Promise((resolve) => {
      if (closeDrawerButtonRef.current) {
        closeDrawerButtonRef.current.click();
      }
      resolve();
    });
  }

  return (
    <>
      {!isAdminOrHigherRole(role) && userCompletedTourBefore === false && (
        <AddPartsTourPopup
          handleSkipTour={() => setTourEnded()}
          handleStartTour={tour?.start}
          userName={userName}
        />
      )}
    </>
  );
}

export default withGuidedTour(AddMultiplePartsTour);
