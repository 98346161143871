import React from 'react';

import FtrSvgImage from '../../images/FtrSvgImage';

import LeftArrowIcon from '../../../assets/icons/left_arrow_icon.svg';
import LeftArrowBlueIcon from '../../../assets/icons/left_arrow_blue_icon.svg';

// ----------------------------------------------------------------------------------

function FtrLeftArrowButton(props) {
  const { onClick = () => {} } = props;

  return (
    <FtrSvgImage
      src={LeftArrowIcon}
      hoverIcon={LeftArrowBlueIcon}
      width={10}
      height={10}
      style={{
        justifyContent: 'end',
        cursor: 'pointer',
      }}
      onClick={onClick}
    />
  );
}

export default FtrLeftArrowButton;
