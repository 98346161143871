// Import settings
import { Link } from 'react-router-dom';
import React from 'react';

// Import material UI components
import { Button } from '@material-ui/core';
import { Home as HomeIcon } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles/index';

const useStyles = makeStyles((theme) => ({
  body: {
    padding: theme.spacing(6),
    marginTop: theme.spacing(8),
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  header: {
    textTransform: 'uppercase',
    fontSize: '60pt',
    fontWeight: 'bolder',
    background:
      '-webkit-linear-gradient(#30CFD0, #330867); -webkit-background-clip: text; -webkit-text-fill-color: transparent;',
  },
  content: {
    fontSize: '12pt',
    fontWeight: 'bold',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    color: '#152238',
  },
}));

const Error = () => {
  const classes = useStyles();
  return (
    <div className={classes.body}>
      <div className={classes.container}>
        <div className={classes.header}>Oops!</div>
        <div className={classes.content}>
          <div style={{ marginBottom: '30px' }}>This page does not exist!</div>
          <div>
            <Button
              variant='contained'
              color='primary'
              size='medium'
              component={Link}
              to={'/'}
              startIcon={<HomeIcon />}
            >
              Go to Home Page
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Error;
