export const LOADING_MESSAGE_OPTIONS_ARRAY = [
  'Please hold while we awaken our mechanical minions for your order.',
  'Please hold while we spread the joy to the factories crafting your order.',
  'Please hold while our trusty intern scribbles down the invoice for your order.',
  'Please hold while our intergalactic spacecraft fills up its tanks for your order.',
  'Please hold while we stir up the cogs and gears for your order.',
  'Please hold while we jingle the bells of the production line for your order.',
  'Please hold while our intern folds the invoice into origami for your order.',
  'Please hold while our rocket ship revs its engines for your order.',
  'Please hold while we rustle up the robots for your order.',
  'Please hold while we shout hooray to the factories for your order.',
];

export const SUBMITTING_ORDER_MESSAGE_OPTIONS_ARRAY = [
  'Donning our detective hats and magnifying glasses to ensure we have all the materials needed for your order.',
  'Waking up our trusty intern to ensure that your parts are bright-eyed and bushy-tailed for fabrication.',
  'Putting on our negotiating hats and calling up our suppliers to get the best deal for your project.',
  "Keeping a watchful eye on your project to make sure it's sailing smoothly towards the finish line.",
  "Giving your product the once-over with our magic magnifying glass to ensure it's top-quality.",
  'Picking up the phone to charm our factories into making sure you get the best quote for your project.',
  'Donning our superhero capes and answering all your questions about your order with lightning speed.',
  'Enlisting the help of our tech-savvy team to ensure your project is executed flawlessly.',
  'Doing a happy dance when all the pieces of your project fall into place perfectly.',
  "Rolling up our sleeves to make sure we've got everything we need to rock your project.",
  'Calling on our technical wizards to cast their spells and work their magic on your project.',
];
