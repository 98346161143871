import { isEmptyValue } from '../utils/commonUtils';

import { CURRENCY_CODE, CURRENCY_SYMBOL_MAPPING } from './currencyConstants';

// -------------------------------------------------------------------------------------------------

export const COUNTRY_NAMES = {
  SINGAPORE: 'Singapore',
  AFGHANISTAN: 'Afghanistan',
  ALBANIA: 'Albania',
  ALGERIA: 'Algeria',
  ALLAND_ISLANDS: 'Alland Islands',
  AMERICA: 'America',
  AMERICAN_SAMOA: 'American Samoa',
  ANDORRA: 'Andorra',
  ANGOLA: 'Angola',
  ANGUILLA: 'Anguilla',
  ANTARCTICA: 'Antarctica',
  ANTIGUA_AND_BARBUDA: 'Antigua and Barbuda',
  ARGENTINA: 'Argentina',
  ARMENIA: 'Armenia',
  ARUBA: 'Aruba',
  AUSTRALIA: 'Australia',
  AUSTRIA: 'Austria',
  AZERBAIJAN: 'Azerbaijan',
  BAHAMAS: 'Bahamas',
  BAHRAIN: 'Bahrain',
  BANGLADESH: 'Bangladesh',
  BARBADOS: 'Barbados',
  BELARUS: 'Belarus',
  BELGIUM: 'Belgium',
  BELIZE: 'Belize',
  BENIN: 'Benin',
  BERMUDA: 'Bermuda',
  BHUTAN: 'Bhutan',
  BOLIVIA: 'Bolivia',
  BOSNIA_AND_HERZEGOVINA: 'Bosnia and Herzegovina',
  BOTSWANA: 'Botswana',
  BOUVET_ISLAND: 'Bouvet Island',
  BRAZIL: 'Brazil',
  BRITISH_INDIAN_OCEAN_TERRITORY: 'British Indian Ocean Territory',
  BRITISH_VIRGIN_ISLANDS: 'British Virgin Islands',
  BRUNEI_DARUSSALAM: 'Brunei Darussalam',
  BULGARIA: 'Bulgaria',
  BURKINA_FASO: 'Burkina Faso',
  BURUNDI: 'Burundi',
  CAMBODIA: 'Cambodia',
  CAMEROON: 'Cameroon',
  CANADA: 'Canada',
  CAPE_VERDE: 'Cape Verde',
  CAYMAN_ISLANDS: 'Cayman Islands',
  CENTRAL_AFRICAN_REPUBLIC: 'Central African Republic',
  CHAD: 'Chad',
  CHILE: 'Chile',
  CHINA: 'China',
  CHRISTMAS_ISLAND: 'Christmas Island',
  COCOS_KEELING_ISLANDS: 'Cocos (Keeling) Islands',
  COLOMBIA: 'Colombia',
  COMOROS: 'Comoros',
  CONGO_DEMOCRATIC_REPUBLIC_OF_THE: 'Congo, Democratic Republic of the',
  CONGO_REPUBLIC_OF_THE: 'Congo, Republic of the',
  COOK_ISLANDS: 'Cook Islands',
  COSTA_RICA: 'Costa Rica',
  COTE_DIVOIRE: "Cote d'Ivoire",
  CROATIA: 'Croatia',
  CUBA: 'Cuba',
  CURACAO: 'Curacao',
  CYPRUS: 'Cyprus',
  CZECH_REPUBLIC: 'Czech Republic',
  DENMARK: 'Denmark',
  DJIBOUTI: 'Djibouti',
  DOMINICA: 'Dominica',
  DOMINICAN_REPUBLIC: 'Dominican Republic',
  ECUADOR: 'Ecuador',
  EGYPT: 'Egypt',
  EL_SALVADOR: 'El Salvador',
  EQUATORIAL_GUINEA: 'Equatorial Guinea',
  ERITREA: 'Eritrea',
  ESTONIA: 'Estonia',
  ETHIOPIA: 'Ethiopia',
  FALKLAND_ISLANDS: 'Falkland Islands (Malvinas)',
  FAROE_ISLANDS: 'Faroe Islands',
  FIJI: 'Fiji',
  FINLAND: 'Finland',
  FRANCE: 'France',
  FRENCH_GUIANA: 'French Guiana',
  FRENCH_POLYNESIA: 'French Polynesia',
  FRENCH_SOUTHERN_TERRITORIES: 'French Southern Territories',
  GABON: 'Gabon',
  GAMBIA: 'Gambia',
  GEORGIA: 'Georgia',
  GERMANY: 'Germany',
  GHANA: 'Ghana',
  GIBRALTAR: 'Gibraltar',
  GREECE: 'Greece',
  GREENLAND: 'Greenland',
  GRENADA: 'Grenada',
  GUADELOUPE: 'Guadeloupe',
  GUAM: 'Guam',
  GUATEMALA: 'Guatemala',
  GUERNSEY: 'Guernsey',
  GUINEA: 'Guinea',
  GUINEA_BISSAU: 'Guinea-Bissau',
  GUYANA: 'Guyana',
  HAITI: 'Haiti',
  HEARD_ISLAND_AND_MCDONALD_ISLANDS: 'Heard Island and McDonald Islands',
  HONDURAS: 'Honduras',
  HONG_KONG: 'Hong Kong',
  HUNGARY: 'Hungary',
  ICELAND: 'Iceland',
  INDIA: 'India',
  INDONESIA: 'Indonesia',
  IRAQ: 'Iraq',
  IRELAND: 'Ireland',
  ISLE_OF_MAN: 'Isle of Man',
  ISRAEL: 'Israel',
  ITALY: 'Italy',
  JAMAICA: 'Jamaica',
  JAPAN: 'Japan',
  JERSEY: 'Jersey',
  JORDAN: 'Jordan',
  KAZAKHSTAN: 'Kazakhstan',
  KENYA: 'Kenya',
  KIRIBATI: 'Kiribati',
  KOSOVO: 'Kosovo',
  KUWAIT: 'Kuwait',
  KYRGYZSTAN: 'Kyrgyzstan',
  LAOS: 'Laos',
  LATVIA: 'Latvia',
  LEBANON: 'Lebanon',
  LESOTHO: 'Lesotho',
  LIBERIA: 'Liberia',
  LIBYA: 'Libya',
  LIECHTENSTEIN: 'Liechtenstein',
  LITHUANIA: 'Lithuania',
  LUXEMBOURG: 'Luxembourg',
  MACAO: 'Macao',
  MADAGASCAR: 'Madagascar',
  MALAWI: 'Malawi',
  MALAYSIA: 'Malaysia',
  MALDIVES: 'Maldives',
  MALI: 'Mali',
  MALTA: 'Malta',
  MARSHALL_ISLANDS: 'Marshall Islands',
  MARTINIQUE: 'Martinique',
  MAURITANIA: 'Mauritania',
  MAURITIUS: 'Mauritius',
  MAYOTTE: 'Mayotte',
  MEXICO: 'Mexico',
  MICRONESIA: 'Micronesia',
  MOLDOVA: 'Moldova, Republic of',
  MONACO: 'Monaco',
  MONGOLIA: 'Mongolia',
  MONTENEGRO: 'Montenegro',
  MONTSERRAT: 'Montserrat',
  MOROCCO: 'Morocco',
  MOZAMBIQUE: 'Mozambique',
  MYANMAR: 'Myanmar',
  NAMIBIA: 'Namibia',
  NAURU: 'Nauru',
  NEPAL: 'Nepal',
  NETHERLANDS: 'Netherlands',
  NEW_CALEDONIA: 'New Caledonia',
  NEW_ZEALAND: 'New Zealand',
  NICARAGUA: 'Nicaragua',
  NIGER: 'Niger',
  NIGERIA: 'Nigeria',
  NIUE: 'Niue',
  NORFOLK_ISLAND: 'Norfolk Island',
  NORTH_MACEDONIA: 'North Macedonia',
  NORTHERN_MARIANA_ISLANDS: 'Northern Mariana Islands',
  NORWAY: 'Norway',
  OMAN: 'Oman',
  PAKISTAN: 'Pakistan',
  PALAU: 'Palau',
  PALESTINE: 'Palestine',
  PANAMA: 'Panama',
  PAPUA_NEW_GUINEA: 'Papua New Guinea',
  PARAGUAY: 'Paraguay',
  PERU: 'Peru',
  PHILIPPINES: 'Philippines',
  PITCAIRN: 'Pitcairn',
  POLAND: 'Poland',
  PORTUGAL: 'Portugal',
  PUERTO_RICO: 'Puerto Rico',
  QATAR: 'Qatar',
  REUNION: 'Reunion',
  ROMANIA: 'Romania',
  RUSSIA: 'Russia',
  RWANDA: 'Rwanda',
  SAMOA: 'Samoa',
  SAN_MARINO: 'San Marino',
  SAO_TOME_AND_PRINCIPE: 'Sao Tome and Principe',
  SAUDI_ARABIA: 'Saudi Arabia',
  SENEGAL: 'Senegal',
  SERBIA: 'Serbia',
  SEYCHELLES: 'Seychelles',
  SIERRA_LEONE: 'Sierra Leone',
  SINT_MAARTEN: 'Sint Maarten',
  SLOVAKIA: 'Slovakia',
  SLOVENIA: 'Slovenia',
  SOLOMON_ISLANDS: 'Solomon Islands',
  SOMALIA: 'Somalia',
  SOUTH_AFRICA: 'South Africa',
  SOUTH_GEORGIA_AND_THE_SOUTH_SANDWICH_ISLANDS:
    'South Georgia and the South Sandwich Islands',
  SOUTH_KOREA: 'South Korea',
  SOUTH_SUDAN: 'South Sudan',
  SPAIN: 'Spain',
  SRI_LANKA: 'Sri Lanka',
  ST_BARTHELEMY: 'St. Barthelemy',
  ST_HELENA: 'St. Helena',
  ST_KITTS_AND_NEVIS: 'St. Kitts and Nevis',
  ST_LUCIA: 'St. Lucia',
  ST_MARTIN: 'St. Martin',
  ST_PIERRE_AND_MIQUELON: 'St. Pierre and Miquelon',
  ST_VINCENT_AND_THE_GRENADINES: 'St. Vincent and the Grenadines',
  SUDAN: 'Sudan',
  SURINAME: 'Suriname',
  SVALBARD_AND_JAN_MAYEN: 'Svalbard and Jan Mayen',
  SWAZILAND: 'Swaziland',
  SWEDEN: 'Sweden',
  SWITZERLAND: 'Switzerland',
  SYRIA: 'Syria',
  TAIWAN: 'Taiwan',
  TAJIKISTAN: 'Tajikistan',
  THAILAND: 'Thailand',
  TIMOR_LESTE: 'Timor-Leste',
  TOGO: 'Togo',
  TOKELAU: 'Tokelau',
  TONGA: 'Tonga',
  TRINIDAD_AND_TOBAGO: 'Trinidad and Tobago',
  TUNISIA: 'Tunisia',
  TURKEY: 'Turkey',
  TURKMENISTAN: 'Turkmenistan',
  TURKS_AND_CAICOS_ISLANDS: 'Turks and Caicos Islands',
  TUVALU: 'Tuvalu',
  UGANDA: 'Uganda',
  UKRAINE: 'Ukraine',
  UNITED_ARAB_EMIRATES: 'United Arab Emirates',
  UNITED_KINGDOM: 'United Kingdom',
  UNITED_REPUBLIC_OF_TANZANIA: 'United Republic of Tanzania',
  URUGUAY: 'Uruguay',
  US_VIRGIN_ISLANDS: 'US Virgin Islands',
  UZBEKISTAN: 'Uzbekistan',
  VANUATU: 'Vanuatu',
  VATICAN_CITY_STATE: 'Vatican City State',
  VENEZUELA: 'Venezuela',
  VIETNAM: 'Vietnam',
  WALLIS_AND_FUTUNA: 'Wallis and Futuna',
  WESTERN_SAHARA: 'Western Sahara',
  YEMEN: 'Yemen',
  ZAMBIA: 'Zambia',
  ZIMBABWE: 'Zimbabwe',
};

export const COUNTRIES = [
  COUNTRY_NAMES.SINGAPORE,
  COUNTRY_NAMES.AFGHANISTAN,
  COUNTRY_NAMES.ALBANIA,
  COUNTRY_NAMES.ALGERIA,
  COUNTRY_NAMES.ALLAND_ISLANDS,
  COUNTRY_NAMES.AMERICA,
  COUNTRY_NAMES.AMERICAN_SAMOA,
  COUNTRY_NAMES.ANDORRA,
  COUNTRY_NAMES.ANGOLA,
  COUNTRY_NAMES.ANGUILLA,
  COUNTRY_NAMES.ANTARCTICA,
  COUNTRY_NAMES.ANTIGUA_AND_BARBUDA,
  COUNTRY_NAMES.ARGENTINA,
  COUNTRY_NAMES.ARMENIA,
  COUNTRY_NAMES.ARUBA,
  COUNTRY_NAMES.AUSTRALIA,
  COUNTRY_NAMES.AUSTRIA,
  COUNTRY_NAMES.AZERBAIJAN,
  COUNTRY_NAMES.BAHAMAS,
  COUNTRY_NAMES.BAHRAIN,
  COUNTRY_NAMES.BANGLADESH,
  COUNTRY_NAMES.BARBADOS,
  COUNTRY_NAMES.BELARUS,
  COUNTRY_NAMES.BELGIUM,
  COUNTRY_NAMES.BELIZE,
  COUNTRY_NAMES.BENIN,
  COUNTRY_NAMES.BERMUDA,
  COUNTRY_NAMES.BHUTAN,
  COUNTRY_NAMES.BOLIVIA,
  COUNTRY_NAMES.BOSNIA_AND_HERZEGOVINA,
  COUNTRY_NAMES.BOTSWANA,
  COUNTRY_NAMES.BOUVET_ISLAND,
  COUNTRY_NAMES.BRAZIL,
  COUNTRY_NAMES.BRITISH_INDIAN_OCEAN_TERRITORY,
  COUNTRY_NAMES.BRITISH_VIRGIN_ISLANDS,
  COUNTRY_NAMES.BRUNEI_DARUSSALAM,
  COUNTRY_NAMES.BULGARIA,
  COUNTRY_NAMES.BURKINA_FASO,
  COUNTRY_NAMES.BURUNDI,
  COUNTRY_NAMES.CAMBODIA,
  COUNTRY_NAMES.CAMEROON,
  COUNTRY_NAMES.CANADA,
  COUNTRY_NAMES.CAPE_VERDE,
  COUNTRY_NAMES.CAYMAN_ISLANDS,
  COUNTRY_NAMES.CENTRAL_AFRICAN_REPUBLIC,
  COUNTRY_NAMES.CHAD,
  COUNTRY_NAMES.CHILE,
  COUNTRY_NAMES.CHINA,
  COUNTRY_NAMES.CHRISTMAS_ISLAND,
  COUNTRY_NAMES.COCOS_KEELING_ISLANDS,
  COUNTRY_NAMES.COLOMBIA,
  COUNTRY_NAMES.COMOROS,
  COUNTRY_NAMES.CONGO_DEMOCRATIC_REPUBLIC_OF_THE,
  COUNTRY_NAMES.CONGO_REPUBLIC_OF_THE,
  COUNTRY_NAMES.COOK_ISLANDS,
  COUNTRY_NAMES.COSTA_RICA,
  COUNTRY_NAMES.COTE_DIVOIRE,
  COUNTRY_NAMES.CROATIA,
  COUNTRY_NAMES.CUBA,
  COUNTRY_NAMES.CURACAO,
  COUNTRY_NAMES.CYPRUS,
  COUNTRY_NAMES.CZECH_REPUBLIC,
  COUNTRY_NAMES.DENMARK,
  COUNTRY_NAMES.DJIBOUTI,
  COUNTRY_NAMES.DOMINICA,
  COUNTRY_NAMES.DOMINICAN_REPUBLIC,
  COUNTRY_NAMES.ECUADOR,
  COUNTRY_NAMES.EGYPT,
  COUNTRY_NAMES.EL_SALVADOR,
  COUNTRY_NAMES.EQUATORIAL_GUINEA,
  COUNTRY_NAMES.ERITREA,
  COUNTRY_NAMES.ESTONIA,
  COUNTRY_NAMES.ETHIOPIA,
  COUNTRY_NAMES.FALKLAND_ISLANDS,
  COUNTRY_NAMES.FAROE_ISLANDS,
  COUNTRY_NAMES.FIJI,
  COUNTRY_NAMES.FINLAND,
  COUNTRY_NAMES.FRANCE,
  COUNTRY_NAMES.FRENCH_GUIANA,
  COUNTRY_NAMES.FRENCH_POLYNESIA,
  COUNTRY_NAMES.FRENCH_SOUTHERN_TERRITORIES,
  COUNTRY_NAMES.GABON,
  COUNTRY_NAMES.GAMBIA,
  COUNTRY_NAMES.GEORGIA,
  COUNTRY_NAMES.GERMANY,
  COUNTRY_NAMES.GHANA,
  COUNTRY_NAMES.GIBRALTAR,
  COUNTRY_NAMES.GREECE,
  COUNTRY_NAMES.GREENLAND,
  COUNTRY_NAMES.GRENADA,
  COUNTRY_NAMES.GUADELOUPE,
  COUNTRY_NAMES.GUAM,
  COUNTRY_NAMES.GUATEMALA,
  COUNTRY_NAMES.GUERNSEY,
  COUNTRY_NAMES.GUINEA,
  COUNTRY_NAMES.GUINEA_BISSAU,
  COUNTRY_NAMES.GUYANA,
  COUNTRY_NAMES.HAITI,
  COUNTRY_NAMES.HEARD_ISLAND_AND_MCDONALD_ISLANDS,
  COUNTRY_NAMES.HONDURAS,
  COUNTRY_NAMES.HONG_KONG,
  COUNTRY_NAMES.HUNGARY,
  COUNTRY_NAMES.ICELAND,
  COUNTRY_NAMES.INDIA,
  COUNTRY_NAMES.INDONESIA,
  COUNTRY_NAMES.IRAQ,
  COUNTRY_NAMES.IRELAND,
  COUNTRY_NAMES.ISLE_OF_MAN,
  COUNTRY_NAMES.ISRAEL,
  COUNTRY_NAMES.ITALY,
  COUNTRY_NAMES.JAMAICA,
  COUNTRY_NAMES.JAPAN,
  COUNTRY_NAMES.JERSEY,
  COUNTRY_NAMES.JORDAN,
  COUNTRY_NAMES.KAZAKHSTAN,
  COUNTRY_NAMES.KENYA,
  COUNTRY_NAMES.KIRIBATI,
  COUNTRY_NAMES.KOSOVO,
  COUNTRY_NAMES.KUWAIT,
  COUNTRY_NAMES.KYRGYZSTAN,
  COUNTRY_NAMES.LAOS,
  COUNTRY_NAMES.LATVIA,
  COUNTRY_NAMES.LEBANON,
  COUNTRY_NAMES.LESOTHO,
  COUNTRY_NAMES.LIBERIA,
  COUNTRY_NAMES.LIBYA,
  COUNTRY_NAMES.LIECHTENSTEIN,
  COUNTRY_NAMES.LITHUANIA,
  COUNTRY_NAMES.LUXEMBOURG,
  COUNTRY_NAMES.MACAO,
  COUNTRY_NAMES.MADAGASCAR,
  COUNTRY_NAMES.MALAWI,
  COUNTRY_NAMES.MALAYSIA,
  COUNTRY_NAMES.MALDIVES,
  COUNTRY_NAMES.MALI,
  COUNTRY_NAMES.MALTA,
  COUNTRY_NAMES.MARSHALL_ISLANDS,
  COUNTRY_NAMES.MARTINIQUE,
  COUNTRY_NAMES.MAURITANIA,
  COUNTRY_NAMES.MAURITIUS,
  COUNTRY_NAMES.MAYOTTE,
  COUNTRY_NAMES.MEXICO,
  COUNTRY_NAMES.MICRONESIA,
  COUNTRY_NAMES.MOLDOVA,
  COUNTRY_NAMES.MONACO,
  COUNTRY_NAMES.MONGOLIA,
  COUNTRY_NAMES.MONTENEGRO,
  COUNTRY_NAMES.MONTSERRAT,
  COUNTRY_NAMES.MOROCCO,
  COUNTRY_NAMES.MOZAMBIQUE,
  COUNTRY_NAMES.MYANMAR,
  COUNTRY_NAMES.NAMIBIA,
  COUNTRY_NAMES.NAURU,
  COUNTRY_NAMES.NEPAL,
  COUNTRY_NAMES.NETHERLANDS,
  COUNTRY_NAMES.NEW_CALEDONIA,
  COUNTRY_NAMES.NEW_ZEALAND,
  COUNTRY_NAMES.NICARAGUA,
  COUNTRY_NAMES.NIGER,
  COUNTRY_NAMES.NIGERIA,
  COUNTRY_NAMES.NIUE,
  COUNTRY_NAMES.NORFOLK_ISLAND,
  COUNTRY_NAMES.NORTH_MACEDONIA,
  COUNTRY_NAMES.NORTHERN_MARIANA_ISLANDS,
  COUNTRY_NAMES.NORWAY,
  COUNTRY_NAMES.OMAN,
  COUNTRY_NAMES.PAKISTAN,
  COUNTRY_NAMES.PALAU,
  COUNTRY_NAMES.PALESTINE,
  COUNTRY_NAMES.PANAMA,
  COUNTRY_NAMES.PAPUA_NEW_GUINEA,
  COUNTRY_NAMES.PARAGUAY,
  COUNTRY_NAMES.PERU,
  COUNTRY_NAMES.PHILIPPINES,
  COUNTRY_NAMES.PITCAIRN,
  COUNTRY_NAMES.POLAND,
  COUNTRY_NAMES.PORTUGAL,
  COUNTRY_NAMES.PUERTO_RICO,
  COUNTRY_NAMES.QATAR,
  COUNTRY_NAMES.REUNION,
  COUNTRY_NAMES.ROMANIA,
  COUNTRY_NAMES.RUSSIA,
  COUNTRY_NAMES.RWANDA,
  COUNTRY_NAMES.SAMOA,
  COUNTRY_NAMES.SAN_MARINO,
  COUNTRY_NAMES.SAO_TOME_AND_PRINCIPE,
  COUNTRY_NAMES.SAUDI_ARABIA,
  COUNTRY_NAMES.SENEGAL,
  COUNTRY_NAMES.SERBIA,
  COUNTRY_NAMES.SEYCHELLES,
  COUNTRY_NAMES.SIERRA_LEONE,
  COUNTRY_NAMES.SINT_MAARTEN,
  COUNTRY_NAMES.SLOVAKIA,
  COUNTRY_NAMES.SLOVENIA,
  COUNTRY_NAMES.SOLOMON_ISLANDS,
  COUNTRY_NAMES.SOMALIA,
  COUNTRY_NAMES.SOUTH_AFRICA,
  COUNTRY_NAMES.SOUTH_GEORGIA_AND_THE_SOUTH_SANDWICH_ISLANDS,
  COUNTRY_NAMES.SOUTH_KOREA,
  COUNTRY_NAMES.SOUTH_SUDAN,
  COUNTRY_NAMES.SPAIN,
  COUNTRY_NAMES.SRI_LANKA,
  COUNTRY_NAMES.ST_BARTHELEMY,
  COUNTRY_NAMES.ST_HELENA,
  COUNTRY_NAMES.ST_KITTS_AND_NEVIS,
  COUNTRY_NAMES.ST_LUCIA,
  COUNTRY_NAMES.ST_MARTIN,
  COUNTRY_NAMES.ST_PIERRE_AND_MIQUELON,
  COUNTRY_NAMES.ST_VINCENT_AND_THE_GRENADINES,
  COUNTRY_NAMES.SUDAN,
  COUNTRY_NAMES.SURINAME,
  COUNTRY_NAMES.SVALBARD_AND_JAN_MAYEN,
  COUNTRY_NAMES.SWAZILAND,
  COUNTRY_NAMES.SWEDEN,
  COUNTRY_NAMES.SWITZERLAND,
  COUNTRY_NAMES.SYRIA,
  COUNTRY_NAMES.TAIWAN,
  COUNTRY_NAMES.TAJIKISTAN,
  COUNTRY_NAMES.THAILAND,
  COUNTRY_NAMES.TIMOR_LESTE,
  COUNTRY_NAMES.TOGO,
  COUNTRY_NAMES.TOKELAU,
  COUNTRY_NAMES.TONGA,
  COUNTRY_NAMES.TRINIDAD_AND_TOBAGO,
  COUNTRY_NAMES.TUNISIA,
  COUNTRY_NAMES.TURKEY,
  COUNTRY_NAMES.TURKMENISTAN,
  COUNTRY_NAMES.TURKS_AND_CAICOS_ISLANDS,
  COUNTRY_NAMES.TUVALU,
  COUNTRY_NAMES.UGANDA,
  COUNTRY_NAMES.UKRAINE,
  COUNTRY_NAMES.UNITED_ARAB_EMIRATES,
  COUNTRY_NAMES.UNITED_KINGDOM,
  COUNTRY_NAMES.UNITED_REPUBLIC_OF_TANZANIA,
  COUNTRY_NAMES.URUGUAY,
  COUNTRY_NAMES.US_VIRGIN_ISLANDS,
  COUNTRY_NAMES.UZBEKISTAN,
  COUNTRY_NAMES.VANUATU,
  COUNTRY_NAMES.VATICAN_CITY_STATE,
  COUNTRY_NAMES.VENEZUELA,
  COUNTRY_NAMES.VIETNAM,
  COUNTRY_NAMES.WALLIS_AND_FUTUNA,
  COUNTRY_NAMES.WESTERN_SAHARA,
  COUNTRY_NAMES.YEMEN,
  COUNTRY_NAMES.ZAMBIA,
  COUNTRY_NAMES.ZIMBABWE,
];

// Reformatted version of
// https://bitbucket.org/atlassian/atlaski"At-mk-2/raw/4ad0e56649c3e6c973e226b7efaeb28cb240ccb0/packages/core/select/src/data/countries.js
let COUNTRY_LIST = {
  [COUNTRY_NAMES.SINGAPORE]: {
    code: 'SG',
    phone: '+65',
    offsetHours: 8,
    currency: CURRENCY_CODE.SGD,
    currencySymbol: CURRENCY_SYMBOL_MAPPING[CURRENCY_CODE.SGD],
  },
  [COUNTRY_NAMES.AFGHANISTAN]: { code: 'AF', phone: '+93', offsetHours: 4.5 },
  [COUNTRY_NAMES.ALBANIA]: { code: 'AL', phone: '+355', offsetHours: 1 },
  [COUNTRY_NAMES.ALGERIA]: { code: 'DZ', phone: '+213', offsetHours: 1 },
  [COUNTRY_NAMES.ALLAND_ISLANDS]: { code: 'AX', phone: '+358', offsetHours: 2 },
  [COUNTRY_NAMES.AMERICAN_SAMOA]: {
    code: 'AS',
    phone: '+1-684',
    offsetHours: -11,
  },
  [COUNTRY_NAMES.ANDORRA]: { code: 'AD', phone: '+376', offsetHours: 1 },
  [COUNTRY_NAMES.ANGOLA]: { code: 'AO', phone: '+244', offsetHours: 1 },
  [COUNTRY_NAMES.ANGUILLA]: { code: 'AI', phone: '+1-264', offsetHours: -4 },
  [COUNTRY_NAMES.ANTARCTICA]: { code: 'AQ', phone: '+672', offsetHours: 0 },
  [COUNTRY_NAMES.ANTIGUA_AND_BARBUDA]: {
    code: 'AG',
    phone: '+1-268',
    offsetHours: -4,
  },
  [COUNTRY_NAMES.ARGENTINA]: { code: 'AR', phone: '+54', offsetHours: -3 },
  [COUNTRY_NAMES.ARMENIA]: { code: 'AM', phone: '+374', offsetHours: 4 },
  [COUNTRY_NAMES.ARUBA]: { code: 'AW', phone: '+297', offsetHours: -4 },
  [COUNTRY_NAMES.AUSTRALIA]: {
    code: 'AU',
    phone: '+61',
    offsetHours: 9.5,
    currency: CURRENCY_CODE.AUD,
    currencySymbol: CURRENCY_SYMBOL_MAPPING[CURRENCY_CODE.AUD],
  },
  [COUNTRY_NAMES.AUSTRIA]: { code: 'AT', phone: '+43', offsetHours: 1 },
  [COUNTRY_NAMES.AZERBAIJAN]: { code: 'AZ', phone: '+994', offsetHours: 4 },
  [COUNTRY_NAMES.BAHAMAS]: { code: 'BS', phone: '+1-242', offsetHours: -5 },
  [COUNTRY_NAMES.BAHRAIN]: { code: 'BH', phone: '+973', offsetHours: 3 },
  [COUNTRY_NAMES.BANGLADESH]: { code: 'BD', phone: '+880', offsetHours: 6 },
  [COUNTRY_NAMES.BARBADOS]: { code: 'BB', phone: '+1-246', offsetHours: -4 },
  [COUNTRY_NAMES.BELARUS]: { code: 'BY', phone: '+375', offsetHours: 3 },
  [COUNTRY_NAMES.BELGIUM]: { code: 'BE', phone: '+32', offsetHours: 1 },
  [COUNTRY_NAMES.BELIZE]: { code: 'BZ', phone: '+501', offsetHours: -6 },
  [COUNTRY_NAMES.BENIN]: { code: 'BJ', phone: '+229', offsetHours: 1 },
  [COUNTRY_NAMES.BERMUDA]: { code: 'BM', phone: '+1-441', offsetHours: -4 },
  [COUNTRY_NAMES.BHUTAN]: { code: 'BT', phone: '+975', offsetHours: 6 },
  [COUNTRY_NAMES.BOLIVIA]: { code: 'BO', phone: '+591', offsetHours: -4 },
  [COUNTRY_NAMES.BOSNIA_AND_HERZEGOVINA]: {
    code: 'BA',
    phone: '+387',
    offsetHours: 1,
  },
  [COUNTRY_NAMES.BOTSWANA]: { code: 'BW', phone: '+267', offsetHours: 2 },
  [COUNTRY_NAMES.BOUVET_ISLAND]: { code: 'BV', phone: '+47', offsetHours: 0 },
  [COUNTRY_NAMES.BRAZIL]: { code: 'BR', phone: '+55', offsetHours: -3 },
  [COUNTRY_NAMES.BRITISH_INDIAN_OCEAN_TERRITORY]: {
    code: 'IO',
    phone: '+246',
    offsetHours: 6,
  },
  [COUNTRY_NAMES.BRITISH_VIRGIN_ISLANDS]: {
    code: 'VG',
    phone: '+1-284',
    offsetHours: -4,
  },
  [COUNTRY_NAMES.BRUNEI_DARUSSALAM]: {
    code: 'BN',
    phone: '+673',
    offsetHours: 8,
  },
  [COUNTRY_NAMES.BULGARIA]: { code: 'BG', phone: '+359', offsetHours: 2 },
  [COUNTRY_NAMES.BURKINA_FASO]: { code: 'BF', phone: '+226', offsetHours: 0 },
  [COUNTRY_NAMES.BURUNDI]: { code: 'BI', phone: '+257', offsetHours: 2 },
  [COUNTRY_NAMES.CAMBODIA]: { code: 'KH', phone: '+855', offsetHours: 7 },
  [COUNTRY_NAMES.CAMEROON]: { code: 'CM', phone: '+237', offsetHours: 1 },
  [COUNTRY_NAMES.CANADA]: { code: 'CA', phone: '+1', offsetHours: -5 },
  [COUNTRY_NAMES.CAPE_VERDE]: { code: 'CV', phone: '+238', offsetHours: -1 },
  [COUNTRY_NAMES.CAYMAN_ISLANDS]: {
    code: 'KY',
    phone: '+1-345',
    offsetHours: -5,
  },
  [COUNTRY_NAMES.CENTRAL_AFRICAN_REPUBLIC]: {
    code: 'CF',
    phone: '+236',
    offsetHours: 1,
  },
  [COUNTRY_NAMES.CHAD]: { code: 'TD', phone: '+235', offsetHours: 1 },
  [COUNTRY_NAMES.CHILE]: { code: 'CL', phone: '+56', offsetHours: -3 },
  [COUNTRY_NAMES.CHINA]: { code: 'CN', phone: '+86', offsetHours: 8 },
  [COUNTRY_NAMES.CHRISTMAS_ISLAND]: {
    code: 'CX',
    phone: '+61',
    offsetHours: 7,
  },
  [COUNTRY_NAMES.COCOS_KEELING_ISLANDS]: {
    code: 'CC',
    phone: '+61',
    offsetHours: 6.5,
  },
  [COUNTRY_NAMES.COLOMBIA]: { code: 'CO', phone: '+57', offsetHours: -5 },
  [COUNTRY_NAMES.COMOROS]: { code: 'KM', phone: '+269', offsetHours: 3 },
  [COUNTRY_NAMES.CONGO_DEMOCRATIC_REPUBLIC_OF_THE]: {
    code: 'CD',
    phone: '+243',
    offsetHours: 1,
  },
  [COUNTRY_NAMES.CONGO_REPUBLIC_OF_THE]: {
    code: 'CG',
    phone: '+242',
    offsetHours: 1,
  },
  [COUNTRY_NAMES.COOK_ISLANDS]: { code: 'CK', phone: '+682', offsetHours: -10 },
  [COUNTRY_NAMES.COSTA_RICA]: { code: 'CR', phone: '+506', offsetHours: -6 },
  [COUNTRY_NAMES.COTE_DIVOIRE]: { code: 'CI', phone: '+225', offsetHours: 0 },
  [COUNTRY_NAMES.CROATIA]: { code: 'HR', phone: '+385', offsetHours: 1 },
  [COUNTRY_NAMES.CUBA]: { code: 'CU', phone: '+53', offsetHours: -5 },
  [COUNTRY_NAMES.CURACAO]: { code: 'CW', phone: '+599', offsetHours: -4 },
  [COUNTRY_NAMES.CYPRUS]: { code: 'CY', phone: '+357', offsetHours: 2 },
  [COUNTRY_NAMES.CZECH_REPUBLIC]: { code: 'CZ', phone: '+420', offsetHours: 1 },
  [COUNTRY_NAMES.DENMARK]: { code: 'DK', phone: '+45', offsetHours: 1 },
  [COUNTRY_NAMES.DJIBOUTI]: { code: 'DJ', phone: '+253', offsetHours: 3 },
  [COUNTRY_NAMES.DOMINICA]: { code: 'DM', phone: '+1-767', offsetHours: -4 },
  [COUNTRY_NAMES.DOMINICAN_REPUBLIC]: {
    code: 'DO',
    phone: '+1-809',
    offsetHours: -4,
  },
  [COUNTRY_NAMES.ECUADOR]: { code: 'EC', phone: '+593', offsetHours: -5 },
  [COUNTRY_NAMES.EGYPT]: { code: 'EG', phone: '+20', offsetHours: 2 },
  [COUNTRY_NAMES.EL_SALVADOR]: { code: 'SV', phone: '+503', offsetHours: -6 },
  [COUNTRY_NAMES.EQUATORIAL_GUINEA]: {
    code: 'GQ',
    phone: '+240',
    offsetHours: 1,
  },
  [COUNTRY_NAMES.ERITREA]: { code: 'ER', phone: '+291', offsetHours: 3 },
  [COUNTRY_NAMES.ESTONIA]: { code: 'EE', phone: '+372', offsetHours: 2 },
  [COUNTRY_NAMES.ETHIOPIA]: { code: 'ET', phone: '+251', offsetHours: 3 },
  [COUNTRY_NAMES.FALKLAND_ISLANDS]: {
    code: 'FK',
    phone: '+500',
    offsetHours: -3,
  },
  [COUNTRY_NAMES.FAROE_ISLANDS]: { code: 'FO', phone: '+298', offsetHours: 0 },
  [COUNTRY_NAMES.FIJI]: { code: 'FJ', phone: '+679', offsetHours: 12 },
  [COUNTRY_NAMES.FINLAND]: { code: 'FI', phone: '+358', offsetHours: 2 },
  [COUNTRY_NAMES.FRANCE]: { code: 'FR', phone: '+33', offsetHours: 1 },
  [COUNTRY_NAMES.FRENCH_GUIANA]: { code: 'GF', phone: '+594', offsetHours: -3 },
  [COUNTRY_NAMES.FRENCH_POLYNESIA]: {
    code: 'PF',
    phone: '+689',
    offsetHours: -10,
  },
  [COUNTRY_NAMES.FRENCH_SOUTHERN_TERRITORIES]: {
    code: 'TF',
    phone: '+262',
    offsetHours: 5,
  },
  [COUNTRY_NAMES.GABON]: { code: 'GA', phone: '+241', offsetHours: 1 },
  [COUNTRY_NAMES.GAMBIA]: { code: 'GM', phone: '+220', offsetHours: 0 },
  [COUNTRY_NAMES.GEORGIA]: { code: 'GE', phone: '+995', offsetHours: 4 },
  [COUNTRY_NAMES.GERMANY]: { code: 'DE', phone: '+49', offsetHours: 1 },
  [COUNTRY_NAMES.GHANA]: { code: 'GH', phone: '+233', offsetHours: 0 },
  [COUNTRY_NAMES.GIBRALTAR]: { code: 'GI', phone: '+350', offsetHours: 1 },
  [COUNTRY_NAMES.GREECE]: { code: 'GR', phone: '+30', offsetHours: 2 },
  [COUNTRY_NAMES.GREENLAND]: { code: 'GL', phone: '+299', offsetHours: -3 },
  [COUNTRY_NAMES.GRENADA]: { code: 'GD', phone: '+1-473', offsetHours: -4 },
  [COUNTRY_NAMES.GUADELOUPE]: { code: 'GP', phone: '+590', offsetHours: -4 },
  [COUNTRY_NAMES.GUAM]: { code: 'GU', phone: '+1-671', offsetHours: 10 },
  [COUNTRY_NAMES.GUATEMALA]: { code: 'GT', phone: '+502', offsetHours: -6 },
  [COUNTRY_NAMES.GUERNSEY]: { code: 'GG', phone: '+44', offsetHours: 0 },
  [COUNTRY_NAMES.GUINEA]: { code: 'GN', phone: '+224', offsetHours: 0 },
  [COUNTRY_NAMES.GUINEA_BISSAU]: { code: 'GW', phone: '+245', offsetHours: 0 },
  [COUNTRY_NAMES.GUYANA]: { code: 'GY', phone: '+592', offsetHours: -4 },
  [COUNTRY_NAMES.HAITI]: { code: 'HT', phone: '+509', offsetHours: -5 },
  [COUNTRY_NAMES.HEARD_ISLAND_AND_MCDONALD_ISLANDS]: {
    code: 'HM',
    phone: '+672',
    offsetHours: 5,
  },
  [COUNTRY_NAMES.HONDURAS]: { code: 'HN', phone: '+504', offsetHours: -6 },
  [COUNTRY_NAMES.HONG_KONG]: { code: 'HK', phone: '+852', offsetHours: 8 },
  [COUNTRY_NAMES.HUNGARY]: { code: 'HU', phone: '+36', offsetHours: 1 },
  [COUNTRY_NAMES.ICELAND]: { code: 'IS', phone: '+354', offsetHours: 0 },
  [COUNTRY_NAMES.INDIA]: { code: 'IN', phone: '+91', offsetHours: 5.5 },
  [COUNTRY_NAMES.INDONESIA]: { code: 'ID', phone: '+62', offsetHours: 7 },
  [COUNTRY_NAMES.IRAQ]: { code: 'IQ', phone: '+964', offsetHours: 3 },
  [COUNTRY_NAMES.IRELAND]: { code: 'IE', phone: '+353', offsetHours: 0 },
  [COUNTRY_NAMES.ISLE_OF_MAN]: { code: 'IM', phone: '+44', offsetHours: 0 },
  [COUNTRY_NAMES.ISRAEL]: { code: 'IL', phone: '+972', offsetHours: 2 },
  [COUNTRY_NAMES.ITALY]: { code: 'IT', phone: '+39', offsetHours: 1 },
  [COUNTRY_NAMES.JAMAICA]: { code: 'JM', phone: '+1-876', offsetHours: -5 },
  [COUNTRY_NAMES.JAPAN]: { code: 'JP', phone: '+81', offsetHours: 9 },
  [COUNTRY_NAMES.JERSEY]: { code: 'JE', phone: '+44', offsetHours: 0 },
  [COUNTRY_NAMES.JORDAN]: { code: 'JO', phone: '+962', offsetHours: 3 },
  [COUNTRY_NAMES.KAZAKHSTAN]: { code: 'KZ', phone: '+7', offsetHours: 5 },
  [COUNTRY_NAMES.KENYA]: { code: 'KE', phone: '+254', offsetHours: 3 },
  [COUNTRY_NAMES.KIRIBATI]: { code: 'KI', phone: '+686', offsetHours: 14 },
  [COUNTRY_NAMES.KOSOVO]: { code: 'XK', phone: '+383', offsetHours: 1 },
  [COUNTRY_NAMES.KUWAIT]: { code: 'KW', phone: '+965', offsetHours: 3 },
  [COUNTRY_NAMES.KYRGYZSTAN]: { code: 'KG', phone: '+996', offsetHours: 6 },
  [COUNTRY_NAMES.LAOS]: { code: 'LA', phone: '+856', offsetHours: 7 },
  [COUNTRY_NAMES.LATVIA]: { code: 'LV', phone: '+371', offsetHours: 2 },
  [COUNTRY_NAMES.LEBANON]: { code: 'LB', phone: '+961', offsetHours: 2 },
  [COUNTRY_NAMES.LESOTHO]: { code: 'LS', phone: '+266', offsetHours: 2 },
  [COUNTRY_NAMES.LIBERIA]: { code: 'LR', phone: '+231', offsetHours: 0 },
  [COUNTRY_NAMES.LIBYA]: { code: 'LY', phone: '+218', offsetHours: 2 },
  [COUNTRY_NAMES.LIECHTENSTEIN]: { code: 'LI', phone: '+423', offsetHours: 1 },
  [COUNTRY_NAMES.LITHUANIA]: { code: 'LT', phone: '+370', offsetHours: 2 },
  [COUNTRY_NAMES.LUXEMBOURG]: { code: 'LU', phone: '+352', offsetHours: 1 },
  [COUNTRY_NAMES.MACAO]: { code: 'MO', phone: '+853', offsetHours: 8 },
  [COUNTRY_NAMES.MADAGASCAR]: { code: 'MG', phone: '+261', offsetHours: 3 },
  [COUNTRY_NAMES.MALAWI]: { code: 'MW', phone: '+265', offsetHours: 2 },
  [COUNTRY_NAMES.MALAYSIA]: {
    code: 'MY',
    phone: '+60',
    offsetHours: 8,
    currency: CURRENCY_CODE.MYR,
    currencySymbol: CURRENCY_SYMBOL_MAPPING[CURRENCY_CODE.MYR],
  },
  [COUNTRY_NAMES.MALDIVES]: { code: 'MV', phone: '+960', offsetHours: 5 },
  [COUNTRY_NAMES.MALI]: { code: 'ML', phone: '+223', offsetHours: 0 },
  [COUNTRY_NAMES.MALTA]: { code: 'MT', phone: '+356', offsetHours: 1 },
  [COUNTRY_NAMES.MARSHALL_ISLANDS]: {
    code: 'MH',
    phone: '+692',
    offsetHours: 12,
  },
  [COUNTRY_NAMES.MARTINIQUE]: { code: 'MQ', phone: '+596', offsetHours: -4 },
  [COUNTRY_NAMES.MAURITANIA]: { code: 'MR', phone: '+222', offsetHours: 0 },
  [COUNTRY_NAMES.MAURITIUS]: { code: 'MU', phone: '+230', offsetHours: 4 },
  [COUNTRY_NAMES.MAYOTTE]: { code: 'YT', phone: '+262', offsetHours: 3 },
  [COUNTRY_NAMES.MEXICO]: { code: 'MX', phone: '+52', offsetHours: -6 },
  [COUNTRY_NAMES.MICRONESIA]: { code: 'FM', phone: '+691', offsetHours: 11 },
  [COUNTRY_NAMES.MOLDOVA]: { code: 'MD', phone: '+373', offsetHours: 2 },
  [COUNTRY_NAMES.MONACO]: { code: 'MC', phone: '+377', offsetHours: 1 },
  [COUNTRY_NAMES.MONGOLIA]: { code: 'MN', phone: '+976', offsetHours: 8 },
  [COUNTRY_NAMES.MONTENEGRO]: { code: 'ME', phone: '+382', offsetHours: 1 },
  [COUNTRY_NAMES.MONTSERRAT]: { code: 'MS', phone: '+1-664', offsetHours: -4 },
  [COUNTRY_NAMES.MOROCCO]: { code: 'MA', phone: '+212', offsetHours: 0 },
  [COUNTRY_NAMES.MOZAMBIQUE]: { code: 'MZ', phone: '+258', offsetHours: 2 },
  [COUNTRY_NAMES.MYANMAR]: { code: 'MM', phone: '+95', offsetHours: 6.5 },
  [COUNTRY_NAMES.NAMIBIA]: { code: 'NA', phone: '+264', offsetHours: 2 },
  [COUNTRY_NAMES.NAURU]: { code: 'NR', phone: '+674', offsetHours: 12 },
  [COUNTRY_NAMES.NEPAL]: { code: 'NP', phone: '+977', offsetHours: 5.75 },
  [COUNTRY_NAMES.NETHERLANDS]: { code: 'NL', phone: '+31', offsetHours: 1 },
  [COUNTRY_NAMES.NEW_CALEDONIA]: { code: 'NC', phone: '+687', offsetHours: 11 },
  [COUNTRY_NAMES.NEW_ZEALAND]: { code: 'NZ', phone: '+64', offsetHours: 12 },
  [COUNTRY_NAMES.NICARAGUA]: { code: 'NI', phone: '+505', offsetHours: -6 },
  [COUNTRY_NAMES.NIGER]: { code: 'NE', phone: '+227', offsetHours: 1 },
  [COUNTRY_NAMES.NIGERIA]: { code: 'NG', phone: '+234', offsetHours: 1 },
  [COUNTRY_NAMES.NIUE]: { code: 'NU', phone: '+683', offsetHours: -11 },
  [COUNTRY_NAMES.NORFOLK_ISLAND]: {
    code: 'NF',
    phone: '+672',
    offsetHours: 11,
  },
  [COUNTRY_NAMES.NORTH_MACEDONIA]: {
    code: 'MK',
    phone: '+389',
    offsetHours: 1,
  },
  [COUNTRY_NAMES.NORTHERN_MARIANA_ISLANDS]: {
    code: 'MP',
    phone: '+1-670',
    offsetHours: 10,
  },
  [COUNTRY_NAMES.NORWAY]: { code: 'NO', phone: '+47', offsetHours: 1 },
  [COUNTRY_NAMES.OMAN]: { code: 'OM', phone: '+968', offsetHours: 4 },
  [COUNTRY_NAMES.PAKISTAN]: { code: 'PK', phone: '+92', offsetHours: 5 },
  [COUNTRY_NAMES.PALAU]: { code: 'PW', phone: '+680', offsetHours: 9 },
  [COUNTRY_NAMES.PALESTINE]: { code: 'PS', phone: '+970', offsetHours: 2 },
  [COUNTRY_NAMES.PANAMA]: { code: 'PA', phone: '+507', offsetHours: -5 },
  [COUNTRY_NAMES.PAPUA_NEW_GUINEA]: {
    code: 'PG',
    phone: '+675',
    offsetHours: 10,
  },
  [COUNTRY_NAMES.PARAGUAY]: { code: 'PY', phone: '+595', offsetHours: -4 },
  [COUNTRY_NAMES.PERU]: { code: 'PE', phone: '+51', offsetHours: -5 },
  [COUNTRY_NAMES.PHILIPPINES]: { code: 'PH', phone: '+63', offsetHours: 8 },
  [COUNTRY_NAMES.PITCAIRN]: { code: 'PN', phone: '+870', offsetHours: -8 },
  [COUNTRY_NAMES.POLAND]: { code: 'PL', phone: '+48', offsetHours: 1 },
  [COUNTRY_NAMES.PORTUGAL]: { code: 'PT', phone: '+351', offsetHours: 0 },
  [COUNTRY_NAMES.PUERTO_RICO]: { code: 'PR', phone: '+1', offsetHours: -4 },
  [COUNTRY_NAMES.QATAR]: { code: 'QA', phone: '+974', offsetHours: 3 },
  [COUNTRY_NAMES.REUNION]: { code: 'RE', phone: '+262', offsetHours: 4 },
  [COUNTRY_NAMES.ROMANIA]: { code: 'RO', phone: '+40', offsetHours: 2 },
  [COUNTRY_NAMES.RUSSIA]: { code: 'RU', phone: '+7', offsetHours: 3 },
  [COUNTRY_NAMES.RWANDA]: { code: 'RW', phone: '+250', offsetHours: 2 },
  [COUNTRY_NAMES.ST_BARTHELEMY]: { code: 'BL', phone: '+590', offsetHours: -4 },
  [COUNTRY_NAMES.ST_HELENA]: { code: 'SH', phone: '+290', offsetHours: 0 },
  [COUNTRY_NAMES.ST_KITTS_AND_NEVIS]: {
    code: 'KN',
    phone: '+1-869',
    offsetHours: -4,
  },
  [COUNTRY_NAMES.ST_LUCIA]: { code: 'LC', phone: '+1-758', offsetHours: -4 },
  [COUNTRY_NAMES.ST_MARTIN]: { code: 'MF', phone: '+590', offsetHours: -4 },
  [COUNTRY_NAMES.ST_PIERRE_AND_MIQUELON]: {
    code: 'PM',
    phone: '+508',
    offsetHours: -3,
  },
  [COUNTRY_NAMES.ST_VINCENT_AND_THE_GRENADINES]: {
    code: 'VC',
    phone: '+1-784',
    offsetHours: -4,
  },
  [COUNTRY_NAMES.SAMOA]: { code: 'WS', phone: '+685', offsetHours: 13 },
  [COUNTRY_NAMES.SAN_MARINO]: { code: 'SM', phone: '+378', offsetHours: 1 },
  [COUNTRY_NAMES.SAO_TOME_AND_PRINCIPE]: {
    code: 'ST',
    phone: '+239',
    offsetHours: 0,
  },
  [COUNTRY_NAMES.SAUDI_ARABIA]: { code: 'SA', phone: '+966', offsetHours: 3 },
  [COUNTRY_NAMES.SENEGAL]: { code: 'SN', phone: '+221', offsetHours: 0 },
  [COUNTRY_NAMES.SERBIA]: { code: 'RS', phone: '+381', offsetHours: 1 },
  [COUNTRY_NAMES.SEYCHELLES]: { code: 'SC', phone: '+248', offsetHours: 4 },
  [COUNTRY_NAMES.SIERRA_LEONE]: { code: 'SL', phone: '+232', offsetHours: 0 },
  [COUNTRY_NAMES.SINT_MAARTEN]: {
    code: 'SX',
    phone: '+1-721',
    offsetHours: -4,
  },
  [COUNTRY_NAMES.SLOVAKIA]: { code: 'SK', phone: '+421', offsetHours: 1 },
  [COUNTRY_NAMES.SLOVENIA]: { code: 'SI', phone: '+386', offsetHours: 1 },
  [COUNTRY_NAMES.SOLOMON_ISLANDS]: {
    code: 'SB',
    phone: '+677',
    offsetHours: 11,
  },
  [COUNTRY_NAMES.SOMALIA]: { code: 'SO', phone: '+252', offsetHours: 3 },
  [COUNTRY_NAMES.SOUTH_AFRICA]: { code: 'ZA', phone: '+27', offsetHours: 2 },
  [COUNTRY_NAMES.SOUTH_GEORGIA_AND_THE_SOUTH_SANDWICH_ISLANDS]: {
    code: 'GS',
    phone: '+500',
    offsetHours: -2,
  },
  [COUNTRY_NAMES.SOUTH_KOREA]: { code: 'KR', phone: '+82', offsetHours: 9 },
  [COUNTRY_NAMES.SOUTH_SUDAN]: { code: 'SS', phone: '+211', offsetHours: 3 },
  [COUNTRY_NAMES.SPAIN]: { code: 'ES', phone: '+34', offsetHours: 1 },
  [COUNTRY_NAMES.SRI_LANKA]: { code: 'LK', phone: '+94', offsetHours: 5.5 },
  [COUNTRY_NAMES.SUDAN]: { code: 'SD', phone: '+249', offsetHours: 2 },
  [COUNTRY_NAMES.SURINAME]: { code: 'SR', phone: '+597', offsetHours: -3 },
  [COUNTRY_NAMES.SVALBARD_AND_JAN_MAYEN]: {
    code: 'SJ',
    phone: '+47',
    offsetHours: 1,
  },
  [COUNTRY_NAMES.SWAZILAND]: { code: 'SZ', phone: '+268', offsetHours: 2 },
  [COUNTRY_NAMES.SWEDEN]: { code: 'SE', phone: '+46', offsetHours: 1 },
  [COUNTRY_NAMES.SWITZERLAND]: { code: 'CH', phone: '+41', offsetHours: 1 },
  [COUNTRY_NAMES.SYRIA]: { code: 'SY', phone: '+963', offsetHours: 2 },
  [COUNTRY_NAMES.TAIWAN]: { code: 'TW', phone: '+886', offsetHours: 8 },
  [COUNTRY_NAMES.TAJIKISTAN]: { code: 'TJ', phone: '+992', offsetHours: 5 },
  [COUNTRY_NAMES.THAILAND]: { code: 'TH', phone: '+66', offsetHours: 7 },
  [COUNTRY_NAMES.TIMOR_LESTE]: { code: 'TL', phone: '+670', offsetHours: 9 },
  [COUNTRY_NAMES.TOGO]: { code: 'TG', phone: '+228', offsetHours: 0 },
  [COUNTRY_NAMES.TOKELAU]: { code: 'TK', phone: '+690', offsetHours: -11 },
  [COUNTRY_NAMES.TONGA]: { code: 'TO', phone: '+676', offsetHours: 13 },
  [COUNTRY_NAMES.TRINIDAD_AND_TOBAGO]: {
    code: 'TT',
    phone: '+1-868',
    offsetHours: -4,
  },
  [COUNTRY_NAMES.TUNISIA]: { code: 'TN', phone: '+216', offsetHours: 1 },
  [COUNTRY_NAMES.TURKEY]: { code: 'TR', phone: '+90', offsetHours: 3 },
  [COUNTRY_NAMES.TURKMENISTAN]: { code: 'TM', phone: '+993', offsetHours: 5 },
  [COUNTRY_NAMES.TURKS_AND_CAICOS_ISLANDS]: {
    code: 'TC',
    phone: '+1-649',
    offsetHours: -5,
  },
  [COUNTRY_NAMES.TUVALU]: { code: 'TV', phone: '+688', offsetHours: 12 },
  [COUNTRY_NAMES.UGANDA]: { code: 'UG', phone: '+256', offsetHours: 3 },
  [COUNTRY_NAMES.UKRAINE]: { code: 'UA', phone: '+380', offsetHours: 2 },
  [COUNTRY_NAMES.UNITED_ARAB_EMIRATES]: {
    code: 'AE',
    phone: '+971',
    offsetHours: 4,
  },
  [COUNTRY_NAMES.UNITED_KINGDOM]: {
    code: 'GB',
    phone: '+44',
    offsetHours: 0,
    currency: CURRENCY_CODE.GBP,
    currencySymbol: CURRENCY_SYMBOL_MAPPING[CURRENCY_CODE.GBP],
  },
  [COUNTRY_NAMES.UNITED_REPUBLIC_OF_TANZANIA]: {
    code: 'TZ',
    phone: '+255',
    offsetHours: 3,
  },
  [COUNTRY_NAMES.AMERICA]: {
    code: 'US',
    phone: '+1',
    offsetHours: -5,
    fullName: 'United States of America',
    currency: CURRENCY_CODE.USD,
    currencySymbol: CURRENCY_SYMBOL_MAPPING[CURRENCY_CODE.USD],
  },
  [COUNTRY_NAMES.URUGUAY]: { code: 'UY', phone: '+598', offsetHours: -3 },
  [COUNTRY_NAMES.US_VIRGIN_ISLANDS]: {
    code: 'VI',
    phone: '+1-340',
    offsetHours: -4,
  },
  [COUNTRY_NAMES.UZBEKISTAN]: { code: 'UZ', phone: '+998', offsetHours: 5 },
  [COUNTRY_NAMES.VANUATU]: { code: 'VU', phone: '+678', offsetHours: 11 },
  [COUNTRY_NAMES.VATICAN_CITY_STATE]: {
    code: 'VA',
    phone: '+379',
    offsetHours: 1,
  },
  [COUNTRY_NAMES.VENEZUELA]: { code: 'VE', phone: '+58', offsetHours: -4.5 },
  [COUNTRY_NAMES.VIETNAM]: { code: 'VN', phone: '+84', offsetHours: 7 },
  [COUNTRY_NAMES.WALLIS_AND_FUTUNA]: {
    code: 'WF',
    phone: '+681',
    offsetHours: 12,
  },
  [COUNTRY_NAMES.WESTERN_SAHARA]: { code: 'EH', phone: '+212', offsetHours: 0 },
  [COUNTRY_NAMES.YEMEN]: { code: 'YE', phone: '+967', offsetHours: 3 },
  [COUNTRY_NAMES.ZAMBIA]: { code: 'ZM', phone: '+260', offsetHours: 2 },
  [COUNTRY_NAMES.ZIMBABWE]: { code: 'ZW', phone: '+263', offsetHours: 2 },
};

const EURO_COUNTRIES = [
  COUNTRY_NAMES.AUSTRIA,
  COUNTRY_NAMES.BELGIUM,
  COUNTRY_NAMES.CYPRUS,
  COUNTRY_NAMES.ESTONIA,
  COUNTRY_NAMES.FINLAND,
  COUNTRY_NAMES.FRANCE,
  COUNTRY_NAMES.GERMANY,
  COUNTRY_NAMES.GREECE,
  COUNTRY_NAMES.IRELAND,
  COUNTRY_NAMES.ITALY,
  COUNTRY_NAMES.LATVIA,
  COUNTRY_NAMES.LITHUANIA,
  COUNTRY_NAMES.LUXEMBOURG,
  COUNTRY_NAMES.MALTA,
  COUNTRY_NAMES.NETHERLANDS,
  COUNTRY_NAMES.PORTUGAL,
  COUNTRY_NAMES.SLOVAKIA,
  COUNTRY_NAMES.SLOVENIA,
  COUNTRY_NAMES.SPAIN,
  COUNTRY_NAMES.ANDORRA,
  COUNTRY_NAMES.KOSOVO,
  COUNTRY_NAMES.MONACO,
  COUNTRY_NAMES.MONTENEGRO,
  COUNTRY_NAMES.SAN_MARINO,
  COUNTRY_NAMES.VATICAN_CITY_STATE,
  COUNTRY_NAMES.ALBANIA,
  COUNTRY_NAMES.ARMENIA,
  COUNTRY_NAMES.BOSNIA_AND_HERZEGOVINA,
  COUNTRY_NAMES.BULGARIA,
  COUNTRY_NAMES.CROATIA,
  COUNTRY_NAMES.CZECH_REPUBLIC,
  COUNTRY_NAMES.DENMARK,
  COUNTRY_NAMES.GEORGIA,
  COUNTRY_NAMES.HUNGARY,
  COUNTRY_NAMES.MOLDOVA,
  COUNTRY_NAMES.NORTH_MACEDONIA,
  COUNTRY_NAMES.POLAND,
  COUNTRY_NAMES.ROMANIA,
  COUNTRY_NAMES.SERBIA,
  COUNTRY_NAMES.SWEDEN,
];

EURO_COUNTRIES.forEach((euroCountry) => {
  const country = COUNTRY_LIST[euroCountry];
  if (!isEmptyValue(country)) {
    country.currency = CURRENCY_CODE.EUR;
    country.currencySymbol = CURRENCY_SYMBOL_MAPPING[CURRENCY_CODE.EUR];
  }
});

export { COUNTRY_LIST };
