import { capitalize, get } from 'lodash';
import React, { useEffect, useReducer, useState } from 'react';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { makeStyles } from '@material-ui/core/styles/index';

import {
  ArtTrack,
  AttachFile,
  LocalShipping,
  Warning,
  OpenInNew,
} from '@material-ui/icons';

import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  Divider,
  FormControlLabel,
  Grid,
  Link,
  Paper,
  Tab,
  Tabs,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';

import ColoredWordsSentence from '../components/ColoredWordsSentence';
import FilesUploadButton from '../components/FilesUploadButton';
import OrderReadyTimeline from '../components/OrderReadyTimeline';
import DownloadAllFilesButton from '../components/buttons/DownloadAllFilesButton';
import ShipmentTrackingIcon from '../components/icons/ShipmentTrackingIcon';
import DownloadableFileWithActions from '../components/list-items/DownloadableFileWithActions';
import CollectedInfoPopup from '../components/popups/CollectedInfoPopup';
import ItemCollectionNoticePopup from '../components/popups/ItemCollectionNoticePopup';
import MultiCheckoutDeliverPopup from '../components/popups/MultiCheckoutDeliverPopup';
import ThankYouFeedbackPopup from '../components/popups/ThankYouFeedbackPopup';
import UserFeedbackPopup from '../components/popups/UserFeedbackPopup';
import YesNoPopup from '../components/popups/YesNoPopup';
import { FlexColumn, FlexRow } from '../components/layouts/FlexLayouts';
import { FtrButton } from '../components/ftr-components';
import {
  MCTIssueDOButton,
  MCTItemCollectedButton,
} from '../components/buttons/actionButtons/MCTActionButton';
import OrderReadyStatusDisplay from './orderReady/OrderReadyStatusDisplay';
import AdminAddEditRequiredQCMaterialsPopup from './project-order-ready/AdminAddEditRequiredQCMaterialsPopup';
import AdminUploadFilesTab from './project-order-ready/AdminUploadFilesTab';
import GuestLinkToPageORM from './project-order-ready/GuestLinkToPageORM';
import ItemOrderReadyReview from './project-order-ready/ItemOrderReadyReview';
import ManageSupplierInvoice from './project-order-ready/ManageSupplierInvoice';
import NextSteps from './project-order-ready/NextSteps';
import ShipmentNotes from './project-order-ready/ShipmentNotes';
import SupplierOrmFileDownloadDisplayWithTracking from './project-order-ready/SupplierOrmFileDownloadDisplayWithTracking';

import TruckIcon from '../assets/icons/truck.svg';
import DeliveryInstructionsVertical from '../assets/img/order-ready-management-delivery-instructions-vertical.png';
import DeliveryInstructions from '../assets/img/order-ready-management-delivery-instructions.png';

import withRepeatItemOrmDetailsPopupHOC from '../hocs/withRepeatItemOrmDetailsPopupHOC';

import { getItemsByIDList } from '../apis/itemApi';
import { deleteItemCollectionNotice } from '../apis/itemCollectionNoticeApi';
import {
  getSupplierTrackingsByItemIDs,
  getTrackingsByItemIDs,
} from '../apis/multiCheckoutApi';
import {
  deleteOrderReadyResource,
  getProjectOrderReadyHistory,
  getProjectOrderReadyInfo,
  updateItemOrderReadyStatus,
  updateOrderShipped,
  updateProjectOrderReadyInfo,
} from '../apis/orderReadyApi';
import { generateItemCollectionNotice } from '../apis/pdfApi';
import { getUserInfo } from '../apis/userApi';
import { getFeedbackOfUsers } from '../apis/userFeedbackApi';

import { useQueryParams } from '../hooks/useQueryParamsHook';

import { getSubmittedProjectOrderReadyCountAction } from '../actions/orderReady';

import { getUserSelector } from '../selectors/userSelector';

import { isEmptyValue } from '../utils/commonUtils';
import { formatDateWithTime } from '../utils/dateTimeUtils';
import {
  extractFileNameWithoutTimestampFromUrl,
  isPdfFile,
} from '../utils/fileUtils';
import {
  uncollectedItemsFilter,
  undeliveredItemsFilter,
} from '../utils/multiCheckoutTrackingUtils';
import { openInNewTab } from '../utils/navigationUtils';
import { uploadOrderReadyResource } from '../utils/orderReadyUtils';
import { isAdminOrHigherRole } from '../utils/roleUtils';

import {
  notifyError,
  notifyOngoing,
  notifySuccess,
  notifyWarning,
  updateNotification,
} from '../services/notificationService';

import ProjectOrderReadyContext from '../context/ProjectOrderReadyContext';

import { ROLE_TYPES } from '../constants';
import { COUNTRY_NAMES } from '../constants/countryConstants';
import {
  DELIVERY_STARTED_OR_COMPLETE_STATUSES,
  DELIVERY_STATUSES,
  ORDER_READY_RESOURCE_TYPES,
  ORDER_READY_STATUS,
  ORDER_READY_STATUS_MAPPING,
} from '../constants/orderReadyConstants';
import { QC_NO_QC_REPORT } from '../constants/projectConstants';
import { USER_FEEDBACK_QUESTION_LIST } from '../constants/userFeedbackConstants';

import { colors } from '../palette';

import { generatePresignedUrl } from '../services/s3Service';
import { isNumber } from '../utils/numberUtils';

// --------------------------------------------------

const useStyles = makeStyles((theme) => ({
  body: {
    padding: '1.6rem',
    paddingTop: '1.2rem',
  },
  heading: {
    fontWeight: 'bold',
  },
  tabRoot: {
    opacity: 1,
    '&:hover': {
      color: 'white',
      backgroundColor: colors.blue050,
    },
  },
  selectedTab: {
    '&:hover': {
      backgroundColor: colors.secondaryBlue,
      color: colors.blue050,
    },
    backgroundColor: colors.secondaryBlue,
    color: colors.blue050,
  },
  disabledTab: {
    color: colors.lightGray,
  },
  iconLabelWrapper: {
    flexDirection: 'row',
    gap: 10,
    '& :nth-child(1)': {
      marginTop: 6,
    },
  },
  labelContainer: {
    width: 'auto',
    padding: 0,
  },
  labelIcon: {
    minHeight: 0,
    paddingTop: 4,
  },
  circularProgress: {
    marginLeft: 0,
    marginRight: theme.spacing.unit,
    color: 'inherit',
  },
  container: {
    padding: 0,
    display: 'flex',
    flexDirection: 'column',
    gap: '0.5rem',
  },
  editShipmentNotes: {
    marginRight: '10px',
  },
  link: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
}));

const TAB_KEYS = {
  REVIEW: 'Review',
  DELIVER: 'Deliver',
  ADMIN_UPLOAD_FILES: 'Admin Upload Files',
};

const TABS = [TAB_KEYS.REVIEW, TAB_KEYS.DELIVER, TAB_KEYS.ADMIN_UPLOAD_FILES];

function CloneProps(props) {
  const { children, ...other } = props;
  return children(other);
}

function ProjectOrderReady(props) {
  const classes = useStyles();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const queryParams = useQueryParams();
  const dispatch = useDispatch();

  const { userID, role: userRole } = useSelector(getUserSelector);

  const { match, updateRepeatItemOrmDetailsPopupHOCState = () => {} } = props;

  const { projectID } = match.params;

  const supplierID = queryParams.get('supplierID') || userID;
  const poAcknowledgedVersion = queryParams.get('version') || 1;

  const [localState, updateLocalState] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      addAnotherRequiredImage: false,
      addPredefinedRequiredImages: [],
      additionalComment: {},
      adminEditStatus: '',
      comments: {},
      deleteItemCollectionNotice: null,
      deleteResource: null,
      errors: {},
      existingShipmentNotes: null,
      hasQcReportsRequired: false,
      imageUrlList: [],
      isNonSgpSupplier: false,
      itemOrderReadyList: [],
      itemCollectionNotices: [],
      itemsData: [],
      multipleCheckoutTrackings: [],
      newImageDescription: '',
      newImageTitle: '',
      orderReadyHistory: [],
      projectOrderReadyID: null,
      projectOrderReadyInfo: {},
      selectedItemOrderReady: null,
      selectedTab: 0,
      persistSelectedTab: false,
      shipmentNotes: null,
      showAdminEditStatusPopup: false,
      showDeleteFilePopup: false,
      showEditShippingNotesContainer: false,
      showImageIndex: 0,
      showImageView: false,
      supplierInvoicesInfo: [],
      updatingItemOrderReadyStatus: false,
    }
  );

  const [requiredQcMaterialState, updateRequiredQcMaterialState] = useReducer(
    (prev, next) => {
      if (isEmptyValue(next)) {
        return {
          open: false,
        };
      }
      return { ...prev, ...next };
    },
    {
      showPopup: false,
      selectedItemOrderReady: null,
      editMode: false,
      resource: null,
    }
  );

  const [showFeedbackPopup, setShowFeedbackPopup] = useState(false);
  const [showThankYouFeedbackPopup, setShowThankYouFeedbackPopup] =
    useState(false);
  const [showItemCollectionPopup, setShowItemCollectionPopup] = useState(false);
  const [showDeliverPopup, setShowDeliverPopup] = useState(false);
  const [showCollectedInfoPopup, setShowCollectedInfoPopup] = useState(false);
  const [fileUrlList, setFileUrlList] = useState([]);
  const [itemFilesMap, setItemFilesMap] = useState({});
  const [orderReadyLoading, setOrderReadyLoading] = useState(false);
  const [
    selectedMultipleCheckoutTracking,
    setSelectedMultipleCheckoutTracking,
  ] = useState({});

  const areSomeItemsDelivered = localState.itemOrderReadyList.some(
    (orderReady) => ORDER_READY_STATUS.DELIVERED === orderReady.info.status
  );
  const isDeliveryInTransitOrComplete =
    DELIVERY_STARTED_OR_COMPLETE_STATUSES.includes(
      localState.projectOrderReadyStatus
    );
  const hasViewedDeliveryDocIfRequired =
    localState.hasDownloadDeliverFiles ||
    localState.noDeliveryDocRequired ||
    isAdminOrHigherRole(userRole);

  const renderItemCollectedButtons = React.useMemo(() => {
    return localState.multipleCheckoutTrackings.map(
      (multipleCheckoutTracking) => {
        const uncollectedItems = multipleCheckoutTracking.acceptedItems.filter(
          uncollectedItemsFilter
        );
        return (
          <div
            key={multipleCheckoutTracking.id}
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              gap: '16px',
              marginBottom: '10px',
            }}
          >
            <div>
              <MCTItemCollectedButton
                onClick={() => {
                  setShowCollectedInfoPopup(true);
                  setSelectedMultipleCheckoutTracking(multipleCheckoutTracking);
                }}
                acceptedItems={uncollectedItems}
              />
            </div>
            {uncollectedItems.length > 0 && (
              <div>{`For Tracking ID: ${multipleCheckoutTracking.id}, Reference Name: ${multipleCheckoutTracking.referenceName}`}</div>
            )}
          </div>
        );
      }
    );
  }, [localState.multipleCheckoutTrackings]);

  const issueDOButtons = React.useMemo(() => {
    return localState.multipleCheckoutTrackings.map(
      (multipleCheckoutTracking) => {
        const undeliveredItems = multipleCheckoutTracking.acceptedItems.filter(
          undeliveredItemsFilter
        );
        return (
          <div
            key={multipleCheckoutTracking.id}
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              gap: '16px',
            }}
          >
            <div>
              <MCTIssueDOButton
                onClick={() => {
                  setShowDeliverPopup(true);
                  setSelectedMultipleCheckoutTracking(multipleCheckoutTracking);
                }}
                acceptedItems={undeliveredItems}
              />
            </div>
            {undeliveredItems.length > 0 && (
              <div>{`For Tracking ID: ${multipleCheckoutTracking.id}, Reference Name: ${multipleCheckoutTracking.referenceName}`}</div>
            )}
          </div>
        );
      }
    );
  }, [localState.multipleCheckoutTrackings]);

  // -----------------------------------------------------------------------------------------------

  const loadOrderReadyInfo = async () => {
    const allProjectOrderReadyInfo = await getProjectOrderReadyInfo({
      projectID,
      supplierID,
      poAcknowledgedVersion,
    }).catch(() => null);

    if (isEmptyValue(allProjectOrderReadyInfo)) {
      return;
    }

    const projectOrderReadyInfo = allProjectOrderReadyInfo.projectOrderReady;
    const projectOrderReadyID = get(projectOrderReadyInfo, [
      'info',
      'projectOrderReadyID',
    ]);
    const orderReadyHistory = await getProjectOrderReadyHistory({
      projectOrderReadyID,
    });
    const projectOrderReadyStatus = get(projectOrderReadyInfo, [
      'info',
      'status',
    ]);
    const shipmentNotes = get(projectOrderReadyInfo, ['info', 'shipmentNotes']);
    const existingShipmentNotes = shipmentNotes;
    const itemOrderReadyList = allProjectOrderReadyInfo.itemOrderReady;

    const projectQcReports = get(projectOrderReadyInfo, [
      'info',
      'qcReports',
      'main',
    ]);
    const projectQcReportsAddOns = get(projectOrderReadyInfo, [
      'info',
      'qcReports',
      'addOns',
    ]);
    const hasQcReportsRequired =
      !isEmptyValue(projectQcReports) && projectQcReports !== QC_NO_QC_REPORT;
    const allItemsVerified =
      itemOrderReadyList.some(
        (ior) => ORDER_READY_STATUS.VERIFIED === ior.info.status
      ) &&
      itemOrderReadyList.every((ior) =>
        [ORDER_READY_STATUS.VERIFIED, ...DELIVERY_STATUSES].includes(
          ior.info.status
        )
      );
    const hasItemNotStarted = itemOrderReadyList.some((ior) =>
      [ORDER_READY_STATUS.NOT_STARTED, ORDER_READY_STATUS.NEED_UPDATE].includes(
        ior.info.status
      )
    );
    const deliverFiles = (projectOrderReadyInfo.resources || []).filter(
      (resource) =>
        [
          ORDER_READY_RESOURCE_TYPES.DELIVERY_ORDER,
          ORDER_READY_RESOURCE_TYPES.WAYBILL,
        ].includes(resource.type)
    );
    const hasDownloadDeliverFiles =
      !isEmptyValue(deliverFiles) &&
      deliverFiles.every((resource) => resource.downloadCount > 0);
    const noDeliveryDocRequired = Boolean(
      get(projectOrderReadyInfo, ['info', 'noDeliveryDocRequired'])
    );
    const hasItemVerified = itemOrderReadyList.some(
      (ior) =>
        ![
          ORDER_READY_STATUS.NOT_STARTED,
          ORDER_READY_STATUS.NEED_UPDATE,
          ORDER_READY_STATUS.SUBMITTED,
        ].includes(ior.info.status)
    );
    const deliverTabWarning =
      hasItemVerified === true &&
      noDeliveryDocRequired === false &&
      deliverFiles.length <= 0 &&
      isAdminOrHigherRole(userRole);
    const itemCollectionNotices =
      allProjectOrderReadyInfo.itemCollectionNotices;
    const supplierInvoicesInfo = allProjectOrderReadyInfo.supplierInvoicesInfo;

    const areSomeItemsInDeliveryState = itemOrderReadyList.some((orderReady) =>
      DELIVERY_STATUSES.includes(orderReady.info.status)
    );
    const areAllItemsInDeliveryState = itemOrderReadyList.every((orderReady) =>
      DELIVERY_STATUSES.includes(orderReady.info.status)
    );
    const isProjectInDeliveryState = DELIVERY_STATUSES.includes(
      projectOrderReadyStatus
    );
    /**
     * disable delivery tab if
     * - it's supplier platform and
     * - no itemOrderReady has status ready_to_ship or in_transit
     * - there is no delivery documents uploaded and the noDeliveryDocRequired is still set false value
     * - or project status is not ready_to_ship or in_transit
     */
    const disableDeliverTab =
      !isAdminOrHigherRole(userRole) &&
      (!areSomeItemsInDeliveryState ||
        !isProjectInDeliveryState ||
        (noDeliveryDocRequired === false && deliverFiles.length <= 0));

    updateLocalState({
      projectOrderReadyID,
      projectOrderReadyInfo,
      projectOrderReadyStatus,
      itemOrderReadyList,
      itemCollectionNotices,
      orderShipmentInfo: allProjectOrderReadyInfo.orderShipmentInfo,
      noDeliveryDocRequired,
      orderReadyHistory,
      allItemsVerified,
      disableDeliverTab,
      projectQcReports,
      projectQcReportsAddOns,
      hasQcReportsRequired,
      hasDownloadDeliverFiles,
      deliverTabWarning,
      hasItemNotStarted,
      errors: {},
      // auto switch to deliver tab if projectOrderReadyStatus and itemOrderReadyStatus are delivery statuses for the first render,
      // persist for subsequent renders
      selectedTab: localState.persistSelectedTab
        ? localState.selectedTab
        : Number(
            !disableDeliverTab &&
              isProjectInDeliveryState &&
              areAllItemsInDeliveryState
          ),
      persistSelectedTab: true,
      shipmentNotes,
      showEditShippingNotesContainer: false,
      supplierInvoicesInfo,
      existingShipmentNotes,
    });
  };

  useEffect(() => {
    if (!isEmptyValue(projectID)) {
      setOrderReadyLoading(true);
      loadOrderReadyInfo().then(() => setOrderReadyLoading(false));
    }
  }, [projectID]);

  useEffect(() => {
    if (isEmptyValue(supplierID)) {
      return;
    }
    getUserInfo(supplierID).then((data) => {
      const isNonSgpSupplier = data.country !== COUNTRY_NAMES.SINGAPORE;
      updateLocalState({
        isNonSgpSupplier,
      });

      // set default shipment notes
      if (localState.projectOrderReadyStatus === ORDER_READY_STATUS.VERIFIED) {
        const notes = isNonSgpSupplier
          ? 'Ship to 81 Ayer Rajah Crescent, #01-54, Singapore 139967'
          : 'Self-collection by courier on (date), (time range)';
        updateLocalState({
          shipmentNotes: localState.shipmentNotes ?? notes,
        });
      }
    });
  }, [supplierID, localState.projectOrderReadyStatus]);

  // Items from same project can be spread apart multiple MCTs
  // and be spread across multiple ORMs too. Thus, items in the same MCT can belong to different suppliers
  useEffect(() => {
    if (isEmptyValue(localState.itemOrderReadyList)) {
      return;
    }
    const itemIDList = localState.itemOrderReadyList.map(
      (or) => or.info.itemID
    );

    const isMctItemInOrm = (item) => itemIDList.includes(item.itemID);

    const findMatchingItemOrderReady = (itemID) => {
      return localState.itemOrderReadyList.find(
        (itemOrderReady) => itemOrderReady.info.itemID === itemID
      );
    };

    const isAcceptedItemVerified = (acceptedItem) => {
      const matchingItemOrderReady = findMatchingItemOrderReady(
        acceptedItem.itemID
      );
      return {
        ...acceptedItem,
        isVerified:
          matchingItemOrderReady.info.status === ORDER_READY_STATUS.VERIFIED,
      };
    };

    const updateAcceptedItems = (tracking) => ({
      ...tracking,
      acceptedItems: tracking.acceptedItems
        .filter(isMctItemInOrm)
        .map(isAcceptedItemVerified),
    });

    const trackingsPromise = isAdminOrHigherRole(userRole)
      ? getTrackingsByItemIDs(itemIDList)
      : getSupplierTrackingsByItemIDs(itemIDList);

    trackingsPromise.then((trackings) => {
      const trackingsWithInfo = trackings.map(updateAcceptedItems);
      updateLocalState({ multipleCheckoutTrackings: trackingsWithInfo });
    });
  }, [userRole, localState.itemOrderReadyList]);

  useEffect(() => {
    // get items info and prepare download all files url if admin or higher role
    if (
      !isAdminOrHigherRole(userRole) ||
      isEmptyValue(localState.itemOrderReadyList)
    ) {
      return;
    }
    const itemIDList = localState.itemOrderReadyList.map(
      (or) => or.info.itemID
    );
    getItemsByIDList(itemIDList).then(async (items) => {
      let allProjectDownloadFileUrlsList = [];
      if (!isEmptyValue(items)) {
        const { itemFilesList, itemFilesMap } = items.reduce(
          (acc, item) => {
            const urlList =
              item.cadFile?.split(',').map((url) => ({
                url,
                subFolder: `part-${item.itemID}`,
              })) ?? [];
            acc.itemFilesList = [...acc.itemFilesList, ...urlList];
            acc.itemFilesMap[item.itemID] = item.cadFile
              ?.split(',')
              .filter((url) => isPdfFile(url));
            return acc;
          },
          {
            itemFilesList: [],
            itemFilesMap: {},
          }
        );
        allProjectDownloadFileUrlsList = itemFilesList;
        setFileUrlList(allProjectDownloadFileUrlsList);
        setItemFilesMap(itemFilesMap);
        updateLocalState({
          itemsData: items.map((item) => {
            const quotationID = localState.itemOrderReadyList.find(
              (itemOrderReady) => itemOrderReady.info.itemID === item.itemID
            ).info.quotationID;
            return {
              ...item,
              quotationID,
            };
          }),
        });
      }
    });
  }, [userRole, localState.itemOrderReadyList]);

  const handleUploadOrderReadyResource = ({
    itemOrderReadyID,
    resourceID,
    file,
    resourceType,
  }) => {
    const porID = localState.projectOrderReadyID;
    uploadOrderReadyResource({
      itemOrderReadyID,
      resourceID,
      file,
      resourceType,
      porID,
    }).then(loadOrderReadyInfo);
  };

  const handleVerifyItemOrderReady = (itemOrderReadyID) => {
    const body = {
      status: ORDER_READY_STATUS.VERIFIED,
    };
    updateLocalState({ updatingItemOrderReadyStatus: true });
    updateItemOrderReadyStatus(itemOrderReadyID, body)
      .then(() => {
        setTimeout(() => {
          loadOrderReadyInfo().then(() => {
            notifySuccess('Item order ready is verified');
            updateLocalState({ updatingItemOrderReadyStatus: false });
          });
          if (isAdminOrHigherRole(userRole)) {
            dispatch(getSubmittedProjectOrderReadyCountAction());
          }
        }, 2000);
      })
      .catch(() => {
        notifyError('Verifying Item order ready failed');
        updateLocalState({ updatingItemOrderReadyStatus: false });
      });
  };

  const handleItemOrderReadyNeedUpdate = (itemOrderReadyID) => {
    updateLocalState({ updatingItemOrderReadyStatus: true });
    const body = {
      status: ORDER_READY_STATUS.NEED_UPDATE,
      comments: [
        ...(localState.comments[itemOrderReadyID] || []),
        localState.additionalComment[itemOrderReadyID],
      ],
    };
    updateLocalState({ updatingItemOrderReadyStatus: true });
    updateItemOrderReadyStatus(itemOrderReadyID, body)
      .then(() => {
        setTimeout(() => {
          loadOrderReadyInfo().then(() => {
            notifySuccess('Item order ready status is updated');
            updateLocalState({ updatingItemOrderReadyStatus: false });
          });
          if (isAdminOrHigherRole(userRole)) {
            dispatch(getSubmittedProjectOrderReadyCountAction());
          }
        }, 2000);
      })
      .catch(() => {
        notifyError('Update Item order ready status failed');
        updateLocalState({ updatingItemOrderReadyStatus: false });
      });
  };

  const handleAdminEditStatus = () => {
    const body = {
      status: localState.adminEditStatus.status,
      adminManuallySwitchStatus: true,
    };
    updateItemOrderReadyStatus(
      localState.adminEditStatus.itemOrderReadyID,
      body
    )
      .then(() => {
        setTimeout(() => {
          loadOrderReadyInfo().then(() => {
            notifySuccess('Successfully edited item status');
            updateLocalState({ updatingItemOrderReadyStatus: false });
          });
          if (isAdminOrHigherRole(userRole)) {
            dispatch(getSubmittedProjectOrderReadyCountAction());
          }
        }, 2000);
      })
      .catch(() => {
        notifyError('Edit item status failed');
        updateLocalState({ updatingItemOrderReadyStatus: false });
      });
  };

  const handleDeleteResourceFile = () => {
    deleteOrderReadyResource(
      localState.projectOrderReadyID,
      localState.deleteResource.resourceID
    )
      .then(() => {
        loadOrderReadyInfo();
        notifySuccess(
          `${localState.deleteResource.fileName} file is removed successfully`
        );
      })
      .catch(() =>
        notifyError(
          `Failed to delete file ${localState.deleteResource.fileName}`
        )
      );
  };

  const handleDeleteItemCollectionNotice = () => {
    deleteItemCollectionNotice(
      localState.deleteItemCollectionNotice.itemCollectionNotice
        .itemCollectionNoticeID
    )
      .then(() => {
        loadOrderReadyInfo();
        notifySuccess('Item collection notice is removed successfully');
      })
      .catch(() => notifyError('Failed to delete item collection notice.'));
  };

  const handleAddComment = (event, itemOrderReadyID) => {
    const { name, checked } = event.target;
    if (checked) {
      updateLocalState({
        comments: {
          ...localState.comments,
          [itemOrderReadyID]: [
            ...(localState.comments[itemOrderReadyID] || []),
            name,
          ],
        },
      });
    } else {
      updateLocalState({
        comments: {
          ...localState.comments,
          [itemOrderReadyID]: (
            localState.comments[itemOrderReadyID] || []
          ).filter((comment) => comment !== name),
        },
      });
    }
  };

  const handleOrderShipped = () => {
    if (localState.isNonSgpSupplier) {
      if (isEmptyValue(localState.shipmentProvider)) {
        updateLocalState({
          errors: {
            shipmentProvider: 'Required',
          },
        });
        return;
      }
      if (
        localState.shipmentProvider === 'Other' &&
        isEmptyValue(localState.otherShipmentProvider)
      ) {
        updateLocalState({
          errors: {
            otherShipmentProvider: 'Required',
          },
        });
        return;
      }
      if (
        localState.shipmentTrackingNA !== true &&
        isEmptyValue(localState.shipmentTrackingNumber)
      ) {
        updateLocalState({
          errors: {
            shipmentTrackingNumber: 'Required',
          },
        });
        return;
      }
    }

    const dimensions = [
      'packageLength',
      'packageWidth',
      'packageHeight',
      'packageWeight',
    ];
    const errors = {};
    for (const dimension of dimensions) {
      if (!isNumber(localState[dimension])) {
        errors[dimension] = 'Please enter a valid number';
      }
    }
    if (Object.keys(errors).length > 0) {
      updateLocalState({ errors });
      return;
    }

    const projectOrderReadyID = localState.projectOrderReadyID;
    const itemOrderReadyList = localState.itemOrderReadyList || [];
    const itemOrderReadyIDList = itemOrderReadyList.map(
      (itemOrderReady) => itemOrderReady.info.itemOrderReadyID
    );
    let body = {
      itemOrderReadyIDList,
      packageLength: localState.packageLength,
      packageWidth: localState.packageWidth,
      packageHeight: localState.packageHeight,
      packageWeight: localState.packageWeight,
    };
    if (localState.isNonSgpSupplier) {
      body = {
        ...body,
        shipmentProvider:
          localState.shipmentProvider === 'Other'
            ? localState.otherShipmentProvider
            : localState.shipmentProvider,
        shipmentTrackingNA: localState.shipmentTrackingNA,
        shipmentTrackingNumber: localState.shipmentTrackingNumber,
      };
    }
    updateLocalState({ orderShippedProcessing: true });
    updateOrderShipped(projectOrderReadyID, body)
      .then(() => {
        setTimeout(() => {
          loadOrderReadyInfo().then(() => {
            notifySuccess('Updated successfully');
            updateLocalState({ orderShippedProcessing: false });
            if (userRole === ROLE_TYPES.SUPPLIER) {
              getFeedbackOfUsers(userID).then((data) => {
                if (isEmptyValue(data)) {
                  setShowFeedbackPopup(true);
                }
              });
            }
          });
        }, 2000);
      })
      .catch(() => {
        notifyError('Failed to updated.');
        updateLocalState({ orderShippedProcessing: false });
      });
  };

  const handleReadyToShipClick = () => {
    updateLocalState({ readyToShipProcessing: true });
    const toastId = toast('Ready to ship is processing...', {
      type: toast.TYPE.INFO,
      autoClose: false,
    });
    updateProjectOrderReadyInfo(localState.projectOrderReadyID, {
      status: ORDER_READY_STATUS.READY_TO_SHIP,
      shipmentNotes: localState.shipmentNotes,
    })
      .then(() => {
        setTimeout(() => {
          loadOrderReadyInfo().then(() => {
            toast.update(toastId, {
              render: 'The partner has been notified successfully.',
              type: toast.TYPE.SUCCESS,
              autoClose: 3000,
            });
            updateLocalState({ readyToShipProcessing: false });
          });
          if (isAdminOrHigherRole(userRole)) {
            dispatch(getSubmittedProjectOrderReadyCountAction());
          }
        }, 2000);
      })
      .catch(() => {
        toast.update(toastId, {
          render: 'Ready to ship processing failed.',
          type: toast.TYPE.ERROR,
          autoClose: 3000,
        });
        updateLocalState({ readyToShipProcessing: false });
      });
  };

  const renderStatus = (status) => {
    return <OrderReadyStatusDisplay status={status} />;
  };

  const renderProjectOrderReadyStatus = () => {
    return renderStatus(localState.projectOrderReadyStatus);
  };

  const renderTrackingDownloadResourceFileWithActions = (
    file,
    showDeleteButton = true,
    iconImage
  ) => {
    return (
      <SupplierOrmFileDownloadDisplayWithTracking
        key={`${file.resourceID}-${file.downloadCount}`}
        file={file}
        showDeleteButton={showDeleteButton}
        userRole={userRole}
        iconImage={iconImage}
        onDelete={(event) => {
          event.preventDefault();
          event.stopPropagation();
          updateLocalState({
            showDeleteFilePopup: true,
            deleteResource: {
              resourceID: file.resourceID,
              fileName: extractFileNameWithoutTimestampFromUrl(file.fileURL),
            },
          });
        }}
      />
    );
  };

  const renderDeliveryOrderFiles = () => {
    const resources =
      get(localState, ['projectOrderReadyInfo', 'resources']) || [];
    const fileList = resources.filter(
      (resource) => resource.type === ORDER_READY_RESOURCE_TYPES.DELIVERY_ORDER
    );
    if (isEmptyValue(fileList)) {
      return null;
    }
    const showDeleteButton = isAdminOrHigherRole(userRole);
    return fileList.map((file) =>
      renderTrackingDownloadResourceFileWithActions(file, showDeleteButton)
    );
  };

  const renderItemCollectionNoticesFiles = () => {
    if (isEmptyValue(localState.itemCollectionNotices)) {
      return <div>No item collection notices have been generated yet.</div>;
    }

    return (
      <FlexColumn style={{ width: 'fit-content', marginTop: '0.5rem' }}>
        {localState.itemCollectionNotices.map((itemCollectionNotice) => {
          const fileName = `Factorem_ICN_Item${
            itemCollectionNotice.itemIDsStr.includes(',') ? 's ' : ' '
          }${itemCollectionNotice.itemIDsStr}`;
          return (
            <DownloadableFileWithActions
              key={itemCollectionNotice.itemCollectionNoticeID}
              showDeleteButton={isAdminOrHigherRole(userRole)}
              fullWidth={true}
              onDelete={(event) => {
                event.preventDefault();
                event.stopPropagation();
                updateLocalState({
                  showDeleteFilePopup: true,
                  deleteItemCollectionNotice: {
                    itemCollectionNotice,
                    fileName,
                  },
                });
              }}
              fileUrl={itemCollectionNotice.itemCollectionNoticeUrl}
              fileName={fileName}
            />
          );
        })}
      </FlexColumn>
    );
  };

  const renderWaybillFiles = () => {
    const resources =
      get(localState, ['projectOrderReadyInfo', 'resources']) || [];
    const fileList = resources.filter(
      (resource) => resource.type === ORDER_READY_RESOURCE_TYPES.WAYBILL
    );
    const showDeleteButton = isAdminOrHigherRole(userRole);

    if (!isAdminOrHigherRole(userRole) && isEmptyValue(fileList)) {
      return null;
    }

    return (
      <div>
        <Typography className={classes.heading}>
          {isAdminOrHigherRole(userRole) ? 'Upload Waybill' : 'Waybill'}
        </Typography>
        {isAdminOrHigherRole(userRole) && (
          <div style={{ marginTop: '1rem' }}>
            <FilesUploadButton
              id='upload-way-bill'
              buttonText='Upload Waybill'
              handleUploadFiles={(files) =>
                handleUploadOrderReadyResource({
                  file: files[0],
                  resourceType: ORDER_READY_RESOURCE_TYPES.WAYBILL,
                })
              }
              multiple={false}
            />
          </div>
        )}
        <div style={{ marginTop: '1rem' }}>
          {fileList.map((file) =>
            renderTrackingDownloadResourceFileWithActions(
              file,
              showDeleteButton,
              TruckIcon
            )
          )}
        </div>
      </div>
    );
  };

  const changeStatusWordColor = (note, status) => {
    switch (status) {
      case ORDER_READY_STATUS.NEED_UPDATE:
        return (
          <ColoredWordsSentence
            sentence={note}
            highlightWords={['Update', 'required']}
            color={colors.bgOrderNeedUpdate}
          />
        );
      default:
        return note;
    }
  };

  const renderOrderReadyHistoryUser = (history) => {
    if (!isAdminOrHigherRole(history.userRole)) {
      // supplier logged history
      return history.companyName || capitalize(history.userRole);
    }

    // admin logged history
    return isAdminOrHigherRole(userRole)
      ? `Factorem (${history.userID})` // admin view
      : 'Factorem'; // supplier view
  };

  const renderItemOrderReadyList = () => {
    const itemOrderReadyList = localState.itemOrderReadyList || [];

    return (
      <div>
        {itemOrderReadyList.map((itemOrderReady, index) => {
          const itemOrderReadyID = itemOrderReady.info.itemOrderReadyID;
          const itemID = itemOrderReady.info.itemID;
          const itemOrderReadyStatus = itemOrderReady.info.status;
          const expand =
            index === 0 &&
            itemOrderReadyStatus === ORDER_READY_STATUS.NOT_STARTED;

          const showVerifyButton =
            isEmptyValue(localState.comments[itemOrderReadyID]) &&
            isEmptyValue(localState.additionalComment[itemOrderReadyID]);

          return (
            <ItemOrderReadyReview
              key={itemID}
              expand={expand}
              itemOrderReady={itemOrderReady}
              localState={localState}
              userRole={userRole}
              showVerifyButton={showVerifyButton}
              itemFilesMap={itemFilesMap}
              loadOrderReadyInfo={loadOrderReadyInfo}
              handleItemOrderReadyNeedUpdate={handleItemOrderReadyNeedUpdate}
              changeStatusWordColor={changeStatusWordColor}
              handleVerifyItemOrderReady={handleVerifyItemOrderReady}
              renderOrderReadyHistoryUser={renderOrderReadyHistoryUser}
              updateRequiredQcMaterialState={updateRequiredQcMaterialState}
              handleUploadOrderReadyResource={handleUploadOrderReadyResource}
              handleAddComment={handleAddComment}
              updateLocalState={updateLocalState}
            />
          );
        })}
      </div>
    );
  };

  const renderOrderReadyHistory = () => {
    return (
      <div>
        <Typography variant='h5' style={{ fontWeight: 'bold' }}>
          History
        </Typography>
        <Typography>
          Your upload history for this project is shown below
        </Typography>
        <Paper
          style={{
            display: 'flex',
            flexDirection: 'column',
            padding: '1rem',
            marginTop: '0.5rem',
          }}
        >
          {localState.orderReadyHistory.map((history, index) => {
            const name = renderOrderReadyHistoryUser(history);
            return (
              <Paper
                style={{
                  padding: '0.6rem',
                  margin: '0.3rem',
                }}
                key={history.notes + index}
              >
                <Typography
                  className={classes.heading}
                  style={{
                    textAlign:
                      history.userRole === ROLE_TYPES.SUPPLIER
                        ? 'left'
                        : 'right',
                  }}
                >
                  {formatDateWithTime(history.createdDate)}, {name}
                </Typography>
                {history.notes.split('\n').map((note, index) => {
                  return (
                    <Typography
                      key={note}
                      style={{
                        textAlign:
                          history.userRole === ROLE_TYPES.SUPPLIER
                            ? 'left'
                            : 'right',
                      }}
                    >
                      {index === 0
                        ? changeStatusWordColor(note, history.status)
                        : note}
                    </Typography>
                  );
                })}
              </Paper>
            );
          })}
        </Paper>
      </div>
    );
  };

  const renderNextSteps = () => {
    return (
      <NextSteps
        localState={localState}
        handleOrderShipped={handleOrderShipped}
        onUpdateShipmentInfo={(updatedProp) => (e) =>
          updateLocalState({
            [updatedProp]: e.target.value,
            errors: {},
          })
        }
        onUpdateIsTrackingNumberNA={(e) =>
          updateLocalState({
            shipmentTrackingNA: e.target.checked,
            errors: {},
          })
        }
      />
    );
  };

  const renderReviewTab = () => {
    return (
      <Grid container spacing={3}>
        {localState.allItemsVerified === true &&
          (localState.disableDeliverTab === false ||
            isAdminOrHigherRole(userRole)) && (
            <Grid item xs={12}>
              <Typography>
                All orders have been verified, please proceed to&nbsp;
                <Link
                  onClick={() => updateLocalState({ selectedTab: 1 })}
                  style={{ cursor: 'pointer' }}
                >
                  Deliver
                </Link>
                &nbsp;tab.
              </Typography>
            </Grid>
          )}
        {localState.hasItemNotStarted === true && (
          <Grid item xs={12}>
            <Typography>
              Upload delivery documents for each item and submit for review.
            </Typography>
          </Grid>
        )}
        <Grid item xs={12} md={8}>
          {orderReadyLoading && <CircularProgress color='primary' size={30} />}
          {!orderReadyLoading && renderItemOrderReadyList()}
        </Grid>
        <Grid item xs={12} md={4}>
          {!isEmptyValue(localState.orderReadyHistory) &&
          isAdminOrHigherRole(userRole) ? (
            renderOrderReadyHistory()
          ) : (
            <OrderReadyTimeline
              projectStatus={localState.projectOrderReadyStatus}
            />
          )}
        </Grid>
      </Grid>
    );
  };

  const renderReadyToShipArea = () => {
    const hasVerifiedItemOrderReady = localState.itemOrderReadyList.some(
      (itemOrderReady) =>
        itemOrderReady.info.status === ORDER_READY_STATUS.VERIFIED
    );
    const resources =
      get(localState, ['projectOrderReadyInfo', 'resources']) || [];
    const hasWaybillOrDO = resources.some((resource) =>
      [
        ORDER_READY_RESOURCE_TYPES.DELIVERY_ORDER,
        ORDER_READY_RESOURCE_TYPES.WAYBILL,
      ].includes(resource.type)
    );
    const disabledButton = localState.deliverTabWarning;

    return (
      <div>
        {!hasWaybillOrDO && (
          <div>
            <FormControlLabel
              control={
                <Checkbox
                  name='no-delivery-docs-required'
                  onChange={(event) => {
                    const { checked } = event.target;
                    updateProjectOrderReadyInfo(
                      localState.projectOrderReadyID,
                      {
                        noDeliveryDocRequired: checked,
                      }
                    )
                      .then(() => {
                        notifySuccess(`Updated successfully`);
                        loadOrderReadyInfo();
                      })
                      .catch(() => {
                        notifyError(`Updated failed`);
                      });
                  }}
                  checked={Boolean(
                    get(localState.projectOrderReadyInfo, [
                      'info',
                      'noDeliveryDocRequired',
                    ])
                  )}
                />
              }
              label='No delivery documents necessary.'
            />
          </div>
        )}
        {localState.projectOrderReadyStatus === ORDER_READY_STATUS.VERIFIED && (
          <div>
            <TextField
              style={{ width: '50%' }}
              label='Shipping Notes (if any)'
              variant='outlined'
              multiline={true}
              minRows={6}
              maxRows={6}
              onChange={(e) =>
                updateLocalState({
                  shipmentNotes: e.target.value,
                  errors: {},
                })
              }
              value={localState.shipmentNotes}
              margin='dense'
              fullWidth
              InputLabelProps={{ shrink: true }}
            />
          </div>
        )}
        {hasVerifiedItemOrderReady !== false && (
          <div>
            <Tooltip title='Clicking this will notify partner to download documents (if any) and ship the order.'>
              <span>
                <Button
                  color='primary'
                  variant='contained'
                  onClick={handleReadyToShipClick}
                  disabled={
                    disabledButton || localState.readyToShipProcessing === true
                  }
                >
                  {localState.readyToShipProcessing === true && (
                    <CircularProgress
                      className={classes.circularProgress}
                      size={20}
                    />
                  )}
                  Ready to ship
                </Button>
              </span>
            </Tooltip>
          </div>
        )}
      </div>
    );
  };

  const renderItemsShippedSentence = () => {
    const allItemsShipped = localState.itemOrderReadyList.every(
      (itemOrderReady) =>
        itemOrderReady.info.status === ORDER_READY_STATUS.IN_TRANSIT
    );
    if (!allItemsShipped) {
      return null;
    }
    return (
      <Typography>
        {localState.itemOrderReadyList.length > 1
          ? 'All items have shipped.'
          : 'Item has shipped.'}
      </Typography>
    );
  };

  const renderItemCollectionDisplay = () => {
    if (!areSomeItemsDelivered) {
      return null;
    }

    return (
      <>
        <div style={{ marginTop: '15px', marginBottom: '25px' }}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              marginBottom: '15px',
            }}
          >
            <div style={{ fontWeight: 'bold' }}>Item Collection</div>
          </div>
          <div style={{ marginBottom: '15px' }}>
            {isAdminOrHigherRole(userRole) && renderItemCollectedButtons}
          </div>
          {isAdminOrHigherRole(userRole) && (
            <FtrButton
              color='blue'
              size='large'
              variant='contained'
              onClick={() => {
                setShowItemCollectionPopup(true);
              }}
            >
              Generate Item Collection Notice
            </FtrButton>
          )}
          {renderItemCollectionNoticesFiles()}
        </div>
      </>
    );
  };

  const renderShipmentInfo = () => {
    const orderShipmentInfo = localState.orderShipmentInfo || [];
    if (isEmptyValue(orderShipmentInfo.shipmentProvider)) {
      return null;
    }
    return (
      <div
        style={{
          margin: '1rem 0',
        }}
      >
        <Typography className={classes.heading}>Shipment Info</Typography>
        {orderShipmentInfo.map((shipmentInfo) => {
          return (
            <div
              key={shipmentInfo.id}
              style={{
                marginLeft: '0.5rem',
              }}
            >
              <Typography>
                Shipment provider: <b>{shipmentInfo.shipmentProvider}</b>
              </Typography>
              <div style={{ display: 'inline-flex', alignItems: 'center' }}>
                <Typography>
                  Shipment Tracking Number: <b>{shipmentInfo.trackingNumber}</b>
                </Typography>
                <ShipmentTrackingIcon
                  shipmentInfo={shipmentInfo}
                  iconButtonStyle={{ padding: '0rem 0rem 0rem 0.5rem' }}
                />
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  const renderManageSupplierInvoice = () => {
    return (
      <ManageSupplierInvoice
        supplierID={supplierID}
        projectID={projectID}
        poAcknowledgedVersion={poAcknowledgedVersion}
        refreshFunc={loadOrderReadyInfo}
        classes={classes}
        itemOrderReadyList={localState?.itemOrderReadyList || []}
        invoiceSentToHubdoc={
          localState?.projectOrderReadyInfo?.info?.invoiceSentToHubdoc
        }
        supplierInvoicesInfo={localState?.supplierInvoicesInfo || []}
        isOrderDelivered={
          localState.projectOrderReadyStatus === ORDER_READY_STATUS.DELIVERED
        }
      />
    );
  };

  const handleDownloadFile = async (event, link) => {
    event.stopPropagation();
    event.preventDefault();
    await generatePresignedUrl(link, 300, true) // 5 minutes expiration
      .then(openInNewTab);
  };

  const renderShipmentInstruction = () => {
    if (isDeliveryInTransitOrComplete) {
      return null;
    }
    return (
      <>
        <Divider style={{ marginTop: '1rem' }} />
        <p>
          <u>
            Please follow all the guidelines below for a smooth delivery of your
            order.
          </u>
        </p>
        <div>
          <div
            style={{
              marginTop: '20px',
              fontWeight: 'bold',
            }}
          >
            Packing Instructions
          </div>
          <br />
          <FlexRow>
            <FtrButton
              color='yellow'
              endIcon={<OpenInNew />}
              onClick={(e) => {
                const fileUrl =
                  'https://factorem-s3-bucket-staging.s3.ap-southeast-1.amazonaws.com/Public/Factorem_Packing_guidelines_for_Partners_1736998204858.pdf';
                if (!isEmptyValue(fileUrl)) {
                  handleDownloadFile(e, fileUrl);
                }
              }}
            >
              Factorem Packing Guidelines for Partners.pdf
            </FtrButton>
          </FlexRow>
          <Divider style={{ marginTop: '1rem' }} />
          <div
            style={{
              marginTop: '20px',
              fontWeight: 'bold',
            }}
          >
            Shipment Instructions
          </div>
          {isMobile ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <img
                src={DeliveryInstructionsVertical}
                style={{ width: '60%', height: '60%' }}
                alt='deliver-process'
              />
            </div>
          ) : (
            <img
              src={DeliveryInstructions}
              style={{ width: '60%', height: '60%' }}
              alt='deliver-process'
            />
          )}
        </div>
      </>
    );
  };

  const renderDeliverTab = () => {
    return (
      <div>
        <Box style={{ height: '0.5rem' }} />
        <>
          {localState.noDeliveryDocRequired === false &&
            !isDeliveryInTransitOrComplete && (
              <Typography>
                Please download the following document(s) to proceed with
                shipping of the order.
              </Typography>
            )}
        </>
        <Grid container spacing={3}>
          <Grid item xs={12} md={3}>
            {isAdminOrHigherRole(userRole) && (
              <>
                <Typography className={classes.heading}>
                  Issue Delivery Order
                </Typography>
                {!get(localState.projectOrderReadyInfo, [
                  'info',
                  'noDeliveryDocRequired',
                ]) && issueDOButtons}
                <div style={{ marginTop: '1rem' }}>
                  <FilesUploadButton
                    id='upload-delivery-order'
                    buttonText='Upload Delivery Order'
                    handleUploadFiles={(files) =>
                      handleUploadOrderReadyResource({
                        file: files[0],
                        resourceType: ORDER_READY_RESOURCE_TYPES.DELIVERY_ORDER,
                      })
                    }
                    multiple={false}
                  />
                </div>
              </>
            )}
            {localState.noDeliveryDocRequired === false &&
              !isAdminOrHigherRole(userRole) && (
                <Typography className={classes.heading}>
                  Delivery Order
                </Typography>
              )}
            <div style={{ marginTop: '1rem' }}>
              {renderDeliveryOrderFiles()}
            </div>
          </Grid>
          <Grid item xs={12} md={3}>
            {renderWaybillFiles()}
          </Grid>
        </Grid>
        {isAdminOrHigherRole(userRole) && renderReadyToShipArea()}
        <ShipmentNotes
          isEditMode={localState.showEditShippingNotesContainer}
          shipmentNotes={localState.shipmentNotes}
          onEditIconClick={() =>
            updateLocalState({ showEditShippingNotesContainer: true })
          }
          onSubmitEdit={(shipmentNotes) => {
            updateProjectOrderReadyInfo(localState.projectOrderReadyID, {
              shipmentNotes,
            })
              .then(() => {
                notifySuccess(`Updated successfully`);
                loadOrderReadyInfo();
              })
              .catch(() => {
                notifyError(`Updated failed`);
              });
          }}
          onCancelEdit={() => {
            updateLocalState({
              showEditShippingNotesContainer: false,
            });
          }}
          supplierID={supplierID}
          isNonSgpSupplier={localState.isNonSgpSupplier}
        />
        {hasViewedDeliveryDocIfRequired && (
          <>
            {renderNextSteps()}
            {renderItemsShippedSentence()}
            {renderItemCollectionDisplay()}
            {renderShipmentInfo()}
            {renderManageSupplierInvoice()}
          </>
        )}
        {renderShipmentInstruction()}
      </div>
    );
  };

  const renderTab = () => {
    const tabName = TABS[localState.selectedTab];
    switch (tabName) {
      case TAB_KEYS.REVIEW:
        return renderReviewTab();
      case TAB_KEYS.DELIVER:
        return renderDeliverTab();
      case TAB_KEYS.ADMIN_UPLOAD_FILES:
        return (
          <AdminUploadFilesTab
            itemOrderReadyList={localState.itemOrderReadyList}
            loadOrderReadyInfo={loadOrderReadyInfo}
          />
        );
      default:
        return null;
    }
  };

  const renderDeleteFilePopup = () => {
    return (
      <YesNoPopup
        open={localState.showDeleteFilePopup}
        handleNo={() => updateLocalState({ showDeleteFilePopup: false })}
        handleYes={() => {
          isEmptyValue(localState.deleteItemCollectionNotice)
            ? handleDeleteResourceFile()
            : handleDeleteItemCollectionNotice();
          updateLocalState({ showDeleteFilePopup: false });
        }}
        body={`${
          localState.deleteResource?.fileName ||
          localState.deleteItemCollectionNotice?.fileName ||
          'Filename not detected'
        }`}
      />
    );
  };

  const renderAdminAddEditRequiredMaterialsPopup = () => {
    return (
      <AdminAddEditRequiredQCMaterialsPopup
        open={requiredQcMaterialState.showPopup}
        onClose={() => updateRequiredQcMaterialState({ showPopup: false })}
        loadOrderReadyInfo={loadOrderReadyInfo}
        selectedItemOrderReady={requiredQcMaterialState.selectedItemOrderReady}
        editMode={requiredQcMaterialState.editMode}
        resource={requiredQcMaterialState.resource}
      />
    );
  };

  const renderAdminEditStatusPopup = () => {
    const closePopup = () =>
      updateLocalState({ showAdminEditStatusPopup: false });
    const itemOrderReadyInfo = localState.itemOrderReadyList?.find(
      (ior) =>
        ior.info.itemOrderReadyID ===
        localState.adminEditStatus.itemOrderReadyID
    );
    const currentIorStatus = itemOrderReadyInfo.info.status;
    if (localState.adminEditStatus.status === currentIorStatus) {
      notifyWarning(`Please select a different status!`);
      closePopup();
      return null;
    }

    return (
      <YesNoPopup
        open={localState.showAdminEditStatusPopup}
        handleNo={closePopup}
        handleYes={() => {
          handleAdminEditStatus();
          updateLocalState({ showAdminEditStatusPopup: false });
        }}
        title={`Are you sure that you want to change status of Part #${
          localState.adminEditStatus.itemID
        } 
          to ${ORDER_READY_STATUS_MAPPING[localState.adminEditStatus.status]}?`}
      />
    );
  };

  return (
    <ProjectOrderReadyContext.Provider
      value={{
        updateRepeatItemOrmDetailsPopupHOCState,
      }}
    >
      <div className={classes.body}>
        <FlexRow>
          <h1>Project {projectID}</h1>
          <div style={{ flexGrow: 1 }}></div>
          <GuestLinkToPageORM supplierID={supplierID} />
        </FlexRow>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '0.5rem',
          }}
        >
          <Typography>Status:</Typography>
          {renderProjectOrderReadyStatus()}
          {isAdminOrHigherRole(userRole) && (
            <DownloadAllFilesButton
              buttonText='Download All Project Files'
              urlList={fileUrlList}
              folderName={`Factorem_Project_${projectID}`}
            />
          )}
        </div>
        <div
          style={{
            marginTop: '1rem',
          }}
        >
          <Tabs
            value={localState.selectedTab}
            onChange={(event, newValue) =>
              updateLocalState({ selectedTab: newValue })
            }
            variant='scrollable'
            scrollButtons='auto'
          >
            <Tab
              classes={{
                root: classes.tabRoot,
                selected: classes.selectedTab,
                wrapper: classes.iconLabelWrapper,
                labelIcon: classes.labelIcon,
              }}
              key={TAB_KEYS.REVIEW}
              label={TAB_KEYS.REVIEW}
              icon={<ArtTrack />}
            />
            <CloneProps>
              {(tabProps) => (
                <Tooltip
                  title={
                    localState.disableDeliverTab
                      ? 'Complete order ready review before proceeding to Deliver'
                      : ''
                  }
                >
                  <div>
                    <Tab
                      classes={{
                        root: classes.tabRoot,
                        selected: classes.selectedTab,
                        disabled: classes.disabledTab,
                        wrapper: classes.iconLabelWrapper,
                        labelIcon: classes.labelIcon,
                      }}
                      icon={<LocalShipping />}
                      {...tabProps}
                      key={TAB_KEYS.DELIVER}
                      label={
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '0.5rem',
                          }}
                        >
                          {TAB_KEYS.DELIVER}
                          {localState.deliverTabWarning === true && (
                            <Tooltip title='Delivery documents have not been uploaded'>
                              <Warning
                                style={{
                                  marginTop: 0,
                                  color: 'red',
                                  opacity: '1 important',
                                }}
                              />
                            </Tooltip>
                          )}
                        </div>
                      }
                      disabled={localState.disableDeliverTab}
                    />
                  </div>
                </Tooltip>
              )}
            </CloneProps>
            {isAdminOrHigherRole(userRole) && (
              <Tab
                classes={{
                  root: classes.tabRoot,
                  selected: classes.selectedTab,
                  wrapper: classes.iconLabelWrapper,
                  labelIcon: classes.labelIcon,
                }}
                key={TAB_KEYS.ADMIN_UPLOAD_FILES}
                label={TAB_KEYS.ADMIN_UPLOAD_FILES}
                icon={<AttachFile />}
              />
            )}
          </Tabs>
          <div
            style={{
              paddingTop: '1rem',
              paddingBottom: '1rem',
            }}
          >
            {renderTab()}
          </div>
        </div>
        {localState.showImageView === true && (
          <Dialog
            open={localState.showImageView}
            onClose={() => updateLocalState({ showImageView: false })}
            maxWidth='md'
          >
            <ImageGallery
              items={localState.imageUrlList}
              startIndex={localState.showImageIndex}
            />
          </Dialog>
        )}
        {localState.showDeleteFilePopup === true && renderDeleteFilePopup()}
        {requiredQcMaterialState.showPopup === true &&
          renderAdminAddEditRequiredMaterialsPopup()}
        <UserFeedbackPopup
          open={showFeedbackPopup}
          questionList={USER_FEEDBACK_QUESTION_LIST}
          userID={userID}
          userRole={userRole}
          handleClose={() => {
            setShowFeedbackPopup(false);
            setShowThankYouFeedbackPopup(true);
          }}
        />
        <ThankYouFeedbackPopup
          open={showThankYouFeedbackPopup}
          handleClose={() => setShowThankYouFeedbackPopup(false)}
        />
        {showItemCollectionPopup && (
          <ItemCollectionNoticePopup
            open={showItemCollectionPopup}
            handleClose={() => setShowItemCollectionPopup(false)}
            itemsData={localState.itemsData}
            projectID={projectID}
            handleSubmit={async (payload) => {
              const toastID = notifyOngoing(
                'Generating item collection notice'
              );
              await generateItemCollectionNotice(payload)
                .then(() =>
                  updateNotification(
                    toastID,
                    'Generated item collection notice',
                    'success'
                  )
                )
                .catch((err) =>
                  updateNotification(
                    toastID,
                    `Failed to generate.` + err,
                    'error'
                  )
                );
              await loadOrderReadyInfo();
            }}
            projectOrderReadyInfo={localState.projectOrderReadyInfo.info}
          />
        )}
        {showDeliverPopup && (
          <MultiCheckoutDeliverPopup
            open={showDeliverPopup}
            handleCancel={() => setShowDeliverPopup(false)}
            checkoutID={selectedMultipleCheckoutTracking.id}
            setShowDeliverPopup={setShowDeliverPopup}
            dataRefetchFunc={loadOrderReadyInfo}
            acceptedItems={selectedMultipleCheckoutTracking.acceptedItems}
            initialEstimateDeliveryDate={
              selectedMultipleCheckoutTracking.estimateDeliveryDate
            }
          />
        )}
        {showCollectedInfoPopup && (
          <CollectedInfoPopup
            open={showCollectedInfoPopup}
            handleClose={() => setShowCollectedInfoPopup(false)}
            rowData={selectedMultipleCheckoutTracking}
            dataRefetchFunc={loadOrderReadyInfo}
          />
        )}
        {localState.showAdminEditStatusPopup === true &&
          renderAdminEditStatusPopup()}
      </div>
    </ProjectOrderReadyContext.Provider>
  );
}

export default withRepeatItemOrmDetailsPopupHOC(ProjectOrderReady);
