import { backAction, continueAction } from './tourConstants';

export const getMultiplePartSummaryTourSteps = (params = {}) => {
  const isMobile = params.isMobile;
  const createOnClickGettingStartedPromise =
    params.createOnClickGettingStartedPromise ?? function() {};
  const createExpandArrowPromise =
    params.createExpandArrowPromise ?? function() {};
  const delayedScrollToHandler = params.delayedScrollToHandler ?? function() {};
  const createCollapseArrowPromise =
    params.createCollapseArrowPromise ?? function() {};
  return [
    {
      attachTo: {
        element: '#multiple-part-instant-quote',
        on: isMobile ? 'top' : 'bottom',
      },
      buttons: [
        {
          ...backAction,
          disabled: true,
        },
        continueAction,
      ],
      showOn: function() {
        const element = document.querySelector(this.attachTo.element);
        return Boolean(element);
      },
      modalOverlayOpeningPadding: 2,
      title: 'View Instant Quote',
      beforeShowPromise: createOnClickGettingStartedPromise,
      text: [
        'Instant Quotes for your part! We have quoted this based on historical parts that best match this item.',
      ],
    },
    {
      attachTo: {
        element: '#multiple-part-manual-rfq',
        on: isMobile ? 'top' : 'bottom',
      },
      buttons: [backAction, continueAction],
      showOn: function() {
        const element = document.querySelector(this.attachTo.element);
        return Boolean(element);
      },
      modalOverlayOpeningPadding: 2,
      title: 'Manual RFQ',
      beforeShowPromise: createOnClickGettingStartedPromise,
      text: [
        'We will take a closer look at these items and revert with a quotation.',
      ],
    },
    {
      attachTo: { element: '.expandMoreIcon', on: isMobile ? 'top' : 'bottom' },
      buttons: [backAction, continueAction],
      modalOverlayOpeningPadding: 8,
      title: 'Configure More Requirements',
      beforeShowPromise: createOnClickGettingStartedPromise,
      text: [
        'Change fabrication technology, materials, set target price, lead times, add in comments and more here!',
      ],
    },
    {
      attachTo: { element: '#edit-part-expected-lead-time', on: 'top' },
      buttons: [backAction, continueAction],
      beforeShowPromise: createExpandArrowPromise,
      scrollToHandler: delayedScrollToHandler,
      title: 'Set your targets',
      text: [
        'Easily set your target lead time and prices for us to find you the best match in manufacturing capacity.',
      ],
    },
    {
      attachTo: { element: '#edit-part-tolerance', on: 'bottom' },
      buttons: [backAction, continueAction],
      modalOverlayOpeningPadding: 4,
      title: 'Set tightest tolerance',
      text: [
        'Use this to define the tightest tolerances that we should work towards achieving. ',
      ],
    },
    {
      attachTo: {
        element: '#edit-part-remarks',
        on: isMobile ? 'top' : 'bottom',
      },
      buttons: [backAction, continueAction],
      title: 'Anything else to add?',
      text: [
        'Tell us anything else that we should know to best service your order with us!',
      ],
    },
    {
      attachTo: { element: '#edit-part-save-edit', on: 'top' },
      buttons: [backAction, continueAction],
      beforeShowPromise: createExpandArrowPromise,
      scrollToHandler: delayedScrollToHandler,
      modalOverlayOpeningPadding: 8,
      title: 'Save your changes',
      text: [
        'Remember to click here to save your changes once you are done configuring your part.',
      ],
    },
    {
      attachTo: { element: '#multiple-part-summary-proceed', on: 'top' },
      buttons: [
        backAction,
        {
          classes: 'shepherd-button-primary',
          text: 'Got it',
          action() {
            this.next();
          },
        },
      ],
      beforeShowPromise: createCollapseArrowPromise,
      modalOverlayOpeningPadding: 8,
      title: 'Proceed to review',
      text: [
        'Click here once you are done with your configurations to review your items and quotes with Factorem.',
      ],
    },
  ];
};
