import { copyObjectByFields } from '../utils/objectUtils';

// --------------------------------------------------------------------------------

/**
 * if want to add sub field:
 * eg: 'metadata.cmmPrice'
 */
const ORDER_AGAIN_FIELDS = [
  'color',
  'customMaterial',
  'customSurfaceFinish',
  'description',
  'itemName',
  'material',
  'materialColor',
  'name',
  'otherMaterial',
  'otherSurfaceFinish',
  'otherTechnology',
  'partApplication',
  'qcReports',
  'quantity',
  'surfaceFinish',
  'technology',
  'tolerance',
  'twoDImageUrl',
  'unitType',
];

/**
 * This is proxy to add utils to order again item
 */
export const orderAgainItemProxy = {
  // Trap for property getting
  get: function(target, prop, receiver) {
    if (prop === 'getOrderAgainItem') {
      return function() {
        return copyObjectByFields(target, ORDER_AGAIN_FIELDS);
      };
    }

    // Use Reflect.get to ensure correct 'this' binding
    return Reflect.get(target, prop, receiver);
  },
};
