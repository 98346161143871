import { useEffect, useReducer } from 'react';

import { TextField } from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';

import { getUserInfo } from '../../../apis/userApi';

import { formatSimpleDate } from '../../../utils/dateTimeUtils';
import { FtrBoldText } from '../../ftr-components';

export const PickupDetailsForm = (props) => {
  const {
    title = 'Pickup Details',
    onChangeState = () => {},
    initialValues,
  } = props;

  const [localState, updateLocalState] = useReducer(
    (prev, next) => {
      const updatedState = { ...prev, ...next };
      onChangeState(updatedState);
      return updatedState;
    },
    {
      supplierContactName: null,
      supplierPhoneNumber: null,
      supplierAddress: null,
      supplierPostalCode: null,
      pickupInstructions: null,
      plannedPickupDate: formatSimpleDate(new Date()),
      ...initialValues,
    }
  );

  const getSupplierDetails = async (supplierID) => {
    const supplierInfo = await getUserInfo(supplierID);
    if (supplierInfo) {
      updateLocalState({
        supplierContactName: supplierInfo.name,
        supplierPhoneNumber: supplierInfo.contact,
      });
    }
  };

  useEffect(() => {
    if (localState.supplierID) {
      getSupplierDetails(localState.supplierID);
    }
  }, [localState.supplierID]);

  return (
    <div>
      <FtrBoldText fontSize='16'>{title}</FtrBoldText>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(4, 1fr)',
          columnGap: '1rem',
          placeItems: 'baseline',
          marginTop: '1rem',
        }}
      >
        <TextField
          label='Contact Name'
          value={localState.supplierContactName}
          variant='outlined'
          margin='dense'
          style={{ marginRight: '1.25rem' }}
          required
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(evt) => {
            updateLocalState({
              supplierContactName: evt.target.value,
            });
          }}
        />
        <TextField
          label='Phone Number'
          value={localState.supplierPhoneNumber}
          variant='outlined'
          margin='dense'
          style={{ marginRight: '1.25rem' }}
          required
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(evt) => {
            updateLocalState({
              supplierPhoneNumber: evt.target.value,
            });
          }}
        />
        <TextField
          label='Address'
          value={localState.supplierAddress}
          variant='outlined'
          margin='dense'
          style={{ marginRight: '1.25rem' }}
          required
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(evt) => {
            updateLocalState({
              supplierAddress: evt.target.value,
            });
          }}
        />
        <TextField
          label='Postcode'
          value={localState.supplierPostalCode}
          variant='outlined'
          margin='dense'
          style={{ marginRight: '1.25rem' }}
          fullWidth
          required
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(evt) => {
            updateLocalState({
              supplierPostalCode: evt.target.value,
            });
          }}
        />
        <DatePicker
          label={'Pickup Date'}
          value={localState.plannedShippingDate}
          onChange={(date) => updateLocalState({ plannedShippingDate: date })}
          animateYearScrolling
          inputVariant='outlined'
          margin='dense'
          clearable
          clearLabel='No Preference'
          fullWidth
          required
        />
        <TextField
          label='Instructions'
          value={localState.pickupInstructions}
          variant='outlined'
          margin='dense'
          style={{ marginRight: '1.25rem' }}
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          multiline
          onChange={(evt) => {
            updateLocalState({
              pickupInstructions: evt.target.value,
            });
          }}
        />
      </div>
    </div>
  );
};
