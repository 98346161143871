import { SAVE_PROJECT } from '../actions/project';

import { LOGOUT_SUCCESS } from '../actions/';

const defaultState = {
  project: {
    projectName: '',
    projectStatus: '',
  },
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case SAVE_PROJECT:
      return {
        ...state,
        project: action.project,
      };
    case LOGOUT_SUCCESS:
      return defaultState;
    default:
      return state;
  }
};
