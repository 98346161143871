import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useQuery } from 'react-query';

import withGuidedTour from './withGuidedTour';

import PartUploadTourPopup from './PartUploadTourPopup';
import { FtrLightText } from '../ftr-components';

import { getGuidedTourCompletionStatus } from '../../apis/tourApi';

import { getFormDataAvailableSelector } from '../../selectors/formDataAvailableSelector';

import { isEmptyValue } from '../../utils/commonUtils';

import { PART_UPLOAD_STEP_TWO_TOUR } from './guidedTours/tourConstants';

// -------------------------------------------------------------------------------------------------

const withPartUploadStepTwoTourPopupHoC = (WrappedComponent) => {
  function WrappedComponentWithHOC(props) {
    const { updateTourState, tour } = props;

    const userID = useSelector((state) => state.auth?.user?.userID);
    const userName = useSelector((state) => state.auth?.user?.name) ?? '';
    const formDataAvailable = useSelector(getFormDataAvailableSelector);

    const [showPopup, setShowPopup] = useState(false);

    const { data: dataTour, isFetching: isTourLoading } = useQuery(
      ['getGuidedTourCompletionStatus', userID, PART_UPLOAD_STEP_TWO_TOUR],
      () => {
        if (isEmptyValue(userID)) {
          return null;
        }

        return getGuidedTourCompletionStatus(userID, PART_UPLOAD_STEP_TWO_TOUR);
      }
    );

    // check the first part only
    const isPartLoading = formDataAvailable
      ?.filter((part) => part.name === formDataAvailable[0].name)
      .some(
        (part) =>
          part.ppePricingStatus === 'loading' || part.tdeStatus === 'loading'
      );

    const hasStartedTour = useRef(false); // Track if the tour has started

    useEffect(() => {
      const completedTour = Boolean(dataTour?.completed);
      updateTourState({
        tourKey: PART_UPLOAD_STEP_TWO_TOUR,
        trackingSteps: dataTour?.trackingSteps,
        userCompletedTourBefore: completedTour,
        isTourLoading,
        showAll: completedTour, // if false then check trackingSteps
      });

      if (
        isPartLoading ||
        isEmptyValue(formDataAvailable) ||
        !isEmptyValue(userID)
      ) {
        return;
      }

      const timer = setTimeout(() => {
        if (!hasStartedTour.current) {
          hasStartedTour.current = true; // Mark the tour as started
          setShowPopup(true);
        }
      }, 3000); // open popup after 3 seconds

      return () => clearTimeout(timer); // clean up timeout
    }, [isPartLoading, dataTour, isTourLoading]);

    return (
      <>
        <WrappedComponent {...props} />
        <PartUploadTourPopup
          open={showPopup}
          onClose={() => setShowPopup(false)}
          userName={userName}
          handleStartTour={tour?.start}
          customTitle='Welcome to Factorem!'
          content={
            <>
              <FtrLightText>
                You can now order custom parts in clicks and hassle-free.
              </FtrLightText>
              <FtrLightText>
                At every step of your manufacturing journey,
              </FtrLightText>
              <FtrLightText>we are here to support you.</FtrLightText>
            </>
          }
        />
      </>
    );
  }

  return withGuidedTour(WrappedComponentWithHOC);
};

export default withPartUploadStepTwoTourPopupHoC;
