import { fetchWithErrorHandling, getAuthorizedHeader } from '../utils/apiUtils';

import { BACKEND_SERVICE_URL } from '../constants';

// -------------------------------------------------------------------------------------------------

export const autoRenderBalloonApi = async (body) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/tdesd/auto_render_balloon`;
  return fetchWithErrorHandling(requestUrl, {
    method: 'POST',
    headers: getAuthorizedHeader(),
    body: JSON.stringify(body),
  });
};

export const addManualBalloonsApi = async (body) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/tdesd/manual_render_balloons`;
  return fetchWithErrorHandling(requestUrl, {
    method: 'POST',
    headers: getAuthorizedHeader(),
    body: JSON.stringify(body),
  });
};

export const deleteBalloonsApi = async (body) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/tdesd/delete_balloons`;
  return fetchWithErrorHandling(requestUrl, {
    method: 'POST',
    headers: getAuthorizedHeader(),
    body: JSON.stringify(body),
  });
};

export const downloadBalloonsReportCsvApi = async (body) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/tdesd/download_report_csv`;
  return fetchWithErrorHandling(requestUrl, {
    method: 'POST',
    headers: getAuthorizedHeader(),
    body: JSON.stringify(body),
  });
};
