import React from 'react';
import { FlexRow } from '../layouts/FlexLayouts';
import Thumb from './Thumb';
import { colors } from '../../palette';

function FeedbackThumbs({
  upIsActive,
  upTooltip,
  upOnclick,
  downIsActive,
  downTooltip,
  downOnclick,
  color = 'default',
}) {
  return (
    <FlexRow
      style={{
        padding: '5px',
        borderRadius: '5px',
        gap: 0,
        backgroundColor: colors.neutral030,
        transform: 'scale(0.8)',
      }}
    >
      <Thumb
        variant='up'
        color={color}
        isActive={upIsActive}
        tooltipText={upTooltip}
        onClick={upOnclick}
      />
      <Thumb
        variant='down'
        color={color}
        isActive={downIsActive}
        tooltipText={downTooltip}
        onClick={downOnclick}
      />
    </FlexRow>
  );
}

export default FeedbackThumbs;
