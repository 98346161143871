import React, { useReducer } from 'react';

import { isEmptyValue } from '../../utils/commonUtils';
import QualityCertificationsPopup from '../../components/popups/QualityCertificationsPopup';
import { updateQcReports } from '../../apis/projectApi';
import { notifyError, notifySuccess } from '../../services/notificationService';

// -------------------------------------------------------------------------------------------------

function withQualityCertificationsPopupHOC(WrappedComponent) {
  function WrappedComponentWithHOC(props) {
    const defaultLocalState = {
      open: false,
    };

    const [localState, updateLocalState] = useReducer((prev, next) => {
      if (isEmptyValue(next)) {
        return defaultLocalState;
      }
      return { ...prev, ...next };
    }, defaultLocalState);

    return (
      <>
        <WrappedComponent
          updateQualityCertificationsPopupState={updateLocalState}
          {...props}
        />
        {localState.open && (
          <QualityCertificationsPopup
            dialog={localState.open}
            value={localState.qcReports}
            onClose={() => updateLocalState({ open: false })}
            onOk={(value) => {
              updateQcReports({
                projectID: localState.projectID,
                qcReports: value,
              })
                .then(() => {
                  localState.setQcReports(value);
                  notifySuccess(`QC Reports is updated successfully`);
                  updateLocalState({ open: false });
                })
                .catch(() => {
                  notifyError(`Unable to update QC Reports`);
                });
            }}
          />
        )}
      </>
    );
  }

  return WrappedComponentWithHOC;
}

export default withQualityCertificationsPopupHOC;
