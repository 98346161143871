// Import settings
import React from 'react';

// Import color palette
import { colors } from '../palette';

// Import material UI components
import {
  ExpansionPanel as MuiExpansionPanel,
  ExpansionPanelDetails as MuiExpansionPanelDetails,
  ExpansionPanelSummary as MuiExpansionPanelSummary,
  Typography,
} from '@material-ui/core';
import { Add as AddIcon, Remove as RemoveIcon } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';

const ExpansionPanel = withStyles({
  root: {
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiExpansionPanel);

const ExpansionPanelSummary = withStyles({
  root: {
    borderBottom: colors.expansionPanelBorder,
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiExpansionPanelSummary);

const ExpansionPanelDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiExpansionPanelDetails);

export default function FAQExpansionPanel({
  faqQn,
  faqAns,
  expanded,
  onChange,
}) {
  return (
    <div>
      <ExpansionPanel square expanded={expanded} onChange={onChange}>
        <ExpansionPanelSummary
          expandIcon={expanded ? <RemoveIcon /> : <AddIcon />}
        >
          <Typography
            style={{
              color: expanded ? `${colors.blue060}` : `${colors.fontGrey}`,
              fontFamily: 'Open Sans',
              fontStyle: 'normal',
              fontWeight: 'bold',
              fontSize: '16px',
              letterSpacing: '0.15em',
            }}
          >
            <span style={{ marginRight: 10 }}>⬤</span>
            {faqQn}
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Typography
            style={{
              color: `${colors.fontGrey}`,
              fontFamily: 'Open Sans',
              fontStyle: 'normal',
              fontWeight: 'normal',
              fontSize: '1rem',
              letterSpacing: '0.15em',
              paddingLeft: 35,
            }}
          >
            {faqAns}
          </Typography>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </div>
  );
}
