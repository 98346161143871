import React from 'react';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles/index';

import { Icon, Tooltip } from '@material-ui/core';

import WorkHistoryIcon from '../assets/icons/work_history.svg';
import WorkHistoryDisabledIcon from '../assets/icons/work_history_disabled.svg';

import { colors } from '../palette';

const useStyles = makeStyles(() => ({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    color: colors.lightGray,
    zIndex: 1,
    border: `2px solid ${colors.lightGray}`,
    borderRadius: '4px',
    minHeight: '1.1rem',
    minWidth: '2rem',
    width: '9.5rem',
    columnGap: '3px',
    justifyContent: 'center',
    margin: '3px 0',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    cursor: 'pointer',
  },
  watchLabelActive: {
    borderColor: colors.blue050,
    color: colors.blue050,
    width: '9.5rem',
  },
  onlyIcon: {
    width: '2.5rem',
    height: '1.125rem',
  },
  imageIcon: {
    display: 'flex',
    justifyContent: 'center',
    height: 28,
    width: 22,
    paddingLeft: 3,
  },
}));

export const ViewAllowSuppliersButton = (props) => {
  const classes = useStyles();

  const {
    withText = false,
    callbackSuccess = () => null,
    onClick = () => null,
    disabled = false,
  } = props;

  return (
    <Tooltip
      title={
        disabled
          ? 'No suppliers can view this item'
          : 'View supplier visibility'
      }
      arrow
    >
      <div
        className={clsx(
          classes.wrapper,
          !disabled && classes.watchLabelActive,
          !withText && classes.onlyIcon
        )}
        onClick={(evt) => {
          evt.preventDefault();
          if (disabled) {
            return;
          }
          onClick();
          callbackSuccess();
        }}
        data-cy='view-allow-supplier-button'
      >
        <React.Fragment>
          <Icon>
            <img
              className={classes.imageIcon}
              src={disabled ? WorkHistoryDisabledIcon : WorkHistoryIcon}
              color='primary'
              alt=''
            />
          </Icon>
        </React.Fragment>
      </div>
    </Tooltip>
  );
};
