import queryString from 'query-string';

import { fetchWithErrorHandling, getAuthorizedHeader } from '../utils/apiUtils';

import { BACKEND_SERVICE_URL } from '../constants';

// -------------------------------------------------------------------------------------------------

export const getAllCompanies = async () => {
  const requestUrl = `${BACKEND_SERVICE_URL}/companies/all-companies`;
  return fetchWithErrorHandling(requestUrl, {
    method: 'GET',
    headers: getAuthorizedHeader(),
  });
};

export const updateCompany = async (companyID, body) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/companies/update/${companyID}`;
  return fetchWithErrorHandling(requestUrl, {
    method: 'PUT',
    headers: getAuthorizedHeader(),
    body: JSON.stringify(body),
  });
};

export const getDifferentValuedCompanyAndUsers = async (params) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/companies/different-values-company-and-users?${queryString.stringify(
    params
  )}`;
  return fetchWithErrorHandling(requestUrl, {
    method: 'GET',
    headers: getAuthorizedHeader(),
  });
};

export const createCompany = async (body) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/companies/create`;
  return fetchWithErrorHandling(requestUrl, {
    method: 'POST',
    headers: getAuthorizedHeader(),
    body: JSON.stringify(body),
  });
};

export const deleteCompany = async (companyID) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/companies/delete/${companyID}`;
  return fetchWithErrorHandling(requestUrl, {
    method: 'DELETE',
    headers: getAuthorizedHeader(),
  });
};
