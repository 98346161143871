import React, { useState } from 'react';
import { FtrButton, FtrTypography } from '../components/ftr-components';
import { useMediaQuery, useTheme } from '@material-ui/core';

import screwIcon from '../assets/icons/screw.svg';

import { getDefaultCADFile } from '../utils/fileUtils';

function GetSampleUpload(props) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [isLoading, setIsLoading] = useState(false);

  const { handleCadFilesChange } = props;

  const handleSamplePartUpload = async () => {
    setIsLoading(true);
    const defaultFile = await getDefaultCADFile();
    handleCadFilesChange(defaultFile);
  };

  return (
    <div>
      <FtrTypography
        type='heading'
        fontSize={isMobile ? '16' : '18'}
        style={{
          paddingTop: isMobile ? '2rem' : '5rem',
          textAlign: 'center',
        }}
      >
        Get started quickly and see how Factorem works
      </FtrTypography>
      <div
        style={{
          marginTop: 20,
          fontSize: '10pt',
          textAlign: 'center',
          paddingBottom: '2rem',
        }}
      >
        <FtrButton
          id='use-sample-part'
          color='black'
          size='small'
          onClick={handleSamplePartUpload}
          startIcon={<img src={screwIcon} alt='logo' />}
          loading={isLoading}
        >
          Use sample part
        </FtrButton>
      </div>
    </div>
  );
}

export default GetSampleUpload;
