/* eslint-disable react/no-unknown-property */
import { useLoader, useThree } from 'react-three-fiber';
import { STLLoader } from 'three/examples/jsm/loaders/STLLoader';
import React, { useImperativeHandle, forwardRef, Fragment } from 'react';
import * as THREE from 'three';

const Model = ({ stlFile }, ref) => {
  const { camera } = useThree();
  const [opacity, setOpacity] = React.useState(1.0);
  const position = [0, 0, 0];
  const meshRef = React.useRef();

  //Load stl file and create mesh
  const stl = useLoader(STLLoader, stlFile);
  const mesh = new THREE.Mesh(stl);

  //Compute the maximum co-ordinate of the mesh
  const maxCoord = computeMaxCoordinate(mesh, camera) * 5;
  console.log(maxCoord);

  //set the camera position to the maxCoord + offset
  camera.position.set(maxCoord, maxCoord, maxCoord);
  camera.updateProjectionMatrix();
  console.log(camera);

  //Create the edge outline for the mesh
  const edges = new THREE.EdgesGeometry(stl, 13);
  const material = new THREE.LineBasicMaterial({
    color: 0x000000,
  });
  const outline = new THREE.LineSegments(edges, material);

  mesh.geometry.center();
  outline.geometry.center();

  useImperativeHandle(ref, () => ({
    resetCamera: () => {
      // reset look-at (target) and camera position
      camera.position.set(maxCoord, maxCoord, maxCoord);
      camera.rotation.order = 'YXZ';
      camera.rotation.y = -Math.PI / 4;
      camera.rotation.x = Math.atan(-1 / Math.sqrt(2));
      console.log(meshRef);

      // needed for trackball controls, reset the up vector
      camera.up.set(0, 1, 0);
    },
    frontView: () => {
      // reset look-at (target) and camera position
      camera.position.set(0, 0, maxCoord * 1.5);

      // needed for trackball controls, reset the up vector
      camera.up.set(0, 1, 0);
    },

    rightView: () => {
      // reset look-at (target) and camera position
      // controls.current.target.set(0, 0, 0)
      camera.position.set(maxCoord * 1.5, 0, 0);

      // needed for trackball controls, reset the up vector
      camera.up.set(0, 1, 0);
    },
    topView: () => {
      // reset look-at (target) and camera position
      // controls.current.target.set(0, 0, 0)
      camera.position.set(0, maxCoord * 1.5, 0);

      // needed for trackball controls, reset the up vector
      camera.up.set(0, 0, 1);
    },

    setTransparent: () => {
      if (opacity === 1.0) {
        setOpacity(0.5);
      } else {
        setOpacity(1.0);
      }
    },
  }));

  return (
    <Fragment>
      <mesh geometry={stl} ref={meshRef} position={position}>
        <meshStandardMaterial
          attach='material'
          color={0x696969} // Changed color to grey
          metalness={0.1}
          roughness={0.8}
          name={'metallic'}
          side={THREE.DoubleSide}
          transparent={true}
          opacity={opacity}
        />
      </mesh>

      <pointLight position={[maxCoord, maxCoord, maxCoord]} intensity={0.5} />
      <lineSegments geometry={edges} material={material} position={position} />
    </Fragment>
  );
};

function computeMaxCoordinate(mesh, camera) {
  const boundingBox = new THREE.Box3();
  mesh.geometry.computeBoundingBox();
  boundingBox.copy(mesh.geometry.boundingBox).applyMatrix4(mesh.matrixWorld);
  console.log(boundingBox);
  let center = new THREE.Vector3();
  center = boundingBox.getCenter(center);

  let size = new THREE.Vector3();
  size = boundingBox.getSize(size);
  console.log(center, size);

  const maxDim = Math.max(size.x, size.y, size.z);
  const fov = camera.fov * (Math.PI / 180);
  const cameraZ = Math.abs((maxDim / 2) * Math.tan(fov * 2));

  return cameraZ * 5;
}

export default forwardRef(Model);
