import queryString from 'query-string';

import { BACKEND_SERVICE_URL } from '../constants';
import { fetchWithErrorHandling, getAuthorizedHeader } from '../utils/apiUtils';

export const createPartsLibraryProject = async (body) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/parts-library/project/create`;
  return fetch(requestUrl, {
    method: 'POST',
    headers: getAuthorizedHeader(),
    body: JSON.stringify(body),
  }).then(async (response) => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return response.json();
  });
};

export const getPartsLibraryProjectByUserID = async (userID, params) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/parts-library/project/${userID}?${queryString.stringify(
    params
  )}`;
  return fetch(requestUrl, {
    method: 'GET',
    headers: getAuthorizedHeader(),
  }).then(async (response) => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return response.json().then((data) => data.projects);
  });
};

export const editPartsLibraryByItemID = async (itemID, body) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/parts-library/edit/${itemID}`;
  return fetch(requestUrl, {
    method: 'PUT',
    headers: getAuthorizedHeader(),
    body: JSON.stringify(body),
  }).then(async (response) => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return response.json();
  });
};

export const updateProjectItemsPartLibrary = async (projectID, body) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/parts-library/project/edit/${projectID}`;
  return fetch(requestUrl, {
    method: 'PUT',
    headers: getAuthorizedHeader(),
    body: JSON.stringify(body),
  }).then((resp) => {
    if (!resp.ok) {
      throw Error(resp.statusText);
    }
    return resp.json();
  });
};

export const getAllPartsLibrary = async () => {
  const requestUrl = `${BACKEND_SERVICE_URL}/parts-library`;
  return fetch(requestUrl, {
    method: 'GET',
    headers: getAuthorizedHeader(),
  }).then(async (response) => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return (await response.json()).data;
  });
};

export const getLatestDfmDefectsPartsLibrary = async (params) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/parts-library/extract-dfm-defects?${queryString.stringify(
    params
  )}`;
  return fetchWithErrorHandling(requestUrl, {
    method: 'GET',
    headers: getAuthorizedHeader(),
  });
};

export const extractDfmDefectPartsLibrary = async (id, body) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/parts-library/${id}/extract-dfm-defects`;
  return fetchWithErrorHandling(requestUrl, {
    method: 'POST',
    headers: getAuthorizedHeader(),
    body: JSON.stringify(body),
  });
};
