import React, { useReducer } from 'react';

import GenerateAutoBalloonForItemPopup from '../pages/order-ready-management/GenerateAutoBalloonForItemPopup';

import { isEmptyValue } from '../utils/commonUtils';

// -------------------------------------------------------------------------------------------------

function withGenerateAutoBalloonForItemPopupHOC(WrappedComponent) {
  function WrappedComponentWithHOC(props) {
    const defaultLocalState = {
      open: false,
    };

    const [localState, updateLocalState] = useReducer((prev, next) => {
      if (isEmptyValue(next)) {
        return defaultLocalState;
      }
      return { ...prev, ...next };
    }, defaultLocalState);

    return (
      <>
        <WrappedComponent
          updateGenerateAutoBalloonForItemPopupState={updateLocalState}
          {...props}
        />
        {localState.open && (
          <GenerateAutoBalloonForItemPopup
            open={localState.open}
            handleClose={() => updateLocalState({ open: false })}
          />
        )}
      </>
    );
  }

  // Set the displayName for the HOC
  const wrappedComponentName =
    WrappedComponent.displayName || WrappedComponent.name || 'Component';
  WrappedComponentWithHOC.displayName = `withSignUpPopup(${wrappedComponentName})`;

  return WrappedComponentWithHOC;
}

export default withGenerateAutoBalloonForItemPopupHOC;
