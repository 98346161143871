import React, { useContext } from 'react';
import { useSelector } from 'react-redux';

import {
  Dialog,
  DialogContent,
  DialogTitle,
  makeStyles,
} from '@material-ui/core';

import FtrSvgImage from '../images/FtrSvgImage';
import { FlexColumn, FlexColumnCenter, FlexRow } from '../layouts/FlexLayouts';
import { FtrB3, FtrB4, FtrButton, FtrH8 } from '../ftr-components';

import BonusSvgIcon from '../../assets/icons/bonus_icon.svg';
import BlackCheckSvgIcon from '../../assets/icons/black_check_icon.svg';
import CopySvgIcon from '../../assets/icons/copy_icon.svg';

import withActionTrackingHOC from '../../hocs/withActionTrackingHOC';

import { copyToClipboard } from '../../utils/commonUtils';

import { getUserIDSelector } from '../../selectors/userSelector';

import AppContext from '../../context/AppContext';

import { colors } from '../../palette';

// -------------------------------------------------------------------------------------------------

const useStyles = makeStyles(() => ({
  backdrop: {
    backdropFilter: 'blur(3px)',
  },
  dialog: {
    padding: '1.5rem',
    borderRadius: '1.5rem',
  },
  title: {
    display: 'flex',
    fontSize: '22px',
    fontWeight: '700',
    alignItems: 'center',
    justifyContent: 'center',
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: 425,
  },
  closeIcon: {
    position: 'absolute',
    right: '1.5rem',
    top: '1.5rem',
    color: colors.neutral060,
    width: '1.5rem',
    height: '1.5rem',
    cursor: 'pointer',
  },
}));

const KEY_POINTS = [
  'Speedy best-in-market quotations',
  'Great quality and on-time delivery',
  'Best manufacturing network in SEA',
  'End-to-End transparency on orders',
];

const DismissButtonWithTracking = withActionTrackingHOC(FtrButton);

function WelcomeBonusPopup(props) {
  const classes = useStyles();

  const {
    open,
    onConfirm = () => {},
    onClose = () => {},
    actionTrackingKey = '',
  } = props;

  const { setSnackbarMessage = () => {} } = useContext(AppContext);

  const userID = useSelector(getUserIDSelector);

  return (
    <Dialog
      open={open}
      width='md'
      maxWidth='xl'
      scroll='body'
      BackdropProps={{
        classes: {
          root: classes.backdrop,
        },
      }}
      PaperProps={{ className: classes.dialog }}
    >
      <DialogTitle disableTypography className={classes.title}>
        <img style={{ padding: '1rem 1rem' }} src={BonusSvgIcon} alt='icon' />
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <FtrH8 style={{ marginBottom: '1rem' }}>Your welcome bonus</FtrH8>
        <FtrB3 style={{ textAlign: 'center' }}>
          Get started using Factorem using a special discount just for you!
        </FtrB3>
        {renderBonusPromoCode()}
        <FtrB3>Get 50% off your first Order with Factorem!</FtrB3>
        <FtrB3>Simply upload your design files and proceed to checkout:</FtrB3>
        {renderKeyPoints()}
        {renderDialogActions()}
      </DialogContent>
    </Dialog>
  );

  function renderBonusPromoCode() {
    return (
      <FlexRow style={{ margin: '2rem 0 1.5rem 0' }}>
        <FtrB4 style={{ fontWeight: 700 }}>FCTRM50OFF</FtrB4>
        <FtrSvgImage
          src={CopySvgIcon}
          alt='icon'
          style={{
            width: '1.5rem',
            height: '1.5rem',
            cursor: 'pointer',
          }}
          onClick={() => {
            copyToClipboard('FCTRM50OFF');
            setSnackbarMessage('Copied to clipboard!');
          }}
        />
      </FlexRow>
    );
  }

  function renderKeyPoints() {
    return (
      <FlexColumnCenter style={{ marginTop: '2rem' }}>
        <FlexColumn>
          {KEY_POINTS.map((keyPoint) => {
            return (
              <FlexRow
                style={{ alignItems: 'center', gap: '0.5rem' }}
                key={keyPoint}
              >
                <img src={BlackCheckSvgIcon} alt='icon' />
                <FtrB3>{keyPoint}</FtrB3>
              </FlexRow>
            );
          })}
        </FlexColumn>
      </FlexColumnCenter>
    );
  }

  function renderDialogActions() {
    return (
      <FlexColumnCenter
        style={{
          gap: '1rem',
          marginTop: '3rem',
          marginBottom: '1.5rem',
          maxWidth: 200,
          width: '100%',
          alignSelf: 'center',
        }}
      >
        <DismissButtonWithTracking
          id='got-it-btn'
          color='blue'
          variant='contained'
          onClick={(e) => {
            onConfirm(e);
            onClose();
          }}
          userID={userID}
          actionTrackingKey={actionTrackingKey}
        >
          Got it
        </DismissButtonWithTracking>
      </FlexColumnCenter>
    );
  }
}

export default WelcomeBonusPopup;
