import React, { useState } from 'react';
import { Button, Fab, Tooltip, Typography } from '@material-ui/core';
import { Visibility } from '@material-ui/icons';
import { useSelector } from 'react-redux';

import ViewAsAccountPopup from './popups/ViewAsAccountPopup';

import { colors } from '../palette';

import { isAdminOrHigherRole } from '../utils/roleUtils';

import { getUserSelector } from '../selectors/userSelector';

import withViewAsSupplierHOC from '../hocs/withViewAsSupplierHOC';

function ViewAsSupplierPopupButton({
  showResetButton,
  itemIDs,
  user,
  resetViewAsSupplier,
  updateViewAsSupplier,
}) {
  const { userID: loggedInUserID, role: loggedInUserRole } = useSelector(
    getUserSelector
  );

  const [openPopup, setOpenPopup] = useState(false);

  const isSupplierView = user.userID && user.userID !== loggedInUserID;

  const renderFab = () => (
    <Tooltip title='View as supplier'>
      <Fab
        onClick={() => {
          setOpenPopup(true);
        }}
        color='primary'
        size='small'
      >
        <Visibility style={{ fontSize: '15pt' }} />
      </Fab>
    </Tooltip>
  );

  const renderResetButtonWithText = () => (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        padding: '0.5rem 0',
        fontStyle: 'italic',
      }}
    >
      <Typography>Viewing as:&nbsp;</Typography>
      <Typography
        style={{
          fontWeight: 'bold',
          color: colors.blue050,
        }}
      >
        {user.displayName}&nbsp;
      </Typography>
      {showResetButton && (
        <Tooltip title='Reset to view as admin account'>
          <Button
            variant='outlined'
            color='secondary'
            style={{
              marginLeft: '0.5rem',
            }}
            onClick={resetViewAsSupplier}
          >
            Reset
          </Button>
        </Tooltip>
      )}
    </div>
  );

  if (!isAdminOrHigherRole(loggedInUserRole)) {
    return null;
  }

  return (
    <>
      {!isSupplierView && renderFab()}
      {isSupplierView && renderResetButtonWithText()}
      {openPopup && (
        <ViewAsAccountPopup
          open={openPopup}
          handleClose={() => setOpenPopup(false)}
          handleOk={updateViewAsSupplier}
          itemIDs={itemIDs}
        />
      )}
    </>
  );
}

export default withViewAsSupplierHOC(ViewAsSupplierPopupButton);
