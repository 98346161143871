import { getAuthorizedHeader } from '../utils/apiUtils';

import { BACKEND_SERVICE_URL } from '../constants';
import { notifyError } from '../services/notificationService';

export const createMultipleCheckoutPaymentIntent = async (body) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/stripe/multiple-checkout/create-payment-intent`;
  return fetch(requestUrl, {
    method: 'POST',
    headers: getAuthorizedHeader(),
    body: JSON.stringify(body),
  }).then(async (response) => {
    if (!response.ok) {
      const json = await response.json();
      if (json.message) {
        throw Error(json.message);
      }
      throw Error(response.statusText);
    }
    return response.json();
  });
};

export const getAllPromotionCodes = async () => {
  const requestUrl = `${BACKEND_SERVICE_URL}/stripe/promotion-codes`;
  return fetch(requestUrl, {
    method: 'GET',
    headers: getAuthorizedHeader(),
  }).then(async (response) => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return (await response.json()).data;
  });
};

export const createPromotionCode = async (body) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/stripe/promotion-codes`;
  return fetch(requestUrl, {
    method: 'POST',
    headers: getAuthorizedHeader(),
    body: JSON.stringify(body),
  }).then(async (response) => {
    if (!response.ok) {
      response.text().then((text) => {
        const err = JSON.parse(text);
        notifyError(err.error);
      });
      throw Error(response.statusText);
    }
    return (await response.json()).data;
  });
};

export const updatePromotionCode = async (promotionCodeID, body) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/stripe/promotion-codes/${promotionCodeID}`;
  return fetch(requestUrl, {
    method: 'PUT',
    headers: getAuthorizedHeader(),
    body: JSON.stringify(body),
  }).then(async (response) => {
    if (!response.ok) {
      response.text().then((text) => {
        const err = JSON.parse(text);
        notifyError(err.error);
      });
      throw Error(response.statusText);
    }
    return (await response.json()).data;
  });
};

export const deletePromotionCode = async (promotionCodeID) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/stripe/promotion-codes/${promotionCodeID}`;
  return fetch(requestUrl, {
    method: 'DELETE',
    headers: getAuthorizedHeader(),
  }).then(async (response) => {
    if (!response.ok) {
      response.text().then((text) => {
        const err = JSON.parse(text);
        notifyError(err.error);
      });
      throw Error(response.statusText);
    }
    return response.json();
  });
};
