import { useEffect, useState } from 'react';

const useDeliveryMessageToggles = (isOverseas = false) => {
  const [deliveryMessageToggles, setDeliveryMessageToggles] = useState({
    packaging: {
      name: 'Packaging',
      isDisabled: false,
      isChecked: false,
    },
    isQCRequired: {
      name: 'Quality Check',
      isDisabled: false,
      isChecked: false,
    },
    isDHLShipping: {
      name: 'Include DHL',
      isDisabled: false,
      isChecked: false,
    },
    isDescriptionRequired: {
      name: 'Include Description',
      isDisabled: false,
      isChecked: false,
    },
  });

  useEffect(() => {
    setDeliveryMessageToggles({
      packaging: {
        name: 'Packaging',
        isDisabled: false,
        isChecked: false,
      },
      isQCRequired: {
        name: 'Quality Check',
        isDisabled: false,
        isChecked: false,
      },
      isDHLShipping: {
        name: 'Include DHL',
        isDisabled: !isOverseas,
        isChecked: false,
      },
      isDescriptionRequired: {
        name: 'Include Description',
        isDisabled: false,
        isChecked: false,
      },
    });
  }, [isOverseas]);

  const handleDeliveryMessageToggles = (event) => {
    const { name, checked } = event.target;
    setDeliveryMessageToggles((prevToggles) => {
      const updatedToggleKey = Object.keys(prevToggles).find(
        (key) => prevToggles[key].name === name
      );

      return {
        ...prevToggles,
        [updatedToggleKey]: {
          ...prevToggles[updatedToggleKey],
          isChecked: checked,
        },
      };
    });
  };

  return [deliveryMessageToggles, handleDeliveryMessageToggles];
};

export default useDeliveryMessageToggles;
