import React, { useState } from 'react';
import { IconButton, Tooltip } from '@material-ui/core';
import FileCopyIcon from '@material-ui/icons/FileCopy';

import { copyToClipboard } from '../utils/commonUtils';

import { FlexRowCenter } from './layouts/FlexLayouts';

import { notifyWarning } from '../services/notificationService';

/**
 * Copies a message to clipboard. If both messageFn and message are provided, will take the result of calling messageFn.
 *
 * @param message
 * @param isDisabled
 * @param disabledTooltipText
 * @param copyableTooltipTitle
 * @param afterCopy
 * @returns {Element}
 * @constructor
 */
const CopyToClipboardIcon = ({
  message = '',
  isDisabled = false,
  disabledTooltipText = '',
  copyableTooltipTitle = 'Copy to clipboard',
  afterCopy = () => {},
}) => {
  const [copied, setCopied] = useState(false);

  const copyTooltipTitle = copied ? 'Copied!' : copyableTooltipTitle;

  const tooltipTitle = isDisabled ? disabledTooltipText : copyTooltipTitle;

  const handleError = (err) => {
    const errMessage = `'Failed to copy text: ', ${err}`;
    notifyWarning(errMessage);
  };

  const handleCopy = () => {
    copyToClipboard(message)
      .then(() => {
        setCopied(true);
        setTimeout(() => setCopied(false), 2000);
        afterCopy();
      })
      .catch(handleError);
  };

  return (
    <FlexRowCenter>
      <Tooltip title={tooltipTitle}>
        <span style={{ display: 'inline-block' }}>
          <IconButton disabled={isDisabled} onClick={handleCopy}>
            <FileCopyIcon />
          </IconButton>
        </span>
      </Tooltip>
    </FlexRowCenter>
  );
};

export default CopyToClipboardIcon;
