import queryString from 'query-string';

import { fetchWithErrorHandling, getAuthorizedHeader } from '../utils/apiUtils';
import { isEmptyValue } from '../utils/commonUtils';

import { BACKEND_SERVICE_URL } from '../constants';

// -------------------------------------------------------------------------------------------------

export const adminGetAllQuotations = async (params) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/quotations/search?${queryString.stringify(
    params
  )}`;
  return fetchWithErrorHandling(requestUrl, {
    method: 'GET',
    headers: getAuthorizedHeader(),
  });
};

export const clearGetAllQuotationsCacheApi = async () => {
  const requestUrl = `${BACKEND_SERVICE_URL}/quotations/clear-cache/get-all-quotations`;
  return fetchWithErrorHandling(requestUrl, {
    method: 'GET',
    headers: getAuthorizedHeader(),
  });
};

export const adminExportQuotationsAsCsv = async (params) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/quotations/search/csv-export?${queryString.stringify(
    params
  )}`;
  return fetch(requestUrl, {
    method: 'GET',
    headers: getAuthorizedHeader(),
  }).then(async (response) => {
    return response;
  });
};

export const supplierExportAcceptedQuotationsAsCsv = async (
  userID,
  tableQueryParams
) => {
  const params = {
    ...tableQueryParams,
    userID,
    status: 'accepted',
  };
  const requestUrl = `${BACKEND_SERVICE_URL}/quotations/search/suppliers/csv-export?${queryString.stringify(
    params
  )}`;
  return fetch(requestUrl, {
    method: 'GET',
    headers: getAuthorizedHeader(),
  }).then(async (response) => {
    return response;
  });
};

export const adminGetRejectedQuotations = async (params) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/quotations/rejected/search?${queryString.stringify(
    params
  )}`;
  return fetchWithErrorHandling(requestUrl, {
    method: 'GET',
    headers: getAuthorizedHeader(),
  });
};

export const adminExportRejectedQuotationsAsCsv = async (params) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/quotations/rejected/search/csv-export?${queryString.stringify(
    params
  )}`;
  return fetch(requestUrl, {
    method: 'GET',
    headers: getAuthorizedHeader(),
  }).then(async (response) => {
    return response;
  });
};

export const adminGetAcceptedQuotations = async (params) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/quotations/accepted/search?${queryString.stringify(
    params
  )}`;
  return fetchWithErrorHandling(requestUrl, {
    method: 'GET',
    headers: getAuthorizedHeader(),
  });
};

export const adminGetVerifyingQuotations = async (params) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/quotations/verifying/search?${queryString.stringify(
    params
  )}`;
  return fetchWithErrorHandling(requestUrl, {
    method: 'GET',
    headers: getAuthorizedHeader(),
  });
};

export const adminGetAllQuotationsByParams = async (params) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/quotations?${queryString.stringify(
    params
  )}`;
  return fetchWithErrorHandling(requestUrl, {
    method: 'GET',
    headers: getAuthorizedHeader(),
  });
};

export const uploadSupplierInvoice = async (body) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/quotations/upload-supplier-invoice`;
  return fetch(requestUrl, {
    method: 'PUT',
    headers: getAuthorizedHeader(),
    body: JSON.stringify(body),
  }).then(async (response) => {
    const res = await response.json();
    if (!response.ok) {
      throw new Error(
        `${response.statusText}${res.message ? `: ${res.message}` : ''}`
      );
    }
    return res;
  });
};

export const deleteSupplierInvoice = async (body) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/quotations/delete-supplier-invoice`;
  return fetchWithErrorHandling(requestUrl, {
    method: 'PUT',
    headers: getAuthorizedHeader(),
    body: JSON.stringify(body),
  });
};

export const adminExportAcceptedQuotationsAsCsv = async (params) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/quotations/accepted/search/csv-export?${queryString.stringify(
    params
  )}`;
  return fetch(requestUrl, {
    method: 'GET',
    headers: getAuthorizedHeader(),
  }).then(async (response) => {
    return response;
  });
};

export const adminExportVerifyingQuotationsAsCsv = async (params) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/quotations/verifying/search/csv-export?${queryString.stringify(
    params
  )}`;
  return fetch(requestUrl, {
    method: 'GET',
    headers: getAuthorizedHeader(),
  }).then(async (response) => {
    return response;
  });
};

export const createBulkQuotations = async (body) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/quotations/bulk-create`;
  return fetch(requestUrl, {
    method: 'POST',
    headers: getAuthorizedHeader(),
    body: JSON.stringify(body),
  }).then(async (response) => {
    if (!response.ok) {
      const json = await response.json();
      const { message } = json;
      if (message.startsWith('ER_DUP_ENTRY: Duplicate entry')) {
        throw new Error(
          `Quotation for this supplier and this project is existed.`
        );
      }
      throw new Error(response.statusText);
    }
    return response.json();
  });
};

export const getAllMyQuotations = async (userId) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/user/quotations/${userId}`;
  return fetchWithErrorHandling(requestUrl, {
    method: 'GET',
    headers: getAuthorizedHeader(),
  });
};

export const getAcceptedQuotationsBySupplier = async (
  userId,
  tableQueryParams
) => {
  const params = {
    ...tableQueryParams,
    status: 'accepted',
  };
  const requestUrl = `${BACKEND_SERVICE_URL}/user/v2/quotations/${userId}?${queryString.stringify(
    params
  )}`;
  return fetch(requestUrl, {
    method: 'GET',
    headers: getAuthorizedHeader(),
  }).then(async (response) => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    const res = await response.json();
    const { success } = res;
    if (!success) {
      throw Error(`Failed to fetch the accepted quotations, ${res?.message}`);
    }
    return res.data;
  });
};

export const getQuotationByID = async (quotationId) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/quotations/${quotationId}`;
  const data = await fetchWithErrorHandling(requestUrl, {
    method: 'GET',
    headers: getAuthorizedHeader(),
  });

  return !isEmptyValue(data) ? data[0] : null;
};

export const deleteSupplierOrder = async (quoteID) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/quotations/${quoteID}`;
  return fetchWithErrorHandling(requestUrl, {
    method: 'DELETE',
    headers: getAuthorizedHeader(),
  });
};

export const approveMultipleQuotations = async (body) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/quotations/approve`;
  return fetchWithErrorHandling(requestUrl, {
    method: 'POST',
    headers: getAuthorizedHeader(),
    body: JSON.stringify(body),
  });
};

export const adminUpdateQuotation = async (quotationID, body) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/quotations/${quotationID}/admin-edit`;
  return fetchWithErrorHandling(requestUrl, {
    method: 'PUT',
    headers: getAuthorizedHeader(),
    body: JSON.stringify(body),
  });
};

export const supplierAcknowledgePO = async (quotationID) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/quotations/${quotationID}/acknowledge`;
  return fetchWithErrorHandling(requestUrl, {
    method: 'PUT',
    headers: getAuthorizedHeader(),
  });
};

export const supplierBulkAcknowledgePO = async (body) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/quotations/bulk-acknowledge`;
  return fetchWithErrorHandling(requestUrl, {
    method: 'PUT',
    headers: getAuthorizedHeader(),
    body: JSON.stringify(body),
  });
};

export const getAllPoAutomationQuotations = async () => {
  const requestUrl = `${BACKEND_SERVICE_URL}/quotations/po-automation`;
  return fetchWithErrorHandling(requestUrl, {
    method: 'GET',
    headers: getAuthorizedHeader(),
  });
};

export const setReasonRejectedQuotation = async (body) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/quotations/reason-rejected`;
  return fetchWithErrorHandling(requestUrl, {
    method: 'POST',
    headers: getAuthorizedHeader(),
    body: JSON.stringify(body),
  });
};

export const getAcceptedQuoteByItemID = async (itemID) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/quotations/accepted/${itemID}`;
  return fetchWithErrorHandling(requestUrl, {
    method: 'GET',
    headers: getAuthorizedHeader(),
  });
};

export const getQuotesWithItemDetails = async () => {
  const requestUrl = `${BACKEND_SERVICE_URL}/quotations/item-details`;
  return fetchWithErrorHandling(requestUrl, {
    method: 'GET',
    headers: getAuthorizedHeader(),
  });
};

export const updateReasonQuotations = async (body) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/quotations/reason`;
  return fetchWithErrorHandling(requestUrl, {
    method: 'POST',
    headers: getAuthorizedHeader(),
    body: JSON.stringify(body),
  });
};

export const getAllRequestQuotes = async () => {
  const requestUrl = `${BACKEND_SERVICE_URL}/quotations/request-quotes`;
  return fetchWithErrorHandling(requestUrl, {
    method: 'GET',
    headers: getAuthorizedHeader(),
  });
};

export const updateRequestQuotes = async (quotationID, body) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/quotations/${quotationID}/request-quotes`;
  return fetchWithErrorHandling(requestUrl, {
    method: 'PATCH',
    headers: getAuthorizedHeader(),
    body: JSON.stringify(body),
  });
};

export const getOriginalPpeQuoteOfItem = async (itemID) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/items/${itemID}/ppe-quote`;
  return fetchWithErrorHandling(requestUrl, {
    method: 'GET',
    headers: getAuthorizedHeader(),
  });
};

export const editItemDataPoint = async (itemID, body) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/item/${itemID}/datapoint`;
  return fetchWithErrorHandling(requestUrl, {
    method: 'POST',
    headers: getAuthorizedHeader(),
    body: JSON.stringify(body),
  });
};

export const createNewQuoteApi = async (body) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/quotation/create`;
  return fetchWithErrorHandling(requestUrl, {
    method: 'POST',
    headers: getAuthorizedHeader(),
    body: JSON.stringify(body),
  });
};

export const editQuotation = async (quotationID, body) => {
  const requestUrl = `${process.env.REACT_APP_BACKEND_SERVICE}/quotation/edit/${quotationID}`;
  return fetchWithErrorHandling(requestUrl, {
    method: 'POST',
    headers: getAuthorizedHeader(),
    body: JSON.stringify(body),
  });
};
