import React, { useEffect, useState } from 'react';

import FtrTextField from './FtrTextField';

// ------------------------------------------------------------------------------------

function FtrNumberField(props) {
  const {
    value: initialValue = 1,
    minValue,
    helperText = '',
    style = {},
    ...restProps
  } = props;

  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  return (
    <FtrTextField
      type='number'
      onChange={(evt) => {
        const newValue = Number(evt.target.value);
        if (!isNaN(minValue) && newValue < minValue) {
          setValue(minValue);
          return;
        }
        setValue(newValue);
      }}
      value={value}
      style={{
        ...style,
      }}
      helperText={helperText}
      onWheel={(e) => e.target.blur()}
      {...restProps}
    />
  );
}

export default FtrNumberField;
