import jwt_decode from 'jwt-decode';

import { isEmptyValue } from './commonUtils';

// -------------------------------------------------------------------------------------------------

/**
 * Decodes a given JWT token and returns a decoded object.
 *
 * If the token is empty, it will return an empty object.
 *
 * The decoded object will have the following properties if they are available:
 * - `iat`: The issued at ISO string.
 * - `exp`: The expired at ISO string.
 *
 * @param {String} token The JWT token.
 * @return {Object} The decoded object.
 */
export const decodeJwtToken = (token) => {
  try {
    if (isEmptyValue(token)) {
      return {};
    }

    const decodedToken = jwt_decode(token) || {};

    if (!isEmptyValue(decodedToken?.iat)) {
      decodedToken.issuedAt = new Date(decodedToken.iat * 1000).toISOString();
    }

    if (!isEmptyValue(decodedToken?.exp)) {
      decodedToken.expiredAt = new Date(decodedToken.exp * 1000).toISOString();
    }

    return decodedToken;
  } catch (error) {
    console.warn(error);
    return {};
  }
};
