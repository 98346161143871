import { getAuthorizedHeader } from '../utils/apiUtils';
import { BACKEND_SERVICE_URL } from '../constants';

export const upsertPriceFeedback = async (request) => {
  const {
    iqLogsID,
    userID,
    priceFeedback,
    targetPrice = null,
    additionalRemarks = null,
    feedbackDetails = null,
    unitPrice = null,
    quotationID = null,
    sendRevisedPriceEmail = false,
  } = request;
  const requestUrl = `${BACKEND_SERVICE_URL}/ppe-feedback/price-feedback`;
  return fetch(requestUrl, {
    method: 'POST',
    headers: getAuthorizedHeader(),
    body: JSON.stringify({
      iqLogsID,
      userID,
      priceFeedback,
      targetPrice,
      additionalRemarks,
      feedbackDetails,
      unitPrice,
      request,
      quotationID,
      sendRevisedPriceEmail,
    }),
  }).then(async (response) => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return (await response.json()).data;
  });
};

export const upsertLeadTimeFeedback = (request) => {
  const {
    iqLogsID,
    userID,
    leadTimeFeedback,
    givenLeadTime = null,
    targetLeadTime = null,
  } = request;
  const requestUrl = `${BACKEND_SERVICE_URL}/ppe-feedback/lead-time-feedback`;
  fetch(requestUrl, {
    method: 'POST',
    headers: getAuthorizedHeader(),
    body: JSON.stringify({
      iqLogsID,
      userID,
      leadTimeFeedback,
      givenLeadTime,
      targetLeadTime,
    }),
  });
};

export const getAllPPELeadtimeQuoteFeedback = async () => {
  const requestUrl = `${BACKEND_SERVICE_URL}/ppe-feedback/lead-time-feedback`;
  return fetch(requestUrl, {
    method: 'GET',
    headers: getAuthorizedHeader(),
  }).then(async (response) => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return (await response.json()).data;
  });
};

export const getAllPPEPriceQuoteFeedback = async () => {
  const requestUrl = `${BACKEND_SERVICE_URL}/ppe-feedback/price-feedback`;
  return fetch(requestUrl, {
    method: 'GET',
    headers: getAuthorizedHeader(),
  }).then(async (response) => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return (await response.json()).data;
  });
};

export const updatePpeFeedbackToHasSeen = async () => {
  const requestUrl = `${BACKEND_SERVICE_URL}/ppe-feedback/price-feedback/has-seen`;
  return fetch(requestUrl, {
    method: 'PATCH',
    headers: getAuthorizedHeader(),
  }).then(async (response) => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return (await response.json()).data;
  });
};

export const getUnSeenPpeFeedbackLog = async (userID) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/ppe-feedback/price-feedback/unseen/${userID}`;
  return fetch(requestUrl, {
    method: 'GET',
    headers: getAuthorizedHeader(),
  }).then(async (response) => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return (await response.json()).data;
  });
};
