import React, { useEffect, useReducer, useState } from 'react';
import { chunk } from 'lodash';
import { nanoid } from 'nanoid';

import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  FormControl,
  FormGroup,
  FormLabel,
} from '@material-ui/core';

import { ExpandMore } from '@material-ui/icons';

import FtrButton from '../../ftr-components/FtrButton';
import SearchBar from '../../grid-data/buttons/SearchBar';
import SurfaceFinishControlField from './SurfaceFinishControlField';
import {
  FlexColumn,
  FlexRow,
  FlexRowSpaceBetween,
} from '../../layouts/FlexLayouts';
import { FtrH5 } from '../../ftr-components/FtrTypography';

import { updatePpeControlConfigsV2 } from '../../../apis/ppeSettingsApi';

import { isEmptyValue } from '../../../utils/commonUtils';

import {
  notifyError,
  notifySuccess,
} from '../../../services/notificationService';

function PpeControlSurfaceFinishesForm(props) {
  const { title, surfaceFinishes: surfaceFinishesParam } = props;

  const [localState, updateLocalState] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      formID: nanoid(),
      surfaceFinishes: surfaceFinishesParam,
    }
  );

  const [beforeEditSurfaces, setBeforeEditSurfaces] = useState(
    JSON.parse(JSON.stringify(surfaceFinishesParam)) || []
  );
  const [modifiedSurfaces, setModifiedSurfaces] = useState(new Set());

  const displayMaterials = chunk([...localState.surfaceFinishes], 5);

  useEffect(() => {
    updateLocalState({ surfaceFinishes: surfaceFinishesParam });
    setBeforeEditSurfaces(surfaceFinishesParam);
  }, [surfaceFinishesParam]);

  const handleSearch = (keyword) => {
    if (isEmptyValue(keyword)) {
      updateLocalState({ surfaceFinishes: surfaceFinishesParam });
      return;
    }
    const _surfaces = localState.surfaceFinishes.filter((m) =>
      m.name.toLowerCase().includes(keyword.toLowerCase())
    );
    updateLocalState({ surfaceFinishes: _surfaces });
  };

  const handleConfigUpdate = (configID, updatedParams) => {
    const _surfaces = localState.surfaceFinishes.map((config) => {
      if (config.id !== configID) {
        return {
          ...config,
        };
      }
      return {
        ...config,
        ...updatedParams,
      };
    });
    updateLocalState({ surfaceFinishes: _surfaces });
    modifiedSurfaces.add(configID);
    setModifiedSurfaces(new Set(modifiedSurfaces));
  };

  const handleReset = () => {
    updateLocalState({
      formID: nanoid(),
      surfaceFinishes: beforeEditSurfaces,
    });
    setModifiedSurfaces(new Set());
  };

  const handleSave = () => {
    const modifiedSurfacesList = Array.from(modifiedSurfaces);
    const configs = localState.surfaceFinishes.filter((m) =>
      modifiedSurfacesList.includes(m.id)
    );

    if (isEmptyValue(configs)) {
      return;
    }

    const body = {
      configs,
    };
    updatePpeControlConfigsV2(body)
      .then(() => {
        notifySuccess('Config has been updated successfully.');
        setBeforeEditSurfaces(localState.surfaceFinishes);
        setModifiedSurfaces(new Set());
      })
      .catch(() => {
        notifyError('Updated failed');
      });
  };

  return (
    <Accordion
      defaultExpanded
      style={{
        overflowY: 'auto',
        overflowX: 'hidden',
        maxWidth: '100%',
        width: '100%',
      }}
    >
      <AccordionSummary expandIcon={<ExpandMore />}>
        <FlexRowSpaceBetween style={{ width: '100%' }}>
          <FormLabel component='legend'>
            <FtrH5>{title}</FtrH5>
          </FormLabel>
          <SearchBar onSearch={handleSearch} autoFocus={false} />
        </FlexRowSpaceBetween>
      </AccordionSummary>
      <AccordionDetails>
        <FormControl component='fieldset' style={{ width: '100%' }}>
          <FormGroup>
            <FlexRow
              style={{
                overflowY: 'auto',
                overflowX: 'auto',
                maxWidth: '100%',
                alignItems: 'start',
                gap: '0.2rem',
              }}
            >
              {displayMaterials.map((chunkArr, index) => {
                return (
                  <FlexColumn
                    key={`${localState.formID}-${index}`}
                    style={{
                      justifyContent: 'start',
                      marginBottom: '1rem',
                      paddingLeft: '1rem',
                      paddingRight: '1rem',
                      boxSizing: 'border-box',
                    }}
                  >
                    {chunkArr.map((surfaceFinish) => {
                      return (
                        <SurfaceFinishControlField
                          key={surfaceFinish.id}
                          surfaceFinish={surfaceFinish}
                          onSelectionFormChange={handleConfigUpdate}
                        />
                      );
                    })}
                  </FlexColumn>
                );
              })}
            </FlexRow>
          </FormGroup>
        </FormControl>
      </AccordionDetails>
      <AccordionActions
        style={{
          paddingRight: '1rem',
          paddingBottom: '1rem',
        }}
      >
        {modifiedSurfaces.size > 0 && (
          <FtrButton color='red' onClick={handleReset}>
            Reset
          </FtrButton>
        )}
        <FtrButton
          color='blue'
          disabled={modifiedSurfaces.size <= 0}
          onClick={handleSave}
        >
          Save
        </FtrButton>
      </AccordionActions>
    </Accordion>
  );
}

export default PpeControlSurfaceFinishesForm;
