// Import settings
import React, { useState, useEffect, useReducer } from 'react';
import { isEmpty } from 'lodash';

// Import material UI components
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  MenuItem,
  TextField,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles/index';

import RequiredReworkField from '../forms/form-fields/RequiredReworkField';
import QualityChecks from '../fields/QualityCheck';

import {
  getQualityProperties,
  getQualityPropertiesToUpdate,
} from '../../utils/itemUtils';

import { ITEM_STAGES, ITEM_STAGE_TYPE } from '../../constants';
import { paymentStatuses } from '../../constants';
import { ITEM_CANCELLATION_REASON } from '../../constants/itemConstants';

// Style the components
const useStyles = makeStyles(() => ({
  dialog: {
    style: { padding: '70px' },
  },
  label: {
    display: 'flex',
    justifyContent: 'flex-start',
    marginBottom: '0.5rem',
  },
  container: {
    padding: 0,
    display: 'flex',
    flexDirection: 'column',
  },
  reworkDeliveryDate: {
    '& .Mui-error': {
      color: 'blue',
    },
    '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
      borderColor: 'blue',
    },
  },
}));

const itemStatus = ITEM_STAGES.map((stage, index) => {
  return {
    value: index,
    label: stage,
  };
});

const paymentStatus = paymentStatuses.map((status) => {
  return {
    value: status.toLowerCase(),
    label: status,
  };
});

function EditItemStatusPopup(props) {
  const classes = useStyles();

  const { handleEditStatus, dialog, handleCancel, item } = props;

  const [editItemStatus, setEditItemStatus] = useState('');
  const [cancellationReason, setCancellationReason] = useState('');
  const [editPaymentStatus, setEditPaymentStatus] = useState('');
  const [requiredRework, setRequiredRework] = useState('');
  const [editReworkDeliveryDate, setEditReworkDeliveryDate] = useState(null);
  const [
    editReworkActualDeliveryDate,
    setEditReworkActualDeliveryDate,
  ] = useState(null);
  const [qualityInformation, updateQualityInformation] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      partnerDimQuality: null,
      partnerSFQuality: null,
      partnerVisualQuality: null,
      partnerPackagingQuality: null,
      customerDimQuality: null,
      customerSFQuality: null,
      customerVisualQuality: null,
      customerPackagingQuality: null,
    }
  );

  useEffect(() => {
    if (item) {
      setEditItemStatus(item.status || itemStatus[0].value);
      setCancellationReason(item.cancellationReason || '');
      setEditPaymentStatus(item.paymentStatus || paymentStatus[0].value);
      setRequiredRework(item.requiredRework);
      setEditReworkDeliveryDate(
        item.reworkDeliveryDate ? new Date(item.reworkDeliveryDate) : null
      );
      setEditReworkActualDeliveryDate(
        item.reworkActualDeliveryDate
          ? new Date(item.reworkActualDeliveryDate)
          : null
      );
      updateQualityInformation(getQualityProperties(item, null, true));
    }
  }, [item]);

  return (
    <Dialog
      maxWidth='md'
      aria-labelledby='create-project-dialog-title'
      open={dialog}
      className={classes.dialog}
      fullWidth={true}
    >
      <DialogTitle id='create-project-dialog-title'>
        <div display='flex'>
          <div>Quick Update Item Properties</div>
        </div>
      </DialogTitle>
      <DialogContent dividers>
        <div className={classes.container}>
          <div className={classes.label}>
            Item ID: {item ? item.itemID : 'N/A'}
          </div>
          <div className={classes.label}>
            Item Name: {item ? item.name : 'N/A'}
          </div>
          <Divider></Divider>
          <div className={classes.label}>
            <TextField
              label='Item Status'
              select
              value={editItemStatus}
              onChange={(evt) => setEditItemStatus(evt.target.value)}
              variant='outlined'
              margin='dense'
              fullWidth={true}
            >
              {itemStatus.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </div>
          {editItemStatus === ITEM_STAGE_TYPE.CANCELLED && (
            <div className={classes.label}>
              <TextField
                id='cancellation-reason'
                label='Cancellation Reason'
                select
                value={cancellationReason}
                onChange={(evt) => setCancellationReason(evt.target.value)}
                variant='outlined'
                margin='dense'
                helperText={
                  isEmpty(cancellationReason) &&
                  'Please select cancellation reason'
                }
                error={isEmpty(cancellationReason)}
                fullWidth={true}
              >
                {Object.values(ITEM_CANCELLATION_REASON)
                  .sort()
                  .map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
              </TextField>
            </div>
          )}
          <div className={classes.label}>
            <TextField
              label='Payment Status'
              select
              value={editPaymentStatus}
              onChange={(evt) => setEditPaymentStatus(evt.target.value)}
              variant='outlined'
              margin='dense'
              fullWidth={true}
            >
              {paymentStatus.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </div>
          <RequiredReworkField
            requiredRework={requiredRework}
            setRequiredRework={setRequiredRework}
            reworkDeliveryDate={editReworkDeliveryDate}
            reworkActualDeliveryDate={editReworkActualDeliveryDate}
            setReworkDeliveryDate={setEditReworkDeliveryDate}
            setReworkActualDeliveryDate={setEditReworkActualDeliveryDate}
            requiredReworkTriggerAt={item?.requiredReworkTriggerAt}
            requiredReworkTriggerByName={item?.requiredReworkTriggerByName}
            requiredReworkTriggerByID={item?.requiredReworkTriggerByID}
          />
          <QualityChecks
            style={{ marginTop: '5px' }}
            grouped
            switchState={getQualityProperties(qualityInformation, null, true)}
            onSwitchChange={(name, checked) =>
              updateQualityInformation(
                getQualityPropertiesToUpdate(name, checked)
              )
            }
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} color='primary'>
          Cancel
        </Button>
        <Button
          onClick={() => {
            handleEditStatus(item.itemID, {
              ...item,
              status: editItemStatus,
              paymentStatus: editPaymentStatus,
              datePosted: item.datePosted ? item.datePosted : undefined,
              dateOfExpiry: item.dateOfExpiry ? item.dateOfExpiry : undefined,
              deadline: item.deadline ? item.deadline : undefined,
              actualDeliveryDate: item.actualDeliveryDate
                ? item.actualDeliveryDate
                : undefined,
              deliveryDate: item.deliveryDate ? item.deliveryDate : undefined,
              collectionDate: item.collectionDate
                ? item.collectionDate
                : undefined,
              actualCollectionDate: item.actualCollectionDate
                ? item.actualCollectionDate
                : undefined,
              verifiedDate: item.datePosted ? item.datePosted : undefined,
              requiredRework,
              reworkDeliveryDate:
                requiredRework === 0 ? null : editReworkDeliveryDate,
              reworkActualDeliveryDate:
                requiredRework === 0 ? null : editReworkActualDeliveryDate,
              cancellationReason: cancellationReason,
              qualityInformation,
            });
          }}
          color='primary'
        >
          Update Item
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default EditItemStatusPopup;
