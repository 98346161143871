import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import { Container, Divider, Typography, Button } from '@material-ui/core';

import { FlexRow } from '../layouts/FlexLayouts';

import { clearGetAllItemsCacheApi } from '../../apis/itemApi';
import { clearCachePPE } from '../../apis/ppeApi';

import { notifyError, notifySuccess } from '../../services/notificationService';

import { colors } from '../../palette';
import { clearGetAllQuotationsCacheApi } from '../../apis/quotationApi';


// -------------------------------------------------------------------------------------------------

const useStyles = makeStyles(() => ({
  container: {
    margin: '25px, 0px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    gap: '25px',
    padding: '25px',
    border: `3px solid ${colors.bgLightGrey}`,
  },
  clearPPECache: {
    background: colors.buttonColorBlueGradient,
    color: colors.fontWhite,
  },
}));

function PpeDataManagement() {
  const classes = useStyles();

  function handleClearCache() {
    clearCachePPE()
      .then(() => {
        notifySuccess('PPE cache cleared successfully!');
      })
      .catch(() => {
        notifyError('Unable to clear PPE cache');
      });
  }

  function handleClearGetAllItemsCache() {
    clearGetAllItemsCacheApi()
      .then(() => {
        notifySuccess('Get All Items cache cleared successfully!');
      })
      .catch(() => {
        notifyError('Unable to clear Get All Items cache');
      });
  }

  function handleClearGetAllQuotationsCache() {
    clearGetAllQuotationsCacheApi()
      .then(() => {
        notifySuccess('Get All Quotations cache cleared successfully!');
      })
      .catch(() => {
        notifyError('Unable to clear Get All Quotations cache');
      });
  }

  return (
    <Container className={classes.container}>
      <div>
        <Typography variant='h6'>PPE Data Management</Typography>
        <Divider />
      </div>
      <FlexRow>
        <Button className={classes.clearPPECache} onClick={handleClearCache}>
          Clear PPE Cache
        </Button>
      </FlexRow>
      <FlexRow>
        <Button className={classes.clearPPECache} onClick={handleClearGetAllItemsCache}>
          Clear Get All Items Cache
        </Button>
        <Button className={classes.clearPPECache} onClick={handleClearGetAllQuotationsCache}>
          Clear Get All Quotations Cache
        </Button>
      </FlexRow>
    </Container>
  );
}

export default PpeDataManagement;
