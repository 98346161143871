import React, { useReducer } from 'react';
import { useSelector } from 'react-redux';

import ThankYouFeedbackPopup from '../../components/popups/ThankYouFeedbackPopup';
import UserFeedbackPopup from '../../components/popups/UserFeedbackPopup';

import { getUserIDSelector } from '../../selectors/userSelector';

import { USER_FEEDBACK_QUESTION_LIST } from '../../constants/userFeedbackConstants';

// ------------------------------------------------------------------------------

function withPaymentSuccessPopupHOC(WrappedComponent) {
  return function PaymentSuccessPopupHOC(props) {
    const userID = useSelector(getUserIDSelector);

    const [localState, updateLocalState] = useReducer(
      (prev, next) => {
        return { ...prev, ...next };
      },
      {
        showFeedbackPopup: false,
        showThankYouFeedbackPopup: false,
      }
    );

    return (
      <>
        <WrappedComponent updatePopupsHocState={updateLocalState} {...props} />
        {localState.showFeedbackPopup && (
          <UserFeedbackPopup
            open={localState.showFeedbackPopup}
            questionList={USER_FEEDBACK_QUESTION_LIST}
            userID={userID}
            handleClose={() => {
              updateLocalState({
                showFeedbackPopup: false,
                showThankYouFeedbackPopup: true,
              });
            }}
          />
        )}
        {localState.showThankYouFeedbackPopup && (
          <ThankYouFeedbackPopup
            open={localState.showThankYouFeedbackPopup}
            handleClose={() =>
              updateLocalState({ showThankYouFeedbackPopup: false })
            }
          />
        )}
      </>
    );
  };
}

export default withPaymentSuccessPopupHOC;
