import { makeStyles } from '@material-ui/core/styles';
import { CsvBuilder } from 'filefy';
import { ceil } from 'lodash';
import React, { useEffect, useReducer, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { connect } from 'react-redux';

import { DataGrid } from '@mui/x-data-grid';

import { Avatar, Button, IconButton, Tooltip } from '@material-ui/core';

import { Add, DeleteOutline, Edit } from '@material-ui/icons';

import HorizontalExpandSpace from '../../components/ftr-components/HorizontalExpandSpace';
import CustomToolbar, {
  DataGridToolbarLayout,
} from '../../components/grid-data/CustomToolbar';
import GridDataPagination from '../../components/grid-data/GridDataPagination';
import { ExportCsvButton } from '../../components/grid-data/buttons/ExportCsvButton';
import SearchBar from '../../components/grid-data/buttons/SearchBar';
import { FlexRowCenter } from '../../components/layouts/FlexLayouts';
import YesNoPopup from '../../components/popups/YesNoPopup';
import DataGridLinkCell from '../../components/tables/cells/DataGridLinkCell';
import DataGridWrapTextCell from '../../components/tables/cells/DataGridWrapTextCell';

import withCreateUpdateCompanyPopupHOC from '../../hocs/withCreateUpdateCompanyPopupHOC';

import { deleteCompany, getAllCompanies } from '../../apis/companiesApi';

import { useDataGridFilterHook } from '../../hooks/useDataGridFilterHook';

import { generateCurrentCustomDateTimeString } from '../../util';
import { transformCsvExportDataGrid } from '../../utils/csvExportUtils';
import { isAdminOrHigherRole, isSuperAdminRole } from '../../utils/roleUtils';

import { notifyError, notifySuccess } from '../../services/notificationService';

import {
  creditTypeCol,
  keyAccountCol,
  paymentTermCol,
} from '../../constants/userTableConstants';

import { DEFAULT_MANUAL_RFQ_MARKUP } from '../../constants/markupConstants';

import { colors } from '../../palette';
import { capitalizeString } from '../../utils/stringUtils';

// -------------------------------------------------------------------------------------------------

const useStyles = makeStyles(() => ({
  body: {
    paddingTop: '1rem',
    '& .MuiTablePagination-root': {
      marginRight: '4rem',
    },
    '& .MuiDataGrid-columnHeaderTitle': {
      color: colors.blue060,
      fontSize: '11pt',
      fontWeight: 600,
      margin: '0 auto',
      whiteSpace: 'normal',
      lineHeight: 'normal',
      textAlign: 'center',
    },
    '& .MuiDataGrid-columnsContainer': {
      display: 'flex',
      justifyContent: 'center',
    },
    '& .MuiDataGrid-cell': {
      textAlign: 'center',
      whiteSpace: 'normal',
      wordBreak: 'break-word',
      lineHeight: 'normal',
    },
  },
  containerMenu: {
    padding: '0',
    display: 'flex',
    flexDirection: 'column',
    '& > :not(:last-child)': {
      borderBottom: `1px solid ${colors.lightGray}`,
    },
  },
  itemMenu: {
    padding: '5px',
    textDecoration: 'none',
    color: colors.fontGrey,
    fontSize: '0.8125rem',
    cursor: 'pointer',
    '&:is(:hover, :active)': {
      backgroundColor: colors.solidBlue,
      color: colors.fontWhite,
    },
  },
  pending: {
    backgroundColor: `${colors.warningYellow}75`,
    '&:hover': {
      backgroundColor: `${colors.warningYellow}95`,
    },
  },
  signed: {
    backgroundColor: `${colors.successGreen}75`,
    '&:hover': {
      backgroundColor: `${colors.successGreen}95`,
    },
  },
}));

const ROW_HEIGHT = 80;

function AllCompanies(props) {
  const classes = useStyles();

  const queryClient = useQueryClient();

  const { role, updateCreateUpdateCompanyPopupHOCState = () => {} } = props;

  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [searchAutoFocus, setSearchAutoFocus] = useState(false);

  const { data: allCompaniesData, isLoading } = useQuery(
    'getAllCompanies',
    getAllCompanies
  );

  const [tableQueryParams, updateTableQueryParams] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      page: 0,
      pageSize: 10,
      search: '',
      totalCount: 0,
    }
  );

  const [
    filteredData,
    { setSearchStr, setColumnsDef, setSourceData },
  ] = useDataGridFilterHook({
    search: '',
    source: allCompaniesData,
  });

  const handleDeleteCompany = () => {
    deleteCompany(selectedCompany.companyID)
      .then(() => {
        notifySuccess('Successfully deleted a company');
        queryClient.invalidateQueries('getAllCompanies');
      })
      .catch(() => notifyError('Failed deleted a company'));
  };

  const ppePriceAccountMarkupColumn = {
    headerName: 'Customer Account Markup',
    field: 'ppePriceMarkup',
    hidden: !isAdminOrHigherRole(role),
    editable: true,
    renderCell: ({ row: rowData }) => (
      <DataGridWrapTextCell text={`${rowData.ppePriceMarkup || 0}%`} />
    ),
    width: 120,
  };

  const manualRfqAccountMarkupColumn = {
    headerName: 'Manual RFQ Markup',
    field: 'manualRfqMarkup',
    hidden: !isAdminOrHigherRole(role),
    editable: true,
    renderCell: ({ row: rowData }) => (
      <DataGridWrapTextCell
        text={`${rowData.manualRfqMarkup || DEFAULT_MANUAL_RFQ_MARKUP}%`}
      />
    ),
    width: 120,
  };

  const defaultColumns = [
    {
      headerName: 'Actions',
      field: 'actions',
      export: false,
      renderCell: ({ row: rowData }) => {
        if (!isSuperAdminRole(role)) {
          return null;
        }

        return (
          <div style={{ display: 'flex' }}>
            <Tooltip title='Edit'>
              <IconButton
                onClick={() => {
                  updateCreateUpdateCompanyPopupHOCState({
                    open: true,
                    type: 'update',
                    company: rowData,
                  });
                }}
              >
                <Edit />
              </IconButton>
            </Tooltip>
            <Tooltip title='Delete'>
              <IconButton
                onClick={() => {
                  setShowDeletePopup(true);
                  setSelectedCompany(rowData);
                }}
              >
                <DeleteOutline />
              </IconButton>
            </Tooltip>
          </div>
        );
      },
      width: 120,
      hide: !isAdminOrHigherRole(role),
    },
    {
      headerName: 'Company ID',
      field: 'companyID',
    },
    {
      headerName: 'Company Name',
      field: 'companyName',
      renderCell: ({ row: rowData }) => (
        <DataGridWrapTextCell text={rowData.companyName} />
      ),
      width: 120,
    },
    {
      headerName: 'Email Domain',
      field: 'emailDomain',
      renderCell: ({ row: rowData }) => (
        <DataGridWrapTextCell text={rowData.emailDomain} />
      ),
    },
    {
      headerName: 'Industry Sector',
      field: 'industrySector',
      renderCell: ({ row: rowData }) => (
        <DataGridWrapTextCell text={rowData.industrySector} />
      ),
      width: 120,
    },
    {
      headerName: 'Customer Type',
      field: 'customerType',
      renderCell: ({ row: rowData }) => (
        <DataGridWrapTextCell text={rowData.customerType} />
      ),
      width: 120,
    },
    { ...keyAccountCol },
    { ...creditTypeCol },
    {
      headerName: 'Credit Type',
      field: 'creditType',
    },
    {
      headerName: 'Customer Quotation Terms',
      field: 'quotationTermsUrl',
      renderCell: ({ row: rowData }) => {
        if (rowData.quotationTermsUrl) {
          return (
            <DataGridLinkCell
              link={rowData.quotationTermsUrl}
              name='Custom Quotation Terms'
            />
          );
        }
        return (
          <DataGridWrapTextCell
            text={
              <a href='QuotationTerms.pdf' target='_blank' rel='noreferrer'>
                Standard Quotation Terms
              </a>
            }
          />
        );
      },
      width: 150,
    },
    {
      ...paymentTermCol,
      renderCell: ({ row: rowData }) => (
        <DataGridWrapTextCell text={rowData.paymentTerms} />
      ),
    },
    {
      headerName: 'Address',
      field: 'address',
      renderCell: ({ row: rowData }) => (
        <DataGridWrapTextCell
          style={{ maxHeight: '100%' }}
          text={rowData.address}
        />
      ),
      width: 200,
    },
    {
      headerName: 'Finance Email',
      field: 'financeEmail',
      renderCell: ({ row: rowData }) => (
        <DataGridWrapTextCell text={rowData.financeEmail} />
      ),
      width: 120,
    },
    {
      headerName: 'Finance Address',
      field: 'financeAddress',
      renderCell: ({ row: rowData }) => (
        <DataGridWrapTextCell text={rowData.financeAddress} />
      ),
      width: 200,
    },
    {
      headerName: 'Company Description',
      field: 'companyDesc',
      renderCell: ({ row: rowData }) => (
        <DataGridWrapTextCell text={rowData.companyDesc} />
      ),
      width: 150,
    },
    ppePriceAccountMarkupColumn,
    manualRfqAccountMarkupColumn,
    {
      headerName: 'PPE Type',
      field: 'ppeType',
      renderCell: ({ row: rowData }) => (
        <DataGridWrapTextCell
          style={{ textAlign: 'left' }}
          text={rowData.ppeType}
        />
      ),
    },
    {
      headerName: 'TDE Type',
      field: 'tdeType',
    },
    {
      headerName: 'Default Anodizing Type',
      field: 'defaultAnodizingType',
      width: 120,
      renderCell: ({ row: rowData }) => (
        <DataGridWrapTextCell
          text={capitalizeString(rowData.defaultAnodizingType)}
        />
      ),
    },
    {
      headerName: 'Company Logo',
      field: 'profilePic',
      renderCell: ({ row: rowData }) => (
        <FlexRowCenter style={{ width: '100%' }}>
          <Avatar
            src={rowData.profilePic}
            style={{
              height: '3rem',
              width: '3rem',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
            }}
          />
        </FlexRowCenter>
      ),
    },
  ];

  // eslint-disable-next-line no-unused-vars
  const [columns, setColumns] = useState(defaultColumns);

  useEffect(() => {
    setSearchStr(tableQueryParams.search);
  }, [tableQueryParams.search]);

  useEffect(() => {
    updateTableQueryParams({ totalCount: filteredData?.length });
  }, [filteredData]);

  useEffect(() => {
    setSourceData(allCompaniesData);
  }, [allCompaniesData]);

  useEffect(() => {
    setColumnsDef(columns);
  }, [columns]);

  const handleSearch = (searchTerm) => {
    updateTableQueryParams({
      page: 0,
      search: searchTerm,
    });
  };

  const handleDownloadCsv = () => {
    const fileName = `All Companies ${generateCurrentCustomDateTimeString()}.csv`;
    const { exportedColumns, exportedData } = transformCsvExportDataGrid(
      columns,
      filteredData
    );
    const builder = new CsvBuilder(fileName);
    builder
      .setDelimeter(',')
      .setColumns(exportedColumns)
      .addRows(exportedData)
      .exportFile();
  };

  const getCustomerToolbar = () => {
    const searchBar = (
      <SearchBar
        key='search'
        onSearch={handleSearch}
        searchTerm={tableQueryParams.search}
        autoFocus={searchAutoFocus}
        onFocus={() => {
          setSearchAutoFocus(true);
        }}
        onBlur={() => {
          setSearchAutoFocus(false);
        }}
      />
    );

    const exportButton = (
      <ExportCsvButton
        key='export-csv'
        handleClick={handleDownloadCsv}
        show={isSuperAdminRole(role)}
      />
    );

    const buttons = [searchBar, exportButton];

    return (
      <DataGridToolbarLayout>
        {isSuperAdminRole(role) && (
          <Button
            variant='contained'
            color='primary'
            className={classes.button}
            startIcon={<Add />}
            onClick={() => {
              updateCreateUpdateCompanyPopupHOCState({
                open: true,
                type: 'create',
              });
            }}
          >
            Create Company
          </Button>
        )}
        <HorizontalExpandSpace />
        <CustomToolbar buttons={buttons} />
      </DataGridToolbarLayout>
    );
  };

  return (
    <div className={classes.body}>
      <DataGrid
        autoHeight
        rows={filteredData ?? []}
        columns={columns.map((col) => ({
          ...col,
          sortable: false,
        }))}
        getRowId={(row) => row.companyID}
        rowHeight={ROW_HEIGHT}
        headerHeight={ROW_HEIGHT}
        components={{
          Toolbar: getCustomerToolbar,
          Pagination: () => (
            <GridDataPagination
              pageCount={ceil(
                tableQueryParams.totalCount / tableQueryParams.pageSize
              )}
            />
          ),
        }}
        rowsPerPageOptions={[10, 20, 50]}
        pageSize={tableQueryParams.pageSize}
        onPageSizeChange={(newPageSize) =>
          updateTableQueryParams({ pageSize: newPageSize })
        }
        disableRowSelectionOnClick
        disableSelectionOnClick
        disableColumnMenu
        loading={isLoading}
      />
      <YesNoPopup
        open={showDeletePopup}
        title='Are you sure that you want to delete this company?'
        handleNo={() => setShowDeletePopup(false)}
        handleYes={() => {
          handleDeleteCompany();
          setShowDeletePopup(false);
        }}
      />
    </div>
  );
}

function mapStateToProps(state) {
  return {
    role: state.auth.user.role,
  };
}

const withConnect = connect(mapStateToProps);

export default withConnect(withCreateUpdateCompanyPopupHOC(AllCompanies));
