import React, { useReducer } from 'react';

import VerifyCodePopup from '../components/popups/VerifyCodePopup';

// ------------------------------------------------------------------------------

function withCodeNumberVerificationPopupHOC(WrappedComponent) {
  return function withCodeNumberVerificationPopupHOC(props) {
    const [localState, updateLocalState] = useReducer(
      (prev, next) => {
        return { ...prev, ...next };
      },
      {
        open: false,
        errorMessage: '',
        isAccessCodeCorrect: false,
        accessCode: null,
      }
    );

    const setAccessCode = (accessCode) => updateLocalState({ accessCode });

    const handleInputComplete = (value) => {
      setAccessCode(Number(value));
    };

    const dialogTitleText = 'Verify your access code';

    const renderDialogContent = () => {
      return <>Enter the 6-digit access code.</>;
    };

    return (
      <>
        <WrappedComponent
          updateCodeNumberVerificationPopup={updateLocalState}
          updateCodeNumberVerification={localState}
          {...props}
        />
        <VerifyCodePopup
          open={localState.open}
          dialogTitleText={dialogTitleText}
          renderDialogContent={renderDialogContent}
          handleInputComplete={handleInputComplete}
          isCodeCorrect={localState.isAccessCodeCorrect}
          errorMessage={localState.errorMessage}
        />
      </>
    );
  };
}

export default withCodeNumberVerificationPopupHOC;
