import queryString from 'query-string';

import { getAuthorizedHeader } from '../utils/apiUtils';

import { BACKEND_SERVICE_URL } from '../constants';

export const extractTechnicalDrawing = async (body) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/technical-drawing-extraction`;
  const controller = new AbortController();
  const timeout = 60_000; // 1 minute in ms
  const timeoutId = setTimeout(() => controller.abort(), timeout);

  try {
    const response = await fetch(requestUrl, {
      method: 'POST',
      headers: getAuthorizedHeader(),
      body: JSON.stringify(body),
      signal: controller.signal,
    });

    clearTimeout(timeoutId);

    if (!response.ok) {
      const json = await response.json();
      throw new Error(json.error);
    } else {
      const { data } = await response.json();
      if ('tde_response' in data) {
        data.tde_response.tdeLogID = data.tdeLogID;
        return data.tde_response;
      }
      return data;
    }
  } catch (error) {
    if (error.name === 'AbortError') {
      throw new Error('Request timed out');
    }
    throw error;
  }
};

export const adminSearchItemsTDELogs = async (params) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/technical-drawing-extraction/search?${queryString.stringify(
    params
  )}`;
  return fetch(requestUrl, {
    method: 'GET',
    headers: getAuthorizedHeader(),
  }).then(async (response) => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    const json = await response.json();
    return json.data;
  });
};

export const adminItemsTDELogsExportCsv = async (params) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/technical-drawing-extraction/search/csv-export?${queryString.stringify(
    params
  )}`;
  return fetch(requestUrl, {
    method: 'GET',
    headers: getAuthorizedHeader(),
  }).then(async (response) => {
    return response;
  });
};

export const extractFirstPageImage = async (body) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/technical-drawing-extraction/image`;
  return fetch(requestUrl, {
    method: 'POST',
    headers: getAuthorizedHeader(),
    body: JSON.stringify(body),
  }).then(async (response) => {
    if (!response.ok) {
      return response.json().then((json) => {
        throw new Error(json.error);
      });
    } else {
      const { data } = await response.json();
      return data;
    }
  });
};

export const getLatestTechnicalDrawingExtractionAttemptById = async (
  itemID
) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/items/${itemID}/extract-technical-drawing`;
  return fetch(requestUrl, {
    method: 'GET',
    headers: getAuthorizedHeader(),
  }).then(async (response) => {
    if (!response.ok) {
      return response.json().then((json) => {
        throw new Error(json.error);
      });
    } else {
      const { data } = await response.json();
      return data;
    }
  });
};

// Body can also contain pdfUrl, to specify which pdf to extract from.
export const extractTechnicalDrawingByIdAndPdfUrl = async (itemID, body) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/items/${itemID}/extract-technical-drawing`;
  return fetch(requestUrl, {
    method: 'POST',
    headers: getAuthorizedHeader(),
    body: JSON.stringify(body),
  }).then(async (response) => {
    if (!response.ok) {
      return response.json().then((json) => {
        throw new Error(json.error);
      });
    } else {
      const { data } = await response.json();
      return data;
    }
  });
};

export const updateTechnicalDrawingDB = async (body) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/technical-drawing-extraction/update`;
  return fetch(requestUrl, {
    method: 'POST',
    headers: getAuthorizedHeader(),
    body: JSON.stringify(body),
  }).then(async (response) => {
    if (!response.ok) {
      return response.json().then((json) => {
        throw new Error(json.error);
      });
    } else {
      const { data } = await response.json();
      return data;
    }
  });
};
