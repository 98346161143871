import React from 'react';
import { useSelector } from 'react-redux';

import ManageProjects from './ManageProjects';
import ManageProjectsV2 from './ManageProjectsV2';

import useFeatureFlagHook from '../../hooks/useFeatureFlagHook';
import useManageProjectRevampLegacyCustomerHook from '../../hooks/useManageProjectRevampLegacyCustomerHook';

import { getUserIDSelector } from '../../selectors/userSelector';

// --------------------------------------------------------------------------------------------

function ManageProjectsFactory(props) {
  const userID = useSelector(getUserIDSelector);

  const { isManageProjectsRevampEnabled } = useFeatureFlagHook();

  const { isLegacyCustomer } = useManageProjectRevampLegacyCustomerHook(userID);

  if (isManageProjectsRevampEnabled || !isLegacyCustomer) {
    return <ManageProjectsV2 {...props} />;
  }

  return <ManageProjects {...props} />;
}

export default ManageProjectsFactory;
