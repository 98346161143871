import React, { useState, useEffect } from 'react';

import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  makeStyles,
} from '@material-ui/core';

import { Close as CloseIcon } from '@material-ui/icons';

import FtrFieldLabel from '../ftr-components/FtrFieldLabel';
import { FtrTextField } from '../ftr-components/FtrField';
import BlueButton from '../buttons/BlueButton';
import Title from '../Title';

const useStyles = makeStyles((theme) => ({
  paper: {
    width: 600,
    minWidth: 200,
    maxWidth: 800,
    borderRadius: 10,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

/**
 * A dialog component for displaying and updating notes.
 *
 * @param {Object} props - The component's properties.
 * @param {Function} props.onClose - A function to be called when the dialog is closed.
 * @param {string} props.title - The title of the dialog.
 * @param {string} [props.id='admin-notes-title'] - The id of the dialog title.
 * @param {string} [props.label='Admin Notes'] - The label for the notes input field.
 * @param {string} [props.value=''] - The initial value of the notes input field.
 * @param {Function} [props.updateHandler=() => null] - A function to be called when the update button is clicked.
 * @param {boolean} [props.autoFocus=true] - Whether to autofocus the notes input field.
 *
 * @returns {JSX.Element} - The NotePopup component.
 */
function NotePopup(props) {
  const {
    onClose,
    id = 'note-title',
    title,
    open = false,
    label = 'Notes',
    value = '',
    updateHandler = () => null,
    autoFocus = true,
  } = props;

  const classes = useStyles();

  const [notes, setNotes] = useState(value);

  useEffect(() => {
    setNotes(value);
  }, [value]);

  return (
    <Dialog
      maxWidth='xl'
      open={open}
      onClose={onClose}
      aria-labelledby='warning-dialog-title'
      classes={{ paper: classes.paper }}
    >
      <DialogTitle id={id}>
        <div style={{ padding: '0 1rem' }}>
          <Title contentTitle={title} size='small' />
        </div>
      </DialogTitle>
      <DialogContent>
        <FtrFieldLabel>{label}</FtrFieldLabel>
        <FtrTextField
          value={notes}
          onChange={(e) => setNotes(e.target.value)}
          multiline
          autoFocus={autoFocus}
        />
      </DialogContent>
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          padding: '0.5rem 0 1rem 0',
        }}
      >
        <BlueButton
          onBtnClick={() => updateHandler(notes)}
          btnContent='Update'
        />
      </div>
      <IconButton
        aria-label='close'
        className={classes.closeButton}
        onClick={onClose}
      >
        <CloseIcon />
      </IconButton>
    </Dialog>
  );
}

export default NotePopup;
