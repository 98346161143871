import React, { useEffect, useMemo, useReducer } from 'react';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { compose } from 'redux';

import { makeStyles } from '@material-ui/core/styles/index';

import { DataGrid } from '@mui/x-data-grid';

import { IconButton, Tooltip } from '@material-ui/core';

import { VerifiedUser } from '@material-ui/icons';

import HorizontalExpandSpace from '../../components/ftr-components/HorizontalExpandSpace';
import { AddNewButton } from '../../components/grid-data/buttons/AddNewButton';
import { ExportCsvButton } from '../../components/grid-data/buttons/ExportCsvButton';
import SearchBar from '../../components/grid-data/buttons/SearchBar';
import CustomToolbar, {
  DataGridToolbarLayout,
} from '../../components/grid-data/CustomToolbar';
import GridDataPagination from '../../components/grid-data/GridDataPagination';
import { FlexRow } from '../../components/layouts/FlexLayouts';
import DataGridWrapCell from '../../components/tables/cells/DataGridWrapCell';

import withGenerateAutoBalloonForItemPopupHOC from '../../hocs/withGenerateAutoBalloonForItemPopupHOC';
import withLoadingBackDropTextHOC from '../../hocs/withLoadingBackDropTextHOC';
import withVerifyBalloonPopupHOC from '../../hocs/withVerifyBalloonPopupHOC';

import { getAutoBalloonItems } from '../../apis/autoBalloonApi';
import { generatePresignedUrl } from '../../apis/s3Api';

import { useDataGridFilterHook } from '../../hooks/useDataGridFilterHook';

import { getUserRoleSelector } from '../../selectors/userSelector';

import { isEmptyValue } from '../../utils/commonUtils';
import { formatDateWithTime } from '../../utils/dateTimeUtils';
import { getFileNameFromUrl } from '../../utils/fileUtils';
import { openInNewTab } from '../../utils/navigationUtils';
import { isSuperAdminRole } from '../../utils/roleUtils';
import {
  compareStringsEqualsIgnoreCase,
  snakeCaseToTitleCase,
} from '../../utils/stringUtils';

import { colors } from '../../palette';

// -------------------------------------------------------------------------------------------------

const useStyles = makeStyles(() => ({
  body: {
    padding: '1.5rem 0',
    '& .MuiDataGrid-columnSeparator': {
      display: 'none',
    },
    '& .MuiTablePagination-root': {
      marginRight: '4rem',
    },
    ' & .MuiDataGrid-columnHeaderTitleContainer': {
      padding: '0',
    },
    '& .MuiDataGrid-columnHeaderTitle': {
      color: colors.blue060,
      fontSize: '11pt',
      fontWeight: 600,
      whiteSpace: 'normal',
      lineHeight: 'normal',
    },
    '& .MuiDataGrid-columnsContainer': {
      display: 'flex',
      justifyContent: 'center',
    },
    '& .MuiDataGrid-menuIcon > button': {
      padding: 0,
    },
    '& .MuiDataGrid-row:hover': {
      cursor: 'pointer',
    },
  },
}));

function AllItemBalloonPdfFilesDataGrid(props) {
  const classes = useStyles();

  const {
    tabFilterFunc,
    updateVerifyBalloonPopupState = () => {},
    updateGenerateAutoBalloonForItemPopupState = () => {},
  } = props;

  const role = useSelector(getUserRoleSelector);

  const {
    data: allBalloonItems,
    // isLoading,
    // isFetching,
    // refetch,
  } = useQuery('getAutoBalloonItems', () => getAutoBalloonItems());

  const tabFilteredData = useMemo(() => {
    return isEmptyValue(allBalloonItems)
      ? []
      : tabFilterFunc
        ? tabFilterFunc(allBalloonItems)
        : allBalloonItems;
  }, [allBalloonItems, tabFilterFunc]);

  const columns = [
    {
      headerName: 'Item ID',
      field: 'itemID',
      align: 'center',
      minWidth: 120,
    },
    {
      headerName: 'Source PDF',
      field: 'sourcePdf',
      renderCell: ({ row: rowData }) => {
        const fileName = getFileNameFromUrl(rowData.pdfUrl);
        return (
          <DataGridWrapCell>
            <Link
              className={classes.forgotPassword}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                generatePresignedUrl(rowData.pdfUrl).then((url) => {
                  openInNewTab(url);
                });
              }}
            >
              {fileName}
            </Link>
          </DataGridWrapCell>
        );
      },
      minWidth: 250,
    },
    {
      headerName: 'Ballooned PDF',
      field: 'balloonedPdf',
      renderCell: ({ row: rowData }) => {
        const fileName = getFileNameFromUrl(rowData.balloonedPdfUrl);
        return (
          <DataGridWrapCell>
            <Link
              className={classes.forgotPassword}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                generatePresignedUrl(rowData.balloonedPdfUrl).then((url) => {
                  openInNewTab(url);
                });
              }}
            >
              {fileName}
            </Link>
          </DataGridWrapCell>
        );
      },
      minWidth: 250,
    },
    {
      headerName: 'Report CSV',
      field: 'reportCsv',
      renderCell: ({ row: rowData }) => {
        const fileName = getFileNameFromUrl(rowData.balloonedCsvUrl);
        return (
          <DataGridWrapCell>
            <Link
              className={classes.forgotPassword}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                generatePresignedUrl(rowData.balloonedCsvUrl).then((url) => {
                  openInNewTab(url);
                });
              }}
            >
              {fileName}
            </Link>
          </DataGridWrapCell>
        );
      },
      minWidth: 300,
    },
    {
      headerName: 'Status',
      field: 'status',
      renderCell: ({ row: rowData }) => {
        const status = snakeCaseToTitleCase(rowData.status);

        return (
          <FlexRow>
            {status}
            {compareStringsEqualsIgnoreCase(status, 'pending') && (
              <Tooltip title='Click to verify if PDF and CSV are correct'>
                <span
                  style={{ display: 'inline-block', color: colors.blue060 }}
                >
                  <IconButton
                    onClick={() => {
                      updateVerifyBalloonPopupState({
                        open: true,
                        itemID: rowData.itemID,
                        pdfUrl: rowData.pdfUrl,
                      });
                    }}
                    color='inherit'
                  >
                    <VerifiedUser color='inherit' />
                  </IconButton>
                </span>
              </Tooltip>
            )}
          </FlexRow>
        );
      },
      align: 'center',
      minWidth: 150,
    },
    {
      headerName: 'Created At',
      field: 'createdAt',
      align: 'center',
      renderCell: ({ row: rowData }) => {
        return formatDateWithTime(rowData.createdAt);
      },
      minWidth: 150,
    },
  ];

  const [tableQueryParams, updateTableQueryParams] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      page: 0,
      pageSize: 20,
      search: '',
      status: [],
      totalCount: 0,
      loading: false,
    }
  );

  const [
    filteredData,
    {
      // filtering,
      // setSearchStr,
      // setColumnsDef,
      setSourceData,
    },
  ] = useDataGridFilterHook({
    search: '',
  });

  useEffect(() => {
    setSourceData(tabFilteredData);
  }, [tabFilteredData]);

  const getCustomToolbar = () => {
    return (
      <DataGridToolbarLayout>
        <CustomToolbar
          buttons={[
            <AddNewButton
              key='add-new-balloon-item'
              handleClick={() => {
                updateGenerateAutoBalloonForItemPopupState({ open: true });
              }}
              name='Generate Balloon'
            />,
          ]}
        />
        <HorizontalExpandSpace />
        <CustomToolbar
          buttons={[
            <SearchBar
              key='search'
              // onSearch={handleSearch}
              searchTerm={tableQueryParams.search}
            />,
            <ExportCsvButton
              key='export-csv'
              // handleClick={handleDownloadCsv}
              show={isSuperAdminRole(role)}
            />,
          ]}
        />
      </DataGridToolbarLayout>
    );
  };

  return (
    <>
      <DataGrid
        autoHeight
        headerHeight={90}
        columns={columns}
        rows={isEmptyValue(filteredData) ? [] : filteredData}
        getRowId={(row) => row.itemID}
        pageSize={tableQueryParams.pageSize}
        onPageSizeChange={(newPageSize) =>
          updateTableQueryParams({ pageSize: newPageSize })
        }
        rowsPerPageOptions={[10, 20, 50]}
        loading={tableQueryParams.loading}
        disableSelectionOnClick
        disableRowSelectionOnClick
        components={{
          Toolbar: getCustomToolbar,
          Pagination: () => {
            return (
              <GridDataPagination
                pageCount={Math.ceil(
                  tableQueryParams.totalCount / tableQueryParams.pageSize
                )}
              />
            );
          },
        }}
        localeText={{
          toolbarExport: 'Export CSV',
        }}
        disableColumnMenu
      />
    </>
  );
}

export default compose(
  withLoadingBackDropTextHOC,
  withVerifyBalloonPopupHOC,
  withGenerateAutoBalloonForItemPopupHOC
)(AllItemBalloonPdfFilesDataGrid);
