export const PAYMENT_TYPE = {
  STRIPE_PAYNOW: 'STRIPE_PAYNOW',
  STRIPE_CREDIT_CARD: 'STRIPE_CREDIT_CARD',
};

export const CHECKOUT_TYPE = {
  STRIPE: 'stripe',
  CREDIT: 'credit',
};

export const PAYMENT_STATUS = {
  PAID: 'paid',
  PARTIALLY_PAID: 'partially paid',
  PENDING: 'pending',
};
