import {
  extractTdeForItemAndGetPrice,
  removeCadPartAction,
  GET_PPE_PRICE,
  addTechnicalDrawingAndGetPrice,
  addTechnicalDrawingAndExtractTdeOnly,
  EXTRACT_TDE,
  addTechnicalDrawing,
  getPpePriceForCadPart,
  generateImageAndGetPriceAction,
  generateImageForItem,
} from './item';

import { partUploadProxy } from '../proxies/partUploadProxy';

import { isEmptyValue } from '../utils/commonUtils';
import { findMatchDesignPartForPdf } from './utils/cadPartUploadUtils';
import { getFileExtension } from '../utils/fileUtils';
import { toLowerCaseString } from '../utils/stringUtils';

import { ALL_3D_DESIGN_SUPPORT_FILES_TYPES } from '../constants/NewPartConstants';
import { AUTO_MATCHING_PDF_STATUS } from '../reducers/partUploadFormReducer';

// ------------------------------------------------------------------------------

export const UPDATE_PART_UPLOAD_FORM_STATE = 'UPDATE_PART_UPLOAD_FORM_STATE';
export const RESET_PART_UPLOAD_FORM_STATE = 'RESET_PART_UPLOAD_FORM_STATE';

export const updatePartUploadFormState = (payload) => {
  return {
    type: UPDATE_PART_UPLOAD_FORM_STATE,
    payload,
  };
};

export const resetPartUploadFormState = () => {
  return {
    type: RESET_PART_UPLOAD_FORM_STATE,
  };
};

export const resetPartUploadPdfMatchingStatus = () => {
  return {
    type: UPDATE_PART_UPLOAD_FORM_STATE,
    payload: {
      autoMatchingPdfStatus: AUTO_MATCHING_PDF_STATUS.IDLE,
    },
  };
};

export const autoMatchingPdfFiles = ({
  getPpePrice = GET_PPE_PRICE.NO,
  extractTde = EXTRACT_TDE.NO,
} = {}) => (dispatch, getState) => {
  const formDataAvailable = getState().item?.formDataAvailable || [];

  const designParts = formDataAvailable.filter((part) => {
    const fileExtension = getFileExtension(part.s3ObjectUrl);
    return ALL_3D_DESIGN_SUPPORT_FILES_TYPES.includes(fileExtension);
  });

  const pdfParts = formDataAvailable.filter((part) =>
    toLowerCaseString(part.s3ObjectUrl).endsWith('.pdf')
  );

  if (isEmptyValue(pdfParts) || isEmptyValue(designParts)) {
    return;
  }

  dispatch(
    updatePartUploadFormState({
      autoMatchingPdfStatus: AUTO_MATCHING_PDF_STATUS.PROCESSING,
    })
  );

  let hasMatched = false;
  for (const pdfPart of pdfParts) {
    const matchDesignPart = findMatchDesignPartForPdf(pdfPart, designParts);
    if (!matchDesignPart) {
      continue;
    }

    hasMatched = true;
    // Dispatch actions
    if (getPpePrice === GET_PPE_PRICE.YES) {
      // this including extract TDE
      dispatch(
        addTechnicalDrawingAndGetPrice(matchDesignPart.id, [
          pdfPart.s3ObjectUrl,
        ])
      );
    } else if (extractTde === EXTRACT_TDE.NO) {
      dispatch(addTechnicalDrawing(matchDesignPart.id, [pdfPart.s3ObjectUrl]));
    } else {
      dispatch(
        addTechnicalDrawingAndExtractTdeOnly(matchDesignPart.id, [
          pdfPart.s3ObjectUrl,
        ])
      );
    }
    dispatch(removeCadPartAction(pdfPart.id));
  }

  dispatch(
    updatePartUploadFormState({
      autoMatchingPdfStatus: hasMatched
        ? AUTO_MATCHING_PDF_STATUS.SUCCESS_MATCHED
        : AUTO_MATCHING_PDF_STATUS.SUCCESS_NO_MATCHED_FOUND,
    })
  );

  setTimeout(() => {
    dispatch(
      updatePartUploadFormState({
        autoMatchingPdfStatus: AUTO_MATCHING_PDF_STATUS.IDLE,
      })
    );
  }, 3000);
};

/**
 * this should be running once on Part Upload Step 2
 *
 * @returns
 */
export const extractTdeAndGetPrice = (isReload = false) => (
  dispatch,
  getState
) => {
  const formDataAvailable = getState().item?.formDataAvailable || [];
  for (const part of formDataAvailable) {
    const partProxy = new Proxy(part, partUploadProxy);
    if (
      partProxy.hasPdfUploaded() &&
      !partProxy.hasTdeGeneratedFields() &&
      !partProxy.isOrderAgain()
    ) {
      dispatch(extractTdeForItemAndGetPrice(part.id));
    } else if (isReload && part.ppePricingStatus === 'loading') {
      if (part.imageStatus === 'loading') {
        dispatch(generateImageAndGetPriceAction(part.id));
      } else {
        dispatch(getPpePriceForCadPart(part.id));
      }
    } else if (part.imageStatus === 'loading') {
      dispatch(generateImageForItem(part.id));
    }
  }
};

/**
 * this should be running once on Part Upload Step 2
 *
 * @returns
 */
export const regenerateImageAndGetPriceIfReload = (isReload = false) => (
  dispatch,
  getState
) => {
  if (!isReload) {
    return;
  }

  const formDataAvailable = getState().item?.formDataAvailable || [];
  for (const part of formDataAvailable) {
    if (isReload && part.ppePricingStatus === 'loading') {
      if (part.imageStatus === 'loading') {
        dispatch(generateImageAndGetPriceAction(part.id));
      } else {
        dispatch(getPpePriceForCadPart(part.id));
      }
    } else if (part.imageStatus === 'loading') {
      dispatch(generateImageForItem(part.id));
    }
  }
};
