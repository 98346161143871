import React, { useRef, useReducer } from 'react';

import { Grid } from '@material-ui/core';
import { Add as AddIcon, FastForward as MagicIcon } from '@material-ui/icons';
import { marked } from 'marked';

import { UNIT_TYPES } from '../../../constants/unitConstants';

import { useModifyPdfCanvasContext } from '../../../context/ModifyPdfCanvasContext';

import { getSurfaceFinishWithCustomizationsText } from '../../../utils/itemUtils';
import { isEmptyValue } from '../../../utils/commonUtils';

import { FlexRow } from '../../layouts/FlexLayouts';
import FtrButton from '../../ftr-components/FtrButton';

import LandscapeTitleblock from './LandscapeTitleblock';
import PortraitTitleblock from './PortraitTitleblock';
import TitleBlockForm from './TitleBlockForm';

const TitleBlockDisplay = (props) => {
  const { titleblockPayload, revision, setRevision, canvasData } = props;
  const { addTitleblock, addPreviousCanvas } = useModifyPdfCanvasContext();
  const landscapeTitleblockRef = useRef(null);
  const portraitTitleblockRef = useRef(null);
  const {
    finish: surfaceFinish,
    otherSurfaceFinish,
    color,
    anodizingType,
  } = titleblockPayload;

  const colorSurfaceFinish = isEmptyValue(surfaceFinish)
    ? 'NONE'
    : getSurfaceFinishWithCustomizationsText({
        surfaceFinish,
        otherSurfaceFinish,
        color,
        anodizingType,
      }).toUpperCase();

  const defaultTitleblockState = {
    ...titleblockPayload,
    region: 'Singapore',
    rev: revision || 1,
    address: '81 AYER RAJAH CRESCENT, #01-54, SINGAPORE 139967',
    mainNote: marked.parse(`
- UNLESS OTHERWISE SPECIFIED DEBURR AND BREAK SHARP EDGES
- DIMENSIONS ARE IN ${
      titleblockPayload.unitType === UNIT_TYPES.IMPERIAL
        ? 'INCHES'
        : 'MILLIMETERS'
    }
- TOLERANCES TO COMPLY WITH: 3.2 ISO 2768/1 TOLERANCE CLASS "MEDIUM"
- TAPPING HOLE TOLERANCE: ISO 2 (6H)
- ANY INTERNAL RADII TO BE A MAX OF 0.2MM, UNLESS OTHERWISE STATED
- ANY SHARP EDGES TO BE BROKEN WITH A 0.2MM X 45° CHAMFER UNLESS OTHERWISE STATED
`),
    colorSurfaceFinish,
    extraNote: '',
  };

  const [titleblockState, updateTitleblockState] = useReducer(
    (prev, next) => ({ ...prev, ...next }),
    defaultTitleblockState
  );

  const handleChange = (field) => (event) => {
    updateTitleblockState({
      [field]: event.target.value,
    });

    if (field === 'rev') {
      setRevision(event.target.value);
    }
  };

  return (
    <Grid item container xs={12}>
      <Grid item xs={12}>
        <FlexRow style={{ marginBottom: '1rem' }}>
          {canvasData && (
            <FtrButton
              color='blue'
              startIcon={<MagicIcon />}
              onClick={() =>
                addPreviousCanvas({
                  canvasData,
                  landscapeContent: landscapeTitleblockRef.current,
                  portraitContent: portraitTitleblockRef.current,
                })
              }
              fullWidth
            >
              Add previous Titleblock
            </FtrButton>
          )}
          <FtrButton
            color='blue'
            startIcon={<AddIcon />}
            onClick={() =>
              addTitleblock({ content: landscapeTitleblockRef.current })
            }
            fullWidth
            variant='outlined'
          >
            Add Landscape Titleblock
          </FtrButton>
          <FtrButton
            color='blue'
            startIcon={<AddIcon />}
            onClick={() =>
              addTitleblock({ content: portraitTitleblockRef.current })
            }
            fullWidth
            variant='outlined'
          >
            Add Portrait Titleblock
          </FtrButton>
        </FlexRow>
      </Grid>
      <Grid item container xs={12} spacing={2}>
        <Grid item xs={12}>
          <LandscapeTitleblock
            ref={landscapeTitleblockRef}
            titleblockState={titleblockState}
            {...props}
          />
        </Grid>
        <Grid item xs={6}>
          <PortraitTitleblock
            ref={portraitTitleblockRef}
            titleblockState={titleblockState}
            {...props}
          />
        </Grid>
        <TitleBlockForm
          handleChange={handleChange}
          titleblockState={titleblockState}
          updateTitleblockState={updateTitleblockState}
        />
      </Grid>
    </Grid>
  );
};

export default TitleBlockDisplay;
