import React from 'react';
import { Email as EmailIcon } from '@material-ui/icons';
import { Tooltip } from '@material-ui/core';

import { colors } from '../../palette';
import { dateTzSingapore } from '../../utils/dateTimeUtils';
import { snakeCaseToTitleCase } from '../../utils/stringUtils';

import {
  EMAIL_BREVO_TYPES,
  EMAIL_TEMPLATE_NAME,
} from '../../constants/emailConstants';

/**
 *
 * @param {Object} props
 * @param {'scheduled'|'sent'|'delivered'|'first_opening'|'loaded_by_proxy'|'opened'|'clicked'|'soft_bounced'|'deferred'|'failed'} props.status
 * @param {Date|String} props.date
 * @param {String} props.emailType
 * @returns
 */
function EmailStatusIcon(props) {
  const { status, date, emailType, onClick = () => null } = props;
  const colorMapping = {
    scheduled: colors.secondaryOrange,
    sent: colors.secondaryGreen,
    delivered: colors.tertiaryGreen,
    first_opening: colors.blue050,
    loaded_by_proxy: colors.blue050,
    opened: colors.blue050,
    unique_proxy_open: colors.blue050,
    clicked: colors.secondaryPurple,
    soft_bounce: colors.secondaryRed,
    deferred: colors.secondaryRed,
    failed: colors.bgGrey,
  };

  const titleMapping = {
    [EMAIL_BREVO_TYPES.RFQ_ITEM]: 'Generate RFQ',
    [EMAIL_BREVO_TYPES.RFQ_PROJECT]: 'Generate RFQ',
    [EMAIL_TEMPLATE_NAME.CURATED_RFQ_ITEMS]: 'Generate RFQ',
    [EMAIL_TEMPLATE_NAME.CURATED_RFQ_PROJECTS]: 'Generate RFQ',
    [EMAIL_BREVO_TYPES.RFQ_ITEM_REMINDER]: 'RFQ Reminder',
    [EMAIL_BREVO_TYPES.RFQ_PROJECT_REMINDER]: 'RFQ Reminder',
    [EMAIL_TEMPLATE_NAME.REMINDER_RFQ_ITEMS]: 'RFQ Reminder',
    [EMAIL_TEMPLATE_NAME.REMINDER_RFQ_PROJECTS]: 'RFQ Reminder',
    [EMAIL_BREVO_TYPES.ORDERS_ACCEPTANCE]: 'PO Issued',
    [EMAIL_TEMPLATE_NAME.ORDERS_ACCEPTANCE]: 'PO Issued',
    [EMAIL_BREVO_TYPES.REMINDER_ORDERS_ACCEPTANCE]: 'PO Reminder',
    [EMAIL_TEMPLATE_NAME.REMINDER_ORDERS_ACCEPTANCE]: 'PO Reminder',
    [EMAIL_BREVO_TYPES.REMINDER_ORDER_DELIVERY_DUE_SOON]: 'ORM Reminder',
    [EMAIL_TEMPLATE_NAME.REMINDER_ORDER_DELIVERY_DUE_SOON]: 'ORM Reminder',
    [EMAIL_BREVO_TYPES.ORDER_READY_READY_TO_SHIP]: 'Ready to Ship',
    [EMAIL_TEMPLATE_NAME.ORDER_READY_READY_TO_SHIP]: 'Ready to Ship',
    [EMAIL_BREVO_TYPES.REMINDER_SUBMIT_INVOICE]: 'Invoice Reminder',
    [EMAIL_TEMPLATE_NAME.REMINDER_SUBMIT_INVOICE]: 'Invoice Reminder',
    [EMAIL_BREVO_TYPES.ORDERS_CONFIRMATION]: 'Order Confirmation',
    [EMAIL_TEMPLATE_NAME.ORDERS_CONFIRMATION]: 'Order Confirmation',
    [EMAIL_BREVO_TYPES.EXPIRING_ITEMS]: 'Order Removal',
    [EMAIL_TEMPLATE_NAME.EXPIRING_ITEMS]: 'Order Removal',
    [EMAIL_BREVO_TYPES.NEW_RFQ_PROJECT_QUOTATIONS]: 'Quotes Available',
    [EMAIL_TEMPLATE_NAME.NEW_RFQ_PROJECT_QUOTATIONS]: 'Quotes Available',
    [EMAIL_BREVO_TYPES.ORDER_READY_NEED_UPDATE]: 'Action Required',
    [EMAIL_TEMPLATE_NAME.ORDER_READY_NEED_UPDATE]: 'Action Required',
    [EMAIL_TEMPLATE_NAME.PARTS_DELIVERED_CREDIT]: 'Invoice Email',
    [EMAIL_TEMPLATE_NAME.PARTS_DELIVERED_STRIPE]: 'Stripe Invoice Email',
  };

  if (!status || !emailType || !date) {
    return null;
  }

  const renderTooltipText = () => {
    return (
      <div>
        {titleMapping[emailType] || emailType}
        <br />
        {snakeCaseToTitleCase(status)} on
        <br />
        {dateTzSingapore(new Date(date), 'ddd, DD MMM YYYY HH:mm')}
      </div>
    );
  };

  return (
    <Tooltip title={renderTooltipText()} arrow>
      <div
        style={{
          width: '27px',
          height: '27px',
          display: 'grid',
          placeItems: 'center',
          borderRadius: '50%',
          backgroundColor: colorMapping[status] ?? 'red',
        }}
        onClick={onClick}
      >
        <EmailIcon
          style={{
            fill: 'white',
            width: '17px',
          }}
        />
      </div>
    </Tooltip>
  );
}

export default EmailStatusIcon;
