import React, { useState } from 'react';
import {
  Container,
  Divider,
  Input,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { useQueryClient } from 'react-query';

import { notifyError, notifySuccess } from '../../services/notificationService';
import { colors } from '../../palette';
import BlueButton from '../../components/buttons/BlueButton';
import { supplierDataDashboardUploadCsv } from '../../apis/supplierDataDashboard';

const useStyles = makeStyles(() => ({
  container: {
    margin: '25px, 0px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    gap: '25px',
    padding: '25px',
    border: `3px solid ${colors.bgLightGrey}`,
  },
  input: {
    width: '250px !important',
  },
}));

function InputPartnerData(props) {
  const { type } = props;
  const classes = useStyles();
  const queryClient = useQueryClient();

  const [selectedFile, setSelectedFile] = useState(null);

  const handleUploadCsv = () => {
    if (!selectedFile) {
      notifyError('File is required');
      return;
    }
    const formData = new FormData();
    formData.append('file', selectedFile);
    formData.append('type', type);
    supplierDataDashboardUploadCsv(formData)
      .then(() => {
        notifySuccess('Successfully inserted data from csv file');
        queryClient.invalidateQueries('getSupplierDashboardUploadHistory');
      })
      .catch((err) => {
        notifyError(err?.message || 'Something went wrong!');
      });
  };

  return (
    <Container className={classes.container}>
      <div>
        <Typography variant='h6'>Input Partner Data</Typography>
        <Divider />
      </div>
      <input
        id='input-partner-data-dashboard'
        type='file'
        accept='.csv'
        hidden
        onChange={(evt) => setSelectedFile(evt.target.files[0])}
      />
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          gap: '25px',
        }}
      >
        <Input
          type='file'
          inputProps={{ accept: '.csv' }}
          fullWidth
          onChange={(evt) => setSelectedFile(evt.target.files[0])}
        />
        <BlueButton
          btnContent='Upload'
          size='small'
          onBtnClick={handleUploadCsv}
          disabled={!selectedFile}
        />
      </div>
    </Container>
  );
}

export default InputPartnerData;
