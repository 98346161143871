import React, { forwardRef } from 'react';

import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles/index';

import { DISPLAY_UNIT_OPTIONS } from '../../../constants/unitConstants';

import MDEditor from '@uiw/react-md-editor';
// No import is required in the WebPack.
import '@uiw/react-md-editor/markdown-editor.css';
// No import is required in the WebPack.
import '@uiw/react-markdown-preview/markdown.css';

import TitleblockNoteImage from '../../../assets/img/titleblock-note.png';

import { isEmptyValue } from '../../../utils/commonUtils';
import { isNotFound } from '../../../utils/tdeUtils';

const useStyles = makeStyles(() => ({
  titleBlock: {
    fontSize: '0.8rem',
    background: 'white',
  },
  gridBorder: {
    border: '1px solid black',
    padding: '0.75rem 0.25rem',
  },
  gridBorderNoPadding: {
    border: '1px solid black',
  },
}));

const PortraitTitleblock = forwardRef(function PortraitTitleblock(props, ref) {
  const { titleblockState, currentPage, numPages } = props;
  const classes = useStyles();

  const {
    title,
    unitType,
    material,
    itemID,
    quantity,
    tolerance,
    name,
    contact,
    scale,
    paperSize,
    region,
    rev,
    address,
    extraNote,
    mainNote,
    colorSurfaceFinish,
  } = titleblockState;

  return (
    <Grid ref={ref} container item xs={12} className={classes.titleBlock}>
      <Grid xs={12} item className={classes.gridBorder}>
        <strong>NOTE</strong>
        <div data-color-mode='light'>
          <MDEditor.Markdown source={mainNote} style={{ fontSize: '0.8rem' }} />
        </div>
      </Grid>
      <Grid container item>
        <Grid xs={4} item className={classes.gridBorder}>
          <strong>TITLE</strong>
        </Grid>
        <Grid xs={8} item className={classes.gridBorder}>
          {title}
        </Grid>
      </Grid>
      <Grid container item>
        <Grid xs={6} item className={classes.gridBorder}>
          <img
            src={TitleblockNoteImage}
            alt='Factorem - Titleblock'
            style={{ width: '100%', height: 'auto' }}
          />
        </Grid>
        <Grid xs={6} container item>
          <Grid xs={12} container item>
            <Grid xs={4} item className={classes.gridBorder}>
              <strong>PART ID</strong>
            </Grid>
            <Grid xs={8} item className={classes.gridBorder}>
              {itemID}
            </Grid>
          </Grid>
          <Grid xs={12} container item>
            <Grid xs={4} item className={classes.gridBorder}>
              <strong>QUANTITY</strong>
            </Grid>
            <Grid xs={8} item className={classes.gridBorder}>
              {quantity}
            </Grid>
          </Grid>
          <Grid xs={12} container item>
            <Grid xs={4} item className={classes.gridBorder}>
              <strong>MATERIAL</strong>
            </Grid>
            <Grid xs={8} item className={classes.gridBorder}>
              {material}
            </Grid>
          </Grid>
          <Grid xs={12} container item>
            <Grid xs={4} item className={classes.gridBorder}>
              <strong>FINISH</strong>
            </Grid>
            <Grid xs={8} item className={classes.gridBorder}>
              {colorSurfaceFinish}
            </Grid>
          </Grid>
          <Grid xs={12} container item>
            <Grid xs={4} item className={classes.gridBorder}>
              <strong>TIGHTEST TOLERANCE</strong>
            </Grid>
            <Grid xs={8} item className={classes.gridBorder}>
              {tolerance + DISPLAY_UNIT_OPTIONS[unitType].LENGTH}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container item>
        <Grid xs={6} item className={classes.gridBorder}>
          <strong>ADDRESS</strong>
          <br />
          {address}
        </Grid>
        <Grid xs={6} container item>
          <Grid xs={12} container item>
            <Grid xs={4} item className={classes.gridBorder}>
              <strong>REV</strong>
            </Grid>
            <Grid xs={8} item className={classes.gridBorder}>
              {rev}
            </Grid>
          </Grid>
          <Grid xs={12} container item>
            <Grid xs={4} item className={classes.gridBorder}>
              <strong>SCALE</strong>
            </Grid>
            <Grid xs={8} item className={classes.gridBorder}>
              {isEmptyValue(scale) || isNotFound(scale) ? '1:1' : scale}
            </Grid>
          </Grid>
          <Grid xs={12} container item>
            <Grid xs={4} item className={classes.gridBorder}>
              <strong>SIZE</strong>
            </Grid>
            <Grid xs={8} item className={classes.gridBorder}>
              {isEmptyValue(paperSize) || isNotFound(paperSize)
                ? 'A4'
                : paperSize}
            </Grid>
          </Grid>
          <Grid xs={12} container item>
            <Grid xs={4} item className={classes.gridBorder}>
              <strong>SHEET</strong>
            </Grid>
            <Grid xs={8} item className={classes.gridBorder}>
              {currentPage}/{numPages}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container item>
        <Grid xs={4} item className={classes.gridBorder}>
          <strong>
            ALL INFORMATION IS PROPRIETARY TO FACTOREM AND/OR IT&apos;S CLIENTS,
            AND NOT BE FORWARDED IN WHOLE OR PART WITHOUT CONSENT.
          </strong>
        </Grid>
        <Grid xs={8} container item>
          <Grid xs={12} container item>
            <Grid xs={6} item className={classes.gridBorder}>
              <strong>POC NAME</strong>
            </Grid>
            <Grid xs={6} item className={classes.gridBorder}>
              {name.toUpperCase()}
            </Grid>
          </Grid>
          <Grid xs={12} container item>
            <Grid xs={6} item className={classes.gridBorder}>
              <strong>POC NUMBER</strong>
            </Grid>
            <Grid xs={6} item className={classes.gridBorder}>
              {contact}
            </Grid>
          </Grid>
          <Grid xs={12} container item>
            <Grid xs={6} item className={classes.gridBorder}>
              <strong>REGION</strong>
            </Grid>
            <Grid xs={6} item className={classes.gridBorder}>
              {region}
            </Grid>
          </Grid>
          <Grid xs={12} container className={classes.gridBorder}>
            <div style={{ minHeight: '80px' }} data-color-mode='light'>
              <MDEditor.Markdown
                source={extraNote}
                style={{ fontSize: '0.6rem' }}
              />
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
});

export default PortraitTitleblock;
