import React, { useEffect, useReducer, useState } from 'react';
import { ceil } from 'lodash';
import { useQuery } from 'react-query';

import { makeStyles } from '@material-ui/core/styles/index';

import { DataGrid, GridToolbarExport } from '@mui/x-data-grid';

import { getSharedQuotationLog } from '../apis/projectApi';

import { useDataGridFilterHook } from '../hooks/useDataGridFilterHook';

import { generateCurrentCustomDateTimeString } from '../util';
import { getCsvExportColumns } from '../utils/dataGridUtils';
import { getDateStrWithMonth } from '../utils/dateTimeUtils';

import HorizontalExpandSpace from '../components/ftr-components/HorizontalExpandSpace';
import CustomToolbar from '../components/grid-data/CustomToolbar';
import GridDataPagination from '../components/grid-data/GridDataPagination';
import SearchBar from '../components/grid-data/buttons/SearchBar';

import { colors } from '../palette';

// -------------------------------------------------------------------------------------------------

const useStyles = makeStyles(() => ({
  body: {
    padding: '1rem',
    marginBottom: '2rem',
    '& .MuiDataGrid-columnSeparator': {
      display: 'none',
    },
    ' & .MuiDataGrid-columnHeaderTitleContainer': {
      padding: '0',
    },
    '& .MuiTablePagination-root': {
      marginRight: '4rem',
    },
    '& .MuiDataGrid-columnHeaderTitle': {
      lineHeight: 'normal',
      wordBreak: 'break-word',
      whiteSpace: 'normal',
      color: colors.blue060,
      fontSize: '11pt',
      fontWeight: 600,
    },
    '& .MuiDataGrid-columnsContainer': {
      display: 'flex',
      justifyContent: 'center',
    },
  },
}));

function SharedQuotationLogDataGrid() {
  const classes = useStyles();

  const projectID = window.location.href.split('/')[4];

  const [tableQueryParams, updateTableQueryParams] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      page: 0,
      pageSize: 10,
      search: '',
      totalCount: 0,
      loading: false,
    }
  );

  const [
    filteredData,
    { setSearchStr, setColumnsDef, setSourceData },
  ] = useDataGridFilterHook({
    search: '',
    source: [],
  });

  const { data: sharedQuotationLog, isLoading } = useQuery(
    'sharedQuotationLog',
    () => getSharedQuotationLog(projectID)
  );

  useEffect(() => {
    setSearchStr(tableQueryParams.search);
  }, [tableQueryParams.search]);

  useEffect(() => {
    updateTableQueryParams({ totalCount: filteredData?.length });
  }, [filteredData]);

  useEffect(() => {
    setSourceData(sharedQuotationLog);
  }, [sharedQuotationLog]);

  useEffect(() => {
    updateTableQueryParams({ loading: isLoading ?? false });
  }, [isLoading]);

  const handleSearch = (searchTerm) => {
    updateTableQueryParams({
      page: 0,
      search: searchTerm,
    });
  };

  const defaultColumns = [
    {
      headerName: 'Date Shared',
      field: 'createdAt',
      renderCell: ({ row: rowData }) => {
        return getDateStrWithMonth(new Date(rowData.createdAt));
      },
      flex: 1,
    },
    {
      headerName: 'Sender',
      field: 'sender',
      renderCell: ({ row: rowData }) => {
        return `${rowData.senderName} <${rowData.senderEmail}>`;
      },
      flex: 1,
    },
    {
      headerName: 'Receiver',
      field: 'receiver',
      renderCell: ({ row: rowData }) => {
        return `${rowData.receiverName} <${rowData.receiverEmail}>`;
      },
      flex: 1,
    },
    {
      headerName: 'Parts in quote',
      field: 'quotationIDList',
      flex: 1,
    },
  ];

  // eslint-disable-next-line no-unused-vars
  const [columns, setColumns] = useState(defaultColumns);

  useEffect(() => {
    setColumnsDef(columns);
  }, [columns]);

  const getCustomerToolbar = () => {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: '0.2rem 0.8rem',
          borderBottom: `1px solid ${colors.inputBorderGrey}`,
          flexWrap: 'wrap',
        }}
      >
        <HorizontalExpandSpace />
        <CustomToolbar
          buttons={[
            <SearchBar
              key='search'
              onSearch={handleSearch}
              searchTerm={tableQueryParams.search}
            />,
            <GridToolbarExport
              key='export'
              csvOptions={{
                fileName: `All Share Quotation Log ${generateCurrentCustomDateTimeString()}`,
                delimiter: ',',
                utf8WithBom: true,
                fields: getCsvExportColumns(columns),
              }}
            />,
          ]}
        />
      </div>
    );
  };

  return (
    <div className={classes.body}>
      <h1>Shared Quotation Log</h1>
      <DataGrid
        autoHeight
        rows={filteredData ?? []}
        columns={columns.map((col) => ({
          ...col,
          sortable: false,
        }))}
        getRowId={(row) => row.id}
        rowHeight={80}
        headerHeight={80}
        components={{
          Toolbar: getCustomerToolbar,
          Pagination: () => (
            <GridDataPagination
              pageCount={ceil(
                tableQueryParams.totalCount / tableQueryParams.pageSize
              )}
            />
          ),
        }}
        rowsPerPageOptions={[10, 20, 50]}
        pageSize={tableQueryParams.pageSize}
        onPageSizeChange={(newPageSize) =>
          updateTableQueryParams({ pageSize: newPageSize })
        }
        disableRowSelectionOnClick
        disableSelectionOnClick
        disableColumnMenu
        loading={tableQueryParams.loading}
      />
    </div>
  );
}

export default SharedQuotationLogDataGrid;
