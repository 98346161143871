export const FEATURE_FLAG_ROCKET_QUOTE =
  process.env.REACT_APP_FEATURE_FLAG_ROCKET_QUOTE;
export const FEATURE_FLAG_SUPPLIER_MANAGEMENT =
  process.env.REACT_APP_FEATURE_FLAG_SUPPLIER_MANAGEMENT;
export const FEATURE_FLAG_HUBSPOT = process.env.REACT_APP_FEATURE_FLAG_HUBSPOT;
export const FEATURE_FLAG_MULTI_CHECKOUT =
  process.env.REACT_APP_FEATURE_FLAG_MULTI_CHECKOUT;
export const FEATURE_FLAG_CUSTOM_QC_INPUT =
  process.env.FEATURE_FLAG_CUSTOM_QC_INPUT;
export const FEATURE_FLAG_2_STEPS_CLARIFICATION =
  process.env.FEATURE_FLAG_2_STEPS_CLARIFICATION;
export const FEATURE_FLAG_ADMIN_MANAGE_CUSTOMER_INPUT =
  process.env.REACT_APP_FEATURE_FLAG_ADMIN_MANAGE_CUSTOMER_INPUT;
export const FEATURE_FLAG_MANAGE_COMPANIES =
  process.env.REACT_APP_FEATURE_FLAG_MANAGE_COMPANIES;
export const FEATURE_FLAG_AINC = process.env.REACT_APP_FEATURE_FLAG_AINC;
export const FEATURE_FLAG_ORDER_READY_MANAGEMENT =
  process.env.REACT_APP_FEATURE_FLAG_ORDER_READY_MANAGEMENT;
export const FEATURE_FLAG_PART_UPLOAD_REVAMP =
  process.env.REACT_APP_FEATURE_FLAG_PART_UPLOAD_REVAMP;
export const FEATURE_FLAG_CUSTOMER_CHECKOUT_UI_REVAMP =
  process.env.REACT_APP_FEATURE_FLAG_CUSTOMER_CHECKOUT_UI_REVAMP;
export const FEATURE_FLAG_TECHNICAL_DRAWING_AUTO_MATCHING =
  process.env.REACT_APP_FEATURE_FLAG_TECHNICAL_DRAWING_AUTO_MATCHING;
export const FEATURE_FLAG_MANAGE_PROJECTS_REVAMP =
  process.env.REACT_APP_FEATURE_FLAG_MANAGE_PROJECTS_REVAMP;
