import React, { Fragment, useEffect } from 'react';
import queryString from 'query-string';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';

import { makeStyles } from '@material-ui/core/styles/index';

import { Alert, AlertTitle } from '@material-ui/lab';

import withPaymentSuccessPopupHOC from './withPaymentSuccessPopupHOC';

import { getFeedbackOfUsers } from '../../apis/userFeedbackApi';

import { isEmptyValue } from '../../utils/commonUtils';

import { colors } from '../../palette';

// ----------------------------------------------------------------------------

// Style components
const useStyles = makeStyles(() => ({
  formComponent: {
    height: '75%',
    overflowY: 'scroll',
    padding: '25px 45px',
  },
  title: {
    fontWeight: '600',
    color: colors.fontDarkGrey,
  },
  subtitle: {
    display: 'flex',
    color: colors.fontGrey,
  },
  link: {
    marginLeft: 3,
    color: colors.blue050,
  },
}));

/**
 * @deprecated
 * @param {*} props
 * @returns
 */
const PaymentSuccess = (props) => {
  const classes = useStyles();

  const { userID, updatePopupsHocState } = props;

  const urlParams = new URLSearchParams(window.location.search);
  const itemName = urlParams.get('itemName');
  const parsed = queryString.parse(location.search);
  const itemNames =
    typeof parsed.itemNames === 'string'
      ? [parsed.itemNames]
      : parsed.itemNames;
  const projectID = parsed.projectID;

  useEffect(() => {
    if (userID) {
      getFeedbackOfUsers(userID).then((data) => {
        if (isEmptyValue(data)) {
          updatePopupsHocState({ showFeedbackPopup: true });
        }
      });
    }
  }, [userID]);

  return (
    <Fragment>
      <div className={classes.formComponent}>
        <Alert severity='success'>
          <AlertTitle className={classes.title}>
            Your payment for below item(s) has been received:
          </AlertTitle>
          <ul>
            {itemName && <li>{itemName}</li>}
            {!isEmpty(itemNames) &&
              itemNames.map((name) => <li key={name}>{name}</li>)}
          </ul>
          {!isEmpty(projectID) && (
            <div>
              <br />
              <div className={classes.subtitle}>
                Go to your project
                <Link
                  className={classes.link}
                  to={{ pathname: `/manage-projects/${projectID}` }}
                >
                  here
                </Link>
                .
              </div>
              <br />
            </div>
          )}
          <div className={classes.subtitle}>
            Manage your projects
            <Link
              className={classes.link}
              to={{ pathname: '/manage-projects' }}
            >
              here
            </Link>
            .
          </div>
          <br />
        </Alert>
      </div>
    </Fragment>
  );
};

function mapStateToProps(state) {
  return {
    userID: state.auth.user.userID,
  };
}

const withConnect = connect(mapStateToProps, null);

export default withConnect(withPaymentSuccessPopupHOC(PaymentSuccess));
