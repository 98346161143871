import React from 'react';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles/index';

import { colors } from '../../palette';

import { Box } from '@material-ui/core';

// -------------------------------------------------------------------------------------------------

const useStyles = makeStyles(() => ({
  root: {
    padding: '16px',
    borderRadius: '10px',
  },
  warning: {
    backgroundColor: '#FFF5DC',
  },
  error: {
    backgroundColor: colors.red010,
  },
  info: {
    backgroundColor: colors.blue010,
  },
  normal: {
    backgroundColor: colors.neutral020,
  },
}));

/**
 * Customised FtrBanner component
 *
 * @param {object} props - The properties of the Button component.
 * @param {React.ReactNode} props.children - Content to be displayed inside the button.
 * @param {'warning' | 'error' | 'info' | 'normal'} props.type - Specifies the color of the banner.
 *
 * @returns {React.ReactNode} The rendered FtrBanner component.
 */
function FtrBanner(props) {
  const classes = useStyles();
  const { type = 'warning', style = {}, ...rest } = props;

  const typeClass = clsx(classes.root, {
    [classes.warning]: type === 'warning',
    [classes.error]: type === 'error',
    [classes.info]: type === 'info',
    [classes.normal]: type === 'normal',
  });

  return <Box className={`${typeClass}`} style={style} {...rest} />;
}

export default FtrBanner;
