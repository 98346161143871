import React, { useEffect, useRef, useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles/index';

import { InputBase, InputAdornment, useMediaQuery } from '@material-ui/core';

import withRenameProjectFieldPopupHOC from './withRenameProjectFieldPopupHOC';

import { colors } from '../../palette';
import FtrEditIconButton from '../ftr-components/buttons/FtrEditIconButton';
import FtrDeleteIconButton from '../ftr-components/buttons/FtrDeleteIconButton';
import { FlexRow } from '../layouts/FlexLayouts';

// -------------------------------------------------------------------------------------------------

const useStyles = makeStyles(() => ({
  hide: {
    visibility: 'hidden',
    position: 'absolute', // this to prevent the space of the hidden element to be rendered
    fontWeight: 700,
    fontSize: '22px',
  },
}));

function ProjectNameFieldV2(props) {
  const classes = useStyles();

  const span = useRef();

  const {
    projectName,
    updateProjectName,
    style,
    fontSize = '22px',
    isProjectDeletable,
    editable = true,
    onDeleteProject = () => {},
    updateRenameProjectFieldPopupState = () => {},
  } = props;

  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));

  const [projectNameValue, setProjectNameValue] = useState(
    !projectName ? 'Project Name' : projectName
  );
  const [projectNameHovered, setProjectNameHovered] = useState(false);
  const [isEditProjectName, setIsEditProjectName] = useState(false);
  const [inputFieldWidth, setInputFieldWidth] = useState(0);

  useEffect(() => {
    if (projectName) {
      setProjectNameValue(projectName);
    }
  }, [projectName]);

  useEffect(() => {
    const width = span.current.offsetWidth;
    setInputFieldWidth(width);
  }, [projectNameValue]);

  const handleChange = () => {
    setIsEditProjectName(false);
    updateProjectName(projectNameValue);
  };

  return (
    <>
      <span className={classes.hide} style={{ fontSize }} ref={span}>
        {projectNameValue}
      </span>
      <InputBase
        id='projectName'
        name='projectName'
        onChange={(event) => setProjectNameValue(event.target.value)}
        onBlur={handleChange}
        onKeyDown={(event) => {
          if (event.key === 'Enter') {
            handleChange();
          }
        }}
        disabled={!isEditProjectName}
        value={projectNameValue}
        variant='outlined'
        size='small'
        style={{
          fontWeight: 700,
          fontSize,
          color: colors.neutral080,
          padding: '0 0.3rem',
          border: isEditProjectName && `2px solid ${colors.blue030}`,
          borderRadius: '6px',
          width: inputFieldWidth + 80, // 40 is for the edit icon & delete icon width
          ...style,
        }}
        onMouseEnter={() => !isTablet && setProjectNameHovered(true)}
        onMouseLeave={() => !isTablet && setProjectNameHovered(false)}
        endAdornment={
          (projectNameHovered || isTablet) &&
          editable && ( // if hovered or isTablet, it will be displayed
            <InputAdornment position='end' style={{ cursor: 'pointer' }}>
              <FlexRow>
                <FtrEditIconButton
                  onClick={() => {
                    if (isTablet) {
                      updateRenameProjectFieldPopupState({
                        open: true,
                        projectNameValue,
                        onUpdate: (value) => {
                          setProjectNameValue(value);
                          updateProjectName(value);
                        },
                      });
                      return;
                    }
                    if (isEditProjectName) {
                      updateProjectName(projectNameValue);
                    }
                    setIsEditProjectName(!isEditProjectName);
                  }}
                />
                {!isEditProjectName && isProjectDeletable && (
                  <FtrDeleteIconButton onClick={onDeleteProject} />
                )}
              </FlexRow>
            </InputAdornment>
          )
        }
      />
    </>
  );
}

export default withRenameProjectFieldPopupHOC(ProjectNameFieldV2);
