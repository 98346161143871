import React, { useEffect, useRef, useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';

import { InputAdornment, InputBase } from '@material-ui/core';

import { FtrErrorText } from '../ftr-components';

import EditPartNameIcon from '../../assets/icons/edit_pencil_icon.svg';

import { isEmptyValue } from '../../utils/commonUtils';

import { colors } from '../../palette';
import useDeviceCheck from '../../hooks/useDeviceCheck';
import RenameFieldPopup from '../forms/part-upload-step-two/configure-part-form/RenameFieldPopup';

const useStyles = makeStyles(() => ({
  hide: {
    visibility: 'hidden',
    position: 'absolute', // this to prevent the space of the hidden element to be rendered
    fontWeight: 700,
    fontSize: '16px',
  },
  input: {
    textOverflow: 'ellipsis',
  },
}));

function PartNameField(props) {
  const classes = useStyles();

  const span = useRef();

  const { partName, updatePartName } = props;

  const [{ isTablet }] = useDeviceCheck();

  const inputRef = useRef(null);

  const [partNameValue, setPartNameValue] = useState(
    !partName ? 'Part Name' : partName
  );

  const [previousPartNameValue, setPreviousPartNameValue] = useState(
    partNameValue
  );

  const [isEditPartName, setIsEditPartName] = useState(false);
  const [openRenameFieldPopup, setOpenRenameFieldPopup] = useState(false);

  useEffect(() => {
    // Auto-focus input when enabled
    if (isEditPartName && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isEditPartName]);

  return (
    <React.Fragment>
      <span className={classes.hide} ref={span}>
        {partNameValue}
      </span>
      <InputBase
        inputRef={inputRef}
        classes={{
          input: classes.input,
        }}
        onChange={(event) => setPartNameValue(event.target.value)}
        onBlur={(event) => {
          if (!event) {
            return;
          }

          event.stopPropagation();
          setIsEditPartName(false);
          if (isEmptyValue(partNameValue)) {
            return;
          }
          updatePartName(partNameValue);
          setPreviousPartNameValue(partNameValue);
        }}
        onKeyDown={(event) => {
          if (event.key === 'Enter') {
            event.preventDefault();
            event.stopPropagation();
            setIsEditPartName(false);
            updatePartName(partNameValue);
            setPreviousPartNameValue(partNameValue);
            return;
          }
          if (event.key === 'Escape') {
            event.preventDefault();
            event.stopPropagation();
            setIsEditPartName(false);
            setPartNameValue(previousPartNameValue);
            return;
          }
        }}
        disabled={!isEditPartName}
        value={partNameValue}
        variant='outlined'
        size='small'
        style={{
          fontWeight: 700,
          fontSize: '16px',
          color: colors.neutral080,
          padding: '0 0.3rem',
          border: isEditPartName && `2px solid ${colors.blue030}`,
          borderRadius: '6px',
          width: Math.min(partNameValue.length * 10 + 45, 220), // 40 is for the edit icon width, 250px is the max width (on all devices)
        }}
        endAdornment={
          <InputAdornment
            position='end'
            style={{ cursor: 'pointer' }}
            onClick={(event) => {
              event.preventDefault();
              event.stopPropagation();
              if (isTablet) {
                setOpenRenameFieldPopup(true);
                return;
              }
              if (isEditPartName) {
                updatePartName(partNameValue);
              }
              setIsEditPartName(!isEditPartName);
            }}
          >
            {!isEditPartName && (
              <img src={EditPartNameIcon} alt='Edit part name' />
            )}
          </InputAdornment>
        }
      />
      {isEmptyValue(partNameValue) && !isEditPartName && (
        <FtrErrorText>Required</FtrErrorText>
      )}
      {openRenameFieldPopup && (
        <RenameFieldPopup
          title='Rename Part'
          open={openRenameFieldPopup}
          name={partNameValue}
          onClose={() => setOpenRenameFieldPopup(false)}
          onUpdate={(value) => {
            setPartNameValue(value);
            updatePartName(value);
          }}
        />
      )}
    </React.Fragment>
  );
}

export default PartNameField;
