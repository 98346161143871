import { useQuery } from 'react-query';

import { getGuidedTourCompletionStatus } from '../apis/tourApi';

import { isEmptyValue } from '../utils/commonUtils';

// -------------------------------------------------------------------------------------------------

const useGuidedTour = (userID, tourKey) => {
  const { data: dataTour, isFetching: isTourLoading } = useQuery(
    ['getGuidedTourCompletionStatus', userID, tourKey],
    () => {
      if (isEmptyValue(userID) || isEmptyValue(tourKey)) {
        return null;
      }

      return getGuidedTourCompletionStatus(userID, tourKey);
    }
  );

  return {
    dataTour,
    isTourLoading,
  };
};

export default useGuidedTour;
