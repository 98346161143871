import React from 'react';

import { Dialog, DialogTitle } from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles/index';

import Title from '../Title';
import BlueButton from '../buttons/BlueButton';

const useStyles = makeStyles(() => ({
  paper: {
    width: 600,
    minWidth: 200,
    maxWidth: 800,
    borderRadius: 0,
  },
}));

function ThankYouFeedbackPopup(props) {
  const classes = useStyles();

  const { open, handleClose = () => {} } = props;

  return (
    <Dialog
      maxWidth='xl'
      open={open}
      onClose={handleClose}
      aria-labelledby='confirmation-dialog-title'
      classes={{ paper: classes.paper }}
    >
      <DialogTitle id='confirmation-dialog-title'>
        <Title contentTitle='Thank you for your feedback!' size='small' />
      </DialogTitle>
      <div
        style={{
          paddingBottom: '1rem',
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <BlueButton
            onBtnClick={() => {
              handleClose();
            }}
            btnContent='OK'
          />
        </div>
      </div>
    </Dialog>
  );
}

export default ThankYouFeedbackPopup;
