import { useCallback, useMemo, useReducer, useState } from 'react';
import { get } from 'lodash';

import {
  get3DPrintingTechnologyOptionsWithCache,
  getMaterialColorOptionsWithCache,
  getMaterialsByTechnologyWithCache,
  getPartApplication,
  getSelectColorSurfacesWithCache,
  getSurfaceFinishColorOptionsWithCache,
  getSurfaceFinishingOptionsForSupplier,
  getSurfaceFinishingOptionsWithCache,
  getTechnologyOptionsWithCache,
} from '../apis/configurationApi';

import { isEmptyValue } from '../utils/commonUtils';
import { is3DPTechnology } from '../utils/itemUtils';
import { isNilString } from '../utils/stringUtils';

import { TECHNOLOGY_OPTION_TYPE } from '../constants/NewPartConstants';

// -------------------------------------------------------------------------------------------------

export const useItemInputConfig = ({
  setTechnology,
  setMaterial,
  setThreeDTechnology,
  setSurfaceFinish,
  setMaterialColor,
  setColor = function() {},
}) => {
  const [loadingStatus, updateLoadingStatus] = useReducer((prev, next) => {
    return { ...prev, ...next };
  }, {});

  let isConfigurationLoading = useMemo(() => {
    return Object.values(loadingStatus).some((status) => status === true);
  }, [loadingStatus]);

  const [technologyOptions, setTechnologyOptions] = useState([]);
  const [materialCategoryOptions, setMaterialCategoryOptions] = useState([]);
  const [threeDTechnologyOptions, setThreeDTechnologyOptions] = useState([]);
  const [threeDMaterialOptions, setThreeDMaterialOptions] = useState([]);
  const [defaultThreeDMaterial, setDefaultThreeDMaterial] = useState('');
  const [surfaceFinishOptions, setSurfaceFinishOptions] = useState([]);
  const [materialColorOptions, setMaterialColorOptions] = useState([]);
  const [surfaceFinishColorOptions, setSurfaceFinishColorOptions] = useState(
    []
  );
  const [selectColorSurfaces, setSelectColorSurfaces] = useState([]);
  const [showPartApplication, setShowPartApplication] = useState(false);

  const loadSelectColorSurfaces = async ({ technology }) => {
    getSelectColorSurfacesWithCache({ technology }).then((response) => {
      setSelectColorSurfaces(response);
    });
  };

  const loadTechnologyOptions = async (setDefault = true, params) => {
    updateLoadingStatus({
      loadTechnologyOptions: true,
    });
    return getTechnologyOptionsWithCache(params).then((response) => {
      setTechnologyOptions(response.options);
      if (setDefault) {
        setTechnology(response.default);
        loadSelectColorSurfaces({ technology: response.default });
      }
      updateLoadingStatus({
        loadTechnologyOptions: false,
      });
      return response.options;
    });
  };

  const loadMaterialCategoryOptions = async (params, setDefault = true) => {
    const { technology } = params;
    if (is3DPTechnology(technology)) {
      return;
    }

    updateLoadingStatus({
      loadMaterialCategoryOptions: true,
    });
    return getMaterialsByTechnologyWithCache(params)
      .then((response) => {
        setMaterialCategoryOptions(response.options);
        if (response.default && setDefault) {
          setMaterial(response.default);
        }
        updateLoadingStatus({
          loadMaterialCategoryOptions: false,
        });
        return response;
      })
      .catch((err) => {
        updateLoadingStatus({
          loadMaterialCategoryOptions: false,
        });
        console.warn(err);
      });
  };

  const load3DTechnologyOptions = async (setDefault = true) => {
    updateLoadingStatus({
      load3DTechnologyOptions: true,
    });
    return get3DPrintingTechnologyOptionsWithCache().then((response) => {
      setThreeDTechnologyOptions(response.options);
      if (response.default && setDefault) {
        setThreeDTechnology(response.default);
      }
      updateLoadingStatus({
        load3DTechnologyOptions: false,
      });
      return response.default;
    });
  };

  const loadThreeDMaterialOptions = async (params, setDefault = true) => {
    updateLoadingStatus({
      loadThreeDMaterialOptions: true,
    });
    return getMaterialsByTechnologyWithCache(params)
      .then((response) => {
        setThreeDMaterialOptions(response.options);
        setDefaultThreeDMaterial(response.default);
        if (response.default && setDefault) {
          setMaterial(response.default);
        }
        updateLoadingStatus({
          loadThreeDMaterialOptions: false,
        });
        return response;
      })
      .catch((err) => {
        updateLoadingStatus({
          loadThreeDMaterialOptions: false,
        });
        console.warn(err);
      });
  };

  const loadSurfaceFinishOptions = async (params, setDefault = true) => {
    const { technology, threeDTechnology } = params;

    if (isEmptyValue(technology)) {
      return;
    }

    if (is3DPTechnology(technology) && isEmptyValue(threeDTechnology)) {
      return;
    }

    updateLoadingStatus({
      loadSurfaceFinishOptions: true,
    });

    if (setDefault) {
      setColor(null);
    }

    return getSurfaceFinishingOptionsWithCache(params)
      .then((response) => {
        if (isEmptyValue(response)) {
          setSurfaceFinishOptions([]);
          setSurfaceFinish(null);
          updateLoadingStatus({
            loadSurfaceFinishOptions: false,
          });
          return {
            options: [],
            default: null,
          };
        }

        const _options = response.options.map((o) => ({
          key: isNilString(o)
            ? is3DPTechnology(technology)
              ? get(response.finishDisplayMapping, [
                  threeDTechnology,
                  o,
                  'displayText',
                ]) || o
              : get(response.finishDisplayMapping, [o, 'displayText']) || o
            : o,
          value: o,
          tooltip: isNilString(o)
            ? is3DPTechnology(technology)
              ? get(response.finishDisplayMapping, [
                  threeDTechnology,
                  o,
                  'tooltip',
                ])
              : get(response.finishDisplayMapping, [o, 'tooltip'])
            : undefined,
        }));
        setSurfaceFinishOptions(_options);
        if (setDefault) {
          setSurfaceFinish(response.default);
        }
        updateLoadingStatus({
          loadSurfaceFinishOptions: false,
        });
        return response;
      })
      .catch((err) => {
        if (err.name === 'AbortError') {
          console.warn('Request aborted');
          // abort error is expected when the request is aborted
          return;
        }
        updateLoadingStatus({
          loadSurfaceFinishOptions: false,
        });
        setSurfaceFinishOptions(['Custom Finish']);
        if (setDefault) {
          setSurfaceFinish(null);
        }
        return {
          options: [],
          default: null,
        };
      });
  };

  const loadSurfaceFinishOptionsForSupplier = async (
    params,
    setDefault = true
  ) => {
    return getSurfaceFinishingOptionsForSupplier(params)
      .then((response) => {
        if (isEmptyValue(response)) {
          setSurfaceFinishOptions([]);
          setSurfaceFinish(null);
          return {
            options: [],
            default: null,
          };
        }
        const _options = response.options.map((o) => ({
          key: isNilString(o)
            ? get(response.finishDisplayMapping, [o, 'displayText']) || o
            : o,
          value: o,
          tooltip: isNilString(o)
            ? get(response.finishDisplayMapping, [o, 'tooltip'])
            : undefined,
        }));
        setSurfaceFinishOptions(_options);
        if (setDefault) {
          setSurfaceFinish(response.default);
        }
        return response;
      })
      .catch((err) => {
        console.warn(err);
        setSurfaceFinishOptions(['Custom Finish']);
        if (setDefault) {
          setSurfaceFinish(null);
        }
        return {
          options: [],
          default: null,
        };
      });
  };

  const loadMaterialColorOptions = async (params, setDefault = true) => {
    getMaterialColorOptionsWithCache(params)
      .then((response) => {
        if (
          isEmptyValue(response) &&
          setMaterialColorOptions &&
          setMaterialColor
        ) {
          setMaterialColorOptions(null);
          setMaterialColor(null);
          return;
        }
        const colorPalette = response?.options;
        if (setMaterialColorOptions) {
          setMaterialColorOptions(colorPalette);
        }
        if (setDefault && setMaterialColor) {
          setMaterialColor(response.default);
        }
      })
      .catch((err) => {
        console.warn(err);
      });
  };

  const loadSurfaceFinishColorOptions = async (params, setDefault = true) => {
    setSurfaceFinishColorOptions([]);
    getSurfaceFinishColorOptionsWithCache(params)
      .then((response) => {
        if (isEmptyValue(response)) {
          setSurfaceFinishColorOptions([]);
          if (setDefault) {
            setColor(null);
          }
          return;
        }
        const colorPalette = response.options;
        setSurfaceFinishColorOptions(colorPalette);
        if (setDefault) {
          if (response.default === 'Custom Color') {
            setColor('');
          } else {
            setColor(response.default);
          }
        }
      })
      .catch((err) => {
        console.warn(err);
      });
  };

  const loadShowPartApplication = async (params) => {
    return getPartApplication(params).then((response) => {
      setShowPartApplication(response.partApplication);
      return response.partApplication;
    });
  };

  const surfaceFinishHasChanged = async ({
    technology,
    material,
    surfaceFinish,
  }) => {
    const params = {
      technology,
      material,
      surfaceFinish,
    };
    loadSurfaceFinishColorOptions(params);
  };

  const materialHasChanged = async ({
    technology,
    material,
    threeDTechnology,
  }) => {
    const params = {
      technology,
      threeDTechnology,
      material,
    };
    loadSurfaceFinishOptions(params).then((response) => {
      setColor(null);
      setSurfaceFinishColorOptions([]);
      if (response) {
        const { default: surfaceFinish } = response;
        surfaceFinishHasChanged({ technology, material, surfaceFinish });
      }
    });
    loadMaterialColorOptions(params);
  };

  const threeDTechnologyHasChanged = async ({
    technology,
    threeDTechnology,
  }) => {
    loadThreeDMaterialOptions({
      technology,
      threeDTechnology,
    }).then(({ default: material }) =>
      materialHasChanged({ technology, material, threeDTechnology })
    );
  };

  const technologyHasChanged = async (technology) => {
    if (technology === TECHNOLOGY_OPTION_TYPE.THREE_D_PRINTING) {
      load3DTechnologyOptions().then((threeDTechnology) =>
        threeDTechnologyHasChanged({ technology, threeDTechnology })
      );
    } else {
      loadMaterialCategoryOptions({
        technology,
      }).then(({ default: material }) =>
        materialHasChanged({ technology, material })
      );
    }
    loadSelectColorSurfaces({ technology });
    loadShowPartApplication({ technology });
  };

  return [
    {
      technologyOptions,
      materialCategoryOptions,
      threeDTechnologyOptions,
      threeDMaterialOptions,
      surfaceFinishOptions,
      materialColorOptions,
      surfaceFinishColorOptions,
      defaultThreeDMaterial,
      selectColorSurfaces,
      showPartApplication,
      loadingStatus,
      isConfigurationLoading,
    },
    {
      loadTechnologyOptions: useCallback(loadTechnologyOptions, []),
      loadMaterialCategoryOptions: useCallback(loadMaterialCategoryOptions, []),
      load3DTechnologyOptions: useCallback(load3DTechnologyOptions, []),
      loadThreeDMaterialOptions: useCallback(loadThreeDMaterialOptions, []),
      loadSurfaceFinishOptions: useCallback(loadSurfaceFinishOptions, []),
      loadSurfaceFinishOptionsForSupplier: useCallback(
        loadSurfaceFinishOptionsForSupplier,
        []
      ),
      loadMaterialColorOptions: useCallback(loadMaterialColorOptions, []),
      loadSurfaceFinishColorOptions: useCallback(
        loadSurfaceFinishColorOptions,
        []
      ),
      loadSelectColorSurfaces: useCallback(loadSelectColorSurfaces, []),
      loadShowPartApplication: useCallback(loadShowPartApplication, []),
      technologyHasChanged: useCallback(technologyHasChanged, []),
      materialHasChanged: useCallback(materialHasChanged, []),
      threeDTechnologyHasChanged: useCallback(threeDTechnologyHasChanged, []),
      surfaceFinishHasChanged: useCallback(surfaceFinishHasChanged, []),
      setSurfaceFinishOptions,
      setSurfaceFinishColorOptions,
      setThreeDMaterialOptions,
      setMaterialColorOptions,
    },
  ];
};
