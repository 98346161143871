import React, { useReducer } from 'react';

import { isEmptyValue } from '../../utils/commonUtils';
import MovePartsPopup from './MovePartsPopup';

// -------------------------------------------------------------------------------------------------

function withMovePartsPopupHOC(WrappedComponent) {
  function WrappedComponentWithHOC(props) {
    const defaultLocalState = {
      open: false,
    };

    const [localState, updateLocalState] = useReducer((prev, next) => {
      if (isEmptyValue(next)) {
        return defaultLocalState;
      }
      return { ...prev, ...next };
    }, defaultLocalState);

    return (
      <>
        <WrappedComponent
          updateMovePartsPopupState={updateLocalState}
          {...props}
        />
        {localState.open && (
          <MovePartsPopup
            open={localState.open}
            handleClose={() => updateLocalState({ open: false })}
            items={localState?.items}
          />
        )}
      </>
    );
  }

  return WrappedComponentWithHOC;
}

export default withMovePartsPopupHOC;
