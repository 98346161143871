import React, { useReducer } from 'react';

import RenameFieldPopup from '../forms/part-upload-step-two/configure-part-form/RenameFieldPopup';

import { isEmptyValue } from '../../utils/commonUtils';

// -------------------------------------------------------------------------------------------------

function withRenameProjectFieldPopupHOC(WrappedComponent) {
  function WrappedComponentWithHOC(props) {
    const defaultLocalState = {
      open: false,
      onUpdate: () => {},
    };

    const [localState, updateLocalState] = useReducer((prev, next) => {
      if (isEmptyValue(next)) {
        return defaultLocalState;
      }
      return { ...prev, ...next };
    }, defaultLocalState);

    return (
      <>
        <WrappedComponent
          updateRenameProjectFieldPopupState={updateLocalState}
          {...props}
        />
        {localState.open && (
          <RenameFieldPopup
            title='Rename Project'
            open={localState.open}
            name={localState.projectNameValue}
            onClose={() => updateLocalState({ open: false })}
            onUpdate={localState.onUpdate}
          />
        )}
      </>
    );
  }

  return WrappedComponentWithHOC;
}

export default withRenameProjectFieldPopupHOC;
