import React, { useEffect, useReducer, useState } from 'react';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { compose } from 'redux';

import { makeStyles } from '@material-ui/core/styles/index';

import { DataGrid, GridToolbarExport } from '@mui/x-data-grid';

import { IconButton, Tooltip } from '@material-ui/core';

import { DeleteOutline, Edit } from '@material-ui/icons';

import CustomToolbar from '../../components/grid-data/CustomToolbar';
import DataGridWrapCell from '../../components/tables/cells/DataGridWrapCell';
import SearchBar from '../../components/grid-data/buttons/SearchBar';
import { AddNewButton } from '../../components/grid-data/buttons/AddNewButton';

import withFactoremPromotionCodePopupHOC from '../../hocs/withFactoremPromotionCodePopupHOC';
import withFtrDeletePopupHOC from '../../hocs/withFtrDeletePopupHOC';

import {
  deleteFactoremPromotionCode,
  getAllFactoremPromotionCodesApi,
} from '../../apis/factoremPromotionCodesApi';

import { getUserRoleSelector } from '../../selectors/userSelector';

import { formatDateWithTime } from '../../utils/dateTimeUtils';
import { generateCurrentCustomDateTimeString } from '../../util';
import { getCsvExportColumns } from '../../utils/dataGridUtils';
import { isAdminOrHigherRole } from '../../utils/roleUtils';
import { isEmptyValue } from '../../utils/commonUtils';
import { snakeCaseToTitleCase } from '../../utils/stringUtils';

import { notifyError, notifySuccess } from '../../services/notificationService';

import { colors } from '../../palette';

// -------------------------------------------------------------------------------------------------

const useStyles = makeStyles(() => ({
  body: {
    '& .MuiDataGrid-columnSeparator': {
      display: 'none',
    },
    '& .MuiTablePagination-root': {
      marginRight: '4rem',
    },
    '& .MuiDataGrid-columnHeaderTitleContainer': {
      paddingLeft: 0,
    },
    '& .MuiDataGrid-columnHeaderTitle': {
      color: colors.blue060,
      fontSize: '11pt',
      fontWeight: 600,
      whiteSpace: 'normal',
      lineHeight: 'normal',
    },
    '& .MuiDataGrid-columnsContainer': {
      display: 'flex',
      justifyContent: 'start',
    },
    '& .MuiDataGrid-menuIcon > button': {
      padding: 0,
    },
  },
}));

function FactoremPromotionCodesDataGrid(props) {
  const classes = useStyles();

  const {
    updateFactoremPromotionCodePopupState = () => {},
    updateDeletePopupHOCState = () => {},
  } = props;

  const userRole = useSelector(getUserRoleSelector);

  const [tableData, setTableData] = useState([]);
  const {
    data: allPromotionCodes = [],
    refetch: refetchFactoremPromotionCodes,
  } = useQuery(['allFactoremPromotionCodes'], () =>
    getAllFactoremPromotionCodesApi()
  );

  // Table search
  const [tableQueryParams, updateTableQueryParams] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      page: 0,
      pageSize: 20,
      search: '',
      totalCount: 0,
      loading: false,
    }
  );

  const [filteredTableData, setFilterTableData] = useState([]);
  const [searchAutoFocus, setSearchAutoFocus] = useState(false);

  const columns = [
    {
      headerName: 'Actions',
      field: 'actions',
      minWidth: 130,
      export: false,
      headerAlign: 'left',
      align: 'left',
      renderCell: (params) => {
        if (!isAdminOrHigherRole(userRole)) {
          return;
        }

        const { row } = params;

        return [
          <Tooltip title='Edit' key='edit'>
            <IconButton
              aria-label='edit'
              onClick={() => {
                updateFactoremPromotionCodePopupState({
                  open: true,
                  codeID: row.id,
                  mode: 'edit',
                });
              }}
            >
              <Edit />
            </IconButton>
          </Tooltip>,
          row.deleted !== 1 ? (
            <Tooltip title='Delete' key='delete'>
              <IconButton
                aria-label='delete'
                onClick={() => {
                  updateDeletePopupHOCState({
                    open: true,
                    title: `Do you want to delete this Promotion Code: ${row.id}?`,
                    onConfirm: () => handleDeleteClick(row.id),
                  });
                }}
              >
                <DeleteOutline />
              </IconButton>
            </Tooltip>
          ) : null,
        ];
      },
    },
    {
      headerName: 'Code Name',
      field: 'id',
      align: 'left',
      minWidth: 300,
      flex: 1,
    },
    {
      headerName: 'Discount %',
      field: 'discountPercent',
      align: 'left',
      minWidth: 150,
      valueGetter: ({ row: rowData }) => {
        return `${rowData.discountPercent}%`;
      },
    },
    {
      headerName: 'Discount CAP',
      field: 'discountCap',
      align: 'left',
      minWidth: 160,
      valueGetter: ({ row: rowData }) => {
        if (rowData.discountCap === 0) {
          return 'N.A.';
        }
        return `S$${rowData.discountCap}`;
      },
    },
    {
      headerName: 'Type',
      field: 'type',
      align: 'left',
      minWidth: 130,
      valueGetter: ({ row: rowData }) => {
        const type = snakeCaseToTitleCase(rowData.type);
        return type;
      },
    },
    {
      headerName: 'Status',
      field: 'active',
      align: 'left',
      minWidth: 130,
      valueGetter: ({ row: rowData }) => {
        if (rowData.deleted === 1) {
          return 'Deleted';
        }

        return rowData.active === 1 ? 'Active' : 'Inactive';
      },
    },
    {
      headerName: 'Date Created',
      field: 'createdAt',
      align: 'left',
      minWidth: 160,
      valueGetter: ({ row: rowData }) => {
        return formatDateWithTime(rowData.createdAt);
      },
    },
    {
      headerName: 'Created By',
      field: 'creatorID',
      align: 'left',
      minWidth: 200,
      valueGetter: ({ row: rowData }) => {
        if (isEmptyValue(rowData.creatorID)) {
          return 'N.A.';
        }

        const { creatorID, creatorName, creatorEmail } = rowData;

        return `${creatorName} (${creatorEmail}) (${creatorID})`;
      },
      renderCell: ({ row: rowData }) => {
        if (isEmptyValue(rowData.creatorID)) {
          return 'N.A.';
        }

        const { creatorID, creatorName, creatorEmail } = rowData;

        const text = `${creatorName} (${creatorEmail}) (${creatorID})`;
        return <DataGridWrapCell>{text}</DataGridWrapCell>;
      },
    },
  ];

  let timer;
  useEffect(() => {
    updateTableQueryParams({ loading: true });
    if (!tableQueryParams.search) {
      setFilterTableData(tableData);
      updateTableQueryParams({
        loading: false,
      });
    } else {
      clearTimeout(timer);
      timer = setTimeout(() => {
        const filteredRows = tableData.filter((row) => {
          return row.id
            .toLowerCase()
            .includes(tableQueryParams.search.toLowerCase());
        });
        setFilterTableData(filteredRows);
        updateTableQueryParams({
          loading: false,
        });
      }, 300);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [
    tableData,
    tableQueryParams.search,
    tableQueryParams.page,
    tableQueryParams.pageSize,
  ]);

  useEffect(() => {
    if (isEmptyValue(allPromotionCodes)) {
      return;
    }

    setTableData(allPromotionCodes);
  }, [allPromotionCodes]);

  const handleSearch = (searchTerm) => {
    updateTableQueryParams({
      page: 0,
      search: searchTerm,
    });
  };

  function handleDeleteClick(codeID) {
    deleteFactoremPromotionCode(codeID)
      .then(() => {
        notifySuccess('Promotion code has been deleted successfully');
        updateDeletePopupHOCState({
          open: false,
        });
        refetchFactoremPromotionCodes();
      })
      .catch((error) => {
        notifyError(
          error.message ||
            'Unable to delete promotion code. Please try again later.'
        );
      });
  }

  const getCustomToolbar = () => {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: '0.2rem 0.8rem',
          borderBottom: `1px solid ${colors.inputBorderGrey}`,
          flexWrap: 'wrap',
        }}
      >
        <CustomToolbar
          buttons={[
            <AddNewButton
              key='add-new-promotion-code'
              handleClick={() => {
                updateFactoremPromotionCodePopupState({
                  open: true,
                  mode: 'add',
                });
              }}
              name='Add new promotion code'
            />,
          ]}
        />
        <CustomToolbar
          buttons={[
            <SearchBar
              key='search'
              onSearch={handleSearch}
              searchTerm={tableQueryParams.search}
              autoFocus={searchAutoFocus}
              onFocus={() => {
                setSearchAutoFocus(true);
              }}
              onBlur={() => {
                setSearchAutoFocus(false);
              }}
            />,
            <GridToolbarExport
              key='export'
              csvOptions={{
                fileName: `All Factorem Promotion Codes ${generateCurrentCustomDateTimeString()}`,
                delimiter: ';',
                utf8WithBom: true,
                fields: getCsvExportColumns(columns),
              }}
            />,
          ]}
        />
      </div>
    );
  };

  return (
    <div className={classes.body}>
      <DataGrid
        autoHeight
        columns={columns}
        rows={isEmptyValue(filteredTableData) ? [] : filteredTableData}
        editMode='row'
        rowsPerPageOptions={[10, 20, 50]}
        components={{ Toolbar: getCustomToolbar }}
        pageSize={tableQueryParams.pageSize}
        onPageSizeChange={(newPageSize) =>
          updateTableQueryParams({ pageSize: newPageSize })
        }
        loading={tableQueryParams.loading}
        localeText={{ toolbarExport: 'Export CSV' }}
      />
    </div>
  );
}

export default compose(
  withFactoremPromotionCodePopupHOC,
  withFtrDeletePopupHOC
)(FactoremPromotionCodesDataGrid);
