import React, { useState } from 'react';

import {
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  makeStyles,
  Radio,
  RadioGroup,
} from '@material-ui/core';

import { Close as CloseIcon } from '@material-ui/icons';

import FtrFieldLabel from '../ftr-components/FtrFieldLabel';
import { FtrButton } from '../ftr-components';
import Title from '../Title';
import { FlexColumn, FlexRow } from '../layouts/FlexLayouts';

import { CHECKOUT_TYPE, PAYMENT_TYPE } from '../../constants/paymentConstants';

const useStyles = makeStyles((theme) => ({
  paper: {
    width: 450,
    minWidth: 200,
    maxWidth: 800,
    borderRadius: 10,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

const CHECKOUT_TYPE_OPTIONS = [
  {
    label: 'credit',
    value: {
      checkoutType: CHECKOUT_TYPE.CREDIT,
      paymentType: null,
    },
  },
  {
    label: 'stripe (credit card)',
    value: {
      checkoutType: CHECKOUT_TYPE.STRIPE,
      paymentType: PAYMENT_TYPE.STRIPE_CREDIT_CARD,
    },
  },
  {
    label: 'stripe (paynow)',
    value: {
      checkoutType: CHECKOUT_TYPE.STRIPE,
      paymentType: PAYMENT_TYPE.STRIPE_PAYNOW,
    },
  },
  {
    label: 'stripe',
    value: {
      checkoutType: CHECKOUT_TYPE.STRIPE,
      paymentType: null,
    },
  },
];

/**
 * A dialog component for selecting a checkout type.
 *
 * @param {Object} props - The component's properties.
 * @param {Function} props.onClose - A function to be called when the dialog is closed.
 * @param {string} props.id - The id of the dialog title. Defaults to 'checkout-type-field-popup'.
 * @param {string} props.title - The title of the dialog.
 * @param {boolean} props.open - Whether the dialog is open. Defaults to false.
 * @param {string} props.value - The initial value of the checkout type input field. Defaults to ''.
 * @param {Function} props.updateHandler - A function to be called when the update button is clicked. Defaults to () => null.
 *
 * @returns {ReactElement} - The CheckoutTypeFieldPopup component.
 */
function CheckoutTypeFieldPopup(props) {
  const {
    onClose,
    id = 'checkout-type-field-popup',
    title,
    open = false,
    value = '',
    updateHandler = () => null,
  } = props;

  const classes = useStyles();

  const [checkoutTypeValue, setCheckoutTypeValue] = useState(
    JSON.stringify(value)
  );

  return (
    <Dialog
      maxWidth='xl'
      open={open}
      onClose={onClose}
      aria-labelledby='warning-dialog-title'
      classes={{ paper: classes.paper }}
    >
      <DialogTitle id={id}>
        <div style={{ padding: '0 1rem' }}>
          <Title contentTitle={title} size='small' />
        </div>
      </DialogTitle>
      <DialogContent>
        <FtrFieldLabel>Checkout Type</FtrFieldLabel>
        <FlexRow>
          <RadioGroup
            row
            value={checkoutTypeValue}
            onChange={(event) => {
              setCheckoutTypeValue(event.target.value);
            }}
          >
            <FlexColumn style={{ gap: 0 }}>
              {CHECKOUT_TYPE_OPTIONS.map((option) => (
                <FormControlLabel
                  key={option.label}
                  value={JSON.stringify(option.value)}
                  control={<Radio color='primary' />}
                  label={option.label}
                />
              ))}
            </FlexColumn>
          </RadioGroup>
        </FlexRow>
      </DialogContent>
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          padding: '0.5rem 0 1rem 0',
        }}
      >
        <FtrButton
          color='blue'
          onClick={() => {
            updateHandler(JSON.parse(checkoutTypeValue));
            onClose();
          }}
        >
          Update
        </FtrButton>
      </div>
      <IconButton
        aria-label='close'
        className={classes.closeButton}
        onClick={onClose}
      >
        <CloseIcon />
      </IconButton>
    </Dialog>
  );
}

export default CheckoutTypeFieldPopup;
