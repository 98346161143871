import { fetchWithErrorHandling, getAuthorizedHeader } from '../utils/apiUtils';

export const getClarityInfo = async () => {
  const requestUrl = `${process.env.REACT_APP_BACKEND_SERVICE}/fe-env-config/clarity-info`;
  return fetchWithErrorHandling(requestUrl, {
    headers: getAuthorizedHeader(),
  }).catch((err) => {
    console.warn(err);
    return null;
  });
};
