import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { makeStyles } from '@material-ui/core';

import AddMultiplePartsTour from '../../components/tour/AddMultiplePartsTour';
import MultiplePartUploadForm from '../../components/forms/MultiplePartUploadForm';
import PartUploadStepOneV2 from '../../components/forms/PartUploadStepOneV2';

import { openPartSummaryPage } from '../../actions';

import { FEATURE_FLAG_PART_UPLOAD_REVAMP } from '../../constants/featureFlagConstants';

import { colors } from '../../palette';

const useStyles = (partUploadRevamp) =>
  makeStyles(() => ({
    '@global': {
      body: {
        backgroundColor:
          FEATURE_FLAG_PART_UPLOAD_REVAMP === 'true' && partUploadRevamp
            ? colors.neutral020
            : colors.fontWhite,
      },
    },
    body: {
      height: '100%',
    },
  }));

function AddMultipleParts(props) {
  const classes = useStyles(true)();

  const { openSummaryPage, changeTitle } = props;

  const [summaryFormOpen, setSummaryFormOpen] = useState(false);
  const [formDataAvailable, setFormDataAvailable] = useState(false);

  function sendFormData(data) {
    if (data.length > 0) {
      setFormDataAvailable(data);
      setSummaryFormOpen(true);
    }
  }

  useEffect(() => {
    if (summaryFormOpen) {
      openSummaryPage(formDataAvailable);
    }
  }, [summaryFormOpen, formDataAvailable, openSummaryPage]);

  return (
    <div className={classes.body}>
      {FEATURE_FLAG_PART_UPLOAD_REVAMP === 'true' ? (
        <PartUploadStepOneV2 sendFormData={sendFormData} />
      ) : (
        <Fragment>
          <AddMultiplePartsTour />
          <MultiplePartUploadForm
            sendFormData={sendFormData}
            changeTitle={changeTitle}
          />
        </Fragment>
      )}
    </div>
  );
}

function matchDispatchToProps(dispatch, props) {
  return {
    openSummaryPage: (formDataAvailable) =>
      dispatch(openPartSummaryPage(formDataAvailable, props)),
  };
}

const withConnect = connect(null, matchDispatchToProps);
export default withRouter(withConnect(AddMultipleParts));
