import React, { useReducer } from 'react';
import { isEmpty } from 'lodash';

import { makeStyles } from '@material-ui/core/styles/index';

import {
  Button,
  CircularProgress,
  Container,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
} from '@material-ui/core';

import { adminGenerateCombinedQuotes } from '../../apis/pdfApi';

import { notifyError } from '../../services/notificationService';

import { SHIPPING_MODES } from '../../constants/checkoutConstants';
import { DELIVERY_OPTIONS_DISPLAY_MAPPING } from '../../constants/itemConstants';
import { Link } from 'react-router-dom';
import { downloadS3File } from '../../utils/fileUtils';
import { getQuotationByID } from '../../apis/quotationApi';
import { getItemDetailsApi } from '../../apis/itemApi';
import Addresses from '../../components/Addresses';
import { getUserInfo } from '../../apis/userApi';
import { addressInfoText, checkCountryAddress } from '../../utils/addressUtils';

const useStyles = makeStyles((theme) => ({
  circularProgress: {
    marginLeft: 0,
    marginRight: theme.spacing.unit,
  },
}));

function GenerateCombinedQuotes() {
  const classes = useStyles();

  const [localState, updateLocalState] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      quotationIDs: '',
      shippingMode: SHIPPING_MODES.SAME_DATE,
      downloadUrl: '',
      processingGenerateQuotes: false,
      userInfo: null,
      processingAddresses: false,
      shippingAddress: null,
      billingAddress: null,
      switchDifferentAddress: null,
    }
  );

  const handleGenerateCombinedQuotes = () => {
    const quotations = localState.quotationIDs
      .split(',')
      .map((quote) => quote.trim());
    const _billing = localState.switchDifferentAddress
      ? localState.billingAddress
      : localState.shippingAddress;
    const body = {
      quotations,
      shippingMode: localState.shippingMode,
      addresses: {
        shipping: {
          address: addressInfoText(localState.shippingAddress),
          contactNumber:
            localState.shippingAddress?.contactNumber ||
            localState?.userInfo?.contact,
          contactName:
            localState.shippingAddress?.contactName ||
            localState?.userInfo?.name,
          country:
            localState.shippingAddress?.country ||
            checkCountryAddress(localState.shippingAddress?.address) ||
            localState?.userInfo?.country,
        },
        billing: {
          address: addressInfoText(_billing),
          contactNumber:
            _billing?.contactNumber || localState?.userInfo?.contact,
          contactName: _billing?.contactName || localState?.userInfo?.name,
          country:
            _billing?.country ||
            checkCountryAddress(_billing?.address) ||
            localState?.userInfo?.country,
        },
      },
    };
    updateLocalState({ downloadUrl: '', processingGenerateQuotes: true });
    adminGenerateCombinedQuotes(body)
      .then((url) => updateLocalState({ downloadUrl: url }))
      .catch(() => notifyError('Unable to generate combined quotes'))
      .finally(() => updateLocalState({ processingGenerateQuotes: false }));
  };

  const handleGenerateAddresses = () => {
    const quotations = localState.quotationIDs
      .split(',')
      .map((quote) => quote.trim());
    const quotationID = quotations[0];
    updateLocalState({ processingAddresses: true });
    getQuotationByID(quotationID)
      .then((quotation) => {
        if (!quotation?.itemID) {
          throw new Error('Quotation ID is not found!');
        }
        return getItemDetailsApi(quotation?.itemID);
      })
      .then((item) => getUserInfo(item?.userID))
      .then((userInfo) => updateLocalState({ userInfo }))
      .catch((err) => {
        notifyError(err?.message || 'Unable to generate addresses');
        updateLocalState({
          userInfo: null,
          shippingAddress: null,
          billingAddress: null,
        });
      })
      .finally(() => updateLocalState({ processingAddresses: false }));
  };

  return (
    <Container>
      <div
        style={{
          paddingTop: '2rem',
        }}
      >
        <TextField
          required
          label='Quotation IDs'
          id={`quotation-ids`}
          variant='outlined'
          placeholder='eg: 2134, 2135'
          value={localState.quotationIDs}
          onChange={(event) =>
            updateLocalState({ quotationIDs: event.target.value })
          }
          margin='dense'
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
        />
      </div>
      <FormControl component='fieldset' style={{ marginTop: '1rem' }}>
        <FormLabel id='shipping-mode'>Delivery Option</FormLabel>
        <RadioGroup
          row
          value={localState.shippingMode}
          onChange={(event) =>
            updateLocalState({ shippingMode: event.target.value })
          }
        >
          {Object.entries(DELIVERY_OPTIONS_DISPLAY_MAPPING).map(
            ([key, value]) => {
              return (
                <FormControlLabel
                  key={key}
                  value={key}
                  control={<Radio color='primary' />}
                  label={value}
                />
              );
            }
          )}
        </RadioGroup>
      </FormControl>
      {localState.userInfo && (
        <Addresses
          handlers={{
            setBillingAddress: (billingAddress) =>
              updateLocalState({ billingAddress }),
            setShippingAddress: (shippingAddress) =>
              updateLocalState({ shippingAddress }),
            setSwitchDifferentAddress: (switchDifferentAddress) =>
              updateLocalState({ switchDifferentAddress }),
          }}
          values={{
            userID: localState.userInfo?.userID,
            billingAddress: localState.billingAddress,
            shippingAddress: localState.shippingAddress,
            switchDifferentAddress: localState.switchDifferentAddress,
          }}
          maxWidth={false}
        />
      )}
      <div style={{ marginTop: '1rem' }}>
        <Button
          variant='contained'
          color='primary'
          fullWidth
          onClick={handleGenerateAddresses}
          disabled={
            isEmpty(localState.quotationIDs) ||
            localState.processingAddresses === true
          }
        >
          {localState.processingAddresses === true && (
            <CircularProgress className={classes.circularProgress} size={20} />
          )}
          Generate Addresses
        </Button>
      </div>
      <div style={{ marginTop: '1rem' }}>
        <Button
          variant='contained'
          color='primary'
          fullWidth
          onClick={handleGenerateCombinedQuotes}
          disabled={
            isEmpty(localState.quotationIDs) ||
            localState.processingGenerateQuotes === true ||
            !localState.shippingAddress?.address
          }
        >
          {localState.processingGenerateQuotes === true && (
            <CircularProgress className={classes.circularProgress} size={20} />
          )}
          Generate Combined Quotes
        </Button>
      </div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          marginTop: '1rem',
        }}
      >
        Download Link:&nbsp;
        {localState.processingGenerateQuotes === true && (
          <CircularProgress className={classes.circularProgress} size={20} />
        )}
        <Link
          onClick={(e) => {
            e.stopPropagation();
            downloadS3File(localState.downloadUrl);
          }}
        >
          {localState.downloadUrl}
        </Link>
      </div>
    </Container>
  );
}

export default GenerateCombinedQuotes;
