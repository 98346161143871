import React, { forwardRef, useState } from 'react';
import { useSelector } from 'react-redux';

import { MAX_UPLOAD_FILES_GUEST_USER } from '../../constants/NewPartConstants';

import { FtrTypography } from '../ftr-components';

import redAlertIcon from '../../assets/icons/red_alert.svg';
import uploadFileIcon from '../../assets/icons/upload_file.svg';
import { ReactComponent as UploadIcon } from '../../assets/icons/upload_icon.svg';

import useDeviceCheck from '../../hooks/useDeviceCheck';

import { getUserIDSelector } from '../../selectors/userSelector';
import { getPartUploadFormSelector } from '../../selectors/partUploadFormSelector';

import withSignUpPopupHOC from '../../hocs/withSignUpPopupHOC';

import { isEmptyValue } from '../../utils/commonUtils';
import { getFileTypesForIphone } from '../../utils/fileUtils';

import { colors } from '../../palette';

// -------------------------------------------------------------------------------------------------

const PartUploadDragAndDropPresentation = forwardRef((props, ref) => {
  const {
    id = 'cad-part-file',
    fullWidth = true,
    supportFilesText,
    supportFileExtensions,
    isAdditionalInfoShown = false,
    multiple = true,
    simpleLabel = false,
    startIcon = <UploadIcon />,
    draggingLabelText = 'Drop design files here',
    labelText = 'Drag or browse to upload',
    simpleLabelStyle,
    error,
    setUnsupportedFileType = () => {},
    handleUploadFiles = () => {},
    setError = () => {},
    onSignUpPopup = () => {},
    ...rest
  } = props;

  const [{ isMobile, isIPhone }] = useDeviceCheck();

  const [dragging, setDragging] = useState(false);
  const [isEnter, setIsEnter] = useState(false);

  const userID = useSelector(getUserIDSelector);
  const partUploadForm = useSelector(getPartUploadFormSelector);

  const guestUserMaxFilesUploadReached =
    !userID &&
    partUploadForm.totalUploadCountGuestUser >= MAX_UPLOAD_FILES_GUEST_USER;

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(true);
  };
  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsEnter(true);
  };
  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsEnter(false);
    setDragging(false);
  };
  const handleDrop = (e) => {
    setUnsupportedFileType([]);
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);
    setIsEnter(false);

    if (guestUserMaxFilesUploadReached) {
      return onSignUpPopup();
    }

    handleUploadFiles(e.dataTransfer.files);
  };

  const handleClick = (e) => {
    // check how many files have been uploaded if the user id is null
    if (guestUserMaxFilesUploadReached) {
      e.preventDefault();
      return onSignUpPopup();
    }

    setUnsupportedFileType([]);
    setError('');
    e.target.value = null;
  };

  const renderMainText = () => {
    return (
      <div
        style={{
          color: colors.fontGrey,
          fontSize: isMobile ? 14 : 16,
          lineHeight: '21px',
          textAlign: 'center',
        }}
      >
        <FtrTypography
          type='body'
          fontSize='16'
          style={{ padding: '0.5rem 0', color: colors.fontBlack }}
        >
          Drop files here or
          <span style={{ color: colors.blue060, fontWeight: 700 }}>
            {' '}
            Browse{' '}
          </span>
          to upload more parts
        </FtrTypography>
        <FtrTypography
          type='body'
          fontSize='14'
          style={{ color: colors.fontBlack }}
        >
          Supports&nbsp;{supportFilesText}
        </FtrTypography>
        {isAdditionalInfoShown && (
          <FtrTypography
            type='body'
            fontSize='14'
            style={{ padding: '0.5rem 0', color: colors.neutral060 }}
          >
            Each upload is recognised as a separate part.
          </FtrTypography>
        )}
        {!isEmptyValue(error) && (
          <FtrTypography
            type='body'
            fontSize='14'
            style={{
              display: 'flex',
              justifyContent: 'center',
              padding: '0.5rem 0',
              color: colors.neutral070,
            }}
          >
            <img src={redAlertIcon} alt='logo' />
            &nbsp;{error}
          </FtrTypography>
        )}
      </div>
    );
  };

  const renderLabel = () => {
    if (simpleLabel) {
      return (
        <div
          style={{
            backgroundColor:
              isEnter || dragging ? colors.blue010 : 'transparent',
            display: 'inline-flex',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer',
            border: `1px solid ${colors.blue050}`,
            padding: '0.5rem 1rem',
            borderRadius: '10px',
            ...simpleLabelStyle,
          }}
        >
          {startIcon}
          <FtrTypography
            type='body'
            fontSize='14'
            style={{
              color: colors.blue050,
              paddingLeft: '8px',
              fontWeight: 600,
            }}
          >
            {isEnter || dragging ? draggingLabelText : labelText}
          </FtrTypography>
        </div>
      );
    }

    return (
      <div
        data-cy='upload-multi-cad-files'
        style={{
          borderRadius: '10px',
          border: `dashed #A9A9A9 1.5px`,
          width: fullWidth ? 'auto' : !isMobile && '55rem',
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          verticalAlign: 'middle',
          alignItems: 'center',
          cursor: 'pointer',
          opacity: isEnter ? 0.8 : 1,
          padding: isMobile ? '20px 10px' : '40px',
        }}
      >
        <img
          src={uploadFileIcon}
          style={isMobile ? { height: 40 } : null}
          alt='logo'
        />
        {dragging && 'Release to upload files'}
        {!dragging && renderMainText()}
      </div>
    );
  };

  return (
    <div ref={ref} {...rest}>
      <input
        id={id}
        type='file'
        accept={
          isIPhone
            ? getFileTypesForIphone(supportFileExtensions)
            : supportFileExtensions
        }
        multiple={multiple}
        onChange={(evt) => {
          const files = evt.target.files;
          setUnsupportedFileType([]);
          setError('');
          handleUploadFiles(files);
        }}
        onClick={handleClick}
        style={{
          display: 'none',
        }}
      />
      <label
        htmlFor={id}
        onDragOver={handleDragOver}
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
      >
        {renderLabel()}
      </label>
    </div>
  );
});

PartUploadDragAndDropPresentation.displayName =
  'PartUploadDragAndDropPresentation';

export default withSignUpPopupHOC(PartUploadDragAndDropPresentation);
