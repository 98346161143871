import React from 'react';
import { Tooltip } from '@material-ui/core';
import clsx from 'clsx';
import { toast } from 'react-toastify';
import {
  Visibility as VisibilityIcon,
  VisibilityOutlined as VisibilityOutlinedIcon,
} from '@material-ui/icons';

import { makeStyles } from '@material-ui/core/styles/index';

import { WATCHING_JOB_STATUS } from '../constants/watchingJob';
import { colors } from '../palette';
import { upsertWatchingJob } from '../apis/watchingJobApi';

const useStyles = makeStyles(() => ({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    color: colors.lightGray,
    zIndex: 1,
    border: `2px solid ${colors.lightGray}`,
    borderRadius: '4px',
    minHeight: '1.1rem',
    minWidth: '2rem',
    width: '9.5rem',
    columnGap: '3px',
    justifyContent: 'center',
    margin: '3px 0',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    cursor: 'pointer',
  },
  watchLabelActive: {
    borderColor: colors.blue050,
    color: colors.blue050,
    width: '9.5rem',
  },
  onlyIcon: {
    width: '2.5rem',
    height: '1.125rem',
  },
}));

/**
 *
 * @param {Object} props
 * @param {Boolean} props.withText
 * @param {Boolean} props.isWatching
 * @param {() => void} props.setIsWatching
 * @param {Number[]} props.itemIDs
 * @param {Number} props.projectID
 * @param {() => void} props.callbackSuccess
 * @param {Boolean} props.forAdmin
 * @param {() => void} props.onClick
 * @param {Number} props.totalWatching
 */
export const WatchingJob = (props) => {
  const classes = useStyles();
  const {
    withText = false,
    isWatching = false,
    setIsWatching = () => null,
    itemIDs,
    callbackSuccess = () => null,
    onClick = () => null,
    forAdmin = false,
    totalWatching,
  } = props;
  const handleUpsertWatchingJob = (status) => {
    upsertWatchingJob({
      itemIDs,
      status,
    }).then(() => {
      toast.success(
        isWatching
          ? 'Unwatched Job: Factorem will stop notifying you about this job!'
          : 'Watching Job: Factorem will notify you in case there is any activity on this job!',
        {
          position: toast.POSITION.TOP_RIGHT,
        }
      );
      setIsWatching(!isWatching);
      callbackSuccess();
    });
  };

  const tooltip = {
    admin: {
      watching: `${
        totalWatching === 1 ? '1 supplier is' : `${totalWatching} suppliers are`
      } watching this item`,
      noWatching: 'No suppliers are watching this item',
    },
    notAdmin: {
      watching:
        'You are receiving notification for updates on this project (click to stop watching)',
      noWatching: 'Watch to get notifications on this part / project',
    },
  };
  return (
    <Tooltip
      title={
        isWatching
          ? tooltip[forAdmin ? 'admin' : 'notAdmin'].watching
          : tooltip[forAdmin ? 'admin' : 'notAdmin'].noWatching
      }
      arrow
    >
      <div
        className={clsx(
          classes.wrapper,
          isWatching && classes.watchLabelActive,
          !withText && classes.onlyIcon
        )}
        onClick={(evt) => {
          evt.preventDefault();
          onClick();
          if (!forAdmin) {
            handleUpsertWatchingJob(
              isWatching
                ? WATCHING_JOB_STATUS.INACTIVE
                : WATCHING_JOB_STATUS.ACTIVE
            );
          }
        }}
      >
        {isWatching ? (
          <React.Fragment>
            <VisibilityIcon style={{ color: colors.blue050 }} />
            {withText && 'Watching'}
          </React.Fragment>
        ) : (
          <React.Fragment>
            <VisibilityOutlinedIcon />
            {withText && 'Watch'}
          </React.Fragment>
        )}
      </div>
    </Tooltip>
  );
};
