import { CsvBuilder } from 'filefy';
import React, { useEffect, useReducer, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles/index';

import {
  Button,
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';

import CreateDeliveryOrderDialog from '../../components/dialogs/CreateDeliveryOrderDialog';
import HorizontalExpandSpace from '../../components/ftr-components/HorizontalExpandSpace';
import { ExportCsvButton } from '../../components/grid-data/buttons/ExportCsvButton';
import SearchBar from '../../components/grid-data/buttons/SearchBar';
import { DataGridToolbarLayout } from '../../components/grid-data/CustomToolbar';
import { FlexRow } from '../../components/layouts/FlexLayouts';
import LoadingBackDropText from '../../components/LoadingBackDropText';
import DataGridWrapTextCell from '../../components/tables/cells/DataGridWrapTextCell';

import { createPdf, getAllItemsAvailableForInvoice } from '../../actions';

import { useMuiTableSearchAndPagination } from '../../hooks/useMuiTablePagination';
import { usePrevious } from '../../hooks/usePrevious';

import { getUserRoleSelector } from '../../selectors/userSelector';

import { generateCurrentCustomDateTimeString } from '../../util';
import { isEmptyValue } from '../../utils/commonUtils';
import { transformCsvExportData } from '../../utils/csvExportUtils';
import { isSuperAdminRole } from '../../utils/roleUtils';

import { notifyError, notifySuccess } from '../../services/notificationService';

import { colors } from '../../palette';

// -------------------------------------------------------------------------------------------------

const useStyles = makeStyles(() => ({
  tableContainer: {
    '& tbody>.MuiTableRow-root.Mui-selected': {
      background: `${colors.green050}33 !important`,
    },
    '& tbody>.MuiTableRow-root:hover': {
      background: `${colors.secondaryBlue} !important`,
      cursor: 'pointer',
    },
  },
  customExportSvg: {
    margin: '0.3rem',
  },
  createDeliveryOrder: {
    margin: '0.3rem',
  },
}));

function CreateDeliveryOrderMuiTable(props) {
  const classes = useStyles();

  const {
    itemsAvailableForInvoice,
    onPageLoad,
    submitCreatePdf,
    createPdfStatus,
  } = props;

  const role = useSelector(getUserRoleSelector);

  const previousCreatePdfStatus = usePrevious(createPdfStatus);

  const [selectedBuyerID, setSelectedBuyerID] = useState();
  const [selectedOrders, setSelectedOrders] = useState([]);
  const [createDOPopupState, updateCreateDOPopupState] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      open: false,
    }
  );

  useEffect(() => {
    if (isEmptyValue(selectedOrders)) {
      setSelectedBuyerID(null);
    }
  }, [selectedOrders]);

  useEffect(() => {
    if (previousCreatePdfStatus !== 'loading') {
      return;
    }
    if (createPdfStatus === 'success') {
      notifySuccess('DO has been created successfully');
      setSelectedOrders([]);
      setSelectedBuyerID(null);
    } else if (createPdfStatus === 'failure') {
      notifyError('DO creation failed');
    }
  }, [createPdfStatus]);

  const handleCheckboxClick = (event, rowData) => {
    const checked = event.target.checked;
    if (checked) {
      if (selectedBuyerID && selectedBuyerID !== rowData.buyerID) {
        notifyError('Multiple customer selection is not allowed');
        return;
      }
      setSelectedOrders([...selectedOrders, rowData.itemID]);
      setSelectedBuyerID(rowData.buyerID);
    } else {
      const _selectedOrders = selectedOrders.filter(
        (selected) => selected !== rowData.itemID
      );
      setSelectedOrders(_selectedOrders);
    }
  };

  const handleQuotationRowClick = (event, rowData) => {
    if (selectedBuyerID && selectedBuyerID !== rowData.buyerID) {
      notifyError('Multiple customer selection is not allowed');
      return;
    }
    if (!selectedOrders.includes(rowData.itemID)) {
      setSelectedOrders([...selectedOrders, rowData.itemID]);
      setSelectedBuyerID(rowData.buyerID);
    } else {
      const _selectedOrders = selectedOrders.filter(
        (selected) => selected !== rowData.itemID
      );
      setSelectedOrders(_selectedOrders);
    }
  };

  const columns = [
    {
      title: '',
      render: (rowData) => {
        return (
          <Checkbox
            checked={selectedOrders.includes(rowData.itemID)}
            onChange={(event) => handleCheckboxClick(event, rowData)}
          />
        );
      },
    },
    { title: 'Part ID', field: 'itemID' },
    {
      title: 'Customer',
      field: 'buyerName',
      exportData: (rowData) => {
        return `${rowData.buyerName} <${rowData.buyerEmail}> (${rowData.buyerID})`;
      },
      render: (rowData) => {
        const text = `${rowData.buyerName} <${rowData.buyerEmail}> (${rowData.buyerID})`;
        return <DataGridWrapTextCell text={text} />;
      },
    },
    {
      title: 'Date Posted',
      field: 'datePosted',
      render: (rowData) =>
        rowData.datePosted ? rowData.datePosted.substring(0, 10) : '',
    },
    {
      title: 'Part Name',
      field: 'name',
      cellStyle: {
        overflow: 'hidden',
        whiteSpace: 'break-space',
        maxWidth: '200px',
      },
    },
    { title: 'Partner', field: 'supplierName' },
    { title: 'QuotationID', field: 'quotationID' },
    { title: 'Qty', field: 'quantity' },
    {
      title: 'Deadline',
      field: 'deadline',
      render: (rowData) =>
        rowData.deadline ? rowData.deadline.substring(0, 10) : '',
    },
  ];

  const [{ displayRows, filteredRows }, { setAllRows, setSearchTerm }] =
    useMuiTableSearchAndPagination({
      initialColumns: columns,
      showAll: true,
    });

  useEffect(() => {
    onPageLoad();
  }, []);

  useEffect(() => {
    setAllRows(itemsAvailableForInvoice);
  }, [itemsAvailableForInvoice]);

  const handleConfirm = (data) => {
    const { selectedDate, refClientPO } = data;
    submitCreatePdf(selectedOrders, selectedDate, 1, refClientPO);
    updateCreateDOPopupState({ open: false });
  };

  return (
    <Paper
      className={classes.tableContainer}
      style={{
        marginTop: '1rem',
      }}
    >
      <DataGridToolbarLayout>
        <HorizontalExpandSpace />
        <FlexRow>
          <Button
            onClick={() => updateCreateDOPopupState({ open: true })}
            variant='contained'
            color='primary'
            className={classes.createDeliveryOrder}
            disabled={isEmptyValue(selectedOrders)}
          >
            Create delivery order
          </Button>
          <SearchBar onSearch={setSearchTerm} useDebounceSearch />
          {isSuperAdminRole(role) && (
            <ExportCsvButton
              iconOnly
              border={false}
              customStyle={classes.customExportSvg}
              handleClick={() => {
                const fileName = `All Create DOs ${generateCurrentCustomDateTimeString()}.csv`;
                const { exportedColumns, exportedData } =
                  transformCsvExportData(columns, filteredRows);
                const builder = new CsvBuilder(fileName);
                builder
                  .setDelimeter(',')
                  .setColumns(exportedColumns)
                  .addRows(exportedData)
                  .exportFile();
              }}
            />
          )}
        </FlexRow>
      </DataGridToolbarLayout>
      <Table aria-label='table'>
        <TableHead>
          <TableRow>
            {columns.map((column) => {
              if (column.hidden) {
                return <TableCell key={column.title}></TableCell>;
              }
              return (
                <TableCell
                  key={column.title}
                  style={{
                    top: 0,
                    color: colors.blue060,
                    fontSize: '11pt',
                    fontWeight: 600,
                  }}
                >
                  {column.title}
                </TableCell>
              );
            })}
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {displayRows.map((row) => {
            return (
              <TableRow
                selected={selectedOrders.includes(row.itemID)}
                key={`${row.itemID}-${row.quotationID}`}
                onClick={(e) => handleQuotationRowClick(e, row)}
                className={classes.tableRow}
              >
                {columns.map((column) => {
                  const renderFunc = column.render;
                  if (!renderFunc && !column.field) {
                    return null;
                  }
                  return (
                    <TableCell key={row.field} style={{ width: column.width }}>
                      {typeof renderFunc === 'function'
                        ? renderFunc(row)
                        : row[column.field]}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      {createDOPopupState.open && (
        <CreateDeliveryOrderDialog
          open={createDOPopupState.open}
          selected={selectedOrders}
          onCancel={() => {
            updateCreateDOPopupState({
              open: false,
            });
          }}
          onConfirm={handleConfirm}
        />
      )}
      {createPdfStatus === 'loading' && (
        <LoadingBackDropText open={createPdfStatus} text='Creating DO...' />
      )}
    </Paper>
  );
}

function mapStateToProps(state) {
  return {
    itemsAvailableForInvoice: state.items.itemsAvailableForInvoice,
    role: state.auth.user.role,
    createPdfStatus: state.pdf.createPdfStatus,
  };
}

function matchDispatchToProps(dispatch) {
  return {
    onPageLoad: () => {
      dispatch(getAllItemsAvailableForInvoice());
    },
    submitCreatePdf: (data, date, type, refClientPO) =>
      dispatch(createPdf({ itemIDs: data, date, type, refClientPO })),
  };
}

const withConnect = connect(mapStateToProps, matchDispatchToProps);

export default withRouter(withConnect(CreateDeliveryOrderMuiTable));
