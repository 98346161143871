import React, { useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Cookies } from 'react-cookie';
import { makeStyles } from '@material-ui/core/styles/index';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { toast } from 'react-toastify';

const cookies = new Cookies();

import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  Tab,
  Tabs,
  Tooltip,
  Badge,
  Radio,
  RadioGroup,
} from '@material-ui/core';

import AcknowledgmentCheckbox from './confirm-order-popup/AcknowledgmentCheckbox';
import BlueButton from '../buttons/BlueButton';
import DeliveryOptionsPanel from '../panels/DeliveryOptionsPanel';
import GreyButton from '../buttons/GreyButton';
import MultiCheckoutSummaryPanel from '../panels/MultiCheckoutSummaryPanel';
import OutlinedDiv from '../panels/OutlinedDiv';
import QcReportsDisplay from '../info/QcReportsDisplay';
import QuoteDetails from '../QuoteDetails';
import Title from '../Title';
import { FlexRow } from '../layouts/FlexLayouts';

import ForwardEmailIcon from '../../assets/icons/forward_to_inbox_grey.svg';
import PdfDownloadIcon from '../../assets/icons/download_pdf_icon.svg';

import withConfirmOrderPopupsHOC from './confirm-order-popup/withConfirmOrderPopupsHOC';
import withLoadingBackDropTextHOC from '../../hocs/withLoadingBackDropTextHOC';

import { downloadS3File } from '../../utils/fileUtils';
import { getCombinedQuotationsFormUrl } from '../../apis/multiCheckoutApi';
import { getFeedbackOfUsers } from '../../apis/userFeedbackApi';
import { getItemsByIDList } from '../../apis/itemApi';
import { isEmptyValue } from '../../utils/commonUtils';

import {
  confirmMultiCheckout,
  updateMulticheckoutFormAddress,
  updatePaymentType,
} from '../../actions/multiCheckoutForm';

import {
  getUserCurrencySelector,
  getUserFeeTypeSelector,
} from '../../selectors/userSelector';

import { COUNTRY_NAMES } from '../../constants/countryConstants';
import { CURRENCY } from '../../constants';
import { CUSTOMER_CREDIT_TYPES } from '../../constants/customerConstants';
import { PAYMENT_TYPE } from '../../constants/paymentConstants';
import { USER_FEE_TYPE } from '../../constants/userConstant';

import { colors } from '../../palette';

// --------------------------------------------------------------------------------------

// Style the components
const useStyles = makeStyles((theme) => ({
  paper: {
    borderRadius: 0,
    [theme.breakpoints.up('md')]: {
      minWidth: 700,
      width: '90%',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      margin: 0,
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: colors.fontWhite,
  },
  error: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: colors.errorRed,
    margin: '10px 0',
  },
  tabRoot: {
    '&:hover': {
      color: 'white',
      backgroundColor: colors.blue050,
    },
  },
  selectedTab: {
    '&:hover': {
      backgroundColor: colors.secondaryBlue,
      color: colors.blue050,
    },
    backgroundColor: colors.secondaryBlue,
    color: colors.blue050,
  },
  dialogContent: {
    padding: '0 30px',
    [theme.breakpoints.down('sm')]: {
      padding: 20,
      paddingTop: 0,
    },
  },
  dialogActions: {
    padding: 20,
    backgroundColor: colors.expansionBackgroundColor,
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      padding: 5,
    },
  },
  checkboxForm: {
    [theme.breakpoints.down('sm')]: {
      padding: 0,
      margin: 0,
    },
    maxWidth: 800,
    display: 'flex',
    alignItems: 'center',
  },
  checkboxFormLabel: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  downloadButton: {
    width: 50,
    height: 40,
    borderRadius: 8,
    backgroundColor: colors.secondaryBlue,
  },
}));

/**
 * @deprecated
 * @param {*} props
 * @returns
 */
function ApproveMultipleQuotationsPopup(props) {
  const classes = useStyles();

  const dispatch = useDispatch();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const {
    quotes,
    dialog,
    handleClose,
    customerID,
    shippingMode,
    onShippingModeChange,
    shippingModeList,
    exchangeRate,
    project,
    onAddressesChange,
    multiCheckoutForm,
    updatePopupsHocState = () => {},
    setLoadingMessage = () => {},
  } = props;

  const itemIDList = quotes.map((quote) => quote.itemID);

  const currency = useSelector(getUserCurrencySelector);
  const feeType = useSelector(getUserFeeTypeSelector);

  const { data: items } = useQuery('getItemsByIDList', () =>
    getItemsByIDList(itemIDList).then((data) => {
      updatePopupsHocState({
        items: data,
      });
      return data;
    })
  );

  const { data: customerFeedback } = useQuery(
    ['getFeedbackOfUsers', customerID],
    () => getFeedbackOfUsers(customerID)
  );

  const [addresses, setAddresses] = useState({
    shipping: {
      address: '',
      contactNumber: '',
      contactName: '',
      country: '',
    },
    billing: {
      address: '',
      contactNumber: '',
      contactName: '',
      country: '',
    },
  });

  const [customerRemarks, setCustomerRemarks] = useState({});
  const [checkAck, setCheckAck] = useState(false);
  const [creditType, setCreditType] = useState('');
  const [selectedQuoteDetailsIndex, setSelectedQuoteDetailsIndex] = useState(0);
  const [partInfo, setPartInfo] = useState(null);
  const [quoteInfo, setQuoteInfo] = useState(null);
  const [prepareDownloadUrl, setPrepareDownloadUrl] = useState(false);

  useEffect(() => {
    updatePopupsHocState({
      showAcceptedPopup: multiCheckoutForm.showAcceptedPopup,
    });
  }, [multiCheckoutForm.showAcceptedPopup]);

  useEffect(() => {
    setLoadingMessage(multiCheckoutForm.loadingMessage);
  }, [multiCheckoutForm.loadingMessage]);

  useEffect(() => {
    if (
      isEmptyValue(customerFeedback) &&
      multiCheckoutForm.confirmOrderStatus === 'success'
    ) {
      updatePopupsHocState({ showFeedbackPopup: true });
    }
  }, [customerFeedback, multiCheckoutForm.confirmOrderStatus]);

  useEffect(() => {
    updatePopupsHocState({ addresses });
  }, [addresses]);

  function retrieveUserCreditType() {
    setLoadingMessage('Retrieving order details');
    const requestUrl = `${process.env.REACT_APP_BACKEND_SERVICE}/users/CreditType`;

    fetch(requestUrl, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${cookies.get('token')}`,
      },
    })
      .then((res) => {
        res.json().then((response) => {
          setLoadingMessage('');
          setCreditType(response.data.creditType);
        });
      })
      .catch(() => {
        setLoadingMessage('');
        toast.error(
          'There was an error fetching your credit information, kindly reload the page',
          {
            position: toast.POSITION.TOP_RIGHT,
          }
        );
      });
  }

  useEffect(() => {
    retrieveUserCreditType();
  }, []);

  useEffect(() => {
    if (isEmptyValue(quotes) || isEmptyValue(items)) {
      return;
    }

    const _quoteInfo = quotes[selectedQuoteDetailsIndex];
    setQuoteInfo({ ..._quoteInfo });
    const itemID = _quoteInfo.itemID;
    const item = items.find((it) => it.itemID === itemID);
    setPartInfo({ ...item });
  }, [selectedQuoteDetailsIndex, quotes, items]);

  const handleSubmit = () => {
    dispatch(
      confirmMultiCheckout({
        customerID,
        creditType,
        customerRemarks,
        addresses,
      })
    );
  };

  const handleSelectQuoteDetails = (event, newValue) => {
    setSelectedQuoteDetailsIndex(newValue);
  };

  const downloadCombinedQuotationsForm = () => {
    setPrepareDownloadUrl(true);
    const body = {
      quotations: quotes.map((quote) => quote.quotationID),
      shippingMode,
      exchangeRate,
      addresses,
      paymentType: multiCheckoutForm.paymentType,
      deliveryInfoID: multiCheckoutForm.deliveryInfoID,
    };
    getCombinedQuotationsFormUrl(body)
      .then((data) => {
        downloadS3File(data);
      })
      .finally(() => setPrepareDownloadUrl(false));
  };

  const renderQuoteItemsDetails = () => {
    return (
      <QuoteDetails
        partInfo={partInfo}
        quoteInfo={quoteInfo}
        customerRemarks={customerRemarks[quoteInfo.quotationID]}
        handleRemarks={(evt) =>
          setCustomerRemarks({
            ...customerRemarks,
            [quoteInfo.quotationID]: evt.target.value,
          })
        }
        handleAddresses={(value) => {
          setAddresses(value);
          onAddressesChange(value);
          dispatch(
            updateMulticheckoutFormAddress({
              country: value?.shipping?.country,
              deliveryInfoID: value?.id,
            })
          );
        }}
      />
    );
  };

  const renderSubmitButton = () => {
    const buttonText =
      creditType === CUSTOMER_CREDIT_TYPES.CREDIT
        ? quotes.length === 1
          ? 'Accept Quote'
          : 'Accept Quotes'
        : 'Confirm and Pay';

    const button = (
      <BlueButton
        id='checkout-submit-btn'
        onBtnClick={handleSubmit}
        btnContent={buttonText}
        disabled={!checkAck || !addresses?.shipping?.address}
      />
    );

    if ((checkAck && addresses?.shipping?.address) || isMobile) {
      return button;
    }
    let tooltipText = 'Please acknowledge terms on the left hand side';
    if (!addresses?.shipping?.address) {
      tooltipText = 'Please add address!';
    }
    return (
      <Tooltip title={tooltipText} arrow placement='top'>
        <div>{button}</div>
      </Tooltip>
    );
  };

  const renderAcknowledgementCheckbox = () => {
    return (
      <AcknowledgmentCheckbox
        userID={customerID}
        checkAck={checkAck}
        onCheck={setCheckAck}
      />
    );
  };

  const renderQcReportInfo = () => {
    return (
      <div
        style={{
          marginBottom: '1rem',
        }}
      >
        <OutlinedDiv label='QC Report'>
          <QcReportsDisplay qcReports={project.qcReports} />
        </OutlinedDiv>
      </div>
    );
  };

  const PaymentOptionsPanel = useMemo(() => {
    if (
      currency !== CURRENCY.SGD ||
      creditType === CUSTOMER_CREDIT_TYPES.CREDIT ||
      feeType === USER_FEE_TYPE.NO_FEE
    ) {
      return null;
    }

    return (
      <div
        style={{
          marginBottom: '1rem',
        }}
      >
        <OutlinedDiv label='Payment Option'>
          <FlexRow>
            <RadioGroup
              row
              value={multiCheckoutForm.paymentType}
              onChange={(event) => {
                dispatch(updatePaymentType(event.target.value));
              }}
            >
              <FormControlLabel
                value={PAYMENT_TYPE.STRIPE_PAYNOW}
                control={<Radio color='primary' />}
                label='Paynow'
              />
              <FormControlLabel
                value={PAYMENT_TYPE.STRIPE_CREDIT_CARD}
                control={<Radio color='primary' />}
                label='Credit Card (3.5% extra fee)'
              />
            </RadioGroup>
          </FlexRow>
        </OutlinedDiv>
      </div>
    );
  }, [dispatch, multiCheckoutForm.paymentType, currency, creditType]);

  return (
    <Dialog
      classes={{ paper: classes.paper }}
      maxWidth='xl'
      open={dialog}
      aria-labelledby='confirmation-dialog-title'
    >
      <DialogTitle id='confirmation-dialog-title'>
        <Title
          contentTitle={`Accept ${quotes.length === 1 ? 'Quote' : 'Quotes'}`}
        />
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Grid container direction='column'>
          <Grid
            item
            xs={12}
            style={{
              overflow: 'auto',
              position: 'sticky',
              top: 0,
              marginBottom: '1.5rem',
              zIndex: 10000,
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                backgroundColor: 'white',
              }}
            >
              <Tabs
                style={{
                  width: 'auto',
                  backgroundColor: 'white',
                }}
                indicatorColor='primary'
                centered
                value={selectedQuoteDetailsIndex}
                onChange={handleSelectQuoteDetails}
                variant='scrollable'
                scrollButtons='auto'
              >
                {quotes.map((quote) => {
                  return (
                    <Tab
                      classes={{
                        root: classes.tabRoot,
                        selected: classes.selectedTab,
                      }}
                      key={quote.quotationID}
                      label={`Part #${quote.itemID}`}
                    />
                  );
                })}
              </Tabs>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginLeft: '1rem',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                  data-cy='download-quotations-btn'
                >
                  <IconButton
                    className={classes.downloadButton}
                    size='medium'
                    color='primary'
                    onClick={downloadCombinedQuotationsForm}
                  >
                    <Tooltip
                      placement='top'
                      title='Download Combined Quotations Form'
                    >
                      {prepareDownloadUrl ? (
                        <div style={{ width: 'auto' }}>
                          <CircularProgress
                            style={{
                              width: 20,
                              height: 20,
                              fontSize: '0.5rem',
                              color: 'black',
                            }}
                          />
                        </div>
                      ) : (
                        <Badge className={classes.quoteNumberBadge}>
                          <img
                            style={{
                              width: '25px',
                              height: '25px',
                              marginRight: '0.5rem',
                              marginLeft: '0.5rem',
                            }}
                            className={classes.imageIcon}
                            src={PdfDownloadIcon}
                            color='primary'
                            alt='quotation-sharing-icon'
                          />
                        </Badge>
                      )}
                    </Tooltip>
                  </IconButton>
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginLeft: '1rem',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                  data-cy='download-quotations-btn'
                >
                  <IconButton
                    className={classes.downloadButton}
                    size='medium'
                    color='primary'
                    onClick={() =>
                      updatePopupsHocState({ showShareQuotePopup: true })
                    }
                  >
                    <Tooltip
                      placement='top'
                      title='Share Combined Quotations Form Via Email'
                    >
                      <Badge className={classes.quoteNumberBadge}>
                        <img
                          style={{
                            width: '25px',
                            height: '25px',
                          }}
                          className={classes.imageIcon}
                          src={ForwardEmailIcon}
                          color='primary'
                          alt='quotation-sharing-icon'
                        />
                      </Badge>
                    </Tooltip>
                  </IconButton>
                </div>
              </div>
            </div>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={12} md={8}>
              {!isEmptyValue(partInfo) &&
                !isEmptyValue(quoteInfo) &&
                renderQuoteItemsDetails()}
            </Grid>
            <Grid
              item
              xs={12}
              md={4}
              style={{
                zIndex: 9999,
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  marginLeft: '1.5rem',
                  marginTop: isMobile ? '1.5rem' : '0.5rem',
                }}
              >
                {renderQcReportInfo()}
                <div
                  style={{
                    marginBottom: '1rem',
                  }}
                >
                  <DeliveryOptionsPanel
                    shippingMode={shippingMode}
                    onChange={(value) => onShippingModeChange(value)}
                    shippingModeList={shippingModeList}
                    disabled={false}
                    status={multiCheckoutForm.status}
                  />
                </div>
                {PaymentOptionsPanel}
                <MultiCheckoutSummaryPanel
                  showButtons={false}
                  shippingCountry={addresses?.shipping?.country}
                  showGst={
                    addresses?.shipping?.country === COUNTRY_NAMES.SINGAPORE
                  }
                />
              </div>
            </Grid>
          </Grid>
          {isMobile && renderAcknowledgementCheckbox()}
        </Grid>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        {!isMobile && renderAcknowledgementCheckbox()}
        <GreyButton
          id='cancel-checkout-btn'
          onBtnClick={handleClose}
          btnContent='Cancel'
        />
        {renderSubmitButton()}
      </DialogActions>
    </Dialog>
  );
}

function mapStateToProps(state) {
  return {
    userEmail: state.auth.user.email,
    exchangeRate: state.auth.rates[state.auth.location.currency],
    country: state.auth.user.country,
    multiCheckoutForm: state.multiCheckoutForm,
  };
}

const withConnect = connect(mapStateToProps, null);

export default withConnect(
  withConfirmOrderPopupsHOC(
    withLoadingBackDropTextHOC(ApproveMultipleQuotationsPopup)
  )
);
