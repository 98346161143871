import { ROLE_TYPES } from '../constants';

export const hasReviewPermission = (role) => {
  return [
    ROLE_TYPES.ADMIN,
    ROLE_TYPES.REVIEWER,
    ROLE_TYPES.SUPER_ADMIN,
  ].includes(role);
};

export const isSuperAdminRole = (role) => {
  return role === ROLE_TYPES.SUPER_ADMIN;
};

export const isAdminOrHigherRole = (role) => {
  return [ROLE_TYPES.SUPER_ADMIN, ROLE_TYPES.ADMIN].includes(role);
};

export const isAdminRole = (role) => role === ROLE_TYPES.ADMIN;

export const isSupplierRole = (role) => {
  return [ROLE_TYPES.SUPPLIER].includes(role);
};
