import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { cloneDeep, isEmpty } from 'lodash';
import { useQuery } from 'react-query';

import { makeStyles } from '@material-ui/core/styles/index';
import {
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Tooltip,
  Typography,
} from '@material-ui/core';

import BlueButton from '../buttons/BlueButton';
import WhiteButton from '../buttons/WhiteButton';
import SubDesc from '../SubDesc';
import QualityAccordion from '../accordions/QualityAccordion';
import DatePickerWithIcon from '../DatePickerWithIcon';
import { FtrNormalText } from '../ftr-components';
import { FlexColumn, FlexRowSpaceBetween } from '../layouts/FlexLayouts';

import { colors } from '../../palette';

import useQualityMetrics from '../../hooks/useQualityMetrics';

import { collectedItems, getTrackingByID } from '../../apis/multiCheckoutApi';

import { isEmptyValue } from '../../utils/commonUtils';
import { formatDeliveryDate } from '../../utils/dateTimeUtils';

const useStyles = makeStyles((theme) => ({
  paper: {
    borderRadius: 0,
  },
  space: {
    width: theme.spacing(2),
  },
  itemCollectedButton: {
    background: colors.orangeGradient,
    color: colors.fontWhite,
    padding: '0.625rem 0.9375rem',
    borderRadius: '0.3125rem',
    fontSize: '9pt',
    letterSpacing: '0.15em',
    '&:hover': {
      filter: 'brightness(0.8)',
    },
  },
}));

const handleSubmitCollectedItems = ({
  itemsData,
  checkoutID,
  dataRefetchFunc,
}) => {
  const body = {
    checkoutID,
    itemsData,
  };
  collectedItems(body).then(dataRefetchFunc);
};

function CollectedInfoPopup(props) {
  const classes = useStyles();

  const {
    open,
    dataRefetchFunc,
    handleClose = () => {},
    handleSubmit = handleSubmitCollectedItems,
    rowData = {},
  } = props;

  const { data: rowDataInfo, isLoading } = useQuery(
    ['getTrackingByID', rowData.id],
    () => getTrackingByID(rowData.id)
  );

  const deliveredItemIDs = rowData.acceptedItems
    .filter((item) => item.delivered && !item.collected)
    .map((item) => item.itemID);

  const [itemsState, setItemsState] = useState([]);

  const groupDataBySupplierID = (data) => {
    const groupedData = {};
    const filteredData = data.filter((item) => item.checked);
    filteredData.forEach((item) => {
      const { supplierID, supplierName, supplierEmail, ...rest } = item;
      if (!groupedData[supplierID]) {
        groupedData[supplierID] = {
          supplierID,
          supplierName,
          supplierEmail,
          items: [],
        };
      }
      groupedData[supplierID].items.push(rest);
    });
    return Object.values(groupedData);
  };

  useEffect(() => {
    if (!isEmptyValue(rowDataInfo?.acceptedItemsInfo)) {
      const initialDataItems = rowDataInfo?.acceptedItemsInfo
        .filter((item) => deliveredItemIDs.includes(item.itemID))
        .map((item) => {
          return {
            ...item,
            actualCollectionDate: item.actualCollectionDate
              ? new Date(item.actualCollectionDate)
              : new Date(),
            checked: false,
          };
        });
      setItemsState(initialDataItems);
    }
  }, [rowDataInfo?.acceptedItemsInfo]);

  const [
    { combinedItemsQuality },
    { updateQualityInformation },
  ] = useQualityMetrics(itemsState);

  const supplierItems = groupDataBySupplierID(combinedItemsQuality);
  const filteredItems = combinedItemsQuality?.filter((item) => item.checked);

  const handleChangeDate = (date, index) => {
    const itemsStateClone = cloneDeep(itemsState);
    itemsStateClone[index].actualCollectionDate = date;
    setItemsState(itemsStateClone);
  };
  const handleChangeCheckbox = (event, index) => {
    const checked = event.target.checked;
    const itemsStateClone = cloneDeep(itemsState);
    itemsStateClone[index].checked = checked;
    setItemsState(itemsStateClone);
  };

  // will update all actualCollectionDates to current date
  const updateActualCollectionDate = () => {
    const itemsStateClone = cloneDeep(itemsState);
    itemsStateClone.forEach((item) => {
      item.actualCollectionDate = new Date();
    });
    setItemsState(itemsStateClone);
  };

  const handleUpdateAllDate = (date) => {
    setItemsState((prevData) => {
      return prevData.map((item) => ({ ...item, actualCollectionDate: date }));
    });
  };

  const renderSendNotification = (itemsState) => {
    const isChecked = itemsState?.some((item) => item.checked);
    if (!isChecked) {
      return null;
    }
    return (
      <div
        style={{
          textAlign: 'start',
          marginTop: '1rem',
        }}
      >
        <div>You are about to send notification:</div>
        <ul
          style={{
            padding: '0 0 0 2rem',
            margin: '0',
          }}
        >
          {supplierItems?.map((user) => (
            <li key={user.supplierID}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Tooltip
                  title={`${user.supplierName} (${user.supplierID})`}
                  arrow
                >
                  <a href={`mailto:${user.supplierEmail}`}>
                    {user.supplierEmail}
                  </a>
                </Tooltip>
                <Typography>
                  {': '}
                  {user.items?.map((item) => item.itemID).join(', ')}
                </Typography>
              </div>
            </li>
          ))}
        </ul>
      </div>
    );
  };

  const renderBody = () => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {combinedItemsQuality?.map((item, index) => {
          return (
            <div key={item.itemID}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  columnGap: '0.5rem',
                }}
              >
                <FormControlLabel
                  style={{ marginRight: '0' }}
                  control={
                    <Checkbox
                      checked={item.checked}
                      onChange={(event) => handleChangeCheckbox(event, index)}
                    />
                  }
                />
                <Typography>
                  ItemID:&nbsp;
                  <Link
                    to={{
                      pathname: `/item/edit/${item.itemID}`,
                    }}
                  >
                    {item.itemID}
                  </Link>
                </Typography>
                <span>-</span>
                <Typography>
                  QuotationID:&nbsp;
                  <Link
                    to={{
                      pathname: `/order/edit/${item.quotationID}`,
                    }}
                  >
                    {item.quotationID}
                  </Link>
                </Typography>
                <span>-</span>
                <DatePickerWithIcon
                  label='Actual Collection Date'
                  value={item.actualCollectionDate}
                  onChange={(date) => handleChangeDate(date, index)}
                  animateYearScrolling
                  inputVariant='outlined'
                  margin='dense'
                  onIconClick={(date) => handleUpdateAllDate(date)}
                />
              </div>
              <QualityAccordion
                style={{ margin: '0 0 0.5rem 2.5rem' }}
                properties={{
                  partnerVisualQuality: item?.partnerVisualQuality,
                  partnerPackagingQuality: item?.partnerPackagingQuality,
                }}
                updateQualityInformation={updateQualityInformation}
                itemID={item.itemID}
              />
            </div>
          );
        })}
        {renderSendNotification(itemsState)}
      </div>
    );
  };

  return (
    <div>
      <Dialog
        maxWidth='md'
        open={open}
        onClose={handleClose}
        aria-labelledby='confirmation-dialog-title'
        classes={{ paper: classes.paper }}
        fullWidth
      >
        <DialogTitle id='confirmation-dialog-title'>
          <SubDesc content={`Collected Info for Tracking ID ${rowData.id}`} />
        </DialogTitle>
        {isLoading ? (
          <CircularProgress style={{ padding: '1rem 2rem' }} />
        ) : (
          <DialogContent
            dividers
            style={{
              padding: '1rem 2rem',
              paddingBottom: '2rem',
              textAlign: 'center',
            }}
          >
            <FlexRowSpaceBetween
              style={{
                marginBottom: '1rem',
              }}
            >
              <FlexColumn style={{ alignItems: 'flex-start' }}>
                <FtrNormalText fontSize='16'>
                  Client Delivery Country:{' '}
                  {rowDataInfo?.shippingAddress?.country}
                </FtrNormalText>
                <FtrNormalText fontSize='16'>
                  Estimated Delivery Date:{' '}
                  {formatDeliveryDate(rowDataInfo?.estimateDeliveryDate)}
                </FtrNormalText>
              </FlexColumn>
              <BlueButton
                onBtnClick={updateActualCollectionDate}
                size='small'
                btnContent='Update all with today’s date'
              />
            </FlexRowSpaceBetween>
            {renderBody()}
          </DialogContent>
        )}
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            gap: '1rem',
            margin: '1rem',
          }}
        >
          <WhiteButton
            onBtnClick={handleClose}
            btnContent='Cancel'
            size='small'
          />
          <Button
            className={classes.itemCollectedButton}
            variant='contained'
            disabled={isEmpty(filteredItems)}
            onClick={() => {
              handleSubmit({
                itemsData: filteredItems,
                checkoutID: rowData.id,
                dataRefetchFunc,
              });
              handleClose();
            }}
          >
            Item Collected
          </Button>
        </div>
      </Dialog>
    </div>
  );
}

export default CollectedInfoPopup;
