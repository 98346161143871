import React, { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { useQuery, useQueryClient } from 'react-query';

import { makeStyles } from '@material-ui/core/styles';

import { CircularProgress } from '@material-ui/core';

import { Alert } from '@material-ui/lab';

import ProjectStatusFiltersBar from '../../components/filters/ProjectStatusFiltersBar';
import CustomerProjectsDataGrid from '../../components/tables/CustomerProjectsDataGrid';

import { getAllOpenCompletedProjectsByUser } from '../../apis/projectApi';

import { getUserIDSelector } from '../../selectors/userSelector';

import { getItemStageStatusText } from '../../utils/itemUtils';
import { isEmptyValue } from '../../utils/commonUtils';

import { colors } from '../../palette';

// --------------------------------------------------------------------------------

const useStyles = makeStyles(() => ({
  body: {
    height: '100%',
  },
  select: {
    '& .MuiSelect-select:focus': {
      backgroundColor: colors.fontWhite,
    },
  },
  formComponent: {
    height: '75%',
    padding: '1rem 2rem',
  },
}));

/**
 * @deprecated
 * @returns
 */
function ManageProjects() {
  const classes = useStyles();

  const userID = useSelector(getUserIDSelector);

  const queryClient = useQueryClient();

  const [projects, setProjects] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState([]);

  const { status: loadingStatus, data: allOpenProjectsData } = useQuery(
    ['allOpenCompletedProjects', userID],
    () => getAllOpenCompletedProjectsByUser(userID)
  );

  useEffect(() => {
    return () => {
      queryClient.removeQueries('allOpenCompletedProjects');
    };
  }, []);

  useEffect(() => {
    if (loadingStatus === 'success') {
      const filteredProjects = isEmptyValue(selectedFilters)
        ? allOpenProjectsData
        : allOpenProjectsData.filter((project) => {
            const projectItemStatus = project.items.map((item) =>
              getItemStageStatusText(item)
            );
            return selectedFilters.some((status) =>
              projectItemStatus.includes(status)
            );
          });
      setProjects(filteredProjects);
    }
  }, [loadingStatus, selectedFilters]);

  const handleFilterChange = (event) => {
    const { name, checked } = event.target;
    const newFilters = checked
      ? [...selectedFilters, name]
      : selectedFilters.filter((item) => item !== name);
    setSelectedFilters(newFilters);
  };

  const renderLoading = () => {
    return (
      <CircularProgress
        style={{
          width: '1.2rem',
          height: '1.2rem',
        }}
        color='primary'
      />
    );
  };

  const renderTableData = () => {
    if (isEmptyValue(allOpenProjectsData)) {
      return (
        <Alert severity='info'>
          No Projects found.
          <br />
          <br />
          Upload your first Project&nbsp;
          <Link to='/' className={classes.link}>
            here
          </Link>
          ! ‌
        </Alert>
      );
    }
    return <CustomerProjectsDataGrid data={projects} />;
  };

  return (
    <div className={classes.body}>
      <Fragment>
        <div className={classes.formComponent}>
          {loadingStatus === 'loading' && renderLoading()}
          {loadingStatus === 'success' &&
            !isEmptyValue(allOpenProjectsData) && (
              <ProjectStatusFiltersBar onFilterChange={handleFilterChange} />
            )}
          {loadingStatus === 'success' && renderTableData()}
        </div>
      </Fragment>
    </div>
  );
}

export default withRouter(ManageProjects);
