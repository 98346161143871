import React, { useReducer } from 'react';

import AddItemsOrQuotesPopup from '../components/popups/AddItemsOrQuotesPopup';

import { isEmptyValue } from '../utils/commonUtils';

// ---------------------------------------------------------------------------------

function withAddItemsOrQuotesPopup(WrappedComponent) {
  function WrappedComponentWithHOC(props) {
    const defaultLocalState = {
      open: false,
      handleAddItemsOrQuotes: () => {},
    };

    const [localState, updateLocalState] = useReducer((prev, next) => {
      if (isEmptyValue(next)) {
        return defaultLocalState;
      }
      return { ...prev, ...next };
    }, defaultLocalState);

    return (
      <>
        <WrappedComponent
          updateAddItemsOrQuotesPopupHOCState={updateLocalState}
          {...props}
        />
        {localState.open && (
          <AddItemsOrQuotesPopup
            open={localState.open}
            handleClose={() => updateLocalState({ open: false })}
            handleAdd={localState.handleAddItemsOrQuotes}
          />
        )}
      </>
    );
  }

  return WrappedComponentWithHOC;
}

export default withAddItemsOrQuotesPopup;
