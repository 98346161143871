import React from 'react';

import StatusButtonV2Presentational from './StatusButtonV2Presentational';

import { isPpeItem } from '../../utils/itemUtils';
import { isEmptyValue } from '../../utils/commonUtils';
import { isSelectableQuote } from '../../utils/quotationUtils';

import {
  ITEM_STATUS_MAPPING,
  RFQ_ITEM_STATUS,
} from '../../constants/itemStatus';

// ------------------------------------------------------------------------------------------------

function StatusButtonV2(props) {
  const { item, onClickBtn = () => {}, allowUnverifiedQuotes = false } = props;

  let buttonText = '';
  if (item.status === ITEM_STATUS_MAPPING.VERIFYING && !isPpeItem(item)) {
    buttonText = RFQ_ITEM_STATUS[item.status];
  } else if (item.status === ITEM_STATUS_MAPPING.VERIFYING) {
    // PPE item
    buttonText = 'Processing';
  } else if (item.status === ITEM_STATUS_MAPPING.SOURCING) {
    buttonText = RFQ_ITEM_STATUS[item.status];
  } else if (item.status === ITEM_STATUS_MAPPING.QUOTES_AVAILABLE) {
    buttonText = 'View Quotes';
  } else if (
    item.status === ITEM_STATUS_MAPPING.ORDER_IN_PROGRESS &&
    isPpeItem(item) &&
    item.ppeVerified === 0
  ) {
    buttonText = 'Processing';
  } else if (item.status === ITEM_STATUS_MAPPING.ORDER_IN_PROGRESS) {
    buttonText = 'In Progress';
  } else if (item.status === ITEM_STATUS_MAPPING.ORDER_READY) {
    buttonText = item.isShipping ? 'Shipping' : RFQ_ITEM_STATUS[item.status];
  } else if (item.status === ITEM_STATUS_MAPPING.DELIVERED) {
    buttonText = RFQ_ITEM_STATUS[item.status];
  } else {
    buttonText = 'Disabled';
  }

  const selectableItemQuotations = item.quotations?.filter((quote) =>
    isSelectableQuote(quote, allowUnverifiedQuotes)
  );

  const hideBadge =
    item.status !== ITEM_STATUS_MAPPING.QUOTES_AVAILABLE ||
    isEmptyValue(selectableItemQuotations) ||
    selectableItemQuotations.length <= 1;

  return (
    <StatusButtonV2Presentational
      status={buttonText}
      badgeContent={hideBadge ? null : selectableItemQuotations.length}
      onClick={onClickBtn}
    />
  );
}

export default StatusButtonV2;
