import React, { useEffect, useReducer, useState } from 'react';

import { makeStyles } from '@material-ui/core/styles/index';

import { Dialog, DialogContent, DialogTitle, Radio } from '@material-ui/core';

import ChatSvgIcon from '../../../assets/icons/chat.svg';

import FtrTextArea from '../../ftr-components/FtrTextArea';
import {
  FlexColumn,
  FlexColumnCenter,
  FlexRowSpaceBetween,
} from '../../layouts/FlexLayouts';
import {
  FtrBoldText,
  FtrButton,
  FtrH6,
  FtrTypography,
} from '../../ftr-components';

import { colors } from '../../../palette';
import clsx from 'clsx';
import { isEmptyValue } from '../../../utils/commonUtils';

const useStyles = makeStyles(() => ({
  backdrop: {
    backdropFilter: 'blur(3px)',
  },
  dialog: {
    padding: '1.5rem',
    borderRadius: '1.5rem',
  },
  title: {
    display: 'flex',
    fontSize: '22px',
    fontWeight: '700',
    alignItems: 'center',
    justifyContent: 'center',
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  closeIcon: {
    position: 'absolute',
    right: '1.5rem',
    top: '1.5rem',
    color: colors.neutral060,
    width: '1.5rem',
    height: '1.5rem',
    cursor: 'pointer',
  },
  option: {
    padding: '0 0 0 1rem',
    '&:hover': {
      backgroundColor: colors.neutral020,
      borderRadius: 12,
      cursor: 'pointer',
    },
  },
  optionSelected: {
    backgroundColor: colors.blue010,
    borderRadius: 12,
    '&:hover': {
      backgroundColor: colors.blue010,
      borderRadius: 12,
      cursor: 'pointer',
    },
  },
}));

const OPTIONS = [
  'High quote price',
  'High delivery fees',
  'Long lead time',
  'Have design changes/issues',
  'Just trying out the platform',
];

function LeaveQuoteFeedbackPopup(props) {
  const classes = useStyles();

  const {
    open,
    onClose = () => {},
    onCancel = () => {},
    onConfirm = () => {},
  } = props;

  const [localState, updateLocalState] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      feedback: {},
      additionalRemarks: '',
    }
  );

  const [isSubmitDisabled, setSubmitDisabled] = useState(false);

  useEffect(() => {
    const feedback = OPTIONS.reduce((acc, opt) => {
      acc[opt] = false;
      return acc;
    }, {});
    updateLocalState({ feedback });
  }, []);

  useEffect(() => {
    const isSubmitDisabled =
      Object.values(localState.feedback).every((v) => v === false) &&
      isEmptyValue(localState.additionalRemarks);
    setSubmitDisabled(isSubmitDisabled);
  }, [localState.feedback, localState.additionalRemarks]);

  const handleOptionClick = (option) => {
    const currentState = localState.feedback[option];
    const feedback = {
      ...localState.feedback,
      [option]: !currentState,
    };
    updateLocalState({
      feedback,
    });
  };

  const handleConfirm = () => {
    onConfirm(localState);
  };

  const renderAdditionalRemarks = () => {
    return (
      <FlexColumn style={{ gap: '1rem', padding: '0 1rem', marginTop: '1rem' }}>
        <FtrBoldText>Tell us more (optional)</FtrBoldText>
        <FtrTextArea
          value={localState.additionalRemarks}
          onChange={(e) =>
            updateLocalState({ additionalRemarks: e.target.value })
          }
        />
      </FlexColumn>
    );
  };

  const renderDialogActions = () => {
    return (
      <FlexColumnCenter
        style={{
          gap: '1rem',
          marginTop: '2rem',
          maxWidth: 200,
          width: '100%',
          alignSelf: 'center',
        }}
      >
        <FtrButton
          id='proceed-to-create-project-btn'
          color='blue'
          variant='contained'
          onClick={() => {
            handleConfirm();
            onClose();
          }}
          autoFocus
          fullWidth
          disabled={isSubmitDisabled}
        >
          Submit
        </FtrButton>
        <FtrButton variant='text-black' onClick={onCancel}>
          Go back to quote
        </FtrButton>
      </FlexColumnCenter>
    );
  };

  return (
    <Dialog
      open={open}
      maxWidth='xs'
      fullWidth
      scroll='body'
      BackdropProps={{
        classes: {
          root: classes.backdrop,
        },
      }}
      PaperProps={{ className: classes.dialog }}
      onClose={onClose}
    >
      <DialogTitle disableTypography className={classes.title}>
        <img style={{ padding: '1rem 1rem' }} src={ChatSvgIcon} alt='icon' />
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <FtrH6 style={{ marginBottom: '1rem' }}>
          Why are you leaving the quote?
        </FtrH6>
        <FlexColumn
          style={{
            width: '100%',
            gap: '1rem',
            padding: '1rem',
            boxSizing: 'border-box',
          }}
        >
          {OPTIONS.map((option) => {
            return (
              <FlexRowSpaceBetween
                key={option}
                className={clsx(
                  classes.option,
                  localState.feedback[option] ? classes.optionSelected : null
                )}
                onClick={() => handleOptionClick(option)}
              >
                <FtrTypography
                  fontSize='16'
                  style={{
                    fontWeight: 400,
                    color: colors.neutral070,
                  }}
                >
                  {option}
                </FtrTypography>
                <Radio
                  color='primary'
                  checked={localState.feedback[option]}
                  onClick={() => handleOptionClick(option)}
                />
              </FlexRowSpaceBetween>
            );
          })}
          {renderAdditionalRemarks()}
        </FlexColumn>
        {renderDialogActions()}
      </DialogContent>
    </Dialog>
  );
}

export default LeaveQuoteFeedbackPopup;
