import React, { useReducer } from 'react';

import EditProjectOwnerPopupV2 from '../../components/popups/EditProjectOwnerPopupV2';

import {
  deleteAssociatedOwner,
  getAssociatedOwners,
  addAssociatedOwner,
} from '../../apis/userApi';
import { removeProjectOwner, updateProjectOwner } from '../../apis/projectApi';

import { isEmptyValue } from '../../utils/commonUtils';

import { notifyError } from '../../services/notificationService';
import { useQuery } from 'react-query';

// -------------------------------------------------------------------------------------------------

function withEditProjectOwnerPopupHOC(WrappedComponent) {
  function WrappedComponentWithHOC(props) {
    const { setSnackbarMessage = () => {} } = props;
    const defaultLocalState = {
      open: false,
      projectOwner: {},
      associatedOwners: [],
      updateProjectOwnerDisplay: () => {},
    };

    const [localState, updateLocalState] = useReducer((prev, next) => {
      if (isEmptyValue(next)) {
        return defaultLocalState;
      }
      return { ...prev, ...next };
    }, defaultLocalState);

    const {
      data: associatedOwners = [],
      refetch: refetchAssociatedOwners,
    } = useQuery(['getAssociatedOwners', localState.customerID], () =>
      getAssociatedOwners(localState.customerID)
    );

    const ownerList = isEmptyValue(associatedOwners)
      ? associatedOwners
      : [
          ...associatedOwners,
          {
            ownerName: localState.currentProjectDetail.userName,
            ownerEmail: localState.currentProjectDetail.userEmail,
          },
        ];

    const handleAssociatedOwnerUpdate = (ownerName, ownerEmail) => {
      const body = {
        ownerEmail,
        ownerName,
      };

      addAssociatedOwner(localState.customerID, body)
        .then(() =>
          setSnackbarMessage(`A new owner has been added successfully!`)
        )
        .then(localState.updateProjectOwnerDisplay)
        .then(refetchAssociatedOwners)
        .catch((err) => notifyError(err.message));
    };

    const handleChangeOwner = (ownerName, ownerEmail) => {
      let ownerChangePromise;
      if (ownerEmail === localState.currentProjectDetail.userEmail) {
        const body = {
          ownerEmail: localState.projectOwner.ownerEmail,
        };
        ownerChangePromise = () =>
          removeProjectOwner(localState.projectID, body);
      } else {
        const body = {
          ownerEmail,
          ownerName,
        };
        ownerChangePromise = () =>
          updateProjectOwner(localState.projectID, body);
      }
      ownerChangePromise()
        .then(() => setSnackbarMessage(`Owner has been updated successfully!`))
        .then(localState.updateProjectOwnerDisplay)
        .then(refetchAssociatedOwners)
        .catch((err) => notifyError(err.message));
    };

    const handleDeleteAssociateOwner = (ownerEmail) => {
      deleteAssociatedOwner(localState.customerID, ownerEmail)
        .then(() => setSnackbarMessage(`Owner has been deleted successfully!`))
        .then(localState.updateProjectOwnerDisplay)
        .then(refetchAssociatedOwners)
        .catch((err) => notifyError(err.message));
    };

    return (
      <>
        <WrappedComponent
          updateEditProjectOwnerPopupState={updateLocalState}
          {...props}
        />
        {localState.open && (
          <EditProjectOwnerPopupV2
            dialog={localState.open}
            associatedOwners={ownerList}
            currentOwnerEmail={
              localState.projectOwner
                ? localState.projectOwner.ownerEmail
                : null
            }
            handleClose={() => updateLocalState({ open: false })}
            onOwnerChange={handleChangeOwner}
            onAssociatedOwnerUpdate={handleAssociatedOwnerUpdate}
            onDeleteItem={handleDeleteAssociateOwner}
            creatorEmail={localState.creatorEmail}
          />
        )}
      </>
    );
  }

  return WrappedComponentWithHOC;
}

export default withEditProjectOwnerPopupHOC;
