// Import settings
import React from 'react';

// Import color palette
import { colors } from '../palette';

// Import material UI components
import { makeStyles } from '@material-ui/core/styles/index';

// Style components
const useStyles = makeStyles(() => ({
  pageTitle: {
    width: '100%',
    fontWeight: 600,
    fontSize: '14pt',
    lineHeight: '18px',
    textAlign: 'center',
    color: colors.fontGrey,
    paddingBottom: 5,
  },
}));

function Desc(props) {
  const classes = useStyles();
  const { contentTitle } = props;
  return <div className={classes.pageTitle}>{contentTitle}</div>;
}

export default Desc;
