import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles/index';

import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import { Box, Tooltip, Typography } from '@material-ui/core';
import WarningIcon from '../../icons/WarningIcon';

import { ITEM_STAGE_TYPE } from '../../../constants';
import { colors } from '../../../palette';
import ShipmentTrackingPopup from '../../popups/ShipmentTrackingPopup';
import { FlexColumn, FlexRow } from '../../layouts/FlexLayouts';
import { FtrCaption, FtrTypography } from '../../ftr-components';
import { isSameDate } from '../../../utils/dateTimeUtils';

const useStyles = makeStyles(() => ({
  delivered: {
    color: 'green',
  },
  shipmentTrackingBtn: {
    all: 'unset',
    color: colors.blue070,
    fontSize: '0.8rem',
    margin: '0 auto',
  },
}));

function DeliveryDateCell(props) {
  const classes = useStyles();

  const { rowData, forAdmin } = props;

  const [showShipmentTrackingPopup, setShowShipmentTrackingPopup] = useState(
    false
  );

  let estimated = true;
  let reworked = false;
  let revised = false;
  let deliveryDate;
  if (rowData.requiredRework === 1 && rowData.reworkDeliveryDate !== null) {
    //to account for legacy data
    deliveryDate = rowData.reworkDeliveryDate;
    reworked = true;
  } else if (
    rowData.revisedDeliveryDate &&
    rowData.status !== ITEM_STAGE_TYPE.DELIVERED
  ) {
    deliveryDate = rowData.revisedDeliveryDate;
    revised = true;
  } else if (
    rowData.actualDeliveryDate &&
    rowData.status === ITEM_STAGE_TYPE.DELIVERED
  ) {
    deliveryDate = rowData.actualDeliveryDate;
    estimated = false;
  } else if (rowData.deliveryDate) {
    deliveryDate = rowData.deliveryDate;
  }
  if (!deliveryDate) {
    return '';
  }
  const dateFormat = { year: 'numeric', month: 'short', day: 'numeric' };
  const deliveryDateStr = new Date(deliveryDate).toLocaleDateString(
    'en-GB',
    dateFormat
  );

  const renderIcon = () => {
    if (rowData.status === ITEM_STAGE_TYPE.DELIVERED) {
      return <AssignmentTurnedInIcon className={classes.delivered} />;
    }
    return <LocalShippingIcon color='primary' />;
  };

  const handleShipmentTracking = (e) => {
    e.stopPropagation();
    setShowShipmentTrackingPopup(true);
  };

  const renderDeliveryDate = () => {
    if (forAdmin) {
      const originalDate = rowData.actualDeliveryDate || rowData.deliveryDate;
      const isDateNotOriginal =
        !isSameDate(deliveryDate, originalDate) && originalDate;
      return (
        <FlexColumn style={{ width: 'auto' }}>
          <FlexRow>
            {isDateNotOriginal && reworked && (
              <WarningIcon toolTipText='Reworked Delivery Date' />
            )}
            <Tooltip
              title={
                reworked
                  ? 'Reworked Delivery Date'
                  : estimated
                  ? 'Estimated Delivery Date'
                  : 'Actual Delivery Date'
              }
              arrow
            >
              <div>
                <FtrTypography fontSize={14}>{deliveryDateStr}</FtrTypography>
              </div>
            </Tooltip>
            {renderIcon()}
          </FlexRow>
          {isDateNotOriginal && (
            <FtrCaption fontSize={10} style={{ paddingLeft: '2rem' }}>
              {`Orig: ${new Date(originalDate).toLocaleDateString(
                'en-GB',
                dateFormat
              )}`}
            </FtrCaption>
          )}
        </FlexColumn>
      );
    }

    return (
      <Tooltip
        title={
          reworked
            ? 'Reworked Delivery Date'
            : estimated
            ? 'Estimated Delivery Date'
            : 'Actual Delivery Date'
        }
        arrow
      >
        <div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              wordBreak: 'break-word',
              whiteSpace: 'normal',
              lineHeight: 'normal',
            }}
          >
            <Typography variant='body2'>{deliveryDateStr}</Typography>
            <Box style={{ width: '0.5rem' }}></Box>
            {renderIcon()}
          </div>
          {revised && <Typography variant='body2'>(Revised)</Typography>}
        </div>
      </Tooltip>
    );
  };

  return (
    <>
      {renderDeliveryDate()}
      {![null, undefined, 'N.A.', ''].includes(rowData?.shipmentProvider) && (
        <Tooltip title='View Shipment Information' arrow>
          <button
            className={classes.shipmentTrackingBtn}
            onClick={handleShipmentTracking}
          >
            <Typography variant='body2'>Shipment Tracking</Typography>
          </button>
        </Tooltip>
      )}
      {showShipmentTrackingPopup && (
        <ShipmentTrackingPopup
          open={showShipmentTrackingPopup}
          rowData={rowData}
          handleCancel={(e) => {
            e.stopPropagation();
            setShowShipmentTrackingPopup(false);
          }}
        />
      )}
    </>
  );
}

export default DeliveryDateCell;
