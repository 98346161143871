import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  Container,
  Divider,
  Grid,
  InputAdornment,
  TextField,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { useQuery } from 'react-query';

import BlueButton from '../../components/buttons/BlueButton';
import { FlexColumn } from '../../components/layouts/FlexLayouts';

import {
  getConfigByKey,
  updateConfiguration,
} from '../../apis/configurationApi';

import { notifySuccess } from '../../services/notificationService';

import { colors } from '../../palette';

import { snakeCaseToTitleCase } from '../../utils/stringUtils';

import { KEY_CONFIGURATION } from '../../constants/configurations';

const useStyles = makeStyles((theme) => ({
  body: {
    paddingTop: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    gap: '25px',
  },
  container: {
    margin: '25px, 0px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    gap: '25px',
    padding: '25px',
    border: `3px solid ${colors.bgLightGrey}`,
  },
  input: {
    width: '250px !important',
  },
}));

function DeliveryFee() {
  const classes = useStyles();
  const [deliveryFee, setDeliveryFee] = useState(0);
  const [
    deliveryServiceFeeMultiplier,
    setDeliveryServiceFeeMultiplier,
  ] = useState({});

  useQuery('getDeliveryFee', () => {
    return getConfigByKey(KEY_CONFIGURATION.DEFAULT_NON_SGP_DELIVERY_FEE).then(
      (data) => {
        setDeliveryFee(data.value);
      }
    );
  });
  useQuery('getDeliveryServiceFeeMultiplier', () => {
    return getConfigByKey(
      KEY_CONFIGURATION.DELIVERY_SERVICE_FEE_MULTIPLIER
    ).then((data) => {
      setDeliveryServiceFeeMultiplier(data.value);
    });
  });

  const handleSubmitButtonClick = () => {
    updateConfiguration({
      key: KEY_CONFIGURATION.DEFAULT_NON_SGP_DELIVERY_FEE,
      value: Number(deliveryFee),
    }).then(() => {
      notifySuccess('Successfully updated international delivery fee');
    });
    updateConfiguration({
      key: KEY_CONFIGURATION.DELIVERY_SERVICE_FEE_MULTIPLIER,
      value: deliveryServiceFeeMultiplier,
    }).then(() => {
      notifySuccess('Successfully updated delivery service fee multiplier');
    });
  };

  return (
    <div className={classes.body}>
      <Container className={classes.container}>
        <div>
          <Typography variant='h6'>Default Delivery Fee</Typography>
          <Divider />
        </div>
        <Grid container>
          <Grid item xs={6}>
            <TextField
              label='International Shipping'
              variant='outlined'
              type='number'
              onChange={(evt) => setDeliveryFee(evt.target.valueAsNumber)}
              value={deliveryFee}
              className={classes.input}
              margin='dense'
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>S$</InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <FlexColumn>
              {Object.keys(deliveryServiceFeeMultiplier).map((key, index) => {
                return (
                  <TextField
                    key={index}
                    label={snakeCaseToTitleCase(key)}
                    variant='outlined'
                    type='number'
                    onChange={(evt) =>
                      setDeliveryServiceFeeMultiplier({
                        ...deliveryServiceFeeMultiplier,
                        [key]: evt.target.valueAsNumber,
                      })
                    }
                    value={deliveryServiceFeeMultiplier[key]}
                    className={classes.input}
                    margin='dense'
                  />
                );
              })}
            </FlexColumn>
          </Grid>
        </Grid>
      </Container>
      <div className={classes.saveButtonWrapper}>
        <BlueButton
          btnContent={'Save Changes'}
          onBtnClick={() => handleSubmitButtonClick()}
        />
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    role: state.auth.user.role,
  };
}

const withConnect = connect(mapStateToProps);

export default withRouter(withConnect(DeliveryFee));
