import React from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles/index';

const useStyles = makeStyles((theme) => ({
  '@global': {
    '#root': {
      height: 'auto',
    },
  },
  body: {
    padding: theme.spacing(7),
  },
  date: {
    fontSize: 14,
    fontWeight: 300,
    marginBottom: 20,
  },
  title: {
    fontSize: 16,
    textTransform: 'uppercase',
    fontWeight: 700,
    marginBottom: 15,
    marginTop: 30,
  },
  desc: {
    fontSize: 15,
    fontWeight: 300,
  },
  para: {
    marginBottom: 12,
  },
}));

export function Privacy() {
  const classes = useStyles();

  return (
    <div className={classes.body}>
      <div>
        <div className={classes.title}>Privacy Policy</div>
        <div className={classes.date}>Last updated July 25, 2023</div>
        <div className={classes.title}>AGREEMENT TO TERMS</div>
        <div className={classes.desc}>
          <div className={classes.para}>
            Thank you for choosing to be part of our community at Factorem
            (“Company”, “we”, “us”, or “our”). We are committed to protecting
            your personal information and your right to privacy on
            https://factorem.co website as well as any other media form, media
            channel, mobile website or mobile application related, linked, or
            otherwise connected thereto (collectively, the “Site”). If you have
            any questions or concerns about our policy, or our practices with
            regards to your personal information, please contact us at
            support@factorem.co.
          </div>
          <div className={classes.para}>
            By using our website and services, you trust us with your personal
            information, and we are fully committed to safeguarding your
            privacy. In this privacy policy, we seek to explain to you in the
            clearest way possible what information we collect, how we use it and
            what rights you have in relation to it. We hope you take some time
            to read through it carefully, as it is important. If there are any
            terms in this privacy policy that you do not agree with, please
            discontinue use of our Sites and our services. You are encouraged to
            periodically review this Privacy Policy to stay informed of updates.
            You will be deemed to have been made aware of, will be subject to,
            and will be deemed to have accepted the changes in any revised
            Privacy Policy by your continued use of the Site after the date such
            revised Privacy Policy is posted.
          </div>
          <div className={classes.para}>
            {`This privacy policy applies to all information collected through our website (such as https://factorem.co), and/or any related services, sales, marketing or events (we refer to them collectively in this privacy policy as the "Services").`}
          </div>
          <div className={classes.para}>
            <b>
              Please read this privacy policy carefully as it will help you make
              informed decisions about sharing your personal information with
              us.
            </b>
          </div>
        </div>
        <div className={classes.title}>1. WHAT INFORMATION DO WE COLLECT?</div>
        <div className={classes.desc}>
          <div className={classes.para}>
            <b>Personal information you disclose to us</b>
          </div>
          <div className={classes.para}>
            <b>In Short: </b>
            <i> We collect personal information that you provide to us.</i>
          </div>
          <div className={classes.para}>
            We collect personal information that you voluntarily provide to us
            when registering at the Services expressing an interest in obtaining
            information about us or our products and services, when
            participating in activities on the Services (such as posting
            messages in our online forums or entering competitions, contests or
            giveaways) or otherwise contacting us. You are under no obligation
            to provide us with personal information of any kind, however, your
            refusal to do so may prevent you from using certain features of the
            Site.
          </div>
          <div className={classes.para}>
            The personal information that we collect depends on the context of
            your interactions with us and the Services, the choices you make and
            the products and features you use. The personal information we
            collect can include the following:
          </div>
          <div className={classes.para}>
            <b>Publicly Available Personal Information. </b>We collect first
            name, maiden name, last name, and nickname; phone numbers; email
            addresses; business email; business phone number; current and former
            address; and other similar data.
          </div>
          <div className={classes.para}>
            <b>Personal Information Provided by You.</b> We collect passwords;
            financial information (credit card number, purchase history,
            invoices); purchase history; email addresses shared with us and
            other similar data. Such data collected is when you complete a user
            profile or registration forms.
          </div>
          <div className={classes.para}>
            All personal information that you provide to us must be true,
            complete and accurate, and you must notify us of any changes to such
            personal information.
          </div>
        </div>
        <div className={classes.title}>2. HOW DO WE USE YOUR INFORMATION?</div>
        <div className={classes.desc}>
          <div className={classes.para}>
            <b>In Short: </b>
            <i>
              We process your information for purposes based on legitimate
              business interests, the fulfilment of our contract with you,
              compliance with our legal obligations, and/or your consent.
            </i>
          </div>
          <div className={classes.para}>
            We use personal information collected via our Services for a variety
            of business purposes described below. We process your personal
            information for these purposes in reliance on our legitimate
            business interests, in order to enter into or perform a contract
            with you, with your consent, and/or for compliance with our legal
            obligations. We indicate the specific processing grounds we rely on
            next to each purpose listed below.
          </div>
          <div className={classes.para}>
            We use the information we collect or receive:
          </div>
        </div>
        <div className={classes.desc}>
          <div>
            <ul>
              <li>
                <b>To facilitate account creation and logon process.</b> If you
                choose to link your account with us to a third-party account
                (such as your Google or Facebook account), we use the
                information you allowed us to collect from those third parties
                to facilitate account creation and logon process for the
                performance of the contract.
              </li>
              <li>
                <b>To send administrative information to you.</b> We may use
                your personal information to send you product, service and/or
                information about changes to our terms, conditions, and
                policies.
              </li>
              <li>
                <b>Fulfil and manage your orders. </b> We may use your
                information to fulfil and manage your orders, payments, returns,
                and exchanges made through the Services.
              </li>
              <li>
                <b>Request Feedback.</b> We may use your information to request
                feedback and to contact you about your use of our Services.
              </li>
              <li>
                <b>To protect our Services.</b> We may use your information as
                part of our efforts to keep our Services safe and secure (for
                example, for fraud monitoring and prevention).
              </li>
              <li>
                <b>To enable user-to-user communications.</b>{' '}
                {`We may use your information in order to enable user-to-user communications with each user's consent.`}
              </li>
              <li>
                <b>
                  To enforce our terms, conditions and policies for Business
                  Purposes, Legal Reasons and Contractual.
                </b>
              </li>
              <li>
                <b>To respond to legal requests and prevent harm.</b> If we
                receive a subpoena or other legal request, we may need to
                inspect the data we hold to determine how to respond.
              </li>
              <li>
                <b>To manage user accounts.</b> We may use your information for
                the purposes of managing our account and keeping it in working
                order.
              </li>
              <li>
                <b>To deliver services to the user.</b> We may use your
                information to provide you with the requested service.
              </li>
              <li>
                <b>To respond to user inquiries/offer support to users.</b> We
                may use your information to respond to your inquiries and solve
                any potential issues you might have with the use of our
                Services.
              </li>
              <li>
                <b>For other Business Purposes.</b> We may use your information
                for other Business Purposes, such as data analysis, identifying
                usage trends, determining the effectiveness of our promotional
                campaigns and to evaluate and improve our Services, products,
                marketing and your experience. We may compile anonymous
                statistical data and analysis for use internally or with third
                parties. We may use and store this information in aggregated and
                anonymized form so that it is not associated with individual end
                users and does not include personal information. We will not use
                identifiable personal information without your consent.
              </li>
              <li>
                <b>For marketing and promotions.</b> We may send you special
                offers, newsletters or new feature information that may be of
                interest or relevance to you.
              </li>
            </ul>
          </div>
        </div>
        <div className={classes.title}>
          3. WILL YOUR INFORMATION BE SHARED WITH ANYONE?
        </div>
        <div className={classes.desc}>
          <div className={classes.para}>
            <b>In Short: </b>
            <i>
              We only share information with your consent, to comply with laws,
              to provide you with services, to protect your rights, or to fulfil
              business obligations.
            </i>
          </div>
          <div className={classes.para}>
            We may process or share data based on the following legal basis:
          </div>
          <div className={classes.para}>
            <ul>
              <li>
                <b>Consent: </b>We may process your data if you have given us
                specific consent to use your personal information in a specific
                purpose.
              </li>
              <li>
                <b>Legitimate Interests: </b>We may process your data when it is
                reasonably necessary to achieve our legitimate business
                interests.
              </li>
              <li>
                <b>Performance of a Contract: </b>Where we have entered into a
                contract with you, we may process your personal information to
                fulfil the terms of our contract.
              </li>
              <li>
                <b>Legal Obligations: </b>We may disclose your information where
                we are legally required to do so in order to comply with
                applicable law, governmental requests, a judicial proceeding,
                court order, or legal process, such as in response to a court
                order or a subpoena (including in response to public authorities
                to meet national security or law enforcement requirements).
              </li>
              <li>
                <b>Vital Interests: </b>We may disclose your information where
                we believe it is necessary to investigate, prevent, or take
                action regarding potential violations of our policies, suspected
                fraud, situations involving potential threats to the safety of
                any person and illegal activities, or as evidence in litigation
                in which we are involved.
              </li>
            </ul>
          </div>
          <div className={classes.para}>
            More specifically, we may need to process your data or share your
            personal information in the following situations:
          </div>
          <div className={classes.para}>
            <ul>
              <li>
                <b>
                  Vendors, Consultants and Other Third-Party Service Providers.
                </b>
                We may share your data with third party vendors, service
                providers, contractors or agents who perform services for us or
                on our behalf and require access to such information to do that
                work. Examples include payment processing, data analysis, email
                delivery, hosting services, customer service and marketing
                efforts. We may allow selected third parties to use tracking
                technology on the Services, which will enable them to collect
                data about how you interact with the Services over time. This
                information may be used to, among other things, analyse and
                track data, determine the popularity of certain content and
                better understand online activity. Unless described in this
                Policy, we do not share, sell, rent or trade any of your
                information with third parties for their promotional purposes.
              </li>
              <li>
                <b>Business Transfers.</b>
                We may share or transfer your information in connection with, or
                during negotiations of, any merger, sale of company assets,
                financing, or acquisition of all or a portion of our business to
                another company.
              </li>
              <li>
                <b>Third-Party Advertisers.</b>
                We may use third-party advertising companies to serve ads when
                you visit the Services. These companies may use information
                about your visits to our Website(s) and other websites that are
                contained in web cookies and other tracking technologies in
                order to provide advertisements about goods and services of
                interest to you.
              </li>
              <li>
                <b>Business Partners.</b>
                We may share your information with our business partners to
                offer you certain products, services or promotions.
              </li>
            </ul>
          </div>
        </div>
        <div className={classes.title}>
          4. HOW LONG DO WE KEEP YOUR INFORMATION?
        </div>
        <div className={classes.desc}>
          <div className={classes.para}>
            <b>In Short: </b>
            <i>
              We keep your information for as long as necessary to fulfil the
              purposes outlined in this privacy policy unless otherwise required
              by law.
            </i>
          </div>
          <div className={classes.para}>
            {`We will only keep your personal information for as long as it is necessary for the purposes set out in this privacy policy, unless a longer retention period is required or permitted by law (such as tax, accounting or other legal requirements). No purpose in this policy will require us keeping your personal information for longer than 1 year past the start of idle period of the user's account.`}
          </div>
          <div className={classes.para}>
            When we have no ongoing legitimate business need to process your
            personal information, we will either delete or anonymize it, or, if
            this is not possible (for example, because your personal information
            has been stored in backup archives), then we will securely store
            your personal information and isolate it from any further processing
            until deletion is possible.
          </div>
        </div>
        <div className={classes.title}>
          5. DO WE COLLECT INFORMATION FROM MINORS?
        </div>
        <div className={classes.desc}>
          <div className={classes.para}>
            <b>In Short: </b>
            <i>We do not knowingly collect data from or market to minors.</i>
          </div>
          <div className={classes.para}>
            We do not knowingly solicit data from or market to minors. By using
            the Services, you represent that you are not a minor or that you are
            the parent or guardian of such a minor and consent to such minor
            dependent’s use of the Services. If we learn that personal
            information from a minor has been collected, we will deactivate the
            account and take reasonable measures to promptly delete such data
            from our records. If you become aware of any data, we have collected
            from minors, please contact us at support@factorem.co.
          </div>
        </div>
        <div className={classes.title}>6. WHAT ARE YOUR PRIVACY RIGHTS?</div>
        <div className={classes.desc}>
          <div className={classes.para}>
            <b>In Short: </b>
            <i>
              You may review, change, or terminate your account at any time.
            </i>
          </div>
          <div className={classes.para}>
            If you have questions or comments about your privacy rights, you may
            email us at support@factorem.co.
          </div>
        </div>
        <div className={classes.title}>7. Account Information</div>
        <div className={classes.desc}>
          <div className={classes.para}>
            If you would at any time like to review or change the information in
            your account or terminate your account, you can:
          </div>
          <div className={classes.para}>
            <ul>
              <li>Contact us using the contact information provided.</li>
              <li>
                Log into your account settings and update your user account.
              </li>
            </ul>
          </div>
          <div className={classes.para}>
            In accordance with applicable laws and regulations, you may be
            entitled to:
          </div>
          <div className={classes.para}>
            <ul>
              <li>
                inquire about the processing of your Personal Data and be
                provided with a copy of it;
              </li>
              <li>
                request the correction and/or deletion of your Personal Data;
              </li>
              <li>
                request the restriction of or objection to the processing of
                your Personal Data;
              </li>
              <li>
                complain to the relevant data privacy authority if your data
                privacy rights are violated, or if you have suffered detriment
                as a result of unlawful processing of your Personal Data.
              </li>
            </ul>
          </div>
          <div className={classes.para}>
            Where you are given the option to share your Personal Data with us,
            you can always choose not to do so. If we have requested your
            consent to processing and you later choose to withdraw it, we will
            respect that choice in accordance with our legal obligations.
          </div>
          <div className={classes.para}>
            However, choosing not to share your Personal Data with us,
            withdrawing your consent to our use of it or to your agreement with
            our Privacy Policy could mean that you may be unable to make use of
            the Site.
          </div>
          <div className={classes.para}>
            Upon your request to terminate your account, we will deactivate or
            delete your account and information from our active databases.
            However, some information may be retained in our files to prevent
            fraud, troubleshoot problems, assist with any investigations,
            enforce our Terms of Use and/or comply with legal requirements.
          </div>
          <div className={classes.para}>
            We will screen and verify all requests beforehand. In order to
            verify your identity and/or authority to make the request, we may
            require you to provide supporting information or documentation to
            corroborate the request. Once verified, we will give effect to your
            request within the timelines prescribed by applicable laws.
          </div>
          <div className={classes.para}>
            <b>Opting out of email marketing:</b> You can unsubscribe from our
            marketing email list at any time by clicking on the unsubscribe link
            in the emails that we send or by contacting us using the details
            provided below. You will then be removed from the marketing email
            list – however, we will still need to send you service-related
            emails that are necessary for the administration and use of your
            account. To otherwise opt-out, you may contact us at
            support@factorem.co.
          </div>
        </div>
        <div className={classes.title}>
          8. CONTROLS FOR DO-NOT-TRACK FEATURES
        </div>
        <div className={classes.desc}>
          <div className={classes.para}>
            Most web browsers and some mobile operating systems and mobile
            applications include a Do-Not-Track (“DNT”) feature or setting you
            can activate to signal your privacy preference not to have data
            about your online browsing activities monitored and collected. No
            uniform technology standard for recognizing and implementing DNT
            signals has been finalized. As such, we do not currently respond to
            DNT browser signals or any other mechanism that automatically
            communicates your choice not to be tracked online. If a standard for
            online tracking is adopted that we must follow in the future, we
            will inform you about that practice in a revised version of this
            privacy policy.
          </div>
        </div>
        <div className={classes.title}>
          9. EUROPEAN RESIDENTS: YOUR PRIVACY RIGHTS AND INTERNATIONAL DATA
          TRANSFER
        </div>
        <div className={classes.desc}>
          <div className={classes.para}>
            For European Residents, please note that the personal data we obtain
            from you may be transferred, processed, and stored outside of the
            European Economic Area (EEA) or Switzerland for the purposes listed
            in this Privacy Policy. We take the privacy of our users seriously
            and therefore have taken steps to safeguard your information,
            including ensuring an adequate level of data protection in
            accordance with E.U. standards in effect as of the date of this
            Privacy Policy.
          </div>
        </div>
        <div className={classes.title}>
          10. DO WE MAKE UPDATES TO THIS POLICY?
        </div>
        <div className={classes.desc}>
          <div className={classes.para}>
            <b>In Short: </b>
            <i>
              Yes, we will update this policy as necessary to stay compliant
              with relevant laws.
            </i>
          </div>
        </div>
        <div className={classes.desc}>
          We may update this privacy policy from time to time. The updated
          version will be indicated by an updated “Revised” date and the updated
          version will be effective as soon as it is accessible. If we make
          material changes to this privacy policy, we may notify you either by
          prominently posting a notice of such changes or by directly sending
          you a notification. We encourage you to review this privacy policy
          frequently to be informed of how we are protecting your information.
        </div>
        <div className={classes.title}>
          11. HOW CAN YOU CONTACT US ABOUT THIS POLICY?
        </div>
        <div className={classes.desc}>
          <div className={classes.para}>
            If you have questions or comments about this policy, you may email
            us at support@factorem.co or by post to:
          </div>
          <div className={classes.para}>
            <div>Factorem</div>
            <div>81 Ayer Rajah Crescent</div>
            <div>#01-54</div>
            <div>Singapore 139967</div>
            <div>Singapore</div>
          </div>
        </div>
        <div className={classes.title}>
          12. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM
          YOU?
        </div>
        <div className={classes.desc}>
          Based on the laws of some countries, you may have the right to request
          access to the personal information we collect from you, change that
          information, or delete it in some circumstances. To request to review,
          update, or delete your personal information, please write in to
          support@factorem.co. We will respond to your request within 30 days.
        </div>
      </div>
    </div>
  );
}

function mapStateToProps() {
  return {};
}

function matchDispatchToProps() {
  return {};
}

const withConnect = connect(mapStateToProps, matchDispatchToProps);

export default withConnect(Privacy);
