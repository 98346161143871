import React, { useReducer } from 'react';

import RepeatSimilarOrderDetailsPopup from '../components/popups/RepeatSimilarOrderDetailsPopup';

import { isEmptyValue } from '../utils/commonUtils';

// -------------------------------------------------------------------------------------------------

function withRepeatSimilarOrderDetailsPopupHOC(WrappedComponent) {
  function WrappedComponentWithHOC(props) {
    const defaultLocalState = {
      open: false,
    };

    const [localState, updateLocalState] = useReducer((prev, next) => {
      if (isEmptyValue(next)) {
        return defaultLocalState;
      }
      return { ...prev, ...next };
    }, defaultLocalState);

    return (
      <>
        <WrappedComponent
          {...props}
          updateRepeatSimilarOrderDetailsPopupHOCState={updateLocalState}
        />
        {localState.open && (
          <RepeatSimilarOrderDetailsPopup
            open={localState.open}
            onClose={() => updateLocalState({ open: false })}
            itemID={localState.itemID}
            requestItem={localState.requestItem}
            matchedItemID={localState.matchedItemID}
            refQuoteID={localState.refQuoteID}
            type={localState.type}
          />
        )}
      </>
    );
  }

  // Set the displayName for the HOC
  const wrappedComponentName =
    WrappedComponent.displayName || WrappedComponent.name || 'Component';
  WrappedComponentWithHOC.displayName = `withSignUpPopup(${wrappedComponentName})`;

  return WrappedComponentWithHOC;
}

export default withRepeatSimilarOrderDetailsPopupHOC;
