import React from 'react';
import { useMutation, useQueryClient } from 'react-query';

import {
  saveAutoBalloonItem,
  verifyAutoBalloonItem,
} from '../apis/autoBalloonApi';
import {
  addManualBalloonsApi,
  autoRenderBalloonApi,
  deleteBalloonsApi,
  downloadBalloonsReportCsvApi,
} from '../apis/tdeSymbolDetectionApi';

import { asyncMap } from '../utils/arrayUtils';
import { isEmptyValue } from '../utils/commonUtils';
import {
  downloadS3File,
  getFileNameWithoutExtensionFromUrl,
} from '../utils/fileUtils';
import {
  convertImagesToPDF,
  exportImagesToPDF,
} from '../utils/imagesToPdfUtils';

import { notifyError, notifySuccess } from '../services/notificationService';
import {
  generatePresignedUrl,
  uploadBlobFileToS3,
} from '../services/s3Service';

import { AUTO_BALLOONING_S3_FOLDER } from '../constants/s3Constants';

// -------------------------------------------------------------------------------------------------

const useItemAutoBalloon = () => {
  const queryClient = useQueryClient();

  const [itemID, setItemID] = React.useState(null);
  const [pdfUrl, setPdfUrl] = React.useState('');

  const [autoBallooning, setAutoBallooning] = React.useState(false);
  const [manualBallooning, setManualBallooning] = React.useState(false);
  const [deletingBalloon, setDeletingBalloon] = React.useState(false);
  const [downloadingPdf, setDownloadingPdf] = React.useState(false);
  const [downloadingReport, setDownloadingReport] = React.useState(false);
  const [verifying, setVerifying] = React.useState(false);
  const [saving, setSaving] = React.useState(false);

  const [ballooningInfo, setBallooningInfo] = React.useState(null);
  const [originalImageUrls, setOriginalImageUrls] = React.useState([]);
  const [annotatedImageUrls, setAnnotatedImageUrls] = React.useState([]);
  const [annotationData, setAnnotationData] = React.useState([]);
  const [reportCsvUrl, setReportCsvUrl] = React.useState('');

  const { mutate: verifyAutoBalloonItemMutate } = useMutation(
    (payloads) => verifyAutoBalloonItem(payloads),
    {
      onSuccess: () => {
        notifySuccess('Auto balloon item verified successfully');
        queryClient.invalidateQueries('getAutoBalloonItems');
        queryClient.invalidateQueries('getPendingAutoBalloonItems');
        setVerifying(false);
      },
      onError: () => {
        notifyError('Auto balloon item verified failed');
        setVerifying(false);
      },
    }
  );

  const { mutate: saveAutoBalloonItemMutate } = useMutation(
    (payloads) => saveAutoBalloonItem(payloads),
    {
      onSuccess: () => {
        notifySuccess('Auto balloon item saved successfully');
        queryClient.invalidateQueries('getAutoBalloonItems');
        setSaving(false);
      },
      onError: () => {
        notifyError('Auto balloon item verified failed');
        setSaving(false);
      },
    }
  );
  const { mutate: addManualBalloonsMutate } = useMutation(
    (payloads) => addManualBalloonsApi(payloads),
    {
      onSuccess: (response) => {
        notifySuccess('Manual balloons added successfully');
        setManualBallooning(false);
        setBallooningInfo(response);
        setOriginalImageUrls(response?.original_image_urls);
        setAnnotatedImageUrls(response?.annotated_image_urls);
        setAnnotationData(response?.annotation_data || []);
        setReportCsvUrl(null);
      },
      onError: () => {
        notifyError('Failed to add manual balloons');
        setManualBallooning(false);
      },
    }
  );

  const { mutate: deleteBalloonsMutate } = useMutation(
    (payloads) => deleteBalloonsApi(payloads),
    {
      onSuccess: (response) => {
        notifySuccess('Deleted balloon successfully');
        setDeletingBalloon(false);
        setBallooningInfo(response);
        setOriginalImageUrls(response?.original_image_urls);
        setAnnotatedImageUrls(response?.annotated_image_urls);
        const _annotationData = response?.annotation_data || [];
        setAnnotationData(
          _annotationData.filter((data) => !isEmptyValue(data.number))
        );
        setReportCsvUrl(response?.report_url);
      },
      onError: () => {
        setDeletingBalloon(false);
        notifyError('Failed to delete balloon');
      },
    }
  );

  const resetState = () => {
    setOriginalImageUrls([]);
    setAnnotatedImageUrls([]);
    setAnnotationData([]);
    setReportCsvUrl('');
  };

  async function generateAutoBallonForItem({ pdfUrl, itemID }) {
    resetState();
    setAutoBallooning(true);

    if (isEmptyValue(pdfUrl)) {
      return;
    }

    setPdfUrl(pdfUrl);
    setItemID(itemID);

    return autoRenderBalloonApi({ pdfUrl, itemID })
      .then((response) => {
        setBallooningInfo(response);
        setOriginalImageUrls(response?.original_image_urls);
        setAnnotatedImageUrls(response?.annotated_image_urls);
        const _annotationData = response?.annotation_data || [];
        setAnnotationData(
          _annotationData.filter((data) => !isEmptyValue(data.number))
        );
        setAutoBallooning(false);
      })
      .catch(() => {
        setAutoBallooning(false);
        throw new Error('Failed to auto balloons for item');
      });
  }

  async function addManualBalloons(newBalloon) {
    if (isEmptyValue(newBalloon)) {
      return;
    }

    setManualBallooning(true);
    const body = {
      original_image_urls: originalImageUrls,
      annotation_data: [...annotationData, ...newBalloon],
      total_balloon: annotationData.length + 1,
    };

    return addManualBalloonsMutate(body);
  }

  async function deleteBalloon(balloonNumber) {
    if (isEmptyValue(balloonNumber)) {
      return;
    }

    setDeletingBalloon(true);
    const body = {
      original_image_urls: originalImageUrls,
      annotation_data: annotationData,
      balloon_removal_number: [balloonNumber],
    };

    return deleteBalloonsMutate(body);
  }

  async function getReportCsvUrl() {
    if (!isEmptyValue(reportCsvUrl)) {
      return reportCsvUrl;
    }

    const pdfFileName = getFileNameWithoutExtensionFromUrl(pdfUrl);

    const body = {
      annotation_data: annotationData,
      filename: `${pdfFileName}`,
    };

    return downloadBalloonsReportCsvApi(body)
      .then((response) => {
        setReportCsvUrl(response?.report_url);
        return response?.report_url;
      })
      .catch(() => {
        throw new Error('Failed to get report csv url');
      });
  }

  async function downloadBalloonsReportCsv() {
    const reportCsvUrl = await getReportCsvUrl().catch(() => null);
    if (isEmptyValue(reportCsvUrl)) {
      return;
    }

    downloadS3File(reportCsvUrl);
  }

  async function downloadBalloonedPdf(uploadToS3 = false) {
    setDownloadingPdf(true);
    const imageUrls = await asyncMap(annotatedImageUrls, async (imgUrl) =>
      generatePresignedUrl(imgUrl)
    );

    const pdfFileName = getFileNameWithoutExtensionFromUrl(pdfUrl);

    if (!uploadToS3) {
      await exportImagesToPDF(imageUrls, `${pdfFileName}_Ballooned`);
    } else {
      const pdfBlob = await convertImagesToPDF(imageUrls);
      await uploadBlobFileToS3(pdfBlob, {
        fileName: `${pdfFileName}_Ballooned.pdf`,
      });
      pdfBlob.save(`${pdfFileName}_Ballooned.pdf`);
    }

    setDownloadingPdf(false);
  }

  async function getBalloonedPdfUrl() {
    const imageUrls = await asyncMap(annotatedImageUrls, async (imgUrl) =>
      generatePresignedUrl(imgUrl)
    );

    const pdfFileName = getFileNameWithoutExtensionFromUrl(pdfUrl);
    const fileName = `${pdfFileName}_Ballooned.pdf`;
    const s3Key = `${AUTO_BALLOONING_S3_FOLDER}/${fileName}`;

    const pdf = await convertImagesToPDF(imageUrls);
    const type = 'application/pdf';
    const pdfBlob = new Blob([pdf.output('blob')], { type });
    const balloonedPdfUrl = await uploadBlobFileToS3(pdfBlob, s3Key, {
      fileName,
    });

    return balloonedPdfUrl?.Location;
  }

  async function verifyItemBalloon() {
    if (isEmptyValue(itemID) || isEmptyValue(pdfUrl)) {
      return;
    }

    setVerifying(true);
    const [balloonedCsvUrl, balloonedPdfUrl] = await Promise.all([
      getReportCsvUrl(),
      getBalloonedPdfUrl(),
    ]);

    const body = {
      itemID,
      pdfUrl,
      ballooningInfo,
      balloonedPdfUrl,
      balloonedCsvUrl,
    };
    return verifyAutoBalloonItemMutate(body);
  }

  async function saveItemBalloon() {
    if (isEmptyValue(itemID) || isEmptyValue(pdfUrl)) {
      return;
    }

    setSaving(true);
    const [balloonedCsvUrl, balloonedPdfUrl] = await Promise.all([
      getReportCsvUrl(),
      getBalloonedPdfUrl(),
    ]);

    const body = {
      itemID,
      pdfUrl,
      ballooningInfo,
      balloonedPdfUrl,
      balloonedCsvUrl,
    };
    return saveAutoBalloonItemMutate(body);
  }

  return {
    autoBallooning,
    manualBallooning,
    deletingBalloon,
    downloadingPdf,
    verifying,
    saving,
    downloadingReport,
    originalImageUrls,
    annotatedImageUrls,
    annotationData,
    reportCsvUrl,
    resetState,
    setDownloadingPdf,
    setDownloadingReport,
    setAnnotationData,
    generateAutoBallonForItem,
    addManualBalloons,
    deleteBalloon,
    downloadBalloonsReportCsv,
    downloadBalloonedPdf,
    verifyItemBalloon,
    saveItemBalloon,
  };
};

export default useItemAutoBalloon;
