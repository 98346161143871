import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import { Box } from '@material-ui/core';

import CustomerProjectsMuiTable from './CustomerProjectsMuiTable';
import { FlexColumn } from '../../components/layouts/FlexLayouts';
import { FtrH3, FtrS4 } from '../../components/ftr-components';

import { useMuiTableFilterData } from '../../hooks/useMuiTableFilterDataHook';

import { isEmptyValue } from '../../utils/commonUtils';

import useOrderSelectionForOrderAgainZustand from '../../hooks/useOrderSelectionForOrderAgainZustand';

import {
  getOrdersTabColumns,
  getQuotationTabColumns,
} from '../../constants/customerProjectsMuiTableConstants';

import { colors } from '../../palette';

// -----------------------------------------------------------------------------------------------

function CustomerProjectsSearchResult(props) {
  const history = useHistory();

  const {
    searchStr,
    statusFilterList,
    createdDateFilter,
    quotationProjects = [],
    orderInProgressProjects = [],
    orderCompletedProjects = [],
  } = props;

  const { addRow, removeRow } = useOrderSelectionForOrderAgainZustand(
    (state) => state
  );

  const quotationColumns = useMemo(() => getQuotationTabColumns());
  const ordersColumns = useMemo(
    () =>
      getOrdersTabColumns({
        addRow,
        removeRow,
      }),
    [addRow, removeRow]
  );

  const { filteredData: quotations } = useMuiTableFilterData({
    data: quotationProjects,
    searchStr,
    columns: quotationColumns,
  });

  const { filteredData: ordersInProgress } = useMuiTableFilterData({
    data: orderInProgressProjects,
    searchStr,
    columns: ordersColumns,
  });

  const { filteredData: ordersCompleted } = useMuiTableFilterData({
    data: orderCompletedProjects,
    searchStr,
    columns: ordersColumns,
  });

  const hasResult = useMemo(() => {
    return (
      quotations.length + ordersInProgress.length + ordersCompleted.length > 0
    );
  }, [quotations.length, ordersInProgress.length, ordersCompleted.length]);

  const handleRowClick = (rowData) => {
    history.push({
      pathname: `/manage-projects/${rowData.projectID}`,
      state: { projectID: rowData.projectID },
    });
  };

  const handleOpenProjectOrders = (rowData) => {
    history.push({
      pathname: `/customer-orders/projects/${rowData.projectID}`,
      state: { projectID: rowData.projectID },
    });
  };

  const renderNoMatchFound = () => {
    return (
      <FlexColumn style={{ gap: '1rem' }}>
        <CustomerProjectsMuiTable
          searchStr={searchStr}
          statusFilterList={statusFilterList}
          createdDateFilter={createdDateFilter}
          projects={quotations}
          onRowClick={handleRowClick}
          columns={quotationColumns}
        />
      </FlexColumn>
    );
  };

  const renderQuotationResult = () => {
    if (isEmptyValue(quotations)) {
      return;
    }

    return (
      <FlexColumn style={{ gap: '1rem' }}>
        <FtrH3 style={{ marginLeft: '1rem' }}>
          Quotation ({quotations.length})
        </FtrH3>
        <CustomerProjectsMuiTable
          statusFilterList={statusFilterList}
          createdDateFilter={createdDateFilter}
          projects={quotations}
          onRowClick={handleRowClick}
          columns={quotationColumns}
        />
        {isEmptyValue(quotations) && (
          <FtrS4
            style={{
              color: colors.neutral060,
              textAlign: 'center',
              marginTop: '2rem',
            }}
          >
            No matches found
          </FtrS4>
        )}
      </FlexColumn>
    );
  };

  const renderOrdersInProgressResult = () => {
    if (isEmptyValue(ordersInProgress)) {
      return;
    }

    return (
      <FlexColumn style={{ gap: '1rem' }}>
        <FtrH3 style={{ marginLeft: '1rem' }}>
          In-Progress ({ordersInProgress.length})
        </FtrH3>
        <CustomerProjectsMuiTable
          statusFilterList={statusFilterList}
          createdDateFilter={createdDateFilter}
          createdDateField='createdDate'
          projects={ordersInProgress}
          onRowClick={handleOpenProjectOrders}
          columns={ordersColumns}
          rowKey='id'
        />
      </FlexColumn>
    );
  };

  const renderOrdersCompleteResult = () => {
    if (isEmptyValue(ordersCompleted)) {
      return;
    }

    return (
      <FlexColumn style={{ gap: '1rem' }}>
        <FtrH3 style={{ marginLeft: '1rem' }}>
          Completed ({ordersCompleted.length})
        </FtrH3>
        <CustomerProjectsMuiTable
          statusFilterList={statusFilterList}
          createdDateFilter={createdDateFilter}
          createdDateField='createdDate'
          projects={ordersCompleted}
          onRowClick={handleOpenProjectOrders}
          columns={ordersColumns}
          rowKey='id'
        />
      </FlexColumn>
    );
  };

  const renderAllResults = () => {
    if (!hasResult) {
      return renderNoMatchFound();
    }

    return (
      <>
        {renderQuotationResult()}
        <Box style={{ height: '1rem', minHeight: '1rem', width: '100%' }} />
        {renderOrdersInProgressResult()}
        <Box style={{ height: '1rem', minHeight: '1rem', width: '100%' }} />
        {renderOrdersCompleteResult()}
      </>
    );
  };

  return (
    <FlexColumn style={{ gap: '1rem', width: '100%' }}>
      {renderAllResults()}
    </FlexColumn>
  );
}

export default CustomerProjectsSearchResult;
