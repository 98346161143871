import {
  BUYER_UPLOAD_PARTS_TOUR,
  BUYER_UPLOAD_PARTS_SUMMARY_TOUR,
  CLEAR_TOUR,
} from '../components/tour/guidedTours/tourConstants';

const defaultState = {
  currentTour: null,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case BUYER_UPLOAD_PARTS_TOUR:
      return {
        ...state,
        currentTour: action.tour,
      };
    case BUYER_UPLOAD_PARTS_SUMMARY_TOUR:
      return {
        ...state,
        currentTour: action.tour,
      };
    case CLEAR_TOUR:
      return {
        ...state,
        currentTour: null,
      };
    default:
      return state;
  }
};
