import React, { useEffect, useReducer, useState } from 'react';
import { chunk } from 'lodash';
import { nanoid } from 'nanoid';

import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  FormControl,
  FormGroup,
  FormLabel,
} from '@material-ui/core';

import { ExpandMore } from '@material-ui/icons';

import FtrButton from '../../ftr-components/FtrButton';
import MaterialWithMarkupField from './MaterialWithMarkupField';
import SearchBar from '../../grid-data/buttons/SearchBar';
import {
  FlexColumn,
  FlexRow,
  FlexRowSpaceBetween,
} from '../../layouts/FlexLayouts';
import { FtrH5 } from '../../ftr-components/FtrTypography';

import { updatePpeControlConfigsV2 } from '../../../apis/ppeSettingsApi';

import { isEmptyValue } from '../../../utils/commonUtils';

import {
  notifyError,
  notifySuccess,
} from '../../../services/notificationService';

function PpeControlMaterialsForm(props) {
  const { title, materials: materialsParam } = props;

  const [localState, updateLocalState] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      formID: nanoid(),
      materials: materialsParam,
    }
  );

  //  divided into 5 rows
  const displayMaterials = chunk([...localState.materials], 5);

  const [beforeEditMaterials, setBeforeEditMaterials] = useState(
    JSON.parse(JSON.stringify(materialsParam)) || []
  );
  const [modifiedMaterials, setModifiedMaterials] = useState(new Set());

  useEffect(() => {
    updateLocalState({ materials: materialsParam });
    setBeforeEditMaterials(materialsParam);
  }, [materialsParam]);

  const handleSearch = (keyword) => {
    if (isEmptyValue(keyword)) {
      updateLocalState({ materials: materialsParam });
      return;
    }
    const _materials = localState.materials.filter((m) =>
      m.name.toLowerCase().includes(keyword.toLowerCase())
    );
    updateLocalState({ materials: _materials });
  };

  const handleMaterialConfigUpdate = (materialID, config) => {
    const _materials = localState.materials.map((m) => {
      if (m.id !== materialID) {
        return {
          ...m,
        };
      }
      return {
        ...m,
        ...config,
      };
    });
    updateLocalState({ materials: _materials });
    modifiedMaterials.add(materialID);
    setModifiedMaterials(new Set(modifiedMaterials));
  };

  const handleReset = () => {
    updateLocalState({
      formID: nanoid(),
      materials: beforeEditMaterials,
    });
    setModifiedMaterials(new Set());
  };

  const handleSave = () => {
    const modifiedMaterialsList = Array.from(modifiedMaterials);
    const configs = localState.materials.filter((m) =>
      modifiedMaterialsList.includes(m.id)
    );

    if (isEmptyValue(configs)) {
      return;
    }

    const body = {
      configs,
    };
    updatePpeControlConfigsV2(body)
      .then(() => {
        notifySuccess('Config has been updated successfully.');
        setBeforeEditMaterials(localState.materials);
        setModifiedMaterials(new Set());
      })
      .catch(() => {
        notifyError('Updated failed');
      });
  };

  return (
    <Accordion
      defaultExpanded
      style={{
        overflowY: 'auto',
        overflowX: 'hidden',
        maxWidth: '100%',
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMore />}
        aria-controls='panel1c-content'
      >
        <FlexRowSpaceBetween style={{ width: '100%' }}>
          <FormLabel component='legend'>
            <FtrH5>{title}</FtrH5>
          </FormLabel>
          <SearchBar onSearch={handleSearch} autoFocus={false} />
        </FlexRowSpaceBetween>
      </AccordionSummary>
      <AccordionDetails
        style={{
          paddingBottom: 8,
        }}
      >
        <FormControl component='fieldset'>
          <FormGroup>
            <FlexRow
              style={{
                overflowY: 'auto',
                overflowX: 'auto',
                maxWidth: '100%',
                alignItems: 'start',
                gap: '2rem',
                paddingLeft: '1rem',
                paddingRight: '1rem',
                boxSizing: 'border-box',
              }}
            >
              {displayMaterials.map((chunkArr, index) => {
                return (
                  <FlexColumn
                    key={`${localState.formID}-${index}`}
                    style={{
                      justifyContent: 'start',
                      marginBottom: '1rem',
                    }}
                  >
                    {chunkArr.map((material) => {
                      const key = `${localState.formID}-${material.id}`;
                      return (
                        <MaterialWithMarkupField
                          key={key}
                          material={material}
                          onSelectionFormChange={handleMaterialConfigUpdate}
                        />
                      );
                    })}
                  </FlexColumn>
                );
              })}
            </FlexRow>
          </FormGroup>
        </FormControl>
      </AccordionDetails>
      <AccordionActions
        style={{
          paddingRight: '1rem',
          paddingBottom: '1rem',
        }}
      >
        {modifiedMaterials.size > 0 && (
          <FtrButton color='red' onClick={handleReset}>
            Reset
          </FtrButton>
        )}
        <FtrButton
          color='blue'
          disabled={modifiedMaterials.size <= 0}
          onClick={handleSave}
        >
          Save
        </FtrButton>
      </AccordionActions>
    </Accordion>
  );
}

export default PpeControlMaterialsForm;
