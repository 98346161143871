import React, { useReducer } from 'react';

import RocketQuotePopup from '../components/popups/RocketQuotePopup';

import { transformRocketQuoteItem } from '../apis/itemApi';
import { updateRequestQuotes } from '../apis/quotationApi';

import { notifyError, notifySuccess } from '../services/notificationService';

import { isEmptyValue } from '../utils/commonUtils';

// ---------------------------------------------------------------------------------

function withRocketQuotePopupHOC(WrappedComponent) {
  function RocketQuotePopupHOC(props) {
    const defaultLocalState = {
      open: false,
      refetchData: () => {},
    };

    const [localState, updateLocalState] = useReducer((prev, next) => {
      if (isEmptyValue(next)) {
        return defaultLocalState;
      }
      return { ...prev, ...next };
    }, defaultLocalState);

    const handleRocketQuoteButtonClick = (data) => {
      const quotationID = localState.item?.quotationID;
      const copyQuotation = localState.copyQuotation;
      const createNewQuotation = isEmptyValue(quotationID) || copyQuotation;

      let successMessage = createNewQuotation
        ? `The rocket quote for item #${localState.item?.itemID} has been created!`
        : 'The rocket quote requested by customer has been submitted successfully!';

      if (createNewQuotation) {
        return transformRocketQuoteItem(data)
          .then(() => {
            updateLocalState({ open: false });
            notifySuccess(successMessage);
          })
          .then(localState.refetchData)
          .catch((err) => {
            notifyError(err.message);
            throw err;
          });
      } else {
        return updateRequestQuotes(quotationID, data)
          .then(() => {
            updateLocalState({ open: false });
            notifySuccess(successMessage);
          })
          .then(localState.refetchData)
          .catch((err) => {
            notifyError(err.message);
            throw err;
          });
      }
    };

    return (
      <>
        <WrappedComponent
          updateRocketQuotePopupState={(params) => {
            updateLocalState({
              ...defaultLocalState,
              ...params,
              open: true,
            });
          }}
          {...props}
        />
        {localState.open && (
          <RocketQuotePopup
            dialog={localState.open}
            handleClose={() => updateLocalState({ open: false })}
            handleRocketQuoteButtonClick={handleRocketQuoteButtonClick}
            {...localState}
          />
        )}
      </>
    );
  }

  const wrappedComponentName =
    WrappedComponent.displayName || WrappedComponent.name || 'Component';
  RocketQuotePopupHOC.displayName = `withRocketQuotePopup(${wrappedComponentName})`;
  return RocketQuotePopupHOC;
}

export default withRocketQuotePopupHOC;
