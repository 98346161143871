import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';

export const renderSkeletonOverlay = () => (
  <Skeleton
    variant='rect'
    width='100%'
    height='100%'
    style={{
      position: 'absolute',
      top: 0,
      left: 0,
      zIndex: 1,
      borderRadius: 10,
    }}
  />
);
