import { useQuery } from 'react-query';

import { getUserInfo } from '../apis/userApi';

import { isEmptyValue } from '../utils/commonUtils';
import { ONE_HOUR_IN_MS } from '../utils/dateTimeUtils.js';

// -------------------------------------------------------------------------------------------------

const useUserInfo = (customerID) => {
  const { data, isLoading, isFetching } = useQuery(
    ['getUserInfo', customerID],
    () => {
      if (isEmptyValue(customerID)) {
        return null;
      }

      return getUserInfo(customerID);
    },
    {
      staleTime: ONE_HOUR_IN_MS,
      cacheTime: ONE_HOUR_IN_MS,
    }
  );

  return {
    data,
    isLoading,
    isFetching,
  };
};

export default useUserInfo;
