import {
  RESET_COST_ESTIMATION,
  UPDATE_COST_ESTIMATION,
} from '../actions/estimateCostActions';

const defaultState = {};

export default (state = defaultState, action) => {
  switch (action.type) {
    case RESET_COST_ESTIMATION:
      return defaultState;
    case UPDATE_COST_ESTIMATION: {
      const { payload } = action;
      return {
        ...state,
        ...payload,
      };
    }
    default:
      return state;
  }
};
