// Import settings
import React, { useEffect, useState } from 'react';

// Import customised components
import SubDesc from '../SubDesc';
import Title from '../Title';
import PartDetails from '../PartDetails';
import ItemTracker from '../ItemTracker';
import PPEItemTracker from '../PPEItemTracker';
import DoneIcon from '@material-ui/icons/Done';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';

import { RFQ_ITEM_STATUS } from '../../constants/itemStatus';

import { Chip } from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles/index';

// Import colors
import { colors } from '../../palette';
import { getItemStageStatusText, isPpeItem } from '../../utils/itemUtils';

// Style the components
const useStyles = makeStyles((theme) => ({
  paper: {
    minWidth: 800,
    borderRadius: 0,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  pendingChip: {
    background: colors.pendingLightYellow,
  },
  successChip: {
    background: colors.successLightGreen,
  },
}));

function PartDetailsDropdown(props) {
  const classes = useStyles();
  const { itemID, data, getItems } = props;

  // Declare component states
  const [item, setItem] = useState(null);

  // Run when popup first loads
  useEffect(() => {
    setItem(data.find((item) => item.itemID === itemID));
  }, [itemID, data]);

  return (
    <div>
      <div style={{ padding: '1rem 0 1rem' }}>
        <SubDesc content={item ? `Item #${item.itemID}` : ''} />
        <Title
          contentTitle={
            item
              ? !isPpeItem(item)
                ? [
                    RFQ_ITEM_STATUS[item.status],
                    <span
                      key={'paid'}
                      style={{ display: 'inherit', marginTop: 15 }}
                    >
                      {/* hardcoded key so compiler wouldn't complain */}
                      {item.paymentStatus === 'paid' ? (
                        <Chip
                          className={classes.successChip}
                          label='Payment Received'
                          icon={<DoneIcon />}
                        />
                      ) : (
                        <React.Fragment></React.Fragment>
                      )}
                    </span>,
                    <span
                      key={'pending'}
                      style={{ display: 'inherit', marginTop: 15 }}
                    >
                      {item.paymentStatus === 'pending' ? (
                        <Chip
                          className={classes.pendingChip}
                          label='Payment Pending'
                          icon={<HourglassEmptyIcon />}
                        />
                      ) : (
                        <React.Fragment></React.Fragment>
                      )}
                    </span>,
                  ]
                : [
                    getItemStageStatusText(item),
                    <span
                      key={'paid'}
                      style={{ display: 'inherit', marginTop: 15 }}
                    >
                      {/* hardcoded key so compiler wouldn't complain */}
                      {item.paymentStatus === 'paid' ? (
                        <Chip
                          className={classes.successChip}
                          label='Payment Received'
                          icon={<DoneIcon />}
                        />
                      ) : (
                        <React.Fragment></React.Fragment>
                      )}
                    </span>,
                    <span
                      key={'pending'}
                      style={{ display: 'inherit', marginTop: 15 }}
                    >
                      {item.paymentStatus === 'pending' ? (
                        <Chip
                          className={classes.pendingChip}
                          label='Payment Pending'
                          icon={<HourglassEmptyIcon />}
                        />
                      ) : (
                        <React.Fragment></React.Fragment>
                      )}
                    </span>,
                  ]
              : ''
          }
        />
        <div>
          {item ? (
            !isPpeItem(item) ? (
              <ItemTracker status={item.status} />
            ) : (
              <PPEItemTracker
                status={item.status}
                ppeVerified={item.ppeVerified}
              />
            )
          ) : (
            ''
          )}
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            padding: '0 55px',
          }}
        >
          {item ? <PartDetails partInfo={item} getItems={getItems} /> : ''}
        </div>
      </div>
    </div>
  );
}

export default PartDetailsDropdown;
