import React, { useEffect, useRef } from 'react';
import { isEmpty } from 'lodash';

import { TextField } from '@material-ui/core';

import { OTHER_SURFACE_FINISH_STR } from '../../../constants/quotationConstants';

function OtherSurfaceFinishingField(props) {
  const customSurfaceFinishRef = useRef();

  const { value, error, onChange, onFocus } = props;

  useEffect(() => {
    if (!isEmpty(error)) {
      customSurfaceFinishRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
      return;
    }
  }, [error]);

  return (
    <TextField
      id='custom-surface-finish'
      ref={customSurfaceFinishRef}
      variant='outlined'
      required
      fullWidth
      margin='dense'
      label='Custom Surface Finishing'
      name={OTHER_SURFACE_FINISH_STR}
      value={value}
      error={error}
      helperText={error}
      onChange={onChange}
      onFocus={onFocus}
    />
  );
}

export default OtherSurfaceFinishingField;
