import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import './instrument';
import App from './App';
import ReactGA from 'react-ga';
import configureStore from './configureStore';
import { CookiesProvider } from 'react-cookie';
import { PersistGate } from 'redux-persist/integration/react';
import { toast } from 'react-toastify';
import { DeviceProvider } from './context/DeviceProvider';
import 'react-toastify/dist/ReactToastify.css';
const store = configureStore();
toast.configure();

function Root() {
  if (document.location.hostname.search('app.factorem.co') !== -1) {
    ReactGA.initialize(process.env.REACT_APP_ANALYTICS_ID);
    ReactGA.pageview('/');
  }

  return (
    <CookiesProvider>
      <Provider store={store.store}>
        <PersistGate loading={null} persistor={store.persistor}>
          <Router>
            <DeviceProvider>
              <App />
            </DeviceProvider>
          </Router>
        </PersistGate>
      </Provider>
    </CookiesProvider>
  );
}
export default Root;
