import React, { useReducer } from 'react';

import { makeStyles } from '@material-ui/core/styles/index';

import {
  Button,
  CircularProgress,
  Container,
  TextField,
} from '@material-ui/core';

import { notifyError } from '../../services/notificationService';
import { individualPOFromItemID } from '../../apis/pdfApi';
import { Link } from 'react-router-dom';
import { downloadS3File } from '../../utils/fileUtils';

const useStyles = makeStyles((theme) => ({
  circularProgress: {
    marginLeft: 0,
    marginRight: theme.spacing.unit,
  },
}));

export default function IndividualPOGenerator() {
  const classes = useStyles();

  const [localState, updateLocalState] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      itemID: '',
      downloadUrl: '',
      processing: false,
    }
  );

  const handleGenerateIndividualPO = () => {
    updateLocalState({ downloadUrl: '', processing: true });
    individualPOFromItemID(localState.itemID)
      .then(({ pdfUrl }) => updateLocalState({ downloadUrl: pdfUrl }))
      .catch(() => notifyError('Unable to generate Individual PO'))
      .finally(() => updateLocalState({ processing: false }));
  };

  return (
    <Container>
      <div
        style={{
          paddingTop: '2rem',
        }}
      >
        <TextField
          label='Part ID'
          value={localState.itemID}
          onChange={(evt) => {
            updateLocalState({ itemID: evt.target.value });
          }}
          variant='outlined'
          margin='dense'
          style={{ marginRight: '1.25rem' }}
          type='number'
          placeholder='eg: 1234'
          required
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
        />

        <div style={{ marginTop: '1rem' }}>
          <Button
            variant='contained'
            color='primary'
            fullWidth
            onClick={handleGenerateIndividualPO}
            disabled={!localState.itemID || localState.processing}
          >
            {localState.processing && (
              <CircularProgress
                className={classes.circularProgress}
                size={20}
              />
            )}
            Generate Individual PO
          </Button>
        </div>
        <div
          style={{
            display: 'flex',
            marginTop: '1rem',
            flexDirection: 'column',
          }}
        >
          <span style={{ minWidth: 'max-content' }}>Download Link:&nbsp;</span>
          {localState.processing && (
            <CircularProgress className={classes.circularProgress} size={20} />
          )}
          <Link
            onClick={(e) => {
              e.stopPropagation();
              downloadS3File(localState.downloadUrl);
            }}
          >
            {localState.downloadUrl}
          </Link>
        </div>
      </div>
    </Container>
  );
}
