import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import LongReceiptIcon from '../../assets/icons/long_receipt.svg';

import { makeStyles } from '@material-ui/core/styles/index';

import {
  Block,
  Delete as DeleteIcon,
  Done,
  Edit,
  FileCopy as FileCopyIcon,
  GetApp,
} from '@material-ui/icons';

import {
  Box,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Tooltip,
  Typography,
} from '@material-ui/core';

import Row from '../Row';
import { FlexColumn, FlexRowEnd } from '../layouts/FlexLayouts';

import withActionTrackingHOC from '../../hocs/withActionTrackingHOC';

import {
  downloadS3File,
  extractFileNameWithoutTimestampFromUrl,
} from '../../utils/fileUtils';

import { notifySuccess } from '../../services/notificationService';

import { colors } from '../../palette';
import { ACTION_TRACKING_TYPE } from '../../constants/actionTrackingConstants';

// -------------------------------------------------------------------------------------------------

// Style components
const useStyles = makeStyles(() => ({
  itemText: {
    '& span, & svg': {
      fontSize: 12,
    },
  },
  iconButton: {
    '&:hover': {
      backgroundColor: colors.lightGray,
    },
  },
  editIcon: {
    fontSize: 12,
    '&:hover': {
      backgroundColor: colors.lightGray,
      color: 'white',
      cursor: 'pointer',
      borderRadius: '50%',
    },
    padding: 2,
  },
}));

const DownloadIconButton = withActionTrackingHOC(IconButton);

function DownloadableFileWithActions(props) {
  const classes = useStyles();

  const VIEW = 'view';
  const EDIT = 'edit';

  const {
    fileUrl,
    fileName: fileNameParam,
    showDeleteButton = false,
    showCopyUrlButton = false,
    postDownloadFunction,
    onDelete,
    fullWidth = false,
    minWidth = 260,
    iconImage = null,
    downloadCount = 0,
    editable = false,
    onSave = () => {},
    noteMessage = '',
    borderColor, // blue, green
  } = props;

  const [mode, setMode] = useState(VIEW);

  const [fileName, setFileName] = useState(
    fileNameParam || extractFileNameWithoutTimestampFromUrl(fileUrl)
  );
  const [editFileName, setEditFileName] = useState(fileName);

  const handleDownloadFile = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (mode === EDIT) {
      return;
    }
    downloadS3File(fileUrl, { fileNameParam: fileName }).then(() => {
      if (typeof postDownloadFunction === 'function') {
        postDownloadFunction();
      }
    });
  };

  const handleSave = () => {
    if (editFileName === fileName) {
      return;
    }
    onSave(editFileName);
    setFileName(editFileName);
  };

  const renderDeleteCopyButton = () => {
    return (
      <FlexRowEnd>
        <Tooltip title='Download'>
          <DownloadIconButton
            actionTrackingKey={ACTION_TRACKING_TYPE.DOWNLOAD_FILE}
            additionalInfo={{
              urls: [fileUrl],
            }}
            className={classes.iconButton}
            style={{ padding: 2, color: colors.blue050 }}
            onClick={handleDownloadFile}
          >
            <GetApp style={{ fontSize: '1.2rem' }} />
          </DownloadIconButton>
        </Tooltip>
        {showCopyUrlButton === true && (
          <Tooltip title='Copy URL'>
            <IconButton
              className={classes.iconButton}
              style={{ padding: 2 }}
              onClick={() => {
                navigator.clipboard.writeText(fileUrl);
                notifySuccess('URL copied');
              }}
            >
              <FileCopyIcon style={{ fontSize: '1.2rem' }} />
            </IconButton>
          </Tooltip>
        )}
        {showDeleteButton === true && (
          <Tooltip title='Delete file'>
            <IconButton
              className={classes.iconButton}
              style={{ padding: 2 }}
              aria-label='Delete PO'
              onClick={onDelete}
            >
              <DeleteIcon style={{ color: 'red', fontSize: '1.2rem' }} />
            </IconButton>
          </Tooltip>
        )}
      </FlexRowEnd>
    );
  };

  const renderEditButton = () => {
    return (
      <Tooltip title='Edit Name'>
        <IconButton
          className={classes.iconButton}
          style={{ padding: 2 }}
          aria-label='Delete PO'
          onClick={() => setMode(EDIT)}
        >
          <Edit style={{ color: colors.blue050, fontSize: '1rem' }} />
        </IconButton>
      </Tooltip>
    );
  };

  return (
    <FlexColumn style={{ width: 'auto', alignItems: 'flex-start' }}>
      <ListItem
        style={{
          backgroundColor:
            borderColor === 'blue'
              ? colors.blue020
              : downloadCount > 0
              ? colors.bgLightGrey
              : colors.uploadPOBg,
          minWidth,
          width: fullWidth ? '100%' : 'fit-content',
          maxWidth: 520,
          margin: 5,
          paddingLeft: 12,
          paddingRight: 8,
          border: `solid 1px ${
            borderColor === 'blue' ? colors.solidBlue : colors.uploadPOBorder
          }`,
          borderRadius: 5,
          height: '3.875rem',
          color: 'inherit',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Tooltip title='Click to download file'>
          <Link
            style={{
              display: 'flex',
              alignItems: 'center',
              textDecoration: 'none',
            }}
            onClick={handleDownloadFile}
          >
            <ListItemAvatar style={{ display: 'flex', minWidth: '2.8rem' }}>
              <img
                src={iconImage || LongReceiptIcon}
                alt='icon'
                style={{ height: '1.875rem' }}
              />
            </ListItemAvatar>
            {mode === VIEW && (
              <ListItemText
                style={{
                  maxWidth: 390,
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  fontSize: 12,
                }}
                primary={fileName}
                className={classes.itemText}
              />
            )}
            {mode === EDIT && (
              <Row>
                <input
                  value={editFileName}
                  onChange={(e) => setEditFileName(e.target.value)}
                  style={{ width: '7rem' }}
                />
              </Row>
            )}
            {mode === EDIT && (
              <Row>
                <Box style={{ width: '0.5rem' }} />
                <Tooltip title='Cancel'>
                  <IconButton
                    className={classes.iconButton}
                    style={{ padding: 2 }}
                    aria-label='Delete PO'
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      setMode(VIEW);
                    }}
                  >
                    <Block style={{ color: 'gray', fontSize: '1rem' }} />
                  </IconButton>
                </Tooltip>
                <Tooltip title='Save'>
                  <IconButton
                    className={classes.iconButton}
                    style={{ padding: 2 }}
                    aria-label='Delete PO'
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      setMode(VIEW);
                      handleSave();
                    }}
                  >
                    <Done style={{ color: colors.blue050, fontSize: '1rem' }} />
                  </IconButton>
                </Tooltip>
              </Row>
            )}
          </Link>
        </Tooltip>
        <FlexRowEnd style={{ marginLeft: '1rem' }}>
          {editable === true && mode === VIEW && renderEditButton()}
          {mode === VIEW && renderDeleteCopyButton()}
        </FlexRowEnd>
      </ListItem>
      {noteMessage && (
        <Typography
          style={{
            fontStyle: 'italic',
            marginLeft: '0.5rem',
          }}
          variant='caption'
        >
          {noteMessage}
        </Typography>
      )}
    </FlexColumn>
  );
}

export default DownloadableFileWithActions;
