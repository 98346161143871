import queryString from 'query-string';
import { getAuthorizedHeader } from '../utils/apiUtils';

import { BACKEND_SERVICE_URL } from '../constants';

export const getS3PartnershipAgreementInfo = async (params) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/onboarding/partnership-agreement?${queryString.stringify(
    params
  )}`;
  return fetch(requestUrl, {
    method: 'GET',
    headers: getAuthorizedHeader(),
  }).then(async (response) => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    const { data } = await response.json();
    return data;
  });
};
