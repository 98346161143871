export const FACTOREM_BLUE = '#1272E3';

export const colors = {
  buttonWhiteText: '#FAFAFA',
  // to remove
  titleBlue: '#0C5DBC',
  bgOrderVerified: '#0c5dbc',
  sideBarMenuHighlight: FACTOREM_BLUE,

  // Figma color palette
  neutral010: '#FCFCFC',
  neutral020: '#F7F7F7',
  neutral030: '#E6E6E6',
  neutral040: '#CCCCCC',
  neutral050: '#A9A9A9',
  neutral060: '#808080',
  neutral070: '#4D4D4D',
  neutral080: '#333333',
  neutral090: '#050505',
  blue010: '#EDF5FE',
  blue020: '#CFE4FC',
  blue030: '#A3C9F5',
  blue040: '#5293E0',
  blue050: '#1272E3', // FACTOREM_BLUE
  blue060: '#0C5DBC',
  blue070: '#094790',
  blue080: '#13316C',
  blue090: '#041B49',
  blue100: '#011132',
  red010: '#F8ECEC',
  red020: '#DC7676',
  red030: '#C64444',
  red040: '#DA2F2F',
  red050: '#8B1818',
  mintGreen: '#D9FCC7',
  green010: '#D9FCC7',
  green020: '#AFFF85',
  green030: '#3DB200',
  green040: '#419F0F',
  green050: '#009045',
  badgeGreen: '#4B9923',
  blackTint020: '#00000033',
  blackTint060: '#00000099',

  // existing
  background: 'linear-gradient(90deg, #E6F9FF, #5496EB)',
  mobileBackground: 'linear-gradient(#E6F9FF, #5496EB)',
  sidebarBackground: 'linear-gradient(#0C5FBC, #094790, #094790)',
  errorRed: '#DC143C',
  buttonRed: '#DC143C',
  buttonBlue: '#288BFD',
  inputBorderBlue: '#62B4FF',
  inputLabelGrey: '#C4C4C4',
  inputBorderGrey: '#C4C4C4',
  fontWhite: '#FFFFFF',
  fontBlack: '#000000',
  fontGrey: '#515151',
  headerGrey: '#565656',
  fontLightGrey: '#9D9D9D',
  statusLightBlue: '#95CCFF',
  boxShadowBlue: '#58cdff',
  buttonColorBlueGradient:
    'linear-gradient(231.54deg, #62B4FF 19.53%, #60A0EA 84.32%)',
  dragdropBg: 'rgba(98, 180, 255, 0.06)',
  uploadFileBg: '#F6FAFF',
  uploadFileBorder: '#60A0EA',
  uploadPOBg: '#E3FFDA',
  uploadPOBorder: '#4DA159',
  bgOrderDelivered: '#4caf50',
  bgOrderInTransit: '#914170',
  bgORderReadyToShip: '#E39008',
  bgOrderNeedUpdate: '#ff0000',
  bgUnverifiedQuote: '#FFC107',
  boxShadowFAQClicked:
    '0px 0px 15px rgba(89, 205, 255, 0.5), 4px 10px 20px rgba(0, 0, 0, 0.1)',
  colorDarkBlueGradient:
    'linear-gradient(90deg, rgba(96,160,234,1) 0%, rgba(96,160,234,1) 100%)',
  darkBlueBorder: '1px solid rgba(96, 160, 234, 0.5)',
  expansionPanelBorder: '1px solid rgba(0, 0, 0, .125)',
  expansionBackgroundColor: 'rgba(0, 0, 0, .03)',
  factoremBlue: '#043578',
  blueSidebarBg: `linear-gradient(227.25deg, #60A0EA -15.96%, #0C5DBC 89.21%), #0C5DBC`,
  mainLightBlue: '#fdfdff',
  successLightGreen: '#dbffd2',
  pendingLightYellow: '#fffdca',
  paleBlue: '#ECF5FF',
  secondaryBlue: '#cbe2ff',
  tertiaryBlue: '#005DBC',
  skyBlue: '#03a9f4',
  bgLightGrey: '#F5F5F5',
  solidBlue: '#0059C1',
  fontDarkGrey: '#222326',
  successGreen: '#39C16C',
  fontBlueBlack: '#263238',
  darkGreyBorder: '1px solid #D8D8D8',
  quotedLabelBlue: '#9ACCF3',
  hotRed: '#CD0000',
  repeatItemColor: '#dc3545',
  darkRed: '#D64242',
  lightRed: '#FFB0B0',
  secondaryRed: '#FB0000',
  redGradient: 'linear-gradient(231.54deg, #dc3545 19.53%, #fc3282 84.32%)',
  greenGradient: 'linear-gradient(231.54deg, #28a745 19.53%, #66d147 84.32%)',
  colorBlueGradient:
    'linear-gradient(131deg, #FFFFFF 0%, #6CB7F0 0%, #0092FF 44%, #006CFF 71%, #004EFF 100%)',
  orangeGradient: 'linear-gradient(231.54deg, #FFBF62 19.53%, #EA8060 84.32%)',
  purpleGradient: 'linear-gradient(231.54deg, #673AB7 19.53%, #9C27B0 84.32%)',
  bgGrey: '#888888',
  fontBlackGrey: '#383838',
  fontFaintGrey: '#0000009A',
  bgBeige: '#FFE0BD',
  bgLightBeige: '#fef0e3',
  bgWhite: '#F9F9F9',
  fontMediumGray: '#B1B1B1',
  lightGray: '#D3D3D3',
  menuItemSelected: '#9ACCF3',
  menuItemHover: `rgba(154, 204, 243, 0.4)`,
  projectJob: '#1A67C1',
  confirmedProjectJob: '#4DA159',
  greenTagColor: '#62BA71',
  darkGreen: '#009447',
  lightGreen: '#74BD97',
  secondaryGreen: '#00D1C5',
  tertiaryGreen: '#05F500',
  manualJob: '#B6D8FF',
  paleYellow: '#FFFCC9',
  bgSubmittedStatus: '#FFE662',
  warningYellow: '#ffaa33',
  orange: '#FE9050',
  secondaryOrange: '#FF9F0E',
  purple: '#6D3190',
  secondaryPurple: '#AE4AFC',
  tableRowHover: 'rgba(0, 0, 0, 0.04)',
  blueLink: '#0000EE',
  boxShadowGrey: `0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)`,
  dropdownSelected: '#F7F7F7',
  dropdownHover: '#EDEDED',
  confirmCheckoutButton:
    'linear-gradient(260.34deg, #1272E3 19.53%, #0C5DBC 84.32%)',
  confirmCheckoutButtonDisabled:
    'linear-gradient(260.34deg, rgba(18, 114, 227, 0.6) 19.53%, rgba(12, 93, 188, 0.6) 84.32%)',
};
