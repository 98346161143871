import React from 'react';

import DownloadableFileWithActions from '../../components/list-items/DownloadableFileWithActions';

import { Tooltip, Typography } from '@material-ui/core';

import { trackOrderReadyResourceDownload } from '../../apis/orderReadyApi';

import { extractFileNameWithoutTimestampFromUrl } from '../../utils/fileUtils';
import { isAdminOrHigherRole } from '../../utils/roleUtils';

// -------------------------------------------------------------------------------------------------

function SupplierOrmFileDownloadDisplayWithTracking(props) {
  const {
    file,
    showDeleteButton = true,
    iconImage,
    userRole,
    onDelete = () => {},
    postDownloadFunction = () => {},
  } = props;

  return (
    <div>
      <DownloadableFileWithActions
        iconImage={iconImage}
        fileUrl={file.fileURL}
        fileName={extractFileNameWithoutTimestampFromUrl(file.fileURL)}
        downloadCount={file.downloadCount}
        showDeleteButton={showDeleteButton}
        onDelete={onDelete}
        borderColor='blue'
        postDownloadFunction={() => {
          if (!isAdminOrHigherRole(userRole)) {
            trackOrderReadyResourceDownload({
              resourceID: file.resourceID,
            }).then(() => {
              setTimeout(() => {
                postDownloadFunction();
              }, 1000);
            });
          }
        }}
      />
      {isAdminOrHigherRole(userRole) && (
        <Tooltip title='Number of times this document was downloaded by partner'>
          <Typography
            style={{
              fontStyle: 'italic',
              marginLeft: '0.5rem',
            }}
            variant='caption'
          >
            Total download: {file.downloadCount}
          </Typography>
        </Tooltip>
      )}
    </div>
  );
}

export default SupplierOrmFileDownloadDisplayWithTracking;
