import { BACKEND_SERVICE_URL } from '../constants';

import { fetchWithErrorHandling, getAuthorizedHeader } from '../utils/apiUtils';

// -------------------------------------------------------------------------------------------------

export const getAllSupplierLeadTimeFeedbacksApi = async () => {
  const requestUrl = `${BACKEND_SERVICE_URL}/feedback/supplier/lead-time`;
  return fetchWithErrorHandling(requestUrl, {
    method: 'GET',
    headers: getAuthorizedHeader(),
  });
};

export const getSupplierLeadTimeFeedbackApi = async (hashKey) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/feedback/supplier/lead-time/${hashKey}`;
  return fetchWithErrorHandling(requestUrl, {
    method: 'GET',
    headers: getAuthorizedHeader(),
  });
};

export const upsertSupplierLeadTimeFeedbackApi = async (body) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/feedback/supplier/lead-time`;
  return fetchWithErrorHandling(requestUrl, {
    method: 'POST',
    headers: getAuthorizedHeader(),
    body: JSON.stringify(body),
  });
};

export const getSupplierLeadTimeUnseenCountApi = async (userID) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/feedback/supplier/lead-time/unseen/${userID}`;
  return fetchWithErrorHandling(requestUrl, {
    method: 'GET',
    headers: getAuthorizedHeader(),
  });
};

export const updateLeadTimeFeedbackToHasSeen = async () => {
  const requestUrl = `${BACKEND_SERVICE_URL}/feedback/supplier/lead-time/has-seen`;
  return fetchWithErrorHandling(requestUrl, {
    method: 'PATCH',
    headers: getAuthorizedHeader(),
  });
};
