import { BACKEND_SERVICE_URL } from '../constants';
import { fetchWithErrorHandling, getAuthorizedHeader } from '../utils/apiUtils';

export const getQueueExecutorByTaskID = async (taskID) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/queue-executor/${taskID}`;
  return fetchWithErrorHandling(requestUrl, {
    method: 'GET',
    headers: getAuthorizedHeader(),
  });
};
