import React, { useReducer, useState } from 'react';

import { Checkbox, FormControlLabel } from '@material-ui/core';

function SurfaceFinishControlField(props) {
  const { surfaceFinish, onSelectionFormChange } = props;

  const [localState, updateLocalState] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      value: !!+surfaceFinish.value,
    }
  );
  const [timer, setTimer] = useState(null);

  const notifyParent = (params) => {
    if (timer) {
      clearTimeout(timer);
      setTimer(null);
    }
    const _timer = setTimeout(() => {
      onSelectionFormChange(surfaceFinish.id, {
        ...params,
      });
    }, 300);
    setTimer(_timer);
  };

  return (
    <FormControlLabel
      key={surfaceFinish.id}
      control={
        <Checkbox
          checked={localState.value}
          onChange={(event) => {
            const params = {
              value: event.target.checked,
            };
            updateLocalState(params);
            notifyParent(params);
          }}
          name={surfaceFinish.name}
        />
      }
      label={surfaceFinish.name}
      style={{
        width: 'max-content',
      }}
    />
  );
}

export default SurfaceFinishControlField;
