import { ceil, get } from 'lodash';
import React, { useEffect, useReducer, useState } from 'react';
import { Cookies } from 'react-cookie';
import { useQuery } from 'react-query';
import { connect } from 'react-redux';
import { Redirect, withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';

import { makeStyles } from '@material-ui/core/styles/index';

import { DataGrid } from '@mui/x-data-grid';

import { Button, Dialog, IconButton, Tooltip } from '@material-ui/core';

import { Edit as EditIcon } from '@material-ui/icons';

import {
  adminEditItem,
  displayEditItemPage,
  receiveFormData,
} from '../../actions';

import NewHardwarePart from '../../components/NewHardwarePart';
import CustomToolbar, {
  DataGridToolbarLayout,
} from '../../components/grid-data/CustomToolbar';
import GridDataPagination from '../../components/grid-data/GridDataPagination';
import { ExportCsvButton } from '../../components/grid-data/buttons/ExportCsvButton';
import SearchBar from '../../components/grid-data/buttons/SearchBar';
import ImageWith3DViewer from '../../components/images/ImageWith3DViewer';
import EditItemStatusPopup from '../../components/popups/EditItemStatusPopup';
import NotInterestedJobPopup from '../../components/popups/NotInterestedJobPopup';
import ViewItemAllowedDeniedSuppliersPopup from '../../components/popups/ViewItemAllowedDeniedSuppliersPopup';
import WatchingJobPopup from '../../components/popups/WatchingJobPopup';
import DataGridWrapTextCell from '../../components/tables/cells/DataGridWrapTextCell';

import { getAllPartsLibrary } from '../../apis/partsLibraryApi';

import { useDataGridFilterHook } from '../../hooks/useDataGridFilterHook';

import { exportCSV } from '../../utils/csvExportUtils';
import { dateTzSingapore } from '../../utils/dateTimeUtils';
import { isSuperAdminRole } from '../../utils/roleUtils';

import { ROLE_TYPES } from '../../constants';

import {
  materialWithColorCol,
  surfaceFinishingWithColorCol,
  toleranceCol,
} from '../../constants/itemTableConstants';

import { colors } from '../../palette';
import HorizontalExpandSpace from '../../components/ftr-components/HorizontalExpandSpace';

// -------------------------------------------------------------------------------------------------

const cookies = new Cookies();

const useStyles = makeStyles(() => ({
  body: {
    paddingTop: '1rem',
    '& .MuiTablePagination-root': {
      marginRight: '4rem',
    },
    marginBottom: '2rem',
    '& .MuiDataGrid-columnHeaderTitle': {
      color: colors.blue060,
      fontSize: '11pt',
      lineHeight: 'normal',
      wordBreak: 'break-word',
      whiteSpace: 'normal',
      fontWeight: 600,
    },
    '& .MuiDataGrid-columnHeaderTitleContainer': {
      height: '60px',
    },
  },
  imageIcon: {
    display: 'flex',
    height: 'inherit',
    width: 'inherit',
    filter: `invert(41%) sepia(90%) saturate(5329%) hue-rotate(203deg) brightness(96%) contrast(86%);`,
  },
  manualRfqIcon: {
    display: 'flex',
    height: 'inherit',
    width: 'inherit',
    filter: '#ffa500',
  },
  ppeIcon: {
    display: 'flex',
    height: 'inherit',
    width: 'inherit',
    filter: '##008000',
  },
  linkButton: {
    color: colors.blue050,
    textDecoration: 'underline',
  },
  containerLog: {
    display: 'flex',
    flexDirection: 'column',
    lineHeight: 'normal',
    wordBreak: 'break-word',
    whiteSpace: 'normal',
  },
  activityLog: {
    fontSize: '12px',
    fontWeight: 'bold',
    display: 'flex',
    flexDirection: 'column',
  },
  rfqTrackingStatus: {
    color: colors.red030,
    fontStyle: 'italic',
    textAlign: 'center',
  },
}));

function AllPartsLibrary(props) {
  const classes = useStyles();

  const { openEditItem, sendFormData, role } = props;

  const [selectedItem, setSelectedItem] = useState(null);
  const [onEditStatus, setOnEditStatus] = useState(false);
  const [openNewPartDialog, setOpenNewPartDialog] = useState(false);
  const [showWatchingJobPopup, setShowWatchingJobPopup] = useState(false);
  const [showNotInterestedPopup, setShowNotInterestedPopup] = useState(false);
  const [tableQueryParams, updateTableQueryParams] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      page: 0,
      pageSize: 10,
      search: '',
      totalCount: 0,
      loading: false,
    }
  );

  const {
    data: allPartsLibrary,
    isLoading,
    isFetching,
    refetch,
  } = useQuery('getAllPartsLibrary', () => getAllPartsLibrary());

  const [
    filteredData,
    { setSearchStr, setColumnsDef, setSourceData },
  ] = useDataGridFilterHook({
    search: '',
    source: allPartsLibrary || [],
  });

  useEffect(() => {
    setSearchStr(tableQueryParams.search);
  }, [tableQueryParams.search]);

  useEffect(() => {
    updateTableQueryParams({ totalCount: filteredData?.length });
  }, [filteredData]);

  useEffect(() => {
    setSourceData(allPartsLibrary);
  }, [allPartsLibrary]);

  const _columns = [
    {
      headerName: ' ',
      field: 'actions',
      export: false,
      renderCell: (params) => {
        const rowData = params.row;
        if (isNaN(+rowData.itemLibraryID)) {
          return null;
        }
        return (
          <Tooltip title='Hold Ctrl/Cmd and click to edit in new tab'>
            <IconButton
              aria-label='edit'
              onClick={(e) => {
                if (e.ctrlKey || e.metaKey) {
                  window.open(`/item/edit/${rowData.itemLibraryID}`);
                  return;
                }
                openEditItem(rowData.itemLibraryID);
              }}
            >
              <EditIcon />
            </IconButton>
          </Tooltip>
        );
      },
      width: 60,
    },
    {
      title: 'Project Library ID',
      headerName: 'Project Library ID',
      field: 'projectLibraryID',
      renderCell: (params) => {
        const rowData = params.row;
        if (isNaN(+rowData.projectLibraryID)) {
          return rowData.projectLibraryID;
        }
        return (
          <Button
            className={classes.linkButton}
            href={`/manage-projects/${rowData.projectLibraryID}`}
          >
            {rowData.projectLibraryID}
          </Button>
        );
      },
      width: 120,
    },
    {
      title: 'Project Library Name',
      headerName: 'Project Library Name',
      field: 'projectName',
      renderCell: ({ row: rowData }) => {
        return <DataGridWrapTextCell text={rowData.projectName} />;
      },
      width: 130,
    },
    {
      title: 'Part Library ID (Version)',
      headerName: 'Part Library ID (Version)',
      field: 'partLibraryID',
      renderCell: ({ row: rowData }) => {
        return (
          <DataGridWrapTextCell
            text={`${rowData.itemLibraryID} (v${rowData.version})`}
          />
        );
      },
      valueGetter: ({ row: rowData }) =>
        `${rowData.itemLibraryID} (v${rowData.version})`,
      width: 120,
    },
    {
      title: 'Part Name',
      headerName: 'Part Name',
      field: 'name',
      renderCell: ({ row: rowData }) => {
        return <DataGridWrapTextCell text={rowData.name} />;
      },
      width: 120,
    },
    {
      title: 'Customer (ID)',
      headerName: 'Customer (ID)',
      field: 'customerID',
      renderCell: ({ row: rowData }) => {
        return (
          <DataGridWrapTextCell
            text={`${rowData.buyerName} (${rowData.userID})`}
          />
        );
      },
      valueGetter: ({ row: rowData }) =>
        `${rowData.buyerName} (${rowData.userID})`,
      width: 120,
    },
    {
      title: 'Status',
      headerName: 'Status',
      field: 'status',
      renderCell: ({ row: rowData }) => {
        return <DataGridWrapTextCell text={rowData.status} />;
      },
      width: 150,
    },
    {
      title: 'Image',
      headerName: 'Image',
      field: 'image',
      renderCell: (params) => {
        const rowData = params.row;
        const url = rowData.imageFile || rowData.twoDImageUrl;
        return (
          <ImageWith3DViewer
            key={rowData.itemID}
            twoDImageUrl={url}
            cadFile={rowData.cadFile || rowData.originalFiles}
            width={80}
            height={80}
            noBorder={false}
            borderRadius={0}
            textRenderStyle={{ fontSize: '10px' }}
          />
        );
      },
      valueGetter: ({ row: rowData }) =>
        rowData.imageFile || rowData.twoDImageUrl,
      width: 100,
    },
    {
      title: 'Technology',
      headerName: 'Technology',
      field: 'technology',
      renderCell: ({ row: rowData }) => (
        <DataGridWrapTextCell text={rowData.technology} />
      ),
      width: 150,
    },
    {
      title: 'Qty',
      headerName: 'Qty',
      field: 'quantity',
      width: 60,
    },
    {
      ...materialWithColorCol,
    },
    {
      ...surfaceFinishingWithColorCol,
    },
    {
      ...toleranceCol,
    },
    {
      title: 'Date Created',
      headerName: 'Date Created',
      field: 'dateCreated',
      renderCell: (params) => {
        const rowData = params.row;
        return (
          <Tooltip title='Date and time displayed in SGT'>
            <div>
              {rowData.dateCreated ? dateTzSingapore(rowData.dateCreated) : ''}
            </div>
          </Tooltip>
        );
      },
      valueGetter: ({ row: rowData }) =>
        rowData.dateCreated ? dateTzSingapore(rowData.dateCreated) : '',
      width: 150,
    },
  ];

  const [columns] = useState(_columns);

  useEffect(() => {
    setColumnsDef(columns);
  }, [columns]);

  const [
    itemAllowedSuppliersPopupState,
    updateItemAllowedSuppliersPopupState,
  ] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      open: false,
      itemID: null,
      locationVisibility: null,
    }
  );

  const updateItemStatus = (itemID, informationToUpdate) => {
    const requestUrl = `${process.env.REACT_APP_BACKEND_SERVICE}/item/edit/${itemID}`;
    fetch(requestUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${cookies.get('token')}`,
      },
      body: JSON.stringify(informationToUpdate),
    })
      .then(function(response) {
        response.json().then((res) => {
          if (res.success) {
            toast.success('Your item status has been updated!', {
              position: toast.POSITION.TOP_RIGHT,
            });
            refetch();
          } else {
            toast.error(
              'Your item status cannot be updated. Please try again.',
              {
                position: toast.POSITION.TOP_RIGHT,
              }
            );
          }
        });
      })
      .catch(function() {
        toast.error(
          'Your project name cannot be changed. Please try again later.',
          {
            position: toast.POSITION.TOP_RIGHT,
          }
        );
      });
  };

  const handleCancelEditStatus = () => {
    setSelectedItem(null);
    setOnEditStatus(false);
  };

  const handleEditStatus = (itemID, informationToUpdate) => {
    updateItemStatus(itemID, informationToUpdate);
    setSelectedItem(null);
    setOnEditStatus(false);
  };

  const handleClose = () => {
    setOpenNewPartDialog(false);
  };

  const handleSearch = (searchTerm) => {
    updateTableQueryParams({
      page: 0,
      search: searchTerm,
    });
  };

  const getCustomerToolbar = () => {
    return (
      <DataGridToolbarLayout>
        <HorizontalExpandSpace />
        <CustomToolbar
          buttons={[
            <SearchBar
              key='search'
              onSearch={handleSearch}
              searchTerm={tableQueryParams.search}
            />,
            <ExportCsvButton
              key='export-csv'
              handleClick={() =>
                exportCSV(columns, filteredData, 'All Parts Library')
              }
              show={isSuperAdminRole(role)}
            />,
          ]}
        />
      </DataGridToolbarLayout>
    );
  };

  return [ROLE_TYPES.BUYER, ROLE_TYPES.SUPPLIER].includes(role) ? (
    <Redirect
      to={{
        pathname: '/',
        state: { from: props.location },
      }}
    />
  ) : (
    <div className={classes.body}>
      <div style={{ height: '100%', width: '100%' }}>
        <DataGrid
          autoHeight
          rows={filteredData ?? []}
          columns={columns.map((col) => ({
            ...col,
            sortable: false,
          }))}
          getRowId={(row) => row.itemLibraryID + row.version + row.userID}
          rowHeight={120}
          headerHeight={60}
          components={{
            Toolbar: getCustomerToolbar,
            Pagination: () => (
              <GridDataPagination
                pageCount={ceil(
                  tableQueryParams.totalCount / tableQueryParams.pageSize
                )}
              />
            ),
          }}
          rowsPerPageOptions={[10, 20, 50]}
          pageSize={tableQueryParams.pageSize}
          onPageSizeChange={(newPageSize) =>
            updateTableQueryParams({ pageSize: newPageSize })
          }
          disableSelectionOnClick
          disableColumnMenu
          loading={tableQueryParams.loading || isLoading || isFetching}
        />
      </div>
      {openNewPartDialog && (
        <Dialog
          open={openNewPartDialog}
          onClose={handleClose}
          aria-labelledby='form-dialog-title'
        >
          <NewHardwarePart
            onClose={handleClose}
            sendFormData={sendFormData}
            isDialog={true}
          />
        </Dialog>
      )}
      {onEditStatus && (
        <EditItemStatusPopup
          dialog={onEditStatus}
          handleCancel={handleCancelEditStatus}
          item={selectedItem}
          handleEditStatus={handleEditStatus}
        />
      )}
      <WatchingJobPopup
        open={showWatchingJobPopup}
        onClose={() => setShowWatchingJobPopup(false)}
        title={`Watching Part #${selectedItem && selectedItem.itemID}`}
        data={get(selectedItem, 'watchingJobs', [])}
      />
      <NotInterestedJobPopup
        open={showNotInterestedPopup}
        onClose={() => setShowNotInterestedPopup(false)}
        itemID={selectedItem && selectedItem.itemID}
        notInterestedJobs={get(selectedItem, 'notInterestedJobs', [])}
        onSuccess={() => {
          refetch();
          setShowNotInterestedPopup(false);
        }}
      />
      {itemAllowedSuppliersPopupState.open && (
        <ViewItemAllowedDeniedSuppliersPopup
          open={itemAllowedSuppliersPopupState.open}
          itemID={itemAllowedSuppliersPopupState.itemID}
          handleClose={() =>
            updateItemAllowedSuppliersPopupState({ open: false })
          }
          locationVisibility={itemAllowedSuppliersPopupState.locationVisibility}
        />
      )}
    </div>
  );
}

function mapStateToProps(state) {
  return {
    users: state.users.users,
    role: state.auth.user.role,
    myUserId: state.auth.user.userID,
  };
}

function matchDispatchToProps(dispatch, props) {
  return {
    submitEditPart: (editedPart) => dispatch(adminEditItem(editedPart)),
    openEditItem: (id) => dispatch(displayEditItemPage(id, props)),
    sendFormData: (data) => dispatch(receiveFormData(data, props)),
  };
}

const withConnect = connect(mapStateToProps, matchDispatchToProps);

export default withRouter(withConnect(AllPartsLibrary));
