import React, { useReducer, useState } from 'react';

import AcceptedMultipleQuotationsPopup from './AcceptedMultipleQuotationsPopup';
import ShareQuotationFormPopup from './ShareQuotationFormPopup';
import ThankYouFeedbackPopup from '../ThankYouFeedbackPopup';
import UserFeedbackPopup from '../UserFeedbackPopup';

import { isEmptyValue } from '../../../utils/commonUtils';

import { USER_FEEDBACK_QUESTION_LIST } from '../../../constants/userFeedbackConstants';

// ------------------------------------------------------------------------------------------------

function withConfirmOrderPopupsHOC(WrappedComponent) {
  return function ConfirmOrderPopupsHOC(props) {
    const {
      customerID,
      refreshProject = () => {},
      handleClose = () => {},
    } = props;

    const [localState, updateLocalState] = useReducer(
      (prev, next) => {
        if (isEmptyValue(next)) {
          return {
            open: false,
          };
        }
        return { ...prev, ...next };
      },
      {
        items: [],
        showAcceptedPopup: false,
        showFeedbackPopup: false,
        showShareQuotePopup: false,
        addresses: {},
      }
    );

    const [showThankYouFeedbackPopup, setShowThankYouFeedbackPopup] = useState(
      false
    );

    const handleAcceptedPopupButtonClick = () => {
      updateLocalState({ showAcceptedPopup: false });
      refreshProject();
      handleClose();
    };

    return (
      <>
        <WrappedComponent updatePopupsHocState={updateLocalState} {...props} />
        {localState.showShareQuotePopup && (
          <ShareQuotationFormPopup
            open={localState.showShareQuotePopup}
            handleClose={() => updateLocalState({ showShareQuotePopup: false })}
            userID={customerID}
            addresses={localState.addresses}
          />
        )}
        {localState.showAcceptedPopup && (
          <AcceptedMultipleQuotationsPopup
            dialog={localState.showAcceptedPopup}
            items={localState.items}
            onBtnClick={handleAcceptedPopupButtonClick}
          />
        )}
        {localState.showFeedbackPopup && (
          <UserFeedbackPopup
            open={localState.showFeedbackPopup}
            questionList={USER_FEEDBACK_QUESTION_LIST}
            userID={customerID}
            handleClose={() => {
              updateLocalState({ showFeedbackPopup: false });
              setShowThankYouFeedbackPopup(true);
            }}
          />
        )}
        {showThankYouFeedbackPopup && (
          <ThankYouFeedbackPopup
            open={showThankYouFeedbackPopup}
            handleClose={() => {
              setShowThankYouFeedbackPopup(false);
            }}
          />
        )}
      </>
    );
  };
}

export default withConfirmOrderPopupsHOC;
