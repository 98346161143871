import React from 'react';
import { get } from 'lodash';

import {
  Button as MctButton,
  CircularProgress,
  IconButton as McIconButton,
  Tooltip,
} from '@material-ui/core';

import GetAppIcon from '@material-ui/icons/GetApp';

import withActionTrackingHOC from '../../hocs/withActionTrackingHOC';

import useDownloadAllFiles from '../../hooks/useDownloadAllFilesHook';

import { isEmptyValue } from '../../utils/commonUtils';

import { ACTION_TRACKING_TYPE } from '../../constants/actionTrackingConstants';

// ------------------------------------------------------------------------------------------------

const Button = withActionTrackingHOC(MctButton);
const IconButton = withActionTrackingHOC(McIconButton);

function DownloadAllFilesButton(props) {
  const {
    folderName = 'Download',
    urlList = [],
    buttonText = '',
    trackingInfo = {},
  } = props;

  const urls = urlList?.map((url) => get(url, 'url') || url);

  const { loading, handleDownloadAllWithWaterMark } = useDownloadAllFiles(
    urlList,
    folderName
  );

  if (!isEmptyValue(buttonText)) {
    return (
      <Button
        actionTrackingKey={ACTION_TRACKING_TYPE.DOWNLOAD_FILE}
        additionalInfo={{
          urls,
        }}
        trackingInfo={trackingInfo}
        onClick={handleDownloadAllWithWaterMark}
        color='primary'
        startIcon={loading ? null : <GetAppIcon />}
        disabled={loading}
      >
        {loading && <CircularProgress size={15} style={{ marginRight: 8 }} />}
        {buttonText}
      </Button>
    );
  }

  return (
    <Tooltip title='Download All'>
      <IconButton
        actionTrackingKey={ACTION_TRACKING_TYPE.DOWNLOAD_FILE}
        additionalInfo={{
          urls,
        }}
        trackingInfo={trackingInfo}
        size='small'
        color='primary'
        onClick={handleDownloadAllWithWaterMark}
        disabled={loading}
      >
        <GetAppIcon />
      </IconButton>
    </Tooltip>
  );
}

export default DownloadAllFilesButton;
