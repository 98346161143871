import { BACKEND_SERVICE_URL } from '../constants';
import { fetchWithErrorHandling, getAuthorizedHeader } from '../utils/apiUtils';

export const getUnSeenBrevoFailedTracking = async (userID) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/brevo/tracking/unseen/${userID}`;
  return fetchWithErrorHandling(requestUrl, {
    method: 'GET',
    headers: getAuthorizedHeader(),
  });
};

export const updateBrevoFailedTrackingToHasSeen = async () => {
  const requestUrl = `${BACKEND_SERVICE_URL}/brevo/tracking/has-seen`;
  return fetchWithErrorHandling(requestUrl, {
    method: 'PATCH',
    headers: getAuthorizedHeader(),
  });
};
