import React, { useEffect, useState } from 'react';
import VerificationInput from 'react-verification-input';

import {
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  useTheme,
  useMediaQuery,
} from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles/index';

import greenTickIcon from '../../assets/icons/green_tick.svg';
import greyTickIcon from '../../assets/icons/grey_tick.svg';

import { colors } from '../../palette';

// ------------------------------- COMPONENT -------------------------------------------------

const useStyles = makeStyles((theme) => ({
  backdrop: {
    backdropFilter: 'blur(3px)',
  },
  dialog: {
    padding: '1.5rem',
    borderRadius: '1.5rem',
    alignItems: 'center',
    textAlign: 'center',
  },
  title: {
    display: 'contents',
    fontSize: '22px',
    fontWeight: '700',
    alignItems: 'center',
    padding: '1rem 0',
  },
  text: {
    colors: colors.fontBlack,
    padding: '0 4rem',
    [theme.breakpoints.down('sm')]: {
      padding: '0 2rem',
    },
  },
  character: {
    borderRadius: '0.5rem',
    borderColor: colors.neutral040,
  },
  characterInactive: {
    backgroundColor: colors.fontWhite,
  },
  button: {
    fontSize: '16px',
    background: colors.blue060,
    color: colors.fontWhite,
    textTransform: 'none',
    '&:hover': {
      background: colors.blue070,
    },
    '&:active': {
      background: colors.blue080,
    },
  },
  invalid: {
    fontSize: '14px',
    color: colors.red030,
  },
  resend: {
    fontSize: '14px',
    color: colors.green050,
  },
}));

function VerifyCodePopup(props) {
  const classes = useStyles();

  const {
    open,
    delay = 1750,
    dialogTitleText,
    renderDialogContent,
    handleInputComplete,
    isCodeCorrect = false,
    errorMessage = '',
    resendText = '',
  } = props;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  useEffect(() => {
    let timeoutID;

    if (open) {
      setIsDialogOpen(true);
    } else {
      timeoutID = setTimeout(() => {
        setIsDialogOpen(false);
      }, delay);
    }

    return () => {
      if (timeoutID) {
        clearTimeout(timeoutID);
      }
    };
  }, [open, delay]);

  const renderSixDigitFields = () => {
    return (
      <div style={{ justifyContent: 'center', padding: '2rem 1rem' }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            paddingBottom: '1rem',
          }}
        >
          <VerificationInput
            autoFocus
            validChars='0-9'
            placeholder=''
            classNames={{
              container: 'container',
              character: classes.character,
              characterInactive: classes.characterInactive,
              characterSelected: 'character--selected',
            }}
            onComplete={handleInputComplete}
          />
        </div>
        {errorMessage && (
          <Typography className={classes.invalid}>{errorMessage}</Typography>
        )}
        {resendText && (
          <Typography className={classes.resend}>{resendText}</Typography>
        )}
      </div>
    );
  };

  return (
    <Dialog
      maxWidth='sm'
      open={isDialogOpen}
      PaperProps={{ className: classes.dialog }}
      fullScreen={isMobile}
      BackdropProps={{
        classes: {
          root: classes.backdrop,
        },
      }}
    >
      <DialogTitle disableTypography className={classes.title}>
        {isCodeCorrect ? (
          <img
            style={{ padding: '3rem 0 1rem 0' }}
            src={greenTickIcon}
            alt='icon'
          />
        ) : (
          <img
            style={{ padding: '3rem 0 1rem 0' }}
            src={greyTickIcon}
            alt='icon'
          />
        )}
        <span style={{ padding: '1rem 0 2rem 0' }}>{dialogTitleText}</span>
      </DialogTitle>
      <DialogContent style={{ textSize: '14' }}>
        <div className={classes.text}>{renderDialogContent()}</div>
        {renderSixDigitFields()}
      </DialogContent>
    </Dialog>
  );
}

export default VerifyCodePopup;
