import { useMemo } from 'react';
import { useQuery, useQueryClient } from 'react-query';
const hash = require('object-hash');

import {
  getSupplierLeadTimeFeedbackApi,
  upsertSupplierLeadTimeFeedbackApi,
} from '../apis/supplierFeedbackApi';

import { isEmptyValue } from '../utils/commonUtils';

// -------------------------------------------------------------------------------------------------

const useSupplierLeadTimeFeedback = (userID, selectedQuotationIDs) => {
  const queryClient = useQueryClient();

  const hashValue = useMemo(() => hash({ userID, selectedQuotationIDs }), [
    userID,
    selectedQuotationIDs,
  ]);

  const { data, refetch } = useQuery(
    ['getSupplierLeadTimeFeedbackApi', hashValue],
    () => {
      if (isEmptyValue(userID) || isEmptyValue(selectedQuotationIDs)) {
        return null;
      }

      return getSupplierLeadTimeFeedbackApi(hashValue);
    }
  );

  function upsertSupplierLeadTimeFeedback(body) {
    upsertSupplierLeadTimeFeedbackApi({
      feedbackID: hashValue,
      ...body,
    }).then(() => {
      queryClient.invalidateQueries('getSupplierLeadTimeFeedbackApi');
    });
  }

  return {
    data,
    refetch,
    upsertSupplierLeadTimeFeedback,
  };
};

export default useSupplierLeadTimeFeedback;
