import React from 'react';

import { makeStyles } from '@material-ui/core/styles/index';

import inputsStyles from './inputsStyles';

import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  withStyles,
} from '@material-ui/core';

import InfoIcon from '@material-ui/icons/Info';

import { FtrB3, FtrBanner, FtrTypography } from '../ftr-components';
import InfoMatchValue from '../info/InfoMatchValue';
import { FlexRowSpaceBetween } from '../layouts/FlexLayouts';
import BootstrapInput from './BootstrapInput';
import BootstrapInputSmall from './BootstrapInputSmall';

import { isEmptyValue } from '../../utils/commonUtils';

import { SURFACE_FINISH_STR } from '../../constants/quotationConstants';

import { colors } from '../../palette';

// -------------------------------------------------------------------------------------------------

const useStyles = makeStyles(() => ({
  inputLabel: {
    fontSize: 16,
    marginBottom: 7,
    color: colors.fontGrey,
    fontWeight: 600,
  },
  inputLabelRowDisplay: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 12,
    color: colors.fontLightGrey,
    fontWeight: '600',
    textTransform: 'uppercase',
    // width: '30%',
    width: 150,
    minWidth: 150,
  },
  selectField: {
    width: '100%',
  },
  rowDisplay: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  container: {
    width: '100%',
  },
  rowContainer: {
    display: 'flex',
    alignItems: 'center',
    '& > div': {
      width: '100%',
    },
  },
}));

const DescTooltip = withStyles({
  tooltip: {
    backgroundColor: 'transparent',
  },
})(Tooltip);

function SurfaceFinishingField(props) {
  const classes = useStyles();
  const inputStyles = inputsStyles();

  // Defining input styles
  classes.emptyField = inputStyles.emptyField;
  classes.v2 = inputStyles.v2;

  const {
    visible = false,
    surfaceFinishOptions = [],
    onSelect,
    value,
    rowDisplay = false,
    bootstrapStyle = true,
    selectColorSurfaces = [],
    valueCustomer,
    overrideStyle = {},
    disableLabelOnBootstrapStyle = false,
    className = '',
    placeholder = '',
    v2 = false,
    isVerifyQuote = false,
    tooltip,
  } = props;

  const handleChange = (evt) => onSelect(evt.target.value);

  if (!visible) {
    return null;
  }

  const renderMenuItems = () => {
    return surfaceFinishOptions.map((option) => {
      const optionValue = option.value || option;
      const optionKey = option.key || option;
      const hasColor =
        selectColorSurfaces && selectColorSurfaces.includes(optionValue);
      const label = hasColor ? (
        <span>
          {optionKey}&nbsp;
          <span
            style={{
              fontStyle: 'italic',
              color: colors.blue050,
              fontSize: '0.8rem',
            }}
          >
            (Select Color)
          </span>
        </span>
      ) : (
        optionKey
      );

      return (
        <MenuItem key={optionKey} value={optionValue}>
          {label}
        </MenuItem>
      );
    });
  };

  const renderTooltipIcon = (tooltip) => {
    return (
      <DescTooltip
        title={
          <FtrBanner
            type='info'
            style={{
              backgroundColor: 'white',
              boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
              color: colors.neutral080,
            }}
          >
            <FtrTypography type='body' fontSize='14'>
              {tooltip}
            </FtrTypography>
          </FtrBanner>
        }
        placement='right'
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <InfoIcon style={{ color: colors.neutral040 }} />
        </div>
      </DescTooltip>
    );
  };

  if (bootstrapStyle) {
    return (
      <div
        className={`${
          rowDisplay ? classes.rowDisplay : classes.container
        } ${className}`}
      >
        {!disableLabelOnBootstrapStyle && (
          <InputLabel
            className={
              rowDisplay ? classes.inputLabelRowDisplay : classes.inputLabel
            }
          >
            Surface Finish
          </InputLabel>
        )}
        <Select
          className={`${classes.selectField} ${v2 ? classes.v2 : ''} ${
            !value ? classes.emptyField : ''
          }`}
          id='surfaceFinish'
          input={rowDisplay ? <BootstrapInputSmall /> : <BootstrapInput />}
          value={value ?? null}
          onChange={handleChange}
          renderValue={(value) => {
            const displayValue =
              surfaceFinishOptions.find((item) => item.value === value)?.key ??
              value;
            if (isEmptyValue(tooltip)) {
              return displayValue || placeholder;
            }

            return (
              <FlexRowSpaceBetween>
                <FtrB3 style={{ fontWeight: 600, color: colors.neutral070 }}>
                  {displayValue || placeholder}
                </FtrB3>
                {renderTooltipIcon(tooltip)}
              </FlexRowSpaceBetween>
            );
          }}
          native={false}
          displayEmpty
        >
          {renderMenuItems()}
        </Select>
      </div>
    );
  }

  return (
    <div
      className={`${classes.rowContainer} ${className}`}
      style={overrideStyle}
    >
      <FormControl variant='outlined' className={classes.formControl} fullWidth>
        <TextField
          labelId='surface-finishing'
          label='Surface Finish'
          id='surface-finishing'
          name={SURFACE_FINISH_STR}
          variant='outlined'
          select
          value={value}
          SelectProps={{
            renderValue: (value) => {
              const displayValue =
                surfaceFinishOptions.find((item) => item.value === value)
                  ?.key ?? value;
              if (isEmptyValue(tooltip)) {
                return displayValue || placeholder;
              }

              return (
                <FlexRowSpaceBetween>
                  <FtrB3 style={{ fontWeight: 600, color: colors.neutral070 }}>
                    {displayValue || placeholder}
                  </FtrB3>
                  {renderTooltipIcon(tooltip)}
                </FlexRowSpaceBetween>
              );
            },
          }}
          onChange={handleChange}
          margin='dense'
          fullWidth
          InputLabelProps={{ shrink: true }}
        >
          {renderMenuItems()}
        </TextField>
      </FormControl>
      {isVerifyQuote && (
        <InfoMatchValue
          name='Surface Finish'
          valueCustomer={valueCustomer}
          valueSupplier={value}
        />
      )}
    </div>
  );
}

export default SurfaceFinishingField;
