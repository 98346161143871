import libphonenumber from 'google-libphonenumber';

import { COUNTRY_LIST } from '../constants/countryConstants';

const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();

export const validatePhoneNumberByCountry = (phoneNumber, country) => {
  const region = COUNTRY_LIST[country].code;
  try {
    const number = phoneUtil.parseAndKeepRawInput(phoneNumber, region);
    return phoneUtil.isValidNumber(number);
  } catch (error) {
    return false;
  }
};

/**
 *
 * @param {string} contact
 * @param {string} country
 * @returns `(<countryPhoneCode>) <contact>`
 * @example (+65) 87654321
 */
export const getContactWithCountryCode = (contact, country) => {
  const countyPhoneCode = COUNTRY_LIST[country].phone;
  return `(${countyPhoneCode}) ${contact}`;
};
