// Import settings
import React from 'react';

// Import material UI components
import { Grid } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import { AssignmentTurnedInOutlined as AssignmentTurnedInOutlinedIcon } from '@material-ui/icons';

function QuoteSubmitLabel() {
  return (
    <Grid container fluid='true' spacing={3} direction='column'>
      <Alert
        color='info'
        icon={<AssignmentTurnedInOutlinedIcon fontSize='inherit' />}
      >
        <AlertTitle>Quote submitted</AlertTitle>
        We have received your quote and will get back to you soon!
      </Alert>
    </Grid>
  );
}

export default QuoteSubmitLabel;
