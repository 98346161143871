import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { useQuery, useQueryClient, useMutation } from 'react-query';
import { startCase } from 'lodash';

import {
  Container,
  Divider,
  Typography,
  makeStyles,
  FormGroup,
  FormControlLabel,
  Switch,
} from '@material-ui/core';

import {
  FE_FEATURE_FLAGS_CONFIGURATION,
  KEY_CONFIGURATION,
} from '../../constants/configurations';

import { notifyError, notifySuccess } from '../../services/notificationService';

// Import services and api
import {
  getFeatureFlags,
  updateConfiguration,
} from '../../apis/configurationApi';

import { colors } from '../../palette';

const useStyles = makeStyles((theme) => ({
  body: {
    paddingTop: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    gap: '25px',
  },
  container: {
    margin: '25px, 0px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    gap: '25px',
    padding: '25px',
    border: `3px solid ${colors.bgLightGrey}`,
  },
}));

function AutomationsPanel() {
  const classes = useStyles();
  const queryClient = useQueryClient();
  const { data: featureFlags, isSuccess } = useQuery('getAllFeatureFlags', () =>
    getFeatureFlags()
  );
  const { mutate } = useMutation((payloads) => updateConfiguration(payloads), {
    onSuccess: () => {
      notifySuccess('Data FE_FEATURE_FLAGS_CONFIGURATION updated successfully');
      queryClient.invalidateQueries('getAllFeatureFlags');
    },
    onError: () => {
      notifyError("Data FE_FEATURE_FLAGS_CONFIGURATION updated failed'");
    },
  });

  const onSwitchChange = (event) => {
    const { name, checked } = event.target;
    const newFeatureFlags = { ...featureFlags.config, [name]: checked };
    mutate({
      key: KEY_CONFIGURATION.FE_FEATURE_FLAGS_CONFIGURATION,
      value: newFeatureFlags,
    });
  };

  const HIDE_FLAGS = [
    FE_FEATURE_FLAGS_CONFIGURATION.CAD_RENDERER_AINC,
    FE_FEATURE_FLAGS_CONFIGURATION.GLOBAL_TDE_SWITCH,
    FE_FEATURE_FLAGS_CONFIGURATION.CAD_RENDERER_NATIVE,
    FE_FEATURE_FLAGS_CONFIGURATION.DFM_AUTOMATION_BUYER,
  ];

  return (
    <div className={classes.body}>
      <Container className={classes.container}>
        <div>
          <Typography variant='h6'>Feature Flags On/Off Switch</Typography>
          <Divider />
        </div>
        <FormGroup row>
          {isSuccess &&
            Object.keys(featureFlags.config)
              .filter((flag) => !HIDE_FLAGS.includes(flag))
              .map((option, index) => {
                return (
                  <FormControlLabel
                    key={index}
                    control={
                      <Switch
                        checked={featureFlags.config[option]}
                        onChange={onSwitchChange}
                        name={option}
                        color='primary'
                      />
                    }
                    label={startCase(option)}
                  />
                );
              })}
        </FormGroup>
      </Container>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    role: state.auth.user.role,
  };
}

const withConnect = connect(mapStateToProps);

export default withRouter(withConnect(AutomationsPanel));
