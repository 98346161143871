// Import settings
import React, { Fragment, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { convertPriceWithQuantityToCurrency } from '../../utils/currencyUtils';
import { reciprocal } from '../../utils/numberUtils';
import { getUserCurrencySelector } from '../../selectors/userSelector';
import { getExchangeRateSelector } from '../../selectors/exchangeRatesSelector';

import {
  Box,
  Dialog,
  DialogContent,
  InputAdornment,
  TextField,
  withStyles,
  IconButton,
} from '@material-ui/core';

import { Close as CloseIcon } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles/index';

// Import material UI components
import { FtrTypography, FtrButton } from '../ftr-components';

import {
  updateTargetUnitPriceForPart,
  updateCadPartIfExists,
} from '../../actions';

import { upsertPriceFeedback } from '../../apis/ppeFeedbackApi';

import { getCurrencySymbol } from '../../utils/currencyUtils';

import { colors } from '../../palette';

import { FEEDBACK_STATUS } from '../../constants/feedbackConstants';

// Style the components
const useStyles = makeStyles((theme) => ({
  paper: {
    borderRadius: '30px',
    [theme.breakpoints.down('sm')]: {
      borderRadius: '0',
    },
    width: 600,
    minWidth: 200,
    maxWidth: 800,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  space: {
    width: theme.spacing(2),
  },
}));

const FieldLabel = withStyles({
  root: {
    padding: '0.3rem 0',
    color: colors.neutral070,
  },
})((props) => <FtrTypography type='heading' fontSize='16' {...props} />);

function PriceFeedbackPopup(props) {
  const renderTargetUnitPriceField = (price, setPrice, currency) => {
    return (
      <Fragment>
        <FtrTypography type='heading' fontSize='22'>
          Target Unit Price
        </FtrTypography>
        <FieldLabel id='target-unit-price-text' style={{ marginTop: '3rem' }}>
          Target Unit Price
        </FieldLabel>
        <TextField
          size='small'
          margin='none'
          type='number'
          variant='outlined'
          style={{ width: '100%' }}
          inputProps={{ min: 0 }}
          value={price}
          onChange={(evt) => setPrice(evt.target.value)}
          InputProps={{
            style: {
              borderRadius: '10px',
            },
            startAdornment: (
              <InputAdornment position='start'>
                {getCurrencySymbol(currency)}
              </InputAdornment>
            ),
          }}
          data-cy='target-unit-price-field'
        />
      </Fragment>
    );
  };
  const dispatch = useDispatch();
  const classes = useStyles();
  const currency = useSelector(getUserCurrencySelector);
  const exchangeRate = useSelector(getExchangeRateSelector);
  const { dialog, handleClose, item } = props;
  const { unitPrice } = convertPriceWithQuantityToCurrency({
    totalPrice: item.totalPrice ?? 0,
    quantity: item.qty ?? 1,
    currency,
    exchangeRate,
  });
  const [price, setPrice] = useState(unitPrice);
  const userID = useSelector((state) => state.auth.user.userID);

  return (
    <Dialog
      maxWidth='xl'
      open={dialog}
      onClose={handleClose}
      aria-labelledby='confirmation-dialog-title'
      classes={{ paper: classes.paper }}
    >
      <div style={{ padding: '35px 0 20px' }}>
        <DialogContent style={{ padding: '0 30px' }}>
          <React.Fragment>
            {renderTargetUnitPriceField(price, setPrice, currency)}
          </React.Fragment>
        </DialogContent>
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
            marginTop: 10,
            position: 'relative',
          }}
        >
          <div style={{ flexGrow: 1, maxWidth: '25%' }}></div> {/* Spacer */}
          <FtrButton
            onClick={handleClose}
            size='medium'
            variant='text'
            style={{ marginLeft: 'auto' }}
          >
            Cancel
          </FtrButton>
          <FtrButton
            color='blue'
            size='medium'
            onClick={() => {
              dispatch(
                updateTargetUnitPriceForPart({
                  id: item.id,
                  originalTargetPrice: price,
                })
              );
              dispatch(
                updateCadPartIfExists({
                  id: item.id,
                  priceFeedback: null,
                })
              );
              const { unitPrice } = convertPriceWithQuantityToCurrency({
                totalPrice: price,
                currency,
                quantity: 1,
                exchangeRate: reciprocal(exchangeRate),
              });
              upsertPriceFeedback({
                iqLogsID: item.ppePriceLogId,
                userID: userID,
                priceFeedback: FEEDBACK_STATUS.BAD,
                targetPrice: unitPrice,
              });
              handleClose();
            }}
            style={{ marginLeft: '10px', marginRight: '10px' }} // Adjust spacing between buttons if needed
            disabled={price === unitPrice}
          >
            Save
          </FtrButton>
          <Box className={classes.space}></Box>
        </div>
      </div>
      <IconButton
        aria-label='close'
        className={classes.closeButton}
        onClick={handleClose}
      >
        <CloseIcon />
      </IconButton>
    </Dialog>
  );
}

export default PriceFeedbackPopup;
