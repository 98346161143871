import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import clsx from 'clsx';
import { InputAdornment, TextField, makeStyles } from '@material-ui/core';
import {
  AddCircle as AddCircleIcon,
  RemoveCircle as RemoveCircleIcon,
} from '@material-ui/icons';

import { ROLE_TYPES } from '../constants';
import { PLATFORM_FEE_RULE_KEY } from '../constants/platformFeeConstants';
import { colors } from '../palette';

const useStyles = makeStyles((theme) => ({
  body: {
    padding: theme.spacing(5),
    paddingTop: theme.spacing(5),
    display: 'flex',
    flexDirection: 'column',
    gap: '25px',
  },
  container: {
    margin: '25px, 0px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    gap: '25px',
    padding: '25px',
    border: `3px solid ${colors.bgLightGrey}`,
  },
  rowInput: { display: 'flex', gap: '1rem', alignItems: 'center' },
  priceInput: {
    width: '430px',
  },
  input: {
    width: '150px !important',
  },
  icons: {
    display: 'flex',
    alignItems: 'center',
    columnGap: '0.5rem',
  },
  priceText: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    textAlign: 'center',
    width: '100px !important',
    '& > span:nth-child(1)': {
      textAlign: 'start',
    },
    '& > span:nth-child(2)': {
      textAlign: 'center',
      paddingLeft: '0.5rem',
    },
    '& > span:nth-child(3)': {
      textAlign: 'end',
    },
  },
}));

const PriceFee = (props) => {
  const classes = useStyles();
  const {
    rule,
    handleChangeValue,
    index,
    role,
    handleAddNewRowRules,
    handleRemoveRowRules,
    totalRules,
  } = props;
  const { fee, lt: ltValue, gte: gteValue } = rule;

  const renderFee = () => (
    <React.Fragment>
      <span>FEE: </span>
      <TextField
        disabled={role !== ROLE_TYPES.SUPER_ADMIN}
        label='Fee'
        variant='outlined'
        type='number'
        value={fee}
        margin='dense'
        onChange={(evt) =>
          handleChangeValue({
            value: evt.target.value,
            key: PLATFORM_FEE_RULE_KEY.FEE,
            index,
          })
        }
        className={classes.input}
        InputProps={{
          startAdornment: <InputAdornment position='start'>S$</InputAdornment>,
        }}
      />
    </React.Fragment>
  );

  const renderLowerThan = () => (
    <TextField
      disabled={role !== ROLE_TYPES.SUPER_ADMIN}
      label='Lower Than'
      variant='outlined'
      type='number'
      className={classes.input}
      onChange={(evt) =>
        handleChangeValue({
          value: evt.target.value,
          key: PLATFORM_FEE_RULE_KEY.LOWER_THAN,
          index,
        })
      }
      value={ltValue}
      margin='dense'
      InputProps={{
        startAdornment: <InputAdornment position='start'>S$</InputAdornment>,
      }}
    />
  );

  const renderGreaterThanEqual = () => (
    <TextField
      disabled
      label='Greater Than Equal'
      variant='outlined'
      type='number'
      value={gteValue}
      margin='dense'
      onChange={(evt) =>
        handleChangeValue({
          value: evt.target.value,
          key: PLATFORM_FEE_RULE_KEY.GREATER_THAN_EQUAL,
          index,
        })
      }
      className={classes.input}
      InputProps={{
        startAdornment: <InputAdornment position='start'>S$</InputAdornment>,
      }}
    />
  );

  const renderPriceType = (type = 'range') => (
    <div className={classes.priceText}>
      <span>{['range', 'greater-than-equal'].includes(type) ? '<=' : ''}</span>
      <span>PRICE</span>
      <span>{['range', 'lower-than'].includes(type) ? '<' : ''}</span>
    </div>
  );

  // Range Row
  if (ltValue >= 0 && gteValue >= 0) {
    return (
      <div className={classes.rowInput}>
        <div className={clsx(classes.rowInput, classes.priceInput)}>
          {renderGreaterThanEqual()}
          {renderPriceType('range')}
          {renderLowerThan()}
        </div>
        <div className={classes.rowInput} style={{ gap: '5px' }}>
          {renderFee()}
        </div>
        <div className={classes.icons}>
          <AddCircleIcon
            style={{
              color: colors.successGreen,
              cursor: 'pointer',
            }}
            onClick={() => handleAddNewRowRules(index + 1)}
          />
          {totalRules > 3 && (
            <RemoveCircleIcon
              style={{
                color: colors.errorRed,
                cursor: 'pointer',
              }}
              onClick={() => handleRemoveRowRules(index)}
            />
          )}
        </div>
      </div>
    );
  }
  // Lower Than Row
  if (ltValue >= 0) {
    return (
      <div className={classes.rowInput}>
        <div
          className={clsx(classes.rowInput, classes.priceInput)}
          style={{
            justifyContent: 'end',
          }}
        >
          {renderPriceType('lower-than')}
          {renderLowerThan()}
        </div>
        <div className={classes.rowInput} style={{ gap: '5px' }}>
          {renderFee()}
        </div>
      </div>
    );
  }
  // Greater Than Equal Row
  if (gteValue >= 0) {
    return (
      <div className={classes.rowInput}>
        <div className={clsx(classes.rowInput, classes.priceInput)}>
          {renderGreaterThanEqual()}
          {renderPriceType('greater-than-equal')}
        </div>
        <div className={classes.rowInput} style={{ gap: '5px' }}>
          {renderFee()}
        </div>
      </div>
    );
  }
};

function mapStateToProps(state) {
  return {
    role: state.auth.user.role,
  };
}

const withConnect = connect(mapStateToProps);

export default withRouter(withConnect(PriceFee));
