import React from 'react';

import { makeStyles } from '@material-ui/core/styles/index';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  Grid,
  Typography,
} from '@material-ui/core';

import FilesUploadButton from '../../components/FilesUploadButton';
import ImageWith3DViewer from '../../components/images/ImageWith3DViewer';
import OrmMaterialFileUploader from './OrmMaterialFileUploader';
import { FlexColumn, FlexRow } from '../../components/layouts/FlexLayouts';
import { FtrB2 } from '../../components/ftr-components';

import { ExpandMore } from '@material-ui/icons';

import { itemProperties } from '../../utils/itemUtils';

import { uploadOrderReadyResource } from '../../utils/orderReadyUtils';

import { notifyError } from '../../services/notificationService';

import { ORDER_READY_RESOURCE_TYPES } from '../../constants/orderReadyConstants';
import { FIFTY_MB_IN_BYTES } from '../../constants/fileConstants';

// ----------------------------------------------------------------------------------

const useStyles = makeStyles(() => ({
  heading: {
    fontWeight: 'bold',
  },
  accordionSummaryContent: {
    margin: '12px 0 !important',
  },
}));

function AdminUploadFilesTab(props) {
  const classes = useStyles();

  const { itemOrderReadyList = [], loadOrderReadyInfo = () => {} } = props;

  const handleUploadOrderReadyResource = ({
    file,
    itemOrderReadyID,
    resourceType,
    porID,
  }) => {
    if (file.size > FIFTY_MB_IN_BYTES) {
      notifyError('File size should be less than 50 MB');
      return;
    }

    uploadOrderReadyResource({
      itemOrderReadyID: itemOrderReadyID,
      file,
      resourceType,
      porID,
    }).then(loadOrderReadyInfo);
  };

  const renderItemOrderReady = (itemOrderReady) => {
    const adminUploadResources = itemOrderReady.resources.filter(
      (resource) =>
        resource.type === ORDER_READY_RESOURCE_TYPES.ADMIN_UPLOAD_FILE
    );

    return (
      <Accordion key={itemOrderReady.info.itemID} defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          classes={{
            content: classes.accordionSummaryContent,
          }}
        >
          <FlexRow style={{ width: '100%' }}>
            <ImageWith3DViewer
              twoDImageUrl={
                itemOrderReady.info.imageFile ||
                itemOrderReady.info.twoDImageUrl
              }
              cadFile={
                itemOrderReady.info.cadFile || itemOrderReady.info.originalFiles
              }
              height={80}
              width={80}
              borderRadius={0}
              noBorder={false}
            />
            <Box style={{ width: '1rem' }} />
            <FlexColumn>
              <Typography className={classes.heading}>
                Part #{itemOrderReady.info.itemID}
              </Typography>
              <FtrB2 style={{ marginTop: '3px' }}>
                {itemProperties(itemOrderReady.info)}
              </FtrB2>
            </FlexColumn>
            <span style={{ flex: '1 1 auto' }} />
            <FilesUploadButton
              id={`upload-admin-file-${itemOrderReady.info.itemID}`}
              handleUploadFiles={(files) =>
                handleUploadOrderReadyResource({
                  file: files[0],
                  itemOrderReadyID: itemOrderReady.info.itemOrderReadyID,
                  resourceType: ORDER_READY_RESOURCE_TYPES.ADMIN_UPLOAD_FILE,
                  porID: itemOrderReady.info.projectOrderReadyID,
                })
              }
              multiple={false}
            />
          </FlexRow>
        </AccordionSummary>
        {adminUploadResources.length > 0 && (
          <AccordionDetails
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Divider />
            <FlexColumn
              style={{
                marginTop: '1rem',
              }}
            >
              {adminUploadResources.map((resource) => {
                return (
                  <OrmMaterialFileUploader
                    key={resource?.resourceID}
                    resource={resource}
                    loadOrderReadyInfo={loadOrderReadyInfo}
                    showEdit={false}
                    showRemoveFileIcon={false}
                    showUploadedTime={true}
                  />
                );
              })}
            </FlexColumn>
          </AccordionDetails>
        )}
      </Accordion>
    );
  };

  return (
    <Grid container>
      <Grid item xs={12} md={6}>
        <FlexColumn>
          {itemOrderReadyList.map((itemOrderReady) =>
            renderItemOrderReady(itemOrderReady)
          )}
        </FlexColumn>
      </Grid>
    </Grid>
  );
}

export default AdminUploadFilesTab;
