import React, { useReducer } from 'react';

import { CUSTOMER_CANCEL_ORDER_REASON_MAPPING } from '../../constants/itemConstants';

import { deleteItemsApi } from '../../apis/itemApi';

import { isEmptyValue } from '../../utils/commonUtils';

import useCustomerProjectInfo from './useCustomerProjectInfoHook';

import DeletePartsPopup from './DeletePartsPopup';
import DeleteWarningPopupV3 from '../../components/popups/DeleteWarningPopupV3';

import { notifyError } from '../../services/notificationService';

// -------------------------------------------------------------------------------------------------

function withDeletePartsPopupHOC(WrappedComponent) {
  function WrappedComponentWithHOC(props) {
    const projectIdFromUri = window.location.href.split('/')[4];

    const projectID = projectIdFromUri
      ? projectIdFromUri
      : props.location?.state?.projectID;

    const [
      { allParts = [] },
      { refetchProjectInfoByID },
    ] = useCustomerProjectInfo(projectID);

    const { setSnackbarMessage = () => {} } = props;

    const defaultLocalState = {
      open: false,
      openWarning: false,
      deleting: false,
    };

    const [localState, updateLocalState] = useReducer((prev, next) => {
      if (isEmptyValue(next)) {
        return defaultLocalState;
      }
      return { ...prev, ...next };
    }, defaultLocalState);

    const handleDeleteItems = (deletedItems) => {
      updateLocalState({ deletedItems });
      refetchProjectInfoByID();
      updateLocalState({ openWarning: true, open: false });
    };

    const handleSubmitDeleteReason = async ({
      feedback,
      additionalRemarks,
    }) => {
      const body = {
        projectID,
        itemIDs: localState.deletedItems.map((item) => item.itemID),
        feedback: CUSTOMER_CANCEL_ORDER_REASON_MAPPING[feedback] || feedback,
        additionalRemarks,
      };

      updateLocalState({ deleting: true });

      try {
        await deleteItemsApi(body);
        setSnackbarMessage('Part(s) deleted successfully!');
        refetchProjectInfoByID();
      } catch (error) {
        notifyError('Unable to delete part(s). Please try again.');
      }

      updateLocalState({ deleting: false, openWarning: false });
    };

    return (
      <>
        <WrappedComponent
          updateDeletePartsPopupState={updateLocalState}
          {...props}
        />
        {localState.open && (
          <DeletePartsPopup
            open={localState.open}
            handleClose={() => updateLocalState({ open: false })}
            onDeleteItems={handleDeleteItems}
            items={allParts}
          />
        )}
        {localState.openWarning && (
          <DeleteWarningPopupV3
            deletedItems={localState.deletedItems}
            open={localState.openWarning}
            deleting={localState.deleting}
            onConfirm={handleSubmitDeleteReason}
            onCancel={() => updateLocalState({ openWarning: false })}
            onClose={() => updateLocalState({ openWarning: false })}
          />
        )}
      </>
    );
  }

  return WrappedComponentWithHOC;
}

export default withDeletePartsPopupHOC;
