import { useQuery } from 'react-query';

import { getAllQuotationsOfItem } from '../apis/itemApi';

import { isEmptyValue } from '../utils/commonUtils';

// -------------------------------------------------------------------------------------------------

const useItemAllQuotationsInfo = (itemID) => {
  const { data, refetch } = useQuery(['getAllQuotationsOfItem', itemID], () => {
    if (isEmptyValue(itemID)) {
      return undefined;
    }

    return getAllQuotationsOfItem(itemID);
  });

  return {
    data,
    refetch,
  };
};

export default useItemAllQuotationsInfo;
