import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { nanoid } from 'nanoid';

import {
  addOrUpdateCadPart,
  getPpePriceForCadPart,
  resetPartSummaryDetails,
} from '../actions';

import { prepareOrderAgainItem } from '../components/util/orderItemAgainUtils';

import { useItemInputConfig } from './useItemInputConfig';

export const useOrderItemsAgainHook = () => {
  const dispatch = useDispatch();

  const [itemList, setItemList] = useState([]);

  const [
    { technologyOptions },
    {
      loadTechnologyOptions,
      loadMaterialCategoryOptions,
      loadThreeDMaterialOptions,
      loadSurfaceFinishOptions,
    },
  ] = useItemInputConfig({});

  useEffect(() => {
    loadTechnologyOptions(false);
  }, []);

  const handleOrderItemsAgain = async (selectedItemIDs) => {
    dispatch(resetPartSummaryDetails());
    const oldPartDetailsList = itemList.filter((item) =>
      selectedItemIDs.includes(item.itemID)
    );
    for (const partDetails of oldPartDetailsList) {
      const item = await prepareOrderAgainItem({
        existingItem: partDetails,
        technologyOptions,
        loadMaterialCategoryOptions,
        loadThreeDMaterialOptions,
        loadSurfaceFinishOptions,
      });
      const id = nanoid();
      item.id = id;
      dispatch(addOrUpdateCadPart(item));
      dispatch(getPpePriceForCadPart(id));
    }
  };

  return [
    {
      setItemList,
      handleOrderItemsAgain,
    },
  ];
};
