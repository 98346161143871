import React from 'react';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles/index';

import inputsStyles from '../inputs/inputsStyles';

import {
  MenuItem,
  Select,
  FormControl,
  withStyles,
  Tooltip,
} from '@material-ui/core';

import InfoIcon from '@material-ui/icons/Info';

import FtrBanner from './FtrBanner';
import FtrTypography, { FtrB3 } from './FtrTypography';
import { FlexRow, FlexRowSpaceBetween } from '../layouts/FlexLayouts';

import { renderSkeletonOverlay } from '../util/skeleton';

import { isEmptyValue } from '../../utils/commonUtils';

import { colors } from '../../palette';

// -------------------------------------------------------------------------------------------------

const useStyles = (props) =>
  makeStyles(() => ({
    selectDropdown: {
      borderRadius: 10,
      '& .MuiSelect-select': {
        color: '#4F4F4F',
        fontWeight: 600,
        textAlign: 'left',
        whiteSpace: 'break-spaces',
      },
      backgroundColor: colors.neutral010,
      '&:hover': {
        backgroundColor: colors.neutral020,
      },
    },
    menuItemRoot: {
      borderRadius: '0.75rem',
      whiteSpace: 'break-spaces',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      '&:hover': {
        backgroundColor: '#EDEDED',
      },
      '&.Mui-selected': {
        backgroundColor: '#EDEDED',
        fontWeight: '600',
      },
    },
    paper: {
      borderRadius: '0.8rem',
      padding: '0.5rem',
      ...props.menuStyle,
    },
  }));

const DescTooltip = withStyles({
  tooltip: {
    backgroundColor: 'transparent',
  },
})(Tooltip);

/**
 * Customised Dropdown component
 *
 * @param {object} props - The properties of the Dropdown component.
 * @param {String} props.id - A unique identifier for the dropdown.
 * @param {String} props.value - The value of the dropdown.
 * @param {Boolean} props.fullWidth - If true, the dropdown will take up the full width of the parent div.
 * @param {Function} props.handleChange - Callback function to be executed when the dropdown value is changed.
 * @param {Array.<{key: String, display: String=}>} props.items - The items to be displayed in the dropdown.
 * @param {Boolean} props.loading - The loading status of the component
 * @param {React.CSSProperties} props.menuStyle - Css style for menu
 *
 * @returns {React.ReactNode} The rendered Dropdown component.
 */
function FtrDropdown(props) {
  const {
    id,
    value,
    fullWidth = false,
    handleChange,
    items = [],
    loading = false,
    placeholder = '',
    menuStyle = {},
    tooltip,
  } = props;

  const classes = useStyles({ menuStyle })();
  const inputStyles = inputsStyles();

  const menuProps = {
    classes: {
      paper: classes.paper,
    },
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'center',
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'center',
    },
    getContentAnchorEl: null,
  };

  // Defining input styles
  classes.emptyField = inputStyles.emptyField;

  if (isEmptyValue(items)) {
    return;
  }

  const renderTooltipIcon = (tooltip) => {
    return (
      <DescTooltip
        title={
          <FtrBanner
            type='info'
            style={{
              backgroundColor: 'white',
              boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
              color: colors.neutral080,
            }}
          >
            <FtrTypography type='body' fontSize='14'>
              {tooltip}
            </FtrTypography>
          </FtrBanner>
        }
        placement='right'
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <InfoIcon style={{ color: colors.neutral040 }} />
        </div>
      </DescTooltip>
    );
  };

  return (
    <FormControl style={{ width: fullWidth && '100%' }}>
      <FlexRow>
        {loading && renderSkeletonOverlay()}
        <Select
          className={clsx(
            classes.selectDropdown,
            classes.disabled,
            !value && classes.emptyField
          )}
          id={id}
          variant='outlined'
          value={value ?? null}
          onChange={(event) => {
            // clicking the background component should not be allowed
            event.preventDefault();
            event.stopPropagation();
            handleChange(event.target.value);
          }}
          onClick={(event) => {
            // To handle cases when the component is disabled
            // clicking the background component should not be allowed
            event.preventDefault();
            event.stopPropagation();
          }}
          MenuProps={menuProps}
          margin='dense'
          fullWidth
          renderValue={(value) => {
            const displayValue =
              items.find((item) => item.value === value)?.key ?? value;
            if (isEmptyValue(tooltip)) {
              return displayValue || placeholder;
            }

            return (
              <FlexRowSpaceBetween>
                <FtrB3 style={{ fontWeight: 600, color: colors.neutral070 }}>
                  {displayValue || placeholder}
                </FtrB3>
                {renderTooltipIcon(tooltip)}
              </FlexRowSpaceBetween>
            );
          }}
          displayEmpty
          {...props}
        >
          {items.map((item) => {
            return (
              <MenuItem
                key={item.key ?? item}
                classes={{
                  root: classes.menuItemRoot,
                }}
                value={item.value || item}
              >
                <FtrTypography>{item.key ?? item}</FtrTypography>
                {item?.tooltip && renderTooltipIcon(item?.tooltip)}
              </MenuItem>
            );
          })}
        </Select>
      </FlexRow>
    </FormControl>
  );
}

export default FtrDropdown;
