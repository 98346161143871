import queryString from 'query-string';

import {
  getAuthorizedHeader,
  witchCacheResponse,
  fetchWithErrorHandling,
} from '../utils/apiUtils';

import { BACKEND_SERVICE_URL } from '../constants';

// -------------------------------------------------------------------------------------------------

const hash = require('object-hash');

const ONE_HOUR_IN_SECS = 60 * 60;

export const getTechnologyOptions = async (params) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/configurations/technologies?${queryString.stringify(
    params
  )}`;
  const headers = getAuthorizedHeader();
  const requestOptions = {
    method: 'GET',
    headers,
  };
  return fetchWithErrorHandling(requestUrl, requestOptions);
};

export const getTechnologyOptionsWithCache = witchCacheResponse(
  getTechnologyOptions,
  ONE_HOUR_IN_SECS
);

export const get3DPrintingTechnologyOptions = async () => {
  const requestUrl = `${BACKEND_SERVICE_URL}/configurations/3dp-technologies`;
  const headers = getAuthorizedHeader();
  const requestOptions = {
    method: 'GET',
    headers,
  };
  return fetchWithErrorHandling(requestUrl, requestOptions);
};

export const get3DPrintingTechnologyOptionsWithCache = witchCacheResponse(
  get3DPrintingTechnologyOptions,
  ONE_HOUR_IN_SECS
);

export const getMaterialsByTechnology = async (params) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/configurations/item-input-config?${queryString.stringify(
    params
  )}`;
  const headers = getAuthorizedHeader();
  const requestOptions = {
    method: 'GET',
    headers,
  };
  return fetchWithErrorHandling(requestUrl, requestOptions);
};

export const getMaterialsByTechnologyWithCache = witchCacheResponse(
  getMaterialsByTechnology,
  ONE_HOUR_IN_SECS
);

let surfaceFinishController = new AbortController();
export const getSurfaceFinishingOptions = async (params) => {
  if (surfaceFinishController) {
    // stop previous call if it's ongoing, only allow for latest call to update FE
    surfaceFinishController.abort();
  }
  surfaceFinishController = new AbortController();
  const signal = surfaceFinishController.signal;
  const requestUrl = `${BACKEND_SERVICE_URL}/configurations/surface-finishing?${queryString.stringify(
    params
  )}`;
  const headers = getAuthorizedHeader();
  const requestOptions = {
    method: 'GET',
    headers,
    signal,
  };
  return fetchWithErrorHandling(requestUrl, requestOptions);
};

export const getSurfaceFinishingOptionsForSupplier = async (params) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/configurations/surface-finishing?${queryString.stringify(
    params
  )}`;
  const headers = getAuthorizedHeader();
  const requestOptions = {
    method: 'GET',
    headers,
  };
  return fetchWithErrorHandling(requestUrl, requestOptions);
};

const surfaceFinishCache = {};
export const getSurfaceFinishingOptionsWithCache = async (params) => {
  if (surfaceFinishController) {
    // stop previous call if it's ongoing, only allow for latest call to update FE
    surfaceFinishController.abort();
  }

  const hashValue = hash(params);
  const key = `getSurfaceFinishingOptions-${hashValue}`;
  let result = surfaceFinishCache[key];
  if (result) {
    return result;
  }

  result = await getSurfaceFinishingOptions(params);
  if (result) {
    surfaceFinishCache[key] = result;
    setTimeout(() => {
      delete surfaceFinishCache[key];
    }, ONE_HOUR_IN_SECS * 1000); // 1 hour
  }

  return result;
};

let surfaceFinishingColorController = new AbortController();
export const getSurfaceFinishColorOptions = async (params) => {
  if (surfaceFinishingColorController) {
    // stop previous call if it's ongoing, only allow for latest call to update FE
    surfaceFinishingColorController.abort();
  }
  surfaceFinishingColorController = new AbortController();
  const signal = surfaceFinishingColorController.signal;
  const requestUrl = `${BACKEND_SERVICE_URL}/configurations/surface-finishing-colors?${queryString.stringify(
    params
  )}`;
  const headers = getAuthorizedHeader();
  const requestOptions = {
    method: 'GET',
    headers,
    signal,
  };
  return fetchWithErrorHandling(requestUrl, requestOptions);
};

export const getSurfaceFinishColorOptionsWithCache = witchCacheResponse(
  getSurfaceFinishColorOptions,
  ONE_HOUR_IN_SECS
);

export const getMaterialColorOptions = async (params) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/configurations/material-colors?${queryString.stringify(
    params
  )}`;
  const headers = getAuthorizedHeader();
  const requestOptions = {
    method: 'GET',
    headers,
  };
  return fetchWithErrorHandling(requestUrl, requestOptions);
};

export const getMaterialColorOptionsWithCache = witchCacheResponse(
  getMaterialColorOptions,
  ONE_HOUR_IN_SECS
);

export const getDefaultAnodizingType = async () => {
  const requestUrl = `${BACKEND_SERVICE_URL}/user-settings/default-anodizing-type`;
  const headers = getAuthorizedHeader();
  const requestOptions = {
    method: 'GET',
    headers,
  };
  return fetchWithErrorHandling(requestUrl, requestOptions);
};

export const getDefaultAnodizingTypeWithCache = witchCacheResponse(
  getDefaultAnodizingType,
  ONE_HOUR_IN_SECS
);

export const getSelectColorSurfaces = async (params) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/configurations/select-color-surfaces?${queryString.stringify(
    params
  )}`;
  return fetchWithErrorHandling(requestUrl, {
    method: 'GET',
    headers: getAuthorizedHeader(),
  });
};

export const getSelectColorSurfacesWithCache = witchCacheResponse(
  getSelectColorSurfaces,
  ONE_HOUR_IN_SECS
);

export const getMaterialInfo = async (params) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/configurations/material-info?${queryString.stringify(
    params
  )}`;
  return fetchWithErrorHandling(requestUrl, {
    method: 'GET',
    headers: getAuthorizedHeader(),
  });
};

export const getMaterialInfoWithCache = witchCacheResponse(
  getMaterialInfo,
  ONE_HOUR_IN_SECS
);

export const getAllConfigurations = async () => {
  const requestUrl = `${BACKEND_SERVICE_URL}/configurations`;
  return fetchWithErrorHandling(requestUrl, {
    method: 'GET',
    headers: getAuthorizedHeader(),
  });
};

export const getConfigByKey = async (key) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/configurations?key=${key}`;
  return fetchWithErrorHandling(requestUrl, {
    method: 'GET',
    headers: getAuthorizedHeader(),
  });
};

export const updateConfiguration = async (body) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/configurations`;
  return fetchWithErrorHandling(requestUrl, {
    method: 'POST',
    headers: getAuthorizedHeader(),
    body: JSON.stringify(body),
  });
};

let currentController = new AbortController();
export const getSupportedFileTypes = async (params) => {
  if (currentController) {
    // stop previous call if it's ongoing, only allow for latest call to update FE
    currentController.abort();
  }
  currentController = new AbortController();
  const signal = currentController.signal;
  const requestUrl = `${BACKEND_SERVICE_URL}/configurations/supported-file-types?${queryString.stringify(
    params
  )}`;
  const headers = getAuthorizedHeader();
  const requestOptions = {
    method: 'GET',
    headers,
    signal,
  };
  return fetchWithErrorHandling(requestUrl, requestOptions);
};

export const getSupportedFileTypesWithCache = witchCacheResponse(
  getSupportedFileTypes,
  12 * 60 * 60 // 12 hours in seconds
);

export const getAllFileTypesSupport = async () => {
  const requestUrl = `${BACKEND_SERVICE_URL}/configurations/supported-file-types?allSupportedTypes=true`;
  const headers = getAuthorizedHeader();
  const requestOptions = {
    method: 'GET',
    headers,
  };
  return fetchWithErrorHandling(requestUrl, requestOptions);
};

export const getFeatureFlags = async (params) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/configurations/feature-flags?${queryString.stringify(
    params
  )}`;
  return fetchWithErrorHandling(requestUrl, {
    method: 'GET',
    headers: getAuthorizedHeader(),
  });
};

export const getVerifyItemsChecklist = async (params) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/configurations/verify-items-checklist?${queryString.stringify(
    params
  )}`;
  return fetchWithErrorHandling(requestUrl, {
    method: 'GET',
    headers: getAuthorizedHeader(),
  });
};

export const getPartApplication = async (params) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/configurations/part-application?${queryString.stringify(
    params
  )}`;
  return fetchWithErrorHandling(requestUrl, {
    method: 'GET',
    headers: getAuthorizedHeader(),
  });
};

export const getOMSBlacklist = async () => {
  let data = await getConfigByKey('OMS_BLACKLIST');
  if (data?.value) {
    data.value.sort((a, b) => new Date(b.dateAdded) - new Date(a.dateAdded));
  }
  return data?.value || [];
};

export const getSurfaceFinishNilMappingApi = async () => {
  const requestUrl = `${BACKEND_SERVICE_URL}/configurations/surface-finish-nil-mapping`;
  return fetchWithErrorHandling(requestUrl, {
    method: 'GET',
    headers: getAuthorizedHeader(),
  });
};
