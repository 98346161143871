import React, { useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useQuery } from 'react-query';

import { makeStyles } from '@material-ui/core/styles';

import {
  Dashboard as DashboardIcon,
  AddBoxOutlined as AddBoxOutlinedIcon,
  Airplay as AirplayIcon,
  AnnouncementOutlined as AnnouncementOutlinedIcon,
  AssignmentOutlined as AssignmentOutlinedIcon,
  AssignmentTurnedIn as AssignmentTurnedInIcon,
  Build as BuildIcon,
  CheckCircle as CheckCircleIcon,
  Description as DescriptionIcon,
  ExitToApp as ExitToAppIcon,
  Extension as ExtensionIcon,
  ImageSearch as ImageSearchIcon,
  LocalShipping as LocalShippingIcon,
  MailOutline as MailOutlineIcon,
  MonetizationOn as MonetizationOnIcon,
  People as PeopleIcon,
  PersonAdd as PersonAddIcon,
  Receipt as ReceiptIcon,
  SettingsOutlined as SettingsOutlinedIcon,
  ShoppingCart as ShoppingCartIcon,
  SportsEsports as SportsEsportsIcon,
  ViewList as ViewListIcon,
} from '@material-ui/icons';

import {
  Avatar,
  Badge,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Typography,
} from '@material-ui/core';

import CountrySelectorPopup from './popups/CountrySelectorPopup';
import ResponsiveSidebar from './ResponsiveSidebar';
import { FlexColumn, FlexRow } from './layouts/FlexLayouts';

import {
  getUnSeenUserInstantQuotesLog,
  updateUnSeenToSeenLog,
} from '../apis/userInstantQuotesLogs';

// Import actions
import { getTotalRequestQuote, logoutUser, setUnitType } from '../actions';
import { getSubmittedProjectOrderReadyCountAction } from '../actions/orderReady';

import { getUserInfo } from '../apis/userApi';
import { getAllProjectOrderReady } from '../apis/orderReadyApi';
import {
  getUnSeenPpeFeedbackLog,
  updatePpeFeedbackToHasSeen,
} from '../apis/ppeFeedbackApi';
import {
  getUnSeenBrevoFailedTracking,
  updateBrevoFailedTrackingToHasSeen,
} from '../apis/brevoApi';
import {
  getSupplierLeadTimeUnseenCountApi,
  updateLeadTimeFeedbackToHasSeen,
} from '../apis/supplierFeedbackApi';

import { isAdminOrHigherRole } from '../utils/roleUtils';
import { isEmptyValue } from '../utils/commonUtils';
import { isInvoiceProcessed, isLegacyInvoice } from '../utils/orderReadyUtils';

import { getUserSelector } from '../selectors/userSelector';

import { ROLE_TYPES } from '../constants';
import { ORDER_READY_STATUS } from '../constants/orderReadyConstants';

import { colors } from '../palette';

// -------------------------------------------------------------------------------------------------

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  profile: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0.8rem',
  },
  companyName: {
    marginTop: theme.spacing(2),
  },
  sidebarMenu: {
    backgroundColor: colors.blue050,
    color: 'white',
    '&:hover': {
      backgroundColor: colors.blue050,
      color: 'white',
    },
  },
  sidebarTransparent: {
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: colors.blue050,
      color: 'white',
    },
  },
  icon: {
    display: 'flex',
    marginRight: 32,
    color: colors.fontLightGrey,
  },
  imageIcon: {
    display: 'flex',
    height: 'inherit',
    width: 'inherit',
  },
  countrySelector: {
    margin: '0px 0px',
    padding: '0px 30px',
  },
  secondBadge: {
    '& .MuiBadge-badge': {
      right: 30,
      backgroundColor: colors.secondaryOrange,
      color: colors.fontWhite,
    },
  },
  thirdBadge: {
    '& .MuiBadge-badge': {
      top: 24,
      right: 0,
      backgroundColor: colors.secondaryOrange,
      color: colors.fontWhite,
    },
  },
}));

function changeColor(menu, selectedMenu) {
  if (menu === selectedMenu) {
    return useStyles().sidebarMenu;
  } else return useStyles().sidebarTransparent;
}

export function Sidebar(props) {
  const classes = useStyles();

  const dispatch = useDispatch();

  const { location } = props;

  const user = useSelector(getUserSelector);
  const unitType = useSelector((state) => state.auth.user.unitType);
  const noRequestQuotes = useSelector((state) => state.items?.noRequestQuotes);
  const submittedOrderReadyCount = useSelector(
    (state) => state.orderReady.submittedCount
  );
  const profilePic = useSelector(
    (state) => state.editProfile.profilePic || state.auth.user.profilePic
  );

  const [selectedMenu, setSelectedMenu] = React.useState(location.pathname);
  const [userDetail, setUserDetail] = React.useState(null);

  const role = user?.role;
  const userID = user?.userID;

  const { data: unSeenInstantQuoteLog, refetch: refetchUnSeenInstantQuoteLog } =
    useQuery(['getUserInstantQuotesLog', user?.userID], () => {
      if (isAdminOrHigherRole(role)) {
        return getUnSeenUserInstantQuotesLog(user?.userID);
      }

      return undefined;
    });

  const {
    data: unSeenPpeFeedbackLog = [],
    refetch: refetchUnSeenPpeFeedbackLog,
  } = useQuery(['getUnSeenPpeFeedbackLog', user?.userID, role], () => {
    if (isAdminOrHigherRole(role)) {
      return getUnSeenPpeFeedbackLog(user?.userID);
    }

    return undefined;
  });

  const { data: orderReadyNotStartedOrDelivered } = useQuery(
    ['getAllProjectOrderReady', ORDER_READY_STATUS.NOT_STARTED, role],
    async () => {
      if (isAdminOrHigherRole(role)) {
        return getAllProjectOrderReady({
          status: [
            ORDER_READY_STATUS.NOT_STARTED,
            ORDER_READY_STATUS.DELIVERED,
          ],
        });
      }

      return undefined;
    }
  );

  const { data: unSeenBrevoFailedLog, refetch: refetchUnSeenBrevoFailedLog } =
    useQuery(['getUnSeenBrevoFailedTracking', user?.userID, role], () => {
      if (isAdminOrHigherRole(role)) {
        return getUnSeenBrevoFailedTracking(user?.userID);
      }

      return undefined;
    });

  const {
    data: unSeenSupplierLeadTimeFeedbackLog,
    refetch: refetchUnSeenSupplierLeadTimeFeedbackLog,
  } = useQuery(['getSupplierLeadTimeUnseenCountApi', userID], () => {
    if (isAdminOrHigherRole(role)) {
      return getSupplierLeadTimeUnseenCountApi(userID);
    }
    return undefined;
  });

  const { orderReadyNotStarted, orderReadyDelivered } = useMemo(() => {
    const accumulator = { orderReadyNotStarted: [], orderReadyDelivered: [] };
    if (isEmptyValue(orderReadyNotStartedOrDelivered)) {
      return accumulator;
    }
    return orderReadyNotStartedOrDelivered?.reduce((acc, orderReady) => {
      if (orderReady.status === ORDER_READY_STATUS.DELIVERED) {
        acc.orderReadyDelivered.push(orderReady);
      } else {
        acc.orderReadyNotStarted.push(orderReady);
      }
      return acc;
    }, accumulator);
  }, [orderReadyNotStartedOrDelivered]);

  const orderReadyDeliveredMissingInvoice = useMemo(() => {
    return (
      orderReadyDelivered?.filter(
        (or) =>
          !isLegacyInvoice(or.createdDate) &&
          isEmptyValue(or.itemOrderReadyList[0].supplierInvoiceUrl)
      ).length || 0
    );
  }, [orderReadyDelivered]);

  const orderReadyDeliveredUnreconciledInvoice = useMemo(() => {
    return (
      orderReadyDelivered?.filter(
        (or) => !isInvoiceProcessed(or.createdDate, or.invoiceSentToHubdoc)
      ).length - orderReadyDeliveredMissingInvoice || 0
    );
  }, [orderReadyDelivered, orderReadyDeliveredMissingInvoice]);

  const totalORMNotStartedAfterRemindedEmail = useMemo(() => {
    return orderReadyNotStarted?.filter(
      (order) => order.brevoReminderOrderDeliveryDueSoon
    ).length;
  }, [orderReadyNotStarted]);

  function getUserDetail() {
    if (isEmptyValue(userID)) {
      return;
    }

    getUserInfo(userID).then(setUserDetail);
  }

  useEffect(() => {
    getUserDetail();
    if (isAdminOrHigherRole(role)) {
      dispatch(getSubmittedProjectOrderReadyCountAction());
      dispatch(getTotalRequestQuote());
    }
  }, [userID, role]);

  useEffect(() => {
    const path = location.pathname.split('/');
    const isUrlVacAdmin = path[3] === 'vac';
    // update the redux unit type if admin without a vac url
    if (!isUrlVacAdmin && userDetail && unitType !== userDetail?.unitType) {
      dispatch(setUnitType(userDetail.unitType));
    }
  }, [location.pathname, userDetail?.unitType]);

  const handleSelectedMenu = async (newMenu) => {
    setSelectedMenu((prevMenu) => {
      const INSTANT_QUOTES_LOGS_TAB = '/instant-quotes-logs';
      const GENERATE_RFQ_EMAIL_TAB = '/generate-rfq';
      if (
        prevMenu === INSTANT_QUOTES_LOGS_TAB &&
        newMenu !== INSTANT_QUOTES_LOGS_TAB
      ) {
        updateUnSeenToSeenLog().then(() => {
          refetchUnSeenInstantQuoteLog();
        });
        updatePpeFeedbackToHasSeen().then(() => {
          refetchUnSeenPpeFeedbackLog();
        });
        updateLeadTimeFeedbackToHasSeen().then(
          refetchUnSeenSupplierLeadTimeFeedbackLog
        );
      }
      if (
        prevMenu === GENERATE_RFQ_EMAIL_TAB &&
        newMenu !== GENERATE_RFQ_EMAIL_TAB
      ) {
        updateBrevoFailedTrackingToHasSeen().then(refetchUnSeenBrevoFailedLog);
      }
      return newMenu;
    });
  };

  const getOrderReadyTooltipMessage = () => {
    const submittedOrmText = `${submittedOrderReadyCount} ORM${
      submittedOrderReadyCount > 1 ? 's' : ''
    } to be reviewed`;
    const notStartedOrmText = `${totalORMNotStartedAfterRemindedEmail} ORM${
      totalORMNotStartedAfterRemindedEmail > 1 ? 's' : ''
    } due soon but 'Not Started'. ORM reminder email sent!`;
    const undeliveredInvoiceText = `${orderReadyDeliveredUnreconciledInvoice} ORM${
      orderReadyDeliveredUnreconciledInvoice > 1 ? 's' : ''
    } have an unreconciled invoice`;
    const missingInvoiceText = `${orderReadyDeliveredMissingInvoice} ORM${
      orderReadyDeliveredMissingInvoice > 1 ? 's' : ''
    } missing an invoice`;

    const messages = [];

    if (submittedOrderReadyCount > 0) {
      messages.push(submittedOrmText);
    }
    if (totalORMNotStartedAfterRemindedEmail > 0) {
      messages.push(notStartedOrmText);
    }
    if (orderReadyDeliveredUnreconciledInvoice > 0) {
      messages.push(undeliveredInvoiceText);
    }
    if (orderReadyDeliveredMissingInvoice > 0) {
      messages.push(missingInvoiceText);
    }

    if (messages.length > 0) {
      if (messages.length === 1) {
        return messages[0];
      }
      return (
        <ul style={{ paddingLeft: '1rem' }}>
          {messages.map((msg, index) => (
            <li key={index}>{msg}</li>
          ))}
        </ul>
      );
    }

    return '';
  };

  const getInstantQuotesLogsTooltipMessage = () => {
    const unseenInstantQuotesLogText = `Unseen Instant Quotes Logs: ${unSeenInstantQuoteLog?.totalUnSeenLog}`;
    const unSeenPpeFeedbackLogText = `Unseen PPE Supplier Feedback: ${unSeenPpeFeedbackLog?.totalUnseenLog}`;
    const unSeenLeadTimeFeedbackLogText = `Unseen Lead Time Supplier Feedback: ${unSeenSupplierLeadTimeFeedbackLog?.totalUnSeenLog}`;
    const dataToDisplay = [];
    if (unSeenInstantQuoteLog?.totalUnSeenLog > 0) {
      dataToDisplay.push(unseenInstantQuotesLogText);
    }
    if (unSeenPpeFeedbackLog?.totalUnseenLog > 0) {
      dataToDisplay.push(unSeenPpeFeedbackLogText);
    }
    if (unSeenSupplierLeadTimeFeedbackLog?.totalUnSeenLog > 0) {
      dataToDisplay.push(unSeenLeadTimeFeedbackLogText);
    }
    if (dataToDisplay.length > 1) {
      return (
        <ul style={{ paddingLeft: '1rem' }}>
          {dataToDisplay.map((msg, index) => (
            <li key={index}>{msg}</li>
          ))}
        </ul>
      );
    }
    if (dataToDisplay.length === 1) {
      return dataToDisplay[0];
    }
    return '';
  };

  const emptyDrawer = (
    <FlexColumn
      style={{
        height: '100%',
        justifyContent: 'space-between',
      }}
    >
      <List>{renderProfileListItem()}</List>
      <List>{renderLogoutListItem()}</List>
    </FlexColumn>
  );

  const adminDrawer = (
    <div>
      <List>
        {renderProfileListItem()}
        <Divider />
        {renderCountryCurrencySelectorPopup()}
        <Divider />
        {renderAdminDashboard()}
        {renderManageUsersListItem()}
        {renderCreateNewUserListItem()}
        {renderManageProjectsListItem()}
        {renderManageItemsListItem()}
        <ListItem
          button
          component={Link}
          to='/verify-parts'
          className={changeColor('/verify-parts', selectedMenu)}
          onClick={() => {
            handleSelectedMenu('/verify-parts');
          }}
        >
          <ListItemIcon>
            <CheckCircleIcon />
          </ListItemIcon>
          <ListItemText primary='Verify Items' data-cy='verify-parts-sidebar' />
        </ListItem>
        {renderManageQuotesListItem()}
        {renderVerifyQuotesListItem()}
        <ListItem
          button
          component={Link}
          to='/accepted-orders'
          className={changeColor('/accepted-orders', selectedMenu)}
          onClick={() => {
            handleSelectedMenu('/accepted-orders');
          }}
        >
          <ListItemIcon>
            <MonetizationOnIcon />
          </ListItemIcon>
          <ListItemText primary='Accepted Quotes' />
        </ListItem>
        <ListItem
          button
          component={Link}
          to='/order-ready-management'
          className={changeColor('/order-ready-management', selectedMenu)}
          onClick={() => {
            handleSelectedMenu('/order-ready-management');
          }}
        >
          <ListItemIcon>
            <MonetizationOnIcon />
          </ListItemIcon>
          <Tooltip title={getOrderReadyTooltipMessage()} arrow>
            <Badge
              badgeContent={submittedOrderReadyCount}
              color='error'
              style={{ maxWidth: '8.5rem' }}
            >
              <Badge
                badgeContent={totalORMNotStartedAfterRemindedEmail}
                style={{ maxWidth: '8.5rem' }}
                className={classes.secondBadge}
              >
                <Badge
                  badgeContent={orderReadyDeliveredMissingInvoice ? '$$' : 0}
                  style={{ maxWidth: '8.5rem' }}
                  className={classes.thirdBadge}
                >
                  <ListItemText primary='Order Ready Management' />
                </Badge>
              </Badge>
            </Badge>
          </Tooltip>
        </ListItem>
        {renderMultiCheckoutTrackingListItem()}
        <ListItem
          button
          component={Link}
          to='/generate-rfq'
          className={changeColor('/generate-rfq', selectedMenu)}
          onClick={() => {
            handleSelectedMenu('/generate-rfq');
          }}
        >
          <ListItemIcon>
            <MailOutlineIcon />
          </ListItemIcon>
          <FlexRow style={{ alignItems: 'flex-start' }}>
            <ListItemText primary='Generate RFQ Email' />
            <Tooltip
              title={
                unSeenBrevoFailedLog?.totalUnseenLog
                  ? `${unSeenBrevoFailedLog?.totalUnseenLog} Unseen Brevo Failed Logs`
                  : ''
              }
              arrow
            >
              <Badge
                badgeContent={unSeenBrevoFailedLog?.totalUnseenLog}
                color='error'
                style={{ maxWidth: '7.5rem' }}
              />
            </Tooltip>
          </FlexRow>
        </ListItem>
        <ListItem
          button
          component={Link}
          to='/generate-po-automation'
          className={changeColor('/generate-po-automation', selectedMenu)}
          onClick={() => {
            handleSelectedMenu('/generate-po-automation');
          }}
        >
          <ListItemIcon>
            <MailOutlineIcon />
          </ListItemIcon>
          <ListItemText primary='Generate PO Email' />
        </ListItem>
        <ListItem
          button
          component={Link}
          to='/generation-tools'
          className={changeColor('/generation-tools', selectedMenu)}
          onClick={() => {
            handleSelectedMenu('/generation-tools');
          }}
        >
          <ListItemIcon>
            <BuildIcon />
          </ListItemIcon>
          <ListItemText primary='Generation Tools' />
        </ListItem>
        <Divider />
        <ListItem
          button
          component={Link}
          to='/control-panel'
          className={changeColor('/control-panel', selectedMenu)}
          onClick={() => {
            handleSelectedMenu('/control-panel');
          }}
        >
          <ListItemIcon>
            <SportsEsportsIcon />
          </ListItemIcon>
          <ListItemText primary='Control Panel' />
        </ListItem>
        <ListItem
          button
          component={Link}
          to='/instant-quotes-logs'
          className={changeColor('/instant-quotes-logs', selectedMenu)}
          onClick={() => {
            handleSelectedMenu('/instant-quotes-logs');
          }}
        >
          <ListItemIcon>
            <AirplayIcon />
          </ListItemIcon>
          <Tooltip title={getInstantQuotesLogsTooltipMessage()} arrow>
            <Badge
              badgeContent={unSeenInstantQuoteLog?.totalUnSeenLog}
              color='error'
              style={{ maxWidth: '8.5rem' }}
            >
              <Badge
                badgeContent={unSeenPpeFeedbackLog?.totalUnseenLog}
                style={{ maxWidth: '8.5rem' }}
                className={classes.secondBadge}
              >
                <Badge
                  badgeContent={
                    unSeenSupplierLeadTimeFeedbackLog?.totalUnSeenLog
                  }
                  style={{ maxWidth: '8.5rem' }}
                  className={classes.thirdBadge}
                >
                  <ListItemText primary='Instant Quotes Logs' />
                </Badge>
              </Badge>
            </Badge>
          </Tooltip>
        </ListItem>
        <Divider />
        <ListItem
          button
          component={Link}
          to='/manage-invoices'
          className={changeColor('/manage-invoices', selectedMenu)}
          onClick={() => {
            handleSelectedMenu('/manage-invoices');
          }}
        >
          <ListItemIcon>
            <DescriptionIcon />
          </ListItemIcon>
          <ListItemText primary='Manage Invoices' />
        </ListItem>
        <ListItem
          button
          component={Link}
          to='/manage-delivery-orders'
          className={changeColor('/manage-delivery-orders', selectedMenu)}
          onClick={() => {
            handleSelectedMenu('/manage-delivery-orders');
          }}
        >
          <ListItemIcon>
            <LocalShippingIcon />
          </ListItemIcon>
          <ListItemText primary='Manage DOs' />
        </ListItem>
        <ListItem
          button
          component={Link}
          to='/manage-receipts'
          className={changeColor('/manage-receipts', selectedMenu)}
          onClick={() => {
            handleSelectedMenu('/manage-receipts');
          }}
        >
          <ListItemIcon>
            <ReceiptIcon />
          </ListItemIcon>
          <ListItemText primary='Manage Receipts' />
        </ListItem>
        <Divider variant='middle' />
        <ListItem
          button
          component={Link}
          to='/'
          className={changeColor('/', selectedMenu)}
          onClick={() => {
            handleSelectedMenu('/');
          }}
        >
          <ListItemIcon>
            <ImageSearchIcon />
          </ListItemIcon>
          <ListItemText primary='Browse Parts' />
        </ListItem>
        <ListItem
          button
          component={Link}
          to='/orders'
          className={changeColor('/orders', selectedMenu)}
          onClick={() => {
            handleSelectedMenu('/orders');
          }}
        >
          <ListItemIcon>
            <AssignmentOutlinedIcon />
          </ListItemIcon>
          <ListItemText primary='My Quotes' data-cy='my-quotes-sidebar' />
        </ListItem>
        <ListItem
          button
          component={Link}
          to='/supplier/instant-quote'
          className={changeColor('/supplier/instant-quote', selectedMenu)}
          onClick={() => {
            handleSelectedMenu('/supplier/instant-quote');
          }}
        >
          <ListItemIcon>
            <SettingsOutlinedIcon />
          </ListItemIcon>
          <ListItemText primary='Price Predictor' />
        </ListItem>
        <ListItem
          button
          component={Link}
          to='/manage-ppe-feedback'
          className={changeColor('/manage-ppe-feedback', selectedMenu)}
          onClick={() => {
            handleSelectedMenu('/manage-ppe-feedback');
          }}
        >
          <ListItemIcon>
            <AnnouncementOutlinedIcon />
          </ListItemIcon>
          <ListItemText primary='Manage PPE Feedback' />
        </ListItem>
        <ListItem
          button
          component={Link}
          to='/make-order'
          className={changeColor('/make-order', selectedMenu)}
          onClick={() => {
            handleSelectedMenu('/make-order');
          }}
        >
          <ListItemIcon>
            <AddBoxOutlinedIcon />
          </ListItemIcon>
          <ListItemText primary='Create Project' />
        </ListItem>
        <ListItem
          button
          component={Link}
          to='/user-faqs'
          className={changeColor('/user-faqs', selectedMenu)}
          onClick={() => {
            handleSelectedMenu('/user-faqs');
          }}
        >
          <ListItemIcon>
            <AssignmentTurnedInIcon />
          </ListItemIcon>
          <ListItemText primary='User FAQs' />
        </ListItem>
        {renderLogoutListItem()}
      </List>
    </div>
  );

  const reviewerDrawer = (
    <FlexColumn
      style={{
        height: '100%',
      }}
    >
      <List>
        {renderProfileListItem()}
        <Divider />
        <ListItem button component={Link} to='/manage-items'>
          <ListItemIcon>
            <ExtensionIcon />
          </ListItemIcon>
          <ListItemText primary='Manage Items' />
        </ListItem>
        <ListItem button component={Link} to='/verify-parts'>
          <ListItemIcon>
            <CheckCircleIcon />
          </ListItemIcon>
          <ListItemText primary='Verify Items' />
        </ListItem>
        <ListItem button component={Link} to='/generation-tools'>
          <ListItemIcon>
            <BuildIcon />
          </ListItemIcon>
          <ListItemText primary='Generation Tools' />
        </ListItem>
        <Divider variant='middle' />
        <ListItem button component={Link} to='/'>
          <ListItemIcon>
            <ImageSearchIcon />
          </ListItemIcon>
          <ListItemText primary='Browse Parts' />
        </ListItem>
      </List>
      <span style={{ flex: '1 auto' }} />
      <div style={{ marginBottom: '1rem' }}>{renderLogoutListItem()}</div>
    </FlexColumn>
  );

  const rocketManDrawer = (
    <FlexColumn
      style={{
        height: '100%',
      }}
    >
      <List>
        {renderProfileListItem()}
        <Divider variant='fullWidth' />
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <ListItem button component={Link} to='/manage-items'>
            <ListItemIcon>
              <ExtensionIcon />
            </ListItemIcon>
            <Badge
              badgeContent={noRequestQuotes}
              color='error'
              style={{ maxWidth: '7.5rem' }}
            >
              <ListItemText primary='Manage Items' />
            </Badge>
          </ListItem>
          <Divider variant='fullWidth' />
          <ListItem button component={Link} to='/'>
            <ListItemIcon>
              <ImageSearchIcon />
            </ListItemIcon>
            <ListItemText primary='Browse Parts' />
          </ListItem>
          <ListItem
            button
            component={Link}
            to='/supplier/instant-quote'
            className={changeColor('Price Predictor', selectedMenu)}
            onClick={() => {
              setSelectedMenu('Price Predictor');
            }}
          >
            <ListItemIcon>
              <SettingsOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary='Price Predictor' />
          </ListItem>
        </div>
      </List>
      <span style={{ flex: '1 auto' }} />
      <div style={{ marginBottom: '1rem' }}>{renderLogoutListItem()}</div>
    </FlexColumn>
  );

  const salesConsultantDrawer = (
    <FlexColumn
      style={{
        height: '100%',
        justifyContent: 'space-between',
      }}
    >
      <List>
        {renderProfileListItem()}
        <Divider />
        {renderCountryCurrencySelectorPopup()}
        <Divider />
        {renderManageUsersListItem()}
        {renderCreateNewUserListItem()}
        {renderManageProjectsListItem()}
        {renderManageItemsListItem()}
        {renderManageQuotesListItem()}
        {renderVerifyQuotesListItem()}
        {renderMultiCheckoutTrackingListItem()}
      </List>
      <List>{renderLogoutListItem()}</List>
    </FlexColumn>
  );

  const getDrawer = (role) => {
    switch (role) {
      case ROLE_TYPES.REVIEWER:
        return reviewerDrawer;
      case ROLE_TYPES.ROCKET_MAN:
        return rocketManDrawer;
      case ROLE_TYPES.SALES_CONSULTANT:
        return salesConsultantDrawer;
      case ROLE_TYPES.ADMIN:
      case ROLE_TYPES.SUPER_ADMIN:
        return adminDrawer;
      default:
        console.warn(`No drawer defined for role=${role}`);
        return emptyDrawer;
    }
  };

  return (
    <ResponsiveSidebar drawerWidth={drawerWidth} Content={props.children}>
      {getDrawer(role)}
    </ResponsiveSidebar>
  );

  function renderCountryCurrencySelectorPopup() {
    return (
      <ListItem
        style={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <CountrySelectorPopup disabled={false} />
      </ListItem>
    );
  }

  function renderProfileListItem() {
    return (
      <ListItem
        button
        className={classes.profile}
        component={Link}
        to='/profile'
      >
        <Avatar src={profilePic} className={classes.large} />
        <Typography className={classes.companyName}>{user?.name}</Typography>
      </ListItem>
    );
  }

  function renderAdminDashboard() {
    return (
      <ListItem
        button
        component={Link}
        to='/dashboard'
        className={changeColor('/dashboard', selectedMenu)}
        onClick={() => {
          handleSelectedMenu('/dashboard');
        }}
      >
        <ListItemIcon>
          <DashboardIcon />
        </ListItemIcon>
        <ListItemText primary='Dashboard' data-cy='admin-dashboard' />
      </ListItem>
    );
  }

  function renderManageUsersListItem() {
    return (
      <ListItem
        button
        component={Link}
        to='/manage-users'
        className={changeColor('/manage-users', selectedMenu)}
        onClick={() => {
          handleSelectedMenu('/manage-users');
        }}
      >
        <ListItemIcon>
          <PeopleIcon />
        </ListItemIcon>
        <ListItemText primary='Manage Users' data-cy='manage-users' />
      </ListItem>
    );
  }

  function renderLogoutListItem() {
    return (
      <ListItem button onClick={() => dispatch(logoutUser())}>
        <ListItemIcon>
          <ExitToAppIcon />
        </ListItemIcon>
        <ListItemText primary='Logout' data-cy='logout-btn' />
      </ListItem>
    );
  }

  function renderCreateNewUserListItem() {
    return (
      <ListItem
        button
        component={Link}
        to='/admin/signup'
        className={changeColor('/admin/signup', selectedMenu)}
        onClick={() => {
          handleSelectedMenu('/admin/signup');
        }}
      >
        <ListItemIcon>
          <PersonAddIcon />
        </ListItemIcon>
        <ListItemText primary='Create New User' />
      </ListItem>
    );
  }

  function renderManageItemsListItem() {
    let tooltipTitle = '';
    if (role !== ROLE_TYPES.SALES_CONSULTANT) {
      tooltipTitle =
        noRequestQuotes > 1
          ? `There are ${noRequestQuotes} items requested for quote`
          : noRequestQuotes === 1
            ? 'There is 1 item requested for quote'
            : '';
    }

    return (
      <ListItem
        className={changeColor('/manage-items', selectedMenu)}
        button
        component={Link}
        to='/manage-items'
        onClick={() => {
          handleSelectedMenu('/manage-items');
        }}
      >
        <ListItemIcon>
          <ExtensionIcon />
        </ListItemIcon>
        <Tooltip title={tooltipTitle}>
          <Badge
            badgeContent={noRequestQuotes}
            color='error'
            style={{ maxWidth: '7.5rem' }}
          >
            <ListItemText primary='Manage Items' />
          </Badge>
        </Tooltip>
      </ListItem>
    );
  }

  function renderManageProjectsListItem() {
    return (
      <ListItem
        button
        component={Link}
        to='/manage-projects'
        className={changeColor('/manage-projects', selectedMenu)}
        onClick={() => {
          handleSelectedMenu('/manage-projects');
        }}
      >
        <ListItemIcon>
          <ExtensionIcon />
        </ListItemIcon>
        <ListItemText
          primary='Manage Projects'
          data-cy='manage-project-sidebar'
        />
      </ListItem>
    );
  }

  function renderManageQuotesListItem() {
    return (
      <ListItem
        button
        component={Link}
        to='/manage-orders'
        className={changeColor('/manage-orders', selectedMenu)}
        onClick={() => {
          handleSelectedMenu('/manage-orders');
        }}
      >
        <ListItemIcon>
          <ViewListIcon />
        </ListItemIcon>
        <ListItemText primary='Manage Quotes' />
      </ListItem>
    );
  }

  function renderVerifyQuotesListItem() {
    return (
      <ListItem
        button
        component={Link}
        to='/verify-quotes'
        className={changeColor('/verify-quotes', selectedMenu)}
        onClick={() => {
          handleSelectedMenu('/verify-quotes');
        }}
      >
        <ListItemIcon>
          <AssignmentTurnedInIcon />
        </ListItemIcon>
        <ListItemText primary='Verify Quotes' data-cy='verify-quotes-sidebar' />
      </ListItem>
    );
  }

  function renderMultiCheckoutTrackingListItem() {
    return (
      <ListItem
        button
        component={Link}
        to='/multi-checkout-tracking'
        className={changeColor('/multi-checkout-tracking', selectedMenu)}
        onClick={() => {
          handleSelectedMenu('/multi-checkout-tracking');
        }}
      >
        <ListItemIcon>
          <ShoppingCartIcon />
        </ListItemIcon>
        <ListItemText
          primary='Multiple Checkout Tracking'
          data-cy='multiple-checkout-tracking-sidebar'
        />
      </ListItem>
    );
  }
}

export default Sidebar;
