import { useQuery } from 'react-query';

import { getItemDetailsApi } from '../apis/itemApi';

import { isEmptyValue } from '../utils/commonUtils';

// -------------------------------------------------------------------------------------------------

const useItemInfo = (itemID) => {
  const { data, refetch } = useQuery(['getItemDetailsApi', itemID], () => {
    if (isEmptyValue(itemID)) {
      return undefined;
    }

    return getItemDetailsApi(itemID);
  });

  return {
    data,
    refetch,
  };
};

export default useItemInfo;
