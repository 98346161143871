import React from 'react';

import { AddCircle } from '@material-ui/icons';

import ManageProjectIcon from '../assets/icons/manage_projects_icon.svg';
import ManageProjectActiveIcon from '../assets/icons/manage_projects_active_icon.svg';
import OrdersIcon from '../assets/icons/orders_icon.svg';
import OrdersActiveIcon from '../assets/icons/orders_active_icon.svg';
import FAQsIcon from '../assets/icons/faqs_icon.svg';
import FAQsActiveIcon from '../assets/icons/faqs_active_icon.svg';
import PartsLibraryIcon from '../assets/icons/parts_library.svg';
import PartsLibraryActiveIcon from '../assets/icons/parts_library_blue.svg';

// --------------------------------------------------------------------------------------------

export const SUPPLIER_SIDEBAR_LINKS = {
  GENERAL: '/',
  DATA_DASHBOARD: '/data-dashboard',
  ORDERS: '/orders',
  ACCEPTED_ORDERS: '/accepted-orders',
  INSTANT_QUOTE: '/supplier/instant-quote',
  FAQ: '/supplier-faq',
  PROFILE: '/profile',
  PARTNERSHIP_AGREEMENT: '/partnership-agreement',
  DFM_CHECKER: '/dfm-checker',
};

export const CUSTOMER_SIDEBAR_TABS = [
  {
    to: '/',
    icon: <AddCircle />,
    text: 'Upload Parts',
    dataCy: 'upload-parts',
    highlight: true,
  },
  {
    id: 'manage-projects-tab',
    to: '/manage-projects',
    text: 'Manage Projects',
    icon: ManageProjectIcon,
    activeIcon: ManageProjectActiveIcon,
    dataCy: 'manage-project-sidebar',
  },
  {
    id: 'order-history-tab',
    to: '/order-history',
    text: 'Order History',
    icon: OrdersIcon,
    activeIcon: OrdersActiveIcon,
    dataCy: 'order-history-sidebar',
  },
  {
    id: 'parts-library-tab',
    to: '/parts-library',
    text: 'Parts Library',
    icon: PartsLibraryIcon,
    activeIcon: PartsLibraryActiveIcon,
    dataCy: 'parts-library-sidebar',
  },
  {
    type: 'spacer',
  },
  {
    id: 'faq-tab',
    to: '/customer-faq',
    text: 'FAQs',
    icon: FAQsIcon,
    activeIcon: FAQsActiveIcon,
  },
];

export const CUSTOMER_SIDEBAR_TABS_REVAMP = [
  {
    to: '/',
    icon: <AddCircle />,
    text: 'Upload Parts',
    dataCy: 'upload-parts',
    highlight: true,
  },
  {
    id: 'manage-projects-tab',
    to: '/manage-projects',
    text: 'Manage Projects',
    icon: ManageProjectIcon,
    activeIcon: ManageProjectActiveIcon,
    dataCy: 'manage-project-sidebar',
  },
  {
    id: 'parts-library-tab',
    to: '/parts-library',
    text: 'Parts Library',
    icon: PartsLibraryIcon,
    activeIcon: PartsLibraryActiveIcon,
    dataCy: 'parts-library-sidebar',
  },
  {
    type: 'spacer',
  },
  {
    id: 'faq-tab',
    to: '/customer-faq',
    text: 'FAQs',
    icon: FAQsIcon,
    activeIcon: FAQsActiveIcon,
  },
];
