import React, { useReducer } from 'react';

import DownloadFileInfoPopup from '../components/popups/DownloadInfoPopup';

import { isEmptyValue } from '../utils/commonUtils';

// -------------------------------------------------------------------------------------------------

function withDownloadFileInfoPopupHOC(WrappedComponent) {
  function WrappedComponentWithHOC(props) {
    const defaultLocalState = {
      open: false,
      downloadFileTracking: [],
      itemID: null,
    };

    const [localState, updateLocalState] = useReducer((prev, next) => {
      if (isEmptyValue(next)) {
        return defaultLocalState;
      }
      return { ...prev, ...next };
    }, defaultLocalState);

    return (
      <>
        <WrappedComponent
          updateDownloadFileInfoPopupHOCState={updateLocalState}
          {...props}
        />
        {localState.open && (
          <DownloadFileInfoPopup
            open={localState.open}
            onClose={() => updateLocalState({ open: false })}
            downloadFileTracking={localState?.downloadFileTracking}
            itemID={localState?.itemID}
          />
        )}
      </>
    );
  }

  // Set the displayName for the HOC
  const wrappedComponentName =
    WrappedComponent.displayName || WrappedComponent.name || 'Component';
  WrappedComponentWithHOC.displayName = `withSignUpPopup(${wrappedComponentName})`;

  return WrappedComponentWithHOC;
}

export default withDownloadFileInfoPopupHOC;
