import React, { useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useQuery } from 'react-query';

import { makeStyles } from '@material-ui/core/styles/index';

import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  DialogActions,
  MenuItem,
} from '@material-ui/core';

import { Close as CloseIcon } from '@material-ui/icons';

import FtrMultiSelectChipDropdown from '../../dropdowns/FtrMultiSelectChipDropdown';
import { FtrBoldText, FtrButton, FtrH5 } from '../../ftr-components';
import { FlexColumn, FlexRow, FlexRowEnd } from '../../layouts/FlexLayouts';
import { FtrTextField } from '../../ftr-components/FtrField';

import { createAlertNotification } from '../../../apis/alertApi';
import {
  getProjectSharedQuoteReceivers,
  createProjectSharedQuoteReceiver,
  shareQuotationForm,
} from '../../../apis/projectApi';

import { validateEmail } from '../../../utils/validators/emailValidator';

import { isEmptyValue } from '../../../utils/commonUtils';

import {
  notifyError,
  notifySuccess,
} from '../../../services/notificationService';

import { colors } from '../../../palette';

// ----------------------------------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  backdrop: {
    backdropFilter: 'blur(3px)',
  },
  dialog: {
    padding: '1.5rem',
    borderRadius: '1.5rem',
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      padding: '1rem',
      borderRadius: '1rem',
      width: 'auto',
    },
  },
  title: {
    display: 'flex',
    fontSize: '22px',
    fontWeight: '700',
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
  },
  closeIcon: {
    position: 'absolute',
    right: '1.5rem',
    top: '1.5rem',
    color: colors.neutral060,
    cursor: 'pointer',
  },
}));

const CustomMenuItem = (item) => {
  return (
    <MenuItem key={item.key ?? item} value={item}>
      <FlexRow>
        <FtrBoldText fontSize='16' style={{ fontWeight: 700 }}>
          {item.name}
        </FtrBoldText>
        |
        <FtrBoldText fontSize='16' style={{ fontWeight: 400 }}>
          {item.email}
        </FtrBoldText>
      </FlexRow>
    </MenuItem>
  );
};

function ShareQuotationFormPopupV2(props) {
  const classes = useStyles();

  const { open, handleClose, userID } = props;

  const multiCheckoutForm = useSelector((state) => state.multiCheckoutForm);

  const quotationIDList =
    multiCheckoutForm?.selectedQuotes?.map((quote) => quote.quotationID) || [];
  const itemIDList =
    multiCheckoutForm?.selectedQuotes?.map((quote) => quote.itemID) || [];
  const shippingMode = multiCheckoutForm?.shippingMode;

  const { data: allProjectSharedQuoteReceivers } = useQuery(
    ['allProjectSharedQuoteReceivers', userID],
    () => getProjectSharedQuoteReceivers(userID)
  );

  const [newReceiverName, setNewReceiverName] = useState('');
  const [newReceiverEmail, setNewReceiverEmail] = useState('');
  const [receiverDisplayList, setReceiverDisplayList] = useState([]); // [{key: 0, text: xx}]
  const [newReceiverNameError, setNewReceiverNameError] = useState(null);
  const [newReceiverEmailError, setNewReceiverEmailError] = useState(null);
  const [allReceiversError, setAllReceiversError] = useState(null);
  const [selectedIDList, setSelectedIDList] = useState([]);
  const [isShareQuoteLoading, setIsShareQuoteLoading] = useState(false);

  useEffect(() => {
    if (!isEmptyValue(allProjectSharedQuoteReceivers)) {
      setReceiverDisplayList(
        allProjectSharedQuoteReceivers.data.map((receiver, idx) => {
          return {
            key: idx,
            text: `${receiver.name} <${receiver.email}>`,
            name: receiver.name,
            email: receiver.email,
          };
        })
      );
      setSelectedIDList(
        allProjectSharedQuoteReceivers.data.map((receiver, idx) => {
          return idx;
        })
      );
    }
  }, [allProjectSharedQuoteReceivers]);

  const unselectedReceivers = useMemo(() => {
    return receiverDisplayList.filter(
      (receiver) => !selectedIDList.includes(receiver.key)
    );
  }, [selectedIDList, receiverDisplayList]);

  const handleAddReceiver = () => {
    if (isEmptyValue(newReceiverName)) {
      setNewReceiverNameError('Name is required');
      return;
    }
    if (isEmptyValue(newReceiverEmail)) {
      setNewReceiverEmailError('Email is required');
      return;
    }
    if (!validateEmail(newReceiverEmail)) {
      setNewReceiverEmailError('Email is invalid');
      return;
    }

    // add to display list
    setReceiverDisplayList([
      ...receiverDisplayList,
      {
        key: receiverDisplayList.length,
        text: newReceiverName + ` <${newReceiverEmail}>`,
        name: newReceiverName,
        email: newReceiverEmail,
      },
    ]);

    // select the newly added receiver
    setSelectedIDList([...selectedIDList, receiverDisplayList.length]);

    // update database with new receiver
    const body = { name: newReceiverName, email: newReceiverEmail };
    createProjectSharedQuoteReceiver(userID, body)
      .then((resp) => {
        if (resp.message === 'ok') {
          notifySuccess('New receiver has been added!');
        } else {
          notifyError('Unable to add new receiver.');
        }
      })
      .catch((e) => console.log(e));

    // resetting the input fields
    setNewReceiverName('');
    setNewReceiverEmail('');
  };

  const onEmailSelectChange = (receiver) => {
    setSelectedIDList(receiver);
    setAllReceiversError(null);
  };

  const handleShareQuotes = () => {
    if (isEmptyValue(selectedIDList)) {
      setAllReceiversError('Receivers cannot be empty!');
      return;
    }

    setAllReceiversError(null);
    // disables button and prevents user from clicking it multiple times
    setIsShareQuoteLoading(true);

    // parsing the list to get name and email
    const finalReceivers = selectedIDList.map((idx) => {
      return receiverDisplayList[idx];
    });

    const body = {
      receiverList: finalReceivers,
      partIdList: itemIDList,
      quotationIDList,
      shippingMode,
      addresses: multiCheckoutForm.addresses,
      paymentType: multiCheckoutForm.paymentType,
      deliveryInfoID: multiCheckoutForm.deliveryInfoID,
    };

    // send the email and CC the sender
    shareQuotationForm(body)
      .then((resp) => {
        setIsShareQuoteLoading(false);
        if (resp.message === 'ok') {
          notifySuccess('Quotes shared!');
          handleClose();
        } else {
          notifyError('Unable to share quotes.');
        }
      })
      .catch((error) => {
        setIsShareQuoteLoading(false);
        notifyError('Unable to share quotes. Please try again later.');
        const alertBody = {
          title: '[FE] Customer share quote failed!',
          errorStack: error.stack,
          additionalInfo: {
            userID,
            body,
          },
        };
        createAlertNotification(alertBody);
      });
  };

  const renderAddNewReceiver = () => {
    return (
      <FlexColumn
        style={{
          marginTop: '16px',
          textAlign: 'left',
          width: '100%',
          boxSizing: 'border-box',
        }}
      >
        <FtrBoldText fontSize='16' style={{ color: colors.neutral080 }}>
          Add new recipient
        </FtrBoldText>
        <Grid
          container
          spacing={2}
          style={{
            display: 'flex',
            alignItems: 'start',
            width: '100%',
            boxSizing: 'border-box',
          }}
        >
          <Grid item xs={12} md={4}>
            <FtrTextField
              title='Recipient Name'
              variant='outlined'
              margin='dense'
              id='recipient-name'
              placeholder=''
              type='text'
              fullWidth
              error={!!newReceiverNameError}
              helperText={newReceiverNameError}
              onFocus={() => {
                setNewReceiverNameError(null);
              }}
              onChange={(evt) => setNewReceiverName(evt.target.value)}
              value={newReceiverName}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FtrTextField
              title='Enter recipient email'
              variant='outlined'
              margin='dense'
              id='recipient-email'
              placeholder=''
              type='text'
              fullWidth
              error={!!newReceiverEmailError}
              helperText={newReceiverEmailError}
              onFocus={() => {
                setNewReceiverEmailError(null);
              }}
              onChange={(evt) => setNewReceiverEmail(evt.target.value)}
              value={newReceiverEmail}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <FtrButton
              variant='outlined'
              size='small'
              color='blue'
              fullWidth
              style={{
                minWidth: 120,
                marginTop: '1.9rem',
              }}
              onClick={handleAddReceiver}
            >
              Add Receiver
            </FtrButton>
          </Grid>
        </Grid>
      </FlexColumn>
    );
  };

  const renderSharingTo = () => {
    return (
      <FlexColumn
        style={{
          marginTop: '1.5rem',
          textAlign: 'left',
        }}
      >
        <FtrBoldText fontSize='18' style={{ color: colors.neutral080 }}>
          Sharing To
        </FtrBoldText>
        <FtrMultiSelectChipDropdown
          id='multi-select-items'
          label='Select recipients'
          itemList={receiverDisplayList}
          unselectedItemList={unselectedReceivers}
          showUnselectedItemList={true}
          selectedItems={selectedIDList}
          customMenuItem={CustomMenuItem}
          onSelect={onEmailSelectChange}
          error={!!allReceiversError}
          errorMessage={allReceiversError}
          searchable
        />
      </FlexColumn>
    );
  };

  const renderActionButtons = () => {
    return (
      <FlexRowEnd
        style={{
          width: '100%',
        }}
      >
        <Box className={classes.space}></Box>
        <FtrButton
          style={{
            marginTop: '9px',
          }}
          variant='contained'
          color='blue'
          onClick={handleShareQuotes}
          disabled={isShareQuoteLoading}
          loading={isShareQuoteLoading}
        >
          Send
        </FtrButton>
      </FlexRowEnd>
    );
  };

  return (
    <Dialog
      maxWidth='md'
      fullWidth
      open={open}
      onClose={handleClose}
      aria-labelledby='share-quote-email-dialog-title'
      BackdropProps={{
        classes: {
          root: classes.backdrop,
        },
      }}
      PaperProps={{ className: classes.dialog }}
    >
      <DialogTitle disableTypography className={classes.title}>
        <FtrH5>Share Quotation</FtrH5>
      </DialogTitle>
      <CloseIcon className={classes.closeIcon} onClick={handleClose} />
      <DialogContent
        style={{
          padding: '0 30px',
          paddingBottom: '2rem',
          textAlign: 'left',
        }}
      >
        <>
          {renderAddNewReceiver()}
          {renderSharingTo()}
        </>
      </DialogContent>
      <DialogActions>{renderActionButtons()}</DialogActions>
    </Dialog>
  );
}

export default ShareQuotationFormPopupV2;
