import { useEffect, useState } from 'react';
import { first } from 'lodash';

import { Chip, Tooltip, Icon, CircularProgress } from '@material-ui/core';
import { HighlightOff as HighlightOffIcon } from '@material-ui/icons';

import CheckIcon from '../icons/CheckIcon';
import WarningIcon from '../icons/WarningIcon';
import { FlexRow } from '../layouts/FlexLayouts';

import { checkSuppliersDims } from '../../apis/rfqEmailSchedulesApi';
import { isEmptyValue } from '../../utils/commonUtils';

function SupplierRfqChip(props) {
  const {
    classes = {},
    disabled = false,
    selectedText = '',
    selectedKeys = [],
    handlePrepareDelete = () => {},
    flatItems = [],
    suggestedSuppliers = [],
    selectedParts = [],
    subTechs = [],
  } = props;

  const [capabilityCheck, setCapabilityCheck] = useState({
    loading: true,
    capability: false,
  });

  useEffect(() => {
    const checkIfSupplierCapable = (supplierId) => {
      if (suggestedSuppliers.includes(supplierId)) {
        setCapabilityCheck({ loading: false, capability: true });
        return;
      }
      if (![selectedParts, supplierId].some(isEmptyValue)) {
        const body = {
          itemIdList: selectedParts,
          subTechs: subTechs,
          supplierId: supplierId,
        };
        checkSuppliersDims(body).then((response) => {
          setCapabilityCheck({
            loading: false,
            capability: response?.capability?.capability_check,
          });
        });
      }
    };
    checkIfSupplierCapable(parseInt(first(selectedKeys)));
  }, []);

  const renderSupplierChipSelected = (args) => {
    const { key = '', label = selectedText } = args ?? {};
    return (
      <Chip
        classes={{
          deleteIcon: classes.chipDeleteIcon,
          deletable: classes.chipDeletable,
        }}
        className={classes.chip}
        key={key}
        label={label}
        disabled={disabled}
        onMouseDown={(event) => event.stopPropagation()}
        deleteIcon={<HighlightOffIcon />}
        onDelete={handlePrepareDelete(first(selectedKeys))}
      />
    );
  };

  return (
    <div className={classes.chips}>
      <FlexRow>
        <div>
          {isEmptyValue(selectedText)
            ? { selectedKeys }?.map((key) => {
                const item = flatItems.find((i) => i.key === key);
                const label = item ? item.text : '';
                if (isEmptyValue(label)) {
                  return null;
                }
                renderSupplierChipSelected({ key: key, label: label });
              })
            : renderSupplierChipSelected()}
        </div>
        <div>
          {capabilityCheck.loading ? (
            <CircularProgress size='1.5rem' />
          ) : capabilityCheck.capability ? (
            <Tooltip title='Supplier capability match'>
              <Icon>
                <CheckIcon />
              </Icon>
            </Tooltip>
          ) : (
            <Tooltip title='Supplier capability mismatch'>
              <Icon>
                <WarningIcon />
              </Icon>
            </Tooltip>
          )}
        </div>
      </FlexRow>
    </div>
  );
}

export default SupplierRfqChip;
