import {
  GET_ITEM_FAILURE,
  GET_ITEM_SUCCESS,
  GET_ITEM_LOADING,
  CREATE_QUOTATION_LOADING,
  CREATE_QUOTATION_SUCCESS,
  CREATE_QUOTATION_FAILURE,
  EDIT_ITEM_FAILURE,
  EDIT_ITEM_LOADING,
  EDIT_ITEM_SUCCESS,
  DELETE_ITEM_FAILURE,
  DELETE_ITEM_LOADING,
  DELETE_ITEM_SUCCESS,
  SAVE_ORDERED_AGAIN_PARTS,
  DELETE_ORDERED_AGAIN_PARTS,
  SAVE_PART_SUMMARY_DETAILS,
  UPDATE_2D_IMAGE_URL,
  UPDATE_UPLOAD_PROJECT_FILES_STEPS,
  RESET_PART_SUMMARY_DETAILS,
  ADD_OR_UPDATE_CAD_PART,
  REMOVE_CAD_PART,
  UPDATE_CAD_PART_IF_EXISTS,
  RESTORE_PARTS,
} from '../actions';
import { LOGOUT_SUCCESS } from '../actions';
import { TWO_D_IMAGE_URLS_KEY } from '../constants';
import { isEmptyValue } from '../utils/commonUtils';

const defaultState = {
  getItemLoading: false,
  getItemSuccess: false,
  getItemFailure: false,
  item: [],
  orderedAgainPartDetails: null,
  formDataAvailable: [],
  uploadProjectFilesSteps: [],
  createQuotationLoading: false,
  createQuotationSuccess: false,
  createQuotationFailure: false,
  editItemFailure: false,
  editItemSuccess: false,
  editItemLoading: false,
  deleteItemLoading: false,
  deleteItemSuccess: false,
  deleteItemFailure: false,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case GET_ITEM_LOADING:
      return {
        ...state,
        getItemLoading: true,
        getItemSuccess: false,
        getItemFailure: false,
      };
    case GET_ITEM_SUCCESS:
      return {
        ...state,
        getItemLoading: false,
        getItemSuccess: true,
        getItemFailure: false,
        item: {
          ...action.item,
          ...action.item.metadata,
        },
      };
    case GET_ITEM_FAILURE:
      return {
        ...state,
        getItemLoading: false,
        getItemSuccess: false,
        getItemFailure: true,
      };
    case CREATE_QUOTATION_LOADING:
      return {
        ...state,
        createQuotationLoading: true,
        createQuotationSuccess: false,
        createQuotationFailure: false,
      };
    case CREATE_QUOTATION_SUCCESS:
      return {
        ...state,
        createQuotationLoading: false,
        createQuotationSuccess: true,
        createQuotationFailure: false,
      };
    case CREATE_QUOTATION_FAILURE:
      return {
        ...state,
        createQuotationLoading: false,
        createQuotationSuccess: false,
        createQuotationFailure: true,
      };
    case EDIT_ITEM_SUCCESS:
      return {
        ...state,
        editItemFailure: false,
        editItemSuccess: true,
        editItemLoading: false,
      };
    case EDIT_ITEM_LOADING:
      return {
        ...state,
        editItemFailure: false,
        editItemSuccess: false,
        editItemLoading: true,
      };
    case EDIT_ITEM_FAILURE:
      return {
        ...state,
        editItemFailure: true,
        editItemSuccess: false,
        editItemLoading: false,
      };
    case DELETE_ITEM_SUCCESS:
      return {
        ...state,
        deleteItemLoading: false,
        deleteItemSuccess: true,
        deleteItemFailure: false,
      };
    case DELETE_ITEM_FAILURE:
      return {
        ...state,
        deleteItemLoading: false,
        deleteItemSuccess: false,
        deleteItemFailure: true,
      };
    case DELETE_ITEM_LOADING:
      return {
        ...state,
        deleteItemLoading: true,
        deleteItemSuccess: false,
        deleteItemFailure: false,
      };
    case SAVE_ORDERED_AGAIN_PARTS:
      return {
        ...state,
        orderedAgainPartDetails: action.orderedAgainPartDetails,
      };
    case RESET_PART_SUMMARY_DETAILS:
      return {
        ...state,
        formDataAvailable: [],
      };
    case RESTORE_PARTS: {
      const restoreParts = action.payload;
      return {
        ...state,
        formDataAvailable: [...restoreParts, ...state.formDataAvailable],
      };
    }
    case ADD_OR_UPDATE_CAD_PART: {
      const { payload } = action;
      const { id } = payload;
      if (isEmptyValue(state.formDataAvailable)) {
        return {
          ...state,
          formDataAvailable: [payload],
        };
      }
      const cadPart = state.formDataAvailable.find((part) => part.id === id);
      if (!cadPart) {
        return {
          ...state,
          formDataAvailable: [...state.formDataAvailable, payload],
        };
      }
      const formDataAvailable = [...state.formDataAvailable];
      return {
        ...state,
        formDataAvailable: formDataAvailable.map((part) => {
          if (part.id === id) {
            return {
              ...part,
              ...payload,
            };
          }
          return part;
        }),
      };
    }
    case UPDATE_CAD_PART_IF_EXISTS: {
      const { payload } = action;
      const { id } = payload;
      if (isEmptyValue(state.formDataAvailable)) {
        return state;
      }
      const cadPart = state.formDataAvailable.find((part) => part.id === id);
      if (!cadPart) {
        return state;
      }
      const formDataAvailable = [...state.formDataAvailable];
      return {
        ...state,
        formDataAvailable: formDataAvailable.map((part) => {
          if (part.id === id) {
            return {
              ...part,
              ...payload,
            };
          }
          return part;
        }),
      };
    }
    case REMOVE_CAD_PART: {
      const id = action.payload;
      return {
        ...state,
        formDataAvailable: state.formDataAvailable.filter(
          (part) => part.id !== id
        ),
      };
    }
    case SAVE_PART_SUMMARY_DETAILS: {
      const newFormData = action.formDataAvailable;
      const existingFormData = [...state.formDataAvailable];
      return {
        ...state,
        formDataAvailable: newFormData.map((newData) => {
          const id = newData.id;
          const existingData = existingFormData.find((data) => data.id === id);
          return {
            ...existingData,
            ...newData,
          };
        }),
      };
    }
    case UPDATE_2D_IMAGE_URL: {
      const { originalS3Key, twoDImageUrl } = action.payload;
      const newFormDataAvailable = [...state.formDataAvailable];
      const item = newFormDataAvailable.find((item) =>
        item.cadPart.includes(originalS3Key)
      );
      if (!item) {
        return state;
      }
      item[TWO_D_IMAGE_URLS_KEY] = [twoDImageUrl];
      return {
        ...state,
        formDataAvailable: newFormDataAvailable,
      };
    }
    case DELETE_ORDERED_AGAIN_PARTS:
      return {
        ...state,
        orderedAgainPartDetails: null,
      };
    case LOGOUT_SUCCESS:
      return defaultState;
    case UPDATE_UPLOAD_PROJECT_FILES_STEPS: {
      return {
        ...state,
        uploadProjectFilesSteps: [...action.payload],
      };
    }
    default:
      return state;
  }
};
