import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { cloneDeep } from 'lodash';
import { useQuery } from 'react-query';

import { makeStyles } from '@material-ui/core/styles/index';

import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  CircularProgress,
} from '@material-ui/core';

import DatePickerWithIcon from '../DatePickerWithIcon';
import BlueButton from '../buttons/BlueButton';
import WhiteButton from '../buttons/WhiteButton';
import { colors } from '../../palette';
import SubDesc from '../SubDesc';
import QualityAccordion from '../accordions/QualityAccordion';
import FinanceNoteInput from '../forms/FinanceNoteInput';
import FinanceEmailInfo from '../info/FinanceEmailInfo';
import ShipmentTrackingIcon from '../icons/ShipmentTrackingIcon';

import { formatTitleDate } from '../../utils/dateTimeUtils';
import { isEmptyValue } from '../../utils/commonUtils';
import { unInvoicedItemsFilter } from '../../utils/multiCheckoutTrackingUtils';

import { getTrackingByID } from '../../apis/multiCheckoutApi';

import useQualityMetrics from '../../hooks/useQualityMetrics';

const useStyles = makeStyles((theme) => ({
  paper: {
    borderRadius: 0,
  },
  space: {
    width: theme.spacing(2),
  },
  invoiceButton: {
    background: colors.redGradient,
    color: colors.fontWhite,
    padding: '0.625rem 0.9375rem',
    borderRadius: '0.3125rem',
    fontSize: '9pt',
    letterSpacing: '0.15em',
    '&:hover': {
      filter: 'brightness(0.8)',
    },
  },
  shipmentInfo: {
    display: 'flex',
    alignItems: 'end',
    flexDirection: 'column',
    fontSize: '10px',
  },
  shipmentRow: {
    display: 'flex',
    columnGap: '2px',
    justifyContent: 'flex-start',
  },
}));

function InvoiceInfoPopup(props) {
  const classes = useStyles();

  const {
    open,
    handleClose = () => {},
    handleSubmit = () => {},
    rowData = {},
  } = props;

  const { data: rowDataInfo, isLoading: isLoadingTracking } = useQuery(
    ['getTrackingByID', rowData.id],
    () => getTrackingByID(rowData.id)
  );

  const hasUnInvoiceItemIDs = rowData.acceptedItems
    .filter(unInvoicedItemsFilter)
    .map((item) => item.itemID);

  const [itemsState, setItemsState] = useState([]);
  const [financeNote, setFinanceNote] = useState('');

  useEffect(() => {
    if (!isEmptyValue(rowDataInfo?.acceptedItemsInfo)) {
      const initialDataItems = rowDataInfo?.acceptedItemsInfo
        .filter((item) => hasUnInvoiceItemIDs.includes(item.itemID))
        .map((item) => {
          return {
            ...item,
            actualCollectionDate: item.actualCollectionDate
              ? new Date(item.actualCollectionDate)
              : new Date(),
            shipmentDate: item.shipmentDate
              ? new Date(item.shipmentDate)
              : null,
          };
        });
      setItemsState(initialDataItems);
    }
  }, [rowDataInfo?.acceptedItemsInfo]);

  useEffect(() => {
    if (rowDataInfo?.financeNote) {
      setFinanceNote(rowDataInfo?.financeNote);
    }
  }, [rowDataInfo?.financeNote]);

  const [
    { combinedItemsQuality },
    { updateQualityInformation, getQualityProperties },
  ] = useQualityMetrics(itemsState);

  const handleChangeDate = (date, index) => {
    const itemsStateClone = cloneDeep(itemsState);
    itemsStateClone[index].actualDeliveryDate = date;
    setItemsState(itemsStateClone);
  };

  // will update all actualDeliveryDates to current date
  const updateActualDeliveryDate = () => {
    const itemsStateClone = cloneDeep(itemsState);
    itemsStateClone.forEach((item) => {
      item.actualDeliveryDate = new Date();
    });
    setItemsState(itemsStateClone);
  };

  const handleUpdateAllDate = (date, selectedItem) => {
    setItemsState((prevData) => {
      return prevData.map((item) => {
        if (
          selectedItem.shipmentProvider === item.shipmentProvider &&
          selectedItem.shipmentTrackingNumber === item.shipmentTrackingNumber
        ) {
          return {
            ...item,
            actualDeliveryDate: date,
          };
        }
        return item;
      });
    });
  };

  const renderShipmentInfo = (item) => {
    const shipmentDate = item.shipmentDate
      ? formatTitleDate(item.shipmentDate)
      : 'N.A.';

    return (
      <div className={classes.shipmentInfo}>
        <div className={classes.shipmentRow}>
          <span>Shipment Date:</span>
          <strong>{shipmentDate}</strong>
        </div>
        <div className={classes.shipmentRow}>
          <span>Shipment Provider:</span>
          <strong>{item.shipmentProvider || 'N.A.'}</strong>
        </div>
        <div
          style={{ display: 'inline-flex', alignItems: 'center' }}
          className={classes.shipmentRow}
        >
          <span>Shipment Tracking Number:</span>
          <strong>{item.shipmentTrackingNumber || 'N.A.'}</strong>
          <ShipmentTrackingIcon
            shipmentInfo={item}
            iconStyle={{ fontSize: '16px' }}
          />
        </div>
      </div>
    );
  };

  const renderBody = () => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '1rem',
        }}
      >
        {combinedItemsQuality?.map((item, index) => {
          return (
            <div key={item.itemID}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    columnGap: '0.5rem',
                  }}
                >
                  <Typography>
                    ItemID:&nbsp;
                    <Link to={{ pathname: `/item/edit/${item.itemID}` }}>
                      {item.itemID}
                    </Link>
                  </Typography>
                  <span>-</span>
                  <DatePickerWithIcon
                    label='Actual Delivery Date'
                    value={item.actualDeliveryDate}
                    onChange={(date) => handleChangeDate(date, index)}
                    animateYearScrolling
                    inputVariant='outlined'
                    margin='dense'
                    onIconClick={(date) => handleUpdateAllDate(date, item)}
                    tooltipIconText='Apply to all dates for the same Shipment Tracking Number'
                  />
                </div>
                {renderShipmentInfo(item)}
              </div>
              <QualityAccordion
                titleText='Any quality feedback from customer?'
                properties={getQualityProperties(item, [
                  'customerDimQuality',
                  'customerVisualQuality',
                  'customerPackagingQuality',
                  'customerSFQuality',
                ])}
                updateQualityInformation={updateQualityInformation}
                itemID={item.itemID}
              />
            </div>
          );
        })}
        <FinanceNoteInput
          financeNote={financeNote}
          setFinanceNote={setFinanceNote}
        />
        <FinanceEmailInfo isForSupplier={false} />
      </div>
    );
  };

  return (
    <div>
      <Dialog
        maxWidth='md'
        open={open}
        onClose={handleClose}
        aria-labelledby='confirmation-dialog-title'
        classes={{ paper: classes.paper }}
        fullWidth
      >
        <DialogTitle id='confirmation-dialog-title'>
          <SubDesc content={`Invoice Info for Tracking ID ${rowData.id}`} />
        </DialogTitle>
        {isLoadingTracking ? (
          <CircularProgress style={{ padding: '1rem 2rem' }} />
        ) : (
          <DialogContent
            dividers
            style={{
              padding: '1rem 2rem',
              textAlign: 'center',
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'end',
                marginBottom: '1rem',
              }}
            >
              <BlueButton
                onBtnClick={updateActualDeliveryDate}
                size='small'
                btnContent='Update all with today’s date'
              />
            </div>
            {renderBody()}
          </DialogContent>
        )}
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            gap: '1rem',
            margin: '1rem',
          }}
        >
          <WhiteButton
            onBtnClick={handleClose}
            btnContent='Cancel'
            size='small'
          />
          <Button
            className={classes.invoiceButton}
            variant='contained'
            onClick={() => {
              handleSubmit(combinedItemsQuality, financeNote);
              handleClose();
            }}
          >
            Invoice
          </Button>
        </div>
      </Dialog>
    </div>
  );
}

export default InvoiceInfoPopup;
