/* eslint-disable react/display-name */
import React, { memo } from 'react';

import { InsertDriveFileOutlined as NewFileIcon } from '@material-ui/icons';

import { Button, CircularProgress } from '@material-ui/core';

import { colors } from '../../../palette';

import { makeStyles } from '@material-ui/core/styles/index';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  circularProgress: {
    marginLeft: 0,
    marginRight: theme.spacing.unit,
  },
  enabledButton: {
    color: colors.blue050,
  },
  disabledButton: {
    color: colors.lightGray,
    border: `1px solid ${colors.lightGray} !important`,
  },
  hasBorder: {
    border: `1px solid ${colors.blue050}`,
  },
}));

export const CustomGridDataToolbarButton = memo(
  ({
    handleClick = () => {},
    icon = NewFileIcon,
    disabled = false,
    name = 'Button',
    loading = false,
    border = true,
    customStyle = {},
  }) => {
    const classes = useStyles({
      border,
    });

    return (
      <Button
        className={clsx(
          disabled ? classes.disabledButton : classes.enabledButton,
          border ? classes.hasBorder : null,
          customStyle
        )}
        startIcon={loading ? null : icon}
        variant='outline'
        onClick={handleClick}
        disabled={disabled}
      >
        {loading && (
          <CircularProgress className={classes.circularProgress} size={20} />
        )}
        {name}
      </Button>
    );
  }
);
