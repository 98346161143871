import React from 'react';

import { makeStyles } from '@material-ui/core/styles/index';

import { colors } from '../../palette';
import { Tooltip } from '@material-ui/core';

// -------------------------------------------------------------------------------------------------

const useStyles = makeStyles(() => ({
  supportStyle: {
    fontSize: '11pt',
    color: colors.fontFaintGrey,
  },
}));

function SupportMessage() {
  const classes = useStyles();

  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        paddingTop: '1rem',
      }}
    >
      <div className={classes.supportStyle}>
        Need help updating your requirements? Contact us via&nbsp;
        <Tooltip title='+65 8925 8615'>
          <a href='https://api.whatsapp.com/send?phone=6589258615&text='>
            WhatsApp
          </a>
        </Tooltip>
        &nbsp;or&nbsp;
        <Tooltip title='sales@factorem.co'>
          <a href='mailto:sales@factorem.co'>Email</a>
        </Tooltip>
        .
      </div>
    </div>
  );
}

export default SupportMessage;
