import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';

export const WhiteOnRedTooltip = withStyles({
  tooltip: {
    color: 'white',
    backgroundColor: 'red',
  },
  arrow: {
    color: 'red',
  },
})(Tooltip);
