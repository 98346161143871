import React, { useEffect, useRef, useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles/index';

import { InputBase, InputAdornment, useMediaQuery } from '@material-ui/core';

import EditProjectNameIcon from '../../assets/icons/edit_pencil_icon.svg';

import withRenameProjectFieldPopupHOC from './withRenameProjectFieldPopupHOC';

import { colors } from '../../palette';

// -------------------------------------------------------------------------------------------------

const useStyles = makeStyles(() => ({
  hide: {
    visibility: 'hidden',
    position: 'absolute', // this to prevent the space of the hidden element to be rendered
    fontWeight: 700,
    fontSize: '22px',
  },
}));

function ProjectNameField(props) {
  const classes = useStyles();

  const span = useRef();

  const {
    projectName,
    updateProjectName,
    style,
    fontSize = '22px',
    updateRenameProjectFieldPopupState = () => {},
  } = props;

  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));

  const [projectNameValue, setProjectNameValue] = useState(
    !projectName ? 'Project Name' : projectName
  );
  const [projectNameHovered, setProjectNameHovered] = useState(false);
  const [isEditProjectName, setIsEditProjectName] = useState(false);
  const [inputFieldWidth, setInputFieldWidth] = useState(0);

  useEffect(() => {
    if (projectName) {
      setProjectNameValue(projectName);
    }
  }, [projectName]);

  useEffect(() => {
    const width = span.current.offsetWidth;
    setInputFieldWidth(width);
  }, [projectNameValue]);

  return (
    <>
      <span className={classes.hide} style={{ fontSize }} ref={span}>
        {projectNameValue}
      </span>
      <InputBase
        id='projectName'
        name='projectName'
        onChange={(event) => setProjectNameValue(event.target.value)}
        onBlur={(event) => {
          event.stopPropagation();
          setIsEditProjectName(false);
          updateProjectName(projectNameValue);
        }}
        disabled={!isEditProjectName}
        value={projectNameValue}
        variant='outlined'
        size='small'
        style={{
          fontWeight: 700,
          fontSize,
          color: colors.neutral080,
          padding: '0 0.3rem',
          border: isEditProjectName && `2px solid ${colors.blue030}`,
          borderRadius: '6px',
          width: inputFieldWidth + 40, // 40 is for the edit icon width
          ...style,
        }}
        onMouseEnter={() => !isTablet && setProjectNameHovered(true)}
        onMouseLeave={() => !isTablet && setProjectNameHovered(false)}
        endAdornment={
          (projectNameHovered || isTablet) && ( // if hovered or isTablet, it will be displayed
            <InputAdornment
              position='end'
              style={{ cursor: 'pointer' }}
              onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();
                if (isTablet) {
                  updateRenameProjectFieldPopupState({
                    open: true,
                    projectNameValue,
                    onUpdate: (value) => {
                      setProjectNameValue(value);
                      updateProjectName(value);
                    },
                  });
                  return;
                }
                if (isEditProjectName) {
                  updateProjectName(projectNameValue);
                }
                setIsEditProjectName(!isEditProjectName);
              }}
            >
              <img src={EditProjectNameIcon} alt='Edit project name' />
            </InputAdornment>
          )
        }
      />
    </>
  );
}

export default withRenameProjectFieldPopupHOC(ProjectNameField);
