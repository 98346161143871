import React from 'react';

import FtrNumberField from '../../ftr-components/FtrNumberField';

// ------------------------------------------------------------------------------------

function QuantityField(props) {
  return (
    <FtrNumberField
      minValue={1}
      style={{
        maxWidth: Number(props.value) > 999 ? 100 : 70,
        backgroundColor: 'transparent',
        marginBottom: 0,
      }}
      inputProps={{ min: 1, style: { textAlign: 'right' } }}
      {...props}
    />
  );
}

export default QuantityField;
