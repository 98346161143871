import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles/index';

import {
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';

import CountryDropDown from './forms/CountryDropDown';

import { COUNTRY_LIST } from '../constants/countryConstants';
import { ADDRESS_COMPONENTS } from '../constants/deliveryInfoConstants';

import { colors } from '../palette';
import { FlexColumn } from './layouts/FlexLayouts';
import { FtrTextField } from './ftr-components';

const useStyles = makeStyles(() => ({
  inputLabel: {
    fontSize: '16px',
    fontWeight: 600,
    paddingBottom: '0.25rem',
    color: colors.neutral070,
  },
  addressField: {
    '& .MuiInputBase-root': {
      borderRadius: '10px',
    },
  },
}));

/**
 *
 * @param {object} props
 * @param {'medium' | 'small'} props.size
 * @param {'normal' | 'none' | 'dense'} props.margin
 * @param {string} props.className
 * @param {string} props.label
 * @param {React.CSSProperties} props.style
 * @param {boolean} props.required
 * @param {({ postalCode, unitNo, address, country}) => void} props.onChange
 * @param {{ postalCode, unitNo, address, country}} props.initialValue
 * @param {React.CSSProperties} props.styleChild
 * @param {boolean} props.showCountry
 */
const AddressAutocomplete = (props) => {
  const classes = useStyles();

  const {
    size = 'medium',
    margin = 'normal',
    className = '',
    label = 'Address',
    style = null,
    required = false,
    onChange = () => null,
    initialValue = {
      address: '',
      postalCode: '',
      unitNo: '',
      country: 'Singapore',
    },
    styleChild = null,
    showCountry = true,
    showSubFieldTitle = false,
    addressError,
    addressHelperText,
    id = 'address-auto-complete',
  } = props;

  const [address, setAddress] = useState(initialValue.address);
  const [postalCode, setPostalCode] = useState(initialValue.postalCode);
  const [unitNo, setUnitNo] = useState(initialValue.unitNo);
  const [country, setCountry] = useState(initialValue.country);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    setTimeout(() => {
      // load html first then load places autocomplete
      const autocomplete = new window.google.maps.places.Autocomplete(
        document.getElementById(id),
        {
          types: ['address'],
        }
      );
      autocomplete.addListener('place_changed', () => {
        const placeResult = autocomplete.getPlace();
        const addressComponents = placeResult?.address_components;
        let address = [];
        let selectedCountry = '';
        let postalCode = null;
        addressComponents?.forEach((component) => {
          if (component.types.includes(ADDRESS_COMPONENTS.COUNTRY)) {
            selectedCountry = component?.short_name;
          } else if (component.types.includes(ADDRESS_COMPONENTS.POSTAL_CODE)) {
            postalCode = component?.long_name;
          } else {
            address.push(component?.long_name);
          }
        });
        let address_str = address.join(', ');
        const [countryName] =
          Object.entries(COUNTRY_LIST).find(
            ([_, country]) => country.code === selectedCountry
          ) || [];
        setCountry(countryName);
        setPostalCode(postalCode ?? '');
        setAddress(address_str);
      });
    }, 500);
  }, []);

  useEffect(() => {
    onChange({
      postalCode,
      unitNo,
      address,
      country,
    });
  }, [postalCode, unitNo, address, country]);

  return (
    <React.Fragment>
      <TextField
        variant='outlined'
        margin={margin}
        fullWidth
        className={`${classes.addressField} ${className || ''}`}
        name='address'
        label={label}
        type='text'
        multiline
        id={id}
        placeholder='Please Enter Your Address'
        onChange={(e) => setAddress(e.target.value)}
        value={address}
        size={size}
        style={style}
        required={required}
        error={addressError}
        helperText={addressHelperText}
      />
      {address && (
        <div
          style={{
            marginBottom: '0.7rem',
            marginTop: '-0.8rem',
            display: 'grid',
            gap: '0.5rem',
            width: '100%',
            alignItems: 'center',
            gridTemplateColumns: `repeat(${
              isMobile || !showCountry ? 2 : 3
            }, 1fr)`,
            ...styleChild,
          }}
        >
          {showCountry && (
            <div
              style={{
                marginBottom: showSubFieldTitle ? '-15px' : '0.95rem',
                width: '100%',
              }}
            >
              <>
                {showSubFieldTitle && (
                  <Typography className={classes.inputLabel} style={{}}>
                    Country *
                  </Typography>
                )}
                <CountryDropDown
                  valueSelected={country}
                  onSelect={(value) => setCountry(value)}
                />
              </>
            </div>
          )}
          <FlexColumn
            style={{
              gap: 0,
              marginTop: showSubFieldTitle ? '1rem' : 0,
            }}
          >
            {/* <TextField
              variant='outlined'
              fullWidth
              name='postalCode'
              label='Zip Code'
              id='postalCode'
              margin='dense'
              value={postalCode}
              onChange={(evt) => setPostalCode(evt.target.value)}
            /> */}
            <FtrTextField
              title={showSubFieldTitle ? 'Zip Code' : null}
              name='zip-code'
              label={showSubFieldTitle ? null : 'Zip Code'}
              id='zip-code'
              onChange={(evt) => setPostalCode(evt.target.value)}
              value={postalCode}
            />
          </FlexColumn>
          <FlexColumn
            style={{
              gap: 0,
              marginTop: showSubFieldTitle ? '1rem' : 0,
            }}
          >
            {/* <TextField
              style={isMobile ? { marginTop: '0' } : null}
              variant='outlined'
              fullWidth
              name='unitNo'
              label='Unit No'
              type='text'
              id='unitNo'
              margin='dense'
              value={unitNo}
              onChange={(evt) => setUnitNo(evt.target.value)}
            /> */}
            <FtrTextField
              title={showSubFieldTitle ? 'Unit No' : null}
              name='unitNo'
              label={showSubFieldTitle ? null : 'Unit No'}
              id='unitNo'
              onChange={(evt) => setUnitNo(evt.target.value)}
              value={unitNo}
            />
          </FlexColumn>
        </div>
      )}
    </React.Fragment>
  );
};

export default AddressAutocomplete;
