import React from 'react';

import { GridPagination } from '@mui/x-data-grid';

import { Pagination as MuiPagination } from '@material-ui/lab';

function Pagination({ page, onPageChange, className, pageCount }) {
  return (
    <MuiPagination
      color='primary'
      className={className}
      count={pageCount}
      page={page + 1}
      onChange={(event, newPage) => {
        onPageChange(event, newPage - 1);
      }}
      showFirstButton
      showLastButton
      siblingCount
    />
  );
}

function GridDataPagination(props) {
  const { pageCount } = props;
  return (
    <GridPagination
      ActionsComponent={(props) => (
        <Pagination pageCount={pageCount} {...props} />
      )}
      {...props}
    />
  );
}

export default GridDataPagination;
