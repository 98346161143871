import queryString from 'query-string';

import { getAuthorizedHeader } from '../utils/apiUtils';

import { BACKEND_SERVICE_URL } from '../constants';

export const getGenerateRFQEmailsTrackingItems = async (params) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/generate-rfq-emails-tracking/tracking?${queryString.stringify(
    params
  )}`;
  return fetch(requestUrl, {
    method: 'GET',
    headers: getAuthorizedHeader(),
  }).then(async (response) => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return (await response.json()).data;
  });
};

export const updateGenerateRFQEmailsTrackingItems = async (body) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/generate-rfq-emails-tracking/tracking`;
  return fetch(requestUrl, {
    method: 'PUT',
    body: JSON.stringify(body),
    headers: getAuthorizedHeader(),
  }).then(async (response) => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return (await response.json()).data;
  });
};

export const exportCsvRFQEmailsTrackingItems = async (params) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/generate-rfq-emails-tracking/csv-export?${queryString.stringify(
    params
  )}`;
  return fetch(requestUrl, {
    method: 'GET',
    headers: getAuthorizedHeader(),
  }).then(async (response) => {
    return response;
  });
};

export const getTrackingBrevo = async (params) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/generate-rfq-emails-tracking/tracking/brevo?${queryString.stringify(
    params
  )}`;
  return fetch(requestUrl, {
    method: 'GET',
    headers: getAuthorizedHeader(),
  }).then(async (response) => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return (await response.json()).data;
  });
};

export const getRFQEmailsTrackingItemStatus = async (params) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/generate-rfq-emails-tracking/tracking/status?${queryString.stringify(
    params
  )}`;
  return fetch(requestUrl, {
    method: 'GET',
    headers: getAuthorizedHeader(),
  }).then(async (response) => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return (await response.json()).data;
  });
};
