import React, { useReducer } from 'react';

import CheckoutTypeFieldPopup from '../../components/popups/CheckoutTypeFieldPopup';

import { isEmptyValue } from '../../utils/commonUtils';
import { updateMultipleCheckout } from '../../apis/multiCheckoutApi';
import { notifyError, notifySuccess } from '../../services/notificationService';

// ---------------------------------------------------------------------------------

function withUpdateCheckoutTypePopupHoC(WrappedComponent) {
  function WrappedComponentWithHOC(props) {
    const defaultLocalState = {
      open: false,
      selectedData: null,
      refetch: () => {},
    };

    const [localState, updateLocalState] = useReducer((prev, next) => {
      if (isEmptyValue(next)) {
        return defaultLocalState;
      }
      return { ...prev, ...next };
    }, defaultLocalState);

    const updateMultiCheckout = ({ checkoutType, paymentType }) => {
      updateMultipleCheckout(localState.selectedData.id, {
        checkoutType,
        paymentType,
      })
        .then(() => {
          notifySuccess(
            `Checkout ${localState.selectedData.referenceName} has been updated successfully.`
          );
          localState.refetch();
        })
        .catch(() => {
          notifyError(
            `Checkout ${localState.selectedData.referenceName} failed to update.`
          );
        });
    };

    return (
      <>
        <WrappedComponent
          updateCheckoutTypePopupHOCState={updateLocalState}
          {...props}
        />
        {localState.open && (
          <CheckoutTypeFieldPopup
            title='Update Checkout Type'
            open={localState.open}
            onClose={() => updateLocalState({ open: false })}
            value={{
              checkoutType: localState.selectedData.checkoutType,
              paymentType: localState.selectedData.paymentType,
            }}
            updateHandler={({ checkoutType, paymentType }) => {
              updateMultiCheckout({ checkoutType, paymentType });
            }}
          />
        )}
      </>
    );
  }

  return WrappedComponentWithHOC;
}

export default withUpdateCheckoutTypePopupHoC;
