import React, { useReducer, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import AcceptedMultipleQuotationsPopupV2 from './AcceptedMultipleQuotationsPopupV2';
import ShareQuotationFormPopupV2 from './ShareQuotationFormPopupV2';
import ThankYouFeedbackPopup from '../ThankYouFeedbackPopup';
import UserFeedbackPopupV2 from '../UserFeedbackPopupV2';

import useFeatureFlagHook from '../../../hooks/useFeatureFlagHook';

import { USER_FEEDBACK_QUESTION_LIST } from '../../../constants/userFeedbackConstants';

// ------------------------------------------------------------------------------------------------

function withConfirmOrderPopupsHOCV2(WrappedComponent) {
  return function ConfirmOrderPopupsHOC(props) {
    const history = useHistory();

    const { projectID } = useParams();

    const {
      customerID,
      refreshProject = () => {},
      handleClose = () => {},
    } = props;

    const { isManageProjectsRevampEnabled } = useFeatureFlagHook();

    const [localState, updateLocalState] = useReducer(
      (prev, next) => {
        return { ...prev, ...next };
      },
      {
        items: [],
        showAcceptedPopup: false,
        showFeedbackPopup: false,
        showShareQuotePopup: false,
        showAddAddressPopup: false,
        addAddressType: 'shipping',
        addresses: {},
      }
    );

    const [showThankYouFeedbackPopup, setShowThankYouFeedbackPopup] = useState(
      false
    );

    const handleAcceptedPopupButtonClick = () => {
      updateLocalState({ showAcceptedPopup: false });
      if (isManageProjectsRevampEnabled) {
        history.push({
          pathname: `/customer-orders/projects/${projectID}`,
          state: { projectID },
        });
      } else {
        refreshProject();
      }
      handleClose();
    };

    return (
      <>
        <WrappedComponent updatePopupsHocState={updateLocalState} {...props} />
        {localState.showShareQuotePopup && (
          <ShareQuotationFormPopupV2
            open={localState.showShareQuotePopup}
            handleClose={() => updateLocalState({ showShareQuotePopup: false })}
            userID={customerID}
            addresses={localState.addresses}
          />
        )}
        {localState.showAcceptedPopup && (
          <AcceptedMultipleQuotationsPopupV2
            open={localState.showAcceptedPopup}
            items={localState.items}
            onBtnClick={handleAcceptedPopupButtonClick}
          />
        )}
        {localState.showFeedbackPopup && (
          <UserFeedbackPopupV2
            open={localState.showFeedbackPopup}
            questionList={USER_FEEDBACK_QUESTION_LIST}
            userID={customerID}
            handleClose={() => {
              updateLocalState({ showFeedbackPopup: false });
              setShowThankYouFeedbackPopup(true);
            }}
          />
        )}
        {showThankYouFeedbackPopup && (
          <ThankYouFeedbackPopup
            open={showThankYouFeedbackPopup}
            handleClose={() => {
              setShowThankYouFeedbackPopup(false);
            }}
          />
        )}
      </>
    );
  };
}

export default withConfirmOrderPopupsHOCV2;
