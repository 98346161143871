import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  resetViewAsSupplier,
  setViewAsSupplierThunk,
} from '../actions/viewAsSupplier';

import { getExchangeRatesSelector } from '../selectors/exchangeRatesSelector';
import {
  getUserCurrencySelector,
  getUserSelector,
} from '../selectors/userSelector';
import { getViewAsSupplierSelector } from '../selectors/viewAsSupplierSelector';

import { isAdminOrHigherRole } from '../utils/roleUtils';

// ---------------------------------------------------------------------------------

function withViewAsSupplierHOC(WrappedComponent) {
  return function ViewAsSupplierHOC(props) {
    const loggedInUser = useSelector(getUserSelector);
    const loggedInUserCurrency = useSelector(getUserCurrencySelector);
    const exchangeRates = useSelector(getExchangeRatesSelector);
    const viewAsSupplier = useSelector(getViewAsSupplierSelector);

    const dispatch = useDispatch();

    const isUserAdminOrHigher = isAdminOrHigherRole(loggedInUser.role);

    const loggedInUserWithDisplayName = {
      ...loggedInUser,
      displayName: `${loggedInUser.name} <${loggedInUser.email}>`,
    };

    const supplierUserID = viewAsSupplier?.user?.userID;
    const isViewAsSupplier =
      supplierUserID && supplierUserID !== loggedInUser.userID;

    const displayUser = isViewAsSupplier
      ? viewAsSupplier.user
      : loggedInUserWithDisplayName;
    const displayUserCurrency = isViewAsSupplier
      ? viewAsSupplier.currency
      : loggedInUserCurrency;
    const displayUserExchangeRate = exchangeRates[displayUserCurrency];

    const updateViewAsSupplier = (viewAsSupplierUser) => {
      if (isUserAdminOrHigher) {
        dispatch(setViewAsSupplierThunk(viewAsSupplierUser));
      }
    };

    const resetViewAsSupplierDispatch = () => {
      dispatch(resetViewAsSupplier());
    };

    return (
      <WrappedComponent
        user={displayUser}
        currency={displayUserCurrency}
        exchangeRate={displayUserExchangeRate}
        updateViewAsSupplier={updateViewAsSupplier}
        resetViewAsSupplier={resetViewAsSupplierDispatch}
        {...props}
      />
    );
  };
}

export default withViewAsSupplierHOC;
