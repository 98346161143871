import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';

import { Box, Divider } from '@material-ui/core';

import CountrySelectorPopup from '../popups/CountrySelectorPopup';
import FtrSvgImage from '../images/FtrSvgImage';
import ProfileMenuItemV2 from '../list-items/ProfileMenuItemV2';
import ResponsiveSidebar from '../ResponsiveSidebar';

import FactoremLogo from '../../assets/icons/factorem_logo_blue.svg';
import BoltIcon from '../../assets/icons/bolt_icon.svg';

import useFeatureFlagHook from '../../hooks/useFeatureFlagHook';
import useManageProjectRevampLegacyCustomerHook from '../../hooks/useManageProjectRevampLegacyCustomerHook';

import { getUserIDSelector } from '../../selectors/userSelector';

import { isEmptyValue } from '../../utils/commonUtils';

import {
  CUSTOMER_SIDEBAR_TABS,
  CUSTOMER_SIDEBAR_TABS_REVAMP,
} from '../../constants/sidebarConstants';

import { colors } from '../../palette';

// ------------------------------------------------------------------------------------

const drawerWidth = 250;

const useStyles = makeStyles({
  top: {
    marginBottom: 10,
    padding: '0 1.2rem',
    '&:hover': {
      background: colors.bgLightGrey,
      cursor: 'pointer',
    },
    display: 'flex',
    flexDirection: 'column',
  },
  countrySelector: {
    margin: '1rem 0',
    padding: '0px 2rem',
    textTransform: 'capitalize',
  },
  icon: {
    display: 'flex',
    marginRight: 15,
  },
  navlinks: {
    display: 'flex',
    flexDirection: 'column',
  },
  listText: {
    textTransform: 'capitalize',
    fontSize: '15px',
    letterSpacing: '0.05em',
    color: colors.neutral060,
  },
  sidebarMenu: {
    textDecoration: 'none',
    display: 'flex',
    margin: '0 8px',
    padding: '9px 15px',
    borderRadius: 10,
    backgroundColor: 'transparent',
    textTransform: 'capitalize',
    fontSize: '15px',
    fontWeight: 600,
    letterSpacing: '0.05em',
    color: colors.neutral060,
    '&:hover': {
      backgroundColor: colors.neutral020,
      color: colors.blue060,
    },
  },
  sidebarMenuHighlight: {
    color: colors.blue060,
  },
  sidebarMenuActive: {
    backgroundColor: colors.blue010,
    color: colors.blue060,
  },
  gettingStarted: {
    textDecoration: 'none',
    margin: '0px 0px',
    display: 'flex',
    padding: '9px 23px',
    fontWeight: 600,
    backgroundColor: 'transparent',
    fontSize: '15px',
    letterSpacing: '0.05em',
    color: colors.neutral060,
    '&:hover': {
      color: colors.fontBlack,
    },
    transition: 'color 100ms ease-in-out',
    cursor: 'pointer',
  },
});

/**
 * @param {*} props
 * @returns
 */
export function SidebarV2(props) {
  const classes = useStyles();

  const history = useHistory();

  const { location, onSignUpPopup } = props;

  const userID = useSelector(getUserIDSelector);
  const currentTour = useSelector((state) => state.tour.currentTour);

  const { isManageProjectsRevampEnabled } = useFeatureFlagHook();
  const { isLegacyCustomer } = useManageProjectRevampLegacyCustomerHook(userID);

  const customerTabs = useMemo(() => {
    if (isManageProjectsRevampEnabled || !isLegacyCustomer) {
      return CUSTOMER_SIDEBAR_TABS_REVAMP;
    }

    return CUSTOMER_SIDEBAR_TABS;
  }, [isManageProjectsRevampEnabled, isLegacyCustomer]);

  const [selectedMenu, setSelectedMenu] = React.useState(location?.pathname);

  useEffect(() => {
    setSelectedMenu(location?.pathname);
  }, [location?.pathname]);

  const handleClickGettingStarted = () => {
    if (window.PBrain) {
      window.PBrain.config({ showhide: 'hide' });
    }
    if (
      props.history.location.pathname !== '/' &&
      props.history.location.pathname !== '/summary'
    ) {
      props.history.push('/');
    } else {
      currentTour?.start();
    }
  };

  const handleLogout = () => {
    // dispatch(logoutUser(props));
    history.push('/logout');
  };

  const renderGettingStarted = () => (
    <div
      id='getting-started-tab'
      className={classes.gettingStarted}
      onClick={handleClickGettingStarted}
    >
      <div className={classes.icon}>
        <FtrSvgImage src={BoltIcon} />
      </div>
      <div>Getting started</div>
    </div>
  );

  const renderTabIcon = (tab) => {
    if (!tab.activeIcon) {
      return <div className={classes.icon}>{tab.icon}</div>;
    }
    return (
      <div
        style={{
          marginLeft: 2,
          marginRight: 18,
        }}
      >
        <FtrSvgImage
          src={tab.to === selectedMenu ? tab.activeIcon : tab.icon}
        />
      </div>
    );
  };

  const renderListMenuItems = () => (
    <div className={classes.navlinks}>
      {customerTabs.map((tab) => {
        if (tab.type === 'spacer') {
          return <Box key={tab.type} style={{ height: '1.5rem' }} />;
        }

        return (
          <Link
            key={tab.to}
            target={tab.to.startsWith('http') ? '_blank' : null}
            to={{
              pathname: tab.to,
            }}
            id={tab.id}
            className={clsx(
              classes.sidebarMenu,
              tab.highlight === true ? classes.sidebarMenuHighlight : null,
              tab.to === selectedMenu ? classes.sidebarMenuActive : null
            )}
            onClick={(e) => {
              if (!userID) {
                e.preventDefault();
                onSignUpPopup();
              }
            }}
          >
            {renderTabIcon(tab)}
            <div data-cy={tab.dataCy}>{tab.text}</div>
          </Link>
        );
      })}
    </div>
  );

  return (
    <ResponsiveSidebar drawerWidth={drawerWidth} Content={props.children}>
      <div>
        <div style={{ padding: '1.5rem' }}>
          <img
            src={FactoremLogo}
            alt='logo'
            style={{
              display: 'block',
            }}
          />
        </div>
        {!isEmptyValue(userID) && (
          <div
            id='buyer-profile'
            className={classes.top}
            onClick={() => setSelectedMenu('/profile')}
          >
            <ProfileMenuItemV2 handleLogout={handleLogout} />
          </div>
        )}
        {!isEmptyValue(userID) && (
          <div className={classes.countrySelector}>
            <CountrySelectorPopup />
          </div>
        )}
        <Divider />
        <div style={{ margin: '1.5rem 0 1rem' }}>{renderGettingStarted()}</div>
        <div>{renderListMenuItems()}</div>
      </div>
    </ResponsiveSidebar>
  );
}

export default withRouter(SidebarV2);
