export const EXTRACT_DEFECT_KEYS_MAPPING = {
  deep_holes: 'deep_holes',
  small_holes: 'small_holes',
  holes_close_to_edge: 'holes_close_to_edge',
  thin_walls: 'thin_walls',
  undercuts: 'undercuts',
  dfm_success: 'dfm_success',
  part_sizes_too_big: 'part_sizes_too_big',
  sharp_edges: 'sharp_edges',
  visualization_links: 'visualization_links',
};

export const EXTRACT_DEFECT_RULES_MAPPING = {
  deep_holes: 0,
  holes_close_to_edge: 0,
  thin_walls: 0,
  small_holes: 0,
  undercuts: 0,
  dfm_success: true,
  sharp_edges: 0,
  part_sizes_too_big: {
    tech: '3DP',
    value: 0,
  },
};

export const KEYS_TO_FEEDBACK_MAPPING = {
  deep_holes: 'deepHolesFeedback',
  holes_close_to_edge: 'holesNearEdgeFeedback',
  part_sizes_too_big: 'partSizeTooBigFeedback',
  thin_walls: 'thinWallsFeedback',
  sharp_edges: 'sharpEdgesFeedback',
  small_holes: 'smallHolesFeedback',
};

export const DFM_AUTOMATION_EXTENSIONS = ['step', 'stp', 'stl'];

export const DFM_TYPES = {
  NO_DFM: 'no-dfm',
  HAS_PARTS_LIBRARY_DFM_ONLY: 'has-parts-library-dfm-only',
  HAS_PUE_MPR_DFM_ONLY: 'has-pue-mpr-dfm-only',
  HAS_DFM: 'has-dfm',
};
