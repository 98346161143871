const FAILURE_REASON = {
  GROUP_NOT_FOUND: 'group-not-found',
  SUPPLIER_NOT_FOUND: 'supplier-not-found',
  INVALID_ITEM: 'invalid-item',
  OMS_DOWN: 'oms-down',
  RFQ_EMAIL_SENDING_FAILED: 'rfq-email-sending-failed',
};

export const FAILURE_REASON_MAPPING = {
  [FAILURE_REASON.GROUP_NOT_FOUND]: "Suppliers' repeat group is not found",
  [FAILURE_REASON.SUPPLIER_NOT_FOUND]:
    "We can't find any supplier that matches our OMS criteria",
  [FAILURE_REASON.INVALID_ITEM]:
    "Item can't be processed as it is either unpaid or unverified",
  [FAILURE_REASON.OMS_DOWN]: 'OMS was down during the automation process',
  [FAILURE_REASON.RFQ_EMAIL_SENDING_FAILED]:
    'Failed to send the automated RFQ email',
};
