import React, { useState } from 'react';

import { Container, Tab, Tabs } from '@material-ui/core';

import S3DownloadFile from './S3DownloadFile';
import S3UploadFile from './S3UploadFile';

import { makeStyles } from '@material-ui/core/styles/index';

import { colors } from '../../palette';

const useStyles = makeStyles(() => ({
  tabRoot: {
    '&:hover': {
      color: 'white',
      backgroundColor: colors.blue050,
    },
  },
  selectedTab: {
    '&:hover': {
      backgroundColor: colors.secondaryBlue,
      color: colors.blue050,
    },
    backgroundColor: colors.secondaryBlue,
    color: colors.blue050,
  },
}));

const TAB_KEYS = {
  S3_DOWNLOAD: 'S3 Download File',
  S3_UPLOAD: 'S3 Upload Public File',
};

const TABS = [TAB_KEYS.S3_DOWNLOAD, TAB_KEYS.S3_UPLOAD];

function S3Tools() {
  const classes = useStyles();

  const [selectedTab, setSelectedTab] = useState(0);

  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const renderTab = () => {
    const tabName = TABS[selectedTab];
    switch (tabName) {
      case TAB_KEYS.S3_DOWNLOAD:
        return <S3DownloadFile />;
      case TAB_KEYS.S3_UPLOAD:
        return <S3UploadFile />;
      default:
        return null;
    }
  };

  return (
    <Container
      style={{
        paddingTop: '1rem',
      }}
    >
      <Tabs value={selectedTab} onChange={handleChange}>
        {TABS.map((tab) => {
          return (
            <Tab
              classes={{
                root: classes.tabRoot,
                selected: classes.selectedTab,
              }}
              key={tab}
              label={tab}
            />
          );
        })}
      </Tabs>
      {renderTab()}
    </Container>
  );
}

export default S3Tools;
