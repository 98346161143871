/* eslint-disable react/display-name */
import React, { memo } from 'react';

import { CreateNewFolder } from '@material-ui/icons';

import { CustomGridDataToolbarButton } from './CustomGridDataToolbarButton';
import { Tooltip } from '@material-ui/core';

export const ReorderProjectsButton = memo(
  ({ handleClick = () => {}, disabled = false, name, tooltip = '' }) => {
    return (
      <Tooltip title={tooltip}>
        <div>
          <CustomGridDataToolbarButton
            icon={<CreateNewFolder />}
            handleClick={handleClick}
            disabled={disabled}
            name={name}
          />
        </div>
      </Tooltip>
    );
  }
);
