import React from 'react';

import { makeStyles } from '@material-ui/core/styles/index';

import { Paper, withStyles } from '@material-ui/core';

import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';

import { UNIT_TYPES } from '../../constants/';

import { colors } from '../../palette';

// ------------------------------------------------------------------------------

const StyledToggleButtonGroup = withStyles((theme) => ({
  root: {
    borderRadius: 8,
  },
  grouped: {
    margin: theme.spacing(0.2),
    border: 'none',
    borderRadius: '8px !important',
    maxHeight: 24,
    minWidth: 40,
    backgroundColor: 'transparent',
  },
}))(ToggleButtonGroup);

const StyledToggleButton = withStyles(() => ({
  root: {
    textTransform: 'none',
    fontWeight: 600,
    color: colors.neutral050,
  },
  selected: {
    backgroundColor: 'white !important',
    color: `${colors.neutral080} !important`,
    boxShadow: '0px 2px 4px 0px #00000033',
  },
}))(ToggleButton);

const useStyles = makeStyles(() => ({
  paper: {
    borderRadius: 8,
    backgroundColor: colors.neutral030,
    maxHeight: 28,
    marginBottom: 1,
  },
}));

function ImperialMetricSwitchV2(props) {
  const classes = useStyles();

  const { selectedUnitType, onChange = () => {} } = props;

  const handleSelectUnitType = (event, newUnitType) => {
    if (newUnitType && selectedUnitType !== newUnitType) {
      onChange(newUnitType);
    }
  };

  return (
    <Paper elevation={0} className={classes.paper}>
      <StyledToggleButtonGroup
        size='small'
        value={selectedUnitType}
        exclusive
        onChange={handleSelectUnitType}
      >
        <StyledToggleButton
          value={UNIT_TYPES.METRIC}
          selected={selectedUnitType === UNIT_TYPES.METRIC}
          aria-label='mm'
        >
          mm
        </StyledToggleButton>
        <StyledToggleButton
          value={UNIT_TYPES.IMPERIAL}
          selected={selectedUnitType === UNIT_TYPES.IMPERIAL}
          aria-label='inch'
        >
          inch
        </StyledToggleButton>
      </StyledToggleButtonGroup>
    </Paper>
  );
}

export default ImperialMetricSwitchV2;
