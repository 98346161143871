import React from 'react';

import FtrSvgImage from '../../images/FtrSvgImage';

import UpArrowIcon from '../../../assets/icons/up_arrow_icon.svg';

// ----------------------------------------------------------------------------------

function FtrUpArrowButton(props) {
  const { onClick = () => {} } = props;

  return (
    <FtrSvgImage
      src={UpArrowIcon}
      width={10}
      height={10}
      style={{
        justifyContent: 'end',
        cursor: 'pointer',
      }}
      onClick={onClick}
    />
  );
}

export default FtrUpArrowButton;
