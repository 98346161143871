/* eslint-disable react/prop-types */
import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';

import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Divider,
  Grid,
  InputAdornment,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
  withStyles,
} from '@material-ui/core';

import { DatePicker } from '@material-ui/pickers';

import ApplyToAllDialog from '../dialogs/ApplyToAllDialog';
import BulkPricingPopupV2 from '../popups/BulkPricingPopupV2';
import CircleButton from '../buttons/CircleButton';
import ColorFtrDropdown from '../ftr-components/ColorFtrDropdown';
import FtrCheckbox from '../ftr-components/FtrCheckbox';
import FtrSvgImage from '../images/FtrSvgImage';
import ImperialMetricSwitchV2 from '../ImperialMetricSwitch/ImperialMetricSwitchV2';
import ManualQuotationRequiredBanner from './part-upload-step-two/ManualQuotationRequiredBanner';
import MaterialCategoriesInputField from '../inputs/MaterialCategoriesInputField';
import PartNameField from '../fields/PartNameField';
import ReplacePartPopup from './part-upload-step-two/configure-part-form/ReplacePartPopup';
import ThreeDInfillSelectField from '../inputs/ThreeDInfillSelectField';
import ThreeDLayerThicknessField from '../inputs/ThreeDLayerThicknessField';
import ThreeDPrintingMaterialField from '../inputs/ThreeDPrintingMaterialField';
import ThreeDPrintingTechnologyInputField from '../inputs/ThreeDPrintingTechnologyInputField';
import ViewBulkPricingButton from './part-upload-step-two/ViewBulkPricingButton';
import {
  FlexColumn,
  FlexRowCenter,
  FlexRowSpaceBetween,
  FlexRow,
} from '../layouts/FlexLayouts';
import {
  FtrBoldText,
  FtrButton,
  FtrDropdown,
  FtrDropdownV2,
  FtrLightText,
  FtrSmallButton,
  FtrTextField,
  FtrTypography,
  FtrUploadFileSmallButton,
} from '../ftr-components';
import ImageWith3DViewer from '../images/ImageWith3DViewer';
import DfmAnalysisButton from '../DfmDefects/DfmAnalysisButton';

import { Refresh } from '@material-ui/icons';

import DeleteDrawingIcon from '../../assets/icons/delete_drawing.svg';
import DollarSymbolIcon from '../../assets/icons/dollar_symbol.svg';
import InfoIcon from '../../assets/icons/info_icon.svg';
import ArrowLeftIcon from '../../assets/icons/left_arrow.svg';
import ArrowRightIcon from '../../assets/icons/right_arrow.svg';
import TechnicalDrawingFileIcon from '../../assets/icons/technical_drawing_file_icon.svg';
import AutoAwesomeIcon from '../../assets/icons/auto_awesome.svg';

import { renderSkeletonOverlay } from '../util/skeleton';
import {
  upsertLeadTimeFeedback,
  upsertPriceFeedback,
} from '../../apis/ppeFeedbackApi';

import {
  addTechnicalDrawingAndGetPrice,
  getDefectForCadPart,
  getPpePriceForCadPart,
  removeTechnicalDrawing,
  replaceCadFile,
  updateCadPartIfExists,
  updateExpectedLeadTimeForPart,
  updateTargetUnitPriceForPart,
} from '../../actions';

import { useItemInputConfig } from '../../hooks/useItemInputConfig';

import { isEmptyValue } from '../../utils/commonUtils';
import { extractFormStateFromItem } from '../../utils/configurePartFormUtils';
import {
  convertPriceWithQuantityToCurrency,
  getCurrencySymbol,
} from '../../utils/currencyUtils';
import { getDateStrWithMonth } from '../../utils/dateTimeUtils';
import { getTechnicalDrawingFiles } from '../../utils/tdeUtils';

import { getDefaultTolerance } from '../../utils/toleranceUtils';
import {
  isCustomMaterial,
  isCustomSurfaceFinish,
  isCustomTechnology,
  isAnodizingSurfaceFinish,
} from '../../utils/inputUtils';
import {
  displayLeadTime,
  getFileNameFromCadFile,
  getItemNameFromCadFile,
  is3DPTechnology,
} from '../../utils/itemUtils';
import { isNumber } from '../../utils/numberUtils';
import {
  convertImperialToMetric,
  convertMetricToImperial,
  displayBoundingBoxValues,
  showUnitValueFromMetric,
} from '../../utils/userUtils';
import { capitalizeString } from '../../utils/stringUtils';

import { uploadCadFileToS3 } from '../../services/s3Service';
import { notifyError } from '../../services/notificationService';

import { CURRENCY, TWO_D_IMAGE_URLS_KEY } from '../../constants';
import { FEEDBACK_STATUS } from '../../constants/feedbackConstants';
import {
  UNIT_OPTIONS,
  DISPLAY_UNIT_OPTIONS,
  STANDARD_TOLERANCE_OPTIONS,
  THREE_D_P_FDM_TECH,
  UNIT_TYPES,
  threeDPrintingInfillDefault,
  threeDPrintingLayerThicknessDefault,
  MIN_MAX_VALUE_PER_UNIT_TYPE,
  ANODIZING_TYPE_OPTIONS,
} from '../../constants/';
import { ppe3dpInfillOptions } from '../../constants/PPEConstants';

import { colors } from '../../palette';
import { showDfmPueMpr } from '../../utils/dfmExtractionUtils';

const hash = require('object-hash');

const useStyles = makeStyles((theme) => ({
  '@global': {
    '*::-webkit-scrollbar': {
      width: '0.4em',
    },
    '*::-webkit-scrollbar-track': {
      display: 'block',
    },
    '*::-webkit-scrollbar-thumb': {
      width: '6px',
      borderRadius: '10px',
      backgroundColor: colors.neutral030,
    },
  },
  paper: {
    borderRadius: '30px',
    [theme.breakpoints.down('sm')]: {
      borderRadius: '0',
    },
    overflow: 'hidden',
  },
  autoAwesomeIcon: {
    height: '1rem !important',
    width: '1rem !important',
    filter: `invert(41%) sepia(90%) saturate(5329%) hue-rotate(203deg) brightness(96%) contrast(86%);`,
  },
  itemField: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '3px 0',
  },
  renderImage: {
    cursor: 'pointer',
    position: 'relative',
    '&:hover': {
      '& img': {
        filter: 'brightness(0.8)',
      },
      '& $render3dCAD': {
        display: 'block',
      },
    },
  },
  render3dCAD: {
    display: 'none',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    color: 'white',
    fontSize: '1.25rem',
  },
  bounce: {
    animation: '$bounce 2s',
    animationIterationCount: 'infinite',
  },
  '@keyframes bounce': {
    '0%, 20%, 50%, 80%, 100%': {
      transform: 'translateY(0)',
    },
    '40%': {
      transform: 'translateY(-10px)',
    },
    '60%': {
      transform: 'translateY(-5px)',
    },
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '1rem 0',
  },
  orderSpecs: {
    marginTop: '1rem',
  },
  partSpecLeftSide: {
    padding: '0 2rem 2rem 2rem',
    [theme.breakpoints.down('sm')]: {
      padding: '0 1rem 2rem 1rem',
    },
  },
  fileName: {
    color: colors.neutral070,
    wordBreak: 'break-all',
    paddingRight: '1rem',
  },
  hide: {
    visibility: 'hidden',
    opacity: 0,
  },
  fadeIn: {
    visibility: 'visible',
    opacity: 1,
    transition: 'opacity 150ms ease-in-out',
  },
}));

const FieldLabel = withStyles({
  root: {
    padding: '0.3rem 0',
    color: colors.neutral070,
  },
})((props) => <FtrTypography type='heading' fontSize='16' {...props} />);

const ItemFieldText = withStyles({
  root: {
    textAlign: 'left',
    color: colors.neutral060,
  },
})((props) => <FtrTypography type='body' fontSize='14' {...props} />);

function TechnicalFileNameDisplay(props) {
  const { item, file } = props;

  const classes = useStyles();
  const dispatch = useDispatch();

  const [hovered, setHoveredRow] = useState(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  const fileName = !isEmptyValue(file)
    ? getFileNameFromCadFile(file)
    : 'No technical drawing uploaded';

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        gap: '0.5rem',
        flexGrow: '1 1 auto',
        width: '100%',
        paddingRight: '0.5rem',
      }}
      onMouseEnter={() => setHoveredRow(true)}
      onMouseLeave={() => {
        setHoveredRow(false);
      }}
    >
      <img src={TechnicalDrawingFileIcon} alt='file-icon' />
      <FtrTypography type='heading' fontSize='16' className={classes.fileName}>
        {fileName}
      </FtrTypography>
      {(hovered || isMobile) && !isEmptyValue(file) && (
        <img
          src={DeleteDrawingIcon}
          alt='delete'
          style={{ cursor: 'pointer' }}
          onClick={() => {
            dispatch(removeTechnicalDrawing(item.id, file));
          }}
        />
      )}
    </div>
  );
}

function ConfigurePartFormV2(props) {
  const {
    open,
    selectedItemIndex,
    onClose = () => {},
    navigateItem = () => {},
  } = props;

  const classes = useStyles();

  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const itemState = useSelector((state) => state.item.formDataAvailable);
  const currency = useSelector((state) => state.auth.location.currency);
  const exchangeRates = useSelector((state) => state.auth.rates);
  const { userID, dfmType } = useSelector((state) => state.auth.user);
  const exchangeRate = exchangeRates[currency];

  const item = itemState[selectedItemIndex];

  const imageContainerRef = useRef(null);
  const imageContainerWidth =
    imageContainerRef?.current?.getBoundingClientRect()?.width ?? 0;

  const defaultFormState = {
    name: '',
    technology: '',
    otherTechnology: '',
    material: '',
    surfaceFinish: '',
    anodizingType: null,
    otherSurfaceFinish: '',
    threeDTechnology: '',
    threeDInfill: '',
    threeDLayerThickness: '',
    tolerance: '',
    remarks: '',
    qty: 1,
    price: null,
    totalPrice: null,
    partApplication: '',
    materialColor: '',
    customMaterialColor: '',
    color: '',
    customColor: '',
    targetPrice: '',
    originalTargetPrice: '',
    expectedLeadTime: null,
    unitType: UNIT_TYPES.METRIC,
  };

  const [formState, updateFormState] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      ...defaultFormState,
    }
  );

  const [
    {
      technologyOptions,
      materialCategoryOptions,
      threeDTechnologyOptions,
      threeDMaterialOptions,
      surfaceFinishOptions,
      materialColorOptions,
      surfaceFinishColorOptions,
      defaultThreeDMaterial,
      loadingStatus,
      isConfigurationLoading,
    },
    {
      loadSelectColorSurfaces,
      loadTechnologyOptions,
      loadMaterialCategoryOptions,
      load3DTechnologyOptions,
      loadThreeDMaterialOptions,
      loadSurfaceFinishOptions,
      loadMaterialColorOptions,
      loadSurfaceFinishColorOptions,
      technologyHasChanged,
      materialHasChanged,
      threeDTechnologyHasChanged,
      surfaceFinishHasChanged,
      setThreeDMaterialOptions,
      setMaterialColorOptions,
      setSurfaceFinishColorOptions,
    },
  ] = useItemInputConfig({
    setTechnology: (technology) => {
      updateFormState({ technology });
      dispatch(
        updateCadPartIfExists({
          id: item.id,
          technology,
        })
      );
    },
    setMaterial: (material) => {
      updateFormState({ material });
      dispatch(
        updateCadPartIfExists({
          id: item.id,
          material,
        })
      );
    },
    setThreeDTechnology: (threeDTechnology) => {
      updateFormState({ threeDTechnology });
      dispatch(
        updateCadPartIfExists({
          id: item.id,
          threeDTechnology,
        })
      );
    },
    setSurfaceFinish: (surfaceFinish) => {
      updateFormState({ surfaceFinish });
      dispatch(
        updateCadPartIfExists({
          id: item.id,
          surfaceFinish,
        })
      );
    },
    setMaterialColor: (materialColor) => {
      updateFormState({ materialColor });
      dispatch(
        updateCadPartIfExists({
          id: item.id,
          materialColor,
        })
      );
    },
    setColor: (color) => {
      updateFormState({ color });
      dispatch(
        updateCadPartIfExists({
          id: item.id,
          color,
        })
      );
    },
  });

  const [isAddingRemarks, setIsAddingRemarks] = useState(false);
  const [openBulkPricingDialog, setOpenBulkPricingDialog] = useState(false);
  const [showReplacePartPopup, setShowReplacePartPopup] = useState(false);
  const [openApplyToAllDialog, setOpenApplyToAllDialog] = useState(false);
  // this state is to make sure that the tolerance taken from TDE is a standard tolerance we have in our set
  const [customizedTolerance, setCustomizedTolerance] = useState(
    !STANDARD_TOLERANCE_OPTIONS[UNIT_TYPES.METRIC].includes(formState.tolerance)
  );
  const [customTolerance, setCustomTolerance] = useState(0);
  const [customToleranceError, setCustomToleranceError] = useState(false);
  const [firstLoad, setFirstLoad] = useState(false);

  const itemFiles = item.cadPart;
  const drawingFiles = getTechnicalDrawingFiles(item.cadPart);

  const { unitPriceStr, totalPriceStr } = useMemo(() => {
    if (
      isEmptyValue(formState.totalPrice) ||
      isEmptyValue(formState.qty) ||
      !isNumber(formState.qty) ||
      Number(formState.qty) <= 0
    ) {
      return {};
    }

    return convertPriceWithQuantityToCurrency({
      totalPrice: formState.totalPrice ?? 0,
      quantity: formState.qty ?? 1,
      currency,
      exchangeRate,
    });
  }, [formState.totalPrice, formState.qty]);

  const isSkeleton = useMemo(() => {
    return item.tdeStatus === 'loading';
  }, [item.id, item.tdeStatus]);

  const surfaceFinishTooltip = useMemo(() => {
    const finish = surfaceFinishOptions.find((finish) => {
      return finish.value === formState.surfaceFinish;
    });

    return finish?.tooltip;
  }, [surfaceFinishOptions, formState.surfaceFinish]);

  // --------------------------------------------------------------------------

  const validateForm = useCallback(() => {
    if (isEmptyValue(formState.qty)) {
      return false;
    }
    if (isEmptyValue(itemFiles)) {
      // notifyError('Missing CAD Part File.');
      return false;
    }
    if (
      isCustomTechnology(formState.technology) &&
      isEmptyValue(formState.otherTechnology)
    ) {
      // notifyError('Missing Custom Technology Field.');
      dispatch(
        updateCadPartIfExists({
          id: item.id,
          price: null,
        })
      );
      return false;
    }
    if (
      isCustomMaterial(formState.material) &&
      isEmptyValue(formState.otherMaterial)
    ) {
      // notifyError('Missing Custom Material Field.');
      dispatch(
        updateCadPartIfExists({
          id: item.id,
          price: null,
        })
      );
      return false;
    }
    if (
      isCustomSurfaceFinish(formState.surfaceFinish) &&
      isEmptyValue(formState.otherSurfaceFinish)
    ) {
      dispatch(
        updateCadPartIfExists({
          id: item.id,
          price: null,
        })
      );
      // notifyError('Missing Custom Surface Finish Field.');
      return false;
    }
    if (
      formState.materialColor === 'Custom Color' &&
      isEmptyValue(formState.customMaterialColor)
    ) {
      dispatch(
        updateCadPartIfExists({
          id: item.id,
          price: null,
        })
      );
      return false;
    }
    if (
      formState.color === 'Custom Color' &&
      isEmptyValue(formState.customColor)
    ) {
      dispatch(
        updateCadPartIfExists({
          id: item.id,
          price: null,
        })
      );
      return false;
    }
    // if (!isEmptyValue(surfaceFinishColorOptions) && isEmptyValue(color)) {
    //   notifyError('Missing Color field.');
    //   return;
    // }
    // if (technology === 'Sheet Metal Fabrication' && isEmptyValue(partApplication)) {
    //   notifyError('Missing Part Application Field.');
    //   return;
    // }
    return true;
  }, [
    formState.qty,
    itemFiles,
    formState.technology,
    formState.otherTechnology,
    formState.material,
    formState.otherMaterial,
    formState.surfaceFinish,
    formState.otherSurfaceFinish,
    formState.materialColor,
    formState.customMaterialColor,
    formState.color,
    formState.customColor,
  ]);

  const isFormValid = useMemo(
    () => validateForm(),
    [
      formState.qty,
      itemFiles,
      formState.technology,
      formState.otherTechnology,
      formState.material,
      formState.otherMaterial,
      formState.surfaceFinish,
      formState.otherSurfaceFinish,
      formState.materialColor,
      formState.customMaterialColor,
      formState.color,
      formState.customColor,
    ]
  );

  const waiting = formState.loading === true;

  const isPPEItem = !!formState.price;

  useEffect(() => {
    if (isEmptyValue(formState.technology)) {
      return;
    }

    loadTechnologyOptions(false);
    loadSelectColorSurfaces({ technology: formState.technology });
    loadMaterialCategoryOptions({ technology: formState.technology }, false);
    if (is3DPTechnology(formState.technology)) {
      load3DTechnologyOptions(false);
      loadThreeDMaterialOptions(
        {
          technology: formState.technology,
          threeDTechnology: formState.threeDTechnology,
        },
        false
      ).catch(() => {
        setThreeDMaterialOptions(['Custom Material']);
      });
    }

    // only load surface finish options on the first time
    // when the popup is open to prevent double calling the API
    // when customer change the technology
    if (!firstLoad) {
      const params = {
        technology: formState.technology,
        threeDTechnology: formState.threeDTechnology,
        material: formState.material,
      };
      loadSurfaceFinishOptions(params, false);
      if (formState.materialColor) {
        loadMaterialColorOptions(params, false);
      }
    }

    setFirstLoad(true);
  }, [formState.technology]);

  useEffect(() => {
    setFirstLoad(false);
  }, [selectedItemIndex]);

  useEffect(() => {
    if (isEmptyValue(formState.materialColor)) {
      setMaterialColorOptions(null);
      return;
    }

    if (!isEmptyValue(materialCategoryOptions)) {
      return;
    }

    const params = {
      technology: formState.technology,
      threeDTechnology: formState.threeDTechnology,
      material: formState.material,
    };
    loadMaterialColorOptions(params, false);
  }, [formState.materialColor]);

  useEffect(() => {
    if (isEmptyValue(formState.color)) {
      setSurfaceFinishColorOptions([]);
      return;
    }

    loadSurfaceFinishColorOptions(
      {
        technology: formState.technology,
        surfaceFinish: formState.surfaceFinish,
      },
      false
    );
  }, [formState.color]);

  useEffect(() => {
    updateFormState({ originalTargetPrice: item.originalTargetPrice });
  }, [item.originalTargetPrice]);

  useEffect(() => {
    if (item.tdeStatus === 'success') {
      const materialParams = {
        technology: formState.technology,
        threeDTechnology: formState.threeDTechnology,
        material: item.material || formState.material,
      };
      loadMaterialColorOptions(materialParams, false);
      loadSurfaceFinishOptions(materialParams, false);
    }
  }, [item.tdeStatus]);

  useEffect(() => {
    const drawingFiles = itemFiles?.filter((fileName) =>
      fileName?.endsWith('.pdf')
    );
    updateFormState({ drawingFiles });
  }, [itemFiles && hash(itemFiles)]);

  useEffect(() => {
    const updatedItem = {
      ...defaultFormState,
      ...extractFormStateFromItem(item),
      loading: false,
      remarksSaved: formState.id !== item.id ? false : formState.remarksSaved,
    };
    updateFormState(updatedItem);

    if (formState.id !== item.id && isEmptyValue(item.remarks)) {
      setIsAddingRemarks(false);
    } else if (item.remarks) {
      setIsAddingRemarks(true);
    }

    if (formState.color) {
      loadSurfaceFinishColorOptions(
        {
          technology: formState.technology,
          surfaceFinish: formState.surfaceFinish,
        },
        false
      );
    }
  }, [hash(item)]);

  useEffect(() => {
    if (!isEmptyValue(formState.tolerance)) {
      const isCustomTolerance = !STANDARD_TOLERANCE_OPTIONS[
        UNIT_TYPES.METRIC
      ].includes(formState.tolerance.toString());
      setCustomizedTolerance(isCustomTolerance);
      const displayedTolerance =
        formState.unitType === UNIT_TYPES.IMPERIAL
          ? convertMetricToImperial(formState.tolerance)
          : formState.tolerance;
      setCustomTolerance(displayedTolerance);
    }
  }, [formState.tolerance]);

  function getUpdatedPart() {
    const {
      name,
      remarks,
      originalTargetPrice,
      threeDTechnology,
      threeDInfill,
      threeDLayerThickness,
      ...restFormState
    } = formState;

    let updatedPart = {
      ...item,
      ...restFormState,
      id: item.id,
      status: item.status,
      userID: item.userID,
      remarks,
      name,
      deliveryPreference: 'on_premise',
      checked: item.checked,
      originalTargetPrice,
      targetPrice: originalTargetPrice
        ? currency !== CURRENCY.SGD
          ? originalTargetPrice / exchangeRate
          : originalTargetPrice
        : '',
      ...(is3DPTechnology(formState.technology) && {
        threeDTechnology,
        threeDInfill,
        threeDLayerThickness,
      }),
    };

    return updatedPart;
  }

  function resetOptionalFormState() {
    return {
      materialColor: null,
      customMaterialColor: null,
      color: null,
      customColor: null,
    };
  }

  const removeTDEGenField = (fieldsToRemove) => {
    const generatedFields = new Set(item.generatedFields || []);
    fieldsToRemove.forEach((field) => generatedFields.delete(field));
    dispatch(
      updateCadPartIfExists({
        id: item.id,
        generatedFields: Array.from(generatedFields),
      })
    );
  };

  const handleChangeUnitType = (unitType) => {
    removeTDEGenField(['unitType']);

    if (unitType === UNIT_TYPES.IMPERIAL) {
      setCustomTolerance(convertMetricToImperial(customTolerance));
    } else {
      setCustomTolerance(convertImperialToMetric(customTolerance));
    }
    setCustomizedTolerance(true);

    updateFormState({ unitType });
    dispatch(
      updateCadPartIfExists({
        id: item.id,
        unitType,
      })
    );
  };

  const handleClose = () => {
    if (!isFormValid || isConfigurationLoading) {
      return;
    }
    onClose();
  };

  const handleGetPpePriceForItem = async (itemID) => {
    if (formState.originalTargetPrice || formState.expectedLeadTime) {
      return;
    }

    dispatch(
      updateCadPartIfExists({
        id: itemID,
        ppePricingStatus: 'loading',
      })
    );

    for (let i = 0; i < 10; i++) {
      if (isConfigurationLoading) {
        // wait for max 5 seconds then get PPE price
        await new Promise((resolve) => setTimeout(resolve, 500));
      }
    }

    dispatch(getPpePriceForCadPart(itemID));
  };

  const handleUploadTechnicalDrawing = (newFiles) => {
    const uploadedUrls = [];
    Promise.all(
      Array.from(newFiles).map(async (file) => {
        const { s3ObjectUrl } = await uploadCadFileToS3(file);
        if (!isEmptyValue(s3ObjectUrl)) {
          uploadedUrls.push(s3ObjectUrl);
        }
      })
    ).then(() => {
      dispatch(addTechnicalDrawingAndGetPrice(item.id, uploadedUrls)).catch(
        () => {
          notifyError('Error with upload');
        }
      );
    });
  };

  const handleReplaceCadFile = async (files) => {
    const file = files[0];
    const newName = getItemNameFromCadFile(file.name);
    dispatch(replaceCadFile(item.id, file));
    if (formState.nameEdited !== true) {
      updateFormState({
        name: newName,
      });
      dispatch(
        updateCadPartIfExists({
          id: item.id,
          name: newName,
        })
      );
    }
  };

  const handleConfirmApplyToAllDialog = async (
    fieldsToUpdate,
    partsToUpdate
  ) => {
    setOpenApplyToAllDialog(false);
    const partIDsToUpdate = partsToUpdate.map((part) => part.id);
    for (const partID of partIDsToUpdate) {
      const updatedPart = {
        id: partID,
        ...fieldsToUpdate,
      };
      dispatch(updateCadPartIfExists(updatedPart));
      handleGetPpePriceForItem(partID);
    }
  };

  const renderTDEGenImage = (fieldNames = []) => {
    const generatedFieldsSet = new Set(item.generatedFields || []);
    if (fieldNames.some((fieldName) => generatedFieldsSet.has(fieldName))) {
      return (
        <Tooltip title={'Automatically extracted parameter'} arrow>
          <div>
            <FtrSvgImage
              src={AutoAwesomeIcon}
              className={classes.autoAwesomeIcon}
            />
          </div>
        </Tooltip>
      );
    }
    return null;
  };

  const render2DImage = () => {
    const imageUrl = item[TWO_D_IMAGE_URLS_KEY]
      ? item[TWO_D_IMAGE_URLS_KEY][0]
      : null;
    return (
      <div
        ref={imageContainerRef}
        style={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <ImageWith3DViewer
          key={item.id}
          cadFile={itemFiles?.join(',')}
          twoDImageUrl={imageUrl}
          width={imageContainerWidth}
          height={imageContainerWidth}
          noBorder
          isLoading={item.imageStatus === 'loading'}
          borderRadius={20}
        />
      </div>
    );
  };

  const renderPPEItemInfo = () => {
    return (
      <Fragment>
        <FtrTypography
          type='heading'
          fontSize='14'
          style={{ padding: '4px 0' }}
        >
          Instant Pricing
        </FtrTypography>
        <div className={classes.itemField}>
          <ItemFieldText>Unit Price</ItemFieldText>
          <FtrTypography type='body' fontSize='14'>
            {unitPriceStr}
          </FtrTypography>
        </div>
        <div className={classes.itemField}>
          <ItemFieldText>Quantity</ItemFieldText>
          <FtrTypography type='body' fontSize='14'>
            {item.qty}
          </FtrTypography>
        </div>
        <div className={classes.itemField}>
          <ItemFieldText>Total Price</ItemFieldText>
          <FtrTypography type='body' fontSize='14'>
            {totalPriceStr}
          </FtrTypography>
        </div>
        {!item.repeatOrder && (
          <ViewBulkPricingButton
            onClick={() => setOpenBulkPricingDialog(true)}
          />
        )}
        <div className={classes.itemField}>
          <ItemFieldText>Lead Time</ItemFieldText>
          <FtrTypography type='body' fontSize='14'>
            {displayLeadTime(item)}
          </FtrTypography>
        </div>
      </Fragment>
    );
  };

  const renderItemInfo = () => {
    return item.ppePricingStatus === 'loading' ? (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <img
          src={DollarSymbolIcon}
          className={classes.bounce}
          alt='dollar-symbol-icon'
        />
        <div>
          <FtrTypography type='heading' fontSize='16'>
            Calculating price
          </FtrTypography>
          <FtrTypography
            type='subHeading'
            fontSize='14'
            style={{ color: colors.neutral050 }}
          >
            Fetching past orders
          </FtrTypography>
        </div>
      </div>
    ) : isPPEItem ? (
      renderPPEItemInfo()
    ) : (
      <ManualQuotationRequiredBanner />
    );
  };

  const renderPartSpecsSummary = () => {
    return (
      <>
        <Grid item style={{ paddingBottom: isMobile ? '2rem' : 0 }}>
          <FtrTypography
            type='heading'
            fontSize='14'
            style={{ padding: '4px 0' }}
          >
            Part Specs
          </FtrTypography>
          <div className={classes.itemField}>
            <ItemFieldText>Dimensions</ItemFieldText>
            <FtrTypography type='body' fontSize='14'>
              {displayBoundingBoxValues(
                item.boundingBoxX,
                item.boundingBoxY,
                item.boundingBoxZ,
                formState.unitType
              )}
            </FtrTypography>
          </div>
          <div className={classes.itemField}>
            <ItemFieldText>Technology</ItemFieldText>
            <FtrTypography type='body' fontSize='14'>
              {formState.technology}
            </FtrTypography>
          </div>
          {is3DPTechnology(formState.technology) && (
            <div className={classes.itemField}>
              <ItemFieldText>3D Printing Technology</ItemFieldText>
              <FtrTypography
                type='body'
                fontSize='14'
                style={{ textAlign: 'right' }}
              >
                {formState.threeDTechnology}
              </FtrTypography>
            </div>
          )}
          {isCustomTechnology(formState.technology) &&
            formState.otherTechnology && (
              <div className={classes.itemField}>
                <ItemFieldText>Custom Technology</ItemFieldText>
                <FtrTypography type='body' fontSize='14'>
                  {formState.otherTechnology}
                </FtrTypography>
              </div>
            )}
          <div className={classes.itemField}>
            <ItemFieldText>Material</ItemFieldText>
            <FtrTypography type='body' fontSize='14'>
              {formState.material}
            </FtrTypography>
          </div>
          {isCustomMaterial(formState.material) && formState.otherMaterial && (
            <div className={classes.itemField}>
              <ItemFieldText>Custom Material</ItemFieldText>
              <FtrTypography type='body' fontSize='14'>
                {formState.otherMaterial}
              </FtrTypography>
            </div>
          )}
          {!isEmptyValue(materialColorOptions) && (
            <div className={classes.itemField}>
              <ItemFieldText>Material Color</ItemFieldText>
              <FtrTypography type='body' fontSize='14'>
                {formState.materialColor}
              </FtrTypography>
            </div>
          )}
          {is3DPTechnology(formState.technology) &&
            formState.threeDTechnology === THREE_D_P_FDM_TECH && (
              <Fragment>
                <div className={classes.itemField}>
                  <ItemFieldText>3D Infill</ItemFieldText>
                  <FtrTypography type='body' fontSize='14'>
                    {Number(formState.threeDInfill * 100).toFixed(0)}%
                  </FtrTypography>
                </div>
                <div className={classes.itemField}>
                  <ItemFieldText>Layer Thickness</ItemFieldText>
                  <FtrTypography type='body' fontSize='14'>
                    {formState.threeDLayerThickness}mm
                  </FtrTypography>
                </div>
              </Fragment>
            )}
          <div className={classes.itemField}>
            <ItemFieldText>Surface Finish</ItemFieldText>
            <FtrTypography type='body' fontSize='14'>
              {formState.surfaceFinish == 'NIL' ||
              formState.surfaceFinish == null
                ? 'NIL'
                : formState.surfaceFinish}
            </FtrTypography>
          </div>
          {isCustomSurfaceFinish(formState.surfaceFinish) &&
            formState.otherSurfaceFinish && (
              <div className={classes.itemField}>
                <ItemFieldText>Custom Surface Finish</ItemFieldText>
                <FtrTypography type='body' fontSize='14'>
                  {formState.otherSurfaceFinish}
                </FtrTypography>
              </div>
            )}
          {isAnodizingSurfaceFinish(formState.surfaceFinish) &&
            formState.anodizingType && (
              <div className={classes.itemField}>
                <ItemFieldText>Anodizing Type</ItemFieldText>
                <FtrTypography type='body' fontSize='14'>
                  {capitalizeString(formState.anodizingType)}
                </FtrTypography>
              </div>
            )}
          {formState.color && (
            <div className={classes.itemField}>
              <ItemFieldText>Finish Color</ItemFieldText>
              <FtrTypography type='body' fontSize='14'>
                {formState.color}
              </FtrTypography>
            </div>
          )}
          <div className={classes.itemField}>
            <ItemFieldText>Tolerance</ItemFieldText>
            <FtrTypography type='body' fontSize='14'>
              ±
              {showUnitValueFromMetric(formState.tolerance, formState.unitType)}
            </FtrTypography>
          </div>
        </Grid>
        {(formState.originalTargetPrice || formState.expectedLeadTime) && (
          <Grid item className={classes.orderSpecs}>
            <FtrTypography
              type='heading'
              fontSize='14'
              style={{ padding: '4px 0' }}
            >
              Order Specs
            </FtrTypography>
            {formState.originalTargetPrice && (
              <div className={classes.itemField}>
                <ItemFieldText>Target Unit Price</ItemFieldText>
                <FtrTypography type='body' fontSize='14'>
                  {getCurrencySymbol(currency)}
                  {formState.originalTargetPrice} / unit
                </FtrTypography>
              </div>
            )}
            {formState.expectedLeadTime && (
              <div className={classes.itemField}>
                <ItemFieldText>Target Delivery Date</ItemFieldText>
                <FtrTypography type='body' fontSize='14'>
                  {getDateStrWithMonth(formState.expectedLeadTime)}
                </FtrTypography>
              </div>
            )}
          </Grid>
        )}
      </>
    );
  };

  const renderPartsNavigationButtons = () => {
    return (
      <FlexRowCenter>
        <CircleButton
          size='small'
          onClick={() => {
            updateFormState({
              ...resetOptionalFormState(),
              loading: true,
            });
            let newUpdatedPart = getUpdatedPart();
            dispatch(updateCadPartIfExists(newUpdatedPart));
            navigateItem(-1);
          }}
          disabled={waiting}
          style={{
            visibility: selectedItemIndex > 0 ? 'visible' : 'hidden',
          }}
        >
          <img src={ArrowLeftIcon} />
        </CircleButton>
        <FtrBoldText style={{ color: colors.neutral060 }}>
          Part {Number(selectedItemIndex) + 1} of {itemState.length}
        </FtrBoldText>
        <CircleButton
          size='small'
          onClick={() => {
            updateFormState({
              ...resetOptionalFormState(),
              loading: true,
            });
            let newUpdatedPart = getUpdatedPart();
            dispatch(updateCadPartIfExists(newUpdatedPart));
            navigateItem(1);
          }}
          disabled={waiting}
          style={{
            visibility:
              selectedItemIndex < itemState.length - 1 ? 'visible' : 'hidden',
          }}
        >
          <img src={ArrowRightIcon} />
        </CircleButton>
      </FlexRowCenter>
    );
  };

  const renderTargetUnitPriceField = () => {
    return (
      <Fragment>
        <FieldLabel id='target-unit-price-text'>Target Unit Price</FieldLabel>
        <TextField
          size='small'
          margin='none'
          type='number'
          variant='outlined'
          style={{ width: '100%' }}
          inputProps={{ min: 0 }}
          value={formState.originalTargetPrice}
          onChange={(evt) => {
            updateFormState({ originalTargetPrice: evt.target.value });
            upsertPriceFeedback({
              iqLogsID: item.ppePriceLogId,
              userID: userID,
              priceFeedback: FEEDBACK_STATUS.BAD,
              targetPrice: evt.target.value,
            });
          }}
          InputProps={{
            style: {
              borderRadius: '10px',
            },
            startAdornment: (
              <InputAdornment position='start'>
                {getCurrencySymbol(currency)}
              </InputAdornment>
            ),
          }}
          onBlur={(evt) => {
            dispatch(
              updateTargetUnitPriceForPart({
                id: item.id,
                originalTargetPrice: evt.target.value,
              })
            );

            // handle if we reset targetPrice
            if (!evt.target.value) {
              dispatch(
                updateCadPartIfExists({
                  id: item.id,
                  priceFeedback: null,
                })
              );
              upsertPriceFeedback({
                iqLogsID: item.ppePriceLogId,
                userID: userID,
                priceFeedback: null,
                targetPrice: null,
              });
            }
            // also log down into the feedback
          }}
          disabled={waiting || item.ppePricingStatus === 'loading'}
          data-cy='target-unit-price-field'
        />
      </Fragment>
    );
  };

  const renderTargetLeadTimeField = () => {
    return (
      <>
        <FieldLabel>Target Delivery Date</FieldLabel>
        <DatePicker
          InputProps={{
            style: {
              borderRadius: '10px',
            },
          }}
          disableToolbar
          value={formState.expectedLeadTime ?? null}
          onChange={(expectedLeadTime) => {
            updateFormState({ expectedLeadTime });
            dispatch(
              updateExpectedLeadTimeForPart({
                id: item.id,
                expectedLeadTime,
              })
            );
            let leadTimeFeedback = null;
            if (expectedLeadTime !== null) {
              leadTimeFeedback = FEEDBACK_STATUS.BAD;
            }
            upsertLeadTimeFeedback({
              iqLogsID: item.ppePriceLogId,
              userID: userID,
              leadTimeFeedback,
              targetLeadTime: expectedLeadTime,
              givenLeadTime:
                Number(item.leadTime) + Number(item.markupLeadTime),
            });
          }}
          animateYearScrolling
          inputVariant='outlined'
          margin='dense'
          fullWidth
          clearable
          clearLabel='No Preference'
          emptyLabel='No Preference'
          disablePast
        />
      </>
    );
  };

  const renderQuantityField = () => {
    return (
      <Fragment>
        <FieldLabel>
          <FlexRow>
            Quantity
            {renderTDEGenImage(['qty'])}
          </FlexRow>
        </FieldLabel>
        <div style={{ position: 'relative' }}>
          {isSkeleton && renderSkeletonOverlay()}
          <TextField
            size='small'
            type='number'
            margin='none'
            value={formState.qty}
            variant='outlined'
            inputProps={{ min: 1 }}
            InputProps={{
              style: {
                borderRadius: '10px',
              },
              inputProps: { min: 1 },
            }}
            style={{ width: '100%' }}
            onChange={(evt) => {
              let value = evt.target.value;
              updateFormState({ qty: value });
              if (isEmptyValue(value) || Number(value) <= 0) {
                return;
              }
              removeTDEGenField(['qty']);
              dispatch(
                updateCadPartIfExists({
                  id: item.id,
                  qty: value,
                })
              );
              handleGetPpePriceForItem(item.id);
            }}
            // disable change value when mouse scroll
            onWheel={(e) => e.target.blur()}
            error={isEmptyValue(formState.qty) || Number(formState.qty) <= 0}
            helperText={
              isEmptyValue(formState.qty) || Number(formState.qty) <= 0
                ? 'Invalid value'
                : null
            }
            disabled={waiting || isSkeleton}
          />
        </div>
      </Fragment>
    );
  };

  const resetMaterialAndSurfaceFinishTDEGen = () => {
    if (!isEmptyValue(item?.generatedFields)) {
      const updatedGeneratedFields = new Set(
        [
          item.generatedFields.includes('unitType') ? 'unitType' : undefined,
          item.generatedFields.includes('qty') ? 'qty' : undefined,
        ].filter((field) => field !== undefined)
      );

      if (item.id) {
        dispatch(
          updateCadPartIfExists({
            id: item.id,
            generatedFields: Array.from(updatedGeneratedFields),
          })
        );
      }
    }
  };

  const renderTechnologyField = () => {
    return (
      <>
        <FieldLabel>Technology</FieldLabel>
        <FtrDropdown
          key='technology-dropdown'
          fullWidth
          value={formState.technology}
          handleChange={(newTech) => {
            const technology = newTech;
            let tolerance = getDefaultTolerance({
              technology,
              unitType: formState.unitType,
            });
            let updatedFormState = {
              technology,
              tolerance,
              otherTechnology: null,
              otherMaterial: null,
              otherSurfaceFinish: null,
            };
            if (is3DPTechnology(technology)) {
              updatedFormState = {
                ...updatedFormState,
                threeDTechnology: THREE_D_P_FDM_TECH,
                threeDInfill: threeDPrintingInfillDefault,
                threeDLayerThickness: threeDPrintingLayerThicknessDefault,
              };
            } else {
              updatedFormState = {
                ...updatedFormState,
                threeDTechnology: null,
                threeDInfill: null,
                threeDLayerThickness: null,
              };
            }
            updateFormState(updatedFormState);
            dispatch(
              updateCadPartIfExists({
                id: item.id,
                ...updatedFormState,
              })
            );

            resetMaterialAndSurfaceFinishTDEGen();
            technologyHasChanged(technology);

            // handleGetPpePriceForItem(item.id);
            setTimeout(() => {
              // change this code to async to let the input loading first
              handleGetPpePriceForItem(item.id);
            }, 500);

            // update defects
            dispatch(getDefectForCadPart(item.id));
          }}
          items={technologyOptions}
          loading={isSkeleton}
          disabled={waiting || isConfigurationLoading || isSkeleton}
        />
      </>
    );
  };

  const renderCustomTechnologyField = () => {
    return (
      <>
        <FieldLabel>Custom Technology</FieldLabel>
        <div style={{ position: 'relative' }}>
          {isSkeleton && renderSkeletonOverlay()}
          <TextField
            size='small'
            type='text'
            margin='none'
            value={formState.otherTechnology}
            variant='outlined'
            InputProps={{
              style: {
                borderRadius: '10px',
              },
            }}
            style={{ width: '100%' }}
            onChange={(evt) =>
              updateFormState({ otherTechnology: evt.target.value })
            }
            onBlur={(evt) => {
              dispatch(
                updateCadPartIfExists({
                  id: item.id,
                  otherTechnology: evt.target.value,
                })
              );
              handleGetPpePriceForItem(item.id);
            }}
            disabled={waiting || isSkeleton}
            error={
              isCustomTechnology(formState.technology) &&
              isEmptyValue(formState.otherTechnology)
            }
            helperText={
              isCustomTechnology(formState.technology) &&
              isEmptyValue(formState.otherTechnology)
                ? 'Required'
                : ''
            }
          />
        </div>
      </>
    );
  };

  const renderPartNameField = () => {
    function updatePartName(partNameValue) {
      updateFormState({
        name: partNameValue,
        nameEdited: true,
      });
      dispatch(
        updateCadPartIfExists({
          id: item.id,
          name: partNameValue,
        })
      );
    }

    return (
      <PartNameField
        key={formState.name}
        partName={formState.name}
        updatePartName={updatePartName}
      />
    );
  };

  const renderSurfaceFinishField = () => {
    if (
      isEmptyValue(formState.surfaceFinish) ||
      isEmptyValue(surfaceFinishOptions)
    ) {
      return null;
    }

    return (
      <Grid item>
        <FieldLabel>
          <FlexRow>
            Surface Finish
            {renderTDEGenImage(['surfaceFinish', 'otherSurfaceFinish'])}
          </FlexRow>
        </FieldLabel>
        <FtrDropdown
          id='surface-finish-dropdown'
          key='surface-finish-dropdown'
          fullWidth
          value={formState.surfaceFinish}
          handleChange={(newValue) => {
            removeTDEGenField([
              'surfaceFinish',
              'otherSurfaceFinish',
              'color',
              'customColor',
              'anodizingType',
            ]);
            const anodizingType = isAnodizingSurfaceFinish(newValue)
              ? ANODIZING_TYPE_OPTIONS[0].key
              : null;

            updateFormState({
              surfaceFinish: newValue,
              otherSurfaceFinish: null,
              color: null,
              customColor: null,
              anodizingType,
            });
            dispatch(
              updateCadPartIfExists({
                id: item.id,
                surfaceFinish: newValue,
                otherSurfaceFinish: null,
                color: null,
                customColor: null,
                anodizingType,
              })
            );
            const params = {
              technology: formState.technology,
              material: formState.material,
              surfaceFinish: newValue,
            };
            surfaceFinishHasChanged(params);
            handleGetPpePriceForItem(item.id);
          }}
          items={surfaceFinishOptions}
          tooltip={surfaceFinishTooltip}
          disabled={waiting || isSkeleton}
          loading={
            loadingStatus.loadSurfaceFinishOptions === true ||
            loadingStatus.loadMaterialCategoryOptions === true ||
            loadingStatus.loadThreeDMaterialOptions === true ||
            loadingStatus.loadTechnologyOptions === true ||
            isSkeleton
          }
        />
      </Grid>
    );
  };

  const renderAnodizingTypeField = () => {
    if (!isAnodizingSurfaceFinish(formState.surfaceFinish)) {
      return;
    }

    return (
      <Grid item>
        <FieldLabel>
          <FlexRow>
            Anodizing Type
            {renderTDEGenImage(['anodizingType'])}
          </FlexRow>
        </FieldLabel>
        <FtrDropdownV2
          id='anodizing-type-dropdown'
          key='anodizing-type-dropdown'
          fullWidth
          value={formState.anodizingType}
          handleChange={(newValue) => {
            removeTDEGenField(['anodizingType']);

            updateFormState({
              anodizingType: newValue,
            });

            dispatch(
              updateCadPartIfExists({
                id: item.id,
                anodizingType: newValue,
              })
            );
          }}
          items={ANODIZING_TYPE_OPTIONS}
          disabled={waiting || isSkeleton}
          loading={
            loadingStatus.loadSurfaceFinishOptions === true ||
            loadingStatus.loadMaterialCategoryOptions === true ||
            loadingStatus.loadThreeDMaterialOptions === true ||
            loadingStatus.loadTechnologyOptions === true ||
            isSkeleton
          }
        />
      </Grid>
    );
  };

  const renderMaterialInputField = () => {
    return (
      <MaterialCategoriesInputField
        technology={formState.technology}
        visible={!is3DPTechnology(formState.technology)}
        value={formState.material}
        TDEGen={renderTDEGenImage(['material', 'otherMaterial'])}
        onSelect={(newValue) => {
          const params = {
            technology: formState.technology,
            threeDTechnology: formState.threeDTechnology,
            material: newValue,
          };
          resetMaterialAndSurfaceFinishTDEGen();
          updateFormState({
            material: newValue,
            otherMaterial: null,
          });
          dispatch(
            updateCadPartIfExists({
              id: item.id,
              material: newValue,
              otherMaterial: null,
            })
          );
          materialHasChanged(params);
          handleGetPpePriceForItem(item.id);
        }}
        materialCategoryOptions={materialCategoryOptions}
        disabled={
          waiting ||
          loadingStatus.loadSurfaceFinishOptions === true ||
          isSkeleton
        }
        loading={
          loadingStatus.loadMaterialCategoryOptions === true ||
          loadingStatus.loadTechnologyOptions === true ||
          isSkeleton
        }
        isBuyer={true}
      />
    );
  };

  const renderThreeDPrintingMaterialField = () => {
    return (
      <ThreeDPrintingMaterialField
        technology={formState.technology}
        threeDTechnology={formState.threeDTechnology}
        visible={is3DPTechnology(formState.technology)}
        value={formState.material}
        onSelect={(_material) => {
          updateFormState({
            material: _material,
            otherMaterial: null,
          });
          dispatch(
            updateCadPartIfExists({
              id: item.id,
              material: _material,
              otherMaterial: null,
            })
          );
          const params = {
            technology: formState.technology,
            threeDTechnology: formState.threeDTechnology,
            material: _material,
          };

          resetMaterialAndSurfaceFinishTDEGen();
          materialHasChanged(params);
          setTimeout(() => handleGetPpePriceForItem(item.id), 500);
        }}
        threeDMaterialOptions={threeDMaterialOptions}
        defaultThreeDMaterial={defaultThreeDMaterial}
        disabled={
          waiting ||
          loadingStatus.loadSurfaceFinishOptions === true ||
          isSkeleton
        }
        loading={loadingStatus.loadThreeDMaterialOptions === true || isSkeleton}
      />
    );
  };

  const renderCustomMaterialField = () => {
    return (
      isCustomMaterial(formState.material) &&
      !loadingStatus.loadMaterialCategoryOptions && (
        <Grid item>
          <FieldLabel>
            <FlexRow>
              Custom Material
              {renderTDEGenImage(['otherMaterial'])}
            </FlexRow>
          </FieldLabel>
          <div style={{ position: 'relative' }}>
            {isSkeleton && renderSkeletonOverlay()}
            <TextField
              size='small'
              type='text'
              margin='none'
              value={formState.otherMaterial}
              variant='outlined'
              InputProps={{
                style: {
                  borderRadius: '10px',
                },
              }}
              style={{ width: '100%' }}
              onChange={(evt) => {
                updateFormState({
                  otherMaterial: evt.target.value,
                });
              }}
              onBlur={(evt) => {
                removeTDEGenField(['otherMaterial']);
                dispatch(
                  updateCadPartIfExists({
                    id: item.id,
                    otherMaterial: evt.target.value,
                  })
                );
                handleGetPpePriceForItem(item.id);
              }}
              error={
                isCustomMaterial(formState.material) &&
                isEmptyValue(formState.otherMaterial)
              }
              helperText={
                isCustomMaterial(formState.material) &&
                isEmptyValue(formState.otherMaterial)
                  ? 'Required'
                  : ''
              }
              disabled={waiting || isSkeleton}
            />
          </div>
        </Grid>
      )
    );
  };

  const renderThreeDPrintingTechnologyInputField = () => {
    return (
      <ThreeDPrintingTechnologyInputField
        visible={is3DPTechnology(formState.technology)}
        value={formState.threeDTechnology}
        onChange={(newValue) => {
          const threeDTechnology = newValue;
          const tolerance = getDefaultTolerance({
            technology: formState.technology,
            unitType: formState.unitType,
            threeDTechnology,
          });
          let updatedFormState = {
            threeDTechnology,
            tolerance,
          };
          if (newValue === THREE_D_P_FDM_TECH) {
            updatedFormState = {
              ...updatedFormState,
              threeDInfill: threeDPrintingInfillDefault,
              threeDLayerThickness: threeDPrintingLayerThicknessDefault,
            };
          } else {
            updatedFormState = {
              ...updatedFormState,
              threeDInfill: '',
              threeDLayerThickness: '',
            };
          }
          updateFormState(updatedFormState);
          dispatch(
            updateCadPartIfExists({
              id: item.id,
              ...updatedFormState,
            })
          );
          const params = {
            technology: formState.technology,
            threeDTechnology: newValue,
          };
          threeDTechnologyHasChanged(params);
          setTimeout(() => {
            handleGetPpePriceForItem(item.id);
          }, 500);
        }}
        threeDTechnologyOptions={threeDTechnologyOptions}
        skeletonOverlay={isSkeleton}
        disabled={
          waiting ||
          loadingStatus.loadThreeDMaterialOptions === true ||
          loadingStatus.loadSurfaceFinishOptions === true ||
          isSkeleton
        }
        loading={
          loadingStatus.load3DTechnologyOptions === true ||
          loadingStatus.loadTechnologyOptions === true
        }
      />
    );
  };

  const renderCustomSurfaceFinishField = () => {
    return (
      !isEmptyValue(surfaceFinishOptions) &&
      isCustomSurfaceFinish(formState.surfaceFinish) &&
      loadingStatus.loadSurfaceFinishOptions !== true && (
        <Grid item>
          <FieldLabel>
            <FlexRow>
              Custom Surface Finish
              {renderTDEGenImage(['otherSurfaceFinish'])}
            </FlexRow>
          </FieldLabel>
          <div style={{ position: 'relative' }}>
            {isSkeleton && renderSkeletonOverlay()}
            <TextField
              size='small'
              type='text'
              margin='none'
              value={formState.otherSurfaceFinish}
              variant='outlined'
              InputProps={{
                style: {
                  borderRadius: '10px',
                },
              }}
              style={{ width: '100%' }}
              onChange={(evt) => {
                updateFormState({
                  otherSurfaceFinish: evt.target.value,
                });
              }}
              onBlur={(evt) => {
                removeTDEGenField(['otherSurfaceFinish']);
                dispatch(
                  updateCadPartIfExists({
                    id: item.id,
                    otherSurfaceFinish: evt.target.value,
                  })
                );
                handleGetPpePriceForItem(item.id);
              }}
              error={
                isCustomSurfaceFinish(formState.surfaceFinish) &&
                isEmptyValue(formState.otherSurfaceFinish)
              }
              helperText={
                isCustomSurfaceFinish(formState.surfaceFinish) &&
                isEmptyValue(formState.otherSurfaceFinish)
                  ? 'Required'
                  : ''
              }
              disabled={waiting || isSkeleton}
            />
          </div>
        </Grid>
      )
    );
  };

  const renderFinishColorField = () => {
    if (
      isEmptyValue(surfaceFinishOptions) ||
      isCustomSurfaceFinish(formState.surfaceFinish) ||
      isEmptyValue(surfaceFinishColorOptions) ||
      isEmptyValue(formState.color)
    ) {
      return null;
    }

    return (
      <Grid item>
        <FieldLabel>
          <FlexRow>
            Finish Color
            {renderTDEGenImage(['color', 'customColor'])}
          </FlexRow>
        </FieldLabel>
        <ColorFtrDropdown
          key='surface-finish-color-dropdown'
          fullWidth
          value={formState.color}
          handleChange={(newValue) => {
            const color = newValue || formState.color;
            removeTDEGenField(['color', 'customColor']);
            updateFormState({
              color,
              customColor: null,
            });
            dispatch(
              updateCadPartIfExists({
                id: item.id,
                color,
                customColor: null,
              })
            );
          }}
          colorPalette={surfaceFinishColorOptions}
          disabled={waiting || isSkeleton}
          loading={isSkeleton}
        />
      </Grid>
    );
  };

  const renderCustomFinishColorField = () => {
    if (
      (isEmptyValue(surfaceFinishOptions) &&
        !isCustomSurfaceFinish(formState.surfaceFinish)) ||
      isEmptyValue(surfaceFinishColorOptions) ||
      (formState.color !== 'Custom Color' &&
        isEmptyValue(formState.customColor))
    ) {
      return;
    }

    return (
      <Grid item>
        <FieldLabel>
          <FlexRow>
            Custom Finish Color
            {renderTDEGenImage(['customColor'])}
          </FlexRow>
        </FieldLabel>
        <div style={{ position: 'relative' }}>
          {isSkeleton && renderSkeletonOverlay()}
          <TextField
            size='small'
            type='text'
            margin='none'
            placeholder='e.g. Yellow orange (RAL 2000)'
            value={formState.customColor}
            variant='outlined'
            InputProps={{
              style: {
                borderRadius: '10px',
              },
            }}
            style={{ width: '100%' }}
            onChange={(evt) => {
              const color = evt.target.value;
              updateFormState({
                customColor: color,
              });
            }}
            onBlur={(evt) => {
              removeTDEGenField(['customColor']);
              const color = evt.target.value;
              dispatch(
                updateCadPartIfExists({
                  id: item.id,
                  customColor: color,
                })
              );
            }}
            error={
              formState.color === 'Custom Color' &&
              isEmptyValue(formState.customColor)
            }
            helperText={
              formState.color === 'Custom Color' &&
              isEmptyValue(formState.customColor)
                ? 'Required'
                : ''
            }
            disabled={waiting || isSkeleton}
          />
        </div>
        <Typography
          variant='body2'
          color='textSecondary'
          style={{ fontStyle: 'italic', fontSize: 'smaller' }}
        >
          Refer{' '}
          <a
            href='https://www.ralcolor.com/'
            target='_blank'
            rel='noopener noreferrer'
          >
            https://www.ralcolor.com/
          </a>{' '}
          for more colors.
        </Typography>
      </Grid>
    );
  };

  const renderMaterialColorField = () => {
    if (
      (isEmptyValue(materialCategoryOptions) &&
        isCustomMaterial(formState.material)) ||
      isEmptyValue(materialColorOptions) ||
      isEmptyValue(formState.materialColor)
    ) {
      return null;
    }

    return (
      <Grid item>
        <FieldLabel>
          <FlexRow>
            Material Color
            {renderTDEGenImage(['materialColor', 'customMaterialColor'])}
          </FlexRow>
        </FieldLabel>
        <ColorFtrDropdown
          key='material-color-dropdown'
          fullWidth
          value={formState.materialColor}
          handleChange={(newValue) => {
            const materialColor = newValue;
            updateFormState({
              materialColor,
            });
            removeTDEGenField(['materialColor', 'customMaterialColor']);
            dispatch(
              updateCadPartIfExists({
                id: item.id,
                materialColor,
              })
            );
            handleGetPpePriceForItem(item.id);
          }}
          colorPalette={materialColorOptions || []}
          disabled={waiting || isSkeleton}
          loading={isSkeleton}
        />
      </Grid>
    );
  };

  const renderCustomMaterialColorField = () => {
    if (
      (isEmptyValue(materialCategoryOptions) &&
        isCustomMaterial(formState.material)) ||
      isEmptyValue(materialColorOptions) ||
      formState.materialColor !== 'Custom Color'
    ) {
      return null;
    }

    return (
      <Grid item>
        <FieldLabel>
          <FlexRow>
            Custom Material Color
            {renderTDEGenImage(['customMaterialColor'])}
          </FlexRow>
        </FieldLabel>
        <div style={{ position: 'relative' }}>
          {isSkeleton && renderSkeletonOverlay()}
          <TextField
            size='small'
            type='text'
            margin='none'
            placeholder='e.g. Yellow orange (RAL 2000)'
            value={formState.customMaterialColor}
            variant='outlined'
            InputProps={{
              style: {
                borderRadius: '10px',
              },
            }}
            style={{ width: '100%' }}
            onChange={(evt) => {
              const materialColor = evt.target.value;
              updateFormState({
                customMaterialColor: materialColor,
              });
            }}
            onBlur={(evt) => {
              removeTDEGenField(['customMaterialColor']);
              const materialColor = evt.target.value;
              dispatch(
                updateCadPartIfExists({
                  id: item.id,
                  customMaterialColor: materialColor,
                })
              );
            }}
            error={
              formState.materialColor === 'Custom Color' &&
              isEmptyValue(formState.customMaterialColor)
            }
            helperText={
              formState.materialColor === 'Custom Color' &&
              isEmptyValue(formState.customMaterialColor)
                ? 'Required'
                : ''
            }
            disabled={waiting || isSkeleton}
          />
        </div>
        <Typography
          variant='body2'
          color='textSecondary'
          style={{ fontStyle: 'italic', fontSize: 'smaller' }}
        >
          Refer{' '}
          <a
            href='https://www.ralcolor.com/'
            target='_blank'
            rel='noopener noreferrer'
          >
            https://www.ralcolor.com/
          </a>{' '}
          for more colors.
        </Typography>
      </Grid>
    );
  };

  const renderThreeDInfillField = () => {
    return (
      <ThreeDInfillSelectField
        visible
        value={formState.threeDInfill}
        onSelect={(newValue) => {
          updateFormState({
            threeDInfill: newValue,
          });
          dispatch(
            updateCadPartIfExists({
              id: item.id,
              threeDInfill: newValue,
            })
          );
          handleGetPpePriceForItem(item.id);
        }}
        ppe3dpInfillOptions={ppe3dpInfillOptions}
        disabled={waiting}
      />
    );
  };

  const renderThreeDLayerThicknessField = () => {
    return (
      <ThreeDLayerThicknessField
        visible
        value={formState.threeDLayerThickness}
        onSelect={(newValue) => {
          updateFormState({
            threeDLayerThickness: newValue,
          });
          dispatch(
            updateCadPartIfExists({
              id: item.id,
              threeDLayerThickness: newValue,
            })
          );
          handleGetPpePriceForItem(item.id);
        }}
        disabled={waiting}
      />
    );
  };

  const renderToleranceField = () => {
    const [minTolerancePerUnitType, maxTolerancePerUnitType] =
      MIN_MAX_VALUE_PER_UNIT_TYPE[formState.unitType];
    const helperText = `Tolerance must be within accepted range (${minTolerancePerUnitType}${
      DISPLAY_UNIT_OPTIONS[formState.unitType].LENGTH
    } - ${maxTolerancePerUnitType}${
      DISPLAY_UNIT_OPTIONS[formState.unitType].LENGTH
    })`;
    const isInvalidTolerance = (value) =>
      value > maxTolerancePerUnitType || value < minTolerancePerUnitType;

    return (
      <div id='tolerance-field'>
        <FlexRow>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <FieldLabel>Tolerance&nbsp;</FieldLabel>
            <Tooltip
              title='Please indicate tightest tolerance in your design.'
              placement='right'
            >
              <img src={InfoIcon} alt='info-icon' />
            </Tooltip>
          </div>
          {renderTDEGenImage(['tolerance'])}
        </FlexRow>
        {isSkeleton && renderSkeletonOverlay()}
        <FlexRow style={{ alignItems: 'flex-start' }}>
          {customizedTolerance ? (
            <TextField
              size='small'
              margin='none'
              type='number'
              variant='outlined'
              style={{ width: '80%' }}
              inputProps={{ min: 0, max: 1 }}
              value={+customTolerance}
              onChange={(e) => {
                setCustomToleranceError(isInvalidTolerance(+e.target.value));
                setCustomTolerance(e.target.value);
              }}
              InputProps={{
                style: {
                  borderRadius: '10px',
                },
                endAdornment: (
                  <InputAdornment position='start'>
                    {UNIT_OPTIONS[formState.unitType].LENGTH}
                  </InputAdornment>
                ),
              }}
              error={customToleranceError}
              helperText={customToleranceError && helperText}
              disabled={
                waiting || item.ppePricingStatus === 'loading' || isSkeleton
              }
              onBlur={(e) => {
                let updatedTolerance = +e.target.value;

                // don't update the tolerance if it's invalid
                if (isInvalidTolerance(updatedTolerance)) {
                  return;
                }

                removeTDEGenField(['tolerance']);

                if (formState.unitType === UNIT_TYPES.IMPERIAL) {
                  updatedTolerance = convertImperialToMetric(updatedTolerance);
                }

                dispatch(
                  updateCadPartIfExists({
                    id: item.id,
                    tolerance: updatedTolerance,
                  })
                );
                handleGetPpePriceForItem(item.id);
              }}
            />
          ) : (
            <FtrDropdown
              width={'80%'}
              key='tolerance-dropdown'
              fullWidth
              value={
                formState.unitType === UNIT_TYPES.IMPERIAL
                  ? +convertMetricToImperial(formState.tolerance)
                  : +formState.tolerance
              }
              handleChange={(value) => {
                let tolerance = value;
                removeTDEGenField(['tolerance']);
                if (formState.unitType === UNIT_TYPES.IMPERIAL) {
                  tolerance = convertImperialToMetric(tolerance);
                }
                updateFormState({ tolerance });
                dispatch(
                  updateCadPartIfExists({
                    id: item.id,
                    tolerance,
                  })
                );
                handleGetPpePriceForItem(item.id);
              }}
              items={STANDARD_TOLERANCE_OPTIONS[formState.unitType]}
              renderValue={(selected) => {
                // selected value here is the converted value
                const value = `±${`${selected}${
                  DISPLAY_UNIT_OPTIONS[formState.unitType]?.LENGTH
                }`}`;
                return value;
              }}
              disabled={waiting || isSkeleton}
              loading={isSkeleton}
            />
          )}
          <FtrCheckbox
            label={'Customize'}
            name='customizedTolerance'
            checked={customizedTolerance}
            style={{
              marginTop: '8px',
              marginLeft: 0,
              marginRight: 0,
            }}
            labelPadding={'0 4px'}
            onChange={(e) => setCustomizedTolerance(e.target.checked)}
          />
        </FlexRow>
      </div>
    );
  };

  const renderTechnicalDrawingUploadArea = () => {
    return (
      <>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <FieldLabel>Technical Drawings&nbsp;</FieldLabel>
          <Tooltip
            title='Please upload complementing 2D files/technical drawings to your parts here. This will help us ensure that we don’t miss out on any component details.'
            placement='right'
          >
            <img src={InfoIcon} alt='info-icon' />
          </Tooltip>
        </div>
        <Box
          style={{
            borderRadius: '10px',
            border: `1px dashed ${colors.neutral050}`,
          }}
        >
          <FlexRowSpaceBetween
            style={{
              alignItems: 'start',
              padding: '0.7rem',
              gap: '1rem',
            }}
          >
            <FlexColumn>
              {drawingFiles?.length === 0 && <TechnicalFileNameDisplay />}
              {drawingFiles?.map((file) => {
                return (
                  <TechnicalFileNameDisplay
                    key={file}
                    file={file}
                    item={item}
                    setItemFiles={(itemFiles) => updateFormState({ itemFiles })}
                  />
                );
              })}
            </FlexColumn>
            <FtrUploadFileSmallButton
              accept='.pdf, image/*'
              handleFiles={handleUploadTechnicalDrawing}
              disabled={waiting}
            >
              Upload
            </FtrUploadFileSmallButton>
          </FlexRowSpaceBetween>
        </Box>
      </>
    );
  };

  const renderRemarksField = () => {
    return (
      (isAddingRemarks || formState.remarks) && (
        <FlexColumn style={{ gap: 0 }}>
          <FtrTextField
            style={{
              width: '100%',
              marginBottom: '0.5rem',
            }}
            disableUnderline
            multiline
            minRows={3}
            value={formState.remarks}
            onChange={(evt) => {
              const remarks = evt.target.value;
              updateFormState({
                remarks,
                remarksSaved: false,
              });
            }}
            onBlur={(evt) => {
              const remarks = evt.target.value;
              updateFormState({
                remarks,
              });
              dispatch(
                updateCadPartIfExists({
                  id: item.id,
                  remarks,
                })
              );
              if (remarks !== item.remarks) {
                updateFormState({
                  remarksSaved: true,
                });
              }
            }}
          />
          <div
            className={clsx(
              classes.hide,
              formState.remarksSaved ? classes.fadeIn : null
            )}
          >
            <FtrLightText
              fontSize='13'
              style={{
                textAlign: 'end',
                width: '100%',
                color: colors.blue050,
              }}
            >
              Changes saved
            </FtrLightText>
          </div>
        </FlexColumn>
      )
    );
  };

  const renderDfmAnalysisButton = () => {
    const { defects, defectStatus } = item;
    if (isEmptyValue(defectStatus) || !showDfmPueMpr(dfmType)) {
      return null;
    }
    return (
      <DfmAnalysisButton
        showIcon
        dfmDefectOutput={defects || {}}
        isLoading={defectStatus === 'loading'}
        cadFile={item.cadPart}
        twoDImageUrl={item[TWO_D_IMAGE_URLS_KEY]?.[0]}
      />
    );
  };

  return (
    <Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth='md'
        fullWidth
        classes={{ paper: classes.paper }}
        scroll='paper'
        fullScreen={isMobile}
        style={{
          cursor: waiting === true ? 'wait' : null,
        }}
      >
        <DialogContent>
          <Grid container direction='row'>
            <Grid item xs={12} sm={7} className={classes.partSpecLeftSide}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  padding: '0.5rem 0',
                }}
              >
                <FtrButton
                  variant='text'
                  color='blue'
                  style={{
                    padding: 'inherit',
                    cursor: waiting === true ? 'wait' : null,
                  }}
                  onClick={handleClose}
                  disabled={isConfigurationLoading}
                >
                  <FtrTypography
                    type='heading'
                    fontSize='16'
                    style={{
                      color:
                        !isFormValid || isConfigurationLoading
                          ? colors.neutral050
                          : colors.blue050,
                    }}
                    id='return-to-quote-btn'
                  >
                    ← Return to quote
                  </FtrTypography>
                </FtrButton>
                {itemState.length > 1 && (
                  <div style={{ display: 'flex' }}>
                    <FtrButton
                      color='blue'
                      size='small'
                      variant='outlined'
                      onClick={() => setOpenApplyToAllDialog(true)}
                    >
                      Apply to all parts
                    </FtrButton>
                    <Box width='0.5rem' />
                  </div>
                )}
              </div>
              <div className={classes.container}>
                <FtrTypography type='heading' fontSize='22'>
                  Part Specifications
                </FtrTypography>
                <FlexRow>
                  <ImperialMetricSwitchV2
                    key={formState.unitType}
                    selectedUnitType={formState.unitType}
                    onChange={handleChangeUnitType}
                    name='imperial-metric-switch'
                  />
                  {renderTDEGenImage(['unitType'])}
                </FlexRow>
              </div>
              {isSkeleton && (
                <div style={{ textAlign: 'right' }}>
                  <Typography
                    variant='subtitle2'
                    style={{ color: colors.neutral060 }}
                  >
                    Extracting Information from your technical drawing
                  </Typography>
                </div>
              )}
              <Grid container direction='column' spacing={2}>
                <Grid item>{renderQuantityField()}</Grid>
                <Grid item>{renderTechnologyField()}</Grid>
                {is3DPTechnology(formState.technology) && (
                  <Grid item>{renderThreeDPrintingTechnologyInputField()}</Grid>
                )}
                {isCustomTechnology(formState.technology) && (
                  <Grid item>{renderCustomTechnologyField()}</Grid>
                )}
                {!is3DPTechnology(formState.technology) && (
                  <Grid item>{renderMaterialInputField()}</Grid>
                )}
                {is3DPTechnology(formState.technology) && (
                  <Grid item>{renderThreeDPrintingMaterialField()}</Grid>
                )}
                {renderCustomMaterialField()}
                {/* No custom material. Material color options are available. */}
                {renderMaterialColorField()}
                {/* No custom material. Material color options are available. */}
                {renderCustomMaterialColorField()}
                {is3DPTechnology(formState.technology) &&
                  formState.threeDTechnology === THREE_D_P_FDM_TECH && (
                    <Fragment>
                      <Grid item>{renderThreeDInfillField()}</Grid>
                      <Grid item>{renderThreeDLayerThicknessField()}</Grid>
                    </Fragment>
                  )}
                {renderSurfaceFinishField()}
                {/* Render custom surface finish field when user choose that. */}
                {renderCustomSurfaceFinishField()}
                {/* No custom surface. Surface finish color options are available. */}
                {renderFinishColorField()}
                {/* Show custom surface finish color options when user choose customer surface. */}
                {renderCustomFinishColorField()}
                {/* Render anodizing type field if surface finish is anodizing kind */}
                {renderAnodizingTypeField()}
                <Grid item>{renderToleranceField()}</Grid>
                <Grid item>{renderTechnicalDrawingUploadArea()}</Grid>
                <Grid item>{renderDfmAnalysisButton()}</Grid>
                <Grid item id='remarks-field'>
                  <FieldLabel>Have additional requirements?</FieldLabel>
                  <Button
                    variant='text'
                    disableFocusRipple
                    style={{
                      color: colors.blue050,
                      textTransform: 'none',
                      fontWeight: 700,
                      fontSize: '14px',
                    }}
                    onClick={() => {
                      if (isAddingRemarks) {
                        updateFormState({
                          remarks: '',
                          remarksSaved: false,
                        });
                        dispatch(
                          updateCadPartIfExists({
                            id: item.id,
                            remarks: '',
                          })
                        );
                        setIsAddingRemarks(false);
                      } else {
                        setIsAddingRemarks(true);
                      }
                    }}
                  >
                    {isAddingRemarks || formState.remarks
                      ? '-  Remove remarks'
                      : '+  Add custom requirements'}
                  </Button>
                  <Box style={{ height: '0.5rem' }} />
                  {renderRemarksField()}
                </Grid>
              </Grid>
              <div id='target-price-and-lead-time-field'>
                <FtrTypography
                  type='heading'
                  fontSize='18'
                  style={{ padding: '1rem 0' }}
                >
                  Order Specifications
                </FtrTypography>
                <Grid container direction='column' spacing={2}>
                  <Grid item>{renderTargetUnitPriceField()}</Grid>
                  <Grid item>{renderTargetLeadTimeField()}</Grid>
                </Grid>
              </div>
            </Grid>
            {!isMobile && (
              <Divider
                flexItem
                orientation='vertical'
                style={{ marginRight: '-1px' }}
              />
            )}

            <Grid
              item
              xs={12}
              sm={5}
              style={{ padding: isMobile ? 'auto' : '2rem 1rem 0 2rem' }}
            >
              <Grid container direction='column' spacing={2}>
                <Grid item>{renderPartsNavigationButtons()}</Grid>
                <Grid item xs={12}>
                  {render2DImage()}
                </Grid>
                <Grid item xs={12}>
                  <FlexRowSpaceBetween style={{ width: '100%' }}>
                    <div
                      style={{
                        flexGrow: '1 auto',
                      }}
                    >
                      {renderPartNameField()}
                    </div>
                    <FtrSmallButton
                      style={{
                        minWidth: 100,
                        justifySelf: 'end',
                      }}
                      color='blue'
                      startIcon={<Refresh />}
                      onClick={() => setShowReplacePartPopup(true)}
                      disabled={waiting}
                    >
                      Replace
                    </FtrSmallButton>
                  </FlexRowSpaceBetween>
                </Grid>
                <Divider width='100%' style={{ margin: '1rem 0' }} />
                <Grid item>{renderItemInfo()}</Grid>
              </Grid>
              <Divider style={{ margin: '1rem 0' }} />
              {renderPartSpecsSummary()}
            </Grid>
          </Grid>
          {openBulkPricingDialog && (
            <BulkPricingPopupV2
              key={item.id}
              dialog={openBulkPricingDialog}
              item={item}
              handleClose={() => setOpenBulkPricingDialog(false)}
            />
          )}
        </DialogContent>
      </Dialog>
      {showReplacePartPopup && (
        <ReplacePartPopup
          open={showReplacePartPopup}
          technology={item.technology}
          handleCadFilesChange={handleReplaceCadFile}
          onClose={() => setShowReplacePartPopup(false)}
        />
      )}
      {openApplyToAllDialog && (
        <ApplyToAllDialog
          open={openApplyToAllDialog}
          item={item}
          parts={itemState}
          changes={{
            ...(formState.qty && { qty: formState.qty }),
            tolerance: formState.tolerance,
            ...(formState.remarks && { remarks: formState.remarks }),
            expectedLeadTime: formState.expectedLeadTime,
            ...(formState.partApplication && {
              partApplication: formState.partApplication,
            }),
          }}
          groupedChanges={{
            technology: formState.technology,
            ...(isCustomTechnology(formState.technology) && {
              otherTechnology: formState.otherTechnology,
            }),
            ...(is3DPTechnology(formState.technology) && {
              threeDTechnology: formState.threeDTechnology,
            }),
            material: formState.material,
            ...(isCustomMaterial(formState.material) && {
              otherMaterial: formState.otherMaterial,
            }),
            ...(formState.materialColor && {
              materialColor: formState.materialColor,
            }),
            ...(is3DPTechnology(formState.technology) && {
              threeDInfill: formState.threeDInfill,
            }),
            ...(is3DPTechnology(formState.technology) && {
              threeDLayerThickness: formState.threeDLayerThickness,
            }),
            surfaceFinish: formState.surfaceFinish,
            ...(isCustomSurfaceFinish(formState.surfaceFinish) && {
              otherSurfaceFinish: formState.otherSurfaceFinish,
            }),
            ...(formState.color && { color: formState.color }),
          }}
          handleClose={() => {
            setOpenApplyToAllDialog(false);
          }}
          handleConfirm={handleConfirmApplyToAllDialog}
        />
      )}
    </Fragment>
  );
}

export default ConfigurePartFormV2;
