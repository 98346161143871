import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGIN_GUEST,
  REMOVE_GUEST,
  LOGOUT_REQUEST,
  LOGOUT_SUCCESS,
  LOGOUT_FAILURE,
  VERIFY_REQUEST,
  VERIFY_SUCCESS,
  RETRIEVE_LOCATION_SUCCESS,
  SET_LOCATION_SUCCESS,
  RETRIEVE_EXCHANGE_RATE_SUCCESS,
  SET_EXCHANGE_RATES_SUCCESS,
  INSERT_PROPERTIES_SUCCESS,
  SET_COUNTRY_SUCCESS,
  SET_USER_CREDIT_SUCCESS,
  SET_UNIT_TYPE_SUCCESS,
} from '../actions/';
import { DEFAULT_MYR_EXCHANGE_RATE } from '../constants';

const defaultState = {
  isLoggingIn: false,
  isLoggingOut: false,
  isVerifying: false,
  loginError: false,
  logoutError: false,
  isAuthenticated: false,
  location: {
    currency: 'SGD',
  },
  user: {
    role: null,
  },
  guestUserInfo: null,
  rates: {
    MYR: DEFAULT_MYR_EXCHANGE_RATE,
  },
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case LOGIN_REQUEST:
      return {
        ...state,
        isLoggingIn: true,
        loginError: false,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        isLoggingIn: false,
        isAuthenticated: true,
        user: action.user,
      };
    case LOGIN_FAILURE:
      return {
        ...state,
        isLoggingIn: false,
        isAuthenticated: false,
        loginError: true,
      };
    case LOGIN_GUEST:
      return {
        ...state,
        guestUserInfo: action.guestUserInfo,
      };
    case REMOVE_GUEST:
      return {
        ...state,
        guestUserInfo: null,
      };
    case LOGOUT_REQUEST:
      return {
        ...state,
        isLoggingOut: true,
        logoutError: false,
      };
    case LOGOUT_SUCCESS:
      return defaultState;
    case LOGOUT_FAILURE:
      return {
        ...state,
        isLoggingOut: false,
        logoutError: true,
      };
    case VERIFY_REQUEST:
      return {
        ...state,
        isVerifying: true,
        verifyingError: false,
      };
    case VERIFY_SUCCESS:
      return {
        ...state,
        isVerifying: false,
      };
    case RETRIEVE_LOCATION_SUCCESS:
      return {
        ...state,
        location: action.location,
      };
    case SET_LOCATION_SUCCESS:
      return {
        ...state,
        location: action.location,
      };
    case SET_COUNTRY_SUCCESS: {
      const { country } = action.payload;
      return {
        ...state,
        user: {
          ...state.user,
          country,
        },
      };
    }
    case RETRIEVE_EXCHANGE_RATE_SUCCESS:
      return {
        ...state,
        rates: action.rates,
      };
    case SET_EXCHANGE_RATES_SUCCESS:
      return {
        ...state,
        rates: action.rates,
      };
    case INSERT_PROPERTIES_SUCCESS: {
      const { payload } = action;
      const { industrySector, customerType } = payload;
      return {
        ...state,
        user: {
          ...state.user,
          industrySector,
          customerType,
        },
      };
    }
    case SET_USER_CREDIT_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          credits: action.credits,
        },
      };
    case SET_UNIT_TYPE_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          unitType: action.unitType,
        },
      };
    default:
      return state;
  }
};
