import AutomationRfqFailuresPopup from '../components/popups/AutomationRfqFailuresPopup';

import withPopupHOC from './withPopupHOC';

// -------------------------------------------------------------------------------------------------

export const withAutomationRfqFailuresHOC = (WrappedComponent) =>
  withPopupHOC({
    WrappedComponent,
    ModalComponent: AutomationRfqFailuresPopup,
    defaultLocalState: {
      open: false,
      rfqFailures: [],
    },
    statePropName: 'automationRfqFailuresPopupState',
    updaterPropName: 'updateAutomationRfqFailuresPopupState',
  });
