import React from 'react';

import { makeStyles } from '@material-ui/core/styles/index';
import { Dialog, DialogContent } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { FtrH6, FtrTypography } from '../ftr-components';

import { colors } from '../../palette';
import { FlexColumn } from '../layouts/FlexLayouts';
import { FAILURE_REASON_MAPPING } from '../../constants/automationRfqFailuresConstants';
import { Link } from 'react-router-dom';

// -------------------------------------------------------------------------------------------------

const useStyles = makeStyles(() => ({
  backdrop: {
    backdropFilter: 'blur(3px)',
  },
  dialog: {
    padding: '0 1.5rem 1.5rem 1.5rem',
    borderRadius: '1.5rem',
  },
  title: {
    display: 'flex',
    fontSize: '22px',
    fontWeight: '700',
    alignItems: 'center',
    justifyContent: 'center',
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  closeIcon: {
    position: 'absolute',
    right: '1.5rem',
    top: '1.5rem',
    color: colors.neutral060,
    width: '1.5rem',
    height: '1.5rem',
    cursor: 'pointer',
  },
}));

const AutomationRfqFailuresPopup = ({ open, rfqFailures, onClose }) => {
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth={'md'}
      fullWidth={true}
      BackdropProps={{
        classes: {
          root: classes.backdrop,
        },
      }}
      PaperProps={{ className: classes.dialog }}
    >
      <Close className={classes.closeIcon} onClick={onClose} />
      <DialogContent
        style={{
          margin: '3rem 0',
        }}
      >
        <FlexColumn>
          <FtrH6 type='heading'>Automation RFQ Issues</FtrH6>
          <FtrTypography type='body'>
            There are issues with the automation flow for these repeat orders.
            Please RFQ these items manually!
          </FtrTypography>
          <ul>
            {rfqFailures.map((failure) => (
              <li key={failure.itemID}>
                <Link
                  to={{
                    pathname: `/manage-projects/${failure.projectID}/rfq-email-schedules`,
                  }}
                >
                  #{failure.itemID} - {FAILURE_REASON_MAPPING[failure.reason]}
                </Link>
              </li>
            ))}
          </ul>
        </FlexColumn>
      </DialogContent>
    </Dialog>
  );
};

export default AutomationRfqFailuresPopup;
