import React from 'react';

import { trackActionApi } from '../apis/actionTrackingApi';

import { isEmptyValue } from '../utils/commonUtils';

// -------------------------------------------------------------------------------------------------

function withActionTrackingHOC(WrappedComponent) {
  function WrappedComponentWithHOC(props) {
    const {
      actionTrackingKey = '',
      additionalInfo,
      trackingInfo = {},
      onClick = () => {},
    } = props;

    const [loading, setLoading] = React.useState(false);

    return (
      <>
        <WrappedComponent
          {...props}
          onClick={(...args) => {
            const shouldTrackAction = !isEmptyValue(actionTrackingKey);
            if (shouldTrackAction) {
              setLoading(true);
              trackActionApi({
                actionTrackingKey,
                additionalInfo,
                ...trackingInfo,
              });
            }

            onClick(...args);
          }}
          loading={props.loading || loading}
        />
      </>
    );
  }

  return WrappedComponentWithHOC;
}

export default withActionTrackingHOC;
