import { fetchWithErrorHandling, getAuthorizedHeader } from '../utils/apiUtils';

import { BACKEND_SERVICE_URL } from '../constants';

// -------------------------------------------------------------------------------------------------

export const getItemRepeatOrmInfo = async (itemID) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/order-ready/repeat-item/${itemID}`;
  return fetchWithErrorHandling(requestUrl, {
    method: 'GET',
    headers: getAuthorizedHeader(),
  });
};
