import React from 'react';

import {
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles/index';
import Title from '../Title';
import BlueButton from '../buttons/BlueButton';
import { colors } from '../../palette';

const useStyles = makeStyles((theme) => ({
  paper: {
    minWidth: 200,
    borderRadius: 0,
  },
  space: {
    width: theme.spacing(2),
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    flex: '50%',
  },
}));

function InstantLogInfoPopup(props) {
  const classes = useStyles();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { rowData, handleClose } = props;

  const renderBody = () => {
    const maxHeight = 450;
    return (
      <div
        style={{
          display: 'flex',
          gap: '1rem',
          maxHeight: '50%',
          flexWrap: isMobile ? 'wrap' : 'nowrap',
        }}
      >
        <div className={classes.container}>
          <Typography variant='h5'>Request</Typography>
          <div
            style={{
              whiteSpace: 'pre-wrap',
              textAlign: 'left',
              padding: '1rem',
              boxSizing: 'border-box',
              border: `1px solid ${colors.fontMediumGray}`,
              borderRadius: 6,
              maxHeight,
              width: '100%',
              overflowY: 'auto',
            }}
          >
            {JSON.stringify(rowData.meta.request, null, 2)}
          </div>
        </div>
        <div className={classes.container}>
          <Typography variant='h5'>Response</Typography>
          <div
            style={{
              whiteSpace: 'pre-wrap',
              textAlign: 'left',
              padding: '1rem',
              boxSizing: 'border-box',
              border: `1px solid ${colors.fontMediumGray}`,
              borderRadius: 6,
              width: '100%',
              maxHeight,
              overflowY: 'auto',
            }}
          >
            {JSON.stringify(rowData.meta.response, null, 2)}
          </div>
        </div>
      </div>
    );
  };

  return (
    <Dialog
      maxWidth='xl'
      open={open}
      onClose={handleClose}
      aria-labelledby='confirmation-dialog-title'
      classes={{ paper: classes.paper }}
    >
      <DialogTitle id='confirmation-dialog-title'>
        <Title contentTitle={`Tracking ID ${rowData.id}`} size='small' />
      </DialogTitle>
      <DialogContent
        style={{
          padding: '0 30px',
          textAlign: 'center',
        }}
      >
        <React.Fragment>{renderBody()}</React.Fragment>
      </DialogContent>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          position: 'sticky',
          bottom: 0,
          width: '100%',
          padding: '1rem',
          backgroundColor: 'white',
          boxSizing: 'border-box',
        }}
      >
        <BlueButton
          onBtnClick={() => {
            handleClose();
          }}
          btnContent='OK'
        />
      </div>
    </Dialog>
  );
}

export default InstantLogInfoPopup;
