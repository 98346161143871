import { isAdminOrHigherRole } from '../utils/roleUtils';

export const PROJECT_TYPE = {
  PPE: 'PPE',
  RFQ: 'RFQ',
  MIX_PPE_RFQ: 'MIX_PPE_RFQ',
  MIX_TECHNOLOGIES: 'MIX_TECHNOLOGIES',
};

export const ITEM_QUOTE_TYPE = {
  PPE: 'PPE',
  RFQ: 'RFQ',
};

export const MAX_BOM_FILES_UPLOAD = 3;

export const QC_NO_QC_REPORT = 'No QC Report';
export const QC_STANDARD_QC_REPORT = 'Standard QC Report';
export const QC_FORMAL_CMM_REPORT = 'Formal/CMM Report';
export const QC_MATERIAL_CERTIFICATE = 'Material Certificate';
export const QC_COATING_FINISHING_CERTIFICATE =
  'Coating (Finishing) Certificate';
export const QC_OTHERS = 'Others';

export const QC_REPORT_MAIN_OPTIONS = [
  {
    name: QC_NO_QC_REPORT,
    tooltip:
      'No inspection done after fabrication. However, parts will still be fabricated according to provided files.',
  },
  {
    name: QC_STANDARD_QC_REPORT,
    tooltip:
      'Inspected using measurement tools like Vernier Caliper, micrometer, Go/No-GO Gauge, 2D Vision Inspection System, Profile Projectors.',
  },
  {
    name: QC_FORMAL_CMM_REPORT,
    tooltip:
      'Measurement using sophisticated equipment like CMM or 3D Scanning systems.',
  },
];

export const getQcReportMainOptions = (role) => {
  if (isAdminOrHigherRole(role)) {
    return QC_REPORT_MAIN_OPTIONS;
  }
  return QC_REPORT_MAIN_OPTIONS.filter(
    (item) => item.name !== QC_FORMAL_CMM_REPORT
  );
};

export const QC_REPORT_ADD_ONS_OPTIONS = [
  {
    name: QC_MATERIAL_CERTIFICATE,
    tooltip: 'Certificate showing traceability to material and heat treat lot.',
  },
  {
    name: QC_COATING_FINISHING_CERTIFICATE,
    tooltip:
      'Certificate showing compliance to coating requirements in drawing.',
  },
];

export const QC_REPORTS_ALL_OPTIONS = [
  ...QC_REPORT_MAIN_OPTIONS,
  ...QC_REPORT_ADD_ONS_OPTIONS,
  {
    name: QC_OTHERS,
  },
];

export const STANDARD_QC_REPORT_TEMPLATE_URL =
  'https://docs.google.com/spreadsheets/d/10Lyz-uMJihhVCGrintBUp6YXJqreQaecxGRf-iqZa1s/edit#gid=0';

export const QC_REPORT_TEMPLATE_URL = {
  [QC_STANDARD_QC_REPORT]: STANDARD_QC_REPORT_TEMPLATE_URL,
};

export const LOCATION_VISIBILITY_OPTIONS = {
  WORLD_WIDE: 'WORLD_WIDE',
  SELECT_COUNTRIES: 'SELECT_COUNTRIES',
};

export const TECH_URL_MAPPING_OPTIONS = {
  'cnc-machining': 'CNC Machining',
  'sheet-metal-fabrication': 'Sheet Metal Fabrication',
  '3d-printing': '3D Printing',
  'design-service': 'Design Service',
  engraving: 'Engraving',
  treatment: 'Treatment',
  'injection-moulding': 'Injection Moulding',
  're-engineering-service': 'Re-engineering Service',
  'urethane-casting': 'Urethane Casting',
  'custom-fabrication-technology': 'Custom Fabrication Technology',
  'not-sure': 'Not Sure',
};

export const TECH_DESCRIPTION_MAP = {
  'CNC Machining': {
    description: 'Ideal for precision machining and high-volume production.',
    link: 'https://factorem.co/cncmachining',
    others: false,
  },
  'Sheet Metal Fabrication': {
    description: 'Durable, cost-effective parts for enclosures and brackets.',
    link: 'https://factorem.co/sheetmetal',
    others: false,
  },
  '3D Printing': {
    description:
      'For complex and customized parts, suited for rapid prototyping.',
    link: 'https://factorem.co/3dprinting',
    others: false,
  },
  'Design Service': {
    description: 'For design assistance in creating or refining part designs.',
    others: true,
  },
  Engraving: {
    description:
      'To add a personalised touch to your parts for branding and customisation.',
    others: true,
  },
  Treatment: {
    description:
      'Enhance the durability and finish of your parts with specialised treatment.',
    others: true,
  },
  'Injection Moulding': {
    description:
      'Create moulds for creating large quantities of consistent parts.',
    others: true,
  },
  'Re-engineering Services': {
    description:
      'If your parts require improvements or adjustments for better performance.',
    others: true,
  },
  'Urethane Casting': {
    description:
      'Provides flexibility and quality for low-volume production or protypes.',
    others: true,
  },
  'Custom Fabrication Technology': {
    description: 'We can cater to a wide range of part requirements.',
    others: true,
  },
  'Not Sure': {
    description:
      'Not sure which technology is right for your parts? We’ll review and guide you.',
    others: true,
  },
};

export const PROJECT_STATUS = {
  OPEN: 'Open',
  DISABLED: 'Disabled',
  COMPLETED: 'Completed',
};
