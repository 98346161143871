import React, { useReducer } from 'react';
import { isEmpty } from 'lodash';

import { Button, CircularProgress, TextField } from '@material-ui/core';
import { downloadS3File } from '../../utils/fileUtils';

function S3DownloadFile() {
  const [localState, updateLocalState] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      imageUrl: undefined,
      processing: false,
    }
  );

  const handleDownloadFile = () => {
    downloadS3File(localState.imageUrl);
  };

  return (
    <div
      style={{
        paddingTop: '1rem',
      }}
    >
      <TextField
        required
        label='Url'
        id='image-url'
        variant='outlined'
        value={localState.imageUrl}
        onChange={(event) => updateLocalState({ imageUrl: event.target.value })}
        margin='dense'
        fullWidth
        InputLabelProps={{
          shrink: true,
        }}
      />
      <div style={{ marginTop: '1rem' }}>
        <Button
          variant='contained'
          color='primary'
          fullWidth
          onClick={handleDownloadFile}
          disabled={
            isEmpty(localState.imageUrl) || localState.processing === true
          }
        >
          {localState.processing === true && <CircularProgress size={20} />}
          &nbsp;Download File
        </Button>
      </div>
    </div>
  );
}

export default S3DownloadFile;
