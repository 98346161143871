export const USER_AGENT_KEYS = {
  BROWSER: 'browser',
  OS: 'os',
  DEVICE: 'device',
};

export const FACTOREM_PARTNER = 'Factorem Partner';
export const FACTOREM_ROCKET_QUOTE = 'Factorem Rocket Quote';

export const DEFAULT_FACTOREM_ADMIN = {
  name: 'Eliot',
  address: '#01-54, 81 Ayer Rajah Crescent, Queenstown, Singapore, 139967',
  companyName: 'Factorem',
  contact: '(+65) 88335224',
  country: 'Singapore',
};

export const USER_FEE_TYPE = {
  HAS_FEE: 'has_fee',
  NO_FEE: 'no_fee',
};

export const TDE_TYPE = {
  HAS_TDE: 'has-tde',
  NO_TDE: 'no-tde',
};

export const PPE_TYPES = {
  NO_PPE: 'no-ppe',
  HAS_ROM_PPE_ONLY: 'has-rom-ppe-only',
  HAS_ROM_CSM_PPE_ONLY: 'has-rom-csm-ppe-only',
  HAS_RBP_PPE_ONLY: 'has-rbp-ppe-only',
  HAS_PPE: 'has-ppe',
};
