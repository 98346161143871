import React, { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles/index';

import { IconButton } from '@material-ui/core';

import {
  Cancel,
  Delete,
  Edit,
  GetApp,
  Refresh,
  Warning,
} from '@material-ui/icons';

import FilesUploadButton from '../../components/FilesUploadButton';
import FtrDeletePopup from '../../components/ftr-components/FtrDeletePopup';
import FtrSvgImage from '../../components/images/FtrSvgImage';
import SingleImage from '../../components/images/SingleImage';
import { FlexColumn, FlexRow } from '../../components/layouts/FlexLayouts';
import {
  FtrBoldText,
  FtrCaption,
  FtrItalicText,
  FtrTooltip,
} from '../../components/ftr-components';

import FileIcon from '../../assets/icons/technical_drawing_file_icon.svg';
import VideoFileIcon from '../../assets/icons/video_file_icon.svg';

import withActionTrackingHOC from '../../hocs/withActionTrackingHOC';

import {
  adminUpdateItemOrderReadyRequiredMaterials,
  deleteOrderReadyResource,
} from '../../apis/orderReadyApi';

import { getUserRoleSelector } from '../../selectors/userSelector';

import {
  convertBytesToMB,
  downloadS3File,
  extractFileNameWithoutTimestampFromUrl,
  isImageFile,
} from '../../utils/fileUtils';
import { isAdminOrHigherRole } from '../../utils/roleUtils';
import { isEmptyValue } from '../../utils/commonUtils';
import { uploadOrderReadyResource } from '../../utils/orderReadyUtils';
import { formatDateWithTime } from '../../utils/dateTimeUtils';

import {
  dismissNotification,
  notifyCancellableInfoContent,
  notifyError,
  updateNotification,
} from '../../services/notificationService';

import { ACTION_TRACKING_TYPE } from '../../constants/actionTrackingConstants';
import { FIFTY_MB_IN_BYTES } from '../../constants/fileConstants';
import { ORDER_READY_RESOURCE_TYPES } from '../../constants/orderReadyConstants';
import { PLACEHOLDER_IMAGE_URL } from '../../constants/imageConstants';

import { colors } from '../../palette';

// --------------------------------------------------------------------------------

const useStyles = makeStyles(() => ({
  iconButton: {
    paddingLeft: 2,
    paddingRight: 2,
  },
}));

const DownloadIconButton = withActionTrackingHOC(IconButton);

function OrmMaterialFileUploader(props) {
  const classes = useStyles();

  const role = useSelector(getUserRoleSelector);

  const {
    resource = {},
    loadOrderReadyInfo = () => {},
    onEdit = () => {},
    showEdit = true,
    showRemoveFileIcon = true,
    showUploadedTime = false,
  } = props;

  const { projectOrderReadyID: porID, fileURL, allowedFileTypes, createdDate } =
    resource || {};

  const hasFileUploaded =
    !isEmptyValue(fileURL) && fileURL !== PLACEHOLDER_IMAGE_URL;

  const { selectedFileTypes = [], customFileTypes = '' } =
    allowedFileTypes || {};
  let allowedFileTypesStr = `${selectedFileTypes.join(', ')}`;
  if (!isEmptyValue(customFileTypes)) {
    allowedFileTypesStr = allowedFileTypesStr
      ? `${allowedFileTypesStr}, ${customFileTypes
          .replace(/ /g, '')
          .split(',')
          .join(', ')}`
      : customFileTypes
          .replace(/ /g, '')
          .split(',')
          .join(', ');
  }
  // default is pdf file
  if (
    isEmptyValue(allowedFileTypesStr) &&
    resource?.type === ORDER_READY_RESOURCE_TYPES.DOCUMENT
  ) {
    allowedFileTypesStr = '.pdf';
  }

  const [openDeletePopup, setOpenDeletePopup] = useState(false);
  const [
    openRemoveExistingFilePopup,
    setOpenRemoveExistingFilePopup,
  ] = useState(false);

  const srcIcon = useMemo(() => {
    if (isEmptyValue(resource?.type)) {
      return;
    }

    switch (resource?.type) {
      case ORDER_READY_RESOURCE_TYPES.VIDEO:
        return VideoFileIcon;
      default:
        return FileIcon;
    }
  }, [resource?.type]);

  const acceptFileTypes = useMemo(() => {
    if (isEmptyValue(resource?.type)) {
      return '*';
    }

    switch (resource?.type) {
      case ORDER_READY_RESOURCE_TYPES.VIDEO:
        return 'video/*';
      case ORDER_READY_RESOURCE_TYPES.DOCUMENT:
        return allowedFileTypesStr;
      default:
        return '*';
    }
  }, [resource.type]);

  const extractedFileName = useMemo(() => {
    if (isEmptyValue(fileURL)) {
      return '';
    }

    return extractFileNameWithoutTimestampFromUrl(fileURL);
  }, [fileURL]);

  if (isEmptyValue(resource)) {
    return;
  }

  const handleUploadOrderReadyResource = useCallback(
    ({ file }) => {
      if (file.size > FIFTY_MB_IN_BYTES) {
        notifyError('File size should be less than 50 MB');
        return;
      }

      uploadOrderReadyResource({
        itemOrderReadyID: resource?.itemOrderReadyID,
        resourceID: resource?.resourceID,
        file,
        resourceType: resource?.type,
        porID,
      }).then(loadOrderReadyInfo);
    },
    [resource?.itemOrderReadyID, resource?.resourceID, resource?.type]
  );

  const handleDeleteOrderReadyResource = () => {
    deleteOrderReadyResource(porID, resource?.resourceID).then(
      loadOrderReadyInfo
    );
  };

  const handleRemoveExistingFile = () => {
    adminUpdateItemOrderReadyRequiredMaterials(resource?.itemOrderReadyID, {
      resourceID: resource?.resourceID,
      fileURL: PLACEHOLDER_IMAGE_URL,
    }).then(loadOrderReadyInfo);
    setOpenRemoveExistingFilePopup(false);
  };

  const renderUploadedFileInfo = () => {
    return (
      <>
        <FlexRow>
          <FtrItalicText
            style={{ color: colors.green050, wordBreak: 'break-all' }}
          >
            Uploaded File: <strong>{extractedFileName}</strong>
          </FtrItalicText>
          {resource.type !== ORDER_READY_RESOURCE_TYPES.ADDITIONAL_RESOURCE &&
            showRemoveFileIcon && (
              <FtrTooltip description='Remove existing file'>
                <IconButton
                  className={classes.iconButton}
                  onClick={() => setOpenRemoveExistingFilePopup(true)}
                  style={{
                    paddingTop: 0,
                    paddingBottom: 0,
                  }}
                >
                  <Cancel color='error' style={{ fontSize: '12pt' }} />
                </IconButton>
              </FtrTooltip>
            )}
        </FlexRow>
        <FtrItalicText
          style={{ color: colors.green050, wordBreak: 'break-all' }}
          fontSize='12'
        >
          File size: <strong>{convertBytesToMB(resource?.fileSize)}</strong>
        </FtrItalicText>
        {showUploadedTime && createdDate && (
          <FtrItalicText
            style={{ color: colors.green050, wordBreak: 'break-all' }}
            fontSize='12'
          >
            Uploaded at: <strong>{formatDateWithTime(createdDate)}</strong>
          </FtrItalicText>
        )}
      </>
    );
  };

  return (
    <>
      <FlexRow
        style={{
          padding: '0.5rem',
          border: `1px solid ${colors.blue040}`,
          borderRadius: 8,
          position: 'relative',
          paddingRight: '1rem',
        }}
      >
        {isImageFile(fileURL) && <SingleImage url={fileURL} noBorder />}
        {!isImageFile(fileURL) && <FtrSvgImage src={srcIcon} />}
        <FlexColumn style={{ flex: '1 1 auto' }}>
          {resource.title && <FtrBoldText>{resource.title}</FtrBoldText>}
          {resource.description && (
            <FtrCaption>{resource.description}</FtrCaption>
          )}
          {allowedFileTypesStr && (
            <FtrCaption>Allowed: {allowedFileTypesStr}</FtrCaption>
          )}
          {hasFileUploaded && renderUploadedFileInfo()}
        </FlexColumn>
        <FlexRow style={{ gap: '0.3rem' }}>
          {!hasFileUploaded && (
            <FilesUploadButton
              id={`upload-orm-resource-${resource.resourceID}`}
              handleUploadFiles={(files) =>
                handleUploadOrderReadyResource({
                  file: files[0],
                })
              }
              multiple={false}
              accept={acceptFileTypes}
              showButtonText={false}
              tooltip='Upload File'
              showTooltip={true}
            />
          )}
          {hasFileUploaded &&
            resource.type !==
              ORDER_READY_RESOURCE_TYPES.ADDITIONAL_RESOURCE && (
              <FilesUploadButton
                id={`replace-orm-resource-${resource.resourceID}`}
                handleUploadFiles={(files) =>
                  handleUploadOrderReadyResource({
                    file: files[0],
                  })
                }
                multiple={false}
                accept={acceptFileTypes}
                showButtonText={false}
                tooltip='Replace File'
                showTooltip={true}
                icon={<Refresh />}
              />
            )}
          {hasFileUploaded && (
            <FtrTooltip description='Download File'>
              <DownloadIconButton
                className={classes.iconButton}
                actionTrackingKey={ACTION_TRACKING_TYPE.DOWNLOAD_FILE}
                additionalInfo={{
                  urls: [fileURL],
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  const abortController = new AbortController();
                  const toastId = notifyCancellableInfoContent({
                    title: `Preparing download file for: ${extractedFileName}`,
                    content: 'Please wait...',
                    cancelBtnText: 'Cancel Download',
                    onCancel: () => {
                      abortController.abort();
                    },
                  });
                  downloadS3File(fileURL, {
                    fileNameParam: extractedFileName,
                    signal: abortController.signal,
                  })
                    .then(() => {
                      dismissNotification(toastId);
                    })
                    .catch(() => {
                      updateNotification(
                        toastId,
                        `Prepared download file failed.`,
                        'error'
                      );
                    });
                }}
                style={{
                  color: colors.blue050,
                  marginTop: 3,
                }}
              >
                <GetApp color='inherit' style={{ fontSize: '17pt' }} />
              </DownloadIconButton>
            </FtrTooltip>
          )}
          {isAdminOrHigherRole(role) &&
            resource.type !== ORDER_READY_RESOURCE_TYPES.ADDITIONAL_RESOURCE &&
            showEdit && (
              <FtrTooltip description='Edit Config'>
                <IconButton
                  className={classes.iconButton}
                  onClick={onEdit}
                  style={{
                    color: colors.blue050,
                  }}
                >
                  <Edit color='inherit' style={{ fontSize: '15pt' }} />
                </IconButton>
              </FtrTooltip>
            )}
          {isAdminOrHigherRole(role) && (
            <FtrTooltip
              description={
                resource.type === ORDER_READY_RESOURCE_TYPES.ADDITIONAL_RESOURCE
                  ? 'Delete File'
                  : 'Delete Config'
              }
            >
              <IconButton
                className={classes.iconButton}
                onClick={() => setOpenDeletePopup(true)}
              >
                <Delete color='error' style={{ fontSize: '15pt' }} />
              </IconButton>
            </FtrTooltip>
          )}
          {resource?.required && !hasFileUploaded && (
            <FtrTooltip description='Please upload file'>
              <div
                style={{
                  position: 'absolute',
                  top: 0,
                  right: 0,
                  padding: 2,
                  boxSizing: 'border-box',
                }}
              >
                <Warning
                  style={{
                    color: 'orange',
                    backgroundColor: 'white',
                    fontSize: 18,
                  }}
                />
              </div>
            </FtrTooltip>
          )}
        </FlexRow>
        {openDeletePopup && (
          <FtrDeletePopup
            title='Do you want to delete this required material?'
            subtitle=''
            open={openDeletePopup}
            onConfirm={handleDeleteOrderReadyResource}
            onClose={() => setOpenDeletePopup(false)}
          />
        )}
        {openRemoveExistingFilePopup && (
          <FtrDeletePopup
            title='Do you want to remove existing file?'
            subtitle=''
            open={openRemoveExistingFilePopup}
            onConfirm={handleRemoveExistingFile}
            onClose={() => setOpenRemoveExistingFilePopup(false)}
          />
        )}
      </FlexRow>
    </>
  );
}

export default OrmMaterialFileUploader;
