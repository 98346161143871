import React from 'react';

import { IconButton, Tooltip } from '@material-ui/core';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';

function VerifiablePendingStatus(props) {
  const { onButtonClick } = props;

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <span style={{ color: 'orange', marginRight: '4px' }}>Pending</span>
      <Tooltip title='Verify User'>
        <IconButton color='primary' onClick={onButtonClick}>
          <PlaylistAddCheckIcon />
        </IconButton>
      </Tooltip>
    </div>
  );
}

export default VerifiablePendingStatus;
