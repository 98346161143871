// Import settings
import React from 'react';
import { connect } from 'react-redux';

// Import color palette
import { colors } from '../palette';

// Import material UI components
import { makeStyles } from '@material-ui/core/styles';

// Style component
const useStyles = makeStyles((theme) => ({
  root: {
    padding: '25px 45px',
    borderBottom: `1px solid ${colors.bgLightGrey}`,
    color: colors.tertiaryBlue,
    marginBottom: 12,
    justifyContent: 'center',
  },
  title: {
    textTransform: 'uppercase',
    fontSize: '21px',
    fontWeight: '600',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
}));

export function PageTitle(props) {
  // Define styles to use in function
  const classes = useStyles();

  // Define props to be used in component
  const { title } = props;

  return (
    <div className={classes.root}>
      <div className={classes.title}>{title}</div>
    </div>
  );
}

// Import states to use in component
function mapStateToProps() {
  return {};
}

// Import functions to use in component
function matchDispatchToProps() {
  return {};
}

const withConnect = connect(mapStateToProps, matchDispatchToProps);

export default withConnect(PageTitle);
