import React, { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  StripeCreditCardPaymentOption,
  StripePaynowPaymentOption,
} from './CheckoutPaymentOption';
import { ConfirmOrderPopupContext } from '../ConfirmOrderPopup';
import { FlexColumn } from '../../layouts/FlexLayouts';
import { FtrBoldText } from '../../ftr-components';

import { updatePaymentType } from '../../../actions/multiCheckoutForm';

import { getMultiCheckoutFormSelector } from '../../../selectors/multiCheckoutFormSelector';
import { getUserFeeTypeSelector } from '../../../selectors/userSelector';

import { CURRENCY } from '../../../constants';
import { CUSTOMER_CREDIT_TYPES } from '../../../constants/customerConstants';
import { PAYMENT_TYPE } from '../../../constants/paymentConstants';
import { USER_FEE_TYPE } from '../../../constants/userConstant';

import { colors } from '../../../palette';

// ------------------------------------------------------------------------------

function PaymentOptionsPanelV2() {
  const dispatch = useDispatch();

  const { creditType } = useContext(ConfirmOrderPopupContext);

  const multiCheckoutForm = useSelector(getMultiCheckoutFormSelector);
  const feeType = useSelector(getUserFeeTypeSelector);

  const currency = multiCheckoutForm.currency;
  const paymentType = multiCheckoutForm.paymentType;

  if (!currency || !creditType || !feeType) {
    return null;
  }

  if (
    currency !== CURRENCY.SGD ||
    creditType === CUSTOMER_CREDIT_TYPES.CREDIT ||
    feeType === USER_FEE_TYPE.NO_FEE
  ) {
    return null;
  }

  return (
    <FlexColumn>
      <FtrBoldText style={{ color: colors.neutral070, fontWeight: 700 }}>
        Payment Method
      </FtrBoldText>
      <FlexColumn>
        <StripePaynowPaymentOption
          selected={paymentType === PAYMENT_TYPE.STRIPE_PAYNOW}
          onClick={() => {
            dispatch(updatePaymentType(PAYMENT_TYPE.STRIPE_PAYNOW));
          }}
        />
        <StripeCreditCardPaymentOption
          selected={paymentType === PAYMENT_TYPE.STRIPE_CREDIT_CARD}
          onClick={() => {
            dispatch(updatePaymentType(PAYMENT_TYPE.STRIPE_CREDIT_CARD));
          }}
        />
      </FlexColumn>
    </FlexColumn>
  );
}

export default PaymentOptionsPanelV2;
