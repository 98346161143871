import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { updateMultiCheckoutFormState } from '../../actions/multiCheckoutForm';

import useUserCreditType from '../../hooks/useUserCreditTypeHook';

// -------------------------------------------------------------------------------------------------

const useUpdateCheckoutFormCreditType = (customerID) => {
  const dispatch = useDispatch();
  const { data: creditType } = useUserCreditType(customerID);

  useEffect(() => {
    if (creditType) {
      dispatch(updateMultiCheckoutFormState({ creditType }));
    }
  }, [creditType]);

  return {
    creditType,
  };
};

export default useUpdateCheckoutFormCreditType;
