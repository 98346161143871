// Import settings
import React from 'react';
import { Avatar, IconButton } from '@material-ui/core';
import { Delete as DeleteIcon } from '@material-ui/icons';

export default function AvatarWithButton(props) {
  const { deleteIconStyle } = props;
  return (
    <div style={{ position: 'relative' }} className={props.className}>
      <IconButton
        style={{
          position: 'absolute',
          zIndex: 2,
          bottom: 0,
          right: 0,
          padding: 0,
          boxShadow: '0 0 0 5px #FFFFFF',
        }}
      >
        <div
          style={{
            borderRadius: '50%',
            backgroundColor: '#e7e7e7',
            fontSize: 0,
            padding: '5px',
          }}
        >
          <DeleteIcon
            onClick={props.onButtonClick}
            style={{
              textAlign: 'center',
              verticalAlign: 'middle',
              ...deleteIconStyle,
            }}
          />
        </div>
      </IconButton>
      <Avatar src={props.src} style={{ width: '100%', height: '100%' }} />
    </div>
  );
}
