import React from 'react';

import { FormControlLabel } from '@material-ui/core';

import FtrBlueCheckbox from './FtrBlueCheckbox';
import { FtrS3 } from './FtrTypography';

// --------------------------------------------------------------------------------------------

function FtrCheckboxBoldText(props) {
  const { label = '', onChange, checked, disabled = false, ...rest } = props;

  return (
    <FormControlLabel
      control={
        <FtrBlueCheckbox
          name='same-as-shipping'
          onChange={onChange}
          checked={checked}
          style={{ padding: '0px 10px' }}
          disabled={disabled}
        />
      }
      style={{
        width: 'fit-content',
      }}
      label={<FtrS3 style={{ userSelect: 'none' }}>{label}</FtrS3>}
      {...rest}
    />
  );
}

export default FtrCheckboxBoldText;
