import React from 'react';

import { Info } from '@material-ui/icons';

import { FlexRow } from '../layouts/FlexLayouts';
import FtrBanner from './FtrBanner';
import FtrButton from './FtrButton';
import { FtrB2 } from './FtrTypography';

import withActionTrackingHOC from '../../hocs/withActionTrackingHOC';

import useActionTracking from '../../hooks/useActionTrackingHook';

import { isEmptyValue } from '../../utils/commonUtils';

// -------------------------------------------------------------------------------------------------

const DismissButtonWithTracking = withActionTrackingHOC(FtrButton);

function FtrDismissGuideText(props) {
  const { text, userID, actionTrackingKey } = props;

  const { data, refetch } = useActionTracking(userID, actionTrackingKey);

  if (!isEmptyValue(data)) {
    return null;
  }

  return (
    <FtrBanner type='info' style={{ padding: '0 1rem' }}>
      <FlexRow>
        <Info color='primary' />
        <FtrB2 style={{ fontStyle: 'italic' }}>{text}</FtrB2>
        <DismissButtonWithTracking
          id={`dismiss-btn-${actionTrackingKey}`}
          variant='text'
          color='blue'
          style={{ padding: '10px 0 0.5rem' }}
          onClick={() => {
            setTimeout(() => {
              refetch();
            }, 500);
          }}
          tooltip='Click to dismiss'
          userID={userID}
          actionTrackingKey={actionTrackingKey}
        >
          Dismiss
        </DismissButtonWithTracking>
      </FlexRow>
    </FtrBanner>
  );
}

export default FtrDismissGuideText;
