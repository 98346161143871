export const ITEM_STATUS_MAPPING = {
  CANCELLED: 0,
  VERIFYING: 1, // PPE item which is just accepted by the customer move status to verifying
  SOURCING: 2,
  QUOTES_AVAILABLE: 3,
  ORDER_IN_PROGRESS: 4,
  ORDER_READY: 5,
  DELIVERED: 6,
};

export const ITEM_STATUS_TEXT_MAPPING = {
  CANCELLED: 'Cancelled',
  VERIFYING: 'Verifying',
  SOURCING: 'Sourcing',
  QUOTES_AVAILABLE: 'Quotes Available',
  ORDER_IN_PROGRESS: 'Order In Progress',
  ORDER_READY: 'Order Ready',
  PROCESSING: 'Processing',
  DELIVERED: 'Delivered',
};

export const ITEM_VERIFY_STATUS_FILTER_ARRAY = [
  { name: 'All', value: 'all', group: 'verify-status' },
  { name: 'Manual RFQ', value: 'non-ppe', group: 'verify-status' },
  { name: 'Confirmed Jobs', value: 'ppe', group: 'verify-status' },
];

export const RFQ_ITEM_STATUS = [
  ITEM_STATUS_TEXT_MAPPING.CANCELLED,
  ITEM_STATUS_TEXT_MAPPING.VERIFYING,
  ITEM_STATUS_TEXT_MAPPING.SOURCING,
  ITEM_STATUS_TEXT_MAPPING.QUOTES_AVAILABLE,
  ITEM_STATUS_TEXT_MAPPING.ORDER_IN_PROGRESS,
  ITEM_STATUS_TEXT_MAPPING.ORDER_READY,
  ITEM_STATUS_TEXT_MAPPING.DELIVERED,
];

export const PPE_ITEM_STAGE_STATUS = [
  ITEM_STATUS_TEXT_MAPPING.QUOTES_AVAILABLE,
  ITEM_STATUS_TEXT_MAPPING.PROCESSING,
  ITEM_STATUS_TEXT_MAPPING.ORDER_IN_PROGRESS,
  ITEM_STATUS_TEXT_MAPPING.ORDER_READY,
  ITEM_STATUS_TEXT_MAPPING.DELIVERED,
];

export const ITEM_FILTER_STATUS_ARRAY = [
  {
    name: 'Verifying',
  },
  {
    name: 'Sourcing',
  },
  {
    name: 'Quotes Available',
  },
  {
    name: 'Processing',
  },
  {
    name: 'Order In Progress',
  },
  {
    name: 'Order Ready',
  },
  {
    name: 'Delivered',
  },
  {
    name: 'Others',
    value: 'Cancelled',
  },
];

export const PARTS_LIBRARY_STATUS = {
  PARTS_LIBRARY_ONLY: 'Parts Library Only',
  ORDER_ENQUIRY: 'Order Enquiry',
  PURCHASED: 'Purchased',
};
