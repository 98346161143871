import { useState, useEffect } from 'react';
import { PDFDocument } from 'pdf-lib';

import { getPresignedUrl } from '../utils/presignedUrlUtils';
import { isEmptyValue } from '../utils/commonUtils';

function usePdfPreview(url) {
  const [previewPdf, setPreviewPdf] = useState(null);
  const [previewLoading, setPreviewLoading] = useState(true);
  const [previewError, setPreviewError] = useState(null);

  useEffect(() => {
    if (!url) {
      setPreviewLoading(false);
      return;
    }

    const preparePDF = async () => {
      setPreviewLoading(true); // Set loading to true before preparing
      const presignedUrl = await getPresignedUrl(url);

      try {
        const tdePdfBytes = await fetch(presignedUrl).then((res) =>
          res.arrayBuffer()
        );
        const pdfDoc = await PDFDocument.load(tdePdfBytes);

        await PDFDocument.load(tdePdfBytes);
        const pdfBytes = await pdfDoc.save();
        const bytes = new Uint8Array(pdfBytes);
        const blob = new Blob([bytes], { type: 'application/pdf' });
        setPreviewPdf(URL.createObjectURL(blob));
      } catch (err) {
        setPreviewError(err); // Catch and set any error that occurs
      } finally {
        setPreviewLoading(false); // Set loading to false after fetching
      }
    };

    if (!isEmptyValue(url)) {
      preparePDF();
    }
  }, [url]); // Dependency array to refetch data when apiUrl or key changes

  return { previewPdf, previewLoading, previewError };
}

export default usePdfPreview;
