import React, { useEffect } from 'react';

import { Tooltip } from '@material-ui/core';
import { Tune as TuneIcon } from '@material-ui/icons';

import Thumb from './fields/Thumb';
import { FlexRow } from './layouts/FlexLayouts';
import DisplayFeedbackCard from './cards/DisplayFeedbackCard';

import { colors } from '../palette';

import { FEEDBACK_STATUS } from '../constants/feedbackConstants';

import { convertPriceWithQuantityToCurrency } from '../utils/currencyUtils';
import { DATE_FORMAT, formatDate } from '../utils/dateTimeUtils';

const FeedbackPriceSupplier = (props) => {
  const {
    unitQuoteStr,
    updateFeedbackState,
    feedbackState,
    supplierUnitPriceInSGD,
    onUpsertFeedbackApi,
    item,
    currency,
    exchangeRate,
  } = props;

  useEffect(() => {
    updateFeedbackState({ unitPriceStr: unitQuoteStr, supplierUnitPriceInSGD });
  }, [unitQuoteStr, supplierUnitPriceInSGD]);

  if (
    feedbackState.priceFeedback === FEEDBACK_STATUS.BAD &&
    feedbackState.showFeedbackQuestion === true
  ) {
    return (
      <div style={{ marginLeft: '5px' }}>
        <DisplayFeedbackCard
          onClickAction={() => {
            updateFeedbackState({
              openPopup: true,
            });
          }}
          onCloseAction={() => {
            const priceFeedbackProperties = {
              priceFeedback: null,
              targetUnitPrice: null,
              feedbackDetails: null,
              additionalRemarks: null,
            };
            updateFeedbackState({
              showFeedbackQuestion: false,
              ...priceFeedbackProperties,
            });
          }}
          text='Feedback?'
        />
      </div>
    );
  }

  const renderPriceFeedbackIcon = () => {
    if (!item?.priceFeedback?.isAdminRevisedLatestFeedback) {
      return null;
    }

    const {
      totalPriceStr: unitPriceBeforeRevised,
    } = convertPriceWithQuantityToCurrency({
      totalPrice:
        item?.priceFeedback?.adminRevisedLatestFeedback?.unitPrice || 0,
      currency,
      exchangeRate,
    });

    const {
      totalPriceStr: unitPriceAfterRevised,
    } = convertPriceWithQuantityToCurrency({
      totalPrice:
        item?.priceFeedback?.adminRevisedLatestFeedback?.targetPrice || 0,
      currency,
      exchangeRate,
    });

    return (
      <Tooltip
        title={`Price revised from from ${unitPriceBeforeRevised} to ${unitPriceAfterRevised} on ${formatDate(
          item?.priceFeedback?.adminRevisedLatestFeedback?.createdAt,
          DATE_FORMAT
        )}`}
        arrow
      >
        <TuneIcon style={{ color: colors.blue050, padding: '0 0.5rem' }} />
      </Tooltip>
    );
  };

  return (
    <FlexRow
      style={{
        padding: '5px',
        borderRadius: '5px',
        gap: 0,
        backgroundColor: colors.neutral030,
        transform: 'scale(0.8)',
      }}
    >
      <Thumb
        variant='up'
        isActive={feedbackState.priceFeedback === FEEDBACK_STATUS.GOOD}
        tooltipText='Price is Good'
        onClick={() => {
          const priceFeedbackProperties = {
            priceFeedback: FEEDBACK_STATUS.GOOD,
            targetUnitPrice: null,
            feedbackDetails: null,
            additionalRemarks: null,
          };
          updateFeedbackState(priceFeedbackProperties);
          onUpsertFeedbackApi(priceFeedbackProperties);
        }}
      />
      {renderPriceFeedbackIcon()}
      <Thumb
        variant='down'
        isActive={feedbackState.priceFeedback === FEEDBACK_STATUS.BAD}
        tooltipText='Price not Good'
        onClick={() => {
          updateFeedbackState({
            priceFeedback: FEEDBACK_STATUS.BAD,
            showFeedbackQuestion: true,
          });
        }}
      />
    </FlexRow>
  );
};

export default FeedbackPriceSupplier;
