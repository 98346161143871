import React, { useState } from 'react';

import LoadingBackDropText from '../components/LoadingBackDropText';

function withLoadingBackDropTextHOC(WrappedComponent) {
  return function WrappedComponentWithLoadingBackDropTextHOC(props) {
    const [loadingMessage, setLoadingMessage] = useState('');

    return (
      <>
        <WrappedComponent setLoadingMessage={setLoadingMessage} {...props} />
        <LoadingBackDropText text={loadingMessage} open={!!loadingMessage} />
      </>
    );
  };
}

export default withLoadingBackDropTextHOC;
